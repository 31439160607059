import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { IDictionary } from '../../helper';
import { ConnectedDashboardHeader } from '../../components/header/dashboard';
import { AppFrameContent, AppFrameHeader } from '../frame';
import { GetCookie, resolveTimestamp, TYPES_PROPS, assetUrl, getDetailRoute  } from '../../helper';
import { ThingTypes } from '../../model/ryve/Thing';
import { ListWidget } from '../widget/list';
import { useIsMobile } from '../../helper/hooks/isMobile';
import { FramedContent } from '../framed-content';
import { IListItem } from '../../model/list/ListItem';
import { INotification } from '../../model/notifications';
import { buildAbbreviation } from '../../helper/user';
import { IContainerConfig } from '../../model/configuration';
import { fetchNotificationsNextPageRequestAction } from '../../data/actions/notifications';
import { faBan, faBookReader, faBriefcase, faBuilding, faHospitalAlt, faMicroscope } from '@fortawesome/pro-light-svg-icons';
import { OpenExternalLinkDialog } from '../dialogs/open-external-link';
import { faMask } from '@fortawesome/pro-solid-svg-icons';
import { useFilterConfig } from '../../data/sagas/filter';
import { ConnectedSearchContent } from '../search-results';
import { useConfiguration } from '../../data/sagas/foundation';
import { setAppStateActiveConfigAction } from '../../data/actions/foundation';
import { IProfile } from '../../model/profile';
import { IAuthorInfo } from '../../model/author/AuthorInfo';

export const NotificationDashboardComponent = () => {
  useFilterConfig('start', false, false, true);
  const [notifications, setNotifications] = useState<any>([]);
  const nItems = useSelector(state => state.notifications.items);
  const working = useSelector(state => state.notifications.working);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const moreDataAvailable = useSelector(state => state.notifications.moreDataAvailable);
  const externalLinksAllowed = useSelector(state => state.foundation?.profile?.externalLinksAllowed);
  
  const config = useConfiguration('start');

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const location = useLocation();

  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openExternalLink, setOpenExternalLink] = useState('');

  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight
      <= document.documentElement.offsetHeight) {
        return;
    }
    if (!working && moreDataAvailable) {
      dispatch(fetchNotificationsNextPageRequestAction());
    }
  }

  function toFallbackItem(item: INotification): IListItem {
    return {
      _id: '',
      _score: 0,
      __original: item,
      typeId: item.content?.type || '',
      alias: item.content?.itemRefResolved?.seo?.alias,
      title: item.content?.notificationType || '',
      type: TYPES_PROPS[item.content?.type || '']?.Name + ' ' + item.content?.itemRefResolved?.content?.title,
      color: 'primary',
      avatar: {
        type: 'user',
        abbreviation: buildAbbreviation(item.content?.itemRefResolved?.content?.authorInfoSubD?.[0]?.authorInfoAuthor)
      }
    };
  }

  function handleMessageReferenceSelected(link: string) {
    if (!externalLinksAllowed) {
      setOpenExternalLink(link);
      setOpenExternalLinkDialog(true);
    }
  }

  const mapNotificationToList: (
    notifications: Array<INotification>, 
    appconfig?: IDictionary<IContainerConfig> | null, 
    location?: any
  ) => Array<IListItem> | undefined = (
    notifications,
    appconfig,
    location
  ) => {
    if (!notifications || !notifications.length) {
      return undefined;
    }
    return notifications
      .map(n => {
        if (!n.content || !n.content.type) {
          return toFallbackItem(n);
        }
        let typeProps = TYPES_PROPS[n.content.type];
        let notificators = n.content.notificatorsResolved?.map(nR => ({ ...nR, timestamp: n.content?.notificatorTimestamps?.[nR._id] }));
        
        let anon = n.content?.itemRefResolved?.content?.anonymousPublish;
        if (!anon && !notificators) {
          return toFallbackItem(n);
        }
        let notificatorNames = new Array<string>();
        if (notificators) {
          notificators.sort((a, b) => a.timestamp - b.timestamp);
          notificatorNames = notificators.map(n => n.content?.fullName ?? '');
        }
        let notificatorString = '';
        switch (notificatorNames.length) {
          case 0: {
            notificatorString += 'Niemand';
            break;
          }
          case 1: {
            notificatorString += notificatorNames[0];
            break;
          }
          case 2: {
            notificatorString += notificatorNames[0] + ' und ' + notificatorNames[1];
            break;
          }
          case 3: {
            notificatorString += notificatorNames[0] + ', ' + notificatorNames[1] + ' und ' + notificatorNames[2];
            break;
          }
          default: {
            notificatorString += notificatorNames[0] + ', ' + notificatorNames[1] + ' und ' + (notificatorNames.length - 2) + ' weitere';
          }
        }

        switch (n.content?.notificationType) {
          case 'special':
          case 'broadcast': {
            let author = (n.content?.itemAuthors[0]?.content || n.content?.itemCreator[0]?.content);
            let notificator = anon ? undefined : n.content?.notificatorsResolved?.[0]?.content;
            let connectedEntry = n.content?.itemRefResolved?.content?.connectedEntryResolved?.[0];
            if (!!connectedEntry && !!connectedEntry?.content?.anonymousPublish) {
              anon = true;
            }
            let listItem: IListItem = {
              alias: n.content?.itemRefResolved?.seo?.alias,
              avatar: {
                type: 'user',
                abbreviation: anon ? undefined : buildAbbreviation(notificator?.fullName),
                icon: anon ? faMask : undefined,
                generalUserStatus: notificator?.generalUserStatus || n.content?.itemCreator?.[0]?.content?.generalUserStatus
              },
              badge: !n.content?.notificationRead,
              categories: undefined,
              color: 'primary',
              description: n.content?.itemRefResolved?.content?.content,
              subtitle: connectedEntry?.content?.title,
              subtitle_2: connectedEntry?.type === ThingTypes.News 
                ? connectedEntry?.content?.url
                : undefined,
              target: connectedEntry?.type === ThingTypes.News ? '_blank' : undefined,
              title: (n.content?.notificatorsResolved?.[0]?.content?.fullName || 'Ehemaliger Benutzer') + (n.content?.notificationType === 'special' ? '' : ' an die Community'),
              to: connectedEntry?.type === ThingTypes.News
                ? connectedEntry?.content?.url
                : getDetailRoute({
                  ...connectedEntry, 
                  alias: connectedEntry?.seo?.alias, 
                  typeId: connectedEntry?.type
                }, appconfig || {}, location),
              selected: (!externalLinksAllowed && connectedEntry?.type === ThingTypes.News)
                ? () => {
                  setOpenExternalLink(connectedEntry?.content?.url);
                  setOpenExternalLinkDialog(true);
                  return false;
                }
                : undefined,
              type: resolveTimestamp(n.createdAt, false, false, true),
              typeId: TYPES_PROPS[connectedEntry?.type]?.Name  || '',
              userAvatar: {
                type: 'user',
                image: author && author.avatar && assetUrl(author.avatar, true),
                abbreviation: buildAbbreviation(author?.fullName),
                generalUserStatus: author?.generalUserStatus
              },
              userLink: notificator && `/userbyid/${notificator.userId + (location ? '?ref=' + location?.pathname : '')}`,
              __original: {
                ...n,
                content: {
                  ...n.content,
                  notificationType: 'broadcast'
                }
              },
              _id: n._id,
              _score: 0
            }

            switch (connectedEntry?.type)
            {
              case ThingTypes.BestPractice:
              case ThingTypes.Blog:
              case ThingTypes.Wiki:
              case ThingTypes.Collection:
              case ThingTypes.Idea:
              case ThingTypes.QnA:
              case ThingTypes.Requisition:
              {
                let author = (connectedEntry.content?.itemAuthors?.[0]?.content || connectedEntry.content?.itemCreator?.[0]?.content);

                listItem.avatar = {
                  type: 'user',
                  image: author && author.avatar && assetUrl(author.avatar, true),
                  abbreviation: buildAbbreviation(author?.fullName),
                  generalUserStatus: author?.generalUserStatus
                }
                listItem.subtitle = connectedEntry?.content?.title;
                break;
              }
              case ThingTypes.Group:
              case ThingTypes.Project:
              {
                let avatar = connectedEntry.content?.avatar;
                let author = connectedEntry.content?.itemCreator?.[0]?.content;

                listItem.avatar = {
                  type: avatar ? 'image' : 'user',
                  image: (avatar && assetUrl(avatar, true)) || (author && author.avatar && assetUrl(author.avatar, true)),
                  abbreviation: buildAbbreviation(author?.fullName),
                  generalUserStatus: author?.generalUserStatus
                }
                listItem.subtitle = connectedEntry?.content?.title;
                break;
              }
              case ThingTypes.Profile: 
              {
                listItem.avatar = {
                  type: 'user',
                  image: connectedEntry.content?.avatar && assetUrl(connectedEntry.content.avatar, true),
                  abbreviation: buildAbbreviation(author?.fullName),
                  generalUserStatus: author?.generalUserStatus
                }
                listItem.subtitle = connectedEntry?.content?.fullName;
                break;
              }
              case ThingTypes.Actors: 
              {
                const type = {
                  type: '',
                  icon: faBuilding,
                };
                let itemTitle = connectedEntry.content?.name || connectedEntry.content?.title;
                switch (connectedEntry.content?.type) {
                  case 'scientific institution':
                    type.type = 'Wissenschaft';
                    type.icon = faMicroscope;
                    itemTitle = (connectedEntry.content?.university_id?.content?.name ? connectedEntry.content?.university_id?.content?.name + ' - ' : '') + itemTitle;
                    break;
                  case 'company':
                    type.type = 'Unternehmen';
                    type.icon = faBuilding;
                    break;
                  case 'health institution':
                    type.type = 'Leistungserbringer';
                    type.icon = faHospitalAlt;
                    itemTitle = (connectedEntry.content?.hospital_id?.content?.name ? connectedEntry.content?.hospital_id?.content?.name + ' - ' : '') + itemTitle;
                    break;
                  case 'other organization':
                    type.type = 'Sonstige';
                    type.icon = faBriefcase;
                    break;
                }
                listItem.title = itemTitle;
                listItem.avatar = {
                  type: 'icon',
                  icon: type.icon
                }
                listItem.subtitle = connectedEntry?.content?.name;
                listItem.typeId = type.type;
                break;
              }
              case ThingTypes.Meeting: 
              {
                listItem.typeId = connectedEntry?.content?.cancelled
                  ? 'Abgesagtes Meeting'
                  : 'Meeting' + (connectedEntry?.content?.interval?.dateFrom 
                      ? ': ' + resolveTimestamp(connectedEntry.content.interval.dateFrom * 1000, true, true) 
                      : '');
                listItem.color = connectedEntry?.content?.cancelled ? 'error' : 'primary';
                listItem.avatar = {
                  type: connectedEntry?.content?.cancelled ? 'icon' : 'time',
                  icon: faBan,
                  time: {
                    from: new Date((connectedEntry?.content?.interval?.dateFrom || 0) * 1000).toISOString(),
                    to: new Date((connectedEntry?.content?.interval?.dateTo || 0) * 1000).toISOString(),
                  }
                };
                break;
              }
              case ThingTypes.News:
              {
                listItem.avatar = {
                  type: 'icon',
                  icon: faBookReader
                };
              }
            }
            return listItem;
          }
          case 'create': {
            switch (n.content.type) {
              case ThingTypes.Wiki: 
              case ThingTypes.Blog: 
              case ThingTypes.BestPractice: 
              case ThingTypes.QnA:
              case ThingTypes.Idea:
              case ThingTypes.Requisition:
              case ThingTypes.Collection:
              {
                let author: IProfile | undefined = anon ? undefined : (n.content?.itemAuthors[0]?.content || n.content?.itemCreator[0]?.content);
                let type = typeProps?.Name;
                if (n.content?.type === ThingTypes.Collection && n.content?.itemRefResolved?.content?.strategicDialog) {
                  type = 'Strategischer Dialog';
                }
                let notificationText = type + ' "' + n.content?.itemRefResolved?.content?.title + '"  von ' + (author?.fullName || (anon ? "Anonym" : "ehemaligem Benutzer"));
                if (n.content?.itemRefResolved?.content?.authorInfoSubD?.length > 1) {
                  notificationText += ' et al.'
                }
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  badge: !n.content?.notificationRead,
                  color: 'primary',
                  typeId: n.content?.type || '',
                  avatar: {
                    type: 'user',
                    image: author && author.avatar && assetUrl(author.avatar, true),
                    abbreviation: anon ? undefined : (author && author?.fullName && buildAbbreviation(author?.fullName)) || 'EB',
                    generalUserStatus: author?.generalUserStatus
                  },
                  highlight: true,
                  title: notificationText,
                  type: 'veröffentlicht ' + resolveTimestamp((n.createdAt || n.content.itemRefResolved.modifiedAt), false, false, true).toLowerCase(),
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.Meeting: // Nur Benachrichtigungen über Meetings bei Invites
              case ThingTypes.Project: // Noch nicht Teil des Angebots
              case ThingTypes.Group: // Noch nicht Teil des Angebots
              default:
                return toFallbackItem(n);
            }
          }
          case 'update': {
            switch (n.content.type) {
              case ThingTypes.Wiki: 
              case ThingTypes.Blog: 
              case ThingTypes.BestPractice: 
              case ThingTypes.QnA:
              case ThingTypes.Idea:
              case ThingTypes.Requisition:
              case ThingTypes.Collection:
              {
                let author: IProfile | undefined = anon ? undefined : (n.content?.itemAuthors[0]?.content || n.content?.itemCreator[0]?.content);
                let type = typeProps?.Name;
                if (n.content?.type === ThingTypes.Collection && n.content?.itemRefResolved?.content?.strategicDialog) {
                  type = 'Strategischer Dialog';
                }

                let notificationText = type + ' "' + n.content?.itemRefResolved?.content?.title + '"  von ' + (author?.fullName || (anon ? "Anonym" : "ehemaligem Benutzer"));
                if (n.content?.itemRefResolved?.content?.authorInfoSubD?.length > 1) {
                  notificationText += ' et al.';
                }
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  badge: !n.content?.notificationRead,
                  color: 'primary',
                  typeId: n.content?.type || '',
                  avatar: {
                    type: 'user',
                    image: author && author.avatar && assetUrl(author.avatar, true),
                    abbreviation: anon ? undefined : (author && author?.fullName && buildAbbreviation(author?.fullName)) || 'EB',
                    generalUserStatus: author?.generalUserStatus
                  },
                  highlight: true,
                  title: notificationText,
                  type: 'aktualisiert ' + resolveTimestamp((n.createdAt || n.content.itemRefResolved.modifiedAt ), false, false, true).toLowerCase(),
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.Meeting:
              {
                const notificator = notificators[0];
                const updateMessage = n.content.itemRefResolved?.content?.meetingUpdateMessage || '';
                let parentType = n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.type
                let type = TYPES_PROPS[parentType]?.Article?.theDat + ' ' +  TYPES_PROPS[parentType]?.Name;
                let notificationText = notificatorString + ' hat das Meeting "' + n.content?.itemRefResolved?.content?.title + '" in ' + type + ' "' + n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.content?.title + '" aktualisiert';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: updateMessage
                    ? updateMessage.substring(0, 50) + (updateMessage.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: resolveTimestamp(( n.createdAt || n.content.itemRefResolved.modifiedAt), false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n);
            }
          }
          case 'delete': {
            switch (n.content.type) {
              case ThingTypes.Meeting:
              {
                const notificator = notificators[0];
                const cancelMessage = n.content.itemRefResolved?.content?.meetingCancelledMessage || '';
                let parentType = n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.type
                let type = TYPES_PROPS[parentType]?.Article?.theDat + ' ' +  TYPES_PROPS[parentType]?.Name;
                let notificationText = notificatorString + ' hat das Meeting "' + n.content?.itemRefResolved?.content?.title + '" in ' + type + ' "' + n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.content?.title + '" abgesagt';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: cancelMessage
                    ? cancelMessage.substring(0, 50) + (cancelMessage.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: resolveTimestamp((n.createdAt || n.content.itemRefResolved.modifiedAt), false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n);
            }
          }
          case 'thread-reply':
          case 'reply': {
            const notificationType = n.content?.notificationType;
            if (notificatorNames.length > 1) {
              notificatorString += ' haben';
            } else {
              notificatorString += ' hat';
            }
            const notificator = notificators?.[0]?.content;
            const multiple = n.content.messages?.length > 1;
            const messages = n.content.messagesResolved?.map(m => ({ content: m.content, timestamp: m.createdAt }));
            messages.sort((a, b) => b.timestamp - a.timestamp);
            const newestMessage = messages[0];
            switch (n.content.type) {
              case ThingTypes.Wiki: 
              case ThingTypes.Blog: 
              case ThingTypes.BestPractice: 
              {
                let type = typeProps?.Article?.zu + ' ' + ( typeProps?.Alt?.Name || typeProps?.NameDat || typeProps?.Name);
                let notificationText = notificatorString + (multiple ? ' neue Diskussionsbeiträge ' : ' einen neuen Diskussionsbeitrag ') + type + ' "' + n.content.itemRefResolved?.content?.title + '"  verfasst';
                if (n.content.itemRelation === 'author') {
                  notificationText = notificatorString + ' auf ' + typeProps?.Article?.yourAkk + ' ' + (typeProps?.NameAkk || typeProps?.Name) + ' "' + n.content.itemRefResolved?.content?.title + '"  geantwortet' 
                }
                if (n.content.itemRelation === 'interest') {
                  notificationText += ', ' + typeProps.Article?.theNom + ' zu Ihren Interessen passt'
                }
                if (n.content.itemRelation === 'competence') {
                  notificationText += ', ' + typeProps.Article?.theNom + ' zu Ihren Kompetenzen passt'
                }
                let listItem: IListItem = { 
                  alias: n.content.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator && notificator.avatar && assetUrl(notificator.avatar, true),
                    abbreviation: notificator && buildAbbreviation(notificator.fullName),
                    generalUserStatus: notificator?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: newestMessage?.content 
                    ? newestMessage.content.substring(0, 50) + (newestMessage.content.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: 'kommentiert ' + resolveTimestamp((newestMessage?.timestamp || n.createdAt), false, false, true).toLowerCase(),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.QnA:
              case ThingTypes.Idea:
              case ThingTypes.Requisition:
              {
                let type = typeProps?.Article?.theAkk + ' ' + (typeProps?.NameDat || typeProps?.Name);
                let notificationText = notificatorString + " auf " + type + ' "' + n.content.itemRefResolved?.content?.title + '"  geantwortet';
                if (n.content.itemRelation === 'author') {
                  notificationText = notificatorString + ' auf ' + typeProps?.Article?.yourAkk + ' ' + (typeProps?.NameAkk || typeProps?.Name) + ' "' + n.content.itemRefResolved?.content?.title + '"  geantwortet' 
                }
                if (n.content.itemRelation === 'interest') {
                  notificationText += ', ' + typeProps.Article?.theNom + ' zu Ihren Interessen passt'
                }
                if (n.content.itemRelation === 'competence') {
                  notificationText += ', ' + typeProps.Article?.theNom + ' zu Ihren Kompetenzen passt'
                }
                let listItem: IListItem = { 
                  alias: n.content.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator && notificator.avatar && assetUrl(notificator.avatar, true),
                    abbreviation: notificator && buildAbbreviation(notificator.fullName),
                    generalUserStatus: notificator?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: newestMessage?.content 
                    ? newestMessage.content.substring(0, 50) + (newestMessage.content.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: 'kommentiert ' + resolveTimestamp((newestMessage?.timestamp || n.createdAt), false, false, true).toLowerCase(),
                  typeId: n.content?.type || '0000',
                  __original: {
                    ...n,
                    content: {
                      ...n.content
                    }
                  },
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.Project: 
              case ThingTypes.Group: 
              {
                let sub = notificationType === 'reply' ? 'Nachricht' : 'Antwort';
                let type = typeProps?.Article?.theDat + ' ' + (typeProps?.NameDat || typeProps?.Name);
                let notificationText = notificatorString + ' in '  + type + ' "' + n.content.itemRefResolved?.content?.title + '"' + (multiple ? ` neue ${sub}en` : ` eine neue ${sub}`) + ' veröffentlicht';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator && notificator.avatar && assetUrl(notificator.avatar, true),
                    abbreviation: notificator && buildAbbreviation(notificator.fullName),
                    generalUserStatus: notificator?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: newestMessage?.content 
                    ? newestMessage.content.substring(0, 50) + (newestMessage.content.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: resolveTimestamp((newestMessage?.timestamp || n.createdAt), false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.OneToOne: 
              {
                let sub = notificationType === 'reply' ? 'Nachricht' : 'Antwort';
                let notificationText = notificatorString + ' Ihnen' + (multiple ? ` neue ${sub}en` : ` eine neue ${sub}`) + ' geschrieben';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator && notificator.avatar && assetUrl(notificator.avatar, true),
                    abbreviation: notificator && buildAbbreviation(notificator.fullName),
                    generalUserStatus: notificator?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: newestMessage?.content 
                    ? newestMessage.content.substring(0, 50) + (newestMessage.content.length > 50 ? '...' : '')
                    : '',
                  title: notificationText,
                  type: resolveTimestamp((newestMessage?.timestamp || n.createdAt), false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.Collection: //Keine Diskussion an Sammlungen
              default:
                return toFallbackItem(n)
            }
          }
          case 'invite': 
          {
            const notificator = notificators[0];
            switch (n.content.type) {
              case ThingTypes.Project: 
              case ThingTypes.Group:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let type = typeProps?.Article?.theAkk + ' ' +  typeProps?.Name;
                let notificationText = notificatorString + ' Sie in ' + type + ' "' + n.content?.itemRefResolved?.content?.title + '" eingeladen';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.OneToOne:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let notificationText = notificatorString + ' Sie zu einem Chat eingeladen';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.Meeting:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                const inviteMessage = n.content.itemRefResolved?.content?.invitation || '';
                let parentType = n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.type
                let type = TYPES_PROPS[parentType]?.Article?.theDat + ' ' +  TYPES_PROPS[parentType]?.Name;
                let notificationText = notificatorString + ' Sie zu dem Meeting "' + n.content?.itemRefResolved?.content?.title + '" in ' + type + ' "' + n.content.itemRefResolved?.content?.parentThingResolved?.[0]?.content?.title + '" eingeladen';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: inviteMessage
                  ? inviteMessage.substring(0, 50) + (inviteMessage.length > 50 ? '...' : '')
                  : '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n)
            }
          }
          case 'invitation-accepted': 
          {
            const notificator = notificators[0];
            switch (n.content.type) {
              case ThingTypes.OneToOne: {
                let notificationText = notificatorString + ' hat Ihre Einladung zum Chat angenommen';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n)
            }
          }
          case 'request': 
          {
            const notificator = notificators[0];
            switch (n.content.type) {
              case ThingTypes.Project: 
              case ThingTypes.Group:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let type = typeProps?.Article?.theAkk + ' ' +  typeProps?.Name;
                let notificationText = notificatorString + ' eine Beitrittsanfrage für ' + type + ' "' + n.content?.itemRefResolved?.content?.title + '" gestellt';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n)
            }
          }
          case 'conference-started': 
          {
            const notificator = notificators[0];
            switch (n.content.type) {
              case ThingTypes.Project: 
              case ThingTypes.Group:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let type = typeProps?.Article?.theAkk + ' ' +  typeProps?.Name;
                let notificationText = notificatorString + ' in ' + type + ' "' + n.content?.itemRefResolved?.content?.title + '" eine neue Adhoc-Konferenz gestartet';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              case ThingTypes.OneToOne:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let notificationText = notificatorString + ' in Ihrem Chat eine neue Adhoc-Konferenz gestartet';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n)
            }
          }
          case 'admin-appointed':
          {
            const notificator = notificators[0];
            switch (n.content.type) {
              case ThingTypes.Project: 
              case ThingTypes.Group:
              {
                if (notificatorNames.length > 1) {
                  notificatorString += ' haben';
                } else {
                  notificatorString += ' hat';
                }
                let type = typeProps?.Article?.theDat + ' ' +  typeProps?.Name;
                let notificationText = notificatorString + ' Sie in ' + type + ' "' + n.content?.itemRefResolved?.content?.title + '" zum Administrator ernannt.';
                let listItem: IListItem = { 
                  alias: n.content?.itemRefResolved?.seo?.alias,
                  avatar: {
                    type: 'user',
                    image: notificator?.content?.avatar && assetUrl(notificator.content.avatar, true),
                    abbreviation: notificator?.content?.fullName && buildAbbreviation(notificator.content.fullName),
                    generalUserStatus: notificator?.content?.generalUserStatus
                  },
                  badge: !n.content?.notificationRead,
                  categories: undefined,
                  color: 'primary',
                  subtitle: '',
                  title: notificationText,
                  type: resolveTimestamp(n.createdAt, false, false, true),
                  typeId: n.content?.type || '0000',
                  __original: n,
                  _id: n._id,
                  _score: 0
                }
                return listItem;
              }
              default:
                return toFallbackItem(n)
            }
          }
          default:
            return toFallbackItem(n)
        }
      })
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (nItems && nItems.length) {
      setNotifications(mapNotificationToList(nItems, appconfig, location))
    }
  }, [nItems, appconfig, location, externalLinksAllowed]);

  useEffect(() => {
    config && dispatch(setAppStateActiveConfigAction(config));
  }, [config, dispatch]);

  return (
    <>
      <AppFrameHeader>
        <ConnectedDashboardHeader
          placeholder={'Medtec Online durchsuchen'} 
          alias={'start'} 
        />
      </AppFrameHeader>
      <AppFrameContent><Switch>
        <Route exact path={['/start']}>
        <FramedContent>
          <ListWidget
            headline={'Neueste Aktivitäten'}
            items={notifications.filter(n => n._id)}
            working={working}
            showAllCaption={isMobile ? 'Alle gelesen' : 'Alle als gelesen markieren'}
            showAllClick={() => {
              const xsrfToken = GetCookie('XSRF-TOKEN') || '';
              fetch('/uwao-api/mto/v2/notifications', {
                method: 'POST',
                body: JSON.stringify({
                  markAllAsRead: true
                }),
                headers: { 'content-type': 'application/json', 'X-XSRF-TOKEN': xsrfToken }
              });
            }}
            externalLinkClicked={handleMessageReferenceSelected}
          />
        </FramedContent>
        <OpenExternalLinkDialog
          open={openExternalLinkDialog}
          headerText={'Sie verlassen Medtec Online'}
          noteToUserText={
            'Bitte beachten Sie, dass dieser Link eine unabhängige Website öffnet, für deren Inhalt und Betrieb wir nicht verantwortlich sind;'
            + 'die Abfrage erfolgt direkt vom Server des Dritten. Für die Bereitstellung, Datenverarbeitung und den Inhalt ist der Dritte verantwortlich.'
            + 'Diese Meldung wird einmalig angezeigt, ihr Inhalt gilt auch für zukünftige Abrufe von Drittwebseiten.'
          }
          labelText={'Weiter zur externen Seite'}
          link={openExternalLink}
          onCanceled={() => {setOpenExternalLinkDialog(false);}}
        />
        </Route>
        <Route exact path={['/start/search']}>
          <ConnectedSearchContent />
        </Route>
      </Switch></AppFrameContent>
    </>
  );
};
