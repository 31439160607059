import { Bx, Input, TabItem, TabList, Typo } from '@curry-group/mui-curcuma';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FramedContent } from '../../../components/framed-content';
import { ListWidget } from '../../../components/widget/list';
import { fetchMeetingsRequest } from '../../../data/reducer/detail';
import { compareTimeStampGreaterOrSameDay, resolveTimestamp } from '../../../helper';
import { transformForList } from '../../../model/ryve/Thing';

export const ConnectedMeetingManager = () => {
  const dispatch = useDispatch();

  const communicationId = useSelector(state => state.communication?.id);
  const meetings = useSelector(state => state.detail.children.items);

  const detail = useSelector(state => state.detail.item);

  const tabs = [
    {
      title: 'Agenda',
      alias: 'agenda'
    },
    {
      title: 'Archiv',
      alias: 'archive'
    }
  ];

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [previousMeetings, setPreviousMeetings] = useState<Array<Array<any>>>([]);
  const [futureMeetings, setFutureMeetings] = useState<Array<Array<any>>>([]);

  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    if (communicationId) dispatch(fetchMeetingsRequest({ parentId: communicationId }));
  }, [communicationId, dispatch]);

  useEffect(() => {
    if (meetings) {
      const prevMeetDict = groupBy(
        meetings
          ?.filter(meeting => !compareTimeStampGreaterOrSameDay(meeting.content.interval.dateTo * 1000, Date.now()))
          ?.sort((a, b) => b.content.interval.dateFrom - a.content.interval.dateFrom)
          ?.map(m => {
            return {
              ...m,
              content: {
                ...m.content,
                interval: {
                  dateFrom: m.content.interval.dateFrom,
                  dateTo: m.content.interval.dateTo,
                  dayFrom: new Date(m.content.interval.dateFrom * 1000).toLocaleDateString(),
                  dayTo: new Date(m.content.interval.dateTo * 1000).toLocaleDateString()
                }
              }
            };
          }),
        'content.interval.dayFrom'
      );
      const prevMeetArray: any[] = [];
      for (const key in prevMeetDict) {
        if (prevMeetDict.hasOwnProperty(key)) {
          prevMeetArray.push(prevMeetDict[key].sort((a, b) => a.content.interval.dateFrom - b.content.interval.dateFrom));
        }
      }
      setPreviousMeetings(prevMeetArray);
      const futMeetDict = groupBy(
        meetings
          ?.filter(meeting => compareTimeStampGreaterOrSameDay(meeting.content.interval.dateTo * 1000, Date.now()))
          ?.sort((a, b) => a.content.interval.dateFrom - b.content.interval.dateFrom)
          .map(m => {
            return {
              ...m,
              content: {
                ...m.content,
                interval: {
                  dateFrom: m.content.interval.dateFrom,
                  dayFrom: new Date(m.content.interval.dateFrom * 1000).toLocaleDateString(),
                  dateTo: m.content.interval.dateTo,
                  dayTo: new Date(m.content.interval.dateTo * 1000).toLocaleDateString()
                }
              }
            };
          }),
        'content.interval.dayFrom'
      );
      const futMeetArray: any[] = [];
      for (const key in futMeetDict) {
        if (futMeetDict.hasOwnProperty(key)) {
          futMeetArray.push(futMeetDict[key]);
        }
      }
      setFutureMeetings(futMeetArray);
    }
  }, [meetings]);

  return (
    <>
      <FramedContent>
        <Bx mb={2} display="flex" justifyContent="space-between">
          <Bx>
            <TabList
              value={selectedTab}
              onChange={
                ((event: any, value: any) => {
                  setSelectedTab(value);
                  setFilter('');
                }) as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)
              }
            >
              {tabs.map((tab, idx) => (
                <TabItem key={idx} label={tab.title} />
              ))}
            </TabList>
          </Bx>
          <Bx>
            <Input
              input={{
                value: filter,
                placeholder: 'Meetings suchen',
                onChange: event => setFilter(event.target.value),
                startAdornment: (
                  <Bx mr={1}>
                    <FontAwesomeIcon icon={faSearch} />
                  </Bx>
                )
              }}
            />
          </Bx>
        </Bx>
        {selectedTab === 0 && (
          <Bx>
            {(!futureMeetings || futureMeetings.length === 0) && (
              <Bx mb={4}>
                <Typo variant="subtitle1">
                  <strong>Aktuell keine terminierten Meetings vorhanden.</strong>
                </Typo>
              </Bx>
            )}
            {futureMeetings
              ?.map(meetings =>
                meetings.filter(
                  meeting => meeting.content.title.toLowerCase().includes(filter.toLowerCase()) || meeting.content.content.toLowerCase().includes(filter.toLowerCase())
                )
              )
              .filter(meetings => meetings.length > 0)
              .map((meetings, idx) => {
                return (
                  <Bx key={idx} mb={4}>
                    <ListWidget
                      headline={resolveTimestamp(meetings?.[0]?.content?.interval?.dateFrom * 1000, true, true)}
                      items={transformForList(
                        (meetings || []).map(item => {
                          return { _source: { ...item, content: { ...item.content, parentThing: detail } }, _id: item?._id, _score: 0 };
                        })
                      )}
                      listType={'meeting'}
                    />
                  </Bx>
                );
              })}
          </Bx>
        )}
        {selectedTab === 1 && (
          <Bx>
            {(!previousMeetings || previousMeetings.length === 0) && (
              <Bx mb={4}>
                <Typo variant="subtitle1">
                  <strong>Keine archivierten terminierten Meetings vorhanden.</strong>
                </Typo>
              </Bx>
            )}
            {previousMeetings
              ?.map(meetings =>
                meetings.filter(
                  meeting => meeting.content.title.toLowerCase().includes(filter.toLowerCase()) || meeting.content.content.toLowerCase().includes(filter.toLowerCase())
                )
              )
              .filter(meetings => meetings.length > 0)
              .map((meetings, idx) => {
                return (
                  <Bx key={idx} mb={4}>
                    <ListWidget
                      headline={resolveTimestamp(meetings?.[0]?.content?.interval?.dateFrom * 1000, true, true)}
                      items={transformForList(
                        (meetings || []).map(item => {
                          return { _source: { ...item, content: { ...item.content, parentThing: detail } }, _id: item?._id, _score: 0 };
                        })
                      )}
                      listType={'meeting'}
                    />
                  </Bx>
                );
              })}
          </Bx>
        )}
      </FramedContent>
    </>
  );
};
