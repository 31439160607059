import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FormRTE } from '../controls/rte';

interface IFormEmployerSiganture {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (formField: string, formValue: any, formName?: string) => void;
}

export const FormEmployerSignature: React.FC<IFormEmployerSiganture> = ({ formData, headline, subtitle, subtitleComponent, onChange }) => {
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormRTE
                value={formData?.signature}
                label={'Signatur Ihres Arbeitgebers'}
                placeholder={'Kopieren & fügen Sie hier die Signatur Ihres Arbeitgebers aus einer Mail ein...'}
                mandatory={true}
                minLength={0}
                onChange={val => {
                  onChange('signature', val, '');
                }}
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
