import React from 'react';
import { FormIdea } from '../../form/compositions/FormIdea';
import { FramedContent } from '../../framed-content';

const IdeaForm = (props: any) => {
  if (!props.flowData || !props.flowData?.itemData?.title || !props.flowData?.itemData?.content || props.flowData?.itemData?.content === '<p></p>') {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormIdea
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={'Bitte achten Sie darauf, keine schutzrechtsrelevanten Details offenzulegen.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default IdeaForm;
