import { Btn, Bx, IconBtn, Input, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { faMailbox, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearRequestToken, fetchUserprofileAdminPendingTokens, resendNotifications, resetAndResendNotifications } from '../../data/reducer/userprofile';
import { ThingTypes } from '../../model/ryve/Thing';
import { NotFoundComponent } from '../detail/not-found';
import { ListWidget } from '../widget/list';

export interface ITokenAdminComponentProps {
//   userProfile: IProfile;
}

export interface IToken {
  _id: string;
  _score: number;
  categories: Array<{ categoryId: string }>;
  refId: string;
  processId: string;
  repeatCount: number;
  type: string;
  content: any;
  title: string;
}

export const TokenAdminComponent: React.FC<ITokenAdminComponentProps> = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.foundation?.profile?.isAdmin);
  const pendingTokens: Array<any> = useSelector(state => state.userProfile?.pendingTokens) || [];
  const pendingTokensWorking = useSelector(state => state.userProfile?.pendingTokensWorking);
  const [userMail, setUserMail] = useState('');
  const [contentId, setContentId] = useState('');
  const [contentType, setContentType] = useState('');
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    !!isAdmin && dispatch(fetchUserprofileAdminPendingTokens({}));
  }, [dispatch, isAdmin]);

  if (!isAdmin) {
    return (<NotFoundComponent />); 
  }
  const registrations = pendingTokens.filter(tok => tok.typeId === ThingTypes.Profile);
  const passReset = pendingTokens.filter(tok => tok.typeId === 'lost-password');
  const loginFailed = pendingTokens.filter(tok => tok.typeId === 'login-failed');
  const messagingSettings = pendingTokens.filter(tok => tok.typeId === 'mail-deactivation-token' && tok.tokenRepeatCount > 4);

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUserMail(event.target.value);
  };
  const onContentIdValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setContentId(event.target.value);
  };
  const onContentTypeValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setContentType(event.target.value);
  };
  const onEventTypeValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEventType(event.target.value);
  };

  return (<>
    <Bx pb={4}>
      <Typo variant={'h3'} component={'h3'}>
          {'Benachrichtigungs-Mail erneut auslösen für User:'}
      </Typo>
      <Grid item xs={12} md={6}>
        <Bx mb={2}>
          <Input inputLabel={{ children: 'E-Mail', mandatory: true }} input={{ value: userMail, fullWidth: true, onChange: onInputValueChange }} />
        </Bx>
      </Grid>
      <Grid item xs={6} md={6}>
        <Btn size="large"
          startIcon={<FontAwesomeIcon icon={faMailbox} />}
          variant="outlined"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(resetAndResendNotifications({email:userMail}));
            setUserMail('');
          }}
        >
          Benachrichtigungs-Mail erneut auslösen
        </Btn>
      </Grid>
    </Bx>
    <Bx key={'registrationsTokens'} pb={2}>
    {registrations && registrations?.length > 0 && (<ListWidget
      working={pendingTokensWorking}
      items={registrations}
      headline={'Offene Registrierungen' + (registrations?.length ? ' (' + registrations?.length + ')' : '')}
      itemActions={item => {
        const options: ReactNode[] = [];
        options.push(
          <Bx key={'buttonBoxInvite'} display="flex" ml="auto">
            <Bx display="flex" justifyContent="center" alignItems="center">
              <IconBtn
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearRequestToken({tokenId:item._id}));
                }}
                color="default"
                size="medium"
                variant="outlined"
                title="Löschen"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Bx>
          </Bx>
        )
        return options;
      }}
    />)}
    {(!registrations || registrations?.length === 0) && (<ListWidget
        ignoreWorking={true}
        items={undefined}
        headline={'Keine offenen Registrierungen'}
      />
    )}
    </Bx>
    <Bx key={'loginFailedTokens'} pb={2}>
    {loginFailed && loginFailed?.length > 0 && (<ListWidget
      working={pendingTokensWorking}
      items={loginFailed}
      headline={'Passwort falsch eingegeben Anfragen' + (loginFailed?.length ? ' (' + loginFailed?.length + ')' : '')}
      itemActions={item => {
        const options: ReactNode[] = [];
        options.push(
          <Bx key={'buttonBoxInvite'} display="flex" ml="auto">
            <Bx display="flex" justifyContent="center" alignItems="center">
              <IconBtn
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearRequestToken({tokenId:item._id}));
                }}
                color="default"
                size="medium"
                variant="outlined"
                title="Löschen"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Bx>
          </Bx>
        )
        return options;
      }}
    />)}
    {(!loginFailed || loginFailed?.length === 0) && (<ListWidget
        ignoreWorking={true}
        items={undefined}
        headline={'Keine falschen Passwort-Logins'}
      />
    )}
    </Bx>
    <Bx key={'passResetTokens'} pb={2}>
    {passReset && passReset?.length > 0 && (<ListWidget
      working={pendingTokensWorking}
      items={passReset}
      headline={'Passwort zurücksetzen Anfragen' + (passReset?.length ? ' (' + passReset?.length + ')' : '')}
      itemActions={item => {
        const options: ReactNode[] = [];
        options.push(
          <Bx key={'buttonBoxInvite'} display="flex" ml="auto">
            <Bx display="flex" justifyContent="center" alignItems="center">
              <IconBtn
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearRequestToken({tokenId:item._id}));
                }}
                color="default"
                size="medium"
                variant="outlined"
                title="Löschen"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Bx>
          </Bx>
        )
        return options;
      }}
    />)}
    {(!passReset || passReset?.length === 0) && (<ListWidget
        ignoreWorking={true}
        items={undefined}
        headline={'Keine offenen Passwort-Resets'}
      />
    )}
    </Bx>
    <Bx key={'messagingSettingsTokens'} pb={2}>
    {messagingSettings && messagingSettings?.length > 0 && (<ListWidget
      working={pendingTokensWorking}
      items={messagingSettings}
      headline={'Mail-Benachrichtigungen anonym anpassen gesperrt' + (messagingSettings?.length ? ' (' + messagingSettings?.length + ')' : '')}
      itemActions={item => {
        const options: ReactNode[] = [];
        options.push(
          <Bx key={'buttonBoxInvite'} display="flex" ml="auto">
            <Bx display="flex" justifyContent="center" alignItems="center">
              <IconBtn
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearRequestToken({tokenId:item._id}));
                }}
                color="default"
                size="medium"
                variant="outlined"
                title="Löschen"
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Bx>
          </Bx>
        )
        return options;
      }}
    />)}
    {(!messagingSettings || messagingSettings?.length === 0) && (<ListWidget
        ignoreWorking={true}
        items={undefined}
        headline={'Keine Mail-Benachrichtigungen-Tokens gesperrt'}
      />
    )}
    </Bx>
    <Bx pb={4}>
      <Typo variant={'h3'} component={'h3'}>
          {'Notifications erneut auslösen'}
      </Typo>
      <Grid item xs={12} md={6}>
        <Bx mb={2}>
          <Input inputLabel={{ children: 'ContentId', mandatory: true }} input={{ value: contentId, fullWidth: true, onChange: onContentIdValueChange }} />
        </Bx>
      </Grid>
      <Grid item xs={12} md={6}>
        <Bx mb={2}>
          <Input inputLabel={{ children: 'ContentType', mandatory: true }} input={{ value: contentType, fullWidth: true, onChange: onContentTypeValueChange }} />
        </Bx>
      </Grid>
      <Grid item xs={12} md={6}>
        <Bx mb={2}>
          <Input inputLabel={{ children: 'EventType', mandatory: true }} input={{ value: eventType, fullWidth: true, onChange: onEventTypeValueChange }} />
        </Bx>
      </Grid>
      <Grid item xs={6} md={6}>
        <Btn size="large"
          startIcon={<FontAwesomeIcon icon={faMailbox} />}
          variant="outlined"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(resendNotifications({contentId, contentType, eventType}));
            setContentId('');
            setContentType('');
            setEventType('');
          }}
        >
          Notifications erneut auslösen
        </Btn>
      </Grid>
    </Bx>
  </>);
};
