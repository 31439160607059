import { Bx, ExtendedListItem, Typo, Ava, useBreakpoints, TBreakpointContextProps, IBxProps } from '@curry-group/mui-curcuma';
import { faFlaskPotion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { MobileHeadBar } from '../../../mobile-head-bar';
import { Grid } from '@material-ui/core';
import { ListAvatarColors, MTOLogo, hashCode } from '../../../../helper';

export interface IHeadBarProps extends IBxProps {
  transparent?: boolean;
  backButtonLink?: string | Function;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 2,
    top: 0,
    width: '100%',
    transition: theme.transitions.create(['background-color', 'border'], { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut }),
    borderBottom: `1px solid transparent`,
    minHeight: 81
  },
  filled: {
    backgroundColor: '#fff',
    borderBottom: `1px solid #DDDFE0`
  },
  hasBoxShadow: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)'
  },
  fade: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut
    })
  }
}));

export interface IConferenceHeaderProps {
  title: string;
  logoAlt: string;
  logoSrc: string;
  partnerLogoAlt: string;
  partnerLogoSrc: string;
  avatar: React.ReactChild | undefined;
  breakpoints: TBreakpointContextProps;
  sidebarVisible: boolean;
}

export const ConnectedConferenceHeaderComponent: React.FC<{ sidebarVisible: boolean; fadeOut?: boolean; onMouseEnter?: () => void; onMouseLeave?: () => void }> = ({
  sidebarVisible,
  fadeOut,
  onMouseEnter,
  onMouseLeave
}) => {
  const breakpoints = useBreakpoints();
  const logoSrc = MTOLogo.logoWithTextSrc;
  const logoAlt = MTOLogo.logoAlt;
  const logoMobileSrc = MTOLogo.logoMobileSrc;
  const partnerLogoSrc = MTOLogo.partnerLogoSrc;
  const partnerLogoMobileSrc = MTOLogo.partnerLogoMobileSrc;
  const partnerLogoAlt = MTOLogo.partnerLogoAlt;

  const title = useSelector(state => state.detail?.item?.content?.title) || '';

  const avatarImage = useSelector(state => state.detail?.item?.content?.avatarResolved?.attachmentUrl);
  let avatar;
  const hash = hashCode(title.slice(0, 2) ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  if (avatarImage) {
    avatar = <Ava size={breakpoints.mdDown ? 'medium' : 'large'}
      src={avatarImage}
      children={<FontAwesomeIcon icon={faFlaskPotion} />}
      style={{ fontWeight: 500 }}
    />;
  } else {
    avatar = <Ava color={'primary'}
      src={undefined}
      children={<FontAwesomeIcon icon={faFlaskPotion} />}
      size={breakpoints.mdDown ? 'medium' : 'large'}
      style={{ color: colorObject.color, fontWeight: 500, backgroundColor: colorObject.bg }}
    />;
  }

  const headerProps = {
    title,
    avatar,
    logoAlt,
    partnerLogoAlt,
    logoSrc,
    partnerLogoSrc,
    breakpoints,
    sidebarVisible
  };

  if (breakpoints.mdUp) {
    return <ConferenceHeaderComponent {...headerProps} fadeOut={fadeOut} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
  } else {
    headerProps.logoSrc = logoMobileSrc;
    headerProps.partnerLogoSrc = partnerLogoMobileSrc;
    headerProps.title = title.length > 50 ? title.trim().substring(0, 50) + '...' : title;
    return <MobileConferenceHeaderComponent {...headerProps} fadeOut={fadeOut}  />;
  }
};

export const ConferenceHeaderComponent: React.FC<IConferenceHeaderProps & { fadeOut?: boolean; onMouseEnter?: () => void; onMouseLeave?: () => void }> = ({
  avatar,
  title,
  logoAlt,
  partnerLogoAlt,
  logoSrc,
  partnerLogoSrc,
  breakpoints,
  sidebarVisible,
  fadeOut,
  onMouseEnter,
  onMouseLeave
}) => {
  const classes = useStyles();
  return (
    <Bx
      px={{ xs: 2, lg: 5 }}
      py={0}
      display="flex"
      className={clsx(classes.root, classes.filled, classes.fade)}
      style={{ opacity: fadeOut ? 0 : undefined }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Bx display="flex" width="100%">
        <Bx display="flex" alignItems="center">
          <ExtendedListItem py={0} avatar={avatar}>
            <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
              <Typo fontWeight={500} color="textPrimary" variant="h2" component="h2">
                {title}
              </Typo>
            </Bx>
          </ExtendedListItem>
        </Bx>
        {!sidebarVisible && (
          <Bx ml={'auto'} display="flex" alignItems="center">
            <Bx display="flex" alignItems="center" justifyContent="flex-start" height="100%">
              <Bx display="flex" flexDirection="column" alignItems="flex-end">
                <Typo variant="body2" component="span" fontSize="body2" style={{ lineHeight: 0.9 }}>
                  powered
                </Typo>
                <Typo variant="body2" component="span" fontSize="body2" style={{ lineHeight: 0.9 }}>
                  by
                </Typo>
              </Bx>
              <Bx ml={2}>
                <img src={logoSrc} alt={logoAlt} style={{ height: 42, display: 'block' }} />
              </Bx>
            </Bx>
            <Bx ml={2} mt={0} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Bx mr={2} position="relative" top={-5}>
                <Typo variant="body2" component="span" style={{ fontSize: 12, textTransform: 'uppercase' }}>
                  Eine Initiative vom
                </Typo>
              </Bx>
              <Bx>
                <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ maxHeight: '50px' }}/>
              </Bx>
            </Bx>
          </Bx>
        )}
      </Bx>
    </Bx>
  );
};

export const MobileConferenceHeaderComponent: React.FC<IConferenceHeaderProps & { fadeOut?: boolean; }> = ({
  title,
  avatar,
  logoAlt,
  partnerLogoAlt,
  logoSrc,
  partnerLogoSrc,
  breakpoints,
  sidebarVisible,
  fadeOut
}) => {
  const classes = useStyles();
  return (
    <MobileHeadBar className={clsx(classes.fade)} style={{ minHeight: 60, opacity: fadeOut ? 0 : undefined, visibility: fadeOut ? 'hidden' : 'visible', top: fadeOut ? -1000 : undefined }} boxShadow={{ xs: 3, md: 3 }}>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center">
        <Grid item xs={9}>
          <ExtendedListItem py={0} avatar={avatar} maxHeight={'48px'} overflow="hidden">
            <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
              <Typo fontWeight={500} color="textPrimary" variant="h1" component="h1" fontSize={breakpoints.mdUp ? 'h3' : 'h5'}>
                {title}
              </Typo>
            </Bx>
          </ExtendedListItem>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Bx
            mr={2}
            display="flex"
            flexDirection={breakpoints.mdUp ? 'row' : 'column'}
            alignItems={breakpoints.mdUp ? 'center' : 'flex-end'}
            justifyContent="flex-start"
            height="100%"
          >
            {!breakpoints.xs && (
              <Typo variant="body2" component="span" fontSize="body3" style={{ marginTop: '-4px' }}>
                powered by
              </Typo>
            )}
            {breakpoints.xs && (
              <Typo variant="body2" component="span" style={{ fontSize: '10px', marginTop: '-4px' }}>
                powered by
              </Typo>
            )}
            <Bx>
              <img src={logoSrc} alt={logoAlt} style={{ height: 32, display: 'block' }} />
            </Bx>
          </Bx>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Bx display="flex" flexDirection="row" justifyContent="flex-start">
            <Typo variant="body2" component="span" style={{ fontSize: 8, lineHeight: 1, textTransform: 'uppercase' }}>
              Eine Initiative vom
            </Typo>
            <Bx pl={.5}>
              <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ height: 42 }} />
            </Bx>
          </Bx>
        </Grid>
      </Bx>
    </MobileHeadBar>
  );
};
