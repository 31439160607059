import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bx } from '@curry-group/mui-curcuma';
import { IConferencingSession } from '../../data/model/session';
import { ConnectedMessages } from '../../../../app/communication/messages';
import { setMessageDraftEdit, setMessageDraftQuotes, setMessageDraftThreadRoot, setUserMessageEmoteRequest, voteMessageRequest } from '../../../../data/reducer/communication';
import { ConnectedCommunicationInput } from '../../../../app/communication/input';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConferenceSidebarFrame } from '../../layout/sidebar-frame';
import { ConferenceIconBtn } from '../../layout/icon-btn';
import { HeadlineContainer } from '../../../headline-container';
import { ConferencingSidebarParticipants } from './participants';
import { IExtendedResolvedParticipant } from '../../interfaces';
import { TSidebarType } from '../../theme';
import { ConferencingSidebarSettings } from './settings';

export const ConferencingSidebar: React.FC<{
  session?: IConferencingSession;
  fullscreen?: boolean;
  sidebarType: TSidebarType;
  currentParticipants?: IExtendedResolvedParticipant[];
  onCloseClick?: () => void;
  onDevicesChange?: (audioDeviceId: string, audioOutDeviceId: string, videoDeviceId: string) => void;
}> = ({
  session,
  fullscreen,
  sidebarType,
  currentParticipants,
  onCloseClick,
  onDevicesChange
}) => {
  const dispatch = useDispatch();
  const conferenceMessage = useSelector(state => state.conferencing.message);
  const communicationDraftThreadRootId = useSelector(state => state.communication.messageDraft?.conference?.threadRoot?.id);
  useEffect(() => {
    if (conferenceMessage?.id !== communicationDraftThreadRootId) {
      dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: conferenceMessage }));
    }
  }, [dispatch, conferenceMessage, communicationDraftThreadRootId]);

  if (sidebarType === 'chat') {
    if (conferenceMessage) {
      return (
        <ConferenceSidebarFrame
          height="100%"
          bgcolor="background.default"
          fullscreen={fullscreen}
          style={{ borderLeft: fullscreen ? undefined : '1px solid #DDDFE0' }}
          bottomBar={
            <ConnectedCommunicationInput
              small
              inputId="conference"
              displayThreadRoot={false}
              removeThreadRoot={() => {
                dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: conferenceMessage }));
              }}
            />
          }
          headBar={
            <HeadlineContainer
              p={0}
              bgcolor="background.paper"
              px={3}
              py={2.5}
              minHeight={81}
              headline={'Diskussionsbeiträge'}
              headlineVariant="h4"
              headlineComponent="h5"
              endAdornment={
                <Bx display="flex">
                  <Bx>
                    <Tooltip title="Chat schließen" arrow>
                      <ConferenceIconBtn semiTransparent onClick={onCloseClick}>
                        <FontAwesomeIcon icon={faTimes} />
                      </ConferenceIconBtn>
                    </Tooltip>
                  </Bx>
                </Bx>
              }
            />
          }
        >
          <Bx height="100%" display="flex" flexDirection="column" flexWrap="nowrap">
            <ConnectedMessages
              p={3}
              scrollContainer={true}
              alias={conferenceMessage.id}
              autoWidth={false}
              quoteClicked={message => {
                dispatch(setMessageDraftQuotes({ quotes: message, id: 'conference' }));
              }}
              emoteClicked={(message, emoji) => {
                dispatch(setUserMessageEmoteRequest({ target: message, emoji }));
              }}
              disableThreading={true}
              forceInlineThreading
              threadRoot={conferenceMessage}
              threadingClicked={message => {
                dispatch(setMessageDraftThreadRoot({ id: 'conference', threadRoot: message }));
              }}
              editMessage={message => {
                dispatch(setMessageDraftEdit({ id: 'conference', message }));
              }}
              voteDownClick={message => {
                dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'conferencing', messageId: message.id, up: false }));
              }}
              voteUpClick={message => {
                dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'conferencing', messageId: message.id, up: true }));
              }}
            />
          </Bx>
        </ConferenceSidebarFrame>
      );
    } else {
      return null;
    }
  } else if (sidebarType === 'participants') {
    return (
      <ConferenceSidebarFrame
        height="100%"
        bgcolor="background.default"
        fullscreen={fullscreen}
        style={{ borderLeft: fullscreen ? undefined : '1px solid #DDDFE0' }}
        headBar={
          <HeadlineContainer
            p={0}
            bgcolor="background.paper"
            px={3}
            py={2.5}
            minHeight={81}
            headline={'Teilnehmende'}
            headlineVariant="h4"
            headlineComponent="h5"
            endAdornment={
              <Bx display="flex">
                <Bx>
                  <Tooltip title="Schließen" arrow>
                    <ConferenceIconBtn semiTransparent onClick={onCloseClick}>
                      <FontAwesomeIcon icon={faTimes} />
                    </ConferenceIconBtn>
                  </Tooltip>
                </Bx>
              </Bx>
            }
          />
        }
      >
        <Bx maxWidth="100%" height="100%" overflowX="auto">
          <ConferencingSidebarParticipants participants={currentParticipants} onCloseClick={onCloseClick} />
        </Bx>
      </ConferenceSidebarFrame>
    );
  } else if (sidebarType === 'device_settings') {
    return (
      <ConferenceSidebarFrame
        maxWidth="100%"
        height="100%"
        bgcolor="background.default"
        fullscreen={fullscreen}
        style={{ borderLeft: fullscreen ? undefined : '1px solid #DDDFE0' }}
        headBar={
          <HeadlineContainer
            p={0}
            bgcolor="background.paper"
            px={3}
            py={2.5}
            height={82}
            headline={'Einstellungen'}
            headlineVariant="h4"
            headlineComponent="h5"
            endAdornment={
              <Bx display="flex">
                <Bx>
                  <Tooltip title="Schließen" arrow>
                    <ConferenceIconBtn semiTransparent onClick={onCloseClick}>
                      <FontAwesomeIcon icon={faTimes} />
                    </ConferenceIconBtn>
                  </Tooltip>
                </Bx>
              </Bx>
            }
          />
        }
      >
        <Bx maxWidth="100%" height="100%" overflowX="auto">
          <ConferencingSidebarSettings
            open={true}
            previewEnabled={!session?.video}
            onCtlAlVisChange={vis => {
              localStorage['__vidConfCtlAlways'] = vis ? '1' : '0';
            }}
            onDevicesChange={(audio, audioOut, video) => {
              onDevicesChange?.(audio, audioOut, video);
            }}
            onCancel={onCloseClick}
            onSave={onCloseClick}
          />
        </Bx>
      </ConferenceSidebarFrame>
    );
  } else {
    return null;
  }
};
