import React, { useEffect, useState } from 'react';
import { Bx, Btn, Typo, CheckboxControl, RadioControl } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { allowedMimeTypes, validateNotEmpty, validateUrl } from '../../../helper/validator';
import { FormDateTime } from '../controls/date-time-select';
import { FormDropZone } from '../controls/dropzone';
import { FormInput, FormValidator } from '../controls/input';
import { FormRTE } from '../controls/rte';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-light-svg-icons';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { CancelAcceptInputDialog } from '../../dialogs/cancel-accept-input';

interface IFormMeeting {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (formField: string, formValue: any, formName?: string) => void;
  onChangeAttachments: (formData: any) => void;
  setValid: (valid: boolean) => void;
  cancelMeeting?: (customMessage?: string) => void;
}

export const FormMeeting: React.FC<IFormMeeting> = ({ formData, headline, subtitle, subtitleComponent, onChange, onChangeAttachments, setValid, cancelMeeting }) => {
  const isMobile = useIsMobile();
  const [customCancelMessageDialog, setCustomCancelMessageDialog] = useState(false);
  const [showExternalMeeting, setShowExternalMeeting] = useState(!formData?.itemData?.location);

  useEffect(() => {
    FormValidator.resetFields('meeting');
  }, [formData?.itemData?.external]);

  if (!!formData?.itemData?.external) {
    return (
      <React.Fragment>
        {headline && (
          <Bx mb={1}>
            <Typo variant="h1" component="h1">
              {headline}
            </Typo>
          </Bx>
        )}
        {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
        {!!subtitleComponent && subtitleComponent}
        <Bx mt={4} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={cancelMeeting ? 8 : 6}>
                  <FormInput
                    value={formData?.itemData?.title}
                    label={'Titel des Meetings'}
                    placeholder={'Titel eingeben'}
                    minLength={5}
                    maxLength={120}
                    onChange={val => {
                      onChange('title', val);
                    }}
                    validator={validateNotEmpty}
                    successMessage=""
                    fieldGroup={'meeting'}
                    lazy
                  />
                </Grid>
                {cancelMeeting && !isMobile && (
                  <Grid item xs={12} lg={4}>
                    <Bx mt={4} display="flex" flexDirection="column" justifyContent="flex-end">
                      <Btn
                        onClick={() => {
                          setCustomCancelMessageDialog(true);
                        }}
                        startIcon={<FontAwesomeIcon icon={faBan} />}
                        color="error"
                        variant="contained"
                        size="large"
                      >
                        Meeting Absagen
                      </Btn>
                    </Bx>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Bx mb={2}>
                <FormDateTime
                  value={formData?.itemData?.interval}
                  onChange={val => {
                    onChange('interval', val);
                  }}
                  setValid={setValid}
                  fieldGroup={'meeting'}
                />
              </Bx>
            </Grid>
            <Grid item xs={12}>
              <Typo variant="h3" component="h3">
                Um welche Art von Meeting handelt es sich?
              </Typo>
            </Grid>
            <Grid item xs={12}>
              <Grid container xs={12}>
                <Grid key={'filter_external_link'} item xs={12}>
                  <Bx pl={1}>
                    <RadioControl
                      label={'Externes Online-Meeting'}
                      size="small"
                      variant="outlined"
                      control={{ color: 'primary' }}
                      highlightLabel
                      onChange={e => {
                        onChange('externalType', 'link');
                        setShowExternalMeeting(true);
                      }}
                      checked={showExternalMeeting}
                    />
                  </Bx>
                </Grid>
                <Grid key={'filter_external_location'} item xs={12}>
                  <Bx pl={1}>
                    <RadioControl
                      label={'Präsenz-Meeting'}
                      size="small"
                      variant="outlined"
                      control={{ color: 'primary' }}
                      highlightLabel
                      onChange={e => {
                        onChange('externalType', 'location');
                        setShowExternalMeeting(false);
                        onChange('externalLinkAcceptedInstructions', false);
                      }}
                      checked={!showExternalMeeting}
                    />
                  </Bx>
                </Grid>
              </Grid>
            </Grid>
            {!showExternalMeeting && <Grid item xs={12}>
              <FormInput
                value={formData?.itemData?.location}
                label={'Ort des Präsenz-Meetings'}
                placeholder={'Ort'}
                minLength={3}
                maxLength={2048}
                onChange={val => {
                  if (!!val) {
                    onChange('externalType', 'location');
                  }
                  onChange('location', val);
                  FormValidator.updateState('Link zum externen Online-Meeting *', true, 'meeting');
                }}
                validator={!!formData?.itemData?.external ? validateNotEmpty : false}
                successMessage=""
                fieldGroup={!!formData?.itemData?.external ? 'meeting' : 'NO_GROUP'}
                lazy
                infotext={'Bitte beachten Sie, dass die Medtec Online-Nutzungsbedingungen nicht für extern durchgeführte Meetings gelten.'}
              />
            </Grid>}
            {showExternalMeeting && <Grid item xs={12}>
              <FormInput
                value={formData?.itemData?.externalLink}
                label={'Link zum externen Online-Meeting *'}
                placeholder={'Externer Link'}
                onChange={val => {
                  if (!!val) {
                    onChange('externalType', 'link');
                  }
                  onChange('externalLink', val);
                  FormValidator.updateState('Ort des Präsenz-Meetings', true, 'meeting');
                }}
                validator={!!formData?.itemData?.external ? validateUrl : false}
                invalidMessage="Die URL muss valide sein und mit http://, oder https:// beginnen!"
                successMessage=""
                fieldGroup={!!formData?.itemData?.external ? 'meeting' : 'NO_GROUP'}
                lazy
                infotext={'Hinweis: Als einladende Person sind Sie für die eingefügten externen Links und die auf diese Weise genutzten Dienste externer Anbieter verantwortlich; dies umfasst auch etwaige notwendige Informationen an die Teilnehmenden, etwa zum Datenschutz. Der Betreiber von Medtec Online tritt insoweit nur als Übermittlungsdienstleister auf. Der verlinkte Dienst ist nicht Teil des Angebots von Medtec Online und richtet sich nach den Bedingungen des externen Anbieters. Medtec Online ist berechtigt, externe Meetings als solche zu kennzeichnen.'}
              />
              <Bx>
                <CheckboxControl
                  control={{
                    value: formData?.itemData?.externalLinkAcceptedInstructions,
                    color: 'primary',
                    name: 'check1',
                    onChange: val => {
                      if (val.target.checked) {
                        onChange('externalType', 'link');
                      }
                      onChange('externalLinkAcceptedInstructions', val.target.checked);
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{margin:0,background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={formData?.itemData?.externalLinkAcceptedInstructions}
                  label={<Typo>Ich habe als einladende Person den Hinweis gelesen und verstanden.</Typo>}
                  
                />
              </Bx>
            </Grid>}
            <Grid item xs={12}>
              <Bx mt={2} mb={2}>
                <FormRTE
                  value={formData?.itemData?.content}
                  label={'Kurzbeschreibung/Agenda'}
                  placeholder={'Inhalte, die Sie in Ihrem Meeting besprechen wollen...'}
                  mandatory={false}
                  minLength={0}
                  maxLength={2500}
                  onChange={val => {
                    onChange('content', val);
                  }}
                  lazy
                />
              </Bx>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormDropZone
                value={formData?.attachments ? formData.attachments : []}
                label={'Anhänge'}
                placeholder={'Hochladen oder Datei(en) hier ablegen'}
                mandatory={false}
                accept={allowedMimeTypes}
                onChange={val => {
                  onChangeAttachments({ ...formData, attachments: val });
                }}
                multiple
              />
            </Grid>
            {cancelMeeting && isMobile && (
              <Grid item xs={12} lg={12}>
                <Bx mt={3} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Btn
                    onClick={() => {
                      setCustomCancelMessageDialog(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faBan} />}
                    color="error"
                    variant="contained"
                    size="large"
                  >
                    Meeting Absagen
                  </Btn>
                </Bx>
              </Grid>
            )}
          </Grid>
        </Bx>
        <CancelAcceptInputDialog
          headerText="Sie können optional einen Grund für die Absage angeben."
          labelText="Grund"
          placeholderText="Bitte einen Grund angeben ..."
          open={customCancelMessageDialog}
          onCanceled={() => {
            setCustomCancelMessageDialog(false);
          }}
          onAccepted={cancelMeeting}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {headline && (
          <Bx mb={1}>
            <Typo variant="h1" component="h1">
              {headline}
            </Typo>
          </Bx>
        )}
        {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
        {!!subtitleComponent && subtitleComponent}
        <Bx mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={cancelMeeting ? 8 : 6}>
                  <FormInput
                    value={formData?.itemData?.title}
                    label={'Titel des Meetings'}
                    placeholder={'Titel eingeben'}
                    minLength={5}
                    maxLength={120}
                    onChange={val => {
                      onChange('title', val);
                    }}
                    validator={validateNotEmpty}
                    successMessage=""
                    fieldGroup={'meeting'}
                    lazy
                  />
                </Grid>
                {cancelMeeting && !isMobile && (
                  <Grid item xs={12} lg={4}>
                    <Bx mt={4} display="flex" flexDirection="column" justifyContent="flex-end">
                      <Btn
                        onClick={() => {
                          setCustomCancelMessageDialog(true);
                        }}
                        startIcon={<FontAwesomeIcon icon={faBan} />}
                        color="error"
                        variant="contained"
                        size="large"
                      >
                        Meeting Absagen
                      </Btn>
                    </Bx>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Bx mb={2}>
                <FormDateTime
                  value={formData?.itemData?.interval}
                  onChange={val => {
                    onChange('interval', val);
                  }}
                  setValid={setValid}
                  fieldGroup={'meeting'}
                />
              </Bx>
            </Grid>
            <Grid item xs={12}>
              <Bx mt={2} mb={2}>
                <FormRTE
                  value={formData?.itemData?.content}
                  label={'Kurzbeschreibung/Agenda'}
                  placeholder={'Inhalte, die Sie in Ihrem Meeting besprechen wollen...'}
                  mandatory={false}
                  minLength={0}
                  maxLength={2500}
                  onChange={val => {
                    onChange('content', val);
                  }}
                  lazy
                />
              </Bx>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormDropZone
                value={formData?.attachments ? formData.attachments : []}
                label={'Anhänge'}
                placeholder={'Hochladen oder Datei(en) hier ablegen'}
                mandatory={false}
                accept={allowedMimeTypes}
                onChange={val => {
                  onChangeAttachments({ ...formData, attachments: val });
                }}
                multiple
              />
            </Grid>
            {cancelMeeting && isMobile && (
              <Grid item xs={12} lg={12}>
                <Bx mt={3} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Btn
                    onClick={() => {
                      setCustomCancelMessageDialog(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faBan} />}
                    color="error"
                    variant="contained"
                    size="large"
                  >
                    Meeting Absagen
                  </Btn>
                </Bx>
              </Grid>
            )}
          </Grid>
        </Bx>
        <CancelAcceptInputDialog
          headerText="Sie können optional einen Grund für die Absage angeben."
          labelText="Grund"
          placeholderText="Bitte einen Grund angeben ..."
          open={customCancelMessageDialog}
          onCanceled={() => {
            setCustomCancelMessageDialog(false);
          }}
          onAccepted={cancelMeeting}
        />
      </React.Fragment>
    );
  }
};
