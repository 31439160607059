import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadFilesModal } from '../../../../components/communication/file-manager/download-modal';
import { closeDownloadFilesModal } from '../../../../data/reducer/communication';

export const ConnectedDownloadFilesModal: React.FC = () => {
  const dispatch = useDispatch();

  const open = useSelector(state => state.communication.downloadFilesActive);
  const preparing = useSelector(state => state.communication.downloadFilesPreparing);

  function close() {
    dispatch(closeDownloadFilesModal({}));
  }

  return <DownloadFilesModal open={open || false} preparing={preparing} close={close} />;
};
