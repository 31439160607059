import clsx from 'clsx';
import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Btn, IBtnProps } from '@curry-group/mui-curcuma';

export interface IFileManagerBtnProps extends Omit<IBtnProps, 'size' | 'color' | 'noXPadding'> {
  active?: boolean;
}

const useStyles = makeStyles(
  theme => ({
    fileManagerBtnRoot: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: -theme.spacing(1),
      marginRight: -theme.spacing(1)
    }
  }),
  {
    name: 'Curcuma'
  }
);

/**
 * The `FileManagerBtn` component is an extension of the `Btn` component.
 */
export const FileManagerBtn: React.FunctionComponent<IFileManagerBtnProps> = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { active, className, ...rest } = props;
  return <Btn color={active ? 'default' : 'default'} variant={active ? 'outlined' : 'text'} className={clsx(classes.fileManagerBtnRoot, className)} ref={ref} {...rest} />;
});
