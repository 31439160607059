import { Bx } from '@curry-group/mui-curcuma';
import React, { ReactNode } from 'react';

export interface IContentWrapperProps {
  header: ReactNode;
}

export const AppFrame: React.FC = ({ children }) => {
  return (
    <Bx
      id="frame"
      display="grid"
      minHeight="calc(var(--vh, 1vh) * 100)"
      gridTemplateAreas={`
"navbar sidebar header sidebar-right"
"navbar sidebar content sidebar-right"
"navbar sidebar footer sidebar-right"
`}
      gridGap={0}
      gridTemplateColumns="auto auto minmax(0, 1fr) auto"
      gridTemplateRows="auto 1fr auto"
    >
      {children}
    </Bx>
  );
};

export { AppFrameNavbar } from './navbar';
export { AppFrameContent } from './content';
export { AppFrameHeader } from './header';
