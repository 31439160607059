import React from 'react';
import { Anohana, Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { Hidden } from '@material-ui/core';
import { faExternalLink, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HoverProfilePictureDialog: React.FC<{
  headerText: string;
  noteToUserText: string;
  labelText: string;
  open: boolean;
  onCanceled?: () => void;
  // onMouseEnter: () => void;
  // onMouseLeave: () => void;
}> = ({ headerText, noteToUserText, labelText, open, onCanceled/*, onMouseEnter, onMouseLeave*/ }) => {
  return (
    <Anohana open={!!open} transition="zoom" disableScrollLock fullWidth maxWidth="sm" onClose={onCanceled}>
      {/* <Bx p={2.5} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}> */}
      <Bx p={2.5}>
        <Bx display="flex" alignItems="center" mb={3}>
          <Typo variant="h4" component="span">
            {headerText || 'Bitte bestätigen Sie'}
          </Typo>
          <Bx ml="auto" mr={-1}>
            <IconBtn onClick={onCanceled}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Bx>
        </Bx>
        
        {noteToUserText && <Bx justifyContent="center" mb={3}>
          <Typo variant="subtitle1" component="span">
            {noteToUserText}
          </Typo>
        </Bx>}
        
        <Bx display="flex" mt={3} justifyContent="flex-end">
          <Hidden smDown>
            <Btn
              color="success" size="large" variant="contained"
              onClick={() => {
                onCanceled?.();
              }}
            >
              {labelText}
            </Btn>
          </Hidden>
          <Hidden mdUp>
            <IconBtn
              color="success" size="medium" variant="contained"
              onClick={() => {
                onCanceled?.();
              }}
              title={labelText}
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </IconBtn>
          </Hidden>
        </Bx>
      </Bx>
    </Anohana>
  );
};
