import React from 'react';
import { useHistory } from 'react-router-dom';
import { Btn, Bx, Ctn, DropDownMenu, IconBtn, IIconBtnProps, TabItem, TabList, Typo } from '@curry-group/mui-curcuma';
import { faArrowLeft, faBookmark as faBookmarkLight, faCameraHome, faCog, faCommentSlash, faEllipsisV, faInfo, faLongArrowLeft, faPlus, faRepeat, faShare, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade, Grid, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { removeDetailUrl, TYPES } from '../../../helper';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { HeadBar } from '../../head-bar';
import { MobileHeadBar } from '../../mobile-head-bar';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setActiveView } from '../../../data/reducer/communication';

export interface IHeaderProps {
  working?: boolean;
  title?: string;
  actions?: Array<ICommunicationAction>;
  metaActions?: Array<ICommunicationAction>;

  tabs?: string[];
  selectedTab?: number;
  selectTab?: (tab: number) => void;
  backButtonAction?: () => void;
  isSettings?: boolean;
  adminMissing?: boolean;
}

export const Header: React.FC<IHeaderProps> =
({
  working,
  title,
  actions,
  metaActions,
  tabs,
  selectedTab,
  selectTab,
  backButtonAction,
  isSettings,
  adminMissing
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const contentScrolled = true;
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectTab?.(newValue);
  };
  return (<>
    <HeadBar backButtonLink={backButtonAction} flexShrink={0} pb={0} zIndex={1} transparent={!contentScrolled} id="content-head-container">
      <Fade in={contentScrolled}>
        <Bx flexShrink={1} flexGrow={1}>
          <Bx height="100%" display="flex" alignItems="center">
            <Ctn>
              <Grid container>
                <Grid md={7} item>
                  <Bx display="flex" alignItems="center" height="100%">
                    {!isSettings && <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                      <IconBtn size="small"
                        onClick={() => {history.goBack();}}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </IconBtn>
                    </Bx>}
                    <Typo component="span" variant="h2">
                      {working ? <Skeleton animation="wave" style={{ width: '200px' }} /> : title ? title : <Skeleton animation="wave" style={{ width: '200px' }} />}
                    </Typo>
                  </Bx>
                </Grid>
                <Grid md={5} item>
                  <Bx display="flex" justifyContent="flex-end" alignItems="center">
                    {actions &&
                      actions.map((action, idx) => {
                        const { specificType, ...validBtnAction } = action;
                        if (specificType === 'IconBtn')
                          return (
                            <Bx key={idx} mr={2}>
                              <IconBtn disabled={working} {...validBtnAction}>
                                {action.icon}
                              </IconBtn>
                            </Bx>
                          );
                        else if (specificType === 'Btn')
                          return (
                            <Bx key={idx} mr={1} display="flex" justifyContent="center" alignItems="center">
                              <Btn {...validBtnAction} startIcon={action.icon}>
                                {action.title}
                              </Btn>
                            </Bx>
                          );
                        else return <></>;
                      })}
                  </Bx>
                </Grid>
                {!!tabs?.length && (
                  <Grid md={5} item>
                    <Bx mt={1.5}>
                      <TabList
                        value={selectedTab}
                        fontVariant="body1"
                        textColor="primary"
                        onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                      >
                        {tabs.map((t, i) => (
                          <TabItem key={i} disabled={working} label={t} />
                        ))}
                      </TabList>
                    </Bx>
                  </Grid>
                )}
                <Grid md={!!tabs?.length ? 7 : 12} item>
                  <Bx mt={1.5} display="flex" justifyContent="flex-end" alignItems="center">
                    {metaActions &&
                      metaActions.map((action, idx) => {
                        const { specificType, ...validBtnAction } = action;
                        if (specificType === 'IconBtn')
                          return (
                            <Bx key={idx} mr={2}>
                              <IconBtn disabled={working} {...validBtnAction}>
                                {action.icon}
                              </IconBtn>
                            </Bx>
                          );
                        else if (specificType === 'Btn')
                          return (
                            <Bx key={idx} mr={1} display="flex" justifyContent="center" alignItems="center">
                              <Btn {...validBtnAction} startIcon={action.icon}>
                                {action.title}
                              </Btn>
                            </Bx>
                          );
                        else return <></>;
                      })}
                  </Bx>
                </Grid>
              </Grid>
            </Ctn>
          </Bx>
        </Bx>
      </Fade>
    </HeadBar>
    {adminMissing && (
      <Bx display="flex" justifyContent="flex-start" px={{ xs: 2, lg: 5 }} py={0} bgcolor={'accent.light'}>
        <Bx
          display="flex"
          justifyContent="flex-start"
          py={1}
          onClick={() => {
            dispatch(setActiveView({ view: 'settings' }));
            dispatch(setActiveTab({tab: {
              title: 'Teilnehmende',
              alias: 'participants',
              type: 'view'
            }}));
          }}
          style={{ cursor: 'pointer' }}
        >
          <Typo variant="body1" color="textSecondary">
            Bitte benennen Sie von den Teilnehmern einen weiteren Administrator.
          </Typo>
        </Bx>
      </Bx>
    )}
  </>);
};

export const MobileHeader: React.FC<IHeaderProps> = ({
  working,
  title,
  actions,
  metaActions,
  tabs,
  selectedTab,
  selectTab,
  backButtonAction,
  adminMissing
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | HTMLButtonElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget.getAttribute('aria-controls'));
    setAnchorEl(event.currentTarget);
  };
  const handleMenu = (menuAction?: (e) => void) => {
    setOpen(null);
    setAnchorEl(null);
    menuAction && menuAction(null);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectTab?.(newValue);
  };
  return (
    <React.Fragment>
      <MobileHeadBar id="content-head-container">
        <Bx display="flex" height="100%" alignItems="center" flexDirection="column" pt={1}>
          <Bx display="flex" alignItems="center" width="100%">
            <Bx zIndex={2}>
              <Bx pr={1}>
                {backButtonAction && (
                  <IconBtn size="medium" onClick={backButtonAction}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </IconBtn>
                )}
                {!backButtonAction && (
                  <IconBtn size="medium" to={window.location.origin + removeDetailUrl(window.location.pathname)}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </IconBtn>
                )}
              </Bx>
            </Bx>
            <Bx display="flex" alignItems="center" height="100%" flexGrow={1} flexShrink={1} overflow="hidden">
              <Typo component="span" variant="h3" noWrap>
                {working ? <Skeleton animation="wave" style={{ width: '200px' }} /> : title ? title : <Skeleton animation="wave" style={{ width: '200px' }} />}
              </Typo>
            </Bx>
            {actions && !!actions.length && (
              <Bx ml="auto" pl={2} flexGrow={0} flexShrink={0} flexBasis="auto">
                <Bx>
                  <IconBtn size="small" onClick={handleClick} color="default" variant="text" aria-controls="more" aria-haspopup="true">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </IconBtn>
                </Bx>
              </Bx>
            )}
          </Bx>
          {!!tabs?.length && (
            <Bx display="flex" alignItems="center" width="100%" mt={1}>
              <TabList
                value={selectedTab}
                fontVariant="body1"
                textColor="primary"
                variant="scrollable"
                onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
              >
                {tabs.map((tab, idx) => (
                  <TabItem key={idx} disabled={working} label={tab} ml={idx ? undefined : 1} />
                ))}
              </TabList>
            </Bx>
          )}
        </Bx>
      </MobileHeadBar>
      {adminMissing && (
        <Bx display="flex" justifyContent="flex-start" px={{ xs: 2, lg: 5 }} py={0} bgcolor={'accent.light'}>
          <Bx
            display="flex"
            justifyContent="flex-start"
            py={1}
            onClick={() => {
              dispatch(setActiveView({ view: 'settings' }));
              dispatch(setActiveTab({tab: {
                title: 'Teilnehmende',
                alias: 'participants',
                type: 'view'
              }}));
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typo variant="body1" color="textSecondary">
              Bitte benennen Sie von den Teilnehmern einen weiteren Administrator.
            </Typo>
          </Bx>
        </Bx>
      )}
      <DropDownMenu
        id={open ? open : undefined}
        elevation={3}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={() => setOpen(null)}
        MenuListProps={{ component: 'div' }}
        open={open !== null}
        borderRadius="borderRadius"
      >
        <Bx bgcolor="primary.main" color="primary.contrastText">
          {actions &&
            actions.map((action, idx) => (
              <MenuItem key={idx} onClick={() => handleMenu(action.onClick)}>
                {action.title}
              </MenuItem>
            ))}
        </Bx>
      </DropDownMenu>
    </React.Fragment>
  );
};

export interface GetMessagesRequest {
  before?: number; // before unix
  since?: number; // since unix

  threadRoot?: string; // thread root

  numThreadChildren?: number; // num thread children

  message?: string; // page with message
}

export interface Tab {
  type: 'messages' | 'files' | 'view';
  title: string;
  alias: string;
}
export interface CommunicationTab extends Tab {
  request?: GetMessagesRequest;
}

export interface ICommunicationAction extends IIconBtnProps {
  icon: JSX.Element;
  specificType: 'IconBtn' | 'Btn';
}

export interface ICommunicationHeaderProps {
  working?: boolean;
  title?: string;

  tabs: Tab[];
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;

  openInfo?: () => void;
  openSettings?: () => void;
  openAddParticipants?: () => void;
  allowConferencing?: boolean;
  onConferenceClick?: () => void;

  oneToOneStatus?: {
    onResendInvitationClick?: () => void;
    onRevokeInvitationClick?: () => void;
  }

  shareClick?: () => void;
  memoryClick?: () => void;
  booked?: boolean;
}
export const CommunicationHeader: React.FC<ICommunicationHeaderProps> = ({
  allowConferencing,
  onConferenceClick,
  working,
  title,
  tabs,
  selectedTab,
  setSelectedTab,
  openInfo,
  openSettings,
  openAddParticipants,
  oneToOneStatus,
  shareClick,
  memoryClick,
  booked,
}) => {
  const communicationActions: Array<ICommunicationAction> = [];
  if (oneToOneStatus) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: oneToOneStatus.onResendInvitationClick,
      icon: <FontAwesomeIcon icon={faRepeat} />,
      title: 'Erneut einladen',
      size: 'small'
    });
  }
  if (oneToOneStatus) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: oneToOneStatus.onRevokeInvitationClick,
      icon: <FontAwesomeIcon icon={faCommentSlash} />,
      title: 'Einladung zurückziehen',
      variant: 'contained',
      color: 'error'
    });
  }
  if (openAddParticipants) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openAddParticipants(),
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      title: 'Teilnehmende einladen',
      size: 'small'
    });
  }
  if (openSettings) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openSettings(),
      icon: <FontAwesomeIcon icon={faCog} />,
      title: 'Einstellungen',
      size: 'small'
    });
  }
  if (openInfo) {
    communicationActions.push({
      specificType: 'IconBtn',
      onClick: () => openInfo(),
      icon: <FontAwesomeIcon icon={faInfo} />,
      title: 'Informationen',
      size: 'small'
    });
  }
  if (allowConferencing && onConferenceClick) {
    if (!openInfo) {
      communicationActions.push({
        specificType: 'IconBtn',
        onClick: () => onConferenceClick(),
        icon: <FontAwesomeIcon icon={faCameraHome} />,
        title: 'Videokonferenz starten',
        variant: 'contained',
        color: 'secondary'
      });
    } else {
      communicationActions.push({
        specificType: 'Btn',
        onClick: () => onConferenceClick(),
        icon: <FontAwesomeIcon icon={faPlus} />,
        title: 'Meeting erstellen',
        variant: 'contained',
        color: 'secondary'
      });
    }
  }
  const metaActions: Array<ICommunicationAction> = [];
  if (shareClick) {
    metaActions.push({
      specificType: 'Btn',
      onClick: () => shareClick(),
      icon: <FontAwesomeIcon icon={faShare} />,
      title: 'Teilen',
      variant: 'contained',
      color: 'primary'
    });
  }
  if (memoryClick) {
    metaActions.push({
      specificType: 'Btn',
      onClick: () => memoryClick(),
      icon: <FontAwesomeIcon icon={booked ? faBookmark : faBookmarkLight} />,
      title: booked ? 'Gemerkt' : 'Merken',
      variant: booked ? 'contained' : 'outlined',
      color: 'primary'
    });
  }

  const HeaderProps: IHeaderProps = {
    working: working,
    title: title,
    actions: communicationActions,
    metaActions: metaActions,
    selectTab: index => setSelectedTab(tabs[index]),
    selectedTab: tabs.indexOf(selectedTab),
    tabs: tabs.map(t => t.title)
  };

  HeaderProps.backButtonAction = undefined;
  return <ConnectedHeader {...HeaderProps} />;
};

export const ConnectedHeader: React.FC<IHeaderProps> = props => {
  const isMobile = useIsMobile();
  const detailType = useSelector(state => state.detail.item?.type);
  const communicationParticipants = useSelector(state => state.communication?.participants);
  const roles = useSelector(state => state.communication?.communication?.roles);
  const userId = useSelector(state => state.foundation?.profile?.userId);

  let adminMissing = false;
  if (communicationParticipants && (detailType === TYPES.GROUP || detailType === TYPES.PROJECT)) {
    const adminRoleId = roles?.find(role => role.type === 'administrator')?.roleId || '';
    const admins = communicationParticipants?.filter(participant => participant.roles.includes(adminRoleId));
    const ownParticipation = communicationParticipants?.find(participant => participant.user === userId);
    if (ownParticipation?.roles?.includes(adminRoleId)) {
      adminMissing = !admins || admins.length < 2;
    }
  }

  if (isMobile) return <MobileHeader {...props} adminMissing={adminMissing} />;
  else return <Header {...props}  adminMissing={adminMissing} />;
};
