import { Bx, SwitchControl, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';

interface IFormUser {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (value: any) => void;
}

export const FormEmailUnsubscribe: React.FC<IFormUser> = ({ formData, headline, subtitle, subtitleComponent, onChange }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const token = formData?.token;
  const messagingPermissions = formData?.itemData?.messagingPermissions ||
    {
      all: true,
      chat: false,
      groupChat: false,
      groupMeeting: false,
      forum: false,
      magazine: false,
    };
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Keine Mails</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.all}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: true, chat: false, groupChat: false, groupMeeting: false, forum: false, magazine: false }
                            : { all: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Chat</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.chat}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: false, chat: true }
                            : { chat: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Gruppen-Chat</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.groupChat}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: false, groupChat: true }
                            : { groupChat: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Gruppen-Meeting</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.groupMeeting}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: false, groupMeeting: true }
                            : { groupMeeting: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Forum</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.forum}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: false, forum: true }
                            : { forum: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                  <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                    <Grid item xs={5}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Magazin</Typo>
                    </Grid>
                    <Grid item xs={1}>
                      <SwitchControl
                        color={'secondary'}
                        checked={messagingPermissions?.magazine}
                        disabled={!token}
                        onChange={e => {
                          const changePermissions = e?.target?.checked
                            ? { all: false, magazine: true }
                            : { magazine: false };
                          onChange({ ...formData, itemData: {
                            ...formData?.itemData,
                            messagingPermissions: { ...formData?.itemData?.messagingPermissions, ...changePermissions },
                            changed: true
                          }});
                        }}
                      />
                    </Grid>
                  </Bx>
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
