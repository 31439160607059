import React from 'react';
import { Bx, InputInfoText, InputLabelTextAdornment, RichTextEditor, Typo } from '@curry-group/mui-curcuma';
import { useDebouncedEffect } from '../../../../helper/hooks/useDebouncedEffect';

interface IFormRTE extends IBreakpoints {
  value: string;
  label: string;
  placeholder: string;
  mandatory: boolean;
  maxLength?: number;
  minLength?: number;
  onChange: (value: string) => void;
  lazy?: boolean;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

export const FormRTE: React.FC<IFormRTE> = ({ value, label, placeholder, mandatory, maxLength, minLength, onChange, isLgUp, isMdUp, isSmUp, lazy }) => {
  const [plain, setPlain] = React.useState<string>(value);
  const [html, setHtml] = React.useState<string>(value);
  useDebouncedEffect(
    () => {
      // if (html === '<p></p>') return;
      onChange(html);
    },
    200,
    [html]
  );
  minLength = minLength || 0;
  maxLength = maxLength || 10000;
  return (
    <Bx mb={2}>
      <Bx mb={1} display="flex" justifyContent="space-between">
        <Typo component="label" variant="body2" fontWeight={500}>
          {label + (mandatory ? ' *' : '')}
        </Typo>
        <InputLabelTextAdornment>
          {plain?.replace(/(\r\n|\n|\r)/gm, '').length || 0}
          {maxLength ? `/${maxLength}` : ''} Zeichen
        </InputLabelTextAdornment>
      </Bx>
      <RichTextEditor htmlValue={html} label={placeholder} maxLength={maxLength} editorHtmlState={setHtml} editorPlainState={setPlain} minHeight={'150px'} />
      {minLength > 0 && (
        <Bx mt={1}>
          <InputInfoText type={plain?.replace(/(\r\n|\n|\r)/gm, '').length > minLength ? 'success' : 'error'}>
            <Typo component='span' variant='body3boosted'>
              {plain?.replace(/(\r\n|\n|\r)/gm, '').length > minLength ? 'Ausgezeichnet!' : `Dieses Eingabefeld sollte mindestens ${minLength} Zeichen haben.`}
            </Typo>
          </InputInfoText>
        </Bx>
      )}
    </Bx>
  );
};
