import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import clsx from 'clsx';
import { rgba } from 'polished';
import { Tooltip } from '@material-ui/core';
import { IconButton, IconButtonProps } from '@material-ui/core';

import { conferenceTheme } from '../../theme';

export interface IConferenceIconBtnProps extends Omit<IconButtonProps, 'size' | 'color'> {
  size?: 'small' | 'large' | 'largeSpecial';
  color?: 'light' | 'dark' | 'red';
  semiTransparent?: boolean;
  outsideRipple?: boolean;
  outsideRippleColor?: string;
  tooltip?: string;
  designType?: string;
}

export interface IConferenceIconBtnThemeExtension {
  conferenceIconBtn: {
    boxShadow?: React.CSSProperties['boxShadow'];
    semiTransparent?: number;
    small?: {
      width?: React.CSSProperties['width'];
      height?: React.CSSProperties['height'];
      fontSize?: React.CSSProperties['fontSize'];
    };
    large?: {
      width?: React.CSSProperties['width'];
      height?: React.CSSProperties['height'];
      fontSize?: React.CSSProperties['fontSize'];
    };
    largeSpecial?: {
      width?: React.CSSProperties['width'];
      height?: React.CSSProperties['height'];
      fontSize?: React.CSSProperties['fontSize'];
    };
    light?: {
      backgroundColor?: React.CSSProperties['backgroundColor'];
      backgroundColorHover?: React.CSSProperties['backgroundColor'];
      color?: React.CSSProperties['color'];
    };
    dark?: {
      backgroundColor?: React.CSSProperties['backgroundColor'];
      backgroundColorHover?: React.CSSProperties['backgroundColor'];
      color?: React.CSSProperties['color'];
    };
    red?: {
      backgroundColor?: React.CSSProperties['backgroundColor'];
      backgroundColorHover?: React.CSSProperties['backgroundColor'];
      color?: React.CSSProperties['color'];
    };
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: (props: IConferenceIconBtnProps) => (!props.semiTransparent ? conferenceTheme.conferenceIconBtn?.boxShadow : undefined),
    '&:hover': {
      boxShadow: conferenceTheme.conferenceIconBtn?.boxShadow
    }
  },
  small: {
    width: conferenceTheme.conferenceIconBtn?.small?.width,
    height: conferenceTheme.conferenceIconBtn?.small?.height,
    fontSize: conferenceTheme.conferenceIconBtn?.small?.fontSize
  },
  large: {
    width: conferenceTheme.conferenceIconBtn?.large?.width,
    height: conferenceTheme.conferenceIconBtn?.large?.height,
    fontSize: conferenceTheme.conferenceIconBtn?.large?.fontSize
  },
  largeSpecial: {
    width: conferenceTheme.conferenceIconBtn?.largeSpecial?.width,
    height: conferenceTheme.conferenceIconBtn?.largeSpecial?.height,
    fontSize: conferenceTheme.conferenceIconBtn?.largeSpecial?.fontSize,
    borderRadius: '15%'
  },
  light: {
    backgroundColor: (props: IConferenceIconBtnProps) =>
      props.semiTransparent
        ? conferenceTheme.conferenceIconBtn?.light?.backgroundColor
          ? rgba(conferenceTheme.conferenceIconBtn?.light?.backgroundColor, conferenceTheme.conferenceIconBtn?.semiTransparent)
          : undefined
        : conferenceTheme.conferenceIconBtn?.light?.backgroundColor,
    color: conferenceTheme.conferenceIconBtn?.light?.color,
    '&:hover': {
      backgroundColor: conferenceTheme.conferenceIconBtn?.light?.backgroundColorHover
    }
  },
  dark: {
    backgroundColor: (props: IConferenceIconBtnProps) =>
      props.semiTransparent
        ? conferenceTheme.conferenceIconBtn?.dark?.backgroundColor
          ? rgba(conferenceTheme.conferenceIconBtn?.dark?.backgroundColor, conferenceTheme.conferenceIconBtn?.semiTransparent)
          : undefined
        : conferenceTheme.conferenceIconBtn?.dark?.backgroundColor,
    color: conferenceTheme.conferenceIconBtn?.dark?.color,
    '&:hover': {
      backgroundColor: conferenceTheme.conferenceIconBtn?.dark?.backgroundColorHover
    }
  },
  red: {
    backgroundColor: (props: IConferenceIconBtnProps) =>
      props.semiTransparent
        ? conferenceTheme.conferenceIconBtn?.red?.backgroundColor
          ? rgba(conferenceTheme.conferenceIconBtn?.red?.backgroundColor, conferenceTheme.conferenceIconBtn?.semiTransparent)
          : undefined
        : conferenceTheme.conferenceIconBtn?.red?.backgroundColor,
    color: conferenceTheme.conferenceIconBtn?.red?.color,
    '&:hover': {
      backgroundColor: conferenceTheme.conferenceIconBtn?.red?.backgroundColorHover
    }
  },
  halfButtonRight: {
    borderRadius: '50% 0 0 50%',
  },
  outsideRipple: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '50%',
      borderWidth: 10,
      borderStyle: 'solid',
      borderColor: (props: IConferenceIconBtnProps) => _.get(theme.palette, props.outsideRippleColor ?? '') || props.outsideRippleColor,
      animation: '$outsideRippleAnimation 2s ease infinite'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '50%',
      borderWidth: 10,
      borderStyle: 'solid',
      borderColor: (props: IConferenceIconBtnProps) => _.get(theme.palette, props.outsideRippleColor ?? '') || props.outsideRippleColor,
      animation: '$outsideRippleAnimation 2s ease infinite .5s'
    }
  },
  '@keyframes outsideRippleAnimation': {
    from: {
      transform: 'scale(1)',
      borderWidth: 10,
      opacity: 1
    },
    to: {
      transform: 'scale(1.5)',
      borderWidth: 0,
      opacity: 0
    }
  }
}));

export const ConferenceIconBtn: React.FunctionComponent<IConferenceIconBtnProps> = React.forwardRef(
  ({ color, size, semiTransparent, outsideRipple, outsideRippleColor, children, className, tooltip, designType, ...rest }, ref) => {
    const classes = useStyles({ semiTransparent, outsideRippleColor });
    return (
      <Tooltip title={tooltip || ''} arrow>
        <IconButton
          ref={ref}
          className={clsx(
            classes.root,
            size === 'small' && classes.small,
            size === 'large' && classes.large,
            size === 'largeSpecial' && classes.largeSpecial,
            color === 'light' && classes.light,
            color === 'dark' && classes.dark,
            color === 'red' && classes.red,
            designType === 'halfButtonRight' && classes.halfButtonRight,
            outsideRipple && classes.outsideRipple,
            className
          )}
          {...rest}
          title={undefined}
        >
          {children}
        </IconButton>
      </Tooltip>
    );
  }
);

ConferenceIconBtn.defaultProps = {
  size: 'small',
  color: 'light',
  outsideRippleColor: '#fff'
};
