import React from 'react';
import { Anohana, Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser } from '@fortawesome/pro-light-svg-icons';
import Hidden from '@material-ui/core/Hidden';

export const OpenNewsInfoDialog: React.FC<{
  headerText: string;
  noteToUserText?: string;
  maturityText?: string;
  link?: string;
  labelText?: string;
  open: boolean;
  onClick?: () => void;
  onCanceled?: () => void;
}> = ({ headerText, noteToUserText, maturityText, open, labelText, onClick, onCanceled }) => {
  return (
    <Anohana open={!!open} transition="zoom" disableScrollLock fullWidth maxWidth="sm" onClose={onCanceled}>
      <Bx p={2.5}>
        <Bx display="flex" alignItems="center" mb={3}>
          <Typo variant="h4" component="span">
            {headerText || 'Bitte bestätigen Sie.'}
          </Typo>
          <Bx ml="auto" mr={-1}>
            <IconBtn onClick={onCanceled}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Bx>
        </Bx>
        
        {noteToUserText && <Bx justifyContent="center" mb={3}>
          <Typo variant="subtitle1" component="span">
            {noteToUserText}
            <br />
            {maturityText}
          </Typo>
        </Bx>}

        <Bx display="flex" mt={3} justifyContent="flex-end">
          <Hidden smDown>
            <Btn
              color="success" size="large" variant="contained"
              startIcon={<FontAwesomeIcon icon={faUser} />}
              onClick={onClick}
            >
              {labelText}
            </Btn>
          </Hidden>
          <Hidden mdUp>
            <IconBtn
              color="success" size="medium" variant="contained"
              onClick={onClick}
              title={labelText}
            >
              <FontAwesomeIcon icon={faUser} />
            </IconBtn>
          </Hidden>
        </Bx>
      </Bx>
    </Anohana>
  );
};
