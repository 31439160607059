import {
  GetAuthorDisplayName,
  GetActorType,
  GetActorColor,
  GetProfileDisplayName,
  removeHTMLTagsSpecial,
  GetThingTypeName,
  TYPES_PROPS,
  GetActorFallbackImage,
  assetUrl,
  resolveTimestamp,
} from '../../helper';
import {
  faCloudDownload,
  faExternalLink,
  faNewspaper,
  faFlaskPotion,
  faBuilding,
  faBan,
  faMicroscope,
  faHospitalAlt,
  faBriefcase,
  faTicketAlt,
  faExclamationSquare,
} from '@fortawesome/pro-light-svg-icons';
import { IListItem } from '../list/ListItem';
import { IDetail } from '../data/Detail';
import { ISearchEntry } from '../search/SearchEntry';
import { ICategoryRef } from './Category';
import { IPageElement, PageElementContentSelection, PageElementVisualization } from '../data/PageElement';
import { ICommunicationModel } from '../communication/Communication';
import { buildAbbreviation } from '../../helper/user';
import { getProfileRouteByUserId } from '../../helper';
import { ISearchResultItem } from '../search/SearchResultItem';
import { shorten } from '@curry-group/mui-curcuma';

export interface IThing<T = any> {
  categories: ICategoryRef[];
  communication?: ICommunicationModel;
  content: T;
  type: string;
  _id: string;
  _score: number;
  createdAt?: string;
  modifiedAt?: string;
  createdBy?: string;
  modifiedBy?: string;
  releaseDate?: number;
  seo?: { alias: string };
  keyvisual?: any;
  avatar?: any;
  chatAllowed?: boolean;
}

export const GetListDateByType = (type: string, timeThing: any, listTemplate: string): string => {
  if (!timeThing) {
    return '';
  }
  switch (type) {
    case ThingTypes.Actors:
      const timestampActor = timeThing.content?.timestampFirstReg;
      if (!timestampActor) {
        return '';
      }
      return `aktiv seit ${resolveTimestamp(timestampActor * 1000, false, true, false, true, true)}`;
    case ThingTypes.Profile:
      const timestampProfile = listTemplate === 'members' ? timeThing.modifiedAt : timeThing.createdAt;
      return `${listTemplate === 'members' ? 'beigetreten' : 'registriert'} seit ${resolveTimestamp(timestampProfile, false, true, false, true, true)}`;
    case ThingTypes.Meeting:
      const timestampCreate = timeThing.createdAt;
      const timestampMod = timeThing.modifiedAt;
      if (timestampCreate === timestampMod) {
        return `erstellt ${resolveTimestamp(timestampMod, false, false, true, true)}`;
      } else {
        return `aktualisert ${resolveTimestamp(timestampMod, false, false, true, true)}`;
      }
    case ThingTypes.OneToOne:
    case ThingTypes.Group:
    case ThingTypes.Project:
    case ThingTypes.Idea:
    case ThingTypes.QnA:
    case ThingTypes.Requisition:
    case ThingTypes.Blog:
    case ThingTypes.Wiki:
    case ThingTypes.BestPractice:
      const timestamp = timeThing.modifiedAt;
      return `letzte Aktivität ${resolveTimestamp(timestamp, false, false, true, true)}`;
    default:
      const timestampDefault = timeThing.modifiedAt;
      return resolveTimestamp(timestampDefault, false, true, false, true);
  }
};

export class ThingTypes {
  static OneToOne = '00000000-0000-0000-0002-000000000000';
  static Group = '6933b507-e181-4875-99f7-6d1b46f1a2f9';
  static Project = '98596b5c-4b8b-420d-9f87-4658ac9f063f';
  static Meeting = '3830919f-405f-4202-8358-b96f82a11940';
  static News = 'eac2dc7a-1b9b-4f6a-89ca-daad3a2a18a1';

  static Notification = '00000000-0000-0000-0000-000000000002';

  static Idea = 'd3f08006-9a61-4ef2-891f-522d92885b96'; // Idee
  static QnA = '3efbd8f0-f7ef-4946-b3ae-22441540653d'; // Frage
  static Requisition = '45b3e9e4-829f-4c06-b99e-af329f568b09'; // Bedarf

  static Blog = 'bc624cbe-be13-4fa3-aa99-0b43fa462731'; // Blogbeitrag
  static Wiki = 'dfe6a977-2982-4e47-a023-a477841958a3'; // Wiki
  static BestPractice = '26fa1adc-fbcb-4073-b683-6bd9df1b6a33'; // Best Practice

  static Collection = '7b2a21f3-6a9e-4af0-8913-298f49e0738d';
  static StrategicDialog = '7b2a21f3-6a9e-4af0-8913-298f49e0738d';
  static SharedObject = '6ede85e6-d6a6-4daf-a788-a29aae2ada83';
  static Actors = '0fc077e7-fcd2-40c7-a57b-590e2b01bdb4';
  static Profile = '00000000-0000-0000-0000-000000000001';
  static ContentPage = 'c8490295-b777-43d8-9ff7-107443ed3f79';
}

export interface IUniversalRepeaterItem {
  elementId: string;
  type: string;
  typeId: string;
}

export interface IMemoryListItem extends IUniversalRepeaterItem {
  externalId?: string;
}

//#region # # # # # ListMapping # # # # #
export function transformForList(searchResults?: ISearchResultItem[], detailedView?: boolean): IListItem[] {
  if (!searchResults || !searchResults.length || !searchResults.filter(x => !!x._source).length) return [];
  return searchResults
    .filter(x => !!x._source)
    .map(hit => {
      const item = hit._source;
      switch (item.type) {
        case ThingTypes.OneToOne:
          return toOneToOneListItem(item, hit._id, hit._score, hit.badge, hit.booked, hit.profileId);
        case ThingTypes.Group:
          return toGroupListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Project:
          return toProjectListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Meeting:
          return toMeetingListItem(item, hit._id, hit._score, detailedView);
        case ThingTypes.Idea:
          return toIdeaListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.QnA:
          return toQnAListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Requisition:
          return toRequisitionListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Blog:
          return toBlogListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.BestPractice:
          return toBestPracticeListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Collection:
          return toCollectionListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Wiki:
          return toWikiListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Profile:
          return toProfileListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.Actors:
          return toActorListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        case ThingTypes.SharedObject:
          return toSharedObjectItem(item, item._id, 0);
        case 'OTT':
          return toOTTListItem(item, hit._id, hit._score);
        case ThingTypes.News:
          return toNewsListItem(item, hit._id, hit._score, hit.badge, hit.booked);
        default:
          return toFallbackItem(item, hit._id, hit._score);
      }
    });
}

export function transformForCard(searchResults?: ISearchResultItem[]): IListItem[] {
  if (!searchResults || !searchResults.length || !searchResults.filter(x => x._source !== undefined).length) return [];
  return searchResults.map(hit => {
    const item = hit._source;
    switch (item.type) {
      case ThingTypes.Idea:
        return toIdeaCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.QnA:
        return toQnACardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Requisition:
        return toRequisitionCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Blog:
        return toBlogCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.BestPractice:
        return toBestPracticeCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Collection:
        return toCollectionCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Group:
        return toGroupCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Project:
        return toProjectCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Wiki:
        return toWikiCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Profile:
        return toProfileCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.News:
        return toNewsCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      case ThingTypes.Actors:
        return toActorCardItem(item, hit._id, hit._score, hit.badge, hit.booked);
      default:
        return toFallbackItem(item, hit._id, hit._score);
    }
  });
}

export function transformForActivityStream(items?: IThing<ISearchEntry>[]): IListItem[] {
  if (!items || !items.length) return [];
  return items.map(item => {
    switch (item.type) {
      case ThingTypes.SharedObject:
        return toSharedObjectItem(item, item._id, 0);
      default:
        return toFallbackItem(item, item._id, 0);
    }
  });
}

// OneToOne
export function toOneToOneListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean, profileId?: string): IListItem {
  let fullName = item.content?.partner?.content?.fullName;
  let avatarId = item.content?.partner?.content?.avatar;
  if (!!profileId && !!item.content?.otoCreatorProfileId && !!item.content?.otoPartnerProfileId) {
    const isCreator = profileId === item.content?.otoCreatorProfileId;
    if (isCreator) {
      fullName = item.content?.otoPartnerFullName;
      avatarId = item.content?.otoPartnerAvatar;
    } else {
      fullName = item.content?.otoCreatorFullName;
      avatarId = item.content?.otoCreatorAvatar;
    }
  }
  const abbrev = buildAbbreviation(fullName) || 'Chat';
  const listItem: IListItem = {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: fullName || 'Chat',
    type: 'Chat',
    color: 'secondary',
    avatar: {
      type: 'user',
      image: assetUrl(avatarId, true),
      abbreviation: abbrev,
    },
    timestamp: item.modifiedAt,
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
  return listItem;
}

// Project
export function toProjectListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  const listItem: IListItem = {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: 'Projekt',
    color: 'secondary',
    avatar: {
      type: 'icon',
      icon: faFlaskPotion,
    },
    timestamp: item.modifiedAt,
    isPrivate: !item.content?.public,
    isHidden: !item.content?.listed,
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
  if (item.content?.avatar) {
    listItem.avatar = {
      type: 'image',
      icon: faFlaskPotion,
      image: assetUrl(item.content.avatar, true),
    };
  }
  return listItem;
}

export function toProjectCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: 'Projekt',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    color: 'secondary',
    avatar: {
      type: 'none',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Meeting
export function toMeetingListItem(item: IThing<ISearchEntry>, _id: string, _score: number, detailedView?: boolean): IListItem {
  const dateFrom = new Date((item.content.interval?.dateFrom || 0) * 1000);
  const dateTo = new Date((item.content.interval?.dateTo || 0) * 1000);
  const parent = item.content.parentThingResolved ? item.content.parentThingResolved : item.content.parentThing ? [item.content.parentThing] : [];
  const listItem: IListItem = {
    _id: _id,
    _score: _score,
    __original: item,
    parentItem:
      parent &&
      transformForList(
        parent.map(parentThing => {
          return {
            _source: parentThing,
            _id: _id,
            _score: 0,
          };
        })
      )?.[0],
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: item.content.cancelled
      ? 'Abgesagtes Meeting'
      : 'Meeting' + (detailedView && item.content.interval?.dateFrom ? ': ' + resolveTimestamp(item.content.interval.dateFrom * 1000, true, true) : ''),
    color: item.content.cancelled ? 'error' : 'primary',
    avatar: {
      type: item.content.cancelled ? 'icon' : 'time',
      icon: faBan,
      time: {
        from: dateFrom.toISOString(),
        to: dateTo.toISOString(),
      },
    },
    timestamp: item.modifiedAt,
    isPrivate: !item.content?.public,
    isHidden: !item.content?.listed,
    categories: item.categories?.map(cat => cat.categoryId),
  };
  return listItem;
}

export function toMeetingCardItem(item: IThing<ISearchEntry>, _id: string, _score: number): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: 'Meeting',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    color: 'secondary',
    avatar: {
      type: 'time',
      time: { from: '0', to: '0' },
    },
    categories: item.categories?.map(cat => cat.categoryId),
  };
}

// Group
export function toGroupListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  const listItem: IListItem = {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: 'Arbeitsgruppe',
    color: 'primary',
    avatar: {
      type: 'icon',
      icon: faFlaskPotion,
    },
    timestamp: item.modifiedAt,
    isPrivate: !item.content?.public,
    isHidden: !item.content?.listed,
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
  if (item?.content?.avatar) {
    listItem.avatar = {
      type: 'image',
      icon: faFlaskPotion,
      image: assetUrl(item.content.avatar, true),
    };
  }
  return listItem;
}

export function toGroupCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.title,
    type: 'Arbeitsgruppe',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    color: 'primary',
    avatar: {
      type: 'none',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Idea
export function toIdeaListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Idee',
    color: 'secondary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Antworten' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.createdBy || '',
      generalUserStatus: item?.content?.creator?.generalUserStatus || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Idea
export function toIdeaCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: removeHTMLTagsSpecial(item.content?.content || ''),
    type: 'Idee',
    color: 'secondary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'comments',
      comments: item?.content.commentCount,
      commentsCaption: 'Antworten',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// QnA
export function toQnAListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Frage',
    color: 'primary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Antworten' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.createdBy || '',
      generalUserStatus: item?.content?.creator?.generalUserStatus || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toQnACardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: removeHTMLTagsSpecial(item.content?.content || ''),
    type: 'Frage',
    color: 'primary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'comments',
      comments: item?.content.commentCount,
      commentsCaption: 'Antworten',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Requisition
export function toRequisitionListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Bedarf',
    color: 'quaternary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Antworten' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.createdBy || '',
      generalUserStatus: item?.content?.creator?.generalUserStatus || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toRequisitionCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: removeHTMLTagsSpecial(item.content?.content || ''),
    type: 'Bedarf',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    color: 'quaternary',
    avatar: {
      type: 'comments',
      comments: item?.content.commentCount,
      commentsCaption: 'Antworten',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Blog
export function toBlogListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Blogbeitrag',
    color: 'primary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Kommentare' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toBlogCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: item.content.subtitleStripped,
    type: 'Blogbeitrag',
    color: 'primary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Best Practice
export function toBestPracticeListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Best Practice',
    color: 'quaternary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Kommentare' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toBestPracticeCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Best Practice',
    color: 'quaternary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Collection
export function toCollectionListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: GetThingTypeName(item.type, item.content?.strategicDialog),
    color: item.content?.strategicDialog ? 'quaternary' : 'secondary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Kommentare' : '',
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toCollectionCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: item.content?.contentStripped,
    type: GetThingTypeName(item.type, item.content?.strategicDialog),
    color: item.content?.strategicDialog ? 'quaternary' : 'secondary',
    backgroundSrc: item.content?.strategicDialog ? TYPES_PROPS[item.type].FallbackImage2 : TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Wiki
export function toWikiListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    type: 'Wikibeitrag',
    color: 'primary',
    subtitle: item.content?.commentCount ? item.content?.commentCount + ' Kommentare' : '',
    avatar: {
      type: 'user',
      image: assetUrl(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || item?.content?.creator?.avatar || '', true),
      abbreviation: buildAbbreviation(item?.content?.authorInfoSubD?.[0]?.authorInfoAuthor || item?.content?.creator?.fullName || item?.content?.title),
      avatarUserId: item?.content?.authorInfoSubD?.[0]?.authorInfoAuthorSystem || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toWikiCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.name || item.content?.title,
    subtitle_2: item.content?.definitionStripped,
    type: 'Wikibeitrag',
    color: 'primary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toSharedObjectItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean): IListItem {
  const connectedEntry = ((!!item.content.connectedEntryResolved &&
    transformForList(
      [
        {
          _source: item.content.connectedEntryResolved[0] || item.content.connectedEntryResolved,
          _id: '',
          _score: 0,
        },
      ],
      true
    )) ||
    [])[0];
  return {
    __original: item,
    description: item.content?.content,
    title: item.content.creator?.fullName || 'Ehemaliger Benutzer',
    _id: item.content.creator?.userId || '',
    timestamp: item.createdAt,

    parentItem: connectedEntry?.parentItem,

    typeId: connectedEntry?.typeId || '',
    alias: connectedEntry?.alias || '',
    subtitle: connectedEntry?.title || 'Geteilter Inhalt nicht mehr verfügbar',
    type: TYPES_PROPS[connectedEntry?.typeId]?.Name || '',
    color: connectedEntry?.color || 'error',
    avatar: connectedEntry?.avatar || {
      type: 'icon',
      icon: faNewspaper,
    },
    to: connectedEntry?.to,
  };
}

// Profile
export function toProfileListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  if (!item.content) {
    return {
      to: '#',
      _id: _id,
      _score: _score,
      __original: item,
      typeId: item.type,
      alias: '',
      title: 'Ehemaliger Benutzer',
      type: '',
      color: 'primary',
      subtitle: '',
      avatar: {
        type: 'user',
      },
      badge: badge,
      categories: item.categories?.map(cat => cat.categoryId),
    };
  }
  return {
    _id: item.content.userId || '',
    _score: _score,
    __original: { ...item, _id },
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.fullName || item.content?.title,
    type: '',
    color: 'primary',
    subtitle: !item.content?.organisation ? '' : `${item.content?.organisation}${item.content?.department ? ' - ' + item.content?.department : ''}`,
    avatar: {
      type: 'user',
      image: item?.content?.avatar ? assetUrl(item?.content?.avatar, true) : undefined,
      abbreviation: buildAbbreviation(item?.content?.fullName || item?.content?.title),
      generalUserStatus: item?.content?.generalUserStatus,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toProfileCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    to: getProfileRouteByUserId(item.content?.userId || ''),
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: item.content?.fullName || item.content?.title,
    subtitle: item.content?.function || '',
    subtitle_2: !item.content?.organisation ? '' : `${item.content?.organisation}${item.content?.department ? ' - ' + item.content?.department : ''}`,
    type: '',
    color: 'primary',
    backgroundSrc: {
      src: item?.content?.keyvisual ? assetUrl(item.content.keyvisual, true) || '' : TYPES_PROPS[item.type].FallbackImage?.src || '',
      alt: 'Profil Hintergrundbild',
    },
    avatar: {
      type: 'user',
      image: item?.content?.avatar ? assetUrl(item?.content?.avatar, true) : undefined,
      generalUserStatus: item?.content?.generalUserStatus || '',
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Actor
export function toActorListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  const type = {
    type: '',
    icon: faBuilding,
  };
  let itemTitle = item.content?.name || item.content?.title;
  switch (item.content?.type) {
    case 'scientific institution':
      type.type = 'Wissenschaft';
      type.icon = faMicroscope;
      itemTitle = (item.content?.university_id?.content?.name ? item.content?.university_id?.content?.name + ' - ' : '') + itemTitle;
      break;
    case 'company':
      type.type = 'Unternehmen';
      type.icon = faBuilding;
      break;
    case 'health institution':
      type.type = 'Leistungserbringer';
      type.icon = faHospitalAlt;
      itemTitle = (item.content?.hospital_id?.content?.name ? item.content?.hospital_id?.content?.name + ' - ' : '') + itemTitle;
      break;
    case 'other organization':
      type.type = 'Sonstige';
      type.icon = faBriefcase;
      break;
  }
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: itemTitle,
    type: type.type,
    subtitle: item.content?.city,
    color: 'default',
    avatar: {
      type: 'icon',
      icon: type.icon,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

export function toActorCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: (item.content?.university_id?.content?.name ? item.content?.university_id?.content?.name + ' - ' : '') + (item.content?.name || item.content?.title),
    description: item.content?.city,
    type: GetActorType(item.content?.subtype || item.content?.type || ''),
    color: GetActorColor(item.content?.type || ''),
    backgroundSrc: GetActorFallbackImage(item.content?.type),
    avatar: {
      type: 'user',
      image: item.content.previewImage,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// News
export function toNewsListItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  let bodyText = '';
  if (item.type === ThingTypes.News) {
    const pl = item.content?.permissionLevel;
    switch (pl) {
      case 1:
        bodyText = item.content?.description ? shorten(item.content?.description + ' ', 500) : shorten(item.content?.body + ' ', 500);
        break;
      case 2:
        bodyText = item.content?.description ? shorten(item.content?.description + ' ', 500) : '';
        break;
      case 3:
      case 4:
      default:
        break;
    }
  }
  return {
    _id: item.content?.id || _id,
    _score: item.content?.score,
    __original: item,
    typeId: item.type,
    type: 'News',
    alias: item.seo?.alias,
    title: item.content?.title,
    source: item?.content?.sourcePreferredLabel,
    avatar: {
      type: 'icon',
      icon: faNewspaper,
    },
    body: bodyText,
    to: item?.content?.url,
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
    itemTemplate: 'news',
  };
}
export function toNewsCardItem(item: IThing<ISearchEntry>, _id: string, _score: number, badge?: boolean, booked?: boolean): IListItem {
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.type,
    alias: item.seo?.alias,
    title: (item.content?.university_id?.content?.name ? item.content?.university_id?.content?.name + ' - ' : '') + (item.content?.name || item.content?.title),
    description: item.content?.city,
    type: 'News',
    color: 'primary',
    backgroundSrc: TYPES_PROPS[item.type].FallbackImage,
    avatar: {
      type: 'user',
      image: item.content.previewImage,
    },
    badge: badge,
    categories: item.categories?.map(cat => cat.categoryId),
    booked: booked || false,
  };
}

// Reference
export function toReferenceListItem(item: { title: string; content: string; url: string; download?: boolean; anchor?: string }): IListItem {
  return {
    __original: item,
    _id: '',
    typeId: '0',
    to: item.url,
    target: '_blank',
    type: item.title,
    title: item.content,
    color: 'tertiary',
    avatar: {
      type: 'icon',
      icon: item.download ? faCloudDownload : faExternalLink,
    },
    anchor: item.anchor,
  };
}

// OTT
export function toOTTListItem(item: IThing<ISearchEntry>, _id: string, _score: number): IListItem {
  const type = {
    type: 'OTT',
    icon: faTicketAlt,
  };
  let tokenBlockedError = false;
  const repeatCount = item.content?.repeatCount || 0;
  switch (item.content?.processId) {
    case ThingTypes.Profile: {
      if (repeatCount >= 3) {
        tokenBlockedError = true;
      }
      break;
    }
    case 'lost-password': {
      if (repeatCount >= 3) {
        tokenBlockedError = true;
      }
      break;
    }
    case 'login-failed': {
      if (repeatCount >= 10) {
        tokenBlockedError = true;
      }
      break;
    }
    case 'mail-deactivation-token': {
      if (repeatCount >= 5) {
        tokenBlockedError = true;
      }
      break;
    }
    default:
      break;
  }
  return {
    _id: _id,
    _score: _score,
    __original: item,
    typeId: item.content?.processId || ThingTypes.Profile,
    alias: item.seo?.alias,
    title: (tokenBlockedError ? 'BLOCKED ' : '') + item.content?.title,
    subtitle: '(TokenId: ' + _id + ')',
    type: type.type,
    color: tokenBlockedError ? 'error' : 'default',
    avatar: {
      type: 'icon',
      icon: tokenBlockedError ? faExclamationSquare : type.icon,
    },
    tokenRepeatCount: repeatCount,
  };
}

// Fallback
export function toFallbackItem(item?: IThing<ISearchEntry>, _id?: string, _score?: number): IListItem {
  return {
    _id: _id || '',
    _score: _score || 0,
    __original: item,
    typeId: item?.type || '',
    alias: item?.seo?.alias,
    title: item?.content ? item.content?.name || item.content?.title : 'N/A',
    type: '',
    color: 'primary',
    avatar: {
      type: 'image',
      image: item?.content && item?.content?.previewImage,
    },
  };
}
//#endregion # # # # # ListMapping # # # # #

export function getAuthor(authorInfoSubD?: Array<any>, creator?: any): IDetail['authors'] {
  if (authorInfoSubD && authorInfoSubD.length > 0) {
    return authorInfoSubD.map(author => {
      return {
        name: GetAuthorDisplayName(author),
        abbreviation: !!author.profile ? buildAbbreviation(author.profile?.firstName + ' ' + author.profile?.lastName) : buildAbbreviation(author.authorInfoAuthor),
        organization: author.authorInfoOrganisation,
        avatar: author.profile?.avatar,
        id: author.authorInfoAuthorSystem,
        generalUserStatus: author.generalUserStatus || author.profile?.generalUserStatus || '',
      };
    });
  } else if (creator)
    return [
      {
        name: GetProfileDisplayName(creator),
        abbreviation: buildAbbreviation(creator?.fullName),
        organization: undefined,
        avatar: creator?.avatar,
        id: creator?.userId,
        generalUserStatus: creator?.generalUserStatus || creator?.generalUserStatus || '',
      },
    ];

  return [
    {
      name: 'Ehemaliger Benutzer',
      abbreviation: 'EB',
    },
  ];
}

export function interactionButtonSettings(type?: string): { hideVoting?: boolean; hideComments?: boolean; hideShare?: boolean } {
  if (!type)
    return {
      hideVoting: true,
      hideComments: true,
      hideShare: true,
    };
  switch (type) {
    case ThingTypes.Idea:
    case ThingTypes.QnA:
    case ThingTypes.Requisition:
      return {
        hideVoting: false,
        hideComments: false,
        hideShare: false,
      };
    case ThingTypes.BestPractice:
    case ThingTypes.Blog:
    case ThingTypes.Wiki:
    case ThingTypes.Profile:
    case ThingTypes.Project:
    case ThingTypes.Group:
      return {
        hideVoting: true,
        hideComments: false,
        hideShare: false,
      };
    case ThingTypes.StrategicDialog:
    case ThingTypes.Collection:
    case ThingTypes.Actors:
    case ThingTypes.Meeting:
      return {
        hideVoting: true,
        hideComments: true,
        hideShare: false,
      };
    case ThingTypes.ContentPage:
    default:
      return {
        hideVoting: true,
        hideComments: true,
        hideShare: true,
      };
  }
}

export function getPageElements(type: string): IPageElement[] | undefined {
  if (!type) return;
  switch (type) {
    case ThingTypes.Group:
      return;
    case ThingTypes.Idea:
    case ThingTypes.QnA:
    case ThingTypes.Requisition:
    case ThingTypes.BestPractice:
    case ThingTypes.Blog:
    case ThingTypes.Wiki:
      return [
        {
          pageElementTitle: 'Verwandte Inhalte',
          pageElementContentSelection__: PageElementContentSelection.RELATED,
          pageElementVisualization__: PageElementVisualization.LIST,
          pageElementSort__: 'score_desc',
          pageElementMaxResults: 10,
        },
      ];
    case ThingTypes.StrategicDialog:
    case ThingTypes.Collection:
    case ThingTypes.Profile:
    case ThingTypes.Actors:
    default:
      return;
  }
}

export function getRelatedFromData(id: string, data: { [alias: string]: { items: IThing<ISearchEntry>[] } }): Array<IThing<ISearchEntry>[]> {
  const result: Array<IThing<ISearchEntry>[]> = [];
  for (const alias in data) {
    if (alias.startsWith(id)) {
      result.push(data[alias].items);
    }
  }
  return result;
}

export function contentTypeCardisBig(id): boolean {
  if (id === ThingTypes.Requisition) return true;
  if (id === ThingTypes.Collection) return true;
  return false;
}

export const GeneralUserStatus = {
  CheckSignature: 'to_check_signature_employer',
  SignatureValidated: 'signature_validated',
  SystemRegistrationOpen: 'system_registration_open',
  SystemRegistrationDone: 'system_registration_done',
};
