import React, { PropsWithChildren, ReactChild, ReactNode } from 'react';

import { Bx, ListWrapper } from '@curry-group/mui-curcuma';
import { HeadlineContainer } from '../headline-container';
import { IDictionary } from '../../helper';
import { IListUser } from '../../model/search/SearchResult';

export interface IItemRenderer<T> {
  renderItem: (item: T, index: number, avatarDict?: IDictionary<IListUser>) => ReactNode;
}

export interface IHasItems<T> {
  items?: T[];
}

export interface IListProps<T> extends IItemRenderer<T>, IHasItems<T> {
  divider?: 'top' | 'bottom' | 'both' | 'none' | undefined;
  dividerMargin?: number;
  top?: ReactNode;
  avatarDict?: IDictionary<IListUser> | undefined;
}
export function List<T>({
  top,
  children,
  items,
  renderItem,
  divider = 'bottom',
  dividerMargin,
  avatarDict
}: PropsWithChildren<IListProps<T>>) {
  if (!items || !items.length) {
    return (
      <ListWrapper divider={divider} dividerMargin={dividerMargin}>
        {top}
        {children}
      </ListWrapper>
    );
  }
  return (
    <ListWrapper divider={divider} dividerMargin={dividerMargin}>
      {top}
      {items?.map((item, index) => renderItem(item, index, avatarDict))}
    </ListWrapper>
  );
}

export interface IListWithHeadlineProps<T> extends IListProps<T> {
  headline?: React.ReactChild | undefined;
  endAdornment?: ReactChild;
  top?: ReactChild;
  divider?: 'top' | 'bottom' | 'both' | 'none' | undefined;
  small?: boolean;
}

export function ListWithHeadline<T>({
  children,
  headline,
  endAdornment,
  items,
  renderItem,
  top,
  divider,
  avatarDict,
  small
}: PropsWithChildren<IListWithHeadlineProps<T>>) {
  return (
    <>
      {headline &&
        <HeadlineContainer
          mb={small ? 1 : 2}
          py={small ? 0 : 1}
          headline={headline}
          headlineVariant="h3"
          hideUnderline
          endAdornment={endAdornment}
        />
      }
      <Bx mb={1}>
        <List<T>
          top={top}
          items={items}
          renderItem={renderItem}
          divider={divider}
          avatarDict={avatarDict}
        >
          {children}
        </List>
      </Bx>
    </>
  );
}
