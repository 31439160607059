import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const forbiddenRedirects = ['/', '/__stats/video', '/login', '/logout', '/lostpassword', '/oauth', '/optin', '/optout', '/register', '/resetpassword'];

export function useRedirectAfterLogin() {
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector(state => state.foundation.auth && !state.foundation.anon);
  const activateMandatoryFlow = useSelector(state => state.foundation?.updatetermsrules);
  const isInitialRoute = useRef(false);
  const [redirectPath, setRedirectPath] = useState<string>('/');
  useEffect(() => {
    // initial & not logged in
    let redirect = '/';
    let redirected = false;
    if (!isInitialRoute.current && !isLoggedIn) {
      isInitialRoute.current = true;
      if (forbiddenRedirects.indexOf(location.pathname) < 0) {
        let initialPath = location.pathname;
        if (location.search) {
          initialPath += location.search;
        }
        redirect = initialPath;
        redirected = true;
      }
    }
    // after login & path was saved
    if (isInitialRoute.current && isLoggedIn) {
      redirect = '/';
      redirected = true;
    }
    // welcome flow
    if (activateMandatoryFlow) {
      redirect = '/termsrules';
      redirected = true;
    }
    if (redirected) {
      setRedirectPath(redirect);
    }
  }, [location, isLoggedIn, history, activateMandatoryFlow]);

  return redirectPath;
}
