import React from 'react';
import { useLocation } from 'react-router';
import { Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { iconMapping, INavbarContentProps, navItemActive } from '..';
import { getNavigationPath, INavigationConfig, INavigationContainerConfig } from '../../../model/configuration';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GetNavBarButtonsFromSections, IDictionary } from '../../../helper';
import { useSelector } from 'react-redux';
import { NavBarHeadline } from '../../navbar-headline';
import { NavBarBtn } from '../../navbar-btn';

export interface INavbarContentBtnProps {
  key: string;
  section: INavigationConfig;
  config: INavigationContainerConfig;
}

export const NavbarContent: React.FC<INavbarContentProps> = ({ sections, badges }) => {
  var navButtons: INavbarContentBtnProps[] = GetNavBarButtonsFromSections(sections);
  return (
    <React.Fragment>
      {!!navButtons && navButtons.length > 0 && navButtons.map((navButton, index) => {
        return (
          <NavbarContentBtn key={navButton.key} badges={badges} section={navButton.section} config={navButton.config} />
        );
      })}
    </React.Fragment>
  );
};

export const NavbarContentBtn: React.FC<{ section: INavigationConfig; config: INavigationContainerConfig; badges: IDictionary<number> }> = ({ section, config, badges }) => {
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation?.appconfig?.[config.alias]);
  let badgeCount = 0;
  if (appconfig?.sidebarTypes && appconfig?.sidebarTypes.length) {
    for (let type of appconfig?.sidebarTypes) {
      if (badges[type]) {
        badgeCount += badges[type];
      }
    }
  } else if (appconfig?.alias === 'start') {
    badgeCount = badges['start'] || 0;
  } else if (appconfig?.alias === 'magazine' || appconfig?.alias === 'forum') {
    for (const type in appconfig?.types) {
      if (badges[appconfig?.types[type].aliasTypeMapping?.typeId]) {
        badgeCount += badges[appconfig?.types[type].aliasTypeMapping?.typeId];
      }
    }
  }
  const active = navItemActive(location, getNavigationPath(section, config));
  return (
    <NavBarBtn
      key={config.alias}
      active={active}
      to={getNavigationPath(section, config)}
      badgeContent={badgeCount ? badgeCount : undefined}
      badgeColor={active ? 'tertiary' : 'secondary'}
      startIcon={<FontAwesomeIcon icon={iconMapping[config.alias] || faQuestionCircle} />}
    >
      {config.name}
    </NavBarBtn>
  );
};
