import { fetchGet, fetchPost } from '@curry-group/data-addons';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import _ from 'lodash';
import { ISelection } from '..';
import { api } from '../../../../../../data/api';
import { GetCookie } from '../../../../../../helper';
import { IProfile } from '../../../../../../model/profile';
import { ThingTypes } from '../../../../../../model/ryve/Thing';

export const myUserProfileAtom = atom(undefined as IProfile | undefined);
export const parentTypeAtom = atom('');
export const communicationIdAtom = atom('');
export const meetingIdAtom = atom('');
export const headerFilterAtom = atom(false);
export const headerFilterWorkingAtom = atom(true);
export const stepAtom = atom(0);
export const buttonTextAtom = atom(get => {
  switch (get(stepAtom)) {
    case 0: {
      return 'Ok, weiter';
    }
    case 1: {
      return 'Einladen';
    }
  }
});
export const incrementOrFinishAtom = atom(
  null,
  async (get, set, updateAndClose?: { update?: () => Promise<void>; close?: () => void; closeDelay?: number; closingStep?: number }) => {
    const step = get(stepAtom);
    set(stepAtom, step + 1);
    if (get(stepAtom) === updateAndClose?.closingStep) {
      await updateAndClose?.update?.();
      if (updateAndClose?.closeDelay) {
        setTimeout(() => {
          updateAndClose?.close?.();
        }, updateAndClose?.closeDelay);
      } else {
        updateAndClose?.close?.();
      }
    }
  }
);
export const pageAtom = atom(0);
export const firstStepAtom = atom(get => get(stepAtom) === 0);
export const lastStepAtom = atom(get => get(stepAtom) === 2);
export const selectedOnlyAtom = atom(false);
export const selectionAtom = atom({} as ISelection);
export const searchResultAtom = atom(undefined as any);
export const allSelectedAtom = atom(get => {
  const searchResult = get(searchResultAtom);
  const selection = get(selectionAtom);
  return searchResult?.hits?.hits?.length === Object.keys(selection).length;
});
export const dataWorkingAtom = atom(true);
export const filterAliasAtom = atom('');
export const filterItemAtom = atom({ content: { queryTerm: '' } } as any);
export const filterDefaultSortAtom = atom(
  get => {
    const filterItem = get(filterItemAtom);
    return filterItem?.content?.defaultSort?.content;
  },
  (get, set, value) => {
    const clone = _.cloneDeep(get(filterItemAtom));
    if (clone && clone.content) {
      clone.content.defaultSort = value;
      set(filterItemAtom, clone);
    }
  }
);
const queryTermDebounceAtom = atom(undefined as any);
export const queryTermAtom = atom(
  get => {
    const filterItem = get(filterItemAtom);
    return filterItem?.content?.queryTerm;
  },
  (get, set, value) => {
    const clone = _.cloneDeep(get(filterItemAtom));
    if (clone && clone.content) {
      clone.content.queryTerm = value ?? '';
      set(filterItemAtom, clone);
      clearTimeout(get(queryTermDebounceAtom));
      if (value) {
        set(
          queryTermDebounceAtom,
          setTimeout(() => {
            set(asyncSearchAtom, true);
          }, 350)
        );
      } else {
        set(asyncSearchAtom, true);
      }
    }
  }
);
export const queryCategoriesAtom = atom(
  get => {
    const filterItem = get(filterItemAtom);
    return filterItem?.content?.queryCategories;
  },
  (get, set, value) => {
    const clone = _.cloneDeep(get(filterItemAtom));
    if (clone && clone.content) {
      clone.content.queryCategories = value;
      set(filterItemAtom, clone);
      set(asyncSearchAtom, true);
    }
  }
);
export const filterTitleAtom = atom(get => {
  return get(filterItemAtom)?.content?.title;
});
export const containedIDsAtom = atom(get => {
  const filterItem = get(filterItemAtom);
  return filterItem?.content?.containedIDs;
});
export const searchTypesAtom = atom(get => {
  const filterItem = get(filterItemAtom);
  return filterItem?.content?.searchTypes;
});
export const autoSuggestActiveAtom = atom(get => {
  const filterItem = get(filterItemAtom);
  return filterItem?.content?.autoSuggestActive;
});

export const searchResultHitsAtom = selectAtom(searchResultAtom, searchResult => {
  return searchResult?.hits?.hits as any[] | undefined;
});

export const searchResultTotalAtom = selectAtom(searchResultAtom, searchResult => {
  return (searchResult?.hits?.total as number) ?? 0;
});

const asyncSearchImpl = async (get, set, reset?: boolean) => {
  set(dataWorkingAtom, true);
  if (reset) {
    set(pageAtom, 0);
    set(searchResultAtom, undefined);
  }
  const selection = get(selectionAtom);
  const selectedIds = Object.keys(selection);
  if (!selectedIds.length) {
    selectedIds.push('00000000-0000-0000-0000-000000000000');
  }

  const sortData = get(filterDefaultSortAtom);

  const xsrfToken = GetCookie('XSRF-TOKEN');
  const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
  const result = await fetchPost(
    api.search.dynamic,
    {
      querySize: 21,
      queryTerm: get(queryTermAtom),
      querySkip: get(pageAtom) * 21,
      querySort: sortData?.type + '_' + sortData?.direction,
      queryCategories: get(queryCategoriesAtom),
      containedIDs: get(selectedOnlyAtom) ? selectedIds : get(containedIDsAtom),
      searchTypes: get(searchTypesAtom)
    },
    addHeader
  );
  const resultObject = await result.json();
  set(dataWorkingAtom, false);
  if (get(pageAtom) === 0) {
    set(searchResultAtom, resultObject);
  } else {
    const results = get(searchResultAtom);
    const newResult = {
      ...results,
      hits: {
        ...results.hits,
        hits: [...results.hits.hits, ...resultObject.hits.hits]
      }
    };
    set(searchResultAtom, newResult);
  }

  set(pageAtom, get(pageAtom) + 1);
};

export const asyncSearchAtom = atom(null, asyncSearchImpl);

const resetFilterImpl = async (get, set) => {
  const filterAlias = get(filterAliasAtom);
  const filter = await fetchGet(api.search.filter + '?alias=' + filterAlias);
  const filterItem = await filter.json();
  if (filterItem.content && filterItem.content.queryTerm === undefined) {
    filterItem.content.queryTerm = '';
  }
  let participantIds: string[] = [];
  const parentType = get(parentTypeAtom);
  const communicationId = get(communicationIdAtom);
  const meetingId = get(meetingIdAtom);

  if (parentType === ThingTypes.Group || parentType === ThingTypes.Project) {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const participantsResponse = await fetchGet(api.params(api.v2.communication.participants, { id: communicationId }), addHeader);
    const participants = await participantsResponse.json();
    if (participantsResponse.status === 200 && participants.data) {
      participantIds = participants.data
        .filter(x => x.status === 'accepted')
        .map(x => x.userResolved?._id)
        .filter(x => x);
    }
  }
  if (participantIds.length) {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const participantsResponse = await fetchGet(api.params(api.v2.communication.participants, { id: meetingId }), addHeader);
    const participants = await participantsResponse.json();
    if (participantsResponse.status === 200 && participants.data) {
      _.pull(
        participantIds,
        ...participants.data
          .filter(x => x.type === 'invite')
          .map(x => x.userResolved?._id)
          .filter(x => x)
      );
      _.pull(participantIds, get(myUserProfileAtom)?.profileId);
    }
  }
  // no participants, no results
  if (!participantIds.length) {
    participantIds = ['00000000-0000-0000-0000-000000000000'];
  }
  filterItem.content = filterItem.content || {};
  filterItem.content.containedIDs = participantIds;
  set(filterItemAtom, filterItem);
  set(asyncSearchAtom, true);
  set(headerFilterWorkingAtom, false);
};

export const resetFilterAtom = atom(null, resetFilterImpl);

const toggleAllSelectedImpl = (get, set) => {
  const filterItem = get(filterItemAtom);
  const allSelected = get(allSelectedAtom);
  if (allSelected) {
    set(selectionAtom, {});
  } else {
    const newSelection = {};
    for (let id of filterItem?.content?.containedIDs) {
      newSelection[id] = true;
    }
    set(selectionAtom, newSelection);
  }
};

export const toggleAllSelectedAtom = atom(null, toggleAllSelectedImpl);

export const invitationTextAtom = atom('');

const setSelectionImpl = (get, set, value: { profileId: string; userId: string }) => {
  const newSelection = _.cloneDeep(get(selectionAtom));
  if (newSelection[value.profileId]) {
    delete newSelection[value.profileId];
  } else {
    newSelection[value.profileId] = value.userId;
  }
  set(selectionAtom, newSelection);
};

export const toggleSelectionAtom = atom(null, setSelectionImpl);

export const footerButtonDisabledAtom = atom(get => {
  const step = get(stepAtom);
  const invitationText = get(invitationTextAtom);
  const selection = get(selectionAtom);
  const selectedLength = Object.keys(selection).length;
  if (step === 0 && selectedLength > 0) {
    return false;
  }
  if (step === 1 && invitationText?.length > 4) {
    return false;
  }
  return true;
});
