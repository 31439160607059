import { fetchPost } from '@curry-group/data-addons';
import { Btn, Bx, SwitchControl, Typo } from '@curry-group/mui-curcuma';
import { FormControlLabel } from '@material-ui/core';
import { useAtom, useSetAtom } from 'jotai';
import { BottomBar } from '../../../../../../components/bottom-bar';
import { api } from '../../../../../../data/api';
import { GetCookie } from '../../../../../../helper';
import { useIsMobile } from '../../../../../../helper/hooks/isMobile';
import {
  asyncSearchAtom,
  buttonTextAtom,
  footerButtonDisabledAtom,
  incrementOrFinishAtom,
  invitationTextAtom,
  meetingIdAtom,
  selectedOnlyAtom,
  selectionAtom,
  stepAtom
} from '../atoms';

export const MeetingInviteFooter: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const isMobile = useIsMobile();
  const [step] = useAtom(stepAtom);
  const [selection] = useAtom(selectionAtom);
  const selectedCount = Object.keys(selection).length;
  const [buttonDisabled] = useAtom(footerButtonDisabledAtom);
  const [selectedOnly, setSelectedOnly] = useAtom(selectedOnlyAtom);
  const [buttonText] = useAtom(buttonTextAtom);
  const [meetingId] = useAtom(meetingIdAtom);
  const additionalParticipantIds = Object.keys(selection).map(x => selection[x]);
  const [invitationText] = useAtom(invitationTextAtom);
  const incrementOrFinish = useSetAtom(incrementOrFinishAtom);
  const asyncSearch = useSetAtom(asyncSearchAtom);
  const inviteParticipants = async () => {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    await fetchPost(api.v2.meetings.inviteAdditional, { meetingId, additionalParticipantIds, invitationText }, addHeader);
  };
  return (
    <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
      <BottomBar scrolled bottom={'unset'}>
        <Bx display="flex" justifyContent={'flex-end'}>
          {step === 0 && (
            <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
              <FormControlLabel
                label={isMobile ? <Typo variant="body2">Nur hinzugefügte ({selectedCount}) anzeigen</Typo> : `Nur hinzugefügte Personen (${selectedCount}) anzeigen`}
                control={
                  <SwitchControl
                    size={isMobile ? 'small' : 'medium'}
                    color={'secondary'}
                    checked={selectedOnly}
                    disabled={false}
                    onChange={() => {
                      setSelectedOnly(!selectedOnly);
                      asyncSearch(true);
                    }}
                  />
                }
              />
            </Bx>
          )}

          <Bx textAlign="right">
            <Btn
              size="large"
              color="success"
              variant="contained"
              disabled={buttonDisabled}
              onClick={() => {
                incrementOrFinish({
                  closingStep: 2,
                  update: inviteParticipants,
                  close: onClose,
                  closeDelay: 2500
                });
              }}
            >
              <Typo variant="inherit" noWrap>
                {buttonText}
              </Typo>
            </Btn>
          </Bx>
        </Bx>
      </BottomBar>
    </Bx>
  );
};
