import React, { FC, ReactChild, ReactNode, useState } from 'react';
import { BtnList, Bx, IIconAvaProps, ExtendedListItem, Typo, Ava, IconAva, IconBtn, DropDownMenu, Indicator } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { faBan, faBookmark as faBookmarkLight, faEllipsisH, faExternalLink, faLock, faShare } from '@fortawesome/pro-light-svg-icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Badge } from './badge';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { GetListDateByType, IMemoryListItem, ThingTypes } from '../../../model/ryve/Thing';
import { MessageListItem } from '../message';
import { IDictionary } from '../../../helper';
import { IListUser } from '../../../model/search/SearchResult';

export interface IBaseListItemProps {
  title: string;
  type: string;
  typeId?: string;
  badge?: ReactNode;

  subtitle?: string;
  subtitle_2?: string;
  description?: string;

  body?: string;
  itemTemplate?: string;
  source?: string;

  color?: IIconAvaProps['color'];
  fontcolor?: string;
  highlight?: boolean;

  to?: string;
  userLink?: string;
  target?: string;
  selected?: (e:any) => void;
  shareClick?: (e:any) => void;

  hasMenu?: boolean;
  menuClicked?: (close: () => void) => ReactNode;
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE', seed?: string) => void;

  actions?: ReactNode;
  categories?: Array<string>;

  anchor?: string;

  booked?: boolean;
  noButtons?: boolean;
  noShare?: boolean;
  isHidden?: boolean;

  avatarDict?: IDictionary<IListUser>;
}

const useStyles = makeStyles(theme => ({
  anchorTarget: {
    scrollMarginTop: '120px'
  },
  btnList: {
    '& > div': {
      '& > *': {
        maxWidth: '100%'
      }
    }
  }
}));

export const BaseListItem: React.FC<
  IBaseListItemProps & {
    avatar: ReactChild;
    userAvatar?: ReactChild;
    _id?: string;
    _score?: number;
    __original?: any;
    noPadding?: boolean;
  }
> = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<{ target: HTMLElement } | null>(null);
  const [renderedOptions, setRenderedOptions] = useState<ReactNode>();
  const handleClick = (target: HTMLButtonElement) => {
    setAnchorEl({ target });
    setRenderedOptions(props.menuClicked?.(() => setAnchorEl(null)));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let liAvatar = props.avatar;
  if (props.to === '/optin') {
    liAvatar = (
      <Bx>
        <Indicator
          badgeContent={<FontAwesomeIcon size={'sm'} icon={faLock} title={'Eingeschränkter Zugang'} />}
          variant="standard"
          color="error"
          overlap="circular"
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {props.avatar}
        </Indicator>
      </Bx>
    );  
  }

  const itemId = props?.__original?._id ?? '';
  const noShare = props?.noShare !== false && props?.noShare !== true ? false : props?.noShare;
  const isHidden = props?.isHidden !== false && props?.isHidden !== true ? false : props?.isHidden;

  let itemRendered = <></>;

  let timestamp = GetListDateByType(props?.typeId || '', props?.__original || undefined, props.itemTemplate || '');
  if (
    !props.itemTemplate ||
    props.itemTemplate === 'default' ||
    props.itemTemplate === 'empty' ||
    props.itemTemplate === 'share' ||
    props.itemTemplate === 'members' ||
    props.itemTemplate === 'meeting' ||
    props.itemTemplate === 'references' ||
    props.itemTemplate === 'adminParticipation'
  ) {
    let itemLink = props.to;
    if (props.itemTemplate === 'adminParticipation' && !!itemLink) {
      if (itemLink.indexOf('?') > -1) {
        itemLink += '&activeView=settings&activeTab=participants';
      } else {
        itemLink += '?activeView=settings&activeTab=participants';
      }
    }
    itemRendered = (
      <Bx position="relative" bgcolor={props.highlight ? "accent.light" : undefined} px={{ xs: 0, sm: 0, md: props.noPadding ? 0 : 2 }}>
        {!!props.anchor && (<Typo component="span" className={clsx(classes.anchorTarget)} style={{width:0, height:0}} id={props.anchor}></Typo>)}
        <ExtendedListItem to={itemLink} target={props.target} onClick={props.selected} avatar={liAvatar}>
          <Bx height="100%" display="flex">
            <Bx display="flex" flexDirection="column" justifyContent="center">
              {props.itemTemplate !== 'empty' && <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span">
                {!!props.badge && (<><Badge color="secondary">neu</Badge>&nbsp;&nbsp;</>)}
                {localStorage.devModeSearch && (<span>
                  {props._id} || {props._score} || 
                  {props.categories && <>&nbsp;{props.categories?.map(cat => cat + ' || ')}</>}
                </span>)}
                <Typo component="span" variant="subtitle2" textTransform="uppercase">
                  {props.type}
                </Typo>
                {!!timestamp && props.itemTemplate !== 'adminParticipation' && props.itemTemplate !== 'share' && props.itemTemplate !== 'references' && props.itemTemplate !== 'meeting' && props?.typeId !== ThingTypes.Profile &&
                  (<Typo component="span" fontSize={'body1'} style={{color:'#aaa'}} textTransform="uppercase">
                    &nbsp;|&nbsp;
                  </Typo>)
                }
                {!!timestamp && props.itemTemplate !== 'adminParticipation' && props.itemTemplate !== 'share' && props.itemTemplate !== 'references' && props.itemTemplate !== 'meeting' ? `${timestamp}` : ''}
              </Typo>}
              <Typo fontWeight={500} color={props.fontcolor ?? 'white'} variant="body1" component="span">
                {props.title}
              </Typo>
              {(props.subtitle || props.subtitle_2) && (
                <Bx display="flex" mt={0.25}>
                  {props.subtitle && (
                    <Bx mr={2}>
                      <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                        {props.subtitle}
                      </Typo>
                    </Bx>
                  )}
                  {props.subtitle_2 && (
                    <Bx>
                      <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                        {props.subtitle_2}
                      </Typo>
                    </Bx>
                  )}
                </Bx>
              )}
              {(props.body) && (
                <Bx display="flex" mt={0.25}>
                  <Typo variant="body1" color={props.fontcolor ?? 'textPrimary'} component="span">
                    {props.body}
                  </Typo>
                </Bx>
              )}
            </Bx>
            {!props.noButtons && props.actions}
            {props.itemTemplate !== 'members' && !props.noButtons &&
              <Bx display="flex" flexDirection="row" alignItems="center" height="100%" ml="auto" justifyContent="center">
                {!noShare && !isHidden && props?.typeId !== ThingTypes.OneToOne && props?.shareClick && 
                <IconBtn
                  color="primary" 
                  shape="rounded"
                  size="medium"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.shareClick && props.shareClick({...props?.__original, _id: props?.typeId !== ThingTypes.Profile ? props?._id : props?.__original?._id});
                  }}
                >
                  <FontAwesomeIcon icon={faShare} />
                </IconBtn>
                }
                {props.itemTemplate !== 'share' && !!props.itemMemoClicked &&
                <IconBtn
                  size="medium"
                  shape="rounded"
                  color="primary"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    props?.itemMemoClicked?.(
                      {
                        elementId: (
                          props?.typeId === ThingTypes.News
                            ? props.__original?.content?.url
                            : !!itemId
                              ? itemId
                              : props._id)
                          || '',
                        externalId: props?.__original?.content?.url || '',
                        type: 'thing',
                        typeId: props.typeId || ''
                      },
                      props.booked ? 'DELETE' : 'POST'
                    );
                  }}
                >
                  {!props.booked && <FontAwesomeIcon icon={faBookmarkLight} />}
                  {!!props.booked && <FontAwesomeIcon icon={faBookmark} />}
                </IconBtn>}
              </Bx>
            }
          </Bx>
        </ExtendedListItem>
        {!!props.__original?.content?.conversationMessage && (
          <MessageListItem
            messageObject={props.__original?.content.conversationMessage}
            parentUrl={props.to || ''}
            avatarDict={props.avatarDict}
          />
        )}
        {props.hasMenu && props.menuClicked && (
          <Bx ml="auto" position="absolute" top="calc(50% - 18px)" right="0">
            <IconBtn
              size="small"
              shape="rounded"
              color="default"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </IconBtn>
          </Bx>
        )}
        {anchorEl?.target && (
          <DropDownMenu anchorEl={anchorEl?.target} keepMounted open={!!anchorEl} onClose={handleClose}>
            <Bx bgcolor="background.paper" borderRadius="borderRadius">
              {renderedOptions}
            </Bx>
          </DropDownMenu>
        )}
      </Bx>
    );
  } else if (props.itemTemplate === 'news') {
    const linkNoClick = props.to;
    const linkClick = props.selected ? undefined : props.to;
    itemRendered = (
      <Bx position="relative" my={2}>
        <ExtendedListItem
          style={{ borderRadius: 10, borderTopLeftRadius: 0 }}
          px={2}
          py={0}
          pt={2}
          bgcolor={"white"}
          to={linkClick}
          target={props.target}
          onClick={props.selected}
          avatar={undefined}
          title={props.title}
        >
          <Bx height="100%" display="flex">
            <Bx display="flex" flexDirection="column" justifyContent="center" maxWidth={'100%'}>
              <Bx pb={.5}>
                <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span">
                  <Typo component="span" variant="subtitle2" textTransform="uppercase">
                    News
                  </Typo>
                  {localStorage.devModeSearch && (<span>
                    &nbsp;|| ZISK_ID: {props._id} || ZISK_SCORE: {props._score}
                    {/* {props.categories && <>&nbsp;{props.categories?.map(cat => cat + ' || ')}</>} */}
                  </span>)}
                  <Typo component="span" fontSize={'body1'} style={{color:'#aaa'}} textTransform="uppercase">
                    &nbsp;|&nbsp;
                  </Typo>
                  <Typo component="span" variant="subtitle2">
                    {props.type === 'News' ? GetListDateByType(props?.typeId || '', props?.__original || undefined, props.itemTemplate || '') : props.type}
                  </Typo>
                </Typo>
              </Bx>
              <Bx pb={2}>
                <Typo variant="subtitle2" color={props.fontcolor ?? 'textSecondary'} component="span">
                  {props.source}
                </Typo>
              </Bx>
              <Bx pb={1}>
                <Typo fontWeight={500} color={props.fontcolor ?? 'white'} variant="h3" component="h2">
                  {props.title}
                </Typo>
              </Bx>
              <Bx pb={2}>
                <Typo color={props.fontcolor ?? 'textPrimary'}  variant="body1" component="span">
                  <FontAwesomeIcon icon={faExternalLink} />
                </Typo>
                &nbsp;&nbsp;
                <Typo color={'blue'} style={{ textDecoration: 'underline', wordBreak: 'break-all' }} variant="subtitle2" component="span">
                  {linkNoClick}
                </Typo>
              </Bx>
              {props.body && <Bx pb={2}>
                <Typo variant="body1" color={props.fontcolor ?? 'textPrimary'} component="span">
                  {props.body}
                  &nbsp;
                </Typo>
              </Bx>}
              {props.categories && props.categories.length > 0 && <Bx pb={2}>
                <BtnList className={clsx(classes.btnList)}>
                  {props.categories.map((cat, index) =>
                    <Chip key={index} variant="outlined" size="medium" label={cat} style={{maxWidth:'100%'}} />
                  )}
                </BtnList>
              </Bx>}
            </Bx>
          </Bx>
        </ExtendedListItem>
        <Bx ml="auto" position="absolute" top="8px" right="8px">
          {props?.shareClick && 
          <IconBtn
            color="primary" 
            shape="rounded"
            size="medium"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              props.shareClick && props.shareClick(props.__original);
            }}
          >
            <FontAwesomeIcon icon={faShare} />
          </IconBtn>
          }
          {props?.itemMemoClicked &&
          <IconBtn
            size="medium"
            shape="rounded"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              props?.itemMemoClicked?.(
                {
                  elementId: (props?.typeId === ThingTypes.News ? props.__original?.content?.url : props._id) || '',
                  externalId: props?.__original?.content?.url || '',
                  type: 'thing',
                  typeId: props.typeId || ''
                },
                props.booked ? 'DELETE' : 'POST',
                Date.now().toString()
              );
            }}
          >
            {!props.booked && <FontAwesomeIcon icon={faBookmarkLight} />}
            {!!props.booked && <FontAwesomeIcon icon={faBookmark} />}
          </IconBtn>
          }
        </Bx>
      </Bx>
    );
  }
  return itemRendered;
};

export const BaseListItemSkeleton = () => {
  return (
    <ExtendedListItem avatar={<Ava loading children={<FontAwesomeIcon icon={faBan} />} size="large" />}>
      <Typo fontWeight={500} variant="body1" component="span">
        <Skeleton animation="wave" style={{ width: '200px' }} />
      </Typo>
      <Typo variant="body2" component="span">
        <Skeleton animation="wave" style={{ width: '120px' }} />
      </Typo>
    </ExtendedListItem>
  );
};

export const BaseListItemEmpty: FC<{ title?: string }> = ({ title }) => {
  return (
    <BaseListItem
      type=" "
      subtitle=" "
      title={title || 'Keine Ergebnisse vorhanden.'}
      avatar={<IconAva color="primary" children={<FontAwesomeIcon icon={faBan} />} size="large" />}
      noButtons={true}
      itemTemplate={'empty'}
    />
  );
};
