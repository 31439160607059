import { useEffect, useRef, useState } from 'react';
import { ConnectedNavBar, ConnectedNavBarDrawer } from '../../../app/navbar';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { AppFrameContent, AppFrameNavbar } from '../../frame';

export const VideoStatsComponent = () => {
  const isMobile = useIsMobile();
  // const [navbarOpen, setNavbarOpen] = useState(false);
  const [stats, setStats] = useState('');
  const sseStats = useRef(undefined as EventSource | undefined);
  useEffect(() => {
    let lastSseStats = sseStats.current;
    if (!sseStats.current) {
      sseStats.current = new EventSource('/uwao-api/core/video-stats?stream=true');
      sseStats.current.addEventListener('stats', (ev: any) => {
        const statsString = JSON.parse(ev.data).stats;
        const statsLines = statsString.split('\n');
        const parsedObj: any = {};
        for (let line of statsLines) {
          const splittedLine = line.split(' ');
          const left = splittedLine[0];
          const right = splittedLine[1];
          switch (left) {
            case 're':
            case 'pe': {
              parsedObj[left] = right?.split(',');
              break;
            }
            case 'h': {
              parsedObj[left] = parseFloat(right);
              break;
            }
            case 'mptr':
            case 'mptdc':
            case 'ctmp':
            case 'mpti': {
              const splitted = right?.split(',') || [];
              const subSplitted = splitted.map((s: any) => s.split('=>'));
              parsedObj[left] = subSplitted.reduce((curr: any, next: any) => {
                if (next[0] && next[1]) {
                  curr[next[0]] = next[1];
                }
                return curr;
              }, {});
              break;
            }
            case 'impc': {
              const splitted = right?.split(',') || [];
              const subSplitted = splitted.map((s: any) => s.split('=>'));
              parsedObj[left] = subSplitted.reduce((curr: any, next: any) => {
                if (next[0] && next[1]) {
                  curr[next[0]] = parseInt(next[1]);
                }
                return curr;
              }, {});
              break;
            }
            default: {
              parsedObj[left] = right;
            }
          }
        }
        setStats(parsedObj);
      });
      lastSseStats = sseStats.current;
    }
    return () => {
      if (lastSseStats) {
        lastSseStats.close();
      }
    };
  }, []);
  return (
    <>
      <ConnectedNavBarDrawer />

      {/* NavBar */}
      {!isMobile && (
        <AppFrameNavbar>
          <ConnectedNavBar small={false} showHamburger={false} />
        </AppFrameNavbar>
      )}
      <AppFrameContent>
        <pre>{JSON.stringify(stats, undefined, 2)}</pre>
      </AppFrameContent>
    </>
  );
};
