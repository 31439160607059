import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Anohana, Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { useDispatch } from 'react-redux';
import { fetchCommunicationsRequest } from '../../../data/reducer/communication';
import { ThingTypes } from '../../../model/ryve/Thing';
import { copyValueToClipboard } from '../../../helper';
import { FramedContent } from '../../framed-content';

const ShareTypeSelect = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [linkCopySuccessModalOpen, setLinkCopySuccessModalOpen] = useState(false);
  function copyPathToClipboard() {
    let link = "";
    if (props.flowData?.itemData?.sharedItem?.type === ThingTypes.News) {
      link = props.flowData.itemData.sharedItem.content?.url
    } else {
      link = window.location.href.split('/flow')[0];
    }
    copyValueToClipboard(link);
    setLinkCopySuccessModalOpen(true);
  }
  function goBack() {
    setLinkCopySuccessModalOpen(false);
    history.goBack();
  }

  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Wohin wollen Sie den Inhalt teilen?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Platzieren Sie interessante Inhalte dort, wo Sie weiter mit ihnen arbeiten wollen.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          {props.flowData?.location?.pathname !== '/news' && <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'activityStream'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowType('activityStream');
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/community.svg" alt="in Community teilen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Community
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie wollen die gesamte Fachcommunity auf Medtec Online über diesen Inhalt informieren?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>}
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'sharetochat'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                dispatch(fetchCommunicationsRequest({ types: [ThingTypes.Project, ThingTypes.Group, ThingTypes.OneToOne] }));
                props.flowFunctions.setFlowType('sharetochat');
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/chat-arbeitsgruppe-projekt.svg" alt="in Chat teilen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Chat oder Communities
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie wollen auf diesen Inhalt in Formaten mit Ihrer Beteiligung hinweisen?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                copyPathToClipboard();
                e.preventDefault();
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/share_link.svg" alt="Zwischanablage" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Link in Zwischenablage kopieren
                  </Typo>
                </Bx>
                <Bx mt={2} mb={2} />
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
      <Anohana
        open={linkCopySuccessModalOpen}
        transition="zoom"
        disableScrollLock
        parent={() => document.querySelector('#frame__content')}
        fullWidth
        maxWidth="sm"
        onClose={goBack}
        style={{zIndex:1}}
      >
        <Bx p={2.5}>
          <Bx display="flex" justifyContent="center" mb={3} mt={3}>
            <Typo variant="h4" component="span">
              Der Link wurde in der Zwischenablage gespeichert.
            </Typo>
          </Bx>
        </Bx>
      </Anohana>
    </FramedContent>
  );
};

export default ShareTypeSelect;
