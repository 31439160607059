import { createReducer } from '@reduxjs/toolkit';
import { createCategoryHierarchy } from '../../../helper';

import { fetchGeneralCategoriesRequestAction, fetchGeneralCategoriesSuccessAction, fetchGeneralCategoriesErrorAction } from '../../actions/categories';

export interface ICategoriesState {
  working: boolean;
  items: any;
  categoryTree: any;
}

const initialState: ICategoriesState = {
  working: false,
  items: null,
  categoryTree: null,
};

const categories = createReducer(initialState, builder =>
  builder
    .addCase(fetchGeneralCategoriesRequestAction, (state, action) => {
      return { ...state, working: true };
    })
    .addCase(fetchGeneralCategoriesSuccessAction, (state, action) => {
      const categoryRepository = action.payload.items;
      const categoryTree = createCategoryHierarchy(
        Object.keys(categoryRepository ?? []).map(k => ({ _id: k, ...categoryRepository[k] })),
        'custom'
      );
      return { ...state, working: false, items: action.payload.items, categoryTree: categoryTree };
    })
    .addCase(fetchGeneralCategoriesErrorAction, (state, action) => {
      return { ...state, working: false, items: null };
    })
);
export default categories;
