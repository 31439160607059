import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommunicationsRequest } from '../../../data/reducer/communication';
import { ParticipationRequestStatus } from '../../../model/communication/Participant';
import { ThingTypes, transformForList } from '../../../model/ryve/Thing';
import { FramedContent } from '../../framed-content';
import { ListWidget } from '../../widget/list';
// import { transformForList } from "../../../model/ryve/Thing";

const ChatSelect: React.FC<{ props: any; working: boolean; result: any; asControl: boolean }> = ({ props, working = false, result, asControl = false }) => {
  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight) return;
    if (!props.search?.working && props.search?.moreDataAvailable) props.flowFunctions?.fetchNextPageSearchEntries?.();
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <FramedContent>
      <ListWidget
        working={working}
        items={transformForList(
          (result || []).map(item => {
            return { _source: item, _id: item._id, _score: 0 };
          })
        ).map(item => {
          return {
            ...item,
            selected: e => {
              props.flowFunctions.setFlowData({ ...props.flowData, communication: item });
              props.nextClick(e);
            }
          };
        })}
        headline={'Ihre Chats und Communities'}
      />
    </FramedContent>
  );
};

export const ConnectedChatSelect = (props: any) => {
  const result = useSelector(state => state.communication.communications);
  const working = useSelector(state => state.communication.communicationsWorking)
  const searchObject = useSelector(state => state.filter.searchObject);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!result) dispatch(fetchCommunicationsRequest({ types: [ThingTypes.Project, ThingTypes.Group, ThingTypes.OneToOne] }));
  }, [dispatch, result]);
  const filteredResult = result?.filter(item => {
    if (item?.content?.status !== ParticipationRequestStatus.ACCEPTED) return false;
    if (
      searchObject?.queryTerm &&
      !item?.content?.title?.toLowerCase()?.includes(searchObject?.queryTerm?.toLowerCase()) &&
      !item?.content?.partner?.content?.fullName?.toLowerCase()?.includes(searchObject?.queryTerm?.toLowerCase())
    ) {
      return false;
    }
    if (searchObject?.queryTypes && searchObject?.queryTypes?.length && !searchObject?.queryTypes?.includes(item.type)) {
      return false;
    }
    if (!!item?.content?.partner?.content?.status && item.content.partner.content.status !== ParticipationRequestStatus.ACCEPTED) {
      return false;
    }
    return true;
  });
  return <ChatSelect props={props} working={working || false} result={filteredResult} asControl={false} />;
};
