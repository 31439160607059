import * as React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bx, IBxProps, ITypoProps, Typo } from '@curry-group/mui-curcuma';

export interface IHeadlineContainerProps extends IBxProps {
  /**
   * Defines that this component doesen't make use of regular children.
   */
  children?: never;
  /**
   * Defines the content of the component.
   */
  headline?: string | React.ReactChild;
  /**
   * Defines the variant of the component. The types are provided by the `Typo` component.
   */
  headlineVariant?: ITypoProps['variant'];
  /**
   * Defines the rendered tag of the component. The types are provided by the `Typo` component.
   */
  headlineComponent?: ITypoProps['component'];
  /**
   * Defines the start adornment.
   */
  startAdornment?: React.ReactChild;
  /**
   * Defines the end adornment.
   */
  endAdornment?: React.ReactChild;
  /**
   * Defines if the regular underline should be disabled.
   */
  hideUnderline?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid #DDDFE0'
  }
}));

/**
 * The `HeadlineContainer` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const HeadlineContainer: React.FunctionComponent<IHeadlineContainerProps> = ({
  headline,
  headlineVariant,
  headlineComponent,
  startAdornment,
  endAdornment,
  hideUnderline,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Bx display="flex" alignItems="center" justifyContent="space-between" py={1} className={clsx(!hideUnderline && classes.root, className)} {...rest}>
      {startAdornment}
      {headline && typeof headline === 'string' && (
        <Typo variant={headlineVariant} component={headlineComponent}>
          {headline}
        </Typo>
      )}
      {headline && typeof headline !== 'string' && headline}
      {endAdornment}
    </Bx>
  );
};

HeadlineContainer.defaultProps = {
  headlineVariant: 'h3',
  headlineComponent: 'h3'
};
