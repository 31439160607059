import { Bx, TreeSelectControl, Typo } from '@curry-group/mui-curcuma';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { filterTreeById } from '../../../helper';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';
import { PlayerTypeSelectErrorModal } from '../content/playerTypeSelectError';

const EmployerTypeSelect: React.FC<{ props: any; employerConfigs: any }> = ({ props, employerConfigs }) => {
  const [employerFirstLevelCategory, ] = useState<string>(props.flowData?.employerFirstLevelCategory);
  const [employerParentType, ] = useState<string>(props.flowData?.employerParentType);
  const [employerType, setEmployerType] = useState<Array<string>>(props.flowData?.employerType?.map(cat => cat.categoryId) || []);
  const [employerTypeConfig, setEmployerTypeConfig] = useState<any>();
  const [dataExpanded, setDataExpanded] = useState<Array<string>>([]);

  let filterCategoryForTree = 'e863719e-97ec-eb0d-afd8-2bd05fa0a034';
  if (!!employerParentType) {
    filterCategoryForTree = employerParentType;
  }
  if (!!employerFirstLevelCategory) {
    filterCategoryForTree = employerFirstLevelCategory;
  }
  const employerData = filterTreeById(props.categories?.categoryTree, filterCategoryForTree, false);

  const handleSelection = (val: string) => {
    FormValidator.resetFields('employer');
    if ((employerType || []).includes(val)) {
      let index = employerType.indexOf(val);
      let newSelection = [...employerType];
      newSelection.splice(index, 1);
      props.flowFunctions.setFlowData({ ...props.flowData, employerType: [] });
      setEmployerType(() => []);
    } else {
      props.flowFunctions.setFlowData({ ...props.flowData, employerType: [val].map(id => ({ categoryId: id })) });
      setEmployerTypeConfig(employerConfigs?.[val]);
      setEmployerType(arr => [val]);
      setTimeout(() => {
          props.nextClick();
      }, 100);
    }
  };

  const handleExpanded = (val: string) => {
    if (dataExpanded.includes(val)) {
      let index = dataExpanded.indexOf(val);
      let newExpanded = dataExpanded;
      newExpanded.splice(index, 1);
      setDataExpanded(() => [...newExpanded]);
    } else {
      setDataExpanded(arr => [...arr, val]);
    }
  };

  return (
    <FramedContent height={'100%'}>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Welcher Kategorie gehört der Arbeitgeber an?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">
        Hier treffen Sie die Unterauswahl der Arbeitgeber-Kategorie, wie sie auf Medtec Online eingeordnet sind.
      </Typo>
      <Bx height={'100%'}>
        <div style={{ height: '95%' }}>
          <TreeSelectControl
            data={employerData}
            allowParentSelection={false}
            collapsible={false}
            nameIdentifier={'name'}
            valueIdentifier={'_id'}
            selected={employerType}
            expanded={dataExpanded}
            onSelectedCallback={handleSelection}
            onExpandedCallback={handleExpanded}
            enableScrollLock
          />
        </div>
      </Bx>
      <PlayerTypeSelectErrorModal
        typeConfig={employerTypeConfig}
        close={() => {
          setEmployerTypeConfig(undefined);
          setEmployerType(() => []);
        }}
      />
    </FramedContent>
  );
};

export const ConnectedEmployerTypeSelect = (props: any) => {
  const employerConfigs = useSelector(state => state.flow?.employerConfigs);
  return <EmployerTypeSelect props={{ ...props }} employerConfigs={employerConfigs} />;
};
