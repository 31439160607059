import { Typo, Link, Btn, Bx } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MessageConferenceContent } from '../content';
import { getProfileRouteByUserId } from '../../../../helper';
import { IMessageModel } from '../../../../model/communication/Message';

export interface IMessageQuoteProps {
  message: IMessageModel;
  remove?: () => void;
  onJoinConference?: () => void;
}

export const MessageQuote: React.FC<IMessageQuoteProps> = ({ message, remove, onJoinConference }) => {
  return (
    <Bx
      bgcolor="accent.light"
      borderRadius="borderRadius"
      p={1}
      border={3}
      borderTop={0}
      borderBottom={0}
      borderRight={0}
      borderColor="accent.main"
      color="text.primary"
      my={1}
    >
      <Bx display="flex">
        <Link underline="hover" to={getProfileRouteByUserId(message.createdBy)}>
          <Typo fontWeight={500} variant="body2" component="span">
            {message.createdByResolved?.content?.fullName || 'Ehemaliger Benutzer'}
          </Typo>
        </Link>

        {!!remove && (
          <Bx ml="auto" display="flex" justifyContent="flex-end">
            <Btn onClick={() => remove?.()} noXPadding color="primary" size="small" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Zitat entfernen
            </Btn>
          </Bx>
        )}
      </Bx>
      {!message.deleted && <Typo>{message.content}</Typo>}
      {message.deleted && <Typo fontWeight="lighter">Diese Nachricht wurde gelöscht</Typo>}

      {!message.deleted && message.conferenceInfo && onJoinConference && (
        <Bx display="flex" pt={1}>
          <MessageConferenceContent conferenceInfo={message.conferenceInfo} onJoinConference={onJoinConference} />
        </Bx>
      )}
    </Bx>
  );
};
