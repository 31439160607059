import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Bx, IBxProps } from '@curry-group/mui-curcuma';

export interface IChatMessageProps extends IBxProps {}

const useStyles = makeStyles(() => ({
  rootChatMessage: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
}));

/**
 * The `ChatMessage` component is an extension of the [Material UI Box](https://material-ui.com/components/box/#box) component and inherits all properties of it.
 */
export const ChatMessage: React.FunctionComponent<IChatMessageProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Bx px={rest.p ? undefined : 2} pt={rest.p ? undefined : 0.75} pb={rest.p ? undefined : 1.25} className={clsx(classes.rootChatMessage, className)} {...rest}>
      {children}
    </Bx>
  );
};
