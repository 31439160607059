import React, { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Ava, Bx, Collapse, IconAva, Typo } from '@curry-group/mui-curcuma';
import { ListAvatarColors, getDetailRoute, hashCode, resolveTimestamp } from '../../../helper';
import { ICommunicationListItem } from '../../../model/communication/Communication';
import { IContainerConfig } from '../../../model/configuration';
import { IListItem } from '../../../model/list/ListItem';
import { ThingTypes, transformForList } from '../../../model/ryve/Thing';
import { AppFrameSidebar } from '../../frame/sidebar';
import { AddSearch, IAddSearchProps } from '../../list-items/add-search';
import { SidebarListItem, SidebarListItemEmpty, SidebarListItemSkeleton } from '../../list-items/sidebar';
import { ListSidebar, SidebarWrapper } from '../../sidebar';
import { ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { MobileHeader } from '../../header/mobile';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskPotion } from '@fortawesome/pro-light-svg-icons';
import { faHourglassClock, faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { userprofileMemorylistRequestAction } from '../../../data/actions/foundation';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
  collector: {
    padding: '24px 24px 12px 24px',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer'
  },
  collapse: {
    boxShadow: '0 2px 2px 0 rgba(0,0,0,.15)'
  },
  collapsed: {
    borderBottom: '1px solid rgba(0,0,0,.15)'
  },
  collectorbottom: {
    marginTop: '2px'
  }
}));

export interface ICommunicationSidebarProps extends Omit<IAddSearchProps, 'maxHeight'> {
  items?: ICommunicationListItem[];
  working?: boolean;
  isMobile?: boolean;
}

export const ContainerSidebar: React.FC<{ config: IContainerConfig }> = ({ config }) => {
  const [searchInput, setSearchInput] = useState('');

  const isMobile = useIsMobile();
  const communications = useSelector(state => state.communication.communications);
  const communicationsWorking = useSelector(state => state.communication.communicationsWorking);

  const match = useRouteMatch();
  const history = useHistory();

  return (
    <AppFrameSidebar isMobile={isMobile}>
      {isMobile && <MobileHeader type="default" />}
      <CommunicationSidebar
        working={communicationsWorking}
        items={
          searchInput
            ? communications?.filter(item => {
                let title = item.content.title;
                if (item.content?.partner?.content?.fullName) {
                  title = item.content.partner.content.fullName;
                }
                return title?.toLowerCase().includes(searchInput.toLowerCase());
              }) || []
            : communications
        }
        addButtonClicked={
          config.sidebarCreate && config.canCreateContent
            ? () => {
                history.push(match.url + '/flow');
              }
            : undefined
        }
        searchEnabled={config.sidebarSearch}
        searchInput={searchInput}
        searchInputChange={setSearchInput}
        addButtonCaption={config.createContentCaption}
      />
    </AppFrameSidebar>
  );
};

export const ConnectedCommunicationSidebarListItem: React.FC<{
  item: IListItem;
  communication?: ICommunicationListItem;
  isMobile?: boolean;
  colorObject?: { color: string; bg: string };
  appconfig?: any;
  active?: boolean;
}> = ({
  item,
  communication,
  isMobile,
  colorObject,
  appconfig,
  active
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const unreadMessages = useSelector(state => state.communication.numUnreadMessages?.[item.typeId]?.[item._id] || 0);
  let baseAvatar = item?.avatar?.type === 'user'
    ? <Ava size="medium" src={undefined} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{item.avatar.abbreviation}</Ava>
    : <IconAva variant="circular" style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}} children={<FontAwesomeIcon icon={faFlaskPotion} />} size="medium" />;

  if ((item.avatar.type === 'image' || item.avatar.type === 'user') && item.avatar.image) {
    if (item.typeId === ThingTypes.OneToOne) {
      if (item.avatar.image) {
        baseAvatar = <Ava size="medium" src={item.avatar.image} style={{fontWeight: 500}}>{item.avatar.abbreviation}</Ava>;
      } else {
        baseAvatar = <Ava size="medium" src={undefined} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{item.avatar.abbreviation}</Ava>;
      }
    } else {
      if (item.avatar.image) {
        baseAvatar = <Ava size="medium" src={item.avatar.image} style={{fontWeight: 500}}>{<FontAwesomeIcon icon={faFlaskPotion} />}</Ava>;
      } else {
        baseAvatar = <Ava size="medium" src={undefined} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{<FontAwesomeIcon icon={faFlaskPotion} />}</Ava>;
      }
    }
  }

  const avatar = !communication?.content?.partner?.content?.generalUserStatus ||
    communication?.content?.partner?.content?.generalUserStatus !== 'to_check_signature_employer'
    ? baseAvatar
    : <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        color={'error'}
        title={'User noch nicht validiert.'}
        badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
      >
        {baseAvatar}
      </Badge>;

  let badgeContent: string | number | ReactNode = unreadMessages;
  let badgeColor: 'primary' | 'secondary' | 'default' = 'primary';
  if (communication) {
    if (communication.content.status === ParticipationRequestStatus.PENDING) {
      badgeColor = 'default';
      if (communication.content.type === ParticipationRequestType.INVITE) {
        badgeContent = 'Einladung';
      } else {
        badgeContent = 'Anfrage';
      }
    } else if (badgeContent === 0) {
      if (communication?.content?.partner?.content?.status === ParticipationRequestStatus.PENDING) {
        badgeColor = 'default';
        badgeContent = 'Anfrage';
      }
    }
  }

  return (
    <SidebarListItem
      isMobile={isMobile}
      subtitle={item.type}
      to={getDetailRoute(item, appconfig || {}, location)}
      title={item.title}
      avatar={avatar}
      isOneToOne={item.typeId === ThingTypes.OneToOne}
      isPublic={!item.isPrivate}
      isHidden={item.isHidden}
      timestamp={item.timestamp ? resolveTimestamp(parseInt(item.timestamp)) : ''}
      badgeContent={badgeContent}
      badgeColor={badgeColor}
      booked={item.booked}
      memoryClick={() => dispatch(userprofileMemorylistRequestAction({
        memoryItem: { elementId: item._id || '', type: 'thing', typeId: item.typeId || ''},
        type: item.booked ? 'DELETE' : 'POST'
      }))}
      active={active}
    />
  );
};

const SidebarAddSearch: React.FC<Omit<Omit<IAddSearchProps, 'searchActive'>, 'setSearchActive'>> = props => {
  const [searchActive, setSearchActive] = useState(false);

  if (!!props.addButtonClicked || props.searchEnabled)
    return (
      <AddSearch
        placedInSidebar
        isMobile={props.isMobile}
        maxHeight={props.maxHeight}
        addButtonClicked={props.addButtonClicked}
        addButtonCaption={props.addButtonCaption || 'erstellen'}
        searchActive={searchActive}
        searchInput={props.searchInput}
        searchInputChange={props.searchInputChange}
        searchEnabled={props.searchEnabled}
        searchPlaceholder={props.searchPlaceholder || 'Suchen'}
        setSearchActive={setSearchActive}
      />
    );
  return <></>;
};

export const CommunicationSidebar: React.FC<Omit<Omit<ICommunicationSidebarProps, 'searchActive'>, 'setSearchActive'>> = ({
  working,
  items,
  addButtonClicked,
  addButtonCaption,
  searchEnabled,
  searchInput,
  searchInputChange,
  searchPlaceholder
}) => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const parentId = useSelector(state => state.detail?.item?.content?.parentThing?.[0]?.elementId);
  const detailId = useSelector(state => state.detail?.item?._id);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);

  const isCommunicationDashboarrd = history.location.pathname === '/coop/groups-projects' || history.location.pathname === '/coop/chat';
  
  const memoryIds = memoryList?.map(m => m.elementId);

  const maxHeightSidebarSearch = !isMobile ? 81 - 1 : undefined; // -1 due to border from ListWrapper

  const _searchEnabled = !!(searchEnabled && searchInputChange);

  function renderItem(item: IListItem, index: number) {
    try {
      const hash = item?.avatar?.abbreviation
        ? hashCode(item?.avatar?.abbreviation ?? '')
        : hashCode(item?.title.slice(0, 2) ?? '');
      const colorIndex = hash % 4;
      const colorObject = ListAvatarColors[colorIndex];
      return <ConnectedCommunicationSidebarListItem
        key={item._id}
        appconfig={appconfig}
        isMobile={isMobile}
        item={item}
        communication={items?.find(i => i._id === item._id)}
        colorObject={colorObject}
        active={!isCommunicationDashboarrd && (item._id === parentId || item._id === detailId)}
      />;
    } catch (e) {
      return <></>;
    }
  }

  const itemsForList = transformForList(
    (items || []).map(item => {
      return {
        _source: item,
        _id: item._id,
        _score: 0,
        booked: !!item?._id && memoryIds?.includes(item?._id)
      };
    })
  );

  const [memorizedExpanded, setMemorizedExpanded] = useState(true);
  const [defaultExpanded, setDefaultExpanded] = useState(true);

  const memorizedItems = !searchInput ? itemsForList.filter(item => item.booked) : itemsForList;
  const defaultItems = !searchInput ? itemsForList.filter(item => !item.booked) : undefined;

  return (
    <SidebarWrapper>
      <>
      <ListSidebar<IListItem>
        working={working}
        items={undefined}
        renderItem={renderItem}
        top={
          !!addButtonClicked || _searchEnabled ? (
            <SidebarAddSearch
              isMobile={isMobile}
              placedInSidebar
              maxHeight={maxHeightSidebarSearch}
              addButtonClicked={addButtonClicked}
              addButtonCaption={addButtonCaption || 'erstellen'}
              searchInput={searchInput}
              searchInputChange={searchInputChange}
              searchEnabled={_searchEnabled}
              searchPlaceholder={searchPlaceholder || 'Suchen'}
            />
          ) : (
            <></>
          )
        }
      ></ListSidebar>
      {!searchInput && !!memorizedItems && !!memorizedItems.length && (
        <Bx display="flex" justifyContent="flex-start" alignItems="center" className={clsx(classes.collector)} onClick={() => {memorizedExpanded ? setMemorizedExpanded(false) : setMemorizedExpanded(true);}}>
          <Bx mr={1} style={{ fontSize: 12 }}>
            <FontAwesomeIcon icon={faTriangle} rotation={memorizedExpanded ? 180 : 90} />
          </Bx>
          <Typo variant="body2" component="span" style={{ fontSize: 14 }}>
            Gemerkt
          </Typo>
        </Bx>
      )}
      {!!memorizedItems && !!memorizedItems.length && (
      <Collapse expanded={memorizedExpanded} className={clsx(classes.collapse, !memorizedExpanded && classes.collapsed)}>
        <ListSidebar<IListItem>
          working={working}
          items={memorizedItems}
          renderItem={renderItem}
          top={<></>}
        >
          {working && _.range(3).map(i => (
            <SidebarListItemSkeleton key={`${i}_skeleton`} />
          ))}
          {!working && memorizedItems?.length === 0 &&
            <SidebarListItemEmpty key={'0_empty'} />
          }
        </ListSidebar>
      </Collapse>
      )}
      {!searchInput &&
        <Bx display="flex" justifyContent="flex-start" alignItems="center" className={clsx(classes.collector, classes.collectorbottom)} onClick={() => {defaultExpanded ? setDefaultExpanded(false) : setDefaultExpanded(true);}}>
          <Bx mr={1} style={{ fontSize: 12 }}>
            <FontAwesomeIcon icon={faTriangle} rotation={defaultExpanded ? 180 : 90} />
          </Bx>
          <Typo variant="body2" component="span" style={{ fontSize: 14 }}>
            Aktuell
          </Typo>
        </Bx>
      }
      {!searchInput && <Collapse expanded={defaultExpanded} className={clsx(classes.collapse, !defaultExpanded && classes.collapsed)}>
        <ListSidebar<IListItem>
          working={working}
          items={defaultItems}
          renderItem={renderItem}
          top={<></>}
        >
          {working && _.range(3).map(i => (
            <SidebarListItemSkeleton key={`${i}_skeleton`} />
          ))}
          {!working && defaultItems?.length === 0 &&
            <SidebarListItemEmpty key={'0_empty'} />
          }
        </ListSidebar>
      </Collapse>}
      </>
    </SidebarWrapper>
  );
};
