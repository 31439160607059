import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Hidden } from '@material-ui/core';
import { Btn, Bx, Divider, IBxProps, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

export interface IContentSeriesBarProps {
  withoutFrame?: boolean;
  episode?: number;
  previous?: {
    title: string,
    link: string
  };
  next?: {
    title: string,
    link: string
  }
}

export const ContentSeriesBar: React.FC<IContentSeriesBarProps> = ({
  withoutFrame = true,
  episode,
  previous,
  next
}) => {
  let frameProps: IBxProps = withoutFrame ? {} : { borderColor: 'divider', border: 1, borderLeft: 0, borderRight: 0, height: 74 };
  
  if (!previous && !next) return <></>;
  
  return (
    <Bx {...frameProps}>
      <Bx display="flex" alignItems="center" height="100%" py={0.5}>

            <Bx width="40%" display="flex" justifyContent="flex-end">
              {previous && (
                <Btn 
                  size='large'
                  component={
                    React.forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => <Link ref={ref} to={previous.link} {...props} />)
                  }
                  startIcon={<Bx mr={{ xs: 1, md: 2 }}  ml={{ xs: 0, md: 2 }}><FontAwesomeIcon icon={faChevronLeft}/></Bx>} 
                  href={previous.link}
                >
                  <Typo noWrap>{previous?.title}</Typo>
                </Btn>
              )}
              <Divider my={1} direction='vertical'/>
            </Bx>


            <Bx px={2} width="20%" display="flex" justifyContent="center">
              <Hidden smDown>
                <Typo align="center">
                  Blog-Serie Teil {episode}
                </Typo>
              </Hidden>
              <Hidden mdUp>
                <Typo align="center">
                  Teil {episode}
                </Typo>
              </Hidden>
            </Bx>


            <Bx width="40%" display="flex">
              <Divider my={1} direction='vertical'/>
              {next && (
                <Btn 
                  size='large' 
                  endIcon={<Bx mr={{ xs: 0, md: 2 }}  ml={{ xs: 1, md: 2 }}><FontAwesomeIcon icon={faChevronRight}/></Bx>} 
                  component={
                    React.forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => <Link ref={ref} to={next.link} {...props} />)
                  }
                  href={next.link}
                >
                  <Typo noWrap>{next.title}</Typo>
                </Btn>
              )}
            </Bx>

      </Bx>
    </Bx>
  );
};