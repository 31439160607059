import { Bx, CheckboxControl, Typo } from '@curry-group/mui-curcuma';
import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileManager, GridRowId } from '../../../components/communication/file-manager';
import { useFiles } from '../../../data/sagas/communication';
import { downloadFilesRequest, setDeleteFiles, setDeleteFilesActive, setUploadFilesActive, toggleWriteProtectRequest } from '../../../data/reducer/communication';
import { FileManagerButtonBar } from '../../../components/communication/file-manager/button-bar';

import { ConnectedUploadFilesModal } from './upload-modal';
import { ConnectedDeleteFilesModal } from './delete-modal';
import { ConnectedDownloadFilesModal } from './download-modal';
import { FramedContent } from '../../../components/framed-content';
import { IAssetResolved } from '../../../model/ryve/Asset';

export const ConnectedFileManager = () => {
  const dispatch = useDispatch();
  useFiles();

  const communicationId = useSelector(state => state.communication?.id);
  const participation = useSelector(state => state.communication?.participation);
  const attachments = useSelector(state => state.detail?.item?.content?.attachmentsResolved);
  const files = useSelector(state => state.communication?.files);
  const working = useSelector(state => state.communication?.filesWorking);

  const allowDownload = useSelector(state => !state.communication.downloadFilesPreparing);
  const allowAttachments = useSelector(state => state.communication?.communication?.allowAttachments && state.communication?.participation?.permissions.allowAttachments);

  const [downloads, setDownloads] = useState<IAssetResolved[]>([]);
  const [downloadsSecure, setDownloadsSecure] = useState<IAssetResolved[]>([]);

  const [attachmentSelection, setAttachmentSelection] = useState<GridRowId[]>([]);
  const [downloadSecureSelection, setDownloadSecureSelection] = useState<GridRowId[]>([]);
  const [downloadSelection, setDownloadSelection] = useState<GridRowId[]>([]);
  const [filters, setFilters] = useState<Array<string>>([]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newFilters;
    if (filters.indexOf(e.target.name) === -1) {
      newFilters = [...filters, e.target.name];
    } else {
      newFilters = filters.filter(a => a !== e.target.name);
    }
    setFilters(newFilters);
  };

  const openUploadModal = () => {
    dispatch(setUploadFilesActive(true));
  }

  const openDeleteModal = () => {
    dispatch(setDeleteFilesActive(true));
    dispatch(setDeleteFiles({ files: files?.filter(file => downloadSelection.includes(file._id)) }));
    setDownloadSelection([]);
  }

  const toggleWriteProtect = (active: boolean) => {
    const selection = downloadSelection?.length > 0
      ? (files?.filter(file => downloadSelection.includes(file._id)).map(file => file._id) || [])
      : (files?.filter(file => downloadSecureSelection.includes(file._id)).map(file => file._id) || []);
    dispatch(toggleWriteProtectRequest({assetIds: selection, active}));
  }

  const startDownload = () => {
    let selectedFiles = new Array<IAssetResolved>();
    if(downloadSelection?.length) {
      selectedFiles = files?.filter(file => downloadSelection.includes(file._id)) || [];
    } else if (attachmentSelection?.length) {
      selectedFiles = attachments?.filter(file => attachmentSelection.includes(file._id)) || [];
    } else if (downloadSecureSelection?.length) {
      selectedFiles = files?.filter(file => downloadSecureSelection.includes(file._id)) || [];
    }
    selectedFiles?.length && communicationId && dispatch(downloadFilesRequest({ communicationId, files: selectedFiles }));
  }

  const resetSelection = () => {
    if (downloadSelection?.length) {
      setDownloadSelection([]);
    }
    if (attachmentSelection?.length) {
      setAttachmentSelection([])
    }
    if (downloadSecureSelection?.length) {
      setDownloadSecureSelection([]);
    }
  }


  useEffect(() => {
    if (files?.length) {
      setDownloads(files?.filter(file => !file.content?.writeProtected) || []);
      setDownloadsSecure(files?.filter(file => !!file.content?.writeProtected) || []);
    } else {
      setDownloads([]);
      setDownloadsSecure([]);
    }
  }, [files])

  return (
    <>
      <FileManagerButtonBar
        allowDownload={allowDownload}
        allowUpload={allowAttachments}
        allowDelete={!!downloadSelection?.length}
        allowWriteProtectFile={downloadSelection?.length > 0 && participation?.permissions?.kickParticipants}
        allowWriteFreeFile={downloadSecureSelection?.length > 0 && participation?.permissions?.kickParticipants}
        toggleWriteProtect={(active) => {toggleWriteProtect(active);resetSelection();}}
        uploadButtonClicked={openUploadModal}
        downloadButtonClicked={startDownload}
        deleteButtonClicked={openDeleteModal}
        numSelected={downloadSelection?.length || attachmentSelection?.length || downloadSecureSelection?.length || 0}
        resetSelection={resetSelection}
        hasFilter={true}
        filters={filters}
        renderFilterMenu={() => {
          return (
            <>
              <MenuItem>
                <CheckboxControl label="Dokumente" control={{ color: 'primary', onChange: handleFilterChange, name: 'document' }} variant="outlined" size="small" />
              </MenuItem>
              <MenuItem>
                <CheckboxControl label="Bilder" control={{ color: 'primary', onChange: handleFilterChange, name: 'image' }} variant="outlined" size="small" />
              </MenuItem>
              <MenuItem>
                <CheckboxControl label="Videos" control={{ color: 'primary', onChange: handleFilterChange, name: 'video' }} variant="outlined" size="small" />
              </MenuItem>
            </>
          );
        }}
      />
      <FramedContent pt={1.5} pb={0}>
        <Bx mb={2}>
          <Typo variant="body1" fontWeight={500}>
            Zentrale Dokumente
          </Typo>
        </Bx>
        <FileManager
          assets={attachments}
          selection={attachmentSelection}
          setSelection={(selection) => {
            if (selection?.length > 0) {
              setDownloadSelection([]);
              setDownloadSecureSelection([]);
            }
            setAttachmentSelection(selection);
          }}
        />
      </FramedContent>
      <FramedContent pt={1.5} pb={0}>
        <Bx mb={2}>
          <Typo variant="body1" fontWeight={500}>
            Wichtige Downloads
          </Typo>
        </Bx>
        <FileManager
          writeProtected={true}
          assets={downloadsSecure}
          selection={downloadSecureSelection}
          setSelection={(selection) => {
            if(selection?.length > 0) {
              setAttachmentSelection([]);
              setDownloadSelection([]);
            }
            setDownloadSecureSelection(selection);
          }}
        />
      </FramedContent>
      <FramedContent>
        <Bx mb={2}>
          <Typo variant="body1" fontWeight={500}>
            Allgemeine Downloads
          </Typo>
        </Bx>
        <FileManager
          filters={filters}
          working={working}
          assets={downloads}
          selection={downloadSelection}
          setSelection={(selection) => {
            if (selection?.length > 0) {
              setAttachmentSelection([]);
              setDownloadSecureSelection([]);
            }
            setDownloadSelection(selection);
          }}
        />
      </FramedContent>
      <ConnectedUploadFilesModal />
      <ConnectedDeleteFilesModal />
      <ConnectedDownloadFilesModal />
    </>
  );
};
