import { FC } from 'react';
import { Typo, Divider, Btn, Bx } from '@curry-group/mui-curcuma';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEllipsisV } from '@fortawesome/pro-light-svg-icons';

export interface IMessageDividerProps {
  caption?: string;
}

export const MessageDivider: FC<IMessageDividerProps> = ({ caption }) => {
  return (
    <Divider mb={3}>
      {caption && (
        <Bx px={2}>
          <Typo variant="subtitle3" fontWeight={500} textTransform="uppercase" noWrap>
            {caption}
          </Typo>
        </Bx>
      )}
    </Divider>
  );
};

export interface IMessageThreadDividerProps {
  working?: boolean;
  caption?: string;
  loadMoreClicked?: () => void;
}

export const MessageThreadDivider: FC<IMessageThreadDividerProps> = ({ working, caption, loadMoreClicked }) => {
  const start = (
    <Typo variant="body1" component="span">
      <FontAwesomeIcon icon={faEllipsisV} />
    </Typo>
  );
  const end = (
    <Typo variant="body1" component="span">
      <FontAwesomeIcon icon={faEllipsisV} />
    </Typo>
  );
  return (
    <Divider mt={-3} mb={0} direction="vertical" startAdornment={start} endAdornment={caption ? end : undefined}>
      {!working && caption && (
        <Btn size="small" endIcon={<FontAwesomeIcon icon={faChevronDown} />} onClick={loadMoreClicked && (() => loadMoreClicked())}>
          {caption}
        </Btn>
      )}
      {working && (
        <Bx display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Bx>
      )}
    </Divider>
  );
};
