import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerAdmin } from '../../data/reducer/userprofile';
import { NotFoundComponent } from '../detail/not-found';
import { Grid } from '@material-ui/core';
import { Btn, Bx, Input, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailbox } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../headline-container';

export interface IBlacklistInviteComponentProps {
//   userProfile: IProfile;
}

export const BlacklistInviteComponent: React.FC<IBlacklistInviteComponentProps> = () => {
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const [blackMail, setBlackmail] = useState('');
  const isAdmin = useSelector(state => state.foundation?.profile?.isAdmin);
  const isMdUp = breakpoints.mdUp || false;

  if (!isAdmin) {
    return (<NotFoundComponent />); 
  }

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setBlackmail(event.target.value);
  }

  return (<>
    <Bx mb={6}>
      <HeadlineContainer
        mb={4}
        headline="Registrierung für Teilnehmer auslösen"
        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
        endAdornment={<>
        </>}
      />
      <Bx mb={2}>
        <Grid item xs={12} md={6}>
          <Bx mb={2}>
            <Input inputLabel={{ children: 'E-Mail', mandatory: true }} input={{ value: blackMail, fullWidth: true, onChange: onInputValueChange }} />
          </Bx>
        </Grid>
        <Grid item xs={6} md={6}>
          <Btn size="large"
            startIcon={<FontAwesomeIcon icon={faMailbox} />}
            variant="outlined"
            onClick={()=>{
              !!isAdmin && dispatch(registerAdmin({email: blackMail}));
              setBlackmail('');
            }}
          >
            Registrierung auslösen
          </Btn>
        </Grid>
      </Bx>
      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie für neue Teilnehmer die Registrierung auslösen, sollten diese von der Blacklist geblockt sein.</Typo>
    </Bx>
  </>);
};
