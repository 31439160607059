import React from 'react';

import { Anohana, Btn, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../../headline-container';

export const PlayerTypeSelectErrorModal: React.FC<{ typeConfig: any; close?: () => void }> = ({ typeConfig, close }) => {
  return (
    <Anohana open={!!typeConfig?.content?.invalidConfig} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={'Typ nicht spezifisch genug'}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={
          <IconBtn size="small" onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </IconBtn>
        }
      />
      <Bx py={3} px={3}>
        <Bx display="flex" justifyContent="flex-end" alignItems="center">
          <Typo variant="body1">{typeConfig?.content?.formText}</Typo>
          <Btn onClick={close} disabled={false} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
            OK
          </Btn>
        </Bx>
      </Bx>
    </Anohana>
  );
};
