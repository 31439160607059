import { Ava, Bx, ExtendedListItem, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { IDictionary, ListAvatarColors, assetUrl, hashCode } from '../../../helper';
import { IListUser } from '../../../model/search/SearchResult';
import { buildAbbreviation } from '../../../helper/user';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface IMessageListItemProps {
  messageObject: any;
  parentUrl: string;
  avatarDict?: IDictionary<IListUser>;
}

const useStyles = makeStyles(theme => ({
  msgHlt: {
    '& em': {
      background: '#FFEB3B'
    }
  }
}));

/**
 * msgHighlight always checked, so html can be displayed
 * @param param0 
 * @returns 
 */
export const MessageListItem: React.FC<IMessageListItemProps> = ({ messageObject, parentUrl, avatarDict }) => {
  const classes = useStyles();
  if (!messageObject || !parentUrl) {
    return (<></>);
  }
  let destination = parentUrl;
  if (parentUrl.indexOf('?') > -1) {
    destination += '&' + messageObject.notificationQueryString;
  } else {
    destination += '?' + messageObject.notificationQueryString;
  }
  let avatar: IListUser | null = null;
  if (!!avatarDict && !!messageObject?.userId && avatarDict[messageObject.userId]) {
    avatar = avatarDict[messageObject.userId];
  }
  const abbreviation = buildAbbreviation(avatar?.fullName ?? '');
  const hash = hashCode(abbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  const avatarSrc = !!avatar?.avatar ? assetUrl(avatar.avatar, true) : '';
  const msgHighlight = messageObject?.message ? `... ${messageObject?.message} ...` : '';
  if (!msgHighlight) {
    return (<></>);
  }
  return (
    <ExtendedListItem to={destination}
      avatar={
        <Bx ml={3}>
          <Ava size="medium" src={avatarSrc} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{abbreviation}</Ava>
        </Bx>
      }
      style={{padding: '0 0 8px 0'}}
    >
      <Bx display="flex" justifyContent="space-between" height="100%" mr={3}>
        <Bx display="flex" flexDirection="column" justifyContent="center">
          <Typo variant="subtitle2" color="textPrimary" component="span">
            Konversation zum Beitrag:
          </Typo>
          <Typo className={clsx(classes.msgHlt)}
            color="textPrimary"
            fontWeight={500}
            variant="body2"
            component="span"
            dangerouslySetInnerHTML={{ __html: msgHighlight }}
          />
        </Bx>
      </Bx>
    </ExtendedListItem>
  );
}
