import { createReducer } from '@reduxjs/toolkit';
import { IDictionary } from '../../../helper';
import { INotification } from '../../../model/notifications';
import { fetchNotificationsFailedAction, fetchNotificationsNextPageFailedAction, fetchNotificationsNextPageRequestAction, fetchNotificationsNextPageSuccessAction, fetchNotificationsRequestAction, fetchNotificationsSuccessAction, setHasBadgeAction } from '../../actions/notifications';

const DEFAULT_PAGE_SIZE = 20;

export interface INotificationState {
  items: INotification[];
  moreDataAvailable: boolean;
  working: boolean;
  badges: IDictionary<number>;
}
const initialState: INotificationState = {
  items: [],
  moreDataAvailable: true,
  working: false,
  badges: {}
};

export const notifications = createReducer(initialState, builder =>
  builder
    .addCase(setHasBadgeAction, (state, action) => {
      return { ...state, badges: { ...state.badges, [action.payload.id]: action.payload.count } };
    })
    .addCase(fetchNotificationsRequestAction, (state, action) => {
      return { ...state, working: true };
    })
    .addCase(fetchNotificationsSuccessAction, (state, action) => {
      const notificationsTrimmed = action.payload.slice(0, DEFAULT_PAGE_SIZE);
      const notificationCount = notificationsTrimmed?.reduce((curr, next) => {
        if (next.content?.notificationRead === false) {
          curr += 1;
        }
        return curr;
      }, 0);
      return { 
        ...state, 
        badges: { 
          ...state.badges,
          start: notificationCount
        }, 
        items: notificationsTrimmed,
        moreDataAvailable: action.payload?.length > DEFAULT_PAGE_SIZE,
        working: false 
      };
    })
    .addCase(fetchNotificationsFailedAction, (state, action) => {
      return { ...state, items: [], working: false };
    })
    .addCase(fetchNotificationsNextPageRequestAction, (state, action) => {
      return { ...state, working: true };
    })
    .addCase(fetchNotificationsNextPageSuccessAction, (state, action) => {
      const notificationsTrimmed = action.payload.slice(0, DEFAULT_PAGE_SIZE);
      const notificationCount = notificationsTrimmed?.reduce((curr, next) => {
        if (next.content?.notificationRead === false) {
          curr += 1;
        }
        return curr;
      }, 0);
      return { 
        ...state, 
        badges: { 
          ...state.badges, 
          start: (state.badges.start || 0) + notificationCount
        },
        items: [...state.items, ...notificationsTrimmed],
        moreDataAvailable: action.payload.length > DEFAULT_PAGE_SIZE,
        working: false
      };
    })
    .addCase(fetchNotificationsNextPageFailedAction, (state, action) => {
      return { 
        ...state,
        moreDataAvailable: false,
        working: false 
      };
    })
);
