import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';

export const RequestBlockedComponent: React.FC = () => {
  return (
    <Bx gridArea="content" display="flex" alignItems="center" justifyContent="center" height="100%">
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src="/images/frage.svg" alt="Error 404: Page not found" />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="subtitle1" align="center">
                Sie haben die maximale Anzahl an abrufbaren Profilen aufgerufen.<br />
                Diese Sperrung erfolgt aus Sicherheitsgründen und Schutz der Profildaten.<br />
                Nach 8h können Sie wie gewohnt Profile aufrufen.
              </Typo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Bx>
  );
};
