import { Bx } from '@curry-group/mui-curcuma';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  fade: {
    maxHeight: 2000,
    transition: theme.transitions.create(['max-height'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut
    })
  }
}));

export const AppFrameHeader: React.FC<{ fadeOut?: boolean }> = ({ children, fadeOut }) => {
  const classes = useStyles();
  return (
    <Bx id="frame__header" gridArea="header" zIndex={2} position="sticky" top="0" className={classes.fade} style={{ maxHeight: fadeOut ? 0 : undefined }}>
      {children}
    </Bx>
  );
};
