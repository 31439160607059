import React from 'react';
import { Bx } from '@curry-group/mui-curcuma';
import { NavbarHeader } from '../header';
import { NavbarContent } from '../content';
import { NavbarFooter } from '../footer';
import { INavbarBaseProps } from '..';
import { NavBar } from '../NavBar';

export const NavbarDesktop: React.FC<INavbarBaseProps & { showHamburger: boolean }> = args => {
  return (
    <NavBar
      pb={1.5}
      pt={0}
      height={'100%'}
    >
      <NavbarHeader
        showHamburger={args.showHamburger}
        hamburgerClicked={() => args.setOpen(!args.open)}
        open={args.open}
        topPad={1.5}
      />
      <Bx component="nav" width="100%">
        <NavbarContent badges={args.badges} sections={args.sections} />
      </Bx>
      <Bx mt="auto">
        <NavbarFooter userFullName={args.userFullName}
          userAvatarSrc={args.userAvatarSrc}
          userAbbreviation={args.userAbbreviation}
          userStatus={args.userStatus}
        />
      </Bx>
    </NavBar>
  );
};
