import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { api } from '../../data/api';

export const UserByIdRedirect = () => {
  const location = useLocation();
  const route = useRouteMatch<{ userId?: string }>();
  const params = route.params;
  const [profileRoute, setProfileRoute] = useState('');
  const userId = useSelector(state => state?.foundation?.profile?.userId);
  useEffect(() => {
    if (params.userId) {
      if (userId === params.userId) setProfileRoute('/user/profile')
      else {
        const url = api.params(api.v1.profile.aliasById, { alias: params.userId });
        // no csrf token needed, does not manipulate data
        fetch(url).then(response => {
          response
            .text()
            .then(result => {
              setProfileRoute('/user/' + result);
            })
            .catch(() => {
              setProfileRoute('/user/404');
            });
        });
      }
    }
  }, [params.userId, userId]);
  return <>{profileRoute && <Redirect to={profileRoute + location.search} />}</>;
};
