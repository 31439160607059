import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Bx } from '@curry-group/mui-curcuma';
import { IListItem } from '../../../model/list/ListItem';
import { ActivityStreamItem, ActivityStreamItemSkeleton } from '../../activity-stream-item';
import { useSelector } from 'react-redux';
import { getDetailRoute, getProfileRouteByUserId } from '../../../helper';
import { ThingTypes } from '../../../model/ryve/Thing';
import { HeadlineContainer } from '../../headline-container';
import _ from 'lodash';

export interface IActivityStreamProps {
  headline: string;
  items: Array<IListItem>;
  working?: boolean;
  backgroundWorking?: boolean;
  ignoreWorking?: boolean;
}

export const ActivityStream: React.FC<IActivityStreamProps> = ({ headline, items, working, backgroundWorking, ignoreWorking = false }) => {
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const [initialWorking, setInitialWorking] = useState(true && !ignoreWorking);

  useEffect(() => {
    if (working || backgroundWorking || (items || []).length > 0) {
      setInitialWorking(false);
    }
  }, [working, backgroundWorking, items]);

  return (
    <Bx>
      <HeadlineContainer mb={2} headline={headline} headlineVariant="h3" hideUnderline />
      {(working || initialWorking) && _.range(5).map(i => <ActivityStreamItemSkeleton key={i} />)}
      {items.map((item, idx) => (
        <ActivityStreamItem
          key={idx}
          item={item}
          creatorTo={item._id ? getProfileRouteByUserId(item._id) + '?ref=' + location.pathname : '/'}
          itemTo={item.typeId === ThingTypes.Profile ? item.to + '?ref=' + location.pathname : getDetailRoute(item, appconfig || {}, location)}
        />
      ))}
    </Bx>
  );
};
