import React from 'react';
import { FormCategories } from '../../form/compositions/FormCategories';
import { FramedContent } from '../../framed-content';

const ProjectCategoryForm = (props: any) => {
  props.setNextStepDisabled(false);
  return (
    <FramedContent>
      <FormCategories
        headline={'Welche Themenbereiche berührt das Projekt?'}
        subtitle={'Diese Angaben helfen uns, Ihr Projekt fachlich zu verorten und sofern gelistet interessierten Nutzerinnen und Nutzern bekannt zu machen.'}
        formData={props.flowData}
        categories={props.categories}
        config={props.config}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default ProjectCategoryForm;
