import { Btn, Bx, LinearLoader, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormInput } from '../controls/input';

interface IFormMagazine {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
  submit: () => void;
  close: () => void;
}

export const FormMagazine: React.FC<IFormMagazine> = ({ formData, headline, subtitle, onChange, submit, close }) => {
  const [step, setStep] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const location = useLocation();

  const timer = setInterval(() => {
    if (step === 1) {
      setProgress(oldProgress => {
        const rest = (100 - oldProgress) / 100;
        const diff = rest * Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }
  }, 500);

  useEffect(() => {
    if (progress >= 99) close();
  }, [progress, close]);

  return (
    <React.Fragment>
      {step === 0 && (
        <Bx>
          {headline && (
            <Bx mb={1}>
              <Typo variant="h1" component="h1">
                {headline}
              </Typo>
            </Bx>
          )}
          <Typo>
            Sie haben Interesse, einen Beitrag für das MTO-Magazin zu verfassen? Wir unterstützen Sie mit einem fachlichen Review. Die Veröffentlichung unterliegt den
            aktuellen <Link to={'/content/page/nutzungsbedingungen?ref=' + location.pathname}>Nutzungsbedingungen</Link> und setzt Ihre und unsere Freigabe voraus.
          </Typo>
          <Bx my={headline || subtitle ? 8 : 0}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <FormInput
                  value={formData?.theme}
                  label={'Thema'}
                  placeholder={'Bitte erläutern Sie kurz ihr Thema...'}
                  minLength={10}
                  maxLength={200}
                  onChange={val => {
                    onChange({ ...formData, theme: val });
                  }}
                  lazy
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormInput
                  value={formData?.benefit}
                  label={'Nutzwert für Innovatorinnen und Innovatoren'}
                  placeholder={'Bitte beschreiben Sie den Nutzen, den die Fachcommunity aus ihrem Beitrag ziehen kann...'}
                  minLength={20}
                  maxLength={200}
                  onChange={val => {
                    onChange({ ...formData, benefit: val });
                  }}
                  multiline
                  lazy
                />
              </Grid>
            </Grid>
            <Bx display="flex" justifyContent="flex-end">
              <Bx mr={1}>
                <Btn color="error" size="large" variant="contained" onClick={() => close()}>
                  Abbrechen
                </Btn>
              </Bx>
              <Bx>
                <Btn
                  disabled={formData?.theme?.length < 10 || formData?.benefit?.length < 20}
                  color="success"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    submit();
                    clearInterval(timer);
                    setStep(1);
                  }}
                >
                  Thema vorschlagen
                </Btn>
              </Bx>
            </Bx>
          </Bx>
        </Bx>
      )}
      {step === 1 && (
        <Bx>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                    <img src="/images/finish.svg" alt="Fertig! Los geht's..." />
                  </Bx>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Bx mb={2}>
                    <Typo variant="h1" align="center">
                      Vielen Dank für ihren Themenvorschlag
                    </Typo>
                  </Bx>
                  <Typo variant="subtitle1" align="center">
                    Experten aus unserem Content-Management werden Ihren Themenvorschlag prüfen und Ihnen zeitnah eine Rückmeldung geben. Bei Annahme Ihres Themenvorschlags
                    erhalten Sie die passende Autorenvorlage.
                  </Typo>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
                  <Bx mt={2}>
                    <LinearLoader variant="determinate" value={progress} />
                  </Bx>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Bx>
      )}
    </React.Fragment>
  );
};
