import React, { FC } from 'react';

import { Anohana, Ava, Btn, Bx, DropZone, formatBytes, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allowedMimeTypes } from '../../../../helper/validator';

export interface IFileUploadModalProps {
  open: boolean;
  close: () => void;

  preparing?: boolean;
  uploading?: boolean;

  files: File[];
  removeFile: (file: File) => void;
  addFiles: (files: File[]) => void;
  onSubmit: () => void;
}

export const UploadFilesModal: FC<IFileUploadModalProps> = ({ open, close, preparing, uploading, files, removeFile, addFiles, onSubmit }) => {
  const onDrop = (acceptedFiles: File[]) => {
    addFiles(acceptedFiles);
  };

  return (
    <Anohana open={open} transition="zoom" disableScrollLock parent={() => document.querySelector('#frame__content')} fullWidth maxWidth="sm" onClose={close}>
      <Bx p={2.5}>
        <Bx display="flex" alignItems="center" mb={3}>
          <Typo variant="h4" component="span">
            Dateien hochladen
          </Typo>
          <Bx ml="auto" mr={-1}>
            <IconBtn onClick={close}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Bx>
        </Bx>
        <Bx mb={4}>
          <DropZone onDrop={onDrop} maxSize={Infinity} minSize={50} multiple={true} label="Klicken oder Dateien hier ablegen" accept={allowedMimeTypes} />
          {files && files.length > 0 && (
            <Bx mt={1}>
              {files.map((file, idx) => (
                <Bx key={idx} mt={0.5} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                  <Bx mr={2}>
                    <Typo variant="h1" color="primary" component="div">
                      <Ava size="small" variant="rounded" src={URL.createObjectURL(file)} />
                    </Typo>
                  </Bx>
                  <Bx mr={2}>
                    <Typo variant="body2" fontWeight={500}>
                      {file.name}
                    </Typo>
                    <Typo variant="subtitle3">{formatBytes(file.size)}</Typo>
                  </Bx>
                  <Bx ml="auto">
                    <IconBtn size="small" onClick={() => removeFile(file)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </IconBtn>
                  </Bx>
                </Bx>
              ))}
            </Bx>
          )}
        </Bx>
        <Bx display="flex">
          <Bx>
            <Btn variant="outlined" onClick={close} size="medium">
              Abbrechen
            </Btn>
          </Bx>
          <Bx ml="auto">
            <Btn disabled={!files || !files.length || preparing || uploading} onClick={onSubmit} color="secondary" variant="contained" size="medium">
              Hochladen
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
