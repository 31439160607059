import { combineReducers } from 'redux';

import { notifications } from './notifications';
import { news } from './news';
import detail from './detail';
import foundation from './foundation';
import data from './data';
import filter from './filter';
import search from './search';
import flow from './flow';
import communication from './communication';
import categories from './categories';
import conferencing from './conferencing';
import userProfile from './userprofile';
import lists from './lists'
import bot from './bot'

export type RootState = ReturnType<ReturnType<typeof setupRootReducers>>;

// DefaultRootState for connected components
declare module 'react-redux' {
  export interface DefaultRootState extends RootState {}
}

export const setupRootReducers = () => {
  return combineReducers({
    categories,
    communication,
    conferencing,
    data,
    detail,
    filter,
    flow,
    foundation,
    lists,
    notifications,
    news,
    search,
    userProfile,
    bot
  });
};
