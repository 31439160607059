import { Bx, Hamburger, Typo } from '@curry-group/mui-curcuma';
import { INavbarHeaderProps } from '..';
import { NavBarIconBtn } from '../../navbar-icon-btn';
import { NavBarLogo } from '../../navbar-logo';
import { MTOLogo } from '../../../helper';

export const NavbarHeader: React.FC<INavbarHeaderProps & {
  showHamburger: boolean;
  hamburgerClicked: () => void
  topPad?: any;
}> = ({
  showHamburger,
  hamburgerClicked,
  open,
  topPad
}) => {
  return (
    <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
      {showHamburger && (
        <NavBarIconBtn onClick={hamburgerClicked}
          pt={topPad}
          to="#">
          <Hamburger toggled={open} />
        </NavBarIconBtn>
      )}
      <Bx display="flex"
        justifyContent={showHamburger ? 'flex-end' : 'flex-start'}
        alignItems="center"
        height="100%"
        pt={topPad}>
        <NavBarLogo to="/" src="/images/logo.svg" />
      </Bx>
      <Bx bgcolor={'white'} py={1} px={3}>
        <Bx display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Typo variant="body2"
            component="span"
            color={'text.primary'}
            style={{ fontSize: 8, textTransform: 'uppercase' }}>
            Eine Initiative vom
          </Typo>
        </Bx>
        <Bx py={1}>
          <img height={'54px'} src={MTOLogo.partnerLogoSrc} alt={MTOLogo.partnerLogoAlt} />
        </Bx>
      </Bx>
    </Bx>
  );
};
