import React, { useEffect } from 'react';
import { Btn, BtnList, IconBtn, Typo, TreeSelectControl, Anohana, Ctn, Bx } from '@curry-group/mui-curcuma';
import { Fade, Hidden } from '@material-ui/core';
// import { Hidden, useMediaQuery } from '@material-ui/core';
// import useTheme from '@material-ui/core/styles/useTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { convertTreeToIDList, filterByNodeName, updateArraySelection } from '../../../../helper';
import { AppFrameHeader } from '../../../frame/header';
import { AppFrameContent } from '../../../frame/content';
import { AppFrameFooter } from '../../../frame/footer';
import { AppFrame } from '../../../frame';
import { AppFrameSidebar } from '../../../frame/sidebar';
import { HeadlineContainer } from '../../../headline-container';
import { SplashSideBar } from '../../../splash-sidebar';
import { HeadBar } from '../../../head-bar';
import { HeadSearchInput } from '../../../head-search-input';
import { MobileHeadBar } from '../../../mobile-head-bar';
import { FramedContent } from '../../../framed-content';
import { BottomBar } from '../../../bottom-bar';

interface IFormCategorySelect extends IBreakpoints {
  value: Array<string>;
  label: string;
  categoryTree: any;
  categoryRepository: any;
  buttonTitle: string;
  placeholder: string;
  placeholderSearch: string;
  minLength: number;
  maxLength: number;
  onChange: (value: string) => void;
  noEdit?: boolean;
  splashImage?: string;
  allowParentSelection?: boolean;
  parentSelectionStartDeepth?: number;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

export const FormCategorySelect: React.FC<IFormCategorySelect> = ({
  value,
  label,
  categoryTree,
  categoryRepository,
  buttonTitle,
  placeholder,
  placeholderSearch,
  minLength,
  maxLength,
  onChange,
  isLgUp,
  isMdUp,
  isSmUp,
  noEdit,
  splashImage,
  allowParentSelection = false,
  parentSelectionStartDeepth = 0
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCategoryItems, setSelectedCategoryItems] = React.useState<Array<string>>(value || []);
  const [selectedCategoryItem, setSelectedCategoryItem] = React.useState<string>('');
  const [categoryChange, setCategoryChange] = React.useState<number>(0);
  const [dataExpanded, setDataExpanded] = React.useState<Array<string>>([]);
  const [data, setData] = React.useState(categoryTree || []);
  const [initialTree] = React.useState(categoryTree || []);
  const [filteredItemIds] = React.useState(convertTreeToIDList(categoryTree) || []);
  const [filterTerm, setFilterTerm] = React.useState('');
  const timer = React.useRef<any>(null);
  if (!minLength) {
    minLength = 0;
  }
  const handleSelection = (val: string) => {
    setSelectedCategoryItem(val);
    setCategoryChange(change => change + 1);
    onChange(val);
  };

  useEffect(() => {
    if (!selectedCategoryItem) {
      return;
    }
    const newCats = updateArraySelection(selectedCategoryItems || Array<string>(), selectedCategoryItem);
    setSelectedCategoryItems(newCats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryItem, categoryChange]);

  const handleExpanded = (val: string) => {
    if (dataExpanded.includes(val)) {
      let index = dataExpanded.indexOf(val);
      let newExpanded = dataExpanded;
      newExpanded.splice(index, 1);
      setDataExpanded([...newExpanded]);
    } else {
      setDataExpanded([...dataExpanded, val]);
    }
  };

  const handleSearch = (searchVal: string) => {
    if (searchVal) {
      const newData = filterByNodeName(initialTree, searchVal);
      setData(newData);
    } else {
      setData(initialTree);
    }
  };

  let noSelection = true;
  let filteredArray: string[] = [];
  if (selectedCategoryItems && selectedCategoryItems.length > 0) {
    filteredArray = selectedCategoryItems.filter(value => filteredItemIds.includes(value));
    if (filteredArray && filteredArray.length > 0) {
      noSelection = false;
    }
  }
  return (
    <Bx mb={3}>
      <HeadlineContainer
        headline={label}
        endAdornment={
          <React.Fragment>
            <Hidden smDown>
              {!noEdit && (
                <Btn variant="outlined" size="medium" endIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setOpen(true)}>
                  {buttonTitle}
                </Btn>
              )}
            </Hidden>
            <Hidden mdUp>
              <IconBtn size="medium" onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faPlus} />
              </IconBtn>
            </Hidden>
          </React.Fragment>
        }
      />
      <Bx py={2.5} borderColor="divider" border={1} borderLeft={0} borderTop={0} borderRight={0}>
        {noSelection && <Typo variant="body1">{placeholder}</Typo>}
        {filteredArray && filteredArray.length > 0 && (
          <BtnList>
            {filteredArray.map(item => {
              return (
                <Btn key={item} variant="outlined" size="small" endIcon={noEdit ? undefined : <FontAwesomeIcon icon={faTimes} onClick={() => handleSelection(item)} />}>
                  {categoryRepository?.[item]?.name}
                </Btn>
              );
            })}
          </BtnList>
        )}
      </Bx>
      <Anohana
        open={open}
        scroll={'body'}
        fullScreen
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Bx bgcolor="background.default">
          <AppFrame>
            <Hidden smDown>
              <AppFrameSidebar>
                <SplashSideBar src={splashImage ? `/images/${splashImage}.svg` : `/images/register.svg`} alt={'Kategorie(n) wählen'} />
              </AppFrameSidebar>
            </Hidden>

            <AppFrameHeader>
              <Hidden smDown>
                <HeadBar
                  transparent={false}
                  backButtonLink={() => {
                    setOpen(false);
                  }}
                >
                  <Fade in={true}>
                    <Bx height="100%" display="flex" alignItems="center" flexGrow={1} flexShrink={1}>
                      <Ctn>
                        <Bx display="flex">
                          <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                            <HeadSearchInput
                              input={{
                                id: 'head-search-categories',
                                value: filterTerm,
                                placeholder: placeholderSearch || 'Durchsuchen',
                                onChange: e => {
                                  setFilterTerm(e.target.value);
                                  timer.current && clearTimeout(timer.current);
                                  timer.current = setTimeout(() => {
                                    handleSearch(e.target.value);
                                  }, 200);
                                }
                              }}
                              inputLabel={{ htmlFor: 'head-search-categories' }}
                              clearOnClick={e => {
                                setFilterTerm('');
                                handleSearch('');
                              }}
                            />
                          </Bx>
                        </Bx>
                      </Ctn>
                    </Bx>
                  </Fade>
                </HeadBar>
              </Hidden>
              <Hidden mdUp>
                <MobileHeadBar>
                  <Bx display="flex" height="100%" alignItems="center" py={1}>
                    <Bx zIndex={2}>
                      <Bx pr={1}>
                        <IconBtn size="medium" onClick={() => setOpen(false)}>
                          <FontAwesomeIcon icon={faLongArrowLeft} />
                        </IconBtn>
                      </Bx>
                    </Bx>
                    <Bx flexGrow={1} flexShrink={1} flexBasis="auto" zIndex={2}>
                      <Bx pl={2} pr={1}>
                        <HeadSearchInput
                          input={{
                            id: 'head-search-categories',
                            value: filterTerm,
                            placeholder: placeholderSearch || 'Durchsuchen',
                            onChange: e => {
                              setFilterTerm(e.target.value);
                              timer.current && clearTimeout(timer.current);
                              timer.current = setTimeout(() => {
                                handleSearch(e.target.value);
                              }, 200);
                            }
                          }}
                          inputLabel={{ htmlFor: 'head-search-categories' }}
                          clearOnClick={e => {
                            setFilterTerm('');
                            handleSearch('');
                          }}
                        />
                      </Bx>
                    </Bx>
                  </Bx>
                </MobileHeadBar>
              </Hidden>
            </AppFrameHeader>
            <AppFrameContent>
              <TreeSelectControl
                data={data}
                allowParentSelection={allowParentSelection}
                parentSelectionStartDeepth={parentSelectionStartDeepth}
                collapsible={false}
                nameIdentifier={'name'}
                valueIdentifier={'_id'}
                selected={selectedCategoryItems}
                expanded={dataExpanded}
                onSelectedCallback={handleSelection}
                onExpandedCallback={handleExpanded}
                templateConfiguration={{
                  frameComponent: (
                    <FramedContent
                      py={0}
                      ml={{ lg: 3 }}
                      innerBoxProps={{
                        border: 1,
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: 0,
                        borderColor: 'divider',
                        py: 2,
                        height: 66,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    />
                  )
                }}
                noResultsMessage="Keine Treffer für Ihre Suche"
              />
            </AppFrameContent>
            <AppFrameFooter>
              <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
                <BottomBar scrolled bottom={'unset'}>
                  <Bx textAlign="right">
                    <Btn
                      size="large"
                      color="success"
                      variant="contained"
                      onClick={e => {
                        setOpen(false);
                      }}
                    >
                      Übernehmen
                    </Btn>
                  </Bx>
                </BottomBar>
              </Bx>
            </AppFrameFooter>
          </AppFrame>
        </Bx>
      </Anohana>
    </Bx>
  );
};
