import React from 'react';
import { passwordValid } from '../../../helper';
import { FormResetPasswordData } from '../../form/compositions/FormResetPasswordData';
import { FramedContent } from '../../framed-content';

const ResetPassFormData = (props: any) => {
  if (!props.flowData || !passwordValid(props.flowData?.password) || props.flowData?.password !== props.flowData?.passwordRepetition) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent display="flex" alignItems="center" height="100%">
      <FormResetPasswordData
        formData={props.flowData}
        headline={'Zurücksetzen Ihres Passworts auf Medtec Online.'}
        subtitle={'Vergeben Sie bitte ein sicheres Passwort für Ihren Zugang.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default ResetPassFormData;
