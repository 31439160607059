import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { allowedMimeTypes } from '../../../helper/validator';
import { FormDropZone } from '../controls/dropzone';
import { FormInput } from '../controls/input';
import { FormRTE } from '../controls/rte';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IFormQnA {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const FormQnA: React.FC<IFormQnA> = ({ formData, headline, subtitle, onChange }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <FormInput
              value={formData?.itemData?.title}
              label={'Frage'}
              placeholder={'Frage eingeben'}
              minLength={5}
              maxLength={150}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, title: val } });
              }}
              lazy
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormRTE
              value={formData?.itemData?.content}
              label={'Hintergrund Ihrer Frage'}
              placeholder={'Bitte beschreiben Sie Ihre Frage...'}
              mandatory={false}
              minLength={0}
              maxLength={1000}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, content: val } });
              }}
              lazy
            />
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <FormDropZone
              value={formData?.attachments ? formData?.attachments : []}
              label={'Anhänge'}
              placeholder={'Hochladen oder Datei(en) hier ablegen'}
              mandatory={false}
              accept={allowedMimeTypes}
              onChange={val => {
                onChange({ ...formData, attachments: val });
              }}
              multiple
            />
          </Grid> */}
          {!!formData?.itemData?.botAnswer &&
          <Grid item xs={12} lg={12}>
            <Typo component="label" variant="body2" fontWeight={500}>
              {'Bot-Antwort'}
            </Typo>
            <Bx
              style={{
                border: '1px solid transparent',
                borderRadius: 16,
                background:'rgb(230, 245, 251)',
                padding:'16px'
              }}
            >
            {!!formData?.itemData?.botAnswer &&
              <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(formData?.itemData?.botAnswer ?? '')}</Markdown>
            }
            </Bx>
          </Grid>}
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
