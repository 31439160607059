import _ from 'lodash';

const titleStrings = [
  'ass.',
  'biol.',
  'dipl.',
  'dipl.-betr.',
  'dipl.-ing.',
  'doz.',
  'dr-ing',
  'dr.',
  'dr.-ing.',
  'dr.rer.nat',
  'habil',
  'habil.',
  'hum.',
  'ing.',
  'iur.',
  'll.m.',
  'llm',
  'm.a.',
  'ma',
  'math.',
  'md',
  'med',
  'med.',
  'medic.',
  'msc',
  'nat.',
  'oec.',
  'päd.',
  'pd',
  'phd',
  'ph.d.',
  'phil.',
  'phys.',
  'priv.',
  'priv.-doz.',
  'prof.',
  'rer.',
  'soz.',
  'soz.-päd.',
  'techn.',
  'univ.',
  'univ.-prof.',
];
const forbiddenAbbreviations = ['ah', 'ss', 'hh'];
const fallbackAbbreviation = 'eb';

function nameSegments(name: string) {
  // string to array
  const nameSegments = (name || '').toLowerCase().split(' ');
  // removes all falsy and title strings
  return _.compact(_.difference(nameSegments, titleStrings));
}

function abbreviationAllowed(abbreviation: string) {
  return !forbiddenAbbreviations.includes(abbreviation);
}

export function buildAbbreviation(name?: string) {
  if (!name) return fallbackAbbreviation;
  let abbreviation;
  const segments = nameSegments(name);

  if (segments?.length) {
    // first letter
    abbreviation = segments[0].charAt(0);

    if (segments.length > 1) {
      // check last to second segment
      for (let i = segments.length - 1; i >= 1; i--) {
        const tmp = abbreviation + segments[i].charAt(0);
        if (abbreviationAllowed(tmp)) {
          abbreviation = tmp;
          break;
        }
      }
    }
  }
  return (abbreviation || fallbackAbbreviation).toUpperCase();
}
