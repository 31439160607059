import { createAction } from '@reduxjs/toolkit';
import { INewsResult } from '../../../model/news';

export const fetchFirstPageNewsRequestAction = createAction<{defaultResultsOnly?: boolean}>('FETCH_FIRST_PAGE_NEWS_REQUEST');
export const fetchFirstPageNewsSuccessAction = createAction<INewsResult>('FETCH_FIRST_PAGE_NEWS_SUCCESS');
export const fetchFirstPageNewsErrorAction = createAction<{ message: string }>('FETCH_FIRST_PAGE_NEWS_ERROR');

export const fetchNextPageNewsRequestAction = createAction('FETCH_NEXT_PAGE_NEWS_REQUEST');
export const fetchNextPageNewsSuccessAction = createAction<INewsResult>('FETCH_NEXT_PAGE_NEWS_SUCCESS');
export const fetchNextPageNewsErrorAction = createAction<{ message: string }>('FETCH_NEXT_PAGE_NEWS_ERROR');

export const resetNewsAction = createAction<{ loading: boolean }>('RESET_NEWS_TICKER');
export const resetNewsReferences = createAction('RESET_NEWS_REFERENCES');

export const setNewsAllConcepts = createAction('SET_NEWS_ALL_CONCEPTS');

export const setNewsWorking = createAction<{ working: boolean }>('SET_NEWS_WORKING');
