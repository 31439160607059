import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { Bx, IBxProps, useBreakpoints } from '@curry-group/mui-curcuma';

import { conferenceTheme } from '../../theme';
import { rgba } from 'polished';
import { ConferencingButton } from '../../controls/button';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';

export interface IConferenceFrameProps extends IBxProps {
  stream?: React.ReactChild;
  leftControls?: React.ReactChild;
  middleControls?: React.ReactChild;
  rightControls?: React.ReactChild;
  frameControls?: React.ReactChild;
  controlsAlwaysVisible?: boolean;
  fadeOutControls?: boolean;
  onControlsMouseEnter?: () => void;
  onControlsMouseLeave?: () => void;
}

export interface IConferenceFrameThemeExtension {
  conferenceFrame: {
    gridBackgroundColor?: React.CSSProperties['backgroundColor'];
    controlsBackgroundColor?: React.CSSProperties['backgroundColor'];
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  rootGrid: {
    backgroundColor: conferenceTheme.conferenceFrame?.gridBackgroundColor
  },
  stream: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: '#000',

    '& video': {
      width: '100%',
      height: '100%'
    }
  },
  participants: {
    position: 'relative',
    zIndex: 2
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 2,
    overflow: 'hidden',
  },
  leftControls: {},
  middleControls: {},
  rightControls: {
    background: rgba(conferenceTheme.conferenceIconBtn?.dark?.backgroundColor, 0.4),
    borderRadius: 10,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: conferenceTheme.conferenceIconBtn?.dark?.backgroundColor,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
      delay: theme.transitions.duration.complex * 2
    }),
    '& > *': {
      opacity: 0,
      transform: 'translateY(50px) scale(.75)',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
        delay: theme.transitions.duration.complex * 2
      })
    }
  },
  controlsController: {
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
  },
  frameButtons: {
    position: 'relative',
    zIndex: 2
  },
  controlsTransition: {
    opacity: 1,
    willChange: 'opacity',
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.complex, easing: theme.transitions.easing.easeInOut }),
    '& > *': {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      willChange: 'opacity, transform',
      transition: theme.transitions.create(['opacity', 'transform'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut
      })
    }
  }
}));

export const ConferenceFrame: React.FunctionComponent<IConferenceFrameProps> = ({
  stream,
  leftControls,
  middleControls,
  rightControls,
  frameControls,
  controlsAlwaysVisible,
  fadeOutControls,
  onControlsMouseEnter,
  onControlsMouseLeave,
  children,
  className,
  ...rest
}) => {
  const [controlsVisible, setControlsVisible] = React.useState(true);
  const breakpoints = useBreakpoints();
  const classes = useStyles({ controlsVisible });

  const Controls = (
    <React.Fragment>
      <Bx
        display="flex"
        alignItems="flex-end"
        onMouseEnter={onControlsMouseEnter}
        onMouseLeave={onControlsMouseLeave}
        className={classes.controls}
        style={{ opacity: fadeOutControls ? 0 : undefined, visibility: fadeOutControls ? 'hidden' : 'visible', bottom: fadeOutControls ? -1000 : undefined }}
      >
        <Bx
          py={2}
          className={clsx(classes.controlsController)}
        >
          <ConferencingButton
            size={breakpoints.mdUp ? 'large' : undefined} 
            icon={controlsVisible ? faMinus : faPlus}
            active={false}
            onClick={() => controlsVisible ? setControlsVisible(false) : setControlsVisible(true)}
            tooltip={controlsVisible ? 'Controls ausblenden' : 'Controls einblenden'}
            noStrikethrough
            designType={'halfButtonRight'}
          />
        </Bx>
        <Bx
          flexGrow={1}
          flexShrink={1}
          flexBasis="auto"
          display="flex"
          alignItems={'flex-end'}
          justifyContent="flex-end"
          className={clsx(classes.rightControls, classes.controlsTransition)}
          p={2}
          style={{ position: controlsVisible ? 'relative' : 'absolute', opacity: controlsVisible ? undefined : 0, visibility: controlsVisible ? 'visible' : 'hidden', bottom: controlsVisible ? undefined : -1000 }}
        >
          {rightControls}
        </Bx>
      </Bx>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Bx display="flex" flexDirection="column" className={clsx(classes.root, className)} {...rest}>
        {/* Start: Active Speaker */}
        <Bx className={classes.stream}>{stream}</Bx>
        {/* End: Active Speaker */}

        {/* Start: Frame Controls & Participants */}
        <Bx p={2} flexGrow={1} display="flex">
          {frameControls && <Bx className={classes.frameButtons}>{frameControls}</Bx>}
        </Bx>
        {/* End: Frame Controls & Participants */}

        {/* Start: Controls */}
        {Controls}
        {/* End: Controls */}
      </Bx>
    </React.Fragment>
  );
};
