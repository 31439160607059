import React, { useEffect } from 'react';
import { Bx, Html2View, IBxProps, ICtnProps, useBreakpoints } from '@curry-group/mui-curcuma';
import { ILandingPageFooterProps, LandingPageFooter } from '../../app/landingpage/footer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchDetailRequest } from '../../data/reducer/detail';
import { NotFoundComponent } from '../detail/not-found';
import { FramedContent } from '../framed-content';
import { LandingpageHeaderImpl } from '../../app/landingpage/impl/header';
import { MTOLogo } from '../../helper';

export const ImpressumComponent: React.FC<{ content: string }> = ({ content }) => {
  const breakpoints = useBreakpoints();
  const dispatch = useDispatch();

  const working = useSelector(state => state.detail.working);
  const item = useSelector(state => state.detail.item);
  const title = useSelector(state => state.detail.item?.content?.title);
  const html = useSelector(state => state.detail.item?.content?.content);

  useEffect(() => {
    dispatch(fetchDetailRequest({ type: 'page', alias: content }));
    window.scrollTo({ top: 0 });
  }, [content, dispatch]);

  const ctnMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const sectionPx: IBxProps['px'] = { xs: 4, md: 4 };
  const history = useHistory();
  const partnerLogoSrc = MTOLogo.partnerLogoSrc;
  const partnerLogoAlt = MTOLogo.partnerLogoAlt;

  return (
    <Bx gridArea="content">
      <LandingpageHeaderImpl
        view="other"
        scrolled
        backButtonLink={() => history.push('/')}
        auth={false}
      />
      <Bx bgcolor="background.paper" py={{ md: 8, lg: 6, xl: 6.25 }}>
        <FramedContent>
          {!working && item && <Html2View>{`<h1>${title}</h1>${html}`}</Html2View>}
          {!working && !item && <NotFoundComponent />}
          {working && <Html2View boosted loading />}
        </FramedContent>
      </Bx>
      <LandingPageFooter
        ctnMaxWidth={ctnMaxWidth}
        sectionPx={sectionPx}
        logoSrc={MTOLogo.logoWithTextSrc}
        logoAlt={MTOLogo.logoAlt}
        partnerLogoSrc={partnerLogoSrc}
        partnerLogoAlt={partnerLogoAlt}
        links={footerLinks}
        responsive={breakpoints}
      />
    </Bx>
  );
};

const footerLinks: ILandingPageFooterProps['links'] = [
  {
    name: 'Kontakt',
    mailto: 'info@medteconline.de'
  },
  {
    name: 'Impressum',
    to: '/impressum'
  },
  {
    name: 'Datenschutz',
    to: '/datenschutz'
  },
  {
    name: 'Nutzungsbedingungen',
    to: '/nutzungsbedingungen'
  },
  {
    name: 'Gemeinschaftsstandards',
    to: '/gemeinschaftsstandards'
  },
  {
    name: 'Unterstützer',
    to: '/unterstuetzer'
  },
  {
    name: 'Cookie Consents verwalten',
    onClick: e => {
      window['klaro']?.show?.();
    }
  },
  {
    name: 'Cookie Consents zurücksetzen',
    onClick: e => {
      window['klaro']?.getManager?.().resetConsents?.();
      window.location.reload();
    }
  }
];
