import { Bx, Carousel, Ctn, ICarouselProps, ICarouselRefProps, IconBtn, ICtnProps, Typo } from '@curry-group/mui-curcuma';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@material-ui/core';
import React from 'react';

interface ILandingPageQuoteSection {
  containerMaxWidth: ICtnProps['maxWidth'];
  headline: string;
  items: React.ReactElement[];
  theme: Theme;
  responsive: { isMDUp: boolean; isXLUp: boolean };
}

export const LandingPageQuoteSection: React.FunctionComponent<ILandingPageQuoteSection> = ({ containerMaxWidth, headline, items, theme, responsive }) => {
  const quoteCarouselRef = React.useRef<ICarouselRefProps>();
  return (
    <Ctn maxWidth={containerMaxWidth}>
      <Bx mb={6}>
        <Typo variant="h1" component="h2" dangerouslySetInnerHTML={{ __html: headline }} />
      </Bx>
      <Bx position="relative">
        <Carousel
          ref={quoteCarouselRef as ICarouselProps['ref']}
          items={items}
          infinite
          autoPlay
          autoPlayInterval={10000}
          autoHeight
          renderDotsItem={({ isActive }) =>
            isActive ? (
              <Bx component="button" mx={1} p={0} width={10} height={10} bgcolor="primary.main" style={{ border: 0, borderRadius: '50%' }}></Bx>
            ) : (
              <Bx component="button" mx={1} p={0} width={10} height={10} bgcolor="#cdcdcd" style={{ border: 0, borderRadius: '50%' }}></Bx>
            )
          }
          mouseTracking
          disableButtonsControls
        />
        {responsive.isMDUp && (
          <React.Fragment>
            <Bx position="absolute" top="calc(50% - 58px)" left={responsive.isXLUp ? -56 : -24}>
              <IconBtn style={{ backgroundColor: theme.palette.background.default }} onClick={e => quoteCarouselRef?.current?.slidePrev?.(e)}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </IconBtn>
            </Bx>
            <Bx position="absolute" top="calc(50% - 58px)" right={responsive.isXLUp ? -56 : -24}>
              <IconBtn style={{ backgroundColor: theme.palette.background.default }} onClick={e => quoteCarouselRef?.current?.slideNext?.(e)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </IconBtn>
            </Bx>
          </React.Fragment>
        )}
      </Bx>
    </Ctn>
  );
};
