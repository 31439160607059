import { callPost } from '@curry-group/data-addons';
import { select, takeLatest } from '@redux-saga/core/effects';
import { call, put, take } from 'redux-saga/effects';
import {
  fetchFirstPageNewsRequestAction,
  fetchFirstPageNewsSuccessAction,
  fetchFirstPageNewsErrorAction,
  fetchNextPageNewsRequestAction,
  fetchNextPageNewsSuccessAction,
  fetchNextPageNewsErrorAction,
  setNewsWorking,
  resetNewsReferences,
} from '../../actions/news';
import { api } from '../../api';
import { subscribeToSignalR, unsubscribeFromSignalR } from '../../reducer/communication';
import { resetBotRequestAction, resetBotFeedbackAction } from '../../actions/bot';

export function* news() {
  yield takeLatest(fetchFirstPageNewsRequestAction.type, fetchFirstPageNewsWorker);
  yield takeLatest(fetchNextPageNewsRequestAction.type, fetchNextPageNewsWorker);
}

function* fetchFirstPageNewsWorker(action: ReturnType<typeof fetchFirstPageNewsRequestAction>) {
  try {
    yield call(waitFor, (state: { filter: { filterItem: { content: any } } }) => state.filter?.filterItem?.content);
    let queryTerm = yield select(state => state.filter?.searchObject?.queryTerm);
    const pageSize = yield select(state => state.news?.queryPageSize);
    const allConcepts = yield select(state => state.news?.allConcepts);
    let querySort = yield select(state => state.filter?.searchObject?.querySort);
    const filterItem = yield select(state => state.filter?.filterItem);
    const botSearchActive = yield select(state => state.bot?.botSearchActive);
    const userId = yield select(state => state.foundation?.profile?.userId);
    
    let querySearchType = 'combined2';
    let titleBoost = filterItem?.content?.combined_TitleBoost;
    let bodyBoost = filterItem?.content?.combined_BodyBoost;
    let descripionBoost = filterItem?.content?.combined_DescriptionBoost;

    let botCommId = '';
    let testFallback = false;
    if (filterItem?.content?.testQueryFallback) {
      testFallback = true;
    }
    let botSearchTrigger = botSearchActive;
    if (action.payload.defaultResultsOnly) {
      queryTerm = '';
      botSearchTrigger = false;
    }

    if (!queryTerm) {
      queryTerm = 'emptyqueryterm';
    }
    if (!querySort) {
      querySort = 'pubdate_desc';
    }
    if (querySearchType) {
      querySort = querySort.indexOf('pubdate') > -1 ? 'pub_date' : '_score';
    }
    if (botSearchTrigger) {
      yield put(resetBotRequestAction());
      yield put(resetNewsReferences());
      yield put(resetBotFeedbackAction());
      querySearchType = 'newsbot';
      botCommId = 'no_comm_XB_NewsRequest_' + userId;
      yield put(subscribeToSignalR({ id: botCommId }));
    }
    const result = yield callPost(api.v2.news, {
      queryTerm,
      querySkip: 0,
      querySize: pageSize,
      allConcepts,
      querySort,
      titleBoost,
      bodyBoost,
      descripionBoost,
      querySearchType,
      testFallback,
    });
    yield put(fetchFirstPageNewsSuccessAction(result));
    yield put(setNewsWorking({ working: false }));

    if (botSearchTrigger) {
      yield put(unsubscribeFromSignalR({ id: botCommId }));
    }
  } catch (e: any) {
    yield put(fetchFirstPageNewsErrorAction({ message: e.message }));
  }
}

function* fetchNextPageNewsWorker() {
  try {
    yield call(waitFor, (state: { filter: { filterItem: { content: any } } }) => state.filter?.filterItem?.content);
    let queryTerm = yield select(state => state.filter?.searchObject?.queryTerm);
    const pageSkip = yield select(state => state.news?.querySkip);
    const pageSize = yield select(state => state.news?.queryPageSize);
    const allConcepts = yield select(state => state.news?.allConcepts);
    let querySort = yield select(state => state.filter?.searchObject?.querySort);
    const filterItem = yield select(state => state.filter?.filterItem);
    const botSearchActive = yield select(state => state.bot?.botSearchActive);

    let querySearchType = 'combined2';
    let titleBoost = filterItem?.content?.combined_TitleBoost;
    let bodyBoost = filterItem?.content?.combined_BodyBoost;
    let descripionBoost = filterItem?.content?.combined_DescriptionBoost;

    var testFallback = false;
    if (filterItem?.content?.testQueryFallback) {
      testFallback = true;
    }

    if (!queryTerm) {
      queryTerm = 'emptyqueryterm';
    }
    if (!querySort) {
      querySort = 'pubdate_desc';
    }
    if (querySearchType) {
      querySort = querySort.indexOf('pubdate') > -1 ? 'pub_date' : '_score';
    }
    if (botSearchActive) {
      querySearchType = 'newsbot';
    }
    const result = yield callPost(api.v2.news, {
      queryTerm,
      querySkip: pageSkip,
      querySize: pageSize,
      allConcepts,
      querySort,
      titleBoost,
      bodyBoost,
      descripionBoost,
      querySearchType,
      testFallback,
    });
    yield put(fetchNextPageNewsSuccessAction(result));
    yield put(setNewsWorking({ working: false }));
  } catch (e: any) {
    yield put(fetchNextPageNewsErrorAction({ message: e.message }));
  }
}

function* waitFor(selector) {
  if (yield select(selector)) return; // return if selector true
  while (true) {
    yield take('*'); // wait with empty task
    if (yield select(selector)) return; // return if selector true
  }
}
