import React from 'react';
import { useAcademicTitles } from '../../../data/sagas/lists';
import { FormUserData } from '../../form/compositions/FormUserData';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';

const UserDataForm = (props: any) => {
  useAcademicTitles();
  if (!props.flowData?.itemData?.firstName || !props.flowData?.itemData?.lastName || !props.flowData?.itemData?.email || !FormValidator.isValid('', 'userData')) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormUserData
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={'Bitte achten Sie darauf, keine schutzrechtsrelevanten Details offenzulegen.'}
        onChange={(formField, formValue) => {
          props.flowFunctions.setFlowFormData(formField, formValue);
        }}
      />
    </FramedContent>
  );
};

export default UserDataForm;
