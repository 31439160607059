import { FC, ReactNode } from 'react';
import { resolveTimestamp } from '../../../../helper';
import { IConferenceInformation, IMessageReferenceMeeting, IMessageReferences } from '../../../../model/communication/Message';
import { Link, Typo, Btn, TimeAva, Bx } from '@curry-group/mui-curcuma';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { BaseListItem } from '../../../list-items/default';
import { faArrowToRight, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

export interface IMessageContentProps {
  isDeleted?: boolean;
  isOwnMessage?: boolean;
  content: string;
  conferenceInfo?: IConferenceInformation;
  onJoinConference?: () => void;
  color?: string;
  references?: IMessageReferences;
  onReferenceSelected?: (type: string, data: any) => void;
}

export const MessageContent: FC<IMessageContentProps> = ({ content, isDeleted, isOwnMessage, conferenceInfo, onJoinConference, color, references, onReferenceSelected }) => {
  let Content: ReactNode = undefined;
  if (isDeleted) {
    Content = <Typo fontWeight="lighter">Diese Nachricht wurde gelöscht</Typo>;
  } else {
    if (references && references.meeting) {
      Content = <MessageMeetingContent color={color} meeting={references.meeting} onMeetingClicked={() => onReferenceSelected?.('meeting', references.meeting)} />;
    } else if (conferenceInfo && onJoinConference) {
      Content = <MessageConferenceContent conferenceInfo={conferenceInfo} onJoinConference={onJoinConference} />;
    } else {
      Content = <MessageTextContent
        data={resolveMessageContentLinks(content)}
        onLinkClick={link => onReferenceSelected?.('link', link)}
        isOwnMessage={isOwnMessage}
      />;
    }
  }
  return <Bx fontStyle={isDeleted ? 'italic' : 'normal'}>{Content}</Bx>;
};

export interface IResolvedMessageContent {
  content: string[];
  links: string[];
}

export const linkRegExp = /((http|https):\/\/)(\S+)([a-z]{2,}?)(.*?)( |, |$|\. )/gim;
export function resolveMessageContentLinks(content: string): IResolvedMessageContent {
  const eliminatedHtml = content
    .replace(/&/g, '&amp')
    .replace(/</g, '&lt')
    .replace(/>/g, '&gt')
    .replace(/(\r\n|\n|\r)/gm, ' <br/>');

  return {
    content: eliminatedHtml.replace(linkRegExp, '<<>>|||<<>>').split('<<>>|||<<>>'),
    links: content.match(linkRegExp) ?? []
  };
}

export interface IMessageTextContentProps {
  data: IResolvedMessageContent;
  onLinkClick: (link: string) => void;
  isOwnMessage?: boolean;
}
export interface IMessageTextContentStyleProps {
  isOwnMessage?: boolean;
}

const useStyles = makeStyles(theme => ({
  externalLink: {
    background: 'none',
    cursor: 'pointer',
    display: 'inline',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'inherit',
    lineBreak: 'anywhere',
    padding: 0,
    justifyContent: 'left',
    border: 'none !important',
    borderRadius: 0,
    fontWeight: 400,
    textAlign: 'initial',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      background: 'none'
    }
  }
}));

export const MessageTextContent: React.FC<IMessageTextContentProps> = ({ data, onLinkClick, isOwnMessage }) => {
  const classes = useStyles({ isOwnMessage });
  const location = window.location.origin;
  const externalLinksAllowed = useSelector(state => state.foundation.profile?.externalLinksAllowed);

  const resultArray = Array<React.ReactFragment>();
  for (let i = 0; i < data.content.length; i++) {
    const linkEndRegExp = /( |,|, |\.|\. )$/gim;
    resultArray.push(<Typo key={i} dangerouslySetInnerHTML={{ __html: data.content[i] }} fontWeight="normal" />);
    let punctuationAfterLink = '';
    let linkReplace = '';
    if (data.links.length > i) {
      linkReplace = data.links[i];
      const match = linkEndRegExp.exec(linkReplace);
      if (match && match[0] && match[0] !== ' ') {
        punctuationAfterLink = match[0];
        linkReplace = linkReplace.replace(linkEndRegExp, '');
      }
      const isExternalLink = !linkReplace.startsWith(location);
      
      resultArray.push(
        <Typo key={i + '-link'} fontWeight="normal">
          {isExternalLink && (
            <button
              className={clsx(classes.externalLink)}
              onClick={externalLinksAllowed ? () => window.open(linkReplace, '_blank'): () => onLinkClick(linkReplace)}
            >
              <FontAwesomeIcon icon={faExternalLink} />
              &nbsp;
              {linkReplace}
            </button>
          )}
          {!isExternalLink && (
            <Link to={'//' + linkReplace.replace(/(http|https):\/\//, '')} target="_blank">
              {linkReplace}
            </Link>
          )}
          {!!punctuationAfterLink && punctuationAfterLink}
        </Typo>
      );
    }
  }

  return <>{resultArray}</>;
};

export interface IMessageConferenceContentProps {
  conferenceInfo: IConferenceInformation;
  onJoinConference: () => void;
}

export const MessageConferenceContent: FC<IMessageConferenceContentProps> = ({ conferenceInfo, onJoinConference }) => {
  if (conferenceInfo.started && !conferenceInfo.ended) {
    return (
      <Btn variant="contained" color="secondary" onClick={() => onJoinConference?.()}>
        An Videokonferenz teilnehmen
      </Btn>
    );
  }

  if (conferenceInfo.ended) {
    return <Typo fontWeight="normal">Beendet: {resolveTimestamp(conferenceInfo.ended)}</Typo>;
  }

  return <></>;
};

export interface IMessageMeetingContentProps {
  meeting: IMessageReferenceMeeting;
  onMeetingClicked?: () => void;
  color?: string;
}

export const MessageMeetingContent: FC<IMessageMeetingContentProps> = ({ meeting, onMeetingClicked, color }) => {
  // const now = dayjs().unix();
  const start = meeting.interval.dateFrom;
  const end = meeting.interval.dateTo;
  return (
    <Bx>
      <BaseListItem
        type=""
        title={meeting.title}
        subtitle={dayjs.unix(start).format('DD.MM.YYYY')}
        avatar={<TimeAva size="large" color="default" fromTime={dayjs.unix(start).toDate().toString()} toTime={dayjs.unix(end).toDate().toString()} />}
        fontcolor={color}
        noButtons={true}
        itemTemplate={'meeting'}
      />
      <Bx mt={2}>
        <Btn variant="contained" color="secondary" onClick={() => onMeetingClicked?.()} startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
          Zum Meeting
        </Btn>
      </Bx>
    </Bx>
  );
};
