import React, { FC } from 'react';

import { Anohana, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { CircularProgress } from '@material-ui/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IFileDownloadModalProps {
  open: boolean;
  close: () => void;

  preparing?: boolean;
}

export const DownloadFilesModal: FC<IFileDownloadModalProps> = ({ open, close, preparing }) => {
  return (
    <Anohana open={open} transition="zoom" disableScrollLock parent={() => document.querySelector('#frame__content')} fullWidth maxWidth="sm" onClose={close}>
      <Bx p={2.5}>
        <Bx display="flex" alignItems="center" mb={3}>
          <Typo variant="h4" component="span">
            Herunterladen
          </Typo>
          <Bx ml="auto" mr={-1}>
            <IconBtn onClick={close}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Bx>
        </Bx>
        <Bx mb={3}>
          {preparing && (
            <>
              <Bx display="flex" justifyContent="center" height="100%" alignItems="center">
                <CircularProgress color="primary" />
              </Bx>
              <Bx mt={5} display="flex" justifyContent="center" height="100%" alignItems="center">
                <Typo>Ihr Download wird vorbereitet und steht in Kürze bereit.</Typo>
              </Bx>
            </>
          )}
          {!preparing && (
            <>
              <Bx display="flex" justifyContent="center" height="100%" alignItems="center">
                <Typo>Wird heruntergeladen...</Typo>
              </Bx>
              <Bx mt={2} display="flex" justifyContent="center" height="100%" alignItems="center">
                <Typo>Sie können dieses Fenster nun schließen.</Typo>
              </Bx>
            </>
          )}
        </Bx>
      </Bx>
    </Anohana>
  );
};
