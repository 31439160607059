import { RadioControl, DropDownMenu, IconBtn, theme, Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlt } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export const sortItems = [
  { caption: 'Neueste', _id: 'date_desc' },
  { caption: 'Älteste', _id: 'date_asc' },
  { caption: 'A-Z', _id: 'title_asc' },
  { caption: 'Z-A', _id: 'title_desc' },
  { caption: 'Relevanz', _id: 'default_desc' }
];

export const sortItemsVoting = [
  { caption: 'Höchste Bewertung', _id: 'voting_desc' },
  { caption: 'Niedrigste Bewertung', _id: 'voting_asc' },
  { caption: 'Relevanz', _id: 'default_desc' },
  { caption: 'Neueste', _id: 'date_desc' },
  { caption: 'Älteste', _id: 'date_asc' }
];

export const sortItemsProfile = [
  { caption: 'Neueste', _id: 'createddate_desc' },
  { caption: 'Älteste', _id: 'createddate_asc' },
  { caption: 'Relevanz', _id: 'default_desc' },
  { caption: 'A-Z', _id: 'title_asc' },
  { caption: 'Z-A', _id: 'title_desc' }
];

export const sortItemsMemorylist = [
  { caption: 'Hinzugefügt Neueste', _id: 'memorylist_desc' },
  { caption: 'Hinzugefügt Älteste', _id: 'memorylist_asc' },
  { caption: 'Neueste', _id: 'date_desc' },
  { caption: 'Älteste', _id: 'date_asc' },
  { caption: 'A-Z', _id: 'title_asc' },
  { caption: 'Z-A', _id: 'title_desc' }
];

export const sortItemsPlayers = [
  { caption: 'Relevanz', _id: 'default_desc' },
  { caption: 'A-Z', _id: 'title_asc' },
  { caption: 'Z-A', _id: 'title_desc' }
];

export const sortItemsNews = [
  { caption: 'Neueste', _id: 'pubdate_desc' },
  { caption: 'Relevanz', _id: 'default_desc' }
];

export interface IUnconnectedDefaultSort {
  componentKey?: string;
  marginLeft?: number;
  smallVariant?: boolean;
  anchorEl?: HTMLElement | null;
  open?: string;
  sortId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: () => void;
  onSortChanged?: (sortId: string) => void;
}

export const UnconnectedDefaultSort: React.FC<IUnconnectedDefaultSort> = ({
  componentKey,
  marginLeft,
  smallVariant,
  open,
  anchorEl,
  sortId,
  onClick,
  onClose,
  onSortChanged
}) => {
  return (
    <Bx key={componentKey} style={{ marginLeft: marginLeft }}>
      <IconBtn
        title={'Sortieren'}
        size={smallVariant ? 'small' : 'medium'}
        color={open === componentKey ? 'primary' : 'default'}
        variant={open === componentKey ? 'contained' : 'outlined'}
        onClick={onClick}
        aria-controls={componentKey}
        aria-haspopup="true"
        style={{
          borderRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: open === componentKey ? 0 : theme.shape.borderRadius,
          borderBottomRightRadius: open === componentKey ? 0 : theme.shape.borderRadius
        }}
      >
        <FontAwesomeIcon icon={faSortAlt} />
      </IconBtn>
      <DropDownMenu
        id={`filter_${componentKey}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={onClose}
        MenuListProps={{ component: 'div' }}
        open={open === componentKey}
        keepMounted
      >
        <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={360} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
          {open === componentKey && (
            <Grid container spacing={1}>
              {(sortItems || []).map((sort: any, index: number) => {
                return (
                  <Grid key={`filter_${componentKey}_${index}`} item xs={12} md={6}>
                    <RadioControl
                      label={sort.caption}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        onSortChanged?.(sort._id);
                      }}
                      checked={sortId === sort._id}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

const Sort: React.FC<{ props: any; querySort: string }> = ({ props, querySort }) => {
  let sortItemsCurrent = sortItems;
  let filterAlias = props?.filter?.alias || '';
  switch (filterAlias) {
    case 'experts':
      sortItemsCurrent = sortItemsProfile;
      break;
    case 'memorylist':
      sortItemsCurrent = sortItemsMemorylist;
      break;
    case 'news':
      sortItemsCurrent = sortItemsNews;
      break;
    default:
      break;
  }
  return (
    <Bx key={props.componentKey} style={{ marginLeft: props.marginLeft }}>
      <IconBtn
        title={'Sortieren'}
        size={props.smallVariant ? 'small' : 'medium'}
        color={props.open === props.componentKey ? 'primary' : 'default'}
        variant={props.open === props.componentKey ? 'contained' : 'outlined'}
        onClick={e => {
          props.filterFunctions?.click?.(e);
        }}
        aria-controls={props.componentKey}
        aria-haspopup="true"
        style={{
          borderRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: props.open === props.componentKey ? 0 : theme.shape.borderRadius,
          borderBottomRightRadius: props.open === props.componentKey ? 0 : theme.shape.borderRadius
        }}
      >
        <FontAwesomeIcon icon={faSortAlt} />
      </IconBtn>
      <DropDownMenu
        id={`filter_${props.componentKey}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={props.anchor}
        onClose={e => props.filterFunctions?.open?.('')}
        MenuListProps={{ component: 'div' }}
        open={props.open === props.componentKey}
        keepMounted
      >
        <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={360} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
          {props.open === props.componentKey && (
            <Grid container spacing={1}>
              {(sortItemsCurrent || []).map((sort: any, index: number) => {
                return (
                  <Grid key={`filter_${props.componentKey}_${index}`} item xs={12} md={6}>
                    <RadioControl
                      label={sort.caption}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        props.filterFunctions.onSortChange?.(sort._id);
                      }}
                      checked={querySort === sort._id}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

export const ConnectedSort = (props: any) => {
  const querySort = useSelector(state => state.filter?.searchObject?.querySort);
  return <Sort props={props} querySort={querySort || ''} />;
};
