import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { INavbarFrameProps, INavbarOpenProps } from '..';
import { Drawer } from '@material-ui/core';
import { NavbarFrame } from '../frame';

export const NavbarDrawer: React.FC<INavbarFrameProps & INavbarOpenProps> = props => {
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  if (isLargeDesktop) return null;

  return (
    <Drawer anchor="left" open={props.open} onClose={() => props.setOpen(!props.open)}>
      <NavbarFrame {...props} small={false} showHamburger={true} />
    </Drawer>
  );
};
