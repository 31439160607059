import { IBxProps } from '@curry-group/mui-curcuma';
import React from 'react';
import Calendar from './components/Calendar';
import { ConnectedCategoryFilter } from './components/CategoryFilter';
import CategoryTree from './components/CategoryTree';
import { ConnectedSort } from './components/Sort';
import { ConnectedTypeFilter } from './components/TypeFilter';
import { ConnectedDestinationFilter } from './components/DestinationFilter';

const GetFilterComponent = (type: string) => {
  switch (type) {
    case 'default':
      return 'categoryFilter';
    case 'types':
      return 'typeFilter';
    case 'destinations':
      return 'destinationFilter';
    case 'tree':
      return 'categoryTree';
    case 'calendar':
      return 'calendar';
    case 'sort':
      return 'sort';
  }
};

const FilterComponentSelector: any = {
  categoryFilter: ConnectedCategoryFilter,
  categoryTree: CategoryTree,
  typeFilter: ConnectedTypeFilter,
  destinationFilter: ConnectedDestinationFilter,
  calendar: Calendar,
  sort: ConnectedSort
};

const FilterComponents = (
  index: number,
  filter: any,
  aggregations: any,
  resultCount: number,
  marginLeft: IBxProps['ml'],
  filterFunctions: any,
  anchor: any,
  open: string,
  smallVariant?: boolean
) => {
  const component = GetFilterComponent(filter.type);
  if (typeof component !== 'undefined' && typeof FilterComponentSelector[component] !== 'undefined') {
    return React.createElement(FilterComponentSelector[component], {
      key: `${component}_${index}`,
      componentKey: `${component}_${index}`,
      filter: filter,
      aggregations: aggregations,
      resultCount: resultCount,
      marginLeft: marginLeft,
      filterFunctions: filterFunctions,
      anchor: anchor,
      open: open,
      smallVariant: smallVariant
    });
  }
  return React.createElement(() => <div>The component {component} has not been created yet.</div>, { key: filter._id });
};

export default FilterComponents;
