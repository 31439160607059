import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unsubscribeFromSignalR } from '../../../data/reducer/communication';

export const SignalRWatchComponent: React.FC = () => {
  const dispatch = useDispatch();
  const detailId = useSelector(state => state.detail.item?._id);

  useEffect(() => {
    return () => {
      if (detailId) {
        dispatch(unsubscribeFromSignalR({ id: detailId }));
      }
    };
  }, [detailId, dispatch]);
  return null;
};
