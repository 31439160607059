import { Ctn, ICtnProps, Typo, TabList, TabItem, Card, Divider, UnsortedList, Bx, IBxProps, Anohana, IconBtn, SliderControl, ISliderControlProps, TBreakpointContextProps, Btn } from '@curry-group/mui-curcuma';
import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faExpandAlt, faPause, faPlay, faTimes, faVideo, faVolume, faVolumeMute } from '@fortawesome/pro-light-svg-icons';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { HeadlineContainer } from '../../../components/headline-container';

interface ILandingPageTabSection {
  containerMaxWidth: ICtnProps['maxWidth'];
  innerSectionSpacing: GridProps['spacing'];
  responsive: TBreakpointContextProps;
  sectionPx: IBxProps['px'];
  imageSrc: string;
  videoSrc: string;
  imageAlt: string;
}

type TLandingPageVideoProps = {
  playing: boolean;
  loop: boolean;
  controls: boolean;
  volume: number;
  muted: boolean;
};

type TLandingPageVideoProgress = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

type ValueOf<T> = T[keyof T];

interface ISliderControlFixProps extends Omit<ISliderControlProps, 'onChange'> {
  onChange: ((event: React.ChangeEvent<{}>, value: any) => void) | ((event: React.FormEvent<HTMLButtonElement>) => void);
}

const SliderControlFix: React.FunctionComponent<ISliderControlFixProps> = React.forwardRef((props: any, ref) => {
  return <SliderControl {...props} ref={ref} />;
});

export const LandingPageTabSection: React.FunctionComponent<ILandingPageTabSection> = ({
  containerMaxWidth,
  innerSectionSpacing,
  responsive,
  sectionPx,
  imageSrc,
  videoSrc,
  imageAlt,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [videoOverlay, setVideoOverlay] = React.useState(false);
  const [videoProps, setVideoProps] = React.useState<TLandingPageVideoProps>({ playing: false, loop: false, controls: false, volume: 1, muted: false });
  const [videoProgress, setVideoProgress] = React.useState<TLandingPageVideoProgress>({ loaded: 0, loadedSeconds: 0, played: 0, playedSeconds: 0 });
  const [videoDuration, setVideoDuration] = React.useState<number>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [seeking, setSeeking] = React.useState<boolean>(false);
  const [seekTime, setSeekTime] = React.useState<number>(0);
  const videoRef = React.useRef<ReactPlayer>(null);

  let videoHeight: number | 'auto' = 'auto';
  if (responsive.md) {
    videoHeight = 500;
  } else if (responsive.lg) {
    videoHeight = 600;
  } else if (responsive.xl) {
    videoHeight = 700;
  } else {
    videoHeight = 'auto';
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleUpdateVideoProps = (prop: keyof TLandingPageVideoProps, val: ValueOf<TLandingPageVideoProps>) => {
    setVideoProps({ ...videoProps, [prop]: val });
  };
  const handleSeekChange = (e: any, val: any) => {
    setSeekTime(val);
    setVideoProgress({ ...videoProgress, playedSeconds: parseFloat(val) });
  };
  const handleSeekMouseDown = () => {
    setSeeking(true);
  };
  const handleSeekMouseUp = (e: any, val: any) => {
    setSeeking(false);
    videoRef.current?.seekTo(parseFloat(val));
  };

  const textImageSections: Array<{ headline: string; content: { children: React.ReactNode; icon: React.ReactNode }[]; imageSrc: string; imageAlt: string }> = [
    {
      headline: 'Für Leistungserbringer',
      content: [
        {
          children: <Typo>Direkter Dialog zu Versorgungsbedarfen unter Anwendern und Innovatoren.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>Geschützte Umgebung inkl. Webconferencing zur Entwicklung innovativer Ideen.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>Strukturiertes Fachwissen zum Thema Innovation für die Gesundheitsversorgung.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>Persönlicher Kontakt zu Innovatoren aus Wissenschaft und Wirtschaft.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>Möglichkeit, das Innovationsgeschehen mit direktem Input mitzugestalten.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-leistungserbringer.svg',
      imageAlt: 'Für Leistungserbringer'
    },
    {
      headline: 'Für Unternehmen',
      content: [
        {
          children: <Typo>barrierefreies Webconferencing für die Zusammenarbeit mit Leistungserbringern.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>geschützte Räume für die Projektentwicklung mit externen Partnern.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>den freien Zugang zu Fachwissen, Foren und Experten zum Thema Innovation.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>den direkten Austausch mit Wissenschaft und Versorgung.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>die Möglichkeit, die eigenen Kompetenzen, Ideen und Themen einzubringen.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-unternehmen.svg',
      imageAlt: 'Für Unternehmen'
    },
    {
      headline: 'Für die Wissenschaft',
      content: [
        {
          children: <Typo>eine sichere Umgebung für Arbeitsgruppen und Webconferencing.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>geschützte Räume zur Projektentwicklung.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>regulatorisches Know-how für den Innovationstransfer.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>persönlichen Kontakt zu innovativen Leistungserbringern und Unternehmen.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>die Möglichkeit, Bedarfe und Ideen in der Community zu spiegeln.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-wissenschaftler.svg',
      imageAlt: 'Für die Wissenschaft'
    },
    {
      headline: 'Für die öffentliche Hand',
      content: [
        {
          children: <Typo>eine Plattform für offizielle Mitteilungen an die Fachcommunity.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>ein Forum für eigene Fachbeiträge zu aktuellen Themen.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>den barrierefreien Austausch mit Experten per Webconferencing.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>einen sicheren Raum für Communities.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        },
        {
          children: <Typo>den direkten Kontakt zu qualifizierten Fachexperten.</Typo>,
          icon: (
            <Typo color="secondary.main">
              <FontAwesomeIcon icon={faCheckSquare} />
            </Typo>
          )
        }
      ],
      imageSrc: '/images/landingpage/fuer-die-oeffentliche-hand.svg',
      imageAlt: 'Für die öffentliche Hand'
    }
  ];

  return (
    <Bx component="section" width="100%" px={sectionPx}>
      <Ctn maxWidth={containerMaxWidth}>
        <Bx mb={6}>
          <Typo variant="h1" component="h2" dangerouslySetInnerHTML={{ __html: "Für wen ist Medtec Online gemacht? Was bietet das Portal?" }} />
        </Bx>
        <Bx mb={6}>
          <Btn
            size="large"
            color="primary"
            variant="outlined"
            disabled={false}
            onClick={() => setVideoOverlay(true)}
            startIcon={<FontAwesomeIcon icon={faVideo} />}
            title={'Video zu Medtec Online'}
          >
            Zielgruppen in einer Minute erklärt
          </Btn>
        </Bx>
        <Card>
          <Grid container>
            <Grid item xs={12}>
              <Bx px={6}>
                <TabList
                  onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                  textColor="primary"
                  fontVariant="h5"
                  value={selectedTab}
                  variant={responsive.mdUp ? 'fullWidth' : 'scrollable'}
                  scrollButtons="desktop"
                >
                  {textImageSections.map((section, idx) => (
                    <TabItem key={idx} label={section.headline} style={{ height: '80px' }} />
                  ))}
                </TabList>
              </Bx>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Bx p={6} display="flex" alignItems="center">
                <Grid container spacing={innerSectionSpacing} alignItems="center" style={{ minHeight: '350px' }}>
                  <Grid item xs={12} md={3}>
                    <img
                      src={textImageSections[selectedTab].imageSrc}
                      alt={textImageSections[selectedTab].imageAlt}
                      style={{ width: '100%', height: 'auto', maxHeight: '250px' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <UnsortedList items={textImageSections[selectedTab].content} spacing={2} />
                  </Grid>
                </Grid>
              </Bx>
            </Grid>
          </Grid>
        </Card>
      </Ctn>
      <Anohana fullWidth maxWidth="lg" open={videoOverlay} onClose={() => setVideoOverlay(false)}>
        <HeadlineContainer
          bgcolor="background.paper"
          pt={2}
          px={2}
          headline={'Medtec Online - Das neue Wir für die Versorgung'}
          headlineVariant="h3"
          headlineComponent="h5"
          endAdornment={<IconBtn size="small" onClick={() => setVideoOverlay(false)}><FontAwesomeIcon icon={faTimes} /></IconBtn>}
        />
        <Bx px={2} pb={2}>
          {!videoSrc && imageSrc && <img src={imageSrc} alt={imageAlt} style={{ width: '100%', height: 'auto' }} />}
          {videoSrc && imageSrc && (
            <Bx display="flex" flexDirection="column">
              <Bx position="relative">
                {loaded && (
                  <button
                    aria-label={!videoProps.playing ? 'Video abspielen' : 'Video pausieren'}
                    onClick={() => handleUpdateVideoProps('playing', !videoProps.playing)}
                    className="position-absolute"
                    style={{ inset: 0, width: '100%', height: '100%', zIndex: 5, background: 'transparent', border: 0, outline: 'none', color: 'transparent' }}
                  >
                    {!videoProps.playing ? 'Video abspielen' : 'Video pausieren'}
                  </button>
                )}
                <ReactPlayer
                  ref={videoRef}
                  url={videoSrc}
                  onReady={() => setLoaded(true)}
                  onProgress={e => setVideoProgress(e)}
                  onDuration={setVideoDuration}
                  config={{ file: { attributes: { poster: imageSrc, id: 'landingpage-video' } } }}
                  width={'100%'}
                  height={videoHeight}
                  style={{ width: '100%', height: videoHeight }}
                  {...videoProps}
                />
              </Bx>
              <Bx pt={1} display="flex" alignItems="center" transition="opacity" style={{ opacity: loaded ? 1 : 0 }}>
                <Bx>
                  <IconBtn color="primary" variant="contained" onClick={() => handleUpdateVideoProps('playing', !videoProps.playing)}>
                    <FontAwesomeIcon icon={videoProps.playing ? faPause : faPlay} />
                  </IconBtn>
                </Bx>
                <Bx ml={2}>
                  <IconBtn color="default" variant="text" onClick={() => handleUpdateVideoProps('volume', videoProps.volume === 1 ? 0 : 1)}>
                    <FontAwesomeIcon icon={videoProps.volume === 1 ? faVolume : faVolumeMute} />
                  </IconBtn>
                </Bx>
                <Bx display="flex" alignItems="center" width="100%" ml={2}>
                  <Bx flexShrink={0} flexBasis="70px" flexGrow={0} textAlign="right" display={{ xs: 'none', md: 'block' }}>
                    <Bx pr={3} display="inline-block">
                      <time dateTime="0">00:00</time>
                    </Bx>
                  </Bx>
                  <Bx flexBasis="100%" flexGrow={1} flexShrink={1}>
                    <SliderControlFix
                      color="primary"
                      min={0}
                      max={videoDuration}
                      value={seeking ? seekTime : videoProgress.playedSeconds}
                      valueLabelDisplay={seeking ? 'on' : 'off'}
                      valueLabelFormat={() => formatDuration(seekTime)}
                      onMouseDown={handleSeekMouseDown}
                      onChange={handleSeekChange}
                      onChangeCommitted={(e, val) => handleSeekMouseUp(e, val)}
                      railColor="#D6D7D9"
                      railSize="medium"
                      size="medium"
                    />
                  </Bx>
                  <Bx flexShrink={0} flexBasis="70px" flexGrow={0} display={{ xs: 'none', md: 'block' }}>
                    <Bx component="span" display="inline-block" pl={3}>
                      {videoDuration && videoProgress?.playedSeconds ? (
                        <time dateTime={`P${Math.round(videoDuration - videoProgress?.playedSeconds)}S`}>
                          -{formatDuration(videoDuration - videoProgress?.playedSeconds)}
                        </time>
                      ) : videoDuration ? (
                        <time dateTime={`P${Math.round(videoDuration)}S`}>-{formatDuration(videoDuration)}</time>
                      ) : (
                        <time dateTime="-00:00">-00:00</time>
                      )}
                    </Bx>
                  </Bx>
                </Bx>
                <Bx ml={2}>
                  <IconBtn
                    color="default"
                    variant="text"
                    onClick={() => screenfull.isEnabled && screenfull.request(document.getElementById('landingpage-video') as Element)}
                  >
                    <FontAwesomeIcon icon={faExpandAlt} />
                  </IconBtn>
                </Bx>
              </Bx>
            </Bx>
          )}
        </Bx>
      </Anohana>
    </Bx>
  );
};

function formatDuration(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${pad(mm)}:${ss}`;
}

function pad(string) {
  return ('0' + string).slice(-2);
}