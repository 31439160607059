import { Location } from 'history';
import { INavigationConfig } from '../../model/configuration';

import { faUsers, faLightbulbOn, faNewspaper, faCalendarDay, faFlaskPotion, faBell, faComment, IconDefinition, faBookReader, faBookmark, faMessageBot } from '@fortawesome/pro-light-svg-icons';
import { INavBarProps } from './NavBar';

export const iconMapping: { [key: string]: IconDefinition } = {
  start: faBell,
  notifications: faBell,
  news: faBookReader,
  chat: faComment,
  'groups-projects': faFlaskPotion,
  events: faCalendarDay,
  forum: faLightbulbOn,
  magazine: faNewspaper,
  'experts-actors': faUsers,
  memorylist: faBookmark,
  bot: faMessageBot
};
export interface INavbarBadges {
  badges: { [id: string]: number };
}
export interface INavbarFrameProps extends INavbarBadges {
  sections: INavigationConfig[];
  userId?: string;
  userFullName?: string;
  userAvatarSrc?: string;
}

export interface INavbarOpenProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export interface INavbarFrameConnectedProps {
  small: boolean;
  showHamburger: boolean;
}
export function navItemActive(location: Location<unknown>, path: string) {
  return (location.pathname === '/' && path === '/') || (location.pathname !== '/' && path !== '/' && location.pathname.startsWith(path));
}

export interface INavbarContentProps extends INavbarBadges {
  sections: INavigationConfig[];
}

export interface INavbarFooterProps {
  userFullName?: string;
  userAvatarSrc?: string;
  userAbbreviation?: string;
  userStatus?: string;
}

export interface INavbarBaseProps extends INavBarProps, INavbarContentProps, INavbarHeaderProps, INavbarFooterProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export interface INavbarHeaderProps {
  open: boolean;
}

export { NavbarDrawer } from './drawer';
export { NavbarFrame } from './frame';
