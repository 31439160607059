import { createAction } from '@reduxjs/toolkit';

export const userCredLoginRequestAction = createAction<{ username: string; password: string; persist: boolean }, 'USER_CRED_LOGIN_REQUEST'>('USER_CRED_LOGIN_REQUEST');
export const userLoginSuccessAction = createAction('USER_LOGIN_SUCCESS');
export const userLoginFailedAction = createAction('USER_LOGIN_FAILED');

export const userLogoutRequestAction = createAction('USER_LOGOUT_REQUEST');
export const userLogoutSuccessAction = createAction('USER_LOGOUT_SUCCESS');
export const userLogoutFailedAction = createAction('USER_LOGOUT_FAILED');

export const userMtoLoginRequestAction = createAction<{ persist: boolean }, 'USER_MTO_LOGIN_REQUEST'>('USER_MTO_LOGIN_REQUEST');
export const userMtoLoginSuccessAction = createAction('USER_MTO_LOGIN_SUCCESS');
export const userMtoLoginFailedAction = createAction('USER_MTO_LOGIN_FAILED');

export const userDeauthAction = createAction('USER_DEAUTH');
