import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faReply, faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { ListAvatarColors, assetUrl, hashCode, resolveTimestamp } from '../../../../helper';
import { buildAbbreviation } from '../../../../helper/user';
import { Typo, IconBtn, Btn, Ava, Bx } from '@curry-group/mui-curcuma';
import useTheme from '@material-ui/core/styles/useTheme';
import { IMessageModel } from '../../../../model/communication/Message';
import { shorten } from '../../../../helper';

export interface IMessageInteractionBarProps {
  hasVoting?: boolean;
  hasThreading?: boolean;
  hasQuotes?: boolean;

  isDeleted?: boolean;
  isOwnMessage?: boolean;

  numChildren?: number;
  numVotes?: number;
  userVoting?: number;

  requestThread: () => void;
  requestQuote: () => void;

  voteUp: () => void;
  voteDown: () => void;
  
  newestAnswer: IMessageModel | null;
}

export const MessageInteractionBar: FC<IMessageInteractionBarProps> = ({
  hasVoting,
  hasThreading,
  hasQuotes,
  isDeleted,
  isOwnMessage,
  numChildren,
  numVotes,
  userVoting,
  requestThread,
  requestQuote,
  voteUp,
  voteDown,
  newestAnswer,
}) => {
  const theme = useTheme();
  const avatarSrc = newestAnswer?.createdByResolved?.content?.avatar ? assetUrl(newestAnswer.createdByResolved?.content?.avatar, true) : undefined;
  const fullName = newestAnswer?.createdByResolved?.content?.fullName || 'Ehemaliger Benutzer';
  const answerTimestamp = newestAnswer?.modifiedAt ? resolveTimestamp(newestAnswer?.modifiedAt) : resolveTimestamp(newestAnswer?.createdAt);
  const abbreviation = buildAbbreviation(fullName);
  const hash = hashCode(abbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  return (
    <>
      {(hasVoting || hasThreading || hasQuotes) && (
        <Bx
          mt={2}
          style={{
            borderTop: hasVoting ? `1px solid ${theme.palette.divider}` : '',
            borderBottom: hasVoting ? `1px solid ${theme.palette.divider}` : ''
          }}
          display={hasVoting ? 'flex' : undefined}
        >
          {!isDeleted && hasVoting && (
            <>
              <Bx
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={isOwnMessage ? 'background.paper' : 'secondary.main'}
                color={isOwnMessage ? 'primary.main' : 'background.paper'}
                minWidth={36}
              >
                <Typo variant="h4">{numVotes || 0}</Typo>
              </Bx>
              <Bx style={{ borderRight: `1px solid ${theme.palette.divider}` }}>
                <IconBtn disabled={userVoting !== undefined && userVoting > 0} onClick={voteUp} size="small" color="inherit">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </IconBtn>
              </Bx>
              <Bx style={{ borderRight: `1px solid ${theme.palette.divider}` }}>
                <IconBtn disabled={userVoting !== undefined && userVoting < 0} onClick={voteDown} size="small" color="inherit">
                  <FontAwesomeIcon icon={faThumbsDown} />
                </IconBtn>
              </Bx>
            </>
          )}

          {!!(numChildren && numChildren > 0) &&
            <Bx>
              <Typo component="p" variant={'body3'}>{fullName} hat geantwortet ({answerTimestamp}):</Typo>
              <Btn
                onClick={() => requestThread()}
                color={'inherit'}
                size="small"
                startIcon={<Ava size={'small'}
                  src={avatarSrc}
                  style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500, fontSize: '14px'}}>
                  {abbreviation}
                </Ava>}
                noXPadding
                disabled={!numChildren && isDeleted}
              >
                <AnswerTeaser newestAnswer={newestAnswer} isOwnMessage={isOwnMessage} />
              </Btn>
            </Bx>}

          {hasThreading && (
            <Bx
              mr={!hasVoting ? 1 : 0}
              ml={hasVoting ? 'auto' : 0}
              display={hasVoting ? 'flex' : undefined}
              alignItems={hasVoting ? 'center' : undefined}
            >
              <Btn
                onClick={() => requestThread()}
                noXPadding
                color={isOwnMessage || isDeleted ? 'inherit' : 'primary'}
                size="small"
                startIcon={<FontAwesomeIcon icon={faReply} />}
                disabled={!numChildren && isDeleted}
              >
                {(!numChildren || numChildren === 1) && 'Jetzt antworten'}
                {!!(numChildren && numChildren > 1) && `${numChildren - 1} weitere Antworten`}
              </Btn>
            </Bx>
          )}

          {!isDeleted && hasQuotes && !hasThreading && (
            <Bx
              mr={!hasVoting ? 1 : 0}
              ml={hasVoting ? 'auto' : 0}
              display={hasVoting ? 'flex' : undefined}
              alignItems={hasVoting ? 'center' : undefined}
            >
              <Btn onClick={() => requestQuote()} noXPadding color={isOwnMessage ? 'inherit' : 'primary'} size="small" startIcon={<FontAwesomeIcon icon={faQuoteRight} />}>
                Zitieren
              </Btn>
            </Bx>
          )}
        </Bx>
      )}
    </>
  );
};

export interface IAnswerTeaserProps {
  newestAnswer: IMessageModel | null;
  isOwnMessage?: boolean;
}

export const AnswerTeaser: FC<IAnswerTeaserProps> = ({ newestAnswer, isOwnMessage }) => {
  if (!newestAnswer) {
    return <></>;
  }
  return (
    <Bx py={1} px={2} textAlign="left">
      <Typo component="span" variant={'body2'} style={{color: 'inherit'}}>
        {shorten(newestAnswer.content, 100)}
      </Typo>
    </Bx>
  );
};