import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transformForList, transformForCard, IThing, transformForActivityStream, IMemoryListItem, ThingTypes } from '../../model/ryve/Thing';
import { IPageElement, PageElementContentSelection, PageElementVisualization } from '../../model/data/PageElement';
import { ListWidget } from '../widget/list';
import { ContentCardCollection } from '../widget/content-cards';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { showAllPageElements, triggerPageElementDataRequests } from '../../data/reducer/data';
import { ActivityStream } from '../widget/activitystream';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { removeDashboardUrl } from '../../helper';
import { fetchFilterRequestAction, setSelectedSearchAction, showFilterAction } from '../../data/actions/filter';
import { setBotSearchAction } from '../../data/actions/bot';
import { INotification } from '../../model/notifications';
import { userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { EmptyContent } from '../communication/empty-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';
import { setFlowDataAction } from '../../data/actions/flow';
import { BotSeearch } from '../widget/botsearch';

export const PageElement: React.FC<{
  config: IPageElement;
  id: string;
  memoryIds?: Array<string>;
  memoryList?: boolean;
}> = ({
  config,
  id,
  memoryIds,
  memoryList
}) => {
  const state = useSelector(state => state.data[id]);
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications?.items);
  const excludeOwn = useSelector(state => state.filter?.filterItem?.content?.excludeOwn);
  const profileId = useSelector(state => state.foundation?.profile?.profileId);

  const [unreadNotifications, setUnreadNotifications] = useState<Array<INotification>>([]);

  const dispatcher = {
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  useEffect(() => {
    if (notifications && notifications.length) {
      setUnreadNotifications(notifications.filter(notification => !notification.content?.notificationRead))
    }
  }, [notifications]);

  if (config.onlyFromUserMemoryList && state?.totalCount === 0) {
    return <></>;
  }

  if (!config.pageElementVisualization__ || config.pageElementVisualization__ === PageElementVisualization.LIST) {
    return (
      <Bx mb={memoryList ? 4 : 6}>
        <ListWidget
          backgroundWorking={state?.backgroundWorking}
          working={state?.working}
          items={transformForList(
            (state?.items || []).map(item => {
              return {
                _source: item,
                _id: item._id,
                _score: item._score,
                badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
                  : memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed),
                profileId: memoryList ? profileId : undefined
              };
            })
          )}
          headline={
            config.pageElementTitle +
            (state?.canShowAll && config.pageElementContentSelection__ !== PageElementContentSelection.MANUAL && config.pageElementShowResultCount
              ? ' (' + state?.totalCount + ')'
              : '')
          }
          showAllClick={
            state?.canShowAll
            ? () => {
                if (config.pageElementThingDescriptions?.length === 1 && config.pageElementThingDescriptions[0] === "00000000-0000-0000-0000-000000000001") {
                  dispatch(fetchFilterRequestAction({ alias: 'experts' }));
                  dispatch(setSelectedSearchAction({ searchAlias: 'experts' }));
                }
                dispatch(showAllPageElements({ pageElement: config, history, route: removeDashboardUrl(match.url) }));
                dispatch(setBotSearchAction({ botSearchActive: false }));
                dispatch(showFilterAction({}));
              }
            : undefined
          }
          showAllCaption={excludeOwn ? 'Alle weiteren anzeigen' : undefined}
          itemMemoClicked={(item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }))}
          shareClick={dispatcher.shareClick}
          divider={config.pageElementQueryAdditionalId === '9948ddfd-a3f2-418c-8dc7-07a106c69cfc' ? 'none' : 'bottom'}
          memoryList={memoryList}
        />
      </Bx>
    );
  } else if (config.pageElementVisualization__ === PageElementVisualization.CARD) {
    return (
      <ContentCardCollection
        backgroundWorking={state?.backgroundWorking}
        working={state?.working}
        items={transformForCard(
          (state?.items || []).map(item => {
            return {
              _source: item,
              _id: item._id,
              _score: item._score,
              badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
              booked: item.type === ThingTypes.News
                ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
                : memoryIds?.includes(item._id)
            };
          })
        )}
        headline={config.pageElementTitle}
        design={config.pageElementCardDesign__ || 'left-alternating'}
        itemMemoClicked={(item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE') => {
          dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }))
        }}
      />
    );
  } else if (config.pageElementVisualization__ === PageElementVisualization.MESSAGE) {
    return <ActivityStream 
      backgroundWorking={state?.backgroundWorking}
      working={state?.working}
      items={transformForActivityStream(state?.items)}
      headline={config.pageElementTitle}
    />;
  } else if (config.pageElementVisualization__ === PageElementVisualization.BOTSEARCH) {
    return <BotSeearch />;
  } else {
    return <></>;
  }
};

export const PageElementsResolve: React.FC<{
  prefix?: string;
  pageElements?: IPageElement[];
  detail?: IThing<any>;
  memoryList?: boolean;
  memoryIds?: Array<string>;
}> = ({
  detail,
  prefix,
  pageElements,
  memoryList,
  memoryIds
}) => {
  const dispatch = useDispatch();
  const initiallyEmpty = useRef(false);

  useEffect(() => {
    if ((!memoryIds || memoryIds?.length === 0) && !initiallyEmpty.current) {
      initiallyEmpty.current = true;
    }
  });

  useEffect(() => {
    dispatch(triggerPageElementDataRequests({ prefix, pageElements, detail }));
  }, [dispatch, pageElements, prefix, detail]);

  if (initiallyEmpty.current && !!memoryList && (!memoryIds || memoryIds?.length === 0)) {
    return (
      <EmptyContent
        caption={'Hier ist noch nichts...'}
        src={'/images/empty-memorylist.svg'}
        bottom={
          <Bx pt={2}>
            <Typo color={'text.secondary'} variant="subtitle1">
              In allen Ansichten können Sie für die diversen Inhalte Lesezeichen ( <Typo component={'span'} color={'primary'}><FontAwesomeIcon icon={faBookmark} /></Typo> ) setzen.<br />
              Diese werden dann hier aufgelistet, unterteilt in die Destinations der MTO App.
            </Typo>
          </Bx>
        }
      />
    );
  }

  return (
    <>
      {pageElements?.map((pageElement, index) => (
        <PageElement key={index} config={pageElement} id={prefix + index.toString()} memoryIds={memoryIds} memoryList={memoryList} />
      ))}
    </>
  );
};
