import React from 'react';
import RequisitionForm from '../components/RequisitionForm';
import PublicSelect from '../components/PublicSelect';
import TypeSelect from '../components/TypeSelect';
import IdeaForm from '../components/IdeaForm';
import PublishSelect from '../components/PublishSelect';
import { ConnectedQnAForm } from '../components/QnAForm';
import CategoryForm from '../components/CategoryForm';
import FinishFlow from '../components/FinishFlow';
import GroupTypeSelect from '../components/GroupTypeSelect';
import GroupVisibilitySelect from '../components/GroupVisibilitySelect';
import WorkgroupForm from '../components/WorkgroupForm';
import WorkgroupCategoryForm from '../components/WorkgroupCategoryForm';
import { ConnectedGroupUserSelect } from '../components/GroupUserSelect';
import { ConnectedWorkgroupInvitationForm } from '../components/WorkgroupInvitationForm';
import ProjectCategoryForm from '../components/ProjectCategoryForm';
import ProjectForm from '../components/ProjectForm';
import ProjectConfirmTerms from '../components/ProjectConfirmTerms';
import ProjectVisibilitySelect from '../components/ProjectVisibilitySelect';
import UserPasswordForm from '../components/UserPasswordForm';
import UserDataForm from '../components/UserDataForm';
import { ConnectedUserEmployerIntro } from '../components/UserEmployerIntro';
import { ConnectedEmployerSearch } from '../components/EmployerSearch';
import { ConnectedEmployerForm } from '../components/EmployerForm';
import { ConnectedEmployerTypeSelect } from '../components/EmployerTypeSelect';
import CompetencesForm from '../components/CompetencesForm';
import InterestsForm from '../components/InterestsForm';
import ShareTypeSelect from '../components/ShareTypeSelect';
import { ConnectedChatSelect } from '../components/ChatSelect';
import ShareMessageForm from '../components/ShareMessageForm';
import EmailDataForm from '../components/EmailDataForm';
import LostPassFormData from '../components/LostPassFormData';
import ResetPassFormData from '../components/ResetPassFormData';
import { ConnectedMeetingTypeSelect } from '../components/MeetingTypeSelect';
import { ConnectedCreateMeetingForm } from '../components/CreateMeetingForm';
import UserTermsForm from '../components/UserTermsForm';
import { ConnectedEmployerCategorySelect } from '../components/EmployerCategorySelect';
import { ConnectedEmployerSignatureForm } from '../components/EmployerSignatureForm';
import EmailUnsubscribeForm from '../components/EmailUnsubscribeForm';

const GetComponent = (component: string) => {
  switch (component) {
    case 'createCategoryForm':
      return 'categoryForm';
    case 'createGroupTypeSelect':
      return 'groupTypeSelect';
    case 'createGroupUserSelect':
      return 'groupUserSelect';
    case 'createGroupVisibilitySelect':
      return 'groupVisibilitySelect';
    case 'createIdeaForm':
      return 'ideaForm';
    case 'createQnAForm':
      return 'qnaForm';
    case 'createRequisitionForm':
      return 'requisitionForm';
    case 'createPublicSelect':
      return 'publicSelect';
    case 'createPublishSelect':
      return 'publishSelect';
    case 'createTypeSelect':
      return 'typeSelect';
    case 'createWorkgroupForm':
      return 'workgroupForm';
    case 'createWorkgroupCategoryForm':
      return 'workgroupCategoryForm';
    case 'createWorkgroupInvitationForm':
      return 'workgroupInvitationForm';
    case 'finishFlow':
      return 'finishFlow';
    case 'createProjectConfirmTerms':
      return 'projectConfirmTerms';
    case 'createProjectVisibilitySelect':
      return 'projectVisibilitySelect';
    case 'createProjectForm':
      return 'projectForm';
    case 'createProjectCategoryForm':
      return 'projectCategoryForm';
    case 'registerUserPassword':
      return 'userPasswordForm';
    case 'registerUserData':
      return 'userDataForm';
    case 'registerEmployerIntro':
      return 'userEmployerIntro';
    case 'registerEmployerSearch':
      return 'employerSearch';
    case 'registerCreateEmployerForm':
      return 'createEmployerForm';
    case 'registerCreateEmployerType':
      return 'createEmployerType';
    case 'registerCreateEmployerCategory':
      return 'createEmployerCategory';
    case 'registerCompetencesForm':
      return 'competencesForm';
    case 'registerInterestsForm':
      return 'interestsForm';
    case 'shareTypeSelect':
      return 'shareTypeSelect';
    case 'shareLocationSelect':
      return 'shareLocationSelect';
    case 'shareMessageForm':
      return 'shareMessageForm';
    case 'optinEmailData':
      return 'emailDataForm';
    case 'lostPassFormData':
      return 'lostPassFormData';
    case 'resetPassFormData':
      return 'resetPassFormData';
    case 'meetingTypeSelect':
      return 'meetingTypeSelect';
    case 'createMeetingForm':
      return 'createMeetingForm';
    case 'registerNewTerms':
      return 'registerNewTerms';
    case 'registerEmployerSignatureForm':
      return 'employerSignatureForm';
    case 'optoutSubscriptionData':
      return 'optoutSubscriptionDataForm';
  }
};

const ComponentSelector: any = {
  categoryForm: CategoryForm,
  groupTypeSelect: GroupTypeSelect,
  groupUserSelect: ConnectedGroupUserSelect,
  groupVisibilitySelect: GroupVisibilitySelect,
  workgroupForm: WorkgroupForm,
  workgroupCategoryForm: WorkgroupCategoryForm,
  workgroupInvitationForm: ConnectedWorkgroupInvitationForm,
  projectConfirmTerms: ProjectConfirmTerms,
  projectVisibilitySelect: ProjectVisibilitySelect,
  projectForm: ProjectForm,
  projectCategoryForm: ProjectCategoryForm,
  ideaForm: IdeaForm,
  qnaForm: ConnectedQnAForm,
  requisitionForm: RequisitionForm,
  shareTypeSelect: ShareTypeSelect,
  shareLocationSelect: ConnectedChatSelect,
  shareMessageForm: ShareMessageForm,
  typeSelect: TypeSelect,
  publishSelect: PublishSelect,
  publicSelect: PublicSelect,
  finishFlow: FinishFlow,
  userPasswordForm: UserPasswordForm,
  userDataForm: UserDataForm,
  userEmployerIntro: ConnectedUserEmployerIntro,
  employerSearch: ConnectedEmployerSearch,
  createEmployerForm: ConnectedEmployerForm,
  createEmployerType: ConnectedEmployerTypeSelect,
  createEmployerCategory: ConnectedEmployerCategorySelect,
  competencesForm: CompetencesForm,
  interestsForm: InterestsForm,
  emailDataForm: EmailDataForm,
  lostPassFormData: LostPassFormData,
  resetPassFormData: ResetPassFormData,
  meetingTypeSelect: ConnectedMeetingTypeSelect,
  createMeetingForm: ConnectedCreateMeetingForm,
  registerNewTerms: UserTermsForm,
  employerSignatureForm: ConnectedEmployerSignatureForm,
  optoutSubscriptionDataForm: EmailUnsubscribeForm,
};

const FlowComponents = (
  index: number,
  flowStep: any,
  categories: any,
  flowFunctions: any,
  flowData: any,
  config: any,
  currentType: string,
  flowAnonymous: boolean,
  finishWorking: boolean,
  finishUrl: string,
  setNextStepDisabled: any,
  nextClick: any,
  filterActive: boolean,
  search: any
) => {
  const component = GetComponent(flowStep?.component);
  if (typeof component !== 'undefined' && typeof ComponentSelector[component] !== 'undefined') {
    return React.createElement(ComponentSelector[component], {
      key: `${component}_${index}`,
      componentKey: `${component}_${index}`,
      flowData,
      config,
      categories,
      flowFunctions,
      currentType,
      flowAnonymous,
      finishWorking,
      finishUrl,
      setNextStepDisabled,
      nextClick,
      filterActive,
      search,
      flowStep,
    });
  }
  return React.createElement(() => <div>The component {component} has not been created yet.</div>, { key: `flow_no_component_${index}` });
};

export default FlowComponents;
