import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FramedContent } from '../../framed-content';

const GroupTypeSelect = (props: any) => {
  const history = useHistory();
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Was möchten Sie tun?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Hier können Sie sich einer laufenden Aktivität anschließen oder eine neue Kooperation ins Leben rufen.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'searchgroup'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              onClick={() => history.push('/coop/groups-projects/dashboard')}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/arbeitsgruppe-oder-projekt-beitreten.svg" alt="Arbeitsgruppe oder Projekt beitreten" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Communities beitreten
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Für den Einstieg in eine offene Gruppe oder eine Beitrittsanfrage bei einem geschlossenen Format.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'workgroup'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowType('workgroup');
                setTimeout(() => {
                  props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/arbeitsgruppe-starten.svg" alt="Arbeitsgruppe starten" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Arbeitsgruppe starten
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Für den fachlichen Austausch und Kooperation in einer offenen oder geschlossenen Gruppe.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'project'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowType('project');
                setTimeout(() => {
                  props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/projekt-starten.svg" alt="Projekt starten" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Projekt starten
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Für die projektbezogene Zusammenarbeit in einem geschlossenen Team.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export default GroupTypeSelect;
