import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { Skeleton } from '@material-ui/lab';
import { Bx, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { ContentCard, ProfileCard, SkeletonCard } from '../../content-card';
import { IListItem } from '../../../model/list/ListItem';
import { IMemoryListItem, ThingTypes } from '../../../model/ryve/Thing';
import { HeadlineContainer } from '../../headline-container';
import _ from 'lodash';

export interface IDimensions {
  xs?: 10 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;
  sm?: 10 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;
  md?: 10 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;
  lg?: 10 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;
}

export interface IContentCardCollection {
  headline: string;
  working?: boolean | undefined;
  backgroundWorking?: boolean | undefined;
  ignoreWorking?: boolean | undefined;
  items: Array<IListItem>;
  design?: 'left' | 'left-alternating' | 'right' | 'right-alternating' | 'flat';
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE') => void;
}

export const sizeToDimensions: (size: 'large' | 'medium' | 'small') => IDimensions = size => {
  switch (size) {
    case 'large':
      return { xs: 12, sm: 6, md: 7, lg: 8 };
    case 'medium':
      return { xs: 12, sm: 6, md: 5, lg: 4 };
    case 'small':
    default:
      return { xs: 12, md: 4 };
  }
};

const arrangeCards: (
  items: Array<IListItem>,
  design?: IContentCardCollection['design'],
  keyHelper?: number,
  isLgUp?: boolean,
  isMdUp?: boolean,
  isSmUp?: boolean,
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE') => void,
) => Array<React.ReactFragment> = (
  items,
  design,
  keyHelper,
  isLgUp,
  isMdUp,
  isSmUp,
  itemMemoClicked
) => {
  let cards: Array<React.ReactFragment> = [];

  let orderedCards: Array<IListItem> = [];
  if (design && design !== 'flat') {
    const bigCards = items.filter(item => {
      switch (item.typeId) {
        case ThingTypes.Requisition:
        case ThingTypes.Collection:
          return true;
        default:
          return false;
      }
    });
    if (bigCards.length) {
      const smallCards = items.filter(item => {
        switch (item.typeId) {
          case ThingTypes.Requisition:
          case ThingTypes.Collection:
            return false;
          default:
            return true;
        }
      });

      for (let i = 0; i < items.length; i++) {
        if (design === 'left') {
          if (i % 3 === 0) {
            const temp = bigCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          } else {
            const temp = smallCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          }
        }
        if (design === 'left-alternating') {
          if (i % 6 === 0 || i % 6 === 5) {
            const temp = bigCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          } else {
            const temp = smallCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          }
        }
        if (design === 'right') {
          if (i % 3 === 2) {
            const temp = bigCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          } else {
            const temp = smallCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          }
        }
        if (design === 'right-alternating') {
          if (i % 6 === 2 || i % 6 === 3) {
            const temp = bigCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          } else {
            const temp = smallCards.shift();
            if (!temp) break;
            orderedCards.push(temp);
          }
        }
      }
    } else design = 'flat';
  }

  //Items MUSS Vielfaches von 3 sein, überzählige Items werden entfernt
  const length = Math.floor(orderedCards.length / 3) * 3;
  orderedCards = orderedCards.slice(0, length);

  if (!design || design === 'flat') {
    cards = items.map((item, idx) => {
      return (
        <Grid key={idx} item style={{ padding: 2 }} {...sizeToDimensions('small')}>
          {item.typeId === ThingTypes.Profile &&
            <ProfileCard key={idx}
              item={item}
              size="small"
              isLgUp={isLgUp}
              isMdUp={isMdUp}
              isSmUp={isSmUp}
              itemMemoClicked={itemMemoClicked} 
            />
          }
          {item.typeId !== ThingTypes.Profile &&
            <ContentCard key={idx}
              item={item}
              size="small"
              isLgUp={isLgUp}
              isMdUp={isMdUp}
              isSmUp={isSmUp}
              itemMemoClicked={itemMemoClicked} 
            />}
        </Grid>
      );
    });
  }
  if (design && design === 'right') {
    for (let i = 0; i < orderedCards.length; i++) {
      if (i % 3 === 2) {
        cards.push(
          <Grid key={(keyHelper || 0) + i} item style={{ padding: 2 }} {...sizeToDimensions('large')}>
            <ContentCard
              key={(keyHelper || 0) + i}
              item={orderedCards[i]}
              size={isMdUp ? 'large' : isSmUp ? 'medium' : 'small'}
              isLgUp={isLgUp}
              isMdUp={isMdUp}
              isSmUp={isSmUp}
              itemMemoClicked={itemMemoClicked} 
            />
          </Grid>
        );
      } else {
        cards.push(
          <Grid key={(keyHelper || 0) + i} item style={{ padding: 2 }} {...sizeToDimensions('medium')}>
            <Bx mb={0.5}>
              <ContentCard key={(keyHelper || 0) + i}
                item={orderedCards[i]}
                size="small"
                isLgUp={isLgUp}
                isMdUp={isMdUp}
                isSmUp={isSmUp}
                itemMemoClicked={itemMemoClicked} 
              />
            </Bx>
            <Bx>
              <ContentCard key={(keyHelper || 0) + i + 1}
                item={orderedCards[i + 1]}
                size="small"
                isLgUp={isLgUp}
                isMdUp={isMdUp}
                isSmUp={isSmUp}
                itemMemoClicked={itemMemoClicked} 
              />
            </Bx>
          </Grid>
        );
        ++i;
      }
    }
  }
  if (design && design === 'left') {
    for (let i = 0; i < orderedCards.length; i++) {
      if (i % 3 === 0) {
        cards.push(
          <Grid key={(keyHelper || 0) + i} item style={{ padding: 2 }} {...sizeToDimensions('large')}>
            <ContentCard
              key={(keyHelper || 0) + i}
              item={orderedCards[i]}
              size={isMdUp ? 'large' : isSmUp ? 'medium' : 'small'}
              isLgUp={isLgUp}
              isMdUp={isMdUp}
              isSmUp={isSmUp}
              itemMemoClicked={itemMemoClicked} 
            />
          </Grid>
        );
      } else {
        cards.push(
          <Grid key={(keyHelper || 0) + i} item style={{ padding: 2 }} {...sizeToDimensions('medium')}>
            <Bx mb={0.5}>
              <ContentCard key={(keyHelper || 0) + i}
                item={orderedCards[i]}
                size="small"
                isLgUp={isLgUp}
                isMdUp={isMdUp}
                isSmUp={isSmUp}
                itemMemoClicked={itemMemoClicked}
              />
            </Bx>
            <Bx>
              <ContentCard key={(keyHelper || 0) + i + 1}
                item={orderedCards[i + 1]}
                size="small"
                isLgUp={isLgUp}
                isMdUp={isMdUp}
                isSmUp={isSmUp}
                itemMemoClicked={itemMemoClicked}
              />
            </Bx>
          </Grid>
        );
        ++i;
      }
    }
  }
  if (design && design === 'left-alternating') {
    for (let i = 0; i < orderedCards.length / 3; i++) {
      if (i % 2 === 0) cards.push(arrangeCards(orderedCards.slice(i * 3, (i + 1) * 3), 'left', i * 3, isLgUp, isMdUp, isSmUp, itemMemoClicked));
      else cards.push(arrangeCards(orderedCards.slice(i * 3, (i + 1) * 3), 'right', i * 3, isLgUp, isMdUp, isSmUp, itemMemoClicked));
    }
  }
  if (design && design === 'right-alternating') {
    for (let i = 0; i < orderedCards.length / 3; i++) {
      if (i % 2 === 0) cards.push(arrangeCards(orderedCards.slice(i * 3, (i + 1) * 3), 'right', i * 3, isLgUp, isMdUp, isSmUp, itemMemoClicked));
      else cards.push(arrangeCards(orderedCards.slice(i * 3, (i + 1) * 3), 'left', i * 3, isLgUp, isMdUp, isSmUp, itemMemoClicked));
    }
  }
  return cards;
};

//items.length MUSS ein vielfaches von 3 sein
export const ContentCardCollection: React.FC<IContentCardCollection> = ({
  headline,
  items,
  design,
  working,
  backgroundWorking,
  ignoreWorking=false,
  itemMemoClicked
}) => {
  const breakpoints = useBreakpoints();
  const [initialWorking, setInitialWorking] = useState(true && !ignoreWorking);

  useEffect(() => {
    if (working || backgroundWorking) {
      setInitialWorking(false);
    }
  }, [working, backgroundWorking]);

  return (
    <Bx>
      <HeadlineContainer mb={2} py={1}
        headline={initialWorking || working
          ? <Typo fontWeight={500} component="h3">
              <Skeleton animation="wave" style={{ width: '200px' }} />
            </Typo>
          : headline}
        headlineVariant="h3"
        hideUnderline />
      <Bx mb={6}>
        <Grid container style={{ width: 'calc(100% + 4px)', margin: -2 }}>
          {(() => {if (initialWorking || working) {
            return _.range(3).map(i => <Grid key={'loading-grid-'+i} item style={{ padding: 2 }} {...sizeToDimensions('small')}>
              <SkeletonCard key={'loading-card-'+i}
                item={{
                  _id:'loading-card-'+i,
                  title:'loading-card-'+i,
                  __original: {},
                  typeId: '',
                  type: '',
                  avatar: { type: 'none' },
                }}
                size="small"
                isLgUp={breakpoints.lgUp}
                isMdUp={breakpoints.mdUp}
                isSmUp={breakpoints.smUp}
              /></Grid>);
          } else {
            if (!items?.length) {
              return <Grid key={'empty-grid-0'} item style={{ padding: 2 }} {...sizeToDimensions('small')}>
                <ContentCard key={'empty-card-0'}
                  item={{
                    _id: 'empty-card-0',
                    title: 'Keine Ergebnisse gefunden.',
                    __original: {},
                    typeId: '',
                    type: '',
                    avatar: { type: 'none' },
                  }}
                  size="small"
                  isLgUp={breakpoints.lgUp}
                  isMdUp={breakpoints.mdUp}
                  isSmUp={breakpoints.smUp}
                  itemMemoClicked={itemMemoClicked}
                />;
              </Grid>
            } else {
              return arrangeCards(items, design, undefined, breakpoints.lgUp, breakpoints.mdUp, breakpoints.smUp, itemMemoClicked);
            }
          }})()}
          
        </Grid>
      </Bx>
    </Bx>
  );
};
