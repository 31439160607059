import { Bx, Typo } from '@curry-group/mui-curcuma';
import React, { useEffect, useState } from 'react';
import { filterTreeById, updateArraySelection } from '../../../helper';
import { FormCategorySelect } from '../controls/category-select';

interface IFormCategories {
  formData: any;
  config: any;
  categories: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  splashImage?: string;
  onChange: (value: any) => void;
}

export const FormCompetenceCategories: React.FC<IFormCategories> = ({ formData, config, categories, headline, subtitle, subtitleComponent, splashImage, onChange }) => {
  const [categoryData] = useState(formData?.itemData?.competenceCategories?.map(cat => cat.categoryId) || []);
  const [categoryItem, setCategoryItem] = useState('');
  const [categoryChange, setCategoryChange] = React.useState<number>(0);
  useEffect(() => {
    if (!categoryItem) {
      return;
    }
    const newCats = updateArraySelection(formData?.itemData?.competenceCategories?.map(cat => cat.categoryId) || Array<string>(), categoryItem);
    onChange({ ...formData, itemData: { ...formData?.itemData, competenceCategories: newCats.map(id => ({ categoryId: id })) } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryItem, categoryChange]);
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={headline || subtitle ? 8 : 0}>
        <FormCategorySelect
          value={categoryData}
          categoryTree={filterTreeById(categories?.categoryTree, '33d73fd4-a973-eac0-5a0b-4389d8ec11a8', false)}
          categoryRepository={categories?.items}
          label={'Innovation'}
          placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
          placeholderSearch={'Kompetenz finden...'}
          buttonTitle={'Kompetenzen hinzufügen'}
          minLength={0}
          maxLength={0}
          onChange={item => {
            setCategoryItem(item);
            setCategoryChange(change => change + 1);
          }}
          splashImage={splashImage}
        />
        <FormCategorySelect
          value={categoryData}
          categoryTree={filterTreeById(categories?.categoryTree, '2534fffe-6239-312f-facd-39f28059fda9', false)}
          categoryRepository={categories?.items}
          label={'Medizin'}
          placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
          placeholderSearch={'Kompetenz finden...'}
          buttonTitle={'Kompetenzen hinzufügen'}
          minLength={0}
          maxLength={0}
          onChange={item => {
            setCategoryItem(item);
            setCategoryChange(change => change + 1);
          }}
          splashImage={splashImage}
          allowParentSelection={true}
        />
        <FormCategorySelect
          value={categoryData}
          categoryTree={filterTreeById(categories?.categoryTree, 'dc173352-54c4-5809-8618-5d250ed9b597', false)}
          categoryRepository={categories?.items}
          label={'Medizinprodukte'}
          placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
          placeholderSearch={'Kompetenz finden...'}
          buttonTitle={'Kompetenzen hinzufügen'}
          minLength={0}
          maxLength={0}
          onChange={item => {
            setCategoryItem(item);
            setCategoryChange(change => change + 1);
          }}
          splashImage={splashImage}
        />
        <FormCategorySelect
          value={categoryData}
          categoryTree={filterTreeById(categories?.categoryTree, 'cd87ce7f-7823-80cc-1136-00eddc710bc9', false)}
          categoryRepository={categories?.items}
          label={'Wissenschaft'}
          placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
          placeholderSearch={'Kompetenz finden...'}
          buttonTitle={'Kompetenzen hinzufügen'}
          minLength={0}
          maxLength={0}
          onChange={item => {
            setCategoryItem(item);
            setCategoryChange(change => change + 1);
          }}
          splashImage={splashImage}
          allowParentSelection={true}
          parentSelectionStartDeepth={1}
        />
      </Bx>
    </React.Fragment>
  );
};
