import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bx, Ctn, IBxProps, ICtnProps } from '@curry-group/mui-curcuma';

export interface IBottomBarProps extends IBxProps {
  scrolled?: boolean;
  rootBoxProps?: IBxProps;
  ctnProps?: Omit<ICtnProps, 'children'>;
}

const useStyles = makeStyles(theme => ({
  rootBottomBar: {},
  rootBottomBarScrolled: {
    borderTop: '1px solid #DDDFE0'
  },
  innerBox: {
    borderTop: '1px solid #DDDFE0'
  }
}));

/**
 * The `BottomBar` component is an extension of the [Material UI Box](https://material-ui.com/components/box/#box) and inherits all properties of it.
 */
export const BottomBar: React.FunctionComponent<IBottomBarProps> = ({ rootBoxProps, ctnProps, scrolled, children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Bx
      position={'sticky' || rest.position}
      bgcolor={'#fff' || rest.bgcolor}
      zIndex={2}
      bottom={0 || rest.bottom}
      px={{ xs: 2, md: 2, lg: 5 }}
      className={clsx(classes.rootBottomBar, scrolled && classes.rootBottomBarScrolled, rootBoxProps?.className)}
      {..._.omit(rootBoxProps, 'className')}
    >
      <Ctn {...ctnProps}>
        <Bx py={2} className={clsx(!scrolled && classes.innerBox, className)} {...rest}>
          {children}
        </Bx>
      </Ctn>
    </Bx>
  );
};
