import { createAction } from '@reduxjs/toolkit';
import { INotification } from '../../../model/notifications';

export const setHasBadgeAction = createAction<
  {
    id: string;
    count: number;
  },
  'SET_HAS_BADGE'
>('SET_HAS_BADGE');

export const fetchNotificationsRequestAction = createAction('FETCH_NOTIFICATIONS_REQUEST');
export const fetchNotificationsSuccessAction = createAction<INotification[]>('FETCH_NOTIFICATIONS_SUCCESS');
export const fetchNotificationsFailedAction = createAction('FETCH_NOTIFICATIONS_FAILED');

export const fetchNotificationsNextPageRequestAction = createAction('FETCH_NOTIFICATIONS_NEXTPAGE_REQUEST');
export const fetchNotificationsNextPageSuccessAction = createAction<INotification[]>('FETCH_NOTIFICATIONS_NEXTPAGE_SUCCESS');
export const fetchNotificationsNextPageFailedAction = createAction('FETCH_NOTIFICATIONS_NEXTPAGE_FAILED');

export const notificationsUpdatedAction = createAction('NOTIFICATIONS_UPDATED_ACTION');

export const setNotificationsReadRequestAction = createAction<{id: string}, 'SET_NOTIFICATION_READ_REQUEST'>('SET_NOTIFICATION_READ_REQUEST');
export const setNotificationsReadSuccessAction = createAction('SET_NOTIFICATION_READ_SUCCESS');
export const setNotificationsReadFailedAction = createAction('SET_NOTIFICATION_READ_FAILED');

export const setNotificationParentReadRequestAction = createAction<{alias: string}, 'SET_NOTIFICATION_PARENT_READ_REQUEST'>('SET_NOTIFICATION_PARENT_READ_REQUEST');
export const setNotificationParentReadSuccessAction = createAction('SET_NOTIFICATION_PARENT_READ_SUCCESS');
export const setNotificationParentReadFailedAction = createAction('SET_NOTIFICATION_PARENT_READ_FAILED');