import { Btn, Bx, Ctn, HorizontalScroll, Typo } from '@curry-group/mui-curcuma';
import { faRedo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@material-ui/core';
import React from 'react';
import { ThingTypes } from '../../model/ryve/Thing';
import { ISearchRequestBody } from '../../model/search/SearchRequest';
import FilterComponents from './components';
import { UnconnectedCategoryFilter } from './components/CategoryFilter';
import { UnconnectedDefaultSort } from './components/Sort';
import { ConnectedSearchTypeSelect } from './components/SearchTypeSelect';

interface IFilter {
  filterItem: any;
  filterAlias: string;
  filtersVisible: boolean;
  filterWorking: boolean;
  filterSwitch?: boolean;
  searchResult: any;
  searchObject?: ISearchRequestBody;
  searchTypes?: Array<string>;
  selectedSearch?: string;
  handleClick?: () => void;
  resetSearchFilter?: () => void;
  onTypeChange?: (thingType: string) => void;
  onDestinationChange?: (thingType: string) => void;
  onCategoryChange?: (category: any, noToggle: boolean, parentCategoryId: string) => void;
  onSortChange?: (sortId: string) => void;
  onSearchTypeChange?: (searchTypeId: string) => void;
  setSelectedSearch?: (searchAlias: string, keepQuery?: boolean) => void;
  smallVariant?: boolean;
  config?: {
    useUnconnectedSort?: boolean;
    useUnconnectedCategoryFilter?: boolean;
  };
  setNewsAllConcepts?: () => void;
  newsAllConcepts?: boolean;
  
  positiveFeedback?: Array<{ name: string; value: string; }>;
  negativeFeedback?: Array<{ name: string; value: string; }>;
}

export const HeaderFilter: React.FC<IFilter> = ({
  filterItem,
  filterAlias,
  filtersVisible,
  filterWorking,
  filterSwitch,
  searchObject,
  searchTypes,
  selectedSearch,
  searchResult,
  resetSearchFilter,
  setSelectedSearch,
  onTypeChange,
  onDestinationChange,
  onCategoryChange,
  onSortChange,
  onSearchTypeChange,
  smallVariant,
  config,
  setNewsAllConcepts,
  newsAllConcepts
}) => {
  const [open, setOpen] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget.getAttribute('aria-controls') ?? '');
    setAnchorEl(event.currentTarget);
  };
  const filterFunctions = {
    click: handleClick,
    open: setOpen,
    onTypeChange: onTypeChange,
    onDestinationChange: onDestinationChange,
    onCategoryChange: onCategoryChange,
    onSortChange: onSortChange,
    onSearchTypeChange: onSearchTypeChange
  };
  const filterCount = filterItem?.content?.filterCategories?.length ?? 0;
  const aggregations = {
    categories: !searchResult?.hits?.hits ? filterItem?.content?.initialAggregations : searchResult?.aggregations,
    types: !searchResult?.hits?.hits ? filterItem?.content?.initialAggregationsTypes : searchResult?.aggregations_types,
    destinations: !searchResult?.hits?.hits ? filterItem?.content?.initialAggregationsDestinations : searchResult?.aggregations_destinations
  };
  const resultCount = searchResult?.hits?.total ?? filterItem?.content?.initialTotalCount;
  return (
    <Collapse in={filtersVisible}>
      <React.Fragment>
        <Bx bgcolor="background.paper" py={smallVariant ? 1 : 2} px={smallVariant ? 1 : 5}>
          {!filterWorking && (
            <Ctn>
              <Bx display="flex">
                <HorizontalScroll gradientColor="background.paper">
                  <Bx display="flex">
                    {selectedSearch === 'news' && (
                      <Bx>
                        <Btn
                          title={'eigene Gruppen'}
                          size={smallVariant ? 'medium' : 'large'}
                          color={newsAllConcepts ? 'primary' : 'default'}
                          variant={newsAllConcepts ? 'contained' : 'outlined'}
                          onClick={e => {setNewsAllConcepts?.();}}
                        >
                          <Typo variant="inherit" noWrap>
                            {newsAllConcepts ? 'Persönliche News durchsuchen' : 'Alle News durchsuchen'}
                          </Typo>
                        </Btn>
                      </Bx>
                    )}
                    {filterSwitch && ['groups-projects', 'new-groups-projects'].includes(selectedSearch || '') && (
                      <Bx>
                        <Btn
                          title={'eigene Gruppen'}
                          size={smallVariant ? 'medium' : 'large'}
                          color={selectedSearch === 'new-groups-projects' ? 'primary' : 'default'}
                          variant={selectedSearch === 'new-groups-projects' ? 'contained' : 'outlined'}
                          onClick={e => {
                            const changeAlias = selectedSearch !== 'new-groups-projects' ? 'new-groups-projects' : 'groups-projects';
                            setSelectedSearch?.(changeAlias, true);
                          }}
                        >
                          <Typo variant="inherit" noWrap>
                            eigene Gruppen ausblenden
                          </Typo>
                        </Btn>
                      </Bx>
                    )}
                    {filterSwitch && ['experts-actors', 'actors', 'experts'].includes(selectedSearch || '') && (
                      <React.Fragment>
                        <Bx>
                          <Btn
                            title={'Akteure'}
                            size={smallVariant ? 'medium' : 'large'}
                            color={selectedSearch === 'actors' ? 'primary' : 'default'}
                            variant={selectedSearch === 'actors' ? 'contained' : 'outlined'}
                            onClick={e => {
                              const changeAlias = selectedSearch !== 'actors' ? 'actors' : 'experts-actors';
                              setSelectedSearch?.(changeAlias, true);
                            }}
                          >
                            <Typo variant="inherit" noWrap>
                              nur Akteure
                            </Typo>
                          </Btn>
                        </Bx>
                        <Bx ml={1}>
                          <Btn
                            title={'Personen'}
                            size={smallVariant ? 'medium' : 'large'}
                            color={selectedSearch === 'experts' ? 'primary' : 'default'}
                            variant={selectedSearch === 'experts' ? 'contained' : 'outlined'}
                            onClick={e => {
                              const changeAlias = selectedSearch !== 'experts' ? 'experts' : 'experts-actors';
                              setSelectedSearch?.(changeAlias, true);
                            }}
                          >
                            <Typo variant="inherit" noWrap>
                              nur Personen
                            </Typo>
                          </Btn>
                        </Bx>
                      </React.Fragment>
                    )}
                    {selectedSearch === 'mychats' && (
                      <React.Fragment>
                        <Bx>
                          <Btn
                            title={'Chats'}
                            size={smallVariant ? 'medium' : 'large'}
                            color={searchObject?.queryTypes?.includes(ThingTypes.OneToOne) ? 'primary' : 'default'}
                            variant={searchObject?.queryTypes?.includes(ThingTypes.OneToOne) ? 'contained' : 'outlined'}
                            onClick={e => {
                              searchObject?.queryTypes?.forEach(type => {
                                if (type !== ThingTypes.OneToOne) onTypeChange?.(type);
                              });
                              onTypeChange?.(ThingTypes.OneToOne);
                            }}
                          >
                            <Typo variant="inherit" color="inherit" noWrap>
                              nur Chats
                            </Typo>
                          </Btn>
                        </Bx>
                        <Bx ml={1}>
                          <Btn
                            title={'Arbeitsgruppen'}
                            size={smallVariant ? 'medium' : 'large'}
                            color={searchObject?.queryTypes?.includes(ThingTypes.Group) ? 'primary' : 'default'}
                            variant={searchObject?.queryTypes?.includes(ThingTypes.Group) ? 'contained' : 'outlined'}
                            onClick={e => {
                              searchObject?.queryTypes?.forEach(type => {
                                if (type !== ThingTypes.Group) onTypeChange?.(type);
                              });
                              onTypeChange?.(ThingTypes.Group);
                            }}
                          >
                            <Typo variant="inherit" color="inherit" noWrap>
                              nur Arbeitsgruppen
                            </Typo>
                          </Btn>
                        </Bx>
                        <Bx ml={1}>
                          <Btn
                            title={'Projekte'}
                            size={smallVariant ? 'medium' : 'large'}
                            color={searchObject?.queryTypes?.includes(ThingTypes.Project) ? 'primary' : 'default'}
                            variant={searchObject?.queryTypes?.includes(ThingTypes.Project) ? 'contained' : 'outlined'}
                            onClick={e => {
                              searchObject?.queryTypes?.forEach(type => {
                                if (type !== ThingTypes.Project) onTypeChange?.(type);
                              });
                              onTypeChange?.(ThingTypes.Project);
                            }}
                          >
                            <Typo variant="inherit" color="inherit" noWrap>
                              nur Projekte
                            </Typo>
                          </Btn>
                        </Bx>
                      </React.Fragment>
                    )}
                    {!config?.useUnconnectedCategoryFilter &&
                      (filterItem?.content?.filterCategories || []).map((filter: any, index: number) => {
                        const ml = index > 0 || filterSwitch ? 1 : 0;
                        return FilterComponents(index, filter, aggregations, resultCount, ml, filterFunctions, anchorEl, open, smallVariant);
                      })}
                    {config?.useUnconnectedCategoryFilter &&
                      (filterItem?.content?.filterCategories || []).map((filter: any, index: number) => {
                        const ml = index > 0 || filterSwitch ? 1 : 0;
                        const key = 'uc_catfilter_' + index;
                        return (
                          <UnconnectedCategoryFilter
                            key={key}
                            anchorEl={anchorEl}
                            componentKey={key}
                            filterCategoryItem={filter}
                            filterItem={filterItem}
                            marginLeft={ml}
                            onClick={handleClick}
                            onClose={() => {
                              setOpen('');
                            }}
                            open={open}
                            aggregations={aggregations}
                            smallVariant={smallVariant}
                            onCategoryChanged={(a, b) => {
                              onCategoryChange?.(a, false, b);
                            }}
                          />
                        );
                      })}
                    {/* {FilterComponents(filterCount, { type: 'calendar' }, {}, 0, 1, filterFunctions, anchorEl, open)} */}
                  </Bx>
                </HorizontalScroll>
                {filterAlias !== 'news' &&
                <Bx display="flex">
                  {filterAlias === 'news' &&
                    <ConnectedSearchTypeSelect
                      selectedSearch={selectedSearch}
                      filter={{ type: 'searchTypeSelect', alias: filterAlias }}
                      filterFunctions={filterFunctions}
                      anchor={anchorEl}
                      open={open}
                      smallVariant={smallVariant}
                    />}
                  {!config?.useUnconnectedSort &&
                    FilterComponents(filterCount, { type: 'sort', alias: filterAlias }, {}, 0, 1, filterFunctions, anchorEl, open, smallVariant)}
                  {config?.useUnconnectedSort && (
                    <UnconnectedDefaultSort
                      componentKey="ucsort_0"
                      anchorEl={anchorEl}
                      marginLeft={1}
                      onClick={handleClick}
                      onClose={() => {
                        setOpen('');
                      }}
                      open={open}
                      smallVariant={smallVariant}
                      onSortChanged={onSortChange}
                      sortId={filterItem?.content?.defaultSort?.content?.type + '_' + filterItem?.content?.defaultSort?.content?.direction}
                    />
                  )}
                  <Bx ml={1}>
                    <Btn style={{height: smallVariant?"36px":"48px"}} title="Zurücksetzen" size={smallVariant ? 'small' : 'medium'} variant="outlined" onClick={resetSearchFilter} startIcon={<FontAwesomeIcon icon={faRedo} />}>
                      Zurücksetzen
                    </Btn>
                  </Bx>
                </Bx>}
                {filterAlias === 'news' &&
                <Bx display="flex">
                  <Bx ml={1} style={{height: smallVariant?"36px":"48px"}}>
                    {/* *empty box for styling* */}
                  </Bx>
                </Bx>}
              </Bx>
            </Ctn>
          )}
        </Bx>
      </React.Fragment>
    </Collapse>
  );
};
