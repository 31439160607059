import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { validateEmail } from '../../../helper/validator';
import { FormInput } from '../controls/input';

interface IFormUser {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (value: any) => void;
}

export const FormEmailData: React.FC<IFormUser> = ({ formData, headline, subtitle, subtitleComponent, onChange }) => {
  const blacklist = useSelector(state => state.foundation?.blacklist);
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.itemData?.email}
                    label={'Berufliche oder Hochschul-E-Mail-Adresse (= Benutzername)'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={0}
                    maxLength={0}
                    onChange={val => {
                      onChange({ ...formData, itemData: { ...formData?.itemData, email: val } });
                    }}
                    type={'email'}
                    validator={value => {
                      return validateEmail(value, blacklist);
                    }}
                    fieldGroup={'emailData'}
                    successMessage={''}
                    invalidMessage={'Die Adresse muss ein gültiges Format haben und darf keine öffentliche Mail-Domain enthalten.'}
                    infotext={
                      <React.Fragment>
                        Wir verwenden Ihre E-Mail-Adresse ausschließlich zur Anmeldung und zur Zusendung von Benachrichtigungen. Anderen Nutzenden auf dem Portal wird sie
                        nicht angezeigt.
                      </React.Fragment>
                    }
                    lazy
                    noEndAdornment
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
