import { createAction } from '@reduxjs/toolkit';

export const setBotSearchAction = createAction<{ botSearchActive?: boolean }, 'SET_BOT_SEARCH'>('SET_BOT_SEARCH');
export const botResponseChunkAction = createAction<{ chunk: string, timestamp: number }, 'SET_XPERTBOT_CHUNK'>('SET_XPERTBOT_CHUNK');
export const botResponseEndAction = createAction<{ response: string, request_id: string }, 'SET_XPERTBOT_END'>('SET_XPERTBOT_END');
export const resetBotRequestAction = createAction('RESET_BOT_REQUEST');
export const trackBotFeedbackAction = createAction<{
  botRequestId: string,
  feedback: string,
  rating: number,
  queryTerm?: string,
  anonToken?: string,
}, 'TRACK_BOT_FEEDBACK'>('TRACK_BOT_FEEDBACK');
export const resetBotFeedbackAction = createAction('RESET_BOT_FEEDBACK');

export const fetchBotAnswerRequestAction = createAction<{ anontoken: string }, 'FETCH_BOT_ANSWER_REQUEST'>('FETCH_BOT_ANSWER_REQUEST');
export const fetchBotAnswerErrorAction = createAction<{ error: string }, 'FETCH_BOT_ANSWER_ERROR'>('FETCH_BOT_ANSWER_ERROR');
export const fetchBotAnswerSuccessAction = createAction<{ result: string }, 'FETCH_BOT_ANSWER_SUCCESS'>('FETCH_BOT_ANSWER_SUCCESS');

export const botSetFingerprintAction = createAction<{ fingerprint: string }, 'SET_BOT_FINGERPRINT'>('SET_BOT_FINGERPRINT');
