import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormEmployer } from '../../form/compositions/FormEmployer';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';

const EmployerForm: React.FC<{ props: any; employerConfigs: any }> = ({ props, employerConfigs }) => {
  const employerType = props.flowData?.employerType && props.flowData?.employerType.length > 0 ? props.flowData?.employerType?.map(cat => cat.categoryId)[0] : '';
  let employerIsCompany = false;
  let employerHasParent = false;
  if (!!employerType) {
    const employerConfig = employerConfigs?.[employerType]
    if (employerConfig?.content?.name.indexOf('company||') > -1) {
      employerIsCompany = true;
    }
    if (['health_institution||hospital_department',
      'health_institution||hospital_facility',
      'health_institution||hospital_location',
      'scientific_institution||university_facility',
      'scientific_institution||public_research_center_department',
      'scientific_institution||public_research_center_institute',
      'scientific_institution||non-public_research_institute',
      ].indexOf(employerConfig?.content?.name) > -1
    ) {
      employerHasParent = true;
    }
  }
  let employerInstitution = props.flowData?.userSelectedEmployerFirstLevel?._source?.content?.name;
  if (!employerType || (employerHasParent && !employerInstitution) || !FormValidator.isValid('', 'employer')) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormEmployer
        formData={props.flowData}
        typeConfig={employerConfigs?.[employerType]}
        headline={'Weitere Angaben'}
        subtitleComponent={<>
          <Bx mb={3}>
            <Typo variant="subtitle1">Die folgenden Angaben benötigen wir zur Identifikation Ihrer Organisation.</Typo>
            {employerIsCompany && <Typo variant="subtitle1">Die Handelsregisternummer finden Sie in der Regel in Ihrer E-Mail-Signatur.</Typo>}
          </Bx>
          {(!!employerInstitution || employerHasParent) && <Bx mb={1}>
            <Typo variant="subtitle1">Übergeordnete Institution:</Typo>
            <Typo variant="h4" color={!!employerInstitution ? 'textPrimary' : 'error'}>{!!employerInstitution ? employerInstitution : 'muss im vorherigen Schritt ausgewählt werden'}</Typo>
          </Bx>}
        </>}
        onChange={(formField, formValue, formName) => {
          props.flowFunctions.setFlowFormData(formField, formValue, formName);
        }}
        employerHasParent={employerHasParent}
      />
    </FramedContent>
  );
};

export const ConnectedEmployerForm = (props: any) => {
  const employerConfigs = useSelector(state => state.flow?.employerConfigs);
  return <EmployerForm props={{ ...props }} employerConfigs={employerConfigs} />;
};
