import React from 'react';
import { IDetailComponent } from '..';
import { AppFrameContent, AppFrameHeader } from '../../../components/frame';
import { ConnectedPlayerProfileComponent } from '../../../components/profile';
import { ConnectedDetailHeaderComponent } from '../../../components/detail/header';

export const ConnectedActorDetail: React.FC<IDetailComponent> = ({ isScrolled, setIsScrolled }) => {
  return (
    <>
      <AppFrameHeader>
        <ConnectedDetailHeaderComponent show={isScrolled} />
      </AppFrameHeader>
      <AppFrameContent>
        <ConnectedPlayerProfileComponent setIsScrolled={setIsScrolled} isScrolled={isScrolled}></ConnectedPlayerProfileComponent>
      </AppFrameContent>
    </>
  );
};
