import { FormUserTerms } from '../../form/compositions/FormUserTerms';
import { FramedContent } from '../../framed-content';

const UserTermsForm = (props: any) => {
  if (
    !props.flowData ||
    !props.flowData?.itemData?.termsAccepted ||
    !props.flowData?.itemData?.communityRulesAccepted
  ) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent display="flex" alignItems="center" height="100%">
      <FormUserTerms
        formData={props.flowData}
        headline={'Willkommen auf Medtec Online!'}
        subtitle={'Sie wurden als Experte für das Medtec Online-Portal registriert.'}
        subtitle2={'Bitte bestätigen Sie noch die Nutzungsbedingungen und Gemeinschaftsstandards um den Registrierungsprozeß abzuschließen.'}
        subtitle3={'Bitte passen Sie Ihr Passwort im Profil und hinterlegen dort Ihre Interessen, um personalisierte News angezeigt zu bekommen.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default UserTermsForm;
