import { createReducer } from '@reduxjs/toolkit';
import { INews } from '../../../model/news';
import {
  fetchFirstPageNewsErrorAction,
  fetchFirstPageNewsRequestAction,
  fetchFirstPageNewsSuccessAction,
  fetchNextPageNewsErrorAction,
  fetchNextPageNewsRequestAction,
  fetchNextPageNewsSuccessAction,
  resetNewsAction,
  resetNewsReferences,
  setNewsAllConcepts,
  setNewsWorking,
} from '../../actions/news';

const DEFAULT_PAGE_SIZE = 20;

export interface INewsState {
  references: INews[];
  items: INews[];
  first: boolean;
  filterChanged: boolean;
  working: boolean;
  moreDataAvailable: boolean;
  querySkip: number;
  queryPageSize: number;
  queryPage: number;
  error: string;
  allConcepts: boolean;
}
const initialState: INewsState = {
  references: [],
  items: [],
  first: true,
  filterChanged: false,
  working: false,
  moreDataAvailable: true,
  querySkip: 0,
  queryPageSize: DEFAULT_PAGE_SIZE,
  queryPage: 0,
  error: '',
  allConcepts: false
};

export const news = createReducer(initialState, builder =>
  builder
    .addCase(fetchFirstPageNewsRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        error: '',
        queryPage: 0,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchFirstPageNewsSuccessAction, (state, action) => {
      let items = action.payload.results || [];
      let moreDataAvailable = action.payload.moreDataAvailable || false;
      if (action.payload.botAnswer) {
        return {
          ...state,
          working: false,
          references: items
        };
      }
      return {
        ...state,
        first: false,
        filterChanged: false,
        items: items,
        error: '',
        querySkip: DEFAULT_PAGE_SIZE,
        queryPage: 1,
        moreDataAvailable
      };
    })
    .addCase(fetchFirstPageNewsErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        items: [],
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        queryPage: 0,
        moreDataAvailable: false,
        references: []
      };
    })
    .addCase(fetchNextPageNewsRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchNextPageNewsSuccessAction, (state, action) => {
      let items = action.payload.results || [];
      let moreDataAvailable = action.payload.moreDataAvailable || false;
      if (action.payload.botAnswer) {
        return {
          ...state,
          working: false,
          references: items
        };
      }
      const newPage = state.queryPage + 1;
      return {
        ...state,
        first: false,
        items: [...(state?.items || []), ...items],
        querySkip: newPage * DEFAULT_PAGE_SIZE,
        queryPage: newPage,
        moreDataAvailable
      };
    })
    .addCase(fetchNextPageNewsErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        queryPage: 0,
        moreDataAvailable: false,
      };
    })
    .addCase(resetNewsAction, (state, action) => {
      return {
        ...state,
        items: [],
        first: true,
        filterChanged: false,
        working: action.payload.loading,
        moreDataAvailable: true,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        queryPage: 0,
        error: '',
        references: []
      };
    })
    .addCase(resetNewsReferences, (state, action) => {
      return {
        ...state,
        references: []
      };
    })
    .addCase(setNewsAllConcepts, (state, action) => {
      return {
        ...state,
        allConcepts: state?.allConcepts === false ? true : false,
      };
    })
    .addCase(setNewsWorking, (state, action) => {
      return {
        ...state,
        working: action.payload.working,
      };
    })
);
