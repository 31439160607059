import React from 'react';
import { Anohana, Bx, Btn, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../headline-container';

export const CommunicationAllowChatModal: React.FC<{ chatAllowed: boolean; editChatAllowed: boolean; close?: () => void; cancel?: () => void; save?: () => void }> =
({ chatAllowed, editChatAllowed, close, cancel, save }) => {
  return (
    <Anohana open={editChatAllowed} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={chatAllowed ? 'Chat-Anfragen deaktivieren?' : 'Chat-Anfragen aktivieren?'}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={<IconBtn size="small" onClick={close}><FontAwesomeIcon icon={faTimes} /></IconBtn>}
      />
      <Bx py={3} px={3}>
        {chatAllowed && <Typo variant="body1">Das Deaktivieren dieser Option führt dazu, dass andere Nutzer Sie nicht mehr zu einem 1:1 Chat einladen dürfen.</Typo>}
        {!chatAllowed && <Typo variant="body1">Das Aktivieren dieser Option führt dazu, dass andere Nutzer Sie zu einem 1:1 Chat einladen dürfen.</Typo>}
        <Bx mt={2} display="flex" justifyContent="flex-end" alignItems="center">
          <Bx>
            <Btn onClick={save} color="success" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
              OK
            </Btn>
          </Bx>
          <Bx ml={1}>
            <Btn onClick={cancel} color="error" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Abbrechen
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
