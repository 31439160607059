import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSlash } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@material-ui/core';
import { ConferenceIconBtn } from '../../layout/icon-btn';

export interface IConferencingButton {
  active?: boolean;
  icon: IconProp;
  tooltip: string;
  size?: 'small' | 'large' | 'largeSpecial';
  emphasize?: boolean;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  noStrikethrough?: boolean;
  designType?: string;
}

export const ConferencingButton: React.FC<IConferencingButton> = ({ active, icon, tooltip, size, emphasize, disabled = false, noStrikethrough = false, designType, onClick }) => {
  let style: any = undefined;
  if (size === undefined) {
    style = { marginLeft: -3 };
  }
  return (
    <Tooltip title={tooltip} arrow>
      <ConferenceIconBtn
        color={emphasize ? 'red' : active ? 'light' : 'dark'}
        size={size}
        onClick={disabled ? undefined : onClick}
        style={{ opacity: disabled ? .5 : 1, cursor: disabled ? 'default' : 'pointer' }}
        designType={designType}
      >
        {(active || emphasize || noStrikethrough) && <FontAwesomeIcon icon={icon} />}
        {!active && !emphasize && !noStrikethrough && (
          <span className="fa-layers">
            <FontAwesomeIcon fixedWidth style={style} icon={faSlash} />
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </ConferenceIconBtn>
    </Tooltip>
  );
};
