import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FramedContent } from '../../framed-content';
import { FormEmployerSignature } from '../../form/compositions/FormEmployerSignature';

const EmployerSignatureForm: React.FC<{ props: any }> = ({ props }) => {
  if (!props.flowData?.itemData?.signature || props.flowData?.itemData?.signature === '<p></p>') {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormEmployerSignature
        formData={props.flowData}
        headline={'Angaben zu Ihrem Arbeitgeber'}
        subtitleComponent={<Bx mb={3}>
            <Typo variant="subtitle1">Bitte übermitteln Sie uns Ihre berufliche E-Mail-Signatur, so dass wir Ihren Arbeitgeber anlegen und mit Ihrem Nutzerprofil verknüpfen können.</Typo>
            <Typo variant="subtitle1">Bis dahin markieren wir Ihr Nutzerprofil als vorläufig. Sie haben jedoch uneingeschränkten Zugang zum Portal.</Typo>
          </Bx>}
        onChange={(formField, formValue, formName) => {
          props.flowFunctions.setFlowFormData(formField, formValue, formName);
        }}
      />
    </FramedContent>
  );
};

export const ConnectedEmployerSignatureForm = (props: any) => {
  return <EmployerSignatureForm props={{ ...props }} />;
};
