import { callGet, callPost } from '@curry-group/data-addons';
import { useSelector } from 'react-redux';
import { eventChannel, END } from 'redux-saga';
import { takeLatest, all, put, call, race, delay, take } from 'redux-saga/effects';
import {
  userCredLoginRequestAction,
  userDeauthAction,
  userLoginFailedAction,
  userLoginSuccessAction,
  userLogoutFailedAction,
  userLogoutRequestAction,
  userLogoutSuccessAction,
  userMtoLoginFailedAction,
  userMtoLoginRequestAction,
  userMtoLoginSuccessAction
} from '../../actions/auth';
import {
  fetchStartupRequestAction,
  fetchStartupFailedAction,
  fetchStartupSuccessAction,
  userprofileAllowExternalLinksRequestAction,
  userprofileAllowExternalLinksErrorAction,
  userprofileAllowExternalLinksSuccessAction,
  userprofileMemorylistRequestAction,
  userprofileMemorylistSuccessAction,
  userprofileMemorylistErrorAction,
  userprofileChangeDataVisibilityErrorAction,
  userprofileChangeDataVisibilitySuccessAction,
  userprofileChangeDataVisibilityRequestAction,
  userprofileChangeMessagingPermissionsRequestAction,
  userprofileChangeMessagingPermissionsSuccessAction,
  userprofileChangeMessagingPermissionsErrorAction,
} from '../../actions/foundation';
import { api } from '../../api';
import { GetCookie } from '../../../helper';

export function useConfiguration(alias: string) {
  return useSelector(state => state.foundation.appconfig?.[alias]);
}

function* watcher() {
  yield takeLatest(fetchStartupRequestAction.type, fetchStartupWorker);
  yield takeLatest(userDeauthAction.type, userDeauthWorker);
  yield takeLatest(userMtoLoginRequestAction.type, userMtoLoginRequestWorker);
  yield takeLatest(userCredLoginRequestAction.type, userCredLoginRequestWorker);
  yield takeLatest(userLoginSuccessAction.type, afterLoginWorker);
  yield takeLatest(userLogoutRequestAction.type, logoutWorker);
  yield takeLatest(userLogoutSuccessAction.type, afterLogoutWorker);
  yield takeLatest(userprofileAllowExternalLinksRequestAction.type, userprofileAllowExternalLinksWorker);
  yield takeLatest(userprofileChangeDataVisibilityRequestAction.type, userprofileChangeDataVisibilityWorker);
  yield takeLatest(userprofileChangeMessagingPermissionsRequestAction.type, userprofileChangeMessagingPermissionsWorker);
  yield takeLatest(userprofileMemorylistRequestAction.type, userprofileMemorylistWorker);
}

function* afterLoginWorker() {
  // yield put(fetchStartupRequestAction());
}
function* logoutWorker() {
  try {
    yield callGet(api.auth.logout);
    yield put(userLogoutSuccessAction());
  } catch {
    yield put(userLogoutFailedAction());
  }
}
function* afterLogoutWorker() {
  yield put(fetchStartupFailedAction());
}

function* fetchStartupWorker() {
  try {
    const startup = yield callGet(api.v2.startup);
    yield put(fetchStartupSuccessAction({ ...startup }));
  } catch (e) {
    yield put(fetchStartupFailedAction());
  }
}

function* userDeauthWorker() {
  yield put(userDeauthAction());
}

function* userCredLoginRequestWorker(action: ReturnType<typeof userCredLoginRequestAction>) {
  try {
    // no csrf token needed, post does not manipulate data
    yield callPost(api.auth.credentialLogin, { ...action.payload });
    yield put(userLoginSuccessAction());
  } catch (e) {
    yield put(userLoginFailedAction());
  }
}

function mtoLoginObserver() {
  return eventChannel(emitter => {
    const loginInterval = setInterval(() => {
      if (localStorage.mto_oauth_success === '-1') {
        emitter(false);
        emitter(END);
        localStorage.removeItem('mto_oauth_success');
      } else if (localStorage.mto_oauth_success === '1') {
        emitter(true);
        emitter(END);
        localStorage.removeItem('mto_oauth_success');
      }
    }, 1000);
    return () => {
      clearInterval(loginInterval);
    };
  });
}

function* userMtoLoginRequestWorker(action: ReturnType<typeof userMtoLoginRequestAction>) {
  try {
    // no csrf token needed, call external
    const response = yield call(fetch, api.auth.oauth_discovery);
    const discoData = yield call([response, response.json]);
    let authorization_url = discoData.authorization_endpoint;
    authorization_url += '?client_id=91921478-6ce4-4cb0-84ff-22699fbd0538';
    authorization_url += '&scope=' + encodeURIComponent('openid email profile');
    authorization_url += '&response_mode=fragment';
    authorization_url += '&response_type=id_token';
    authorization_url += '&redirect_uri=' + encodeURIComponent(window.location.origin + '/oauth');
    localStorage.mto_oauth_nonce = (Math.random() * 10000).toFixed(0);
    localStorage.mto_oauth_persist = action.payload.persist ? '1' : '0';
    authorization_url += '&nonce=' + localStorage.mto_oauth_nonce;
    window.open(authorization_url);
    const loginObserverChannel = yield call(mtoLoginObserver);
    const result = yield race({
      success: take(loginObserverChannel),
      timeout: delay(240000),
    });
    if (result.success) {
      yield put(userMtoLoginSuccessAction());
    } else {
      yield put(userMtoLoginFailedAction());
    }
  } catch (e) {
    yield put(userMtoLoginFailedAction());
  }
}

function* userprofileAllowExternalLinksWorker() {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.auth.externalLinksAccepted, {}, addHeader);
    if (result) {
      yield put(userprofileAllowExternalLinksSuccessAction());
    } else {
      yield put(userprofileAllowExternalLinksErrorAction());
    }
  } catch {
    yield put(userprofileAllowExternalLinksErrorAction());
  }
}

function* userprofileChangeDataVisibilityWorker(action: ReturnType<typeof userprofileChangeDataVisibilityRequestAction>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.auth.userprofileChangeDataVisibility, { toggleProp: action.payload.toggleProperty }, addHeader);
    if (result) {
      yield put(userprofileChangeDataVisibilitySuccessAction({ dataVisibility: result }));
    } else {
      yield put(userprofileChangeDataVisibilityErrorAction());
    }
  } catch {
    yield put(userprofileChangeDataVisibilityErrorAction());
  }
}

function* userprofileChangeMessagingPermissionsWorker(action: ReturnType<typeof userprofileChangeMessagingPermissionsRequestAction>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.auth.userprofileChangeMessagingPermissions, { toggleProp: action.payload.toggleProperty }, addHeader);
    if (result) {
      yield put(userprofileChangeMessagingPermissionsSuccessAction({ messagingPermissions: result }));
    } else {
      yield put(userprofileChangeMessagingPermissionsErrorAction());
    }
  } catch {
    yield put(userprofileChangeMessagingPermissionsErrorAction());
  }
}

function* userprofileMemorylistWorker(action: ReturnType<typeof userprofileMemorylistRequestAction>) {
  try {
    const result = yield callPost(api.v1.profile.memorylist, { item: action.payload.memoryItem, type: action.payload.type });
    if (result) {
      yield put(userprofileMemorylistSuccessAction({ memoryList: result }));
    } else {
      yield put(userprofileMemorylistErrorAction());
    }
  } catch {
    yield put(userprofileMemorylistErrorAction());
  }
}

export function* foundation() {
  yield all([watcher()]);
}
