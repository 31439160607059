import { callGet, callPost, callPatchPlain } from '@curry-group/data-addons';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { ISearchRequestBody, SearchRequestObject } from '../../../model/search/SearchRequest';
import { ISearchResult } from '../../../model/search/SearchResult';
import { api } from '../../api';
import {
  fetchDetailFailed,
  fetchDetailRequest,
  fetchDetailSuccess,
  fetchRelatedEntriesFailed,
  fetchRelatedEntriesRequest,
  fetchRelatedEntriesSuccess,
  updateVotingFailed,
  updateVotingRequest,
  updateVotingSuccess,
  fetchMeetingsRequest,
  fetchMeetingsSuccess,
  fetchMeetingsFailed,
  updateCommitmentFailed,
  updateCommitmentRequest,
  updateCommitmentSuccess,
  updateDetailFailed,
  updateDetailRequest,
  resetFormData,
  fetchLPDetailRequest,
  updateConferencing,
  cancelMeetingRequest,
  cancelMeetingFailed,
  cancelMeetingSuccess,
  fetchEmployeesRequest,
  fetchEmployeesFailed,
  fetchEmployeesSuccess,
  fetchArticlesRequest,
  fetchArticlesSuccess,
  fetchArticlesFailed,
  fetchMeetingParticipantsRequest,
  fetchMeetingParticipantsFailed,
  fetchMeetingParticipantsSuccess,
} from '../../reducer/detail';
import { GetCookie, getDetailRoute, GetFormData } from '../../../helper';
import { ThingTypes } from '../../../model/ryve/Thing';
import {
  fetchParticipantsRequest,
  fetchParticipationFailed,
  fetchParticipationRequest,
  setActionCanvas,
  setCommunication,
  setInitialMessage,
  setLastReadTimestampRequest,
  subscribeToSignalR,
} from '../../reducer/communication';
import { fetchCommunicationByIdRequest } from '../../reducer/communication';
import { fetchNotificationsRequestAction, setNotificationsReadRequestAction } from '../../actions/notifications';
import { IMessageModel } from '../../../model/communication/Message';
import { Tab } from '../../../components/communication/header';
import { setAvatarAssetsAction } from '../../actions/foundation';

function* watcher() {
  yield takeLatest(fetchDetailRequest.type, fetchDetailWorker);
  yield takeLatest(fetchLPDetailRequest.type, fetchLPDetailWorker);
  yield takeLatest(updateDetailRequest.type, updateDetailWorker);
  yield takeLatest(cancelMeetingRequest.type, cancelMeetingWorker);
  yield takeLatest(fetchRelatedEntriesRequest.type, fetchRelatedWorker);
  yield takeLatest(updateVotingRequest.type, votingWorker);
  yield takeLatest(fetchMeetingsRequest.type, meetingWorker);
  yield takeLatest(updateCommitmentRequest.type, commitmentWorker);
  yield takeLatest(fetchEmployeesRequest.type, fetchEmployeesHandler);
  yield takeLatest(fetchArticlesRequest.type, fetchArticlesHandler);
  yield takeLatest(fetchDetailSuccess.type, detailSuccessHandler);
  yield takeLatest(fetchMeetingParticipantsRequest.type, fetchMeetingParticipantsWorker);
}

function* detailSuccessHandler(action: ReturnType<typeof fetchDetailSuccess>) {
  if (action.payload) {
    try {
      if (action.payload.communication) {
        let updateQueryString = false;
        const queryParams = new URLSearchParams(window.location.search);
        const activeView = queryParams.get('activeView') || undefined;
        if (!!activeView) {
          queryParams.delete('activeView');
          updateQueryString = true;
        }
        const activeTabParam = queryParams.get('activeTab') || undefined;
        let activeTab: Tab | undefined = undefined;
        if (!!activeTabParam) {
          activeTab = {
            title: 'Teilnehmende',
            alias: 'participants',
            type: 'view',
          };
          queryParams.delete('activeTab');
          updateQueryString = true;
        }
        yield put(setCommunication({ communication: action.payload.communication, id: action.payload._id, view: activeView, tab: activeTab }));
        yield put(fetchParticipationRequest({ id: action.payload._id }));
        yield put(subscribeToSignalR({ id: action.payload._id }));
        const initialMessage = queryParams.get('message') || undefined;
        const messageInThread = queryParams.get('threadMessage') || undefined;
        const openDetail = queryParams.get('detailContent') || undefined;
        if (!!initialMessage) {
          yield put(setInitialMessage({ initialMessage, messageInThread, openDetail: !!openDetail }));
          if (!!initialMessage) {
            queryParams.delete('message');
            queryParams.delete('detailContent');
            updateQueryString = true;
          }
          if (!!messageInThread) {
            queryParams.delete('threadMessage');
            updateQueryString = true;
          }
        }

        if (updateQueryString) {
          var newQuery = queryParams.toString();
          var originUrl = `${window.location.origin}${window.location.pathname}${!!newQuery ? `?${newQuery}` : ''}`;
          window.history.replaceState({}, '', originUrl);
        }

        const calledMessageInContentPage = yield select(state => state.communication?.calledMessageInContentPage);
        if (!!calledMessageInContentPage) {
          yield put(setActionCanvas({ open: true, threadRoot: {} as IMessageModel }));
        } else {
          const acOpen = yield select(state => state.communication?.actioncanvas?.open);
          if (acOpen) {
            yield put(setActionCanvas({ open: false }));
          }
        }

        const notificationItems = yield select(state => state.notifications?.items);
        const notificationsWorking = yield select(state => state.notifications?.working);
        const parentThingId = yield select(state => state.detail?.item?.content?.parentThing?.[0]?.elementId);

        if (!notificationItems?.length) {
          if (!notificationsWorking) {
            yield put(fetchNotificationsRequestAction());
          }
          yield call(waitFor, (state: { notifications: { working: any } }) => !state.notifications?.working);
          yield put(setNotificationsReadRequestAction({ id: action.payload._id }));
        } else {
          yield put(setNotificationsReadRequestAction({ id: action.payload._id }));
        }
        if (parentThingId) {
          yield call(waitFor, (state: { communication: { participationWorking: any } }) => !state.communication?.participationWorking);
          yield put(setNotificationsReadRequestAction({ id: parentThingId }));
          yield put(setLastReadTimestampRequest({ id: parentThingId, timestamp: Date.now() + 1 }));
        }
      }

      if (action.payload.type === ThingTypes.Group || action.payload.type === ThingTypes.Project) {
        yield put(fetchParticipantsRequest({ id: action.payload._id }));
        yield call(waitFor, (state: { communication: { participationWorking: any } }) => !state.communication?.participationWorking);
        yield put(setLastReadTimestampRequest({ id: action.payload._id, timestamp: Date.now() + 1 }));
      }

      if (action.payload.type === ThingTypes.Actors) {
        yield put(fetchEmployeesRequest({ id: action.payload._id }));
        yield put(fetchArticlesRequest({ id: action.payload._id }));
      }
      if (action.payload.content.list_users) {
        const listUsers = { ...action.payload.content.list_users };
        if (Object.keys(listUsers).length > 0) {
          yield put(setAvatarAssetsAction({ idAssetDict: listUsers }));
        }
      }
    } catch (e: any) {
      
    }
  }
}

function* waitFor(selector) {
  if (yield select(selector)) return; // return if selector true
  while (true) {
    yield take('*'); // wait with empty task
    if (yield select(selector)) return; // return if selector true
  }
}

function* fetchEmployeesHandler(action: ReturnType<typeof fetchEmployeesRequest>) {
  if (action.payload) {
    try {
      const result = yield callGet(api.params(api.v1.profile.employees, { actorId: action.payload.id }));
      yield put(fetchEmployeesSuccess({ result: result }));
    } catch (e: any) {
      yield put(fetchEmployeesFailed({ message: e.message }));
    }
  }
}

function* fetchArticlesHandler(action: ReturnType<typeof fetchArticlesRequest>) {
  if (action.payload) {
    try {
      const result = yield callGet(api.params(api.v1.profile.articles, { actorId: action.payload.id }));
      if (Object.keys(result.list_users).length > 0) {
        yield put(setAvatarAssetsAction({ idAssetDict: result.list_users }));
      }
      yield put(fetchArticlesSuccess({ result: result.articles }));
    } catch (e: any) {
      yield put(fetchArticlesFailed({ message: e.message }));
    }
  }
}

function* fetchDetailWorker(action: ReturnType<typeof fetchDetailRequest>) {
  try {
    const anon = yield select(state => state.foundation.anon);
    yield put(setActionCanvas({ open: false }));
    yield put(setCommunication({ communication: undefined, id: undefined }));
    yield put(fetchParticipationFailed({ message: '' }));
    const result = yield callGet(
      action.payload.type?.toLowerCase() === 'user'
        ? action.payload.alias?.toLowerCase() === 'profile'
          ? api.v1.profile.myProfile
          : api.params(api.v1.profile.byAlias, action.payload)
        : api.params(api.v2.detail, action.payload)
    );
    if (!anon) {
      if (result?.communication?.allowConferencing) {
        const conferenceMsg = yield callGet(api.params(api.v2.meetings.adhoc, { parentId: result._id }));
        if (conferenceMsg) {
          yield put(updateConferencing({ message: conferenceMsg }));
        } else {
          yield put(updateConferencing({ message: null }));
        }
      } else {
        yield put(updateConferencing({ message: null }));
      }
    }
    if (!result) {
      yield put(fetchDetailFailed({ errorCode: 404, errorMessage: 'Not found' }));
    } else {
      yield put(fetchDetailSuccess(result));
    }
  } catch (e: any) {
    if (action.payload.type?.toLowerCase() === 'user') {
      if (((e?.message || '') + '')?.indexOf('429') > -1) {
        yield put(fetchDetailFailed({ errorCode: 429, errorMessage: 'TMR' }));
      } else {
        try {
          const alias = yield callGet(api.params(api.v1.profile.aliasById, action.payload));
          const profile = yield callGet(api.params(api.v1.profile.byAlias, { alias }));
          yield put(fetchDetailSuccess(profile));
        } catch (e: any) {
          yield put(fetchDetailFailed({ errorCode: 404, errorMessage: 'Not found' }));
        }
      }
    } else {
      yield put(fetchDetailFailed({ errorCode: 404, errorMessage: 'Not found' }));
    }
  }
}

function* fetchLPDetailWorker(action: ReturnType<typeof fetchLPDetailRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.landingpageDetail, action.payload));
    yield put(fetchDetailSuccess(result));
  } catch {
    yield put(fetchDetailFailed({ errorCode: 404, errorMessage: 'LP not found' }));
  }
}

export function* fetchRelatedWorker(action: ReturnType<typeof fetchRelatedEntriesRequest>) {
  try {
    const detail = yield select(state => state.detail.item);
    const searchRequestObject = SearchRequestObject.fromPageElement(action.payload.pageElement, detail);

    const pageSize = yield select(state => state.search.queryPageSize);
    const effectiveParams: ISearchRequestBody = {
      ...searchRequestObject,
      querySize: pageSize,
      querySkip: detail?.content?.related?.hits?.length,
    };
    // no csrf token needed, post does not manipulate data
    const result: ISearchResult = yield callPost(api.search.related, effectiveParams);
    yield put(fetchRelatedEntriesSuccess({ result }));
  } catch (e: any) {
    yield put(fetchRelatedEntriesFailed({ message: e.message }));
  }
}

export function* votingWorker(action: ReturnType<typeof updateVotingRequest>) {
  try {
    const detail = yield select(state => state.detail.item);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(`${api.v2.voting}?thingType=${detail.type}&thingId=${detail._id}&vote=${action.payload.voting}`, undefined, addHeader);
    yield put(updateVotingSuccess({ result }));
  } catch (e: any) {
    yield put(updateVotingFailed({ message: e.message }));
  }
}

export function* meetingWorker(action: ReturnType<typeof fetchMeetingsRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.meetings.parent, { parentId: action.payload.parentId }));
    yield put(fetchMeetingsSuccess({ result }));
  } catch (e: any) {
    yield put(fetchMeetingsFailed({ message: e.message }));
  }
}

export function* commitmentWorker(action: ReturnType<typeof updateCommitmentRequest>) {
  try {
    const detail = yield select(state => state.detail.item);
    const profileId = yield select(state => state.foundation.profile.profileId);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(`${api.v2.meetings.commitment}?meetingId=${detail._id}&profileId=${profileId}&commit=${action.payload.commit}`, undefined, addHeader);
    yield put(updateCommitmentSuccess({ result }));
  } catch (e: any) {
    yield put(updateCommitmentFailed({ message: e.message }));
  }
}

function* updateDetailWorker(action: ReturnType<typeof updateDetailRequest>) {
  try {
    const formData = yield select(state => state.detail?.formData);
    const _id = yield select(state => state.detail?.item?._id);
    const type = yield select(state => state.detail?.item?.type);
    const alias = yield select(state => state.detail?.item?.seo?.alias);
    const parentThing = yield select(state => state.detail?.item?.content?.parentThingResolved?.[0]);
    const config = yield select(state => state.foundation.appconfig);
    let routeType = '';
    let finishUrl = '';
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    switch (type) {
      case ThingTypes.Idea: {
        routeType = 'idea';
        const newIdeaForm = GetFormData(_id, formData?.itemData, formData?.categories, formData?.attachments, false);
        const createdIdea = yield callPatchPlain(api.flow.create.idea, newIdeaForm, addHeader);
        if (alias !== createdIdea.seo.alias) {
          finishUrl = '/info/forum/idea/' + createdIdea.seo.alias;
        }
        break;
      }
      case ThingTypes.QnA: {
        routeType = 'qna';
        const newQnaForm = GetFormData(_id, formData?.itemData, formData?.categories, formData?.attachments, false);
        const createdQnA = yield callPatchPlain(api.flow.create.qna, newQnaForm, addHeader);
        if (alias !== createdQnA.seo.alias) {
          finishUrl = '/info/forum/qna/' + createdQnA.seo.alias;
        }
        break;
      }
      case ThingTypes.Requisition: {
        routeType = 'requisition';
        const newRequisitionForm = GetFormData(_id, formData?.itemData, formData?.categories, formData?.attachments, false);
        const createdRequisition = yield callPatchPlain(api.flow.create.requisition, newRequisitionForm, addHeader);
        if (alias !== createdRequisition.seo.alias) {
          finishUrl = '/info/forum/requisition/' + createdRequisition.seo.alias;
        }
        break;
      }
      case ThingTypes.Group: {
        routeType = 'groups';
        const workgroup = { ...formData?.itemData };
        workgroup.participants = formData?.selectedUsers;
        workgroup.public = formData?.groupType === 'open_listed';
        workgroup.listed = formData?.groupType === 'open_listed' || formData?.groupType === 'closed_listed';
        const avatar = formData?.avatar && formData?.avatar.length > 0 ? formData?.avatar : [new File([], 'AVATAR_PLACEHOLDER')];
        const keyvisual = formData?.keyvisual && formData?.keyvisual.length > 0 ? formData?.keyvisual : [new File([], 'KEYVISUAL_PLACEHOLDER')];
        const newWorkgroupForm = GetFormData(_id, workgroup, formData?.categories, [...avatar, ...keyvisual, ...formData?.attachments], false);
        const createdWorkgroup = yield callPatchPlain(api.flow.create.workgroup, newWorkgroupForm, addHeader);

        yield put(fetchCommunicationByIdRequest({ id: createdWorkgroup._id }));

        if (alias !== createdWorkgroup.seo.alias) {
          finishUrl = '/coop/groups-projects/groups/' + createdWorkgroup.seo.alias;
        }
        break;
      }
      case ThingTypes.Project: {
        routeType = 'projects';
        const project = { ...formData?.itemData };
        project.participants = formData?.selectedUsers;
        project.public = formData?.groupType === 'open_listed';
        project.listed = formData?.groupType === 'open_listed' || formData?.groupType === 'closed_listed';
        const avatar = formData?.avatar && formData?.avatar.length > 0 ? formData?.avatar : [new File([], 'AVATAR_PLACEHOLDER')];
        const keyvisual = formData?.keyvisual && formData?.keyvisual.length > 0 ? formData?.keyvisual : [new File([], 'KEYVISUAL_PLACEHOLDER')];
        const newProjectForm = GetFormData(_id, project, formData?.categories, [...avatar, ...keyvisual, ...formData?.attachments], false);
        const createdProject = yield callPatchPlain(api.flow.create.project, newProjectForm, addHeader);

        yield put(fetchCommunicationByIdRequest({ id: createdProject._id }));

        if (alias !== createdProject.seo.alias) {
          finishUrl = '/coop/groups-projects/projects/' + createdProject.seo.alias;
        }
        break;
      }
      case ThingTypes.Meeting: {
        routeType = 'meetings';
        const meeting = { ...formData?.itemData };
        if (meeting?.externalType === 'link') {
          if (meeting?.location) {
            meeting.location = undefined;
          }
        } else if (meeting?.externalType === 'location') {
          if (meeting?.externalLink) {
            meeting.externalLink = undefined;
            meeting.externalLinkAcceptedInstructions = undefined;
          }
        }
        meeting.participants = formData?.selectedUsers;
        const newMeetingForm = GetFormData(_id, meeting, formData?.categories, formData?.attachments || [], false);
        if (action.payload?.customMessage) {
          newMeetingForm.append('customMessage', action.payload.customMessage);
        }
        const updatedMeeting = yield callPatchPlain(api.flow.create.meeting, newMeetingForm, addHeader);

        yield put(fetchCommunicationByIdRequest({ id: updatedMeeting._id }));

        if (alias !== updatedMeeting.seo.alias) {
          const newMeetingRoute =
            getDetailRoute(
              {
                typeId: updatedMeeting.type,
                alias: updatedMeeting.seo.alias,
                parentItem: {
                  typeId: parentThing.type,
                  alias: parentThing.seo.alias
                }
              },
              config
            ) || '/';
          finishUrl = newMeetingRoute;
        }
        break;
      }
    }
    if (finishUrl) {
      action.payload.history.replace(finishUrl);
    } else {
      yield put(fetchDetailRequest({ type: routeType, alias: alias }));
    }
    yield put(resetFormData({}));
  } catch (e: any) {
    yield put(updateDetailFailed({ message: e.message }));
  }
}

function* cancelMeetingWorker(action: ReturnType<typeof cancelMeetingRequest>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPost(`${api.v2.meetings.cancel}?meetingId=${action.payload.id}`, { customMessage: action.payload?.customMessage }, addHeader);
    action.payload.onSuccess && action.payload.onSuccess();
    yield put(cancelMeetingSuccess({ customMessage: action.payload?.customMessage }));
  } catch (e: any) {
    yield put(cancelMeetingFailed());
  }
}

function* fetchMeetingParticipantsWorker(action) {
  try {
    const meetingId = yield select(state => state.detail.meetingParticipants?.meetingId);
    const participantsResolved = yield callGet(api.params(api.v2.communication.participants, { id: meetingId }));
    yield put(fetchMeetingParticipantsSuccess({ participantsResolved: participantsResolved.data }));
  } catch (error: any) {
    yield put(fetchMeetingParticipantsFailed({ error: error.message }));
  }
}

export function* detail() {
  yield all([watcher()]);
}
