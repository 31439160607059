import React from 'react';
import { Bx, useBreakpoints } from '@curry-group/mui-curcuma';
import { IConferencingParticipant } from '../../../interfaces';
import { ConferencingParticipant } from '../../../participant';
import { SimpleParticipant } from '../../../participant/simple';
import { useTheme } from '@material-ui/core/styles';
import useScreenOrientation from '../../../../../helper/hooks/useScreenOrientation';
import { useIsMobile } from '../../../../../helper/hooks/isMobile';
import { isMobile as isMobileDevice } from "react-device-detect";

export const ParticipantDesktop: React.FC<{
  participants?: IConferencingParticipant[];
  desktop?: IConferencingParticipant;
  self?: IConferencingParticipant;
  speakers?: string[];
  userName?: string;
}> = ({
  participants,
  self,
  desktop,
  speakers,
  userName
}) => {
  const mapping = participants || [];
  const theme = useTheme();
  const isMobile = useIsMobile();
  const breakpoints = useBreakpoints();
  const orientation = useScreenOrientation();
  let unit = 'vh';
  if (isMobileDevice && isMobile && orientation.indexOf('landscape') > -1) {
    unit = 'vw';
  }
  return (
    <Bx height="100%">
      <Bx style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Bx style={{ display: 'flex', overflowX: 'auto' }}>
          {mapping
            .filter(p => !p.self)
            .map((p, idx) => {
              return (
                <Bx key={p.id} height={'12' + unit} width={'calc(12' + unit + ' / 9 * 16)'} style={{ flexShrink: 0, borderLeft: idx !== 0 ? theme?.spacing(0.5) + 'px solid black' : undefined }}>
                  <ConferencingParticipant
                    audio={p.audio}
                    displayName={p.displayName}
                    hand={p.hand}
                    media={p.media}
                    speaking={speakers?.includes(p.sessionIdInternal ?? '')}
                    video={p.video}
                    small
                  />
                </Bx>
              );
            })}
        </Bx>
        {self && ((breakpoints.smDown && !desktop) || breakpoints.smUp) && (
          <Bx>
            <Bx height={'12' + unit} width={'calc(12' + unit + ' / 9 * 16)'} style={{ flexShrink: 0 }}>
              <ConferencingParticipant
                audio={self.audio}
                displayName={userName}
                hand={self.hand}
                media={self.media}
                muted
                speaking={self.speaking}
                video={self.video}
                small
                self
              />
            </Bx>
          </Bx>
        )}
      </Bx>
      <Bx height={'calc(100% - 12' + unit + ')'} style={{ borderTop: '2px solid black' }}>
        <SimpleParticipant media={desktop?.media} muted />
      </Bx>
    </Bx>
  );
};
