import React from 'react';
import { Anohana, Bx, Btn, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../headline-container';

export const AllowExternalLinksModal: React.FC<{ externalLinksAllowed: boolean; editExternalLinksAllowed: boolean; close?: () => void; cancel?: () => void; save?: () => void }> =
({ externalLinksAllowed, editExternalLinksAllowed, close, cancel, save }) => {
  return (
    <Anohana open={editExternalLinksAllowed} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={externalLinksAllowed ? 'Externe Links deaktivieren?' : 'Externe Links aktivieren?'}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={<IconBtn size="small" onClick={close}><FontAwesomeIcon icon={faTimes} /></IconBtn>}
      />
      <Bx py={3} px={3}>
        {externalLinksAllowed && <Typo variant="body1">Das Deaktivieren dieser Option führt dazu, dass Links zu externen Quellen nur mit Warnhinweis geöffnet werden können.</Typo>}
        {!externalLinksAllowed && <Typo variant="body1">Das Aktivieren dieser Option führt dazu, dass Links zu externen Quellen direkt geöffnet werden können.</Typo>}
        <Bx mt={2} display="flex" justifyContent="flex-end" alignItems="center">
          <Bx>
            <Btn onClick={save} color="success" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
              OK
            </Btn>
          </Bx>
          <Bx ml={1}>
            <Btn onClick={cancel} color="error" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Abbrechen
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
