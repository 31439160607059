import React, { FC, ReactNode } from 'react';
import { getProfileRouteByUserId } from '../../../../helper';
import { Link, Typo, IconBtn, Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { IMessageReferences } from '../../../../model/communication/Message';
// import dayjs from 'dayjs';

export interface IMessageHeaderProps {
  mobile?: boolean;

  isDeleted?: boolean;
  isModified?: boolean;
  isOwnMessage?: boolean;
  isConference?: boolean;

  content: string;
  timestamp: string;
  createdById: string;
  createdByName: string;

  hasDropdown?: boolean;
  setDropdownAnchor?: (target: HTMLButtonElement) => void;

  references?: IMessageReferences;
  isMeeting?: boolean;
}

export const MessageHeader: FC<IMessageHeaderProps> = ({
  mobile,
  isDeleted,
  isOwnMessage,
  isConference,
  content,
  timestamp,
  createdById,
  createdByName,
  isModified,
  hasDropdown,
  setDropdownAnchor,
  references,
  isMeeting,
}) => {
  const flexDirection = mobile ? 'column' : undefined;

  const linkToProfile = (str: string) => {
    return (
      <Link underline="hover" to={getProfileRouteByUserId(createdById)}>
        {headline(str)}
      </Link>
    );
  };

  const headline = (str: string) => {
    return (
      <Typo fontWeight="bold" variant="body2" style={{ lineHeight: 1.25 }}>
        {str}
      </Typo>
    );
  };

  let timestampValue = timestamp;
  let headlineComponent: ReactNode = undefined;
  if (references && references.meeting) {
    if (isDeleted) {
      headlineComponent = headline(`Meeting`);
    } else {
      headlineComponent = headline(content);
    }
  } else if (isConference) {
    if (isDeleted) {
      headlineComponent = headline(`${content} gestartet`);
    } else {
      headlineComponent = headline(`Besprechung`);
    }
  } else {
    if (isOwnMessage) {
      headlineComponent = headline('Du');
    } else {
      headlineComponent = linkToProfile(createdByName);
    }
  }
  return (
    <Bx display="flex" justifyContent="space-between" alignItems="center" mb={1}>
      <Bx display="flex" flexDirection={flexDirection} alignItems="baseline">
        <Bx>{headlineComponent}</Bx>

        <Bx ml={mobile ? undefined : 1}>
          <Typo variant="subtitle2">
            {timestampValue}
            {isModified && ' (bearbeitet)'}
          </Typo>
        </Bx>
      </Bx>

      {hasDropdown && !isMeeting && (
        <Bx>
          <IconBtn size="small" color="inherit" onClick={e => setDropdownAnchor?.(e.currentTarget)}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </IconBtn>
        </Bx>
      )}
    </Bx>
  );
};
