import { createReducer } from '@reduxjs/toolkit';
import { IMessageModel } from '../../../model/communication/Message';
import { toggleConferencing, updateConferencingMessage, toggleSpeakersDynamic, setSpeakersDynamic } from '../../actions/conferencing';

export interface IConferencingState {
  visible: boolean;
  message?: IMessageModel;
  token?: string;
  speakersDynamic?: boolean;
}

const initialState: IConferencingState = {
  visible: false,
  message: undefined,
  token: undefined,
  speakersDynamic: false
};

export const conferencing = createReducer(initialState, builder =>
  builder
    .addCase(toggleConferencing, (state, action) => {
      const newVisible = !state.visible;
      const payload = newVisible ? action.payload : {};
      return { speakersDynamic: state.speakersDynamic, visible: newVisible, ...payload };
    })
    .addCase(updateConferencingMessage, (state, action) => {
      const payload = action.payload ?? {};
      return { visible: state.visible, speakersDynamic: state.speakersDynamic, ...payload };
    })
    .addCase(toggleSpeakersDynamic, (state, action) => {
      return { ...state, speakersDynamic: !state.speakersDynamic };
    })
    .addCase(setSpeakersDynamic, (state, action) => {
      const setDynamic = action.payload ? action.payload.setDynamic : false;
      return { ...state, speakersDynamic: setDynamic };
    })
);

export default conferencing;
