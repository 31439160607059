import { Bx, IBxProps } from '@curry-group/mui-curcuma';
import React from 'react';

interface ILandingPageSectionWrapperProps {
  sectionPy: IBxProps['py'];
  sectionPx?: IBxProps['px'];
  bgcolor?: string;
}

export const LandingPageSectionWrapper: React.FunctionComponent<ILandingPageSectionWrapperProps> = ({ bgcolor, sectionPy, sectionPx, children }) => {
  return (
    <Bx component="section" width="100%" bgcolor={bgcolor} py={sectionPy} px={sectionPx} style={{ overflow: 'hidden' }}>
      {children}
    </Bx>
  );
};
