import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CurcumaThemeProvider } from '@curry-group/mui-curcuma';

import reportWebVitals from './reportWebVitals';
import { loadLocalStorage, setupStore } from './data/store';

import { AppRouting } from './app';

import { RootState } from './data/reducer';
import '@curry-group/mui-curcuma/dist/scss/curcuma.scss';
import './theme.scss';

const ConnectedOrStatic = (props: any) => {
  const { children } = props;
  return <BrowserRouter>{children}</BrowserRouter>;
};

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

export const startup = () => {
  const store = setupStore(loadLocalStorage() as RootState);

  return {
    store,
    app: (
      <CurcumaThemeProvider breakpointDebounce={150}>
        <CssBaseline />
        <Provider store={store}>
          <ConnectedOrStatic>
            <AppRouting />
          </ConnectedOrStatic>
        </Provider>
      </CurcumaThemeProvider>
    )
  };
};

ReactDOM.render(startup().app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
