import React from 'react';
import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FramedContent } from '../../framed-content';

const GroupVisibilitySelect = (props: any) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Wie soll Ihre Arbeitsgruppe erreichbar sein?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Mit dem Status Ihrer Arbeitsgruppe entscheiden Sie auch über deren Sichtbarkeit.</Typo>
      <Typo variant="subtitle1">Der Gruppenstatus kann nachträglich nicht geändert werden.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.flowData?.groupType === 'open_listed'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, groupType: 'open_listed' });
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/offen-und-gelistet.svg" alt="Offen und gelistet" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Offen und gelistet
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Ihre Arbeitsgruppe ist für alle Nutzenden des Portals sichtbar. Jeder kann beitreten.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.flowData?.groupType === 'closed_listed'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, groupType: 'closed_listed' });
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/geschlossen-und-gelistet.svg" alt="Geschlossen und gelistet" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Geschlossen und gelistet
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Ihre Arbeitsgruppe ist für alle sichtbar. Jeder Beitritt bedarf Ihrer Einladung oder Zustimmung.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.flowData?.groupType === 'closed_hidden'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, groupType: 'closed_hidden' });
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/geschlossen-und-unsichtbar.svg" alt="Geschlossen und unsichtbar" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Geschlossen und unsichtbar
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Ihre Arbeitsgruppe ist nur für die Teilnehmenden sichtbar. Der Beitritt erfolgt über Einladung.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export default GroupVisibilitySelect;
