import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMessagesRequest } from '../../../components/communication/header';
import { setActionCanvas, setMessageDraftEdit, setMessageDraftQuotes, setMessageDraftThreadRoot, setUserMessageEmoteRequest, voteMessageRequest } from '../../../data/reducer/communication';
import { ConnectedCommunicationInput } from '../input';
import { ActionCanvas, Bx, IconBtn, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConnectedMessages } from '../messages';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { HeadlineContainer } from '../../../components/headline-container';
import { BottomBar } from '../../../components/bottom-bar';

export interface IConnectedCommunicationActionCanvasProps {
  headline: string;
  threadOnly?: boolean;
  request?: GetMessagesRequest;
}
export const ConnectedCommunicationActionCanvas: React.FC<IConnectedCommunicationActionCanvasProps> = ({ headline, threadOnly, request }) => {
  const state = useSelector(state => state.communication?.actioncanvas);
  const calledMessageInContentPage = useSelector(state => state.communication?.calledMessageInContentPage);
  const breakpoints = useBreakpoints();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  let boxWidth: React.CSSProperties['width'];
  let minWidth: React.CSSProperties['minWidth'];
  if (breakpoints.xs || breakpoints.sm) {
    boxWidth = '100%';
    minWidth = 0;
  } else if (breakpoints.md) {
    boxWidth = '65%';
    minWidth = 500;
  } else {
    boxWidth = 'calc(100% * (2 - 1.6180334))';
    minWidth = 500;
  }

  // das actioncanvas für den chat in der detailansicht wird direkt gemounted
  // soll aber erst laden wenn es geöffnet wird (dann ist threadRoot gesetzt)
  if (threadOnly && !state?.threadRoot) return <></>;

  function close() {
    dispatch(setActionCanvas({ open: false, threadRoot: undefined }));
  }

  return (
    <ActionCanvas
      headerElement={isMobile ? undefined : document.getElementById('frame__header') || undefined}
      container={isMobile ? undefined : document.getElementById('frame__content') || undefined}
      backdropProps={{ open: state?.open || calledMessageInContentPage || false, onClick: () => close(), style: { top: isMobile ? 0 : undefined } }}
      slideProps={{ in: state?.open || calledMessageInContentPage || false, direction: 'left' }}
      boxProps={{ width: boxWidth, minWidth }}
    >
      <Bx height="100%" display="flex" flexDirection="column" flexWrap="nowrap">
        <HeadlineContainer
          bgcolor="background.paper"
          px={isMobile ? 2 : 3}
          py={isMobile ? 2 : 2.5}
          headline={headline}
          minHeight={isMobile ? 0 : 81}
          headlineVariant="h4"
          headlineComponent="h5"
          endAdornment={
            <IconBtn size="small" onClick={() => close()}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          }
        />
        <ConnectedMessages
          p={isMobile ? 2 : 3}
          scrollContainer={true}
          request={request}
          alias={state?.threadRoot?.id || 'main'}
          autoWidth={false}
          quoteClicked={message => {
            dispatch(setMessageDraftQuotes({ quotes: message, id: 'actioncanvas' }));
          }}
          emoteClicked={(message, emoji) => {
            dispatch(setUserMessageEmoteRequest({ target: message, emoji }));
          }}
          disableThreading={!!state?.threadRoot}
          forceInlineThreading
          threadRoot={state?.threadRoot}
          threadingClicked={message => {
            dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: message }));
          }}
          voteDownClick={message => {
            dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'actioncanvas', messageId: message.id, up: false }));
          }}
          voteUpClick={message => {
            dispatch(voteMessageRequest({ communicationId: message.parent, alias: 'actioncanvas', messageId: message.id, up: true }));
          }}
          editMessage={message => {
            dispatch(setMessageDraftEdit({ id: 'actioncanvas', message }));
          }}
        />
        {(!state?.threadRoot || !state?.threadRoot.deleted) && (
          <ConnectedCommunicationInput
            inputId="actioncanvas"
            small
            displayThreadRoot={!state?.threadRoot}
            removeThreadRoot={() => {
              // befinden wir uns im actioncanvas muss der threadRoot wieder auf den ursprünglichen wert gesetzt werden (undefined oder IMessageModel)
              dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: state?.threadRoot }));
            }}
          />
        )}
        {state?.threadRoot?.deleted && (
          <BottomBar scrolled bottom="unset" rootBoxProps={{ px: { xs: 2, md: 3 } }} ctnProps={{ maxWidth: 'xl', disableGutters: true }}>
            <Bx display="flex" alignItems="center">
              <Bx width="100%">
                <Typo variant="subtitle2">In diesem Thread können keine weiteren Nachrichten hinzugefügt werden</Typo>
              </Bx>
            </Bx>
          </BottomBar>
        )}
      </Bx>
    </ActionCanvas>
  );
};
