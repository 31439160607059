import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createConferenceMessageRequest, joinConferenceRequest } from '../../../data/reducer/communication';
import { FramedContent } from '../../framed-content';
import { useHistory } from 'react-router';

const MeetingTypeSelect: React.FC<{ props: any; parentId: string; parentType: string; adhocActive: boolean; onAdHocClick: () => void }> = ({
  props,
  parentId,
  parentType,
  adhocActive = false,
  onAdHocClick
}) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Welche Art von Meeting wollen Sie starten?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">
        Hier können Sie für Ihre Arbeitsgruppe / Ihr Projektteam ein Online- oder ein Präsenz-Meeting einrichten oder direkt miteinander sprechen.
        Grundsätzlich können alle Gruppenmitglieder teilnehmen.
      </Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard active={adhocActive} variant="outlined" fluid={{ from: 240, to: 458 }} height="100%" to="#" onClick={onAdHocClick}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/quick-meeting.svg" alt="Ad-hoc Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    {`Ad-hoc-Meeting${adhocActive ? ' läuft' : ''}`}
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      {adhocActive
                        ? 'Sie können der laufenden Videokonferenz beitreten. Ein paralleles Ad-hoc-Meeting ist nicht möglich.'
                        : 'Starten Sie eine Videokonferenz mit den anwesenden Gruppenmitgliedern.'}
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 240, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                if (!parentId || !parentType) {
                  alert('Error: reload parent element!');
                  return;
                }
                props.flowFunctions.setFlowData({ ...props.flowData, parentType, parentId, external: false });
                setTimeout(() => {
                  props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/terminiertes-meeting.svg" alt="Terminiertes Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Terminiertes Meeting
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Richten Sie hier einen neuen Termin ein und versenden Sie eine persönliche Einladung.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 240, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                if (!parentId || !parentType) {
                  alert('Error: reload parent element!');
                  return;
                }
                props.flowFunctions.setFlowData({ ...props.flowData, external: true, parentType, parentId });
                setTimeout(() => {
                  props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/externes-meeting.svg" alt="Externes Online- / Präsenz-Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Externes Online- / Präsenz-Meeting
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Planen Sie einen neuen Termin (Vor-Ort oder externer Anbieter) und laden Sie persönlich ein.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export const ConnectedMeetingTypeSelect = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adhocConferencing = useSelector(state => state.detail?.adhocConferencing);
  const detailId = useSelector(state => state.detail?.item?._id);
  const detailType = useSelector(state => state.detail?.item?.type);
  const adhocActive = !!detailId && adhocConferencing?.parent === detailId && !adhocConferencing?.conferenceInfo?.ended;
  return (
    <MeetingTypeSelect
      props={props}
      parentId={detailId || ''}
      parentType={detailType || ''}
      adhocActive={adhocActive}
      onAdHocClick={() => {
        adhocActive
          ? !!adhocConferencing
            ? dispatch(joinConferenceRequest({ message: adhocConferencing }))
            : alert('ERROR: no detail provided, reload previous page!')
          : !!detailId
          ? dispatch(createConferenceMessageRequest({ id: 'conference', communicationId: detailId }))
          : alert('ERROR: no detail provided, reload previous page!');
        history.goBack();
      }}
    />
  );
};
