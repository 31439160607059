import { Btn, Bx, IconBtn, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid, Collapse } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { faLongArrowLeft, faSlidersV } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom, useSetAtom } from 'jotai';
import { HeadBar } from '../../../../../../components/head-bar';
import { HeadSearchInput } from '../../../../../../components/head-search-input';
import { HeaderHeadbarContainer } from '../../../../../../components/header/headbar-container';
import {
  allSelectedAtom,
  autoSuggestActiveAtom,
  filterAliasAtom,
  filterDefaultSortAtom,
  filterItemAtom,
  filterTitleAtom,
  headerFilterAtom,
  headerFilterWorkingAtom,
  queryCategoriesAtom,
  queryTermAtom,
  resetFilterAtom,
  searchResultAtom,
  stepAtom,
  toggleAllSelectedAtom
} from '../atoms';
import { MobileHeadBar } from '../../../../../../components/mobile-head-bar';
import { MTOLogo } from '../../../../../../helper';
import { useIsMobile } from '../../../../../../helper/hooks/isMobile';
import { useEffect } from 'react';
import { HeaderFilter } from '../../../../../../components/filter';
import _ from 'lodash';

export interface IHeaderProps {
  headerFilter?: React.ReactNode;
  searchEnabled?: boolean;
  onBack?: () => void;
}

export const HeadSearchInputComponent = ({ value = '', onValueChange = function (changedValue: string) {}, placeholder = 'Durchsuchen' }) => {
  return (
    <HeadSearchInput
      input={{
        id: 'head-search',
        value: value ?? '',
        placeholder: placeholder,
        onChange: e => {
          onValueChange?.(e.target.value);
        }
      }}
      inputLabel={{ htmlFor: 'head-search' }}
      clearOnClick={() => {
        onValueChange?.('');
      }}
    />
  );
};

export const HeaderDesktop: React.FC<IHeaderProps> = ({ headerFilter, searchEnabled, onBack }) => {
  const [filterVisible, setFilterVisible] = useAtom(headerFilterAtom);
  const [filterWorking] = useAtom(headerFilterWorkingAtom);
  const [queryTerm, setQueryTerm] = useAtom(queryTermAtom);
  const [filterTitle] = useAtom(filterTitleAtom);
  const [allSelected] = useAtom(allSelectedAtom);
  const toggleAllSelected = useSetAtom(toggleAllSelectedAtom);
  return (
    <>
      <HeadBar backButtonLink={onBack}>
        {searchEnabled && (
          <HeaderHeadbarContainer>
            <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
              <HeadSearchInputComponent value={queryTerm} placeholder={filterWorking ? '' : filterTitle} onValueChange={setQueryTerm} />
            </Bx>
            <Bx mr={1}>
              <Btn size="large" color="success" variant={allSelected ? 'outlined' : 'contained'} onClick={toggleAllSelected}>
                <Typo variant="inherit" noWrap>
                  {allSelected ? 'Alle Mitglieder abwählen' : 'Alle Mitglieder einladen'}
                </Typo>
              </Btn>
            </Bx>
            <Bx>
              <Btn
                size="large"
                startIcon={<FontAwesomeIcon icon={faSlidersV} />}
                onClick={() => {
                  setFilterVisible(!filterVisible);
                }}
                color={filterVisible ? 'primary' : 'default'}
                variant={filterVisible ? 'contained' : 'outlined'}
              >
                Filtern
              </Btn>
            </Bx>
          </HeaderHeadbarContainer>
        )}
      </HeadBar>
      {headerFilter}
    </>
  );
};

export const HeaderMobile: React.FC<IHeaderProps> = ({ headerFilter, searchEnabled, onBack }) => {
  const [filterVisible, setFilterVisible] = useAtom(headerFilterAtom);
  const [filterWorking] = useAtom(headerFilterWorkingAtom);
  const [queryTerm, setQueryTerm] = useAtom(queryTermAtom);
  const [filterTitle] = useAtom(filterTitleAtom);
  const [autoSuggestActive] = useAtom(autoSuggestActiveAtom);
  const theme = useTheme();
  return (
    <MobileHeadBar style={{ minHeight: 64 }} boxShadow={{ xs: 0, md: 0 }}>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
        <Grid item xs={3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn size="medium" onClick={onBack}>
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={6}>
          <Bx pl={2} display="flex" alignItems="center" justifyContent="flex-start" height="100%">
            <Link
              to="/"
              onClick={e => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <img src={MTOLogo.logoWithTextSrc} alt={MTOLogo.logoAlt} style={{ height: 28, display: 'block' }} />
            </Link>
          </Bx>
        </Grid>
      </Bx>
      <Collapse in={searchEnabled}>
        <Bx display="flex" height="100%" minHeight={64} alignItems="center" py={1}>
          <Bx display="flex" width="100%" zIndex={2}>
            <Bx px={1} flexGrow={1} flexShrink={1} flexBasis="auto">
              <HeadSearchInputComponent value={queryTerm} placeholder={filterWorking ? '' : filterTitle} onValueChange={setQueryTerm} />
            </Bx>
            <Bx mr={1}>
              {!autoSuggestActive && (
                <Bx zIndex={2}>
                  <Bx px={1}>
                    <IconBtn
                      size="small"
                      onClick={() => {
                        setFilterVisible(!filterVisible);
                      }}
                      color={filterVisible ? 'primary' : 'default'}
                      variant={filterVisible ? 'contained' : 'outlined'}
                      style={{ borderRadius: theme.shape.borderRadius }}
                    >
                      <FontAwesomeIcon icon={faSlidersV} />
                    </IconBtn>
                  </Bx>
                </Bx>
              )}
            </Bx>
          </Bx>
        </Bx>
      </Collapse>
      {searchEnabled && headerFilter}
    </MobileHeadBar>
  );
};

export const Header: React.FC<{ onBack?: () => void }> = props => {
  const isMobile = useIsMobile();
  const [filterVisible] = useAtom(headerFilterAtom);
  const [filterWorking] = useAtom(headerFilterWorkingAtom);
  const [filterItem] = useAtom(filterItemAtom);
  const [filterAlias] = useAtom(filterAliasAtom);
  const [searchResult] = useAtom(searchResultAtom);
  const [step] = useAtom(stepAtom);
  const resetFilter = useSetAtom(resetFilterAtom);
  const updateQueryCategories = useSetAtom(queryCategoriesAtom);
  const updateDefaultSort = useSetAtom(filterDefaultSortAtom);
  useEffect(() => {
    resetFilter();
  }, [resetFilter]);
  const headerFilter = (
    <HeaderFilter
      smallVariant={isMobile}
      filterItem={filterItem}
      filterAlias={filterAlias}
      filtersVisible={!!filterVisible}
      filterWorking={!!filterWorking}
      searchResult={searchResult}
      handleClick={() => {
        console.log('handleClick');
      }}
      resetSearchFilter={() => {
        resetFilter();
      }}
      onTypeChange={(thingType: string) => {
        console.log('onTypeChange', thingType);
      }}
      onCategoryChange={(category: string, noToggle: boolean, parentCategoryId: string) => {
        let currentCategories = _.cloneDeep(filterItem?.content?.queryCategories);
        currentCategories = currentCategories || {};
        currentCategories[parentCategoryId] = currentCategories[parentCategoryId] || {};
        if (currentCategories[parentCategoryId][category]) {
          currentCategories[parentCategoryId][category] = 0;
        } else {
          currentCategories[parentCategoryId][category] = 1;
        }
        updateQueryCategories(currentCategories);
      }}
      onSortChange={(sortId: string) => {
        let currentSort = _.cloneDeep(filterItem?.content?.defaultSort?.content);
        currentSort = currentSort || {};
        const sortIdSplitted = sortId.split('_');
        currentSort.type = sortIdSplitted[0];
        currentSort.direction = sortIdSplitted[1];
        updateDefaultSort(currentSort);
      }}
      config={{ useUnconnectedSort: true, useUnconnectedCategoryFilter: true }}
    />
  );
  if (isMobile) {
    return <HeaderMobile {...props} searchEnabled={step === 0} headerFilter={headerFilter} />;
  } else {
    return <HeaderDesktop {...props} searchEnabled={step === 0} headerFilter={headerFilter} />;
  }
};
