import { Bx, Btn, IconBtn } from '@curry-group/mui-curcuma';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getDetailRoute } from '../../helper';
import { ThingTypes } from '../../model/ryve/Thing';

export interface IOneToOneCommunicationStateButtonProps {
  userId: string;
  onRequest: () => void;
}
export const OneToOneCommunicationStateButton: React.FC<IOneToOneCommunicationStateButtonProps> = ({ userId, onRequest }) => {
  const history = useHistory();
  const location = useLocation();

  const communication = useSelector(state => state.userProfile.communication);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const chatAllowed = useSelector(state => state.detail?.item?.chatAllowed);

  function navigateToOneToOne() {
    if (communication) {
      if (appconfig) {
        const route = getDetailRoute({ typeId: ThingTypes.OneToOne, alias: communication.alias }, appconfig, location);

        route && history.push(route);
      }
    }
  }

  if (!communication) {
    return (
      <Bx display="flex" justifyContent="center" alignItems="center">
        <Hidden smDown>
          <Btn title={!chatAllowed ? 'Benutzer hat Anfragen deaktiviert.' : ''} onClick={onRequest} disabled={!chatAllowed} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faComments} />}>
            Chat starten
          </Btn>
        </Hidden>
        <Hidden mdUp>
          <IconBtn onClick={onRequest} disabled={!chatAllowed} color="secondary" size="medium" variant="contained" shape="rounded">
            <FontAwesomeIcon icon={faComments} />
          </IconBtn>
        </Hidden>
      </Bx>
    );
  }

  if (communication.status === 'partner-pending') {
    return (
      <Bx display="flex" justifyContent="center" alignItems="center">
        <Hidden smDown>
          <Btn disabled color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faComments} />}>
            Antwort ausstehend
          </Btn>
        </Hidden>
        <Hidden mdUp>
          <IconBtn disabled color="secondary" size="medium" variant="contained" shape="rounded">
            <FontAwesomeIcon icon={faComments} />
          </IconBtn>
        </Hidden>
      </Bx>
    );
  }

  if (communication.status === 'accepted') {
    return (
      <Bx display="flex" justifyContent="center" alignItems="center">
        <Hidden smDown>
          <Btn onClick={navigateToOneToOne} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faComments} />}>
            Zur Konversation
          </Btn>
        </Hidden>
        <Hidden mdUp>
          <IconBtn onClick={navigateToOneToOne} color="secondary" size="medium" variant="contained" shape="rounded">
            <FontAwesomeIcon icon={faComments} />
          </IconBtn>
        </Hidden>
      </Bx>
    );
  }

  return <></>;
};
