import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useTheme from '@material-ui/core/styles/useTheme';
import { Ava, Bx, Divider, IAvaProps, IBxProps, ILinkProps, isExternalUrl, Link, TCurcumaColors, Typo } from '@curry-group/mui-curcuma';
import { hashCode, ListAvatarColors } from '../../helper';
import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassClock } from '@fortawesome/pro-solid-svg-icons';

export type TNavBarUserLinkProps = {
  name: string;
  title?: string;
  to?: string;
  target?: HTMLAnchorElement['target'];
  onClick?: (e: React.MouseEvent) => void;
};
export interface INavBarUserProps extends IBxProps {
  avatar: IAvaProps;
  fullName: string;
  userAbbreviation?: string;
  avatarTo?: (e: React.MouseEvent) => void | string;
  links?: Array<TNavBarUserLinkProps>;
  linksColor?: TCurcumaColors;
  linksSpacing?: number;
  linksDivider?: React.ReactElement;
  linksUnderline?: ILinkProps['underline'];
  userStatus?: string;
}

interface INavBarUserStyles {
  linksSpacing?: number;
  linksColor: React.CSSProperties['color'];
  linksColorHover: React.CSSProperties['color'];
}

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    innerBox: {
      marginLeft: theme.spacing(2)
    },
    name: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500
    },
    link: {
      cursor: 'pointer',
      fontSize: theme.typography.pxToRem(12),
      color: (props: INavBarUserStyles) => props.linksColor,

      '&:hover': {
        color: (props: INavBarUserStyles) => props.linksColorHover
      }
    },
    lastLink: {
      marginRight: (props: INavBarUserStyles) => (props.linksSpacing ? theme.spacing(props.linksSpacing / 2) : undefined)
    },
    linkBox: {
      marginRight: (props: INavBarUserStyles) => (props.linksSpacing ? theme.spacing(props.linksSpacing / 2) : undefined),
      '&:last-child': {
        marginRight: 0
      }
    }
  }),
  {
    name: 'Curcuma',
    meta: 'NavBarUser'
  }
);

/**
 * The `NavBarUser` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const NavBarUser: React.FunctionComponent<INavBarUserProps> = ({
  avatar,
  fullName,
  userStatus,
  userAbbreviation,
  avatarTo,
  links,
  linksColor,
  linksUnderline,
  className,
  linksSpacing,
  linksDivider,
  ...rest
}) => {
  const theme = useTheme();
  const compLinkDivider = linksDivider || <Divider direction="vertical" strokeColor="currentColor" height={10} />;
  const fc: string = linksColor === 'default' ? 'inherit' : _.get(theme.palette, linksColor + '.main', linksColor);
  const fch: string = linksColor === 'default' ? 'inherit' : _.get(theme.palette, linksColor + '.light', linksColor);
  const classes = useStyles({ linksSpacing, linksColor: fc, linksColorHover: fch });

  const hash = hashCode(userAbbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];

  const baseAvatar = typeof avatarTo === 'function'
    ? <Ava {...avatar} style={{color: colorObject?.color, background: colorObject?.bg, cursor: 'pointer', fontWeight: 500 }} onClick={avatarTo} />
    : <Ava {...avatar} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}} />;
  
  const userAvatar = !userStatus || userStatus !== 'to_check_signature_employer'
    ? baseAvatar
    : <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        color={'error'}
        title={'User noch nicht validiert.'}
        badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
      >
        {baseAvatar}
      </Badge>;

  return (
    <Bx className={clsx(classes.root, className)} {...rest} display="flex">
      {typeof avatarTo === 'string' && <Link to={avatarTo}>{userAvatar}</Link>}
      {typeof avatarTo === 'function' && userAvatar}
      {typeof avatarTo === 'undefined' && userAvatar}
      <Bx className={classes.innerBox}>
        <Typo color="inherit" className={classes.name}>{fullName}</Typo>
        <Bx display="flex" flexWrap="wrap">
          {!!links && links?.length > 0 &&
            links.map((link, idx) => {return (
              <Bx key={idx + link.name.toLowerCase()} className={classes.linkBox} display="flex" alignItems="center">
                <Link
                  component={link.to && !isExternalUrl(link.to) ? undefined : 'a'}
                  to={isExternalUrl(link.to) ? undefined : (link.to as any)}
                  href={isExternalUrl(link.to) ? link.to : undefined}
                  target={link.target}
                  onClick={link.onClick}
                  title={link.title}
                  underline={linksUnderline}
                  className={clsx(classes.link, idx + 1 !== links.length && classes.lastLink)}
                >
                  {link.name}
                </Link>
                {idx + 1 !== links.length && compLinkDivider}
              </Bx>
            );})}
        </Bx>
      </Bx>
    </Bx>
  );
};

NavBarUser.defaultProps = {
  linksUnderline: 'hover',
  linksSpacing: 2,
  linksDivider: undefined,
  linksColor: 'accent'
};
