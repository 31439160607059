import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FormEmailData } from '../../form/compositions/FormEmailData';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';

const EmailDataForm = (props: any) => {
  if (!props.flowData?.itemData?.email || !FormValidator.isValid('', 'emailData')) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormEmailData
        formData={props.flowData}
        headline={'Registrierung auf Medtec Online'}
        subtitleComponent={
          <Bx>
            <Typo variant="subtitle1">
              Bitte geben Sie Ihre berufliche oder Hochschul-E-Mail-Adresse an*.
              Anschließend erhalten Sie einen Registrierungslink per Mail. Wenn Sie diesem Link folgen, können Sie Ihre Registrierung
              abschließen. Der Link ist 48 Stunden gültig. Vor Abschluss der Registrierung ist eine Anmeldung nicht möglich. Sollten Sie keine Mail erhalten, prüfen Sie bitte
              zunächst Ihren Spam-Filter.
            </Typo>
            <Typo variant="body2" style={{fontSize: '12px'}}>
              *Private E-Mail-Adressen/öffentliche Mail-Domains (gmail.com, gmx.de o. Ä.) sind für die Registrierung generell nicht zugelassen. Sollte dies für Sie ein Problem darstellen, nehmen Sie bitte mit uns <a href="mailto:info@medteconline.de">Kontakt</a>{' '}auf.
            </Typo>
          </Bx>
        }
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default EmailDataForm;
