import { Btn, Bx, SwitchControl, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useFlow } from '../../../data/sagas/flow';
import { finishFlowRequestAction, setFlowDataAction, setFlowStepAction } from '../../../data/actions/flow';
import { WizardNavigation } from '@curry-group/react-wizard';
import { FormControlLabel } from '@material-ui/core';
import { useFilterState } from '../../../data/sagas/filter';
import { resetSearchObjectAction, setFrontendFilterAction } from '../../../data/actions/filter';
import { fetchFirstPageSearchEntriesRequestAction } from '../../../data/actions/search';
import { addParticipantsRequest } from '../../../data/reducer/communication';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { BottomBar } from '../../bottom-bar';

const Button = props => {
  return <Btn {...props} />;
};

export interface IFlowFooterProps {
  flow: any;
  filter: any;
  flowSteps: any[];
  currentStep: any;
  nextStepDisabled: boolean;
  setFlowStep: (step: number) => void;
  finishFlow: any;
  setFrontendFilter: () => void;
}

export const FlowFooter: React.FC<IFlowFooterProps> = ({ flow, filter, flowSteps, currentStep, nextStepDisabled, setFlowStep, finishFlow, setFrontendFilter }) => {
  const currentComponent = currentStep?.component;
  const buttonText = currentStep?.buttonText;
  const searchActive = currentComponent === 'createGroupUserSelect' && flow.currentAlias !== 'chat';
  const selectedCount = (flow?.flowData?.selectedUsers || []).length;
  const finishEarly = 0; // searchActive && selectedCount === 0 ? 1 : 0;
  const userSelectedEmployer = !!flow?.flowData?.userSelectedEmployer;
  const nextVisibleException = currentComponent === 'registerEmployerSearch' && userSelectedEmployer;
  const finishFlowDisabled = flow?.currentAlias === 'optin' || flow?.currentAlias === 'lostpassword' || flow?.currentAlias === 'resetpassword';
  const isMobile = useIsMobile();
  return (
    <WizardNavigation>
      {(callbacks, options) => {
        if (options.currentStep === options.stepCount - (2 + finishEarly)) {
          callbacks.nextClick = finishFlow;
        }
        if (flowSteps[options?.currentStep]?.nextVisible || nextVisibleException) {
          return (
            <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
              <BottomBar scrolled bottom={'unset'}>
                <Bx display="flex">
                  {searchActive && (
                    <React.Fragment>
                      <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                        <FormControlLabel
                          label={isMobile ? <Typo variant="body2">Nur hinzugefügte ({selectedCount}) anzeigen</Typo> : `Nur hinzugefügte Personen (${selectedCount}) anzeigen`}
                          control={
                            <SwitchControl
                              size={isMobile ? 'small' : 'medium'}
                              color={'secondary'}
                              checked={filter?.frontendFilterActive}
                              disabled={false}
                              onChange={() => {
                                setFrontendFilter();
                              }}
                            />
                          }
                        />
                      </Bx>
                    </React.Fragment>
                  )}
                  {!searchActive && (
                    <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                      &nbsp;
                    </Bx>
                  )}
                  <Bx alignSelf="flex-end" textAlign="right">
                    <Btn
                      size="large"
                      color="success"
                      variant="contained"
                      disabled={(nextStepDisabled || options.nextDisabled) && (options.currentStep <= options.stepCount - (2 + finishEarly) || finishFlowDisabled)}
                      onClick={e => {
                        e.preventDefault();
                        callbacks?.nextClick();
                        setFlowStep(options?.currentStep + 1);
                      }}
                    >
                      <Typo variant="inherit" noWrap>
                        {options.currentStep < options.stepCount - (2 + finishEarly) && (buttonText ? buttonText : 'Ok, weiter')}
                        {options.currentStep === options.stepCount - (2 + finishEarly) && (buttonText ? buttonText : 'Abschließen')}
                      </Typo>
                    </Btn>
                  </Bx>
                  {currentComponent === 'optinEmailData' &&
                    <Bx alignSelf="flex-end" textAlign="right" ml={1}>
                      <Button
                        size="large"
                        variant="outlined"
                        component={RouterLink}
                        to="/login"
                        ml={1}
                      >
                        <Typo variant="inherit" noWrap>
                          Anmelden
                        </Typo>
                      </Button>
                    </Bx>
                  }
                </Bx>
              </BottomBar>
            </Bx>
          );
        }
      }}
    </WizardNavigation>
  );
};

export interface IConnectedFlowFooter {
  alias: string;
  flowSteps: any[];
  nextStepDisabled: boolean;
}

export const ConnectedFlowFooter: React.FC<IConnectedFlowFooter> = ({ alias, flowSteps, nextStepDisabled }) => {
  const flow = useFlow();
  const history = useHistory();
  const filter = useFilterState();
  const currentStep = flowSteps[flow?.currentStep];
  const dispatch = useDispatch();
  const dispatcher = {
    setFlowDataAction: (flowData: any) => dispatch(setFlowDataAction?.({ alias, flowData })),
    setFlowStepAction: (step: number) => dispatch(setFlowStepAction?.({ step })),
    finishFlowRequestAction: () => dispatch(finishFlowRequestAction?.({ history })),
    setFrontendFilterAction: () => dispatch(setFrontendFilterAction?.({})),
    resetSearchObjectAction: () => dispatch(resetSearchObjectAction?.({})),
    fetchFirstPageSearchEntriesRequestAction: () => dispatch(fetchFirstPageSearchEntriesRequestAction?.({})),
    addParticipantsRequest: () => dispatch(addParticipantsRequest?.({})),
    toggleSelectAll: () => dispatch(addParticipantsRequest?.({}))
  };
  let finishFlowRequest: any = dispatcher.finishFlowRequestAction;
  if (alias === 'add-participants') {
    finishFlowRequest = dispatcher.addParticipantsRequest;
  }
  if (flow?.userRequestInvalid) {
    return <></>;
  }
  return (
    <FlowFooter
      flow={flow}
      filter={filter}
      flowSteps={flowSteps}
      currentStep={currentStep}
      nextStepDisabled={nextStepDisabled}
      setFlowStep={dispatcher.setFlowStepAction}
      finishFlow={finishFlowRequest}
      setFrontendFilter={dispatcher.setFrontendFilterAction}
    />
  );
};
