import { useDispatch, useSelector } from 'react-redux';
import { INavbarFrameConnectedProps, NavbarDrawer, NavbarFrame } from '../../components/navbar';
import { setNavbarOpenAction } from '../../data/actions/foundation';
import { assetUrl } from '../../helper';
import { buildAbbreviation } from '../../helper/user';

const useNavbarProps = () => {
  const badges = useSelector(state => state.notifications?.badges) || {};
  const profileUserId = useSelector(state => state.foundation?.profile?.userId);
  const profileFullName = useSelector(state => state.foundation?.profile?.fullName);
  const profileStatus = useSelector(state => state.foundation?.profile?.generalUserStatus);
  const profileAvatar = useSelector(state => state.foundation?.profile?.avatar);
  const navigation = useSelector(state => state.foundation?.navigation);
  const navbarOpen = useSelector(state => state.foundation?.navbarOpen);
  const dispatch = useDispatch();
  const setNavbarOpen = () => dispatch(setNavbarOpenAction(!navbarOpen));
  return {
    open: !!navbarOpen,
    setOpen: setNavbarOpen,
    sections: navigation || [],
    badges,
    userId: profileUserId,
    userFullName: profileFullName,
    userStatus: profileStatus,
    userAvatarSrc: profileAvatar ? assetUrl(profileAvatar, true) : undefined,
    userAbbreviation: buildAbbreviation(profileFullName),
  };
};

export const ConnectedNavBarDrawer: React.FC = () => {
  const stateProps = useNavbarProps();
  return <NavbarDrawer {...stateProps} />;
};

export const ConnectedNavBar: React.FC<INavbarFrameConnectedProps> = props => {
  const stateProps = useNavbarProps();
  return <NavbarFrame {...props} {...stateProps} />;
};
