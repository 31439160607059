import { imageFileExtensions } from '../../helper';
import { IProfile } from '../profile';
import { IThing } from './Thing';

export interface IAsset {
  _id: string;
  type: string;
  extension: string;
  fileSize: number;

  width?: number | null;
  height?: number | null;

  name: string;
  fullPath: string;
  displayName: string;

  copyright?: string;
  indicationOfSource?: string;
  published?: boolean;

  createdBy: string;
  modifiedBy?: string;
  createdAt: string;
  modifiedAt: string;

  content?: IAssetContent;
}

export interface IAssetContent {
  writeProtected?: boolean;
}

export interface IAssetResolved extends IAsset {
  createdByResolved: IThing<IProfile>;
}

export function isImage(asset: IAsset) {
  return imageFileExtensions.includes((asset?.extension || '').toLowerCase());
}
