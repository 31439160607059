import React from 'react';
import { Carousel, Ctn, ICtnProps, Typo, ICarouselResponsiveProps, Bx, TBreakpointContextProps } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import { SkeletonCard } from '../../../components/content-card';
import { IDimensions } from '../../../components/widget/content-cards';

interface ILandingPageCardSectionProps {
  containerMaxWidth: ICtnProps['maxWidth'];
  headline: string;
  responsive: TBreakpointContextProps;
  items: React.ReactElement[];
  responsiveSlider: ICarouselResponsiveProps;
}

export const LandingPageCardSection: React.FunctionComponent<ILandingPageCardSectionProps> =
({
    containerMaxWidth,
    headline,
    responsive,
    items,
    responsiveSlider
}) => {
  return (
    <Ctn maxWidth={containerMaxWidth}>
      <Bx mb={6}>
        <Typo variant="h1" component="h2" dangerouslySetInnerHTML={{ __html: headline }} />
      </Bx>
      {!!items && items.length > 0 && (
        <>
        {responsive.mdUp ? (
          <Grid container style={{ width: 'calc(100% + 4px)', margin: -2 }}>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              {items.length >= 1 && <Bx mb={0.5}>{items[0]}</Bx>}
              {items.length >= 2 && <Bx>{items[1]}</Bx>}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              {items.length >= 3 && <Bx mb={0.5}>{items[2]}</Bx>}
              {items.length >= 4 && <Bx>{items[3]}</Bx>}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              {items.length >= 5 && <Bx mb={0.5}>{items[4]}</Bx>}
              {items.length >= 6 && <Bx>{items[5]}</Bx>}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              {items.length >= 7 && <Bx mb={0.5}>{items[6]}</Bx>}
              {items.length >= 8 && <Bx>{items[7]}</Bx>}
            </Grid>
          </Grid>
        ) : (
          <Bx>
            {items.length && (
              <Carousel
                items={items}
                infinite
                responsive={responsiveSlider}
                renderDotsItem={({ isActive }) =>
                  isActive ? (
                    <Bx component="button" mx={1} border={0} p={0} width={10} height={10} bgcolor="primary.main" style={{ borderRadius: '50%' }}></Bx>
                  ) : (
                    <Bx component="button" mx={1} border={0} p={0} width={10} height={10} bgcolor="#cdcdcd" style={{ borderRadius: '50%' }}></Bx>
                  )
                }
                mouseTracking
                disableButtonsControls
              />
            )}
          </Bx>
        )}
        </>
      )}
      {(!items || items.length === 0) && (<>
        {responsive.mdUp ? (
          <Grid container style={{ width: 'calc(100% + 4px)', margin: -2 }}>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              <Bx mb={0.5}><SkeletonCard key={'loading-card-0'}
                item={{ _id:'loading-card-0', title:'loading-card-0', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
              <Bx><SkeletonCard key={'loading-card-1'}
                item={{ _id:'loading-card-1', title:'loading-card-1', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              <Bx mb={0.5}><SkeletonCard key={'loading-card-2'}
                item={{ _id:'loading-card-2', title:'loading-card-2', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
              <Bx><SkeletonCard key={'loading-card-3'}
                item={{ _id:'loading-card-3', title:'loading-card-3', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              <Bx mb={0.5}><SkeletonCard key={'loading-card-4'}
                item={{ _id:'loading-card-4', title:'loading-card-4', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
              <Bx><SkeletonCard key={'loading-card-5'}
                item={{ _id:'loading-card-5', title:'loading-card-5', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: 2 }}>
              <Bx mb={0.5}><SkeletonCard key={'loading-card-6'}
                item={{ _id:'loading-card-6', title:'loading-card-6', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
              <Bx><SkeletonCard key={'loading-card-7'}
                item={{ _id:'loading-card-7', title:'loading-card-7', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
                size="small"
                isLgUp={responsive.lgUp}
                isMdUp={responsive.mdUp}
                isSmUp={responsive.smUp}
              /></Bx>
            </Grid>
          </Grid>)
        : (<Bx>
          <SkeletonCard key={'loading-card-1'}
            item={{ _id:'loading-card-1', title:'loading-card-1', __original: {}, typeId: '', type: '', avatar: { type: 'none' }, }}
            size="medium"
            isLgUp={responsive.lgUp}
            isMdUp={responsive.mdUp}
            isSmUp={responsive.smUp}
          />
          </Bx>
        )}
      </>)}
    </Ctn>
  );
};
