import { theme } from '@curry-group/mui-curcuma';

export const conferenceTheme = {
  conferenceRaisedHand: {
    small: {
      width: 32,
      height: 32,
      fontSize: 18,
    },
    large: {
      width: 56,
      height: 56,
      fontSize: 24,
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.accent?.main,
    borderRadius: '50%',
    boxShadow: '2px 2px 4px 0 rgba(255,255,255,0.15)',
  },
  conferenceParticipant: {
    paddingTop: '70%',
    paddingTopSelf: '57.5%',
    borderRadius: 4,
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.15)',
    boxShadowSelf: `0 0 0 3px ${theme.palette.accent?.main}, 2px 2px 4px 0 rgba(0,0,0,0.15)`,
    noStreamBackgroundColor: '#2B3031',
    avaBackgroundColor: '#576164',
    avaColor: '#fff',
    menuBtnBackgroundColor: '#646a6b',
    menuBtnColor: '#fff',
    nameColor: '#fff',
    nameBackgroundColor: theme.palette.primary.dark,
    namePadding: '2px 6px',
  },
  conferenceIconBtn: {
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.15)',
    semiTransparent: 0.5,
    small: {
      width: 42,
      height: 42,
      fontSize: 18,
    },
    large: {
      width: 56,
      height: 56,
      fontSize: 24,
    },
    largeSpecial: {
      width: 112,
      height: 56,
      fontSize: 24,
    },
    light: {
      backgroundColor: '#fff',
      backgroundColorHover: '#EFEFEF',
      color: '#000',
    },
    dark: {
      backgroundColor: '#576164',
      backgroundColorHover: '#475052',
      color: '#fff',
    },
    red: {
      backgroundColor: '#780F2D',
      backgroundColorHover: '#670D27',
      color: '#fff',
    },
  },
  conferenceChatFrame: {
    widthXS: '100%',
    widthSM: '100%',
    // widthMD: 350,
    widthMD: 'calc(100vw - 55vw)',
    // widthLG: 380,
    widthLG: 'calc(100vw - 60vw)',
    // widthXL: 410,
    widthXL: 'calc(100vw - 70vw)',
    scrollBarTrackBackgroundColor: theme.palette.background.default,
    scrollBarThumbBackgroundColor: '#DDDFE0',
  },
  conferenceFrame: {
    gridBackgroundColor: '#576164',
    controlsBackgroundColor: '#2B3031',
  },
};

export declare type TSidebarType = 'chat' | 'participants' | 'device_settings' | 'health' | '';