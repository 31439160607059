import { callPost } from '@curry-group/data-addons';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { api } from '../../api';
import { resetBotRequestAction, resetBotFeedbackAction, fetchBotAnswerErrorAction, fetchBotAnswerRequestAction, fetchBotAnswerSuccessAction, trackBotFeedbackAction } from '../../actions/bot';
import { subscribeToSignalR, unsubscribeFromSignalR } from '../../reducer/communication';
import { ISearchResult } from '../../../model/search/SearchResult';
import { resetNewsReferences } from '../../actions/news';

export function* bot() {
  yield takeLatest(trackBotFeedbackAction.type, trackBotFeedbackWorker);
  yield takeLatest(fetchBotAnswerRequestAction.type, fetchBotAnswerWorker);
}

function* trackBotFeedbackWorker(action: ReturnType<typeof trackBotFeedbackAction>) {
  try {
    const botAnswer = yield select(state => state.bot?.botAnswer);
    const resFeed = yield callPost(api.v2.trackbotfeedback, {
      Query: action.payload.queryTerm,
      RequestId: action.payload.botRequestId,
      Response: botAnswer,
      Feedback: action.payload.feedback,
      Characteristic: action.payload.rating,
      AnonToken: action.payload.anonToken,
    });
  } catch (error: any) {
    console.log('Error in trackBotFeedbackWorker', error);
  }
}

function* waitFor(selector) {
  if (yield select(selector)) return; // return if selector true
  while (true) {
    yield take('*'); // wait with empty task
    if (yield select(selector)) return; // return if selector true
  }
}

function* fetchBotAnswerWorker(action) {
  try {
    yield call(waitFor, (state: { filter: { filterItem: any } }) => state.filter.filterItem);
    const searchParams = yield select(state => state.filter.searchObject);
    const botSearchActive = yield select(state => state.bot?.botSearchActive);

    yield put(resetBotRequestAction());
    yield put(resetNewsReferences());
    yield put(resetBotFeedbackAction());
    const botCommId = 'no_comm_XB_LPRequest_' + action.payload.anontoken;
    
    yield put(subscribeToSignalR({ id: botCommId, anonToken: action.payload.anontoken }));

    const result: ISearchResult = yield callPost(api.v2.anonymous_search, { ...searchParams, querySearchType: 'botsearch', anonToken: action.payload.anontoken });

    if (!!result.bot_error) {
      yield put(fetchBotAnswerErrorAction({ error: result.bot_error }));
    } else {
      yield put(fetchBotAnswerSuccessAction({ result: result?.bot_answer || '' }));
    }
    if (botSearchActive) {
      yield put(unsubscribeFromSignalR({ id: botCommId, anonToken: action.payload.anontoken }));
    }
  } catch (e: any) {
    yield put(fetchBotAnswerErrorAction({ error: e.message }));
  }
}
