import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Btn, BtnList, Bx, Ctn, IBxProps, IconBtn, ICtnProps, Link, TBreakpointContextProps, Typo } from '@curry-group/mui-curcuma';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';

interface ILandingPageHeaderProps {
  logoSrc: string;
  logoAlt: string;
  partnerLogoSrc: string;
  partnerLogoMobileSrc: string;
  partnerLogoAlt: string;
  ctnMaxWidth: ICtnProps['maxWidth'];
  scrolled: boolean;
  responsive: TBreakpointContextProps;
  registerLabel: string;
  registerLink: string;
  loginLabel: string;
  loginLink: string;
  auth: boolean;
  sectionPx: IBxProps['px'];
  backButtonLink?: () => void;
}

const Button = props => {
  return <Btn {...props} />;
};

export const LandingPageHeader: React.FunctionComponent<ILandingPageHeaderProps> = ({
  logoSrc,
  logoAlt,
  partnerLogoSrc,
  partnerLogoMobileSrc,
  partnerLogoAlt,
  ctnMaxWidth,
  scrolled,
  responsive,
  registerLabel,
  registerLink,
  loginLabel,
  loginLink,
  auth = false,
  sectionPx,
  backButtonLink
}) => {
  return (
    <Bx
      component="header"
      position={responsive.mdUp ? 'fixed' : 'sticky'}
      top={0}
      width="100%"
      py={backButtonLink ? 1 : responsive.mdUp ? (scrolled ? 2 : 7) : 1}
      // display={backButtonLink ? 'flex' : undefined}
      display={scrolled || backButtonLink || responsive.mdDown ? 'flex' : undefined}
      alignItems="center"
      px={sectionPx}
      zIndex={2}
      bgcolor={responsive.mdUp ? (scrolled ? 'background.paper' : undefined) : 'background.paper'}
      boxShadow={{ xs: 3, md: scrolled ? 3 : 0 }}
      style={{ zIndex: 2, transition: 'all .15s ease-in-out', willChange: 'padding, box-shadow, background-color' }}
      height={responsive.mdUp ? 80 : 60}
    >
      {(responsive.wqhd || responsive.wqhdUp) && backButtonLink && (
        <Bx zIndex={2} position="absolute" top={12}>
          <IconBtn mrSelf size="large" onClick={() => backButtonLink()}>
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </IconBtn>
        </Bx>
      )}
      <Ctn maxWidth={ctnMaxWidth}>
        <Bx display="flex" alignItems={{ md: !scrolled ? 'flex-start' : 'center' }} justifyContent="space-between">
          <Bx display="flex" alignItems="center">
            {!responsive.wqhd && responsive.wqhdDown && backButtonLink && (
              <Bx zIndex={2} position={responsive.wqhdUp ? 'absolute' : undefined} left={responsive.wqhdUp ? -80 : undefined} mr={!responsive.wqhdUp && !responsive.mdDown ? 3 : undefined}>
                <IconBtn size={responsive.smDown ? 'medium' : 'large'} onClick={() => backButtonLink()}>
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                </IconBtn>
              </Bx>
            )}
            <Link to="/" onClick={e => { window.scrollTo({ top: 0, behavior: 'smooth' }); }} >
              <img src={logoSrc} alt={logoAlt} style={{ height: responsive.mdUp ? undefined : 28, display: 'block' }} />
            </Link>
          </Bx>
          {responsive.mdUp && (
            <Fade in={scrolled}>
              <Bx display="flex" flexDirection="row" alignItems="center">
                {!auth && <Bx mr={5} mt={{ xs: 3, sm: 0 }} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                  <Bx mr={2} position="relative" top={-5}>
                    <Typo variant="body2" component="span" style={{ fontSize: 12, textTransform: 'uppercase' }}>
                      Eine Initiative vom
                    </Typo>
                  </Bx>
                  <Bx>
                    <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ maxHeight: '50px' }}/>
                  </Bx>
                </Bx>}
                <Bx ml={!auth? 0 : 'auto'}>
                  <BtnList spacing={1}>
                    <Button color="secondary" size={responsive.mdUp ? 'large' : 'medium'} variant="contained" component={RouterLink} to={registerLink}>
                      {registerLabel}
                    </Button>
                    <Button variant="outlined" size={responsive.mdUp ? 'large' : 'medium'} component={RouterLink} to={loginLink}>
                      {loginLabel}
                    </Button>
                  </BtnList>
                </Bx>
              </Bx>
            </Fade>
          )}
          {responsive.mdUp && !scrolled && (
            <Bx display="flex" flexDirection="column">
              <Typo variant="h6" gutterBottom>
                Nationales Community-Portal Medtec Online
              </Typo>
              <Bx mt={{ xs: 3, sm: 0 }} display="flex" justifyContent="flex-end">
                <Bx mr={2} position="relative" top={-5}>
                  <Typo variant="body2" component="span" style={{ fontSize: 12, textTransform: 'uppercase' }}>
                    Eine Initiative vom
                  </Typo>
                </Bx>
                <Bx>
                  <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ maxHeight: '60px' }}/>
                </Bx>
              </Bx>
            </Bx>
          )}
          {!responsive.mdUp && <Bx display="flex" flexDirection="row">
            <Typo variant="body2" component="span" style={{ fontSize: 8, lineHeight: 1, textTransform: 'uppercase' }}>
              Eine Initiative vom
            </Typo>
            <Bx pl={.5}>
              <img src={partnerLogoMobileSrc} alt={partnerLogoAlt} style={{ height: 42 }}/>
            </Bx>
          </Bx>}
        </Bx>
      </Ctn>
    </Bx>
  );
};
