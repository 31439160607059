import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FramedContent } from '../../framed-content';

const TypeSelect = (props: any) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Welche Art von Beitrag möchten Sie posten?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Mit Ihrem Beitrag erreichen Sie die gesamte Fachcommunity auf Medtec Online.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'idea'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowType('idea');
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/idee.svg" alt="Idee teilen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Idee
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie möchten eine Idee zur Diskussion stellen oder mit anderen umsetzen?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'requisition'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowType('requisition');
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/bedarf.svg" alt="Bedarf melden" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Bedarf
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie möchten als Leistungserbringer einen medizinischen Bedarf melden?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.currentType === 'qna'}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowType('qna');
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/frage.svg" alt="Frage stellen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Frage
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie suchen Antworten rund um das Thema Innovation?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export default TypeSelect;
