import React, { ReactNode } from 'react';
import { Btn, Bx, IBxProps, IconBtn } from '@curry-group/mui-curcuma';
import { faArrowRight, faArrowToRight, faBookmark as faBookmarkLight, faComment, faPencil, faSearch, faShare, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';

export interface IContentInteractionBarProps {
  withoutFrame?: boolean;
  numVotes?: number;
  voteUpClick?: () => void;
  voteDownClick?: () => void;

  numComments?: number;
  chatButton?: {
    type?: 'discussion' | 'participate' | 'apply';
    onClick?: () => any;
  };

  shareClick?: () => void;
  memoryClick?: () => void;
  editClick?: () => void;
  inviteClick?: () => void;
  searchGoupsClick?: () => void;

  extraContent?: ReactNode;
  booked?: boolean;
}

export const ContentInteractionBar: React.FC<IContentInteractionBarProps> = ({
  withoutFrame,
  chatButton,
  numVotes,
  voteDownClick,
  voteUpClick,
  numComments,
  shareClick,
  memoryClick,
  editClick,
  inviteClick,
  searchGoupsClick,
  extraContent,
  booked
}) => {
  const showVoting = !!(voteUpClick || voteDownClick);
  const showInteractionBar = !!(showVoting || chatButton || extraContent || shareClick || editClick || inviteClick || searchGoupsClick);
  let frameProps: IBxProps = withoutFrame ? {} : { borderColor: 'divider', border: 1, borderLeft: 0, borderRight: 0, height: 74 };

  if (!showInteractionBar) return <></>;
  return (
    <Bx {...frameProps}>
      <Bx display="flex" alignItems="center" height="100%" py={0.5}>
        {/* {showVoting && (
          <Hidden smDown>
            <Bx display="flex" justifyContent="center" alignItems="center">
              <VotesAva size="large" color="secondary" votes={numVotes} colorVariant="contained" />
            </Bx>
            <Bx ml={0.5} px={0.5} display="flex" justifyContent="center" alignItems="center" borderColor="divider" border={1} borderLeft={0} borderTop={0} borderBottom={0}>
              <IconBtn onClick={voteUpClick} disabled={!voteUpClick}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </IconBtn>
            </Bx>
            <Bx ml={0.5} px={0.5} display="flex" justifyContent="center" alignItems="center" borderColor="divider" border={1} borderLeft={0} borderTop={0} borderBottom={0}>
              <IconBtn onClick={voteDownClick} disabled={!voteDownClick}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </IconBtn>
            </Bx>
          </Hidden>
        )} */}
        {chatButton && chatButton.type === 'discussion' && (
          <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <Btn
                disabled={!chatButton.onClick}
                onClick={chatButton.onClick}
                color="secondary"
                size="large"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faComment} />}
              >
                Diskussion ({numComments || 0})
              </Btn>
            </Hidden>
            <Hidden mdUp>
              <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled={!chatButton.onClick} onClick={chatButton.onClick}>
                <FontAwesomeIcon icon={faComment} />
              </IconBtn>
            </Hidden>
          </Bx>
        )}
        {chatButton && chatButton.type === 'participate' && (
          <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <Btn
                disabled={!chatButton.onClick}
                onClick={chatButton.onClick}
                color="secondary"
                size="large"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faArrowToRight} />}
              >
                Teilnehmen
              </Btn>
            </Hidden>
            <Hidden mdUp>
              <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled={!chatButton.onClick} onClick={chatButton.onClick}>
                <FontAwesomeIcon icon={faArrowToRight} />
              </IconBtn>
            </Hidden>
          </Bx>
        )}
        {chatButton && chatButton.type === 'apply' && (
          <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <Btn
                disabled={!chatButton.onClick}
                onClick={chatButton.onClick}
                color="secondary"
                size="large"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faArrowRight} />}
              >
                {chatButton.onClick ? 'Beitritt anfragen' : 'Anfrage läuft'}
              </Btn>
            </Hidden>
            <Hidden mdUp>
              <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled={!chatButton.onClick} onClick={chatButton.onClick}>
                <FontAwesomeIcon icon={faArrowRight} />
              </IconBtn>
            </Hidden>
          </Bx>
        )}
        {!!extraContent && (
          <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
            {extraContent}
          </Bx>
        )}
        <Bx ml={{ xs: 0, md: 'auto' }} display="flex" justifyContent="center" alignItems="flex-end">
          {!!searchGoupsClick && (
            <Bx mr={!editClick && !inviteClick && !shareClick ? 0 : 1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={searchGoupsClick} color="primary" size="large" variant="outlined" startIcon={<FontAwesomeIcon icon={faSearch} />}>
                  Relevante Gruppen finden
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="primary" size="medium" variant="outlined" shape="rounded" onClick={searchGoupsClick}>
                  <FontAwesomeIcon icon={faSearch} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {!!shareClick && (
            <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={shareClick} color="primary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faShare} />}>
                  Teilen
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="primary" size="medium" variant="contained" shape="rounded" onClick={shareClick}>
                  <FontAwesomeIcon icon={faShare} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {!!memoryClick && (
            <Bx mr={!editClick && !inviteClick ? 0 : 1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn
                  onClick={memoryClick}
                  color="primary"
                  size="large"
                  variant={booked ? 'contained' : 'outlined'}
                  startIcon={
                    <FontAwesomeIcon icon={booked ? faBookmark : faBookmarkLight} />
                  }
                >
                  {booked ? 'Gemerkt' : 'Merken'}
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="primary" size="medium" variant="contained" shape="rounded" onClick={memoryClick}>
                  {<FontAwesomeIcon icon={booked ? faBookmark : faBookmarkLight} />}
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {!!inviteClick && (
            <Bx mr={!editClick ? 0 : 1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={inviteClick} color="default" size="large" variant="outlined" startIcon={<FontAwesomeIcon icon={faUserPlus} />}>
                  Einladen
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={inviteClick}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {!!editClick && (
            <Bx display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={editClick} color="default" size="large" variant="outlined" startIcon={<FontAwesomeIcon icon={faPencil} />}>
                  Bearbeiten
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={editClick}>
                  <FontAwesomeIcon icon={faPencil} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
        </Bx>
      </Bx>
    </Bx>
  );
};
export const ContentInteractionBarAnon: React.FC<{
  withoutFrame?: boolean;
  numComments?: number;
  shareClick?: () => void;
  isGroup?: boolean;
}> = ({ withoutFrame, numComments, shareClick, isGroup }) => {
  let frameProps: IBxProps = withoutFrame ? {} : { borderColor: 'divider', border: 1, borderLeft: 0, borderRight: 0, height: 74 };

  return (
    <Bx {...frameProps}>
      <Bx display="flex" alignItems="center" height="100%" py={0.5}>
        <Bx ml={{ xs: 0, md: 'auto' }} display="flex" justifyContent="center" alignItems="flex-end">
          {numComments !== undefined && (
            <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn disabled={true} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faComment} />}>
                  Diskussion ({numComments || 0})
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled={true}>
                  <FontAwesomeIcon icon={faComment} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {isGroup && (
            <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn disabled={true} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowRight} />}>
                  Beitritt anfragen
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled={true}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          <Bx mr={0} display="flex" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <Btn onClick={shareClick} color="primary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faShare} />}>
                Teilen
              </Btn>
            </Hidden>
            <Hidden mdUp>
              <IconBtn color="primary" size="medium" variant="contained" shape="rounded" onClick={shareClick}>
                <FontAwesomeIcon icon={faShare} />
              </IconBtn>
            </Hidden>
          </Bx>
        </Bx>
      </Bx>
    </Bx>
  );
};
