import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { useLocation } from 'react-router';
import { Ava, Bx, Link, Hamburger } from '@curry-group/mui-curcuma';
import { getNavigationPath, INavigationConfig, INavigationContainerConfig } from '../../../model/configuration';
import { IDictionary } from '../../../helper';
import { useSelector } from 'react-redux';
import { NavBar } from '../NavBar';
import { NavBarIconBtn } from '../../navbar-icon-btn';
import { iconMapping, INavbarBaseProps, navItemActive } from '..';

export const NavbarMobile: React.FC<INavbarBaseProps> = props => {
  const location = useLocation();

  return (
    <NavBar py={2} small>
      <Bx component="nav">
        <NavBarIconBtn pt={1.5} to="#" aria-label={props.open ? 'Menü schließen' : 'Menü öffnen'} onClick={() => props.setOpen(!props.open)}>
          <Hamburger toggled={props.open} label={props.open ? 'Menü schließen' : 'Menü öffnen'} />
        </NavBarIconBtn>

        {props.sections.map((section, sectionIndex) => {
          return (
            <Bx mt={4} display="flex" flexDirection="column" alignItems="center" key={sectionIndex}>
              {section.configs
                .filter(item => item.visible)
                .map((item, itemIndex) => (
                  <NavbarContentBtnMobile key={itemIndex} badges={props.badges} section={section} config={item} />
                ))}
            </Bx>
          );
        })}
      </Bx>

      <Bx mt="auto">
        <Bx mt={4}>
          <Link to={'/user/profile?ref=' + location.pathname}>
            <Ava size="small" src={props.userAvatarSrc} alt={'Avatar von ' + props.userFullName} />
          </Link>
        </Bx>
      </Bx>
    </NavBar>
  );
};

export const NavbarContentBtnMobile: React.FC<{ section: INavigationConfig; config: INavigationContainerConfig; badges: IDictionary<number> }> = ({
  section,
  config,
  badges
}) => {
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation?.appconfig?.[config.alias]);
  let badgeCount = 0;
  if (appconfig?.sidebarTypes && appconfig?.sidebarTypes.length) {
    for (let type of appconfig?.sidebarTypes) {
      if (badges[type]) {
        badgeCount += badges[type];
      }
    }
  } else if (appconfig?.alias === 'notifications') {
    badgeCount = badges['notifications'] || 0;
  }
  const active = navItemActive(location, getNavigationPath(section, config));
  return (
    <NavBarIconBtn
      to={getNavigationPath(section, config) || '/'}
      key={config.alias}
      active={active}
      aria-label={config.name}
      color="inherit"
      badgeContent={badgeCount ? badgeCount : undefined}
      badgeColor={active ? 'tertiary' : 'secondary'}
    >
      <FontAwesomeIcon icon={iconMapping[config.alias] || faQuestionCircle} />
    </NavBarIconBtn>
  );
};
