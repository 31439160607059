import { IContentCardCollection } from '../../components/widget/content-cards';

export class PageElementVisualization {
  static LIST = '00000000-0000-0000-0000-000000000000';
  static CARD = '00000000-0000-0000-0000-000000000001';
  static MESSAGE = '00000000-0000-0000-0000-000000000002';
  static BOTSEARCH = '00000000-0000-0000-0000-000000000003';
}

export class PageElementCardDesign {
  static FLAT = 'flat';
  static LEFT = 'left';
  static LEFT_ALTERNATING = 'left-alternating';
  static RIGHT = 'right';
  static RIGHT_ALTERNATING = 'right-alternating';
}

export class PageElementContentSelection {
  static MANUAL = '00000000-0000-0000-0000-000000000000';
  static SEARCH = '00000000-0000-0000-0000-000000000001';
  static RELATED = '00000000-0000-0000-0000-000000000002';
}

export class PageElementCategoryType {
  static MANUAL = '00000000-0000-0000-0000-000000000000';
  static CONTENT = '00000000-0000-0000-0000-000000000001';
  static USER = '00000000-0000-0000-0000-000000000002';
}

export interface IPageElement {
  pageElementTitle: string;
  pageElementShowResultCount?: boolean;
  pageElementVisualization?: string;
  pageElementVisualization__: string;
  pageElementCardDesign?: string;
  pageElementCardDesign__?: IContentCardCollection['design'];
  pageElementContentSelection?: string;
  pageElementContentSelection__: string;
  pageElementQueryAdditionalId?: string;
  pageElementSort?: string;
  pageElementSort__: string;
  pageElementMaxResults?: number;
  pageElementThingDescriptions?: string[];
  pageElementCategoryType?: string;
  pageElementCategoryType__?: string;
  pageElementCategorySelection?: { categoryId: string }[];
  pageElementUniversalElements?: any[];
  pageElementCategoryExactMatch?: boolean;
  excludeChats?: boolean;
  excludeGroups?: boolean;
  excludeOwn?: boolean;
  pageElementQueryMaxRotate?: number;
  pageElementUserExcludeCompetences?: boolean;
  pageElementUserExcludeInterests?: boolean;
  onlyFromUserMemoryList?: boolean;
}
