import React from 'react';
import { Ava, Btn, Bx, ClickableCard, IClickableCardProps, ParticipantsAva, TCurcumaColorsOnly, TimeAva, Typo, VotesAva } from '@curry-group/mui-curcuma';
import { IListItem } from '../../model/list/ListItem';
import { useSelector } from 'react-redux';
import { ListAvatarColors, getDetailRoute, hashCode } from '../../helper';
import { buildAbbreviation } from '../../helper/user';
import { useLocation } from 'react-router';
import { Grid } from '@material-ui/core';
import { TUtilSpacingProps } from '@curry-group/mui-curcuma/dist/ui/Utils/Spacing';
import { Skeleton } from '@material-ui/lab';
import { faBookmark as faBookmarkLight } from '@fortawesome/pro-light-svg-icons';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMemoryListItem, ThingTypes } from '../../model/ryve/Thing';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Badge } from '../list-items/default/badge';
import { CardHeader } from '../card/header';

const useStyles = makeStyles(theme => ({
  memoButton: {
    padding: 0,
    minWidth: 0,
    '& > .MuiButton-label': {
      padding: 0,
      '& > .typo': {
        lineHeight: 1
      }
    }
  }
}));

interface IContentCard extends IBreakpoints {
  item: IListItem;
  size?: 'small' | 'medium' | 'large' | 'larger';
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE') => void;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

const getOuterPadding = (size: IContentCard['size']): { py: TUtilSpacingProps; px: TUtilSpacingProps } | undefined => {
  switch (size) {
    case 'large':
      return { py: 3.5, px: 4 };
    case 'medium':
      return { py: 2, px: 2 };
    case 'small':
      return { py: 2, px: 2 };
  }
};

export const ContentCard: React.FC<IContentCard> = ({
  item,
  size,
  isLgUp,
  isMdUp,
  isSmUp,
  itemMemoClicked
}) => {
  const classes = useStyles();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const items = useSelector(state => state.categories?.items);
  const location = useLocation();
  return (
    <ClickableCard
      color={item.color as IClickableCardProps['color']}
      hoverColor={item.color as IClickableCardProps['hoverColor']}
      backgroundImage={item.backgroundSrc?.src || 'https://source.unsplash.com/WLUHO9A_xik/1600x900'}
      aspect="16:10"
      height="100%"
      to={getDetailRoute(item, appconfig || {}, location)}
    >
      <Bx {...getOuterPadding(size)} height="100%" display="flex" flexDirection="column">
        <Bx display="flex" mb="auto">
          <Bx>
            <Typo gutterBottom display="block" variant="subtitle2" component="span" textTransform="uppercase">
              {!!item.badge && (<><Badge color="secondary">neu</Badge>&nbsp;&nbsp;</>)}
              {item.type}<br/>
              {localStorage.devModeSearch && (<span>
                {item._id}<br/>
                {item._score}
                {item.categories && <>
                  <br/>
                  {item.categories?.map(cat =>
                    (items[cat] ? items[cat].name : cat) + ' || '
                  )}
                </>}
              </span>)}
            </Typo>
            {item.subtitle && (
              <Typo variant="body1" textTransform="uppercase" fontWeight={500}>
                XSUB_{item.subtitle}
              </Typo>
            )}
          </Bx>
          <Bx ml="auto">
            <Bx display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
              <Btn className={clsx(classes.memoButton)}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  itemMemoClicked?.(
                    {
                      elementId: (item?.typeId === ThingTypes.News ? item.__original?.content?.url : item._id) || '',
                      externalId: item?.__original?.content?.url || '',
                      type: 'thing',
                      typeId: item.typeId || ''
                    },
                    item.booked ? 'DELETE' : 'POST'
                  );
                }}
              >
                <Typo variant="body1" component="span" color="background.paper">
                  {!item.booked && <FontAwesomeIcon icon={faBookmarkLight} />}
                  {!!item.booked && <FontAwesomeIcon icon={faBookmark} />}
                </Typo>
              </Btn>
            </Bx>
            {item?.avatar?.type === 'voting' && <VotesAva size={size === 'large' ? size : 'medium'} votes={item?.avatar?.voting || 0} />}
            {item?.avatar?.type === 'comments' && (
              <VotesAva size={size === 'large' ? size : 'medium'} votes={item?.avatar?.comments || 0} label={item?.avatar?.commentsCaption || 'Antworten'} />
            )}
            {item?.avatar?.type === 'participants' && <ParticipantsAva size={size === 'large' ? size : 'medium'} participants={item?.avatar?.participants} />}
            {item?.avatar?.type === 'time' && item?.avatar?.time && (
              <TimeAva size={size === 'large' ? size : 'medium'} fromTime={item?.avatar?.time?.from} toTime={item?.avatar?.time?.to} />
            )}
            {item?.avatar?.type === 'image' && (
              <Ava src={item?.avatar?.image} size={size === 'large' ? size : 'medium'} variant="rounded" />
            )}
          </Bx>
        </Bx>
        <Bx mt={2}>
          {item.title && (
            <Typo
              gutterBottom={isSmUp && (item.subtitle_2 || item.description) ? true : undefined}
              variant={size !== 'small' ? (isLgUp ? 'h1boosted' : 'h5') : isLgUp ? 'h5boosted' : 'h5'}
              component="h2"
            >
              {item.title}
            </Typo>
          )}
          {item.title && size !== 'small' && (
            <Bx>
              <Typo variant={'body1boosted'} maxLines={3}>
                {item.subtitle_2}
              </Typo>
            </Bx>
          )}
          {!item.title && item.subtitle_2 && (
            <Typo variant={isMdUp ? 'h5boosted' : 'h5'} component="h3">
              {item.subtitle_2}
            </Typo>
          )}
          {item.description && (
            <Bx mt={0.5}>
              <Typo variant={isMdUp ? 'subtitle2boosted' : 'subtitle2'} component="span" textTransform="uppercase">
                {item.description}
              </Typo>
            </Bx>
          )}
        </Bx>
      </Bx>
    </ClickableCard>
  );
};

export const ProfileCard: React.FC<IContentCard> = ({ item, size, isLgUp, isMdUp, isSmUp }) => {
  const appconfig = useSelector(state => state.foundation.appconfig);
  const location = useLocation();
  const abbreviation = buildAbbreviation(item.title ?? '');
  const hash = hashCode(abbreviation);
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  return (
    <Grid>
      <ClickableCard variant="outlined" color="default" to={getDetailRoute(item, appconfig || {}, location)}>
        <CardHeader
          src={item.backgroundSrc?.src}
          alt={`${item.title} Keyvisual`}
          avatar={
            <Ava
              src={item.avatar.image}
              size="larger"
              alt={`${item.title} Avatar`}
              style={{color: colorObject.color, background: colorObject.bg}}
            >
              {abbreviation}
            </Ava>
          }
        />
        <Bx py={2} px={3} minHeight={180} display="flex" flexDirection="column">
          <Bx mb={1}>
            <Typo align="center" variant="body1">
              <strong>{item.title || ''}</strong><br/>
              {item._id}<br/>
              {item._score}
            </Typo>
          </Bx>
          <Bx mb={1}>
            <Typo align="center" variant="body2">
              {item.subtitle ? item.subtitle : <span>&nbsp;</span>}
            </Typo>
          </Bx>
          <Bx mb={2}>
            <Typo align="center" variant="body2">
              {item.subtitle_2 ? item.subtitle_2 : <span>&nbsp;</span>}
            </Typo>
          </Bx>
        </Bx>
      </ClickableCard>
    </Grid>
  );
};

export const SkeletonCard: React.FC<IContentCard> = ({ item, size, isLgUp, isMdUp, isSmUp }) => {
  let color:TCurcumaColorsOnly = 'primary';
  return (
    <ClickableCard
      color={color}
      hoverColor={color}
      aspect="16:10"
      height="100%"
    >
      <Bx {...getOuterPadding(size)} height="100%" display="flex" flexDirection="column">
        <Bx display="flex" mb="auto">
          <Bx>
            <Typo gutterBottom display="block" variant="subtitle2" component="span" textTransform="uppercase">
              <Skeleton style={{backgroundColor: 'rgba(192,192,192,0.25)'}} animation="pulse" width="100px" />
            </Typo>
          </Bx>
        </Bx>
        <Bx mt={2}>
          <Typo gutterBottom variant={isMdUp ? 'h1' : 'h5'} component="h2">
            <Skeleton style={{backgroundColor: 'rgba(192,192,192,0.25)'}} animation="pulse" width="80%" />
          </Typo>
          {isSmUp && (
            <Bx>
              <Typo variant={isMdUp ? 'body1' : 'body2'} maxLines={{ sm: 2, md: 2 }}>
                <Skeleton style={{backgroundColor: 'rgba(192,192,192,0.25)'}} animation="pulse" width="100%" />
                <Skeleton style={{backgroundColor: 'rgba(192,192,192,0.25)'}} animation="pulse" width="80%" />
              </Typo>
            </Bx>
          )}
        </Bx>
      </Bx>
    </ClickableCard>
  );
};
