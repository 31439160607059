import { Bx, IconAva, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlayersByParentIdRequestAction, fetchPlayersByParentIdResetAction } from '../../../../data/actions/search';
import { fetchPlayerAutoSuggest } from '../../../../data/reducer/userprofile';
import { GetActorIcon, GetActorType, GetActorTypeString } from '../../../../helper';
import useTheme from '@material-ui/core/styles/useTheme';

export const employerSecondLevel = (actorSubtype: string) => {
  return actorSubtype === 'hospital' || actorSubtype === 'university' || actorSubtype === 'public research center' || actorSubtype === 'non-public research facility';
};
export const employerSecondLevelOptions = (actorSubtypeContent: any) => {
  switch (actorSubtypeContent.subtype) {
    case 'hospital':
      return [...(actorSubtypeContent.faculties || []), ...(actorSubtypeContent.standorte || [])];
    case 'university':
    case 'public research center':
    case 'non-public research facility':
      return (
        actorSubtypeContent.departments.map(dep => {
          return { name: dep.name, id: dep._id };
        }) || []
      );
    default:
      return [];
  }
};

export const EmployerSelect: React.FC<{
  label: string;
  sublabel: string;
  customLabelVariant?: 'body2' | 'h3' | 'h3boosted';
  preset?: any;
  subpreset?: any;
  canAddEntry?: boolean;
  onSelectFirstLevel: (o: any) => void;
  onSelectSecondLevel: (o: any) => void;
  onSelect: (o: any, level?: string) => void;
}> = ({
  label,
  sublabel,
  customLabelVariant,
  preset,
  subpreset,
  onSelectFirstLevel,
  onSelectSecondLevel,
  onSelect,
  canAddEntry
}) => {
  const options = useSelector(state => state.userProfile.autoSuggest?.hits?.hits);
  const optionsSecondLevel = useSelector(state => state.search.playerResult?.hits?.hits);
  const [lazy, setLazy] = useState(0);
  const [query, setQuery] = useState(preset?._source?.content?.name ?? '');
  const [subquery, setSubquery] = useState(subpreset?._source?.content?.name ?? '');
  const dispatch = useDispatch();
  const theme = useTheme();
  const responsive = useBreakpoints();
  const isMDUp = responsive.mdUp || false;
  const iconSize = isMDUp ? "large" : "medium";
  const finerSpacing = theme.spacing(0.1);
  const subFontSize = isMDUp ? 12 : 10;
  return (
    <>
      <Typo variant={customLabelVariant ? customLabelVariant : 'h3'} component="label">
        {label}
      </Typo>
      <Bx mt={1}>
        <Autocomplete
          value={preset}
          options={options || []}
          noOptionsText={!!options && options.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
          autoComplete
          inputValue={query || preset?._source?.content?.name || ''}
          includeInputInList={canAddEntry}
          placeholder="keine Angabe"
          renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
          onChange={(e, v: any) => {
            onSelectFirstLevel(v);
            setQuery('');
            if (!v || !employerSecondLevel(v?._source?.content?.subtype)) {
              onSelect(v, 'first');
              dispatch(fetchPlayersByParentIdResetAction({}));
              if (!v) {
                setSubquery('');
              }
            } else {
              dispatch(fetchPlayersByParentIdRequestAction({ type: v?._source?.content?.type, id: v?._id }));
            }
          }}
          onInputChange={(event, newInputValue) => {
            window.clearTimeout(lazy);
            setQuery(newInputValue);
            setLazy(
              window.setTimeout(() => {
                dispatch(fetchPlayerAutoSuggest(newInputValue.trim()));
              }, 250)
            );
          }}
          getOptionLabel={o => {
            const label = (o as any)?._source?.content?.name ?? '';
            return label;
          }}
          getOptionSelected={o => (o as any)?._id === preset?._id}
          filterOptions={o => o}
          renderOption={o => {
            const actor = (o as any)?._source?.content;
            return (
              <Bx display="flex" alignItems="stretch">
                <Bx mr={2}>
                  <IconAva size={iconSize} variant="rounded">
                    <FontAwesomeIcon icon={GetActorIcon(actor.type)} />
                  </IconAva>
                </Bx>
                <Bx display="flex" flexDirection="column" width="100%">
                  <Bx display="flex" flexDirection="column" justifyContent="center">
                    <Typo variant="subtitle2" component="span" textTransform="uppercase" style={{ fontSize: subFontSize }}>
                      {GetActorTypeString(actor.type)}
                    </Typo>
                    <Typo fontWeight={500} variant="body1" component="span">
                      {actor.name}
                    </Typo>
                    <Bx display="flex" style={{ marginTop: finerSpacing }}>
                      <Typo variant="subtitle2" component="span" textTransform="uppercase" style={{ fontSize: subFontSize }}>
                        {actor.city}
                      </Typo>
                    </Bx>
                  </Bx>
                </Bx>
              </Bx>
            )
          }}
        />
      </Bx>
      {employerSecondLevel(preset?._source?.content?.subtype) && (
        <Bx mt={3}>
          <Typo variant={customLabelVariant ? customLabelVariant : 'h3'} component="label">
            {sublabel}
          </Typo>
          <Bx mt={1}>
            <Autocomplete
              value={subpreset}
              options={optionsSecondLevel || []}
              noOptionsText={optionsSecondLevel?.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
              autoComplete
              inputValue={subquery || subpreset?._source?.content?.name || ''}
              includeInputInList={canAddEntry}
              placeholder="keine Angabe"
              renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
              onChange={(e, v) => {
                onSelectSecondLevel(v);
                onSelect(v, 'second');
                setSubquery('');
              }}
              onInputChange={(event, newInputValue) => {
                window.clearTimeout(lazy);
                setSubquery(newInputValue);
              }}
              getOptionLabel={o => (o as any)?._source?.content?.name ?? ''}
              getOptionSelected={o => (o as any)?._id === subpreset?._id}
              renderOption={o => {
                const actor = (o as any)?._source?.content;
                return (
                  <Bx display="flex" alignItems="stretch">
                    <Bx mr={2}>
                      <IconAva size={iconSize} variant="rounded">
                        <FontAwesomeIcon icon={GetActorIcon(actor.type)} />
                      </IconAva>
                    </Bx>
                    <Bx display="flex" flexDirection="column" width="100%">
                      <Bx display="flex" flexDirection="column" justifyContent="center">
                        <Typo variant="subtitle2" component="span" textTransform="uppercase" style={{ fontSize: subFontSize }}>
                          {GetActorType(actor.subtype)}
                        </Typo>
                        <Typo fontWeight={500} variant="body1" component="span">
                          {actor.name}
                        </Typo>
                        <Bx display="flex" style={{ marginTop: finerSpacing }}>
                          <Typo variant="subtitle2" component="span" textTransform="uppercase" style={{ fontSize: subFontSize }}>
                            {actor.city}
                          </Typo>
                        </Bx>
                      </Bx>
                    </Bx>
                  </Bx>
                )
              }}
            />
          </Bx>
        </Bx>
      )}
    </>
  );
};
