import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

export interface INavBarLogoProps extends LinkProps {
  /**
   * Defines the source destination of the image
   */
  src?: string;
  /**
   * Defines the ALT-Tag for the image
   */
  alt?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex'
  }
}));

/**
 * The `NavBarLogo` component is an extension of the [React-Router-Link](https://reactrouter.com/web/api/Link) and inherits all properties of it.
 */
export const NavBarLogo: React.FunctionComponent<INavBarLogoProps> = ({ to, src, alt, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Link to={to} className={clsx(classes.root, className)} {...rest}>
      <img src={src ? src : 'images/logo.svg'} alt={alt ? alt : 'Logo'} />
    </Link>
  );
};
