import React, { useEffect, useRef } from 'react';
import { Bx, IconBtn, Input, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPaperclip, faPaperPlane, faTimes } from '@fortawesome/pro-light-svg-icons';
import { IMessageModel } from '../../../model/communication/Message';
import { MessageQuote } from '../message/quote';
import { MessageThreadRoot } from '../message/threadRoot';
import { IUploadableFile } from '../../../data/reducer/communication';
import * as _ from 'lodash';
import { AttachmentsView } from '../attachments';
import { allowedMimeTypes } from '../../../helper/validator';
import { BottomBar } from '../../bottom-bar';

export interface ICommnicationInputProps {
  editMode?: boolean;

  placeholder?: string;

  canWriteMessage?: boolean;

  allowAttachments?: boolean;

  disabled?: boolean;
  value?: string;
  onChange?: (content: string) => void;

  onSubmit?: () => void;
  onCancel?: () => void;

  error?: string;

  small?: boolean;

  quotes?: IMessageModel;
  removeQuotes?: () => void;

  displayThreadRoot?: boolean;
  threadRoot?: IMessageModel;
  removeThreadRoot?: () => void;

  attachments?: IUploadableFile[];
  setAttachments?: (attachments?: IUploadableFile[]) => void;
}

export const CommunicationInput: React.FC<ICommnicationInputProps> = ({
  editMode,
  placeholder,
  error,
  canWriteMessage,
  allowAttachments,
  onSubmit,
  onCancel,
  disabled,
  value,
  onChange,
  small,
  quotes,
  removeQuotes,
  threadRoot,
  removeThreadRoot,
  displayThreadRoot,
  attachments,
  setAttachments
}) => {
  const fiRef = React.createRef<HTMLInputElement>();
  const openFileInput = () => {
    fiRef && fiRef.current && fiRef.current.click();
  };
  const attachmentsAdornment = (
    <Bx display="flex" mr={-1}>
      <Bx>
        <IconBtn
          onClick={() => {
            openFileInput();
          }}
        >
          <FontAwesomeIcon icon={faPaperclip} />
        </IconBtn>
      </Bx>
    </Bx>
  );

  function onInputValueChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    onChange && onChange(event.target.value);
  }

  function _handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSubmit?.();
    }
  }

  const inputRef = useRef<HTMLDivElement | null>(null);
  const editModeRef = useRef(editMode);

  useEffect(() => {
    if (editMode) {
      if (inputRef.current) {
        const textarea = inputRef.current.querySelector('textarea');
        if (textarea) {
          textarea.focus();
        }
      }
      inputRef.current?.focus();
    }
    if (editMode !== editModeRef.current) {
      editModeRef.current = editMode;
    }
  }, [inputRef, editModeRef, editMode]);

  const bottomBarProps: any = small ? { bottom: 'unset', rootBoxProps: { px: { xs: 2, md: 3 } }, ctnProps: { maxWidth: 'xl', disableGutters: true } } : {};
  return (
    <BottomBar scrolled {...bottomBarProps}>
      <InvisibleFileInput
        inputRef={fiRef}
        multiple
        accept={allowedMimeTypes.join(',')}
        onFilesChange={files => {
          const uniqueFiles = _.uniqBy([...(attachments || []), ...files], x => x.name);
          setAttachments?.(uniqueFiles);
        }}
      />

      {error && (
        <Bx mb={1}>
          <Typo color="error" variant="subtitle2">
            Es ist ein Fehler aufgetreten
          </Typo>
        </Bx>
      )}

      {quotes && <MessageQuote message={quotes} remove={!editMode ? removeQuotes : undefined} />}
      {displayThreadRoot && threadRoot && <MessageThreadRoot message={threadRoot} remove={!editMode ? removeThreadRoot : undefined} />}

      {canWriteMessage && (
        <Bx display="flex" alignItems="center">
          <Bx width="100%">
            <div ref={inputRef}>
              <Input
                canHaveAttachment
                attachments={attachments && attachments.length && <AttachmentsView files={attachments} setFiles={newAttachments => setAttachments?.(newAttachments)} />}
                input={{
                  disabled,
                  value,
                  onKeyDown: _handleKeyDown,
                  placeholder: placeholder || (editMode ? 'Nachricht bearbeiten' : 'Neue Nachricht verfassen'),
                  maxRows: 5,
                  multiline: true,
                  onChange: onInputValueChange,
                  endAdornment: allowAttachments && !editMode && attachmentsAdornment,
                  autoFocus: true
                }}
              />
            </div>
          </Bx>
          {!editMode && (
            <Bx ml={1}>
              <IconBtn
                disabled={disabled || (!attachments?.length && !value)}
                color="secondary"
                variant="contained"
                onClick={() => {
                  onSubmit?.();
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </IconBtn>
            </Bx>
          )}
          {editMode && (
            <Bx ml={1}>
              <IconBtn
                disabled={disabled}
                color="secondary"
                variant="contained"
                onClick={() => {
                  onSubmit?.();
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </IconBtn>
            </Bx>
          )}
          {editMode && (
            <Bx ml={1}>
              <IconBtn
                disabled={disabled}
                color="error"
                variant="contained"
                onClick={() => {
                  onCancel?.();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            </Bx>
          )}
        </Bx>
      )}
    </BottomBar>
  );
};

export interface IInvisibleFileInput {
  inputRef?: React.Ref<HTMLInputElement>;
  accept?: string;
  multiple?: boolean;
  onFilesChange?: (files: File[]) => void;
}

export const InvisibleFileInput: React.FC<IInvisibleFileInput> = ({ inputRef, multiple, accept, onFilesChange }) => {
  return (
    <input
      type="file"
      hidden
      ref={inputRef}
      multiple={multiple}
      accept={accept}
      onChange={e => {
        if (e.target.files) {
          onFilesChange?.(Array.from(e.target.files));
        } else {
          onFilesChange?.([]);
        }
        e.target.value = '';
      }}
    />
  );
};
