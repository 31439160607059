import React, { MouseEventHandler } from 'react';
import { Typo, ListWrapper, ExtendedListItem, Ava, IconAva, Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faSlash, faVideo } from '@fortawesome/pro-light-svg-icons';
import { ListAvatarColors, assetUrl, hashCode } from '../../../../../helper';
import { IExtendedResolvedParticipant } from '../../../interfaces';
import { ConferenceRaisedHand } from '../../../layout/raised-hand';
import { buildAbbreviation } from '../../../../../helper/user';

export interface IConferencingSidebarParticipantsProps {
  participants?: IExtendedResolvedParticipant[];
  onCloseClick?: MouseEventHandler;
  onInfoClick?: MouseEventHandler;
}

export const ConferencingSidebarParticipants: React.FC<IConferencingSidebarParticipantsProps> = ({ participants, onCloseClick, onInfoClick }) => {
  return (
    <Bx p={4} width="100%">
      <ListWrapper divider="none">
        {participants?.map((participant, idx) => {
          const abbreviation = buildAbbreviation(participant.userResolved?.content?.fullName);
          const hash = hashCode(abbreviation ?? '');
          const colorIndex = hash % 4;
          const colorObject = ListAvatarColors[colorIndex];
          return (
            <ExtendedListItem
              key={participant.userResolved?._id || idx.toString()}
              avatar={
                participant.userResolved?.content?.avatar ? (
                  <Ava src={assetUrl(participant.userResolved?.content?.avatar, true)}
                    alt={`Profilbild von ${participant.userResolved?.content?.fullName}`}
                    children={abbreviation}
                  />
                ) : (
                  <Ava src={undefined}
                    style={{ color: colorObject.color, fontWeight: 500, backgroundColor: colorObject.bg }}
                    children={abbreviation}
                  />
                )
              }
            >
              <Bx display="flex" alignItems="center" height="100%">
                <Typo>{participant.userResolved?.content?.fullName}</Typo>
                <Bx ml="auto" display="flex" height="100%">
                  <Bx display="flex" height="100%" alignItems="center">
                    <Bx ml={1} style={participant.conferencingStatus?.hand ? {} : { visibility: 'hidden' }}>
                      <ConferenceRaisedHand active={participant.conferencingStatus?.hand} small />
                    </Bx>
                    <Bx ml={1}>
                      <IconAva variant="circular" color="primary" size="small">
                        <span className="fa-layers">
                          {!participant.conferencingStatus?.audio && <FontAwesomeIcon fixedWidth style={{ marginLeft: -3 }} icon={faSlash} />}
                          <FontAwesomeIcon icon={faMicrophone} />
                        </span>
                      </IconAva>
                    </Bx>
                    <Bx ml={1}>
                      <IconAva variant="circular" color="primary" size="small">
                        <span className="fa-layers">
                          {!participant.conferencingStatus?.video && <FontAwesomeIcon fixedWidth style={{ marginLeft: -3 }} icon={faSlash} />}
                          <FontAwesomeIcon icon={faVideo} />
                        </span>
                      </IconAva>
                    </Bx>
                  </Bx>
                </Bx>
              </Bx>
            </ExtendedListItem>
          );
        }
          
        )}
      </ListWrapper>
    </Bx>
  );
};
