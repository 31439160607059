import { Btn, Bx, CheckboxControl, DropDownMenu, Typo } from '@curry-group/mui-curcuma';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export interface IUnconnectedCategoryFilter {
  componentKey?: string;
  marginLeft?: number;
  smallVariant?: boolean;
  anchorEl?: HTMLElement | null;
  open?: string;
  filterItem?: any;
  filterCategoryItem?: {
    name?: string;
    categories?: {
      _id?: string;
      filterCategories?: {
        _id: string;
        name: string;
        parentCategory: string;
        order: number;
      }[];
    };
  };
  aggregations: {
    categories?: any;
    types?: any;
  };
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: () => void;
  onCategoryChanged?: (categoryId: string, parentCategoryId: string) => void;
}

export const UnconnectedCategoryFilter: React.FC<IUnconnectedCategoryFilter> = ({
  anchorEl,
  componentKey,
  marginLeft,
  open,
  smallVariant,
  filterItem,
  filterCategoryItem,
  aggregations,
  onClick,
  onClose,
  onCategoryChanged
}) => {
  const parentCategoryIdSafe = filterCategoryItem?.categories?._id ?? '';
  const categoryAggregations = aggregations?.categories;
  let parentCount = 0;
  if (parentCategoryIdSafe.indexOf('||') > -1) {
    const parentCats = parentCategoryIdSafe.split('||');
    for (const cat of parentCats) {
      parentCount += categoryAggregations[cat] ? categoryAggregations[cat] : 0;
    }
  } else {
    parentCount = categoryAggregations[parentCategoryIdSafe] ? categoryAggregations[parentCategoryIdSafe] : 0;
  }
  const cats = filterItem?.content?.queryCategories?.[parentCategoryIdSafe];
  const active = cats ? Object.keys(cats).some(cat => cats[cat] === 1) : false;
  return (
    <Bx key={componentKey} style={{ marginLeft: marginLeft }}>
      <Btn
        title={`${filterCategoryItem?.name} (${parentCount})`}
        size={smallVariant ? 'medium' : 'large'}
        color={open === componentKey || active ? 'primary' : 'default'}
        variant={open === componentKey || active ? 'contained' : 'outlined'}
        endIcon={<FontAwesomeIcon icon={open === componentKey ? faChevronUp : faChevronDown} />}
        onClick={onClick}
        aria-controls={componentKey}
        aria-haspopup="true"
        style={{ borderBottomLeftRadius: open === componentKey ? 0 : undefined, borderBottomRightRadius: open === componentKey ? 0 : undefined }}
      >
        <Typo variant="inherit" noWrap>
          {`${filterCategoryItem?.name} (${parentCount})`}
        </Typo>
      </Btn>
      <DropDownMenu
        id={`filter_${componentKey}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={onClose}
        MenuListProps={{ component: 'div' }}
        open={open === componentKey}
        keepMounted
      >
        <Bx
          bgcolor="primary.main"
          color="primary.contrastText"
          p={3}
          width={540}
          maxWidth="100%"
          borderRadius="borderRadius"
          style={{ outline: 'none', borderTopLeftRadius: 0 }}
        >
          {open === componentKey && (
            <Grid container spacing={1}>
              {(filterCategoryItem?.categories?.filterCategories || []).map((filterCategory: any, index: number) => {
                const disabled = categoryAggregations[filterCategory._id] ? false : true;
                return (
                  <Grid key={`filter_${componentKey}_${index}`} item xs={12} md={6}>
                    <CheckboxControl
                      label={filterCategory.name + ' (' + (disabled ? '0' : categoryAggregations[filterCategory._id]) + ')'}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        onCategoryChanged?.(filterCategory._id, parentCategoryIdSafe);
                      }}
                      checked={cats?.[filterCategory._id] === 1}
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

const CategoryFilter: React.FC<{ props: any; queryCategories: any }> = ({ props, queryCategories }) => {
  const parentCat = props.filter?.categories?._id;
  const aggregations = props.aggregations?.categories;
  let parentCount = 0;
  if (parentCat.indexOf('||') > -1) {
    const parentCats = parentCat.split('||');
    for (const cat of parentCats) {
      parentCount += aggregations?.[cat] ? aggregations[cat] : 0;
    }
  } else {
    parentCount = aggregations?.[parentCat] ? aggregations[parentCat] : 0;
  }
  const cats = queryCategories?.[parentCat];
  const active = cats ? Object.keys(cats).some(cat => cats[cat] === 1) : false;
  return (
    <Bx key={props.componentKey} style={{ marginLeft: props.marginLeft }}>
      <Btn
        title={`${props.filter.name} (${parentCount})`}
        size={props.smallVariant ? 'medium' : 'large'}
        color={props.open === props.componentKey || active ? 'primary' : 'default'}
        variant={props.open === props.componentKey || active ? 'contained' : 'outlined'}
        endIcon={<FontAwesomeIcon icon={props.open === props.componentKey ? faChevronUp : faChevronDown} />}
        onClick={e => {
          props.filterFunctions?.click?.(e);
        }}
        aria-controls={props.componentKey}
        aria-haspopup="true"
        style={{ borderBottomLeftRadius: props.open === props.componentKey ? 0 : undefined, borderBottomRightRadius: props.open === props.componentKey ? 0 : undefined }}
      >
        <Typo variant="inherit" noWrap>
          {`${props.filter.name} (${parentCount})`}
        </Typo>
      </Btn>
      <DropDownMenu
        id={`filter_${props.componentKey}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={props.anchor}
        onClose={e => props.filterFunctions?.open?.('')}
        MenuListProps={{ component: 'div' }}
        open={props.open === props.componentKey}
        keepMounted
      >
        <Bx
          bgcolor="primary.main"
          color="primary.contrastText"
          p={3}
          width={540}
          maxWidth="100%"
          borderRadius="borderRadius"
          style={{ outline: 'none', borderTopLeftRadius: 0 }}
        >
          {props.open === props.componentKey && (
            <Grid container spacing={1}>
              {(props.filter?.categories?.filterCategories || []).map((filterCategory: any, index: number) => {
                const disabled = aggregations[filterCategory._id] ? false : true;
                return (
                  <Grid key={`filter_${props.componentKey}_${index}`} item xs={12} md={6}>
                    <CheckboxControl
                      label={filterCategory.name + ' (' + (disabled ? '0' : aggregations[filterCategory._id]) + ')'}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        props.filterFunctions.onCategoryChange?.(filterCategory, false, parentCat);
                      }}
                      checked={cats?.[filterCategory._id] === 1}
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

export const ConnectedCategoryFilter = (props: any) => {
  const queryCategories = useSelector(state => state.filter?.searchObject?.queryCategories);
  return <CategoryFilter props={props} queryCategories={queryCategories} />;
};
