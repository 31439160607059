import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useAcademicTitlesState } from '../../../data/sagas/lists';
import { PROFILE_TYPES } from '../../../helper';
import { validateEmail, validateLinkedIn, validateNotEmpty, validatePhone, validateXing } from '../../../helper/validator';
import { FormInput } from '../controls/input';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

interface IFormUser {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (formField: string, formValue: any, formName?: string) => void;
}

export const FormUserData: React.FC<IFormUser> = ({ formData, headline, subtitle, subtitleComponent, onChange }) => {
  const academicTitles = useAcademicTitlesState();

  const [selectedTitle, setSelectedTitle] = useState<{ _id: string, title: string}>({ _id: '', title: '' });
  const [titleQuery, setTitleQuery] = useState('');
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={1}><Typo component='span' variant='body2' fontWeight={500}>Akademischer Titel</Typo></Bx>
                <Autocomplete
                  value={selectedTitle}
                  options={academicTitles}
                  noOptionsText={!!academicTitles && academicTitles.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
                  autoComplete={false}
                  inputValue={titleQuery}
                  includeInputInList={false}
                  placeholder="Titel eingeben"
                  renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
                  onChange={(e, v: any) => {
                    setSelectedTitle(v);
                    onChange('title', v?.title);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setTitleQuery(newInputValue);
                  }}
                  getOptionLabel={o => o.title}
                  getOptionSelected={o => o._id === selectedTitle?._id}
                  renderOption={o => {
                    return (
                      <Bx display="flex" alignItems="stretch">
                        <Bx mr={2}>
                          <Typo variant="subtitle1" component="span">
                            {o.title}
                          </Typo>
                        </Bx>
                      </Bx>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={formData?.itemData?.firstName}
                label={'Vorname'}
                placeholder={'Vorname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={val => {
                  onChange('firstName', val);
                }}
                validator={validateNotEmpty}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={formData?.itemData?.lastName}
                label={'Nachname'}
                placeholder={'Nachname eingeben'}
                minLength={2}
                maxLength={50}
                onChange={val => {
                  onChange('lastName', val);
                }}
                validator={validateNotEmpty}
                successMessage=""
                fieldGroup={'userData'}
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.itemData?.email}
                    disabled={true}
                    label={'Berufliche E-Mail-Adresse (= Benutzername)'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={2}
                    maxLength={0}
                    onChange={val => {
                      onChange('email', val);
                    }}
                    type={'email'}
                    validator={validateEmail}
                    fieldGroup={'userData'}
                    successMessage=""
                    invalidMessage="Ihre Email muss ein gültiges Format haben!"
                    infotext={
                      <React.Fragment>
                        Wir verwenden Ihre E-Mail-Adresse ausschließlich zur Anmeldung und zur Zusendung von Benachrichtigungen. Anderen Nutzenden auf dem Portal wird sie
                        nicht angezeigt.
                      </React.Fragment>
                    }
                    lazy
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={(formData?.itemData?.communicationChannels || []).find(c => c.type === PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE)?.identifier || ''}
                label={'Telefon'}
                placeholder={'Telefonnummer eingeben'}
                minLength={0}
                maxLength={0}
                onChange={val => {
                  if (!formData?.itemData?.communicationChannels) {
                    onChange('communicationChannels', [{ type: PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE, identifier: val }]);
                  } else {
                    const ofs = formData?.itemData?.communicationChannels?.findIndex(c => c.type === PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE);
                    const swap = Array.from(formData?.itemData?.communicationChannels || []);
                    if (ofs > -1) swap.splice(ofs, 1);
                    swap.push({ type: PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE, identifier: val });
                    onChange('communicationChannels', [...swap]);
                  }
                }}
                validator={validatePhone}
                successMessage=""
                invalidMessage="Bitte geben Sie ein gültige Telefonnummer an"
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={(formData?.itemData?.communicationChannels || []).find(c => c.type === PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE)?.identifier || ''}
                label={'Mobil'}
                placeholder={'Mobilnummer eingeben'}
                minLength={0}
                maxLength={0}
                onChange={val => {
                  if (!formData?.itemData?.communicationChannels) {
                    onChange('communicationChannels', [{ type: PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE, identifier: val }]);
                  } else {
                    const ofs = formData?.itemData?.communicationChannels?.findIndex(c => c.type === PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE);
                    const swap = Array.from(formData?.itemData?.communicationChannels || []);
                    if (ofs > -1) swap.splice(ofs, 1);
                    swap.push({ type: PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE, identifier: val });
                    onChange('communicationChannels', [...swap]);
                  }
                }}
                validator={validatePhone}
                successMessage=""
                invalidMessage="Bitte geben Sie ein gültige Telefonnummer an"
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={formData?.itemData?.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url || ''}
                label={'LinkedIn-Profil'}
                placeholder={'URL eingeben'}
                minLength={0}
                maxLength={200}
                onChange={val => {
                  if (!formData?.itemData?.socialMedia) {
                    onChange('socialMedia', [{ type: PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN, url: val }]);
                  } else {
                    const ofs = formData?.itemData?.socialMedia?.findIndex(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN);
                    const swap = Array.from(formData?.itemData?.socialMedia || []);
                    if (ofs > -1) swap.splice(ofs, 1);
                    swap.push({ type: PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN, url: val });
                    onChange('socialMedia', [...swap]);
                  }
                }}
                validator={validateLinkedIn}
                fieldGroup={'userData'}
                successMessage=""
                invalidMessage="Die URL muss mit https://www.linkedin.com/in/ beginnen!"
                lazy
              />
            </Bx>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Bx mb={2}>
              <FormInput
                value={formData?.itemData?.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url || ''}
                label={'Xing-Profil'}
                placeholder={'URL eingeben'}
                minLength={0}
                maxLength={200}
                onChange={val => {
                  if (!formData?.itemData?.socialMedia) {
                    onChange('socialMedia', [{ type: PROFILE_TYPES.SOCIAL_MEDIA.XING, url: val }]);
                  } else {
                    const ofs = formData?.itemData?.socialMedia?.findIndex(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING);
                    const swap = Array.from(formData?.itemData?.socialMedia || []);
                    if (ofs > -1) swap.splice(ofs, 1);
                    swap.push({ type: PROFILE_TYPES.SOCIAL_MEDIA.XING, url: val });
                    onChange('socialMedia', [...swap]);
                  }
                }}
                validator={validateXing}
                fieldGroup={'userData'}
                successMessage=""
                invalidMessage="Die URL muss mit https://www.xing.com/profile/ beginnen!"
                lazy
              />
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
