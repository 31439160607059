import { Bx, IconBtn, Input, InputEndAdornment, InputInfoText, InputValidationIcon, Typo } from '@curry-group/mui-curcuma';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import { passwordValid } from '../../../helper';

interface IFormIdea {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormResetPasswordData: React.FC<IFormIdea> = ({ formData, headline, subtitle, onChange }) => {
  const inputRef1 = React.useRef<any>(null);
  const inputRef2 = React.useRef<any>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [inputVal, setInputVal] = React.useState({
    password1: formData?.password,
    password2: formData?.passwordRepetition
  });

  const handleTogglePassword = () => {
    if (inputRef1.current.type === 'password' && inputRef2.current.type === 'password') {
      inputRef1.current.type = 'text';
      inputRef2.current.type = 'text';
      setShowPassword(true);
    } else {
      inputRef1.current.type = 'password';
      inputRef2.current.type = 'password';
      setShowPassword(false);
    }
  };
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort' }}
              input={{
                value: inputVal.password1,
                placeholder: 'Passwort eingeben',
                type: 'password',
                inputRef: inputRef1,
                onChange: e => {
                  setInputVal({ ...inputVal, [e.target.name]: e.target.value });
                  onChange({ ...formData, password: e.target.value, passwordRepetition: inputVal.password2 });
                },
                name: 'password1',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={handleTogglePassword}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && passwordValid(inputVal.password1) && inputVal.password1 === inputVal.password2 && (
                      <InputValidationIcon type="success" />
                    )}
                  </InputEndAdornment>
                )
              }}
            />
            {inputVal.password1?.length > 0 && !passwordValid(inputVal.password1) && (
              <Bx mt={1}>
                <InputInfoText type="error">
                  <Typo component='span' variant='body3boosted'>
                    Das Passwort muss Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten und mindestens 8 Zeichen lang sein.
                  </Typo>
                </InputInfoText>
              </Bx>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort wiederholen' }}
              input={{
                value: inputVal.password2,
                placeholder: 'Passwort eingeben',
                type: 'password',
                inputRef: inputRef2,
                onChange: e => {
                  setInputVal({ ...inputVal, [e.target.name]: e.target.value });
                  onChange({ ...formData, password: inputVal.password1, passwordRepetition: e.target.value });
                },
                name: 'password2',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={handleTogglePassword}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && passwordValid(inputVal.password1) && inputVal.password1 === inputVal.password2 && (
                      <InputValidationIcon type="success" />
                    )}
                  </InputEndAdornment>
                )
              }}
            />
            {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && inputVal.password1 !== inputVal.password2 && (
              <Bx mt={1}>
                <InputInfoText type="error">
                  <Typo component='span' variant='body3boosted'>Die Passwörter simmen nicht überein!</Typo>
                </InputInfoText>
              </Bx>
            )}
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
