import { takeLatest, put, select } from 'redux-saga/effects';
import { callGet } from '@curry-group/data-addons';
import { api } from '../../api';
import {
  fetchGeneralCategoriesRequestAction,
  fetchGeneralCategoriesSuccessAction,
  fetchGeneralCategoriesErrorAction
} from '../../actions/categories';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useCategories() {
  const dispatch = useDispatch();  
  useEffect(() => {
    dispatch(fetchGeneralCategoriesRequestAction({}));
  }, [dispatch]);
}

export function useCategoriesState() {
  return useSelector(state => state.categories);
}

export function* categories() {
  yield takeLatest(fetchGeneralCategoriesRequestAction.type, fetchCategoriesWorker);
}

function* fetchCategoriesWorker(action: ReturnType<typeof fetchGeneralCategoriesRequestAction>) {
  try {
    const categories = yield select(state => state.categories.items);
    if (!categories || categories.length === 0) {
      const items = yield callGet(api.categories);
      yield put(fetchGeneralCategoriesSuccessAction({ items }));
    } else {
      yield put(fetchGeneralCategoriesSuccessAction({ items:categories }));
    }
  } catch (e) {
    yield put(fetchGeneralCategoriesErrorAction({ message: e.message }));
  }
}
