import { Typo, Link, Btn, Bx } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getProfileRouteByUserId } from '../../../../helper';
import { IMessageModel } from '../../../../model/communication/Message';

export interface IMessageThreadRootProps {
  message: IMessageModel;
  remove?: () => void;
}

export const MessageThreadRoot: React.FC<IMessageThreadRootProps> = ({ message, remove }) => {
  return (
    <Bx
      bgcolor="accent.light"
      borderRadius="borderRadius"
      p={1}
      border={3}
      borderTop={0}
      borderBottom={0}
      borderRight={0}
      borderColor="accent.main"
      color="text.primary"
      my={1}
    >
      <Bx display="flex">
        <Bx mr={0.5} display="flex" justifyContent="flex-end">
          <Typo variant="body2" component="span">
            Antwort an
          </Typo>
        </Bx>
        <Link underline="hover" to={getProfileRouteByUserId(message.createdBy)}>
          <Typo fontWeight={500} variant="body2" component="span">
            {message.createdByResolved?.content?.fullName || 'Ehemaliger Benutzer'}
          </Typo>
        </Link>
        <Bx ml="auto" display="flex" justifyContent="flex-end">
          <Btn onClick={() => remove?.()} noXPadding color="primary" size="small" startIcon={<FontAwesomeIcon icon={faTimes} />}>
            Entfernen
          </Btn>
        </Bx>
      </Bx>
      <Typo>{message.content}</Typo>
    </Bx>
  );
};
