import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FormWorkgroup } from '../../form/compositions/FormWorkgroup';
import { FramedContent } from '../../framed-content';

const WorkgroupForm = (props: any) => {
  if (!props.flowData || !props.flowData?.itemData?.title || !props.flowData?.itemData?.description || props.flowData?.itemData?.description === '<p></p>') {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormWorkgroup
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitleComponent={
          <Bx>
            <Typo variant="subtitle1">
              Folgende Frage können dabei hilfreich sein: Welches Ziel verfolgt sie? Was sind die Schwerpunkte? An wen richtet sich Ihre Arbeitsgruppe?
            </Typo>
            <Typo variant="subtitle1">
              <strong>Hinweis:</strong> Das Portal dient der Kommunikation zu Fachthemen. Gelistete Gruppen müssen daher einen fachlichen Titel haben. Titel mit Bezug zu
              einzelnen Organisationen sind für gelistete Gruppen nicht zulässig.
            </Typo>
          </Bx>
        }
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default WorkgroupForm;
