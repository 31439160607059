import { Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';
import { HeadlineContainer } from '../headline-container';

interface IDashboardFooterProps {
  title: string;
  btnCaption?: string;
  btnClick?: () => void;
}

export const DashboardFooter: React.FC<IDashboardFooterProps> = ({ title, btnCaption, btnClick }) => {
  const theme = useTheme();
  const endEndornment = (
    <Btn color="primary" onClick={btnClick} variant="contained" size="large" endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}>
      {btnCaption || 'Thema einreichen'}
    </Btn>
  );
  return (
    <Bx bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText} borderRadius="borderRadius2" py={{ xs: 2 }} px={{ xs: 3, md: 5 }} mt={6}>
      <HeadlineContainer headline={title} headlineVariant="h3" hideUnderline endAdornment={btnClick && endEndornment} />
      <Bx mb={2}>
        <Typo>
          Sie haben Interesse, einen Beitrag für das MTO-Magazin zu verfassen? Dann schlagen Sie uns doch ein Thema vor! Im Fokus sollte dabei immer der Nutzwert für
          Innovatorinnen und Innovatoren stehen. Wir unterstützen Sie gern mit einem fachlichen Review.
        </Typo>
      </Bx>
    </Bx>
  );
};
