import React, { FC } from 'react';
import { Ava, Btn, Bx, IBxProps, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { IParticipationResolvedModel, ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { BaseListItem } from '../../list-items/default';
import { ListAvatarColors, assetUrl, hashCode } from '../../../helper';
import { buildAbbreviation } from '../../../helper/user';
import Badge from '@material-ui/core/Badge';
import { faHourglassClock } from '@fortawesome/pro-solid-svg-icons';

export interface IConentPendingComponentProps {
  onAccept?: () => void;
  onDecline?: () => void;
  onRevoke?: () => void;
  headline?: string;
  content?: string;
  invitedByName?: string;
  invitedByAvatarSrc?: string;
  isRequest?: boolean;
  px?: IBxProps['px'];
  meetingEndedOrCancelled?: boolean;
  userStatus?: string;
}

export const ContentParticipationComponent: FC<IConentPendingComponentProps> = ({
  headline,
  onRevoke,
  isRequest,
  onAccept,
  onDecline,
  content,
  invitedByName,
  invitedByAvatarSrc,
  px,
  meetingEndedOrCancelled,
  userStatus
}) => {
  if (meetingEndedOrCancelled) {
    return (<></>);
  }
  let message = content;
  if (!message) {
    if (isRequest) {
      message = 'Ihre Anfrage zum Beitritt wurde noch nicht bestätigt';
    } else {
      message = '';
    }
  }
  const hash = hashCode(invitedByName?.slice(0,2) ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  const baseAvatar = <Ava size="medium"
      style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}
      src={invitedByAvatarSrc && assetUrl(invitedByAvatarSrc, true)}
    >
      {buildAbbreviation(invitedByName)}
    </Ava>;
  return (
    <Bx px={px}>
      <Bx>
        <Typo variant="h3" component="h3">
          {headline}
        </Typo>
      </Bx>
      {invitedByName && (
        <Bx>
          <BaseListItem
            type=""
            title={invitedByName}
            avatar={!userStatus || userStatus !== 'to_check_signature_employer'
              ? baseAvatar
              : <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  color={'error'}
                  title={'User noch nicht validiert.'}
                  badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
                >
                  {baseAvatar}
                </Badge>}
            noButtons={true}
            itemTemplate={'share'}
            noPadding={true}
          />
        </Bx>
      )}
      <Bx pt={1}>
        <Typo variant="body1" component="p">
          {message}
        </Typo>
      </Bx>
      <Bx display="flex" pt={3}>
        {!isRequest && (
          <>
            <Bx display="flex" justifyContent="center" alignItems="center">
              <Btn onClick={onAccept} color="success" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faCheckCircle} />}>
                Annehmen
              </Btn>
            </Bx>
            <Bx onClick={onDecline} display="flex" ml={1} justifyContent="center" alignItems="center">
              <Btn color="default" size="large" variant="outlined" startIcon={<FontAwesomeIcon icon={faTimes} />}>
                Ablehnen
              </Btn>
            </Bx>
          </>
        )}
        {isRequest && (
          <Bx onClick={onRevoke} display="flex" justifyContent="center" alignItems="center">
            <Btn color="default" size="large" variant="outlined" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Zurückziehen
            </Btn>
          </Bx>
        )}
      </Bx>
    </Bx>
  );
};

export const ContentPendingParticipationComponent: FC<IConentPendingComponentProps & {
  participation?: IParticipationResolvedModel;
  noHeadline?: boolean;
  noUser?: boolean;
}> = ({
  participation,
  onAccept,
  onDecline,
  onRevoke,
  noHeadline,
  noUser,
  px,
  headline,
  meetingEndedOrCancelled
}) => {
  if (participation && participation.status === ParticipationRequestStatus.PENDING) {
    if (participation.type === ParticipationRequestType.INVITE) {
      return (
        <ContentParticipationComponent
          px={px}
          headline={noHeadline ? '' : headline || 'Sie sind eingeladen!'}
          onAccept={onAccept}
          onDecline={onDecline}
          content={participation.message}
          invitedByName={noUser ? '' : participation.createdByResolved?.content?.fullName}
          invitedByAvatarSrc={noUser ? '' : participation.createdByResolved?.content?.avatar}
          userStatus={participation.createdByResolved?.content.generalUserStatus}
          meetingEndedOrCancelled={meetingEndedOrCancelled}
        />
      );
    } else if (participation.type === ParticipationRequestType.REQUEST) {
      return (
        <ContentParticipationComponent
          px={px}
          isRequest
          headline={noHeadline ? '' : 'Teilnahme angefragt'}
          onRevoke={onRevoke}
          content={participation.message}
          meetingEndedOrCancelled={meetingEndedOrCancelled}
        />
      );
    }
  }
  return <></>;
};

export const BorderedContentPendingParticipationComponent: FC<IConentPendingComponentProps & {
  participation?: IParticipationResolvedModel;
  noHeadline?: boolean;
  noUser?: boolean;
  mt?: IBxProps['mt'];
  userStatus?: string;
}> = props => {
  if (
    props.participation &&
    props.participation.status === ParticipationRequestStatus.PENDING &&
    (props.participation.type === ParticipationRequestType.INVITE || props.participation.type === ParticipationRequestType.REQUEST)
  ) {
    return (
      <Bx bgcolor="accent.light" borderRadius="borderRadius" mt={props.mt || 0} py={5} mb={5}>
        <ContentPendingParticipationComponent {...props} px={7.5} />
      </Bx>
    );
  }
  return <></>;
};
