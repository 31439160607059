import { MouseEventHandler, RefObject, useEffect, useRef, useState } from 'react';
import { Anohana, Bx, DropDownMenu, useBreakpoints } from '@curry-group/mui-curcuma';
import { faDesktop, faHandPaper, faMicrophone, faPhoneSlash, faVideo, faBorderAll, faComment, faUserPlus, faCog, faExchange, faUsers, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { ConferencingButton } from './button';
import { TSidebarType } from '../theme';
import { ConferenceIconBtn } from '../layout/icon-btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@material-ui/core';
import { ConferencingSidebar } from '../outlet/sidebar';
import { IExtendedResolvedParticipant } from '../interfaces';

export interface IConferencingControls {
  toggleDesktop?: () => void;
  toggleHand?: () => void;
  toggleAudio?: () => void;
  toggleVideo?: () => void;
  onGridviewChange?: (gridview: boolean) => void;
  toggleSettings?: MouseEventHandler;
  toggleSidebar: (sidebarType: TSidebarType) => void;
  toggleParticipantsOrderDynamic?: MouseEventHandler;
  close?: () => void;
  onDevicesChange?: (audioDeviceId: string, audioOutDeviceId: string, videoDeviceId: string) => void;
  desktop?: boolean;
  hand?: boolean;
  audio?: boolean;
  video?: boolean;
  gridview?: boolean;
  chatVisible?: boolean;
  settingsOpen?: boolean;
  participantsOpen?: boolean;
  participantsOrderDynamic?: boolean;
  sidebarType: TSidebarType;
  currentParticipants?: IExtendedResolvedParticipant[];
}

export const ConferencingControls: React.FC<IConferencingControls> = ({
    audio,
    video,
    hand,
    desktop,
    gridview,
    chatVisible,
    settingsOpen,
    participantsOpen,
    participantsOrderDynamic,
    sidebarType,
    currentParticipants,
    toggleDesktop,
    toggleHand,
    toggleAudio,
    toggleVideo,
    onGridviewChange,
    toggleSidebar,
    toggleSettings,
    toggleParticipantsOrderDynamic,
    close,
    onDevicesChange
}) => {
  const buttonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;
  const [menu, setMenu] = useState(false);
  const [mobileChat, setMobileChat] = useState(false);
  const [mobileParticipants , setMobileParticipants] = useState(false);
  const [mobileSettings , setMobileSettings] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement | HTMLButtonElement>(null);
  const breakpoints = useBreakpoints();
  const [audioUpdate, setAudioUpdate] = useState(false);
  const [videoUpdate, setVideoUpdate] = useState(false);

  useEffect(() => {
    setAudioUpdate(false);
  }, [audio]);
  useEffect(() => {
    setVideoUpdate(false);
  }, [video]);

  return (<>
    <Bx display="flex">
      {breakpoints.smUp && <Bx mr={1}>
        <ConferencingButton
          size={breakpoints.mdUp ? 'large' : undefined}
          active={sidebarType === 'participants'}
          icon={faUsers}
          tooltip={sidebarType === 'participants' ? 'Teilnehmende ausblenden' : 'Teilnehmende'}
          onClick={() => toggleSidebar('participants')}
          noStrikethrough
        />
      </Bx>}
      {breakpoints.smUp && <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} 
          icon={faComment}
          active={sidebarType === 'chat'}
          onClick={() => toggleSidebar('chat')}
          tooltip={sidebarType === 'chat' ? 'Chat ausblenden' : 'Chat einblenden'}
          noStrikethrough
        />
      </Bx>}
      {breakpoints.smUp && <Bx mr={2}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined}
          onClick={toggleHand}
          active={hand}
          tooltip={hand ? 'Hand nicht mehr heben' : 'Hand heben'}
          icon={faHandPaper}
          noStrikethrough
        />
      </Bx>}
      {breakpoints.smUp && <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} 
          icon={faCog}
          active={sidebarType === 'device_settings'}
          onClick={() => toggleSidebar('device_settings')}
          tooltip={'Einstellungen'}
          noStrikethrough
        />
      </Bx>}
      {breakpoints.smUp && <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} active={participantsOrderDynamic} icon={faExchange} tooltip="Dynamische Sprechersortierung" onClick={toggleParticipantsOrderDynamic} />
      </Bx>}
      <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} disabled={audioUpdate} onClick={() => {if(audioUpdate){return;}setAudioUpdate(true);toggleAudio?.();}} active={audio} tooltip={audio ? 'Stummschalten' : 'Stummschaltung aufheben'} icon={faMicrophone} />
      </Bx>
      <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} disabled={videoUpdate} onClick={() => {if(videoUpdate){return;}setVideoUpdate(true);toggleVideo?.();}} active={video} icon={faVideo} tooltip={video ? 'Kamera ausschalten' : 'Kamera einschalten'} />
      </Bx>
      <Bx mr={1} display="none">
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} icon={faUserPlus} tooltip="Teilnehmende hinzufügen" active />
      </Bx>
      <Bx mr={1} display="none">
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined} 
          icon={faBorderAll}
          active={gridview}
          tooltip={gridview ? 'Kachelansicht schließen' : 'Kachelansicht öffnen'}
          onClick={() => {
            onGridviewChange?.(!gridview);
          }}
        />
      </Bx>
      <Bx mr={1}>
        <ConferencingButton size={breakpoints.mdUp ? 'large' : undefined}
          onClick={toggleDesktop}
          active={desktop}
          tooltip={desktop ? 'Bildschirm nicht mehr teilen' : 'Bildschirm teilen'}
          icon={faDesktop}
        />
      </Bx>
      {breakpoints.smDown && <>
        <Bx mr={2}>
          <ConferenceIconBtn
            size={breakpoints.mdUp ? 'large' : undefined}
            tooltip={'Menü öffnen'}
            color="dark"
            aria-controls="mobile-more-menu"
            aria-haspopup="true"
            onClick={() => {
              if (buttonRef.current) {
                setDropdownAnchor(buttonRef.current);
              }
              setMenu(!menu);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </ConferenceIconBtn>
        </Bx>
        <DropDownMenu
          id="mobile-more-menu"
          open={menu}
          anchorReference="anchorEl"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          anchorEl={dropdownAnchor}
          onClose={() => {
            setMenu(false);
          }}
          keepMounted
          style={{ zIndex: 1301 }}
        >
          <Bx bgcolor="background.paper" borderRadius="borderRadius">
            <MenuItem onClick={() => {setMobileParticipants(true);setMenu(false);}}>Teilnehmende</MenuItem>
            <MenuItem onClick={() => {setMobileChat(true);setMenu(false);}}>Chat</MenuItem>
            <MenuItem onClick={() => {toggleHand?.();setMenu(false);}}>{hand ? 'Hand nicht mehr heben' : 'Hand heben'}</MenuItem>
            <MenuItem onClick={() => {setMobileSettings(true);setMenu(false);}}>Einstellungen</MenuItem>
            <MenuItem onClick={(e) => {toggleParticipantsOrderDynamic?.(e);setMenu(false);}}>{participantsOrderDynamic ? 'Statische Sprechersortierung' : 'Dynamische Sprechersortierung'}</MenuItem>
          </Bx>
        </DropDownMenu>
      </>}
      <Bx ml={breakpoints.mdUp ? 4 : (breakpoints.xs ? 1 : 2)}>
        <ConferencingButton size={breakpoints.mdUp ? 'largeSpecial' : undefined} onClick={close} emphasize icon={faPhoneSlash} tooltip="Videokonferenz beenden" />
      </Bx>
      {breakpoints.smDown &&<Anohana fullScreen open={mobileChat} onClose={() => {setMobileChat(false);}} transition="slide-left">
        <ConferencingSidebar
          sidebarType='chat'
          fullscreen
          onCloseClick={() => {
            setMobileChat(false);
          }}
        />
      </Anohana>}
      {breakpoints.smDown &&<Anohana fullScreen open={mobileParticipants} onClose={() => {setMobileParticipants(false);}} transition="slide-left">
        <ConferencingSidebar
          sidebarType='participants'
          currentParticipants={currentParticipants}
          fullscreen
          onCloseClick={() => {
            setMobileParticipants(false);
          }}
        />
      </Anohana>}
      {breakpoints.smDown &&<Anohana fullScreen open={mobileSettings} onClose={() => {setMobileSettings(false);}} transition="slide-left">
        <ConferencingSidebar
          sidebarType='device_settings'
          fullscreen
          onCloseClick={() => {
            setMobileSettings(false);
          }}
          onDevicesChange={onDevicesChange}
        />
      </Anohana>}
    </Bx>
    {breakpoints.smDown && <button style={{width: 0, height: 0, margin: 0, padding: 0, border: 0, position: 'fixed', bottom: -60, right: 0}} ref={buttonRef}></button>}
    </>);
};
