import React from 'react';
import { passwordValid } from '../../../helper';
import { FormUserPassword } from '../../form/compositions/FormUserPassword';
import { FramedContent } from '../../framed-content';

const UserPasswordForm = (props: any) => {
  if (
    !props.flowData ||
    !props.flowData?.itemData?.communityRulesAccepted ||
    !props.flowData?.itemData?.termsAccepted ||
    !passwordValid(props.flowData?.password) ||
    props.flowData?.password !== props.flowData?.passwordRepetition
  ) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent display="flex" alignItems="center" height="100%">
      <FormUserPassword
        formData={props.flowData}
        headline={'Willkommen auf Medtec Online!'}
        subtitle={'Bevor es losgeht vergeben Sie bitte ein sicheres Passwort für Ihren Zugang.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default UserPasswordForm;
