import { callGet } from '@curry-group/data-addons';
import { select, takeLatest } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';
import { GetCookie } from '../../../helper';
import { fetchStartupSuccessAction } from '../../actions/foundation';
import { IDictionary, isSubscriptionType } from '../../../helper';
import { 
  fetchNotificationsRequestAction, 
  fetchNotificationsSuccessAction, 
  fetchNotificationsFailedAction, 
  notificationsUpdatedAction, 
  setNotificationsReadRequestAction, 
  setNotificationsReadFailedAction, 
  setNotificationsReadSuccessAction, 
  setHasBadgeAction, 
  setNotificationParentReadRequestAction, 
  setNotificationParentReadFailedAction,
  setNotificationParentReadSuccessAction,
  fetchNotificationsNextPageRequestAction,
  fetchNotificationsNextPageSuccessAction,
  fetchNotificationsNextPageFailedAction
} from '../../actions/notifications';
import { api } from '../../api';

export function* notifications() {
  yield takeLatest(fetchNotificationsRequestAction, notificationsWorker);
  yield takeLatest(fetchNotificationsNextPageRequestAction, notificationNextPageWorker);
  yield takeLatest(fetchStartupSuccessAction.type, function* () {
    const anon = yield select(state => state.foundation?.anon);
    if (!anon) {
      yield put(fetchNotificationsRequestAction());
    }
  });
  yield takeLatest(notificationsUpdatedAction.type, function* () {
    yield put(fetchNotificationsRequestAction());
  });
  yield takeLatest(setNotificationsReadRequestAction, notificationReadWorker);
  yield takeLatest(setNotificationParentReadRequestAction, notificationParentReadWorker);
  yield takeLatest(fetchNotificationsSuccessAction, notificationBadgeWorker);
}

function* notificationsWorker() {
  try {
    const result = yield callGet(api.query(api.v2.notifications, { skip: 0, limit: 21 } ) );
    yield put(fetchNotificationsSuccessAction(result));
  } catch {
    yield put(fetchNotificationsFailedAction());
  }
}

function* notificationNextPageWorker() {
  try {
    const notifications = yield select(state => state.notifications?.items);

    const result = yield callGet(api.query(api.v2.notifications, { skip: notifications.length, limit: 21 } ) );
    yield put(fetchNotificationsNextPageSuccessAction(result));
  } catch {
    yield put(fetchNotificationsNextPageFailedAction());
  }
}

function* notificationBadgeWorker(action: ReturnType<typeof fetchNotificationsSuccessAction>) {
  let TypeItems: IDictionary<Array<string>> = {};
  const notificationsTrimmed = action.payload.slice(0, 20);

  notificationsTrimmed.forEach(notification => {
    const itemRefType = notification?.content?.type;
    if (itemRefType && notification.content?.itemRef) {
      if (TypeItems[itemRefType] === undefined || TypeItems[itemRefType].length === 0) {
        TypeItems[itemRefType] = notification.content.notificationParentRead ? [] : [notification.content.itemRef];
      } else {
        if (!notification.content.notificationParentRead) {
          TypeItems[itemRefType].push(notification.content.itemRef);
        }
      }
    }
  });
  
  for (const type in TypeItems) {
    if (isSubscriptionType(type)) {
      yield put(setHasBadgeAction({ id: type, count: TypeItems[type].length }))
    }
  }
}

function* notificationReadWorker(action: ReturnType<typeof setNotificationsReadRequestAction>) {
  try {
    //const notificationBadges = yield select(state => state.notifications?.badges?.notifications);
    const notificationItems = yield select(state => state.notifications?.items);
    const unreadNotifications = notificationItems?.filter(n => n.content?.itemRef === action.payload.id && !n.content?.notificationRead)
    const type = notificationItems?.find(n => n.content?.itemRef === action.payload.id)?.content?.type;
      
    if ((unreadNotifications?.length || isSubscriptionType(type)) && notificationItems?.filter(n => n.content.itemRef === action.payload.id)?.length) {
      const xsrfToken = GetCookie('XSRF-TOKEN') || '';
      fetch('/uwao-api/mto/v2/notifications', {
        method: 'POST',
        body: JSON.stringify({
          ids: notificationItems?.filter(n => n.content.itemRef === action.payload.id)?.map(n => n._id),
        }),
        headers: { 'content-type': 'application/json', 'X-XSRF-TOKEN': xsrfToken },
      });
    }
    yield put(setNotificationsReadSuccessAction());
  } catch {
    yield put(setNotificationsReadFailedAction());
  }
}

function* notificationParentReadWorker(action: ReturnType<typeof setNotificationParentReadRequestAction>) {
  try {
    const configs = yield select(state => state.foundation?.appconfig);

    let typeIds: string[] = [];
    const types = configs?.[action.payload.alias]?.types;
    
    for (const type in types) {
      typeIds.push(types?.[type]?.aliasTypeMapping?.typeId);
    }

    if (typeIds && typeIds.length) {
      const xsrfToken = GetCookie('XSRF-TOKEN') || '';
      fetch('/uwao-api/mto/v2/notifications/parent', {
        method: 'POST',
        body: JSON.stringify(typeIds),
        headers: { 'content-type': 'application/json', 'X-XSRF-TOKEN': xsrfToken },
      });
    }
    yield put(setNotificationParentReadSuccessAction())
  } catch {
    yield put(setNotificationParentReadFailedAction());
  }
}
