import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { sumProperties } from '../../../helper';
import { ISearchRequestBody } from '../../../model/search/SearchRequest';
import {
  fetchFilterErrorAction,
  fetchFilterRequestAction,
  fetchFilterSuccessAction,
  showFilterAction,
  hideFilterAction,
  resetSearchObjectAction,
  changeQueryTermAction,
  changeQuerySortAction,
  changeQueryCategoriesAction,
  changeQueryTypeAction,
  changeQueryDestinationAction,
  changeQueryDateRangeFromAction,
  changeQueryDateRangeToAction,
  copySearchParamsToSearchAction,
  changeQueryContainedIdsAction,
  changeQueryCategoryIdsAction,
  changeQueryCategiesExactAction,
  setFrontendFilterAction,
  setSelectedSearchAction,
  changeQueryExcludedIdsAction,
  changeQuerySearchTypeAction,
} from '../../actions/filter';

interface IBotSearch {
  botAnswer?: string;
  botAnswerDict?: any;
  botRequestId?: string;
  botFeedback?: string;
  botFeedbackRating?: number
  botWorking?: boolean;
  botError?: string;
  botToken?: string;
}

interface IFilterState {
  filterAlias: string;
  filterWorking: boolean;
  filterItem?: any;
  filterVisible: boolean;
  filterChanged: boolean;
  filterError?: string;
  frontendFilterActive: boolean;
  searchObject: ISearchRequestBody;
  selectedSearch: string;
  flowSearch: boolean;
  lastUpdatedFilter?: string[];
  noUpdateCategories?: string[];
}

const initialState: IFilterState = {
  filterAlias: '',
  filterWorking: false,
  filterItem: null as any,
  filterVisible: false,
  filterChanged: false,
  filterError: '',
  frontendFilterActive: false,
  searchObject: {
    queryTerm: '',
    queryCategories: {},
    queryDateRange: { gte: undefined as number | undefined, lte: undefined as number | undefined },
    querySort: '',
    querySearchType: 'combined',
    queryTypes: Array<string>(),
    excludedIDs: Array<string>(),
    queryDestinations: Array<string>(),
  },
  selectedSearch: '',
  flowSearch: false,
  lastUpdatedFilter: Array<string>(),
  noUpdateCategories: Array<string>()
};

const filter = createReducer(initialState, builder =>
  builder
    .addCase(fetchFilterRequestAction, (state, action) => {
      return { ...state, filterError: '', filterWorking: true, filterItem: null, filterAlias: action.payload.alias };
    })
    .addCase(fetchFilterErrorAction, (state, action) => {
      return { ...state, filterError: action.payload.message, filterWorking: false, filterItem: null };
    })
    .addCase(fetchFilterSuccessAction, (state, action) => {
      const filterItem = action.payload.filterItem;
      const defSort = filterItem?.content?.defaultSort?.content;
      const showFilterInitial = filterItem?.content?.showFilterInitial;
      const sort = defSort?.type && defSort?.direction ? defSort?.type + '_' + defSort?.direction : '';
      return {
        ...state,
        filterItem: filterItem,
        filterWorking: false,
        filterVisible: !!showFilterInitial || state.filterVisible,
        searchObject: {
          ...state.searchObject,
          querySort: sort,
        },
      };
    })
    .addCase(showFilterAction, (state, action) => {
      return { ...state, filterVisible: true };
    })
    .addCase(hideFilterAction, (state, action) => {
      return { ...state, filterVisible: false };
    })
    .addCase(changeQueryContainedIdsAction, (state, action) => {
      return { ...state, searchObject: { ...state, containedIDs: action.payload.ids } };
    })
    .addCase(changeQueryCategoryIdsAction, (state, action) => {
      return { ...state, searchObject: { ...state, queryCategoryIds: action.payload.categoryIds } };
    })
    .addCase(changeQueryCategiesExactAction, (state, action) => {
      return { ...state, searchObject: { ...state, queryCategoriesExact: action.payload.exact } };
    })
    .addCase(changeQueryCategoriesAction, (state, action) => {
      const categoryObject: any = !state.searchObject.queryCategories ? {} : _.cloneDeep(state.searchObject.queryCategories);
      const parentCat = action.payload.parentCategoryId ? action.payload.parentCategoryId : (action.payload.category.parentCategory as string);
      const catId = action.payload.category._id;
      if (!categoryObject[parentCat]) {
        categoryObject[parentCat] = {};
      }
      if (categoryObject[parentCat][catId]) {
        if (!action.payload.noToggle) {
          categoryObject[parentCat][catId] = 0;
        }
      } else {
        categoryObject[parentCat][catId] = 1;
      }
      const indexUpdatedCat = !state.lastUpdatedFilter ? -1 : state.lastUpdatedFilter?.indexOf(parentCat);
      let newUpdatedFilter = state.lastUpdatedFilter ? [...state.lastUpdatedFilter] : [];
      if (indexUpdatedCat > -1) {
        if (indexUpdatedCat > 0) {
          newUpdatedFilter.splice(indexUpdatedCat, 1);
          newUpdatedFilter.unshift(parentCat);
        }
      } else {
        newUpdatedFilter.unshift(parentCat);
      }
      if (sumProperties(categoryObject[parentCat]) === 0) {
        newUpdatedFilter.shift();
      }
      let noUpdateCategories = [];
      if (newUpdatedFilter.length > 0) {
        const activeParentCat = newUpdatedFilter[0];
        if (state.filterItem) {
          const activeParentCatItem = _.find(state.filterItem?.content?.filterCategories, cat => cat.categories && cat.categories._id === activeParentCat);
          if (activeParentCatItem) {
            noUpdateCategories = activeParentCatItem.categories.filterCategories.map((cat: any) => cat._id);
          }
        }
      }
      return {
        ...state,
        filterChanged: true,
        lastUpdatedFilter: newUpdatedFilter,
        noUpdateCategories: noUpdateCategories,
        searchObject: { ...state.searchObject, queryCategories: categoryObject },
      };
    })
    .addCase(changeQueryTermAction, (state, action) => {
      const indexUpdated = !state.lastUpdatedFilter ? -1 : state.lastUpdatedFilter?.indexOf('queryterm');
      let newUpdatedFilter = state.lastUpdatedFilter ? [...state.lastUpdatedFilter] : [];
      if (indexUpdated > -1) {
        if (indexUpdated > 0) {
          newUpdatedFilter.splice(indexUpdated, 1);
          newUpdatedFilter.unshift('queryterm');
        }
      } else {
        newUpdatedFilter.unshift('queryterm');
      }
      return {
        ...state,
        filterChanged: true,
        lastUpdatedFilter: newUpdatedFilter,
        noUpdateCategories: [],
        searchObject: { ...state.searchObject, queryTerm: action.payload.term },
      };
    })
    .addCase(changeQueryTypeAction, (state, action) => {
      let typeArray = state.searchObject.queryTypes ? [...state.searchObject.queryTypes] : [];
      const typeIdx = typeArray.indexOf(action.payload.thingType);
      if (typeIdx === -1) {
        typeArray.push(action.payload.thingType);
      } else {
        typeArray.splice(typeIdx, 1);
      }
      const indexUpdated = !state.lastUpdatedFilter ? -1 : state.lastUpdatedFilter?.indexOf('types');
      let newUpdatedFilter = state.lastUpdatedFilter ? [...state.lastUpdatedFilter] : [];
      if (indexUpdated > -1) {
        if (indexUpdated > 0) {
          newUpdatedFilter.splice(indexUpdated, 1);
          newUpdatedFilter.unshift('types');
        }
      } else {
        newUpdatedFilter.unshift('types');
      }
      return {
        ...state,
        lastUpdatedFilter: newUpdatedFilter,
        searchObject: { ...state.searchObject, queryTypes: typeArray },
      };
    })
    .addCase(changeQueryDestinationAction, (state, action) => {
      let destinationArray = state.searchObject.queryDestinations ? [...state.searchObject.queryDestinations] : [];
      const destinationIdx = destinationArray.indexOf(action.payload.destinationAlias);
      if (destinationIdx === -1) {
        destinationArray.push(action.payload.destinationAlias);
      } else {
        destinationArray.splice(destinationIdx, 1);
      }
      const indexUpdated = !state.lastUpdatedFilter ? -1 : state.lastUpdatedFilter.indexOf('destinations');
      let newUpdatedFilter = state.lastUpdatedFilter ? [...state.lastUpdatedFilter] : [];
      if (indexUpdated > -1) {
        if (indexUpdated > 0) {
          newUpdatedFilter.splice(indexUpdated, 1);
          newUpdatedFilter.unshift('destinations');
        }
      } else {
        newUpdatedFilter.unshift('destinations');
      }
      return { ...state, lastUpdatedFilter: newUpdatedFilter, searchObject: { ...state.searchObject, queryDestinations: destinationArray } };
    })
    .addCase(changeQuerySortAction, (state, action) => {
      return { ...state, filterChanged: true, searchObject: { ...state.searchObject, querySort: action.payload.sortId } };
    })
    .addCase(changeQuerySearchTypeAction, (state, action) => {
      return { ...state, filterChanged: true, searchObject: { ...state.searchObject, querySearchType: action.payload.searchTypeId } };
    })
    .addCase(changeQueryDateRangeFromAction, (state, action) => {
      const queryDateRangeObject = { ...state.searchObject.queryDateRange };
      if (action.payload.fromDate && action.payload.fromDate.unix) {
        queryDateRangeObject.gte = action.payload.fromDate.unix() * 1000;
      } else {
        queryDateRangeObject.gte = undefined;
      }
      return { ...state, filterChanged: true, noUpdateCategories: [], searchObject: { ...state.searchObject, queryDateRange: queryDateRangeObject } };
    })
    .addCase(changeQueryDateRangeToAction, (state, action) => {
      const queryDateRangeObject = { ...state.searchObject.queryDateRange };
      if (action.payload.toDate && action.payload.toDate.unix) {
        queryDateRangeObject.lte = action.payload.toDate.unix() * 1000;
      } else {
        queryDateRangeObject.lte = undefined;
      }
      return { ...state, filterChanged: true, noUpdateCategories: [], searchObject: { ...state.searchObject, queryDateRange: queryDateRangeObject } };
    })
    .addCase(changeQueryExcludedIdsAction, (state, action) => {
      return { ...state, searchObject: { ...state.searchObject, excludedIDs: action.payload.excludedIDs } };
    })
    .addCase(resetSearchObjectAction, (state, action) => {
      const defSort = state.filterItem?.content?.defaultSort?.content;
      return {
        ...state,
        filterAlias: action.payload.keepFilterAlias ? state.filterAlias : '',
        lastUpdatedFilter: [],
        noUpdateCategories: [],
        searchObject: {
          querySort: defSort?.type && defSort?.direction ? defSort?.type + '_' + defSort?.direction : '',
          queryTerm: action.payload.keepQuery ? state.searchObject?.queryTerm : '',
          queryDateRange: { lte: undefined, gte: undefined },
          queryCategories: {},
          queryTypes: [],
          excludedIDs: action.payload.keepExcludedIds ? state.searchObject?.excludedIDs : [],
        },
        filterVisible: action.payload.keepFilter ? state.filterVisible : false,
        filterChanged: false,
        filterError: '',
        frontendFilterActive: false,
      };
    })
    .addCase(copySearchParamsToSearchAction, (state, action) => {
      return {
        ...state,
        filterItem: {
          ...state.filterItem,
          content: {
            ...state.filterItem.content,
          },
        },
        searchObject: action.payload.searchObject,
        lastUpdatedFilter: action.payload.lastUpdatedFilter,
      };
    })
    .addCase(setFrontendFilterAction, (state, action) => {
      return { ...state, frontendFilterActive: !state.frontendFilterActive };
    })
    .addCase(setSelectedSearchAction, (state, action) => {
      return { ...state, selectedSearch: action.payload.searchAlias, flowSearch: !!action.payload.flowSearch };
    })
);
export default filter;
