import React from 'react';
import { useLocation } from 'react-router';
import { Ava, Bx, ExtendedListItem, Link, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { ListAvatarColors, assetUrl, getProfileRouteByUserId, hashCode } from '../../helper';
import { faHourglassClock } from '@fortawesome/pro-solid-svg-icons';
import Badge from '@material-ui/core/Badge';

export interface IContentAuthorProps {
  name: string;
  abbreviation: string;
  organization?: string;
  avatar?: string;
  id?: string;
  generalUserStatus?: string;
}

export const ContentAuthor: React.FC<IContentAuthorProps> = ({
  name,
  abbreviation,
  organization,
  avatar,
  id,
  generalUserStatus
}) => {
  const userStatusValidation = !!generalUserStatus && generalUserStatus === 'to_check_signature_employer';
  const location = useLocation();
  let baseAvatar = <Ava src={undefined} size="medium" children={<FontAwesomeIcon icon={faUser} />} />;
  if (!!avatar) {
    baseAvatar = <Ava src={assetUrl(avatar, true)} size="medium">{abbreviation}</Ava>;
  } else if (!!abbreviation) {
    const hash = hashCode(abbreviation ?? '');
    const colorIndex = hash % 4;
    const colorObject = ListAvatarColors[colorIndex];
    baseAvatar = <Ava src={undefined} size="medium" style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{abbreviation}</Ava>;
  }
  const caAvatar = userStatusValidation
    ? <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        color={'error'}
        title={'User noch nicht validiert.'}
        badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
      >
        {baseAvatar}
      </Badge>
    : baseAvatar;
  return (
    <ExtendedListItem avatar={caAvatar}>
      <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
        {!!id && <Link to={getProfileRouteByUserId(id || '') + '?ref=' + location.pathname} underline="hover">
          <Typo fontWeight={500} variant="body1" component="span">
            {name}
          </Typo>
        </Link>}
        {!id && <Typo fontWeight={500} variant="body1" component="span">
          {name}
        </Typo>}
        {organization && (
          <Typo variant="body2" component="span">
            {organization}
          </Typo>
        )}
      </Bx>
    </ExtendedListItem>
  );
};
