import { Bx } from '@curry-group/mui-curcuma';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router';
import { AppFrameSidebar } from '../../../components/frame/sidebar';
import { NavBarLogo } from '../../../components/navbar-logo';
import { SplashSideBar } from '../../../components/splash-sidebar';
import { ConnectedFlowComponentFrame } from '../../flow';
import { useFlowOptout } from '../../../data/sagas/flow';

export const OptoutComponent = (data: {
  headline?: string;
  subHeadline?: string | string[];
  page?: React.ReactChildren | React.ReactChild;
  pageCentered?: boolean;
  paddingY?: number;
  paddingX?: number;
  alias: string;
  noSidebar?: boolean;
}) => {
  return (
    <React.Fragment>
      {!data.noSidebar &&<Hidden mdDown>
        <AppFrameSidebar>
          <SplashSideBar src={'/images/welcome.svg'} alt={'Abmelden'}>
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src="/images/logo.svg" alt="Medtec Online" />
              </Bx>
            </Bx>
          </SplashSideBar>
        </AppFrameSidebar>
      </Hidden>}
      <ConnectedFlowComponentFrame alias={data.alias} />
    </React.Fragment>
  );
};

export const ConnectedOptoutFrame = (data: {
  headline?: string;
  subHeadline?: string | string[];
  page?: React.ReactChildren | React.ReactChild;
  pageCentered?: boolean;
  paddingY?: number;
  paddingX?: number;
  noSidebar?: boolean;
}) => {
  const location = useLocation();
  useFlowOptout(location.pathname, location.search);
  return <OptoutComponent alias={'optout'} noSidebar={data.noSidebar || false} />;
};
