import React from 'react';
import { Btn, Bx, DropDownMenu } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

const CategoryTree = (props: any) => (
  <Bx key={props.componentKey} style={{ marginLeft: props.marginLeft }}>
    <Btn
      title={props.filter.name}
      size={props.smallVariant ? 'medium' : 'large'}
      color={props.open === props.componentKey ? 'primary' : 'default'}
      variant={props.open === props.componentKey ? 'contained' : 'outlined'}
      endIcon={<FontAwesomeIcon icon={props.open === props.componentKey ? faChevronUp : faChevronDown} />}
      onClick={e => {
        props.filterFunctions?.click?.(e);
      }}
      aria-controls={props.componentKey}
      aria-haspopup="true"
      style={{ borderBottomLeftRadius: props.open === props.componentKey ? 0 : undefined, borderBottomRightRadius: props.open === props.componentKey ? 0 : undefined }}
    >
      TREE_NOT_IMPLEMENTED! {props.filter.name}
    </Btn>
    <DropDownMenu
      id={`filter_${props.componentKey}`}
      elevation={0}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorEl={props.anchor}
      onClose={e => props.filterFunctions?.open?.('')}
      MenuListProps={{ component: 'div' }}
      open={props.open === props.componentKey}
      keepMounted
    >
      <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={240} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
        {props.open === props.componentKey && <div>TREE_NOT_IMPLEMENTED! {props.filter.name}</div>}
      </Bx>
    </DropDownMenu>
  </Bx>
);

export default CategoryTree;
