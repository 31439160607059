export class DetailVisualization {
  static CONTENT = '00000000-0000-0000-0000-000000000000';
  static CONVERSATION = '00000000-0000-0000-0000-000000000001';
}
export interface IAliasTypeMapping {
  typeId: string;
  votingTypeId: string;
  typeAlias: string;
  detailVisualization: string;
}
