import React from 'react';
import { AppFrameContent, AppFrameHeader } from '../../../../../components/frame';
import { AppFrameFooter } from '../../../../../components/frame/footer';
import { useSelector } from 'react-redux';
import { Provider, useAtom } from 'jotai';
import { communicationIdAtom, filterAliasAtom, meetingIdAtom, myUserProfileAtom, parentTypeAtom, stepAtom } from './atoms';
import { MeetingInviteStep1 } from './step-1';
import { Header } from './header';
import { MeetingInviteStep0 } from './step-0';
import { MeetingInviteFooter } from './footer';
import { FinishLoader } from './loader';

export interface ISelection {
  [key: string]: string;
}

const MeetingInviteImpl: React.FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const [step, setStep] = useAtom(stepAtom);

  const decrementOrClose = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      onClose?.();
    }
  };

  return (
    <>
      <AppFrameHeader>
        <Header onBack={decrementOrClose} />
      </AppFrameHeader>
      <AppFrameContent>
        {step === 0 && <MeetingInviteStep0 />}
        {step === 1 && <MeetingInviteStep1 />}
        {step === 2 && <FinishLoader mode={'framed'} />}
      </AppFrameContent>
      <AppFrameFooter>{step !== 2 && <MeetingInviteFooter onClose={onClose} />}</AppFrameFooter>
    </>
  );
};

export const MeetingInvite: React.FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const parentType = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?.type);
  const communicationId = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?._id);
  const meetingId = useSelector(state => state.detail?.item?._id);
  const myProfile = useSelector(state => state.foundation.profile);
  return (
    <Provider
      initialValues={
        [
          [filterAliasAtom, 'find-experts'],
          [parentTypeAtom, parentType],
          [communicationIdAtom, communicationId],
          [meetingIdAtom, meetingId],
          [myUserProfileAtom, myProfile]
        ] as any
      }
    >
      <MeetingInviteImpl onClose={onClose} />
    </Provider>
  );
};
