import clsx from 'clsx';
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bx, IBxProps } from '@curry-group/mui-curcuma';

export interface IMobileHeadBarProps extends IBxProps {}

export interface IMobileHeadBarThemeExtension {
  mobileHeadBar: {
    backgroundColor?: string;
    boxShadow?: string;
    height?: number;
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: 64,
    backgroundColor: '#fff',
    boxShadow: '1px 0 2px 0 rgba(0,0,0,.15)'
  }
}));

/**
 * The `MobileHeadBar` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 * 
 * **Theme-Extension:**<br />
 * ```
 interface IMobileHeadBarThemeExtension {
  mobileHeadBar: {
    backgroundColor: string;
    boxShadow: string;
    height: number;
  };
}```
 */
export const MobileHeadBar: React.FunctionComponent<IMobileHeadBarProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Bx px={rest.px || 1} component={rest.component || 'header'} zIndex={rest.zIndex || 1} className={clsx(classes.root, className)} {...rest}>
      {children}
    </Bx>
  );
};
