import { IDateRangeFilter } from './DateRangeFilter';
import { IThing, ThingTypes } from '../ryve/Thing';
import { ISearchEntryRelated } from './SearchEntryRelated';
import { IPageElement, PageElementCategoryType, PageElementContentSelection } from '../data/PageElement';
import * as _ from 'lodash';
import { IProfile } from '../profile';
import { IDetailItem } from '../detail';

export interface ISearchRequestBody {
  alias?: string;
  querySort?: string;
  queryTerm?: string;
  querySkip?: number | null;
  querySize?: number | null;
  queryCategoriesExact?: boolean;
  queryCategoryIds?: string[];
  queryCategories?: ISearchRequestObjectCategories;
  lastUpdatedFilter?: string[];
  noUpdateCategories?: string[];
  queryRequiredFields?: string[];
  queryDateRange?: IDateRangeFilter;
  queryRelatedItem?: IThing<ISearchEntryRelated>;
  destinations?: string[];
  queryTypes?: string[];
  queryDestinations?: string[];
  searchTypes?: string[];
  competenceCategories?: string[];
  interestCategories?: string[];
  excludedIDs?: string[];
  containedIDs?: string[];
  queryStringFields?: { [key: string]: string[] };
  excludeChatNotAllowed?: boolean;
  queryAdditionalId?: string;
  fuzzyQueryActive?: boolean;
  fuzzyFuzziness?: string;
  fuzzyMaxExpansions?: number;
  fuzzyPrefixLength?: number;
  fuzzyTranspositions?: boolean;
  searchInMessages?: boolean;
  containedIdsChats?: boolean;
  querySearchType?: 'combined' | 'combined2' | 'ontology' | 'elastic' | 'newsbot' | 'botsearch' | string;
}

export interface ISearchRequestObjectCategories {
  [key: string]: { [key: string]: number };
}
export class SearchRequestObject implements ISearchRequestBody {
  alias?: string;
  querySort?: string;
  queryTerm?: string;
  querySkip?: number | null;
  querySize?: number | null;
  queryCategoriesExact?: boolean;
  queryCategoryIds?: string[];
  queryCategories?: ISearchRequestObjectCategories;
  lastUpdatedFilter?: string[];
  noUpdateCategories?: string[];
  queryRequiredFields?: string[];
  queryDateRange?: IDateRangeFilter;
  queryRelatedItem?: IThing<ISearchEntryRelated>;
  queryTypes?: string[];
  searchTypes?: string[];
  destinations?: string[];
  competenceCategories?: string[];
  interestCategories?: string[];
  excludedIDs?: string[];
  containedIDs?: string[];
  queryStringFields?: { [key: string]: string[] };
  queryAdditionalId?: string;
  queryMaxRotate?: number;
  fuzzyQueryActive?: boolean;
  fuzzyFuzziness?: string;
  fuzzyMaxExpansions?: number;
  fuzzyPrefixLength?: number;
  fuzzyTranspositions?: boolean;
  searchInMessages?: boolean;
  containedIdsChats?: boolean;

  Alias(alias: string) {
    this.alias = alias;
    return this;
  }

  Sort(sort: string) {
    this.querySort = sort;
    return this;
  }

  Term(term: string) {
    this.queryTerm = term;
    return this;
  }

  Skip(skip: number) {
    this.querySkip = skip;
    return this;
  }

  Size(size: number) {
    this.querySize = size;
    return this;
  }

  CategoryIds(categories: string[]) {
    this.queryCategoryIds = categories;
    return this;
  }

  AddExcludedIds(...ids: string[]) {
    if (!this.excludedIDs) this.excludedIDs = [];
    this.excludedIDs.push(...ids);
    return this;
  }

  AddContainedIds(...ids: string[]) {
    if (!this.containedIDs) this.containedIDs = [];
    this.containedIDs.push(...ids);
    return this;
  }

  AddTypes(...types: string[]) {
    if (!this.searchTypes) this.searchTypes = [];
    this.searchTypes.push(...types);
    return this;
  }

  AddLastUpdated(...filter: string[]) {
    if (!this.lastUpdatedFilter) this.lastUpdatedFilter = [];
    this.lastUpdatedFilter.push(...filter);
    return this;
  }

  AddNoUpdateCategories(categories: string[]) {
    if (!this.noUpdateCategories) this.noUpdateCategories = [];
    this.noUpdateCategories.push(...categories);
    return this;
  }

  AddRequiredFields(...fields: string[]) {
    if (!this.queryRequiredFields) this.queryRequiredFields = [];
    this.queryRequiredFields.push(...fields);
    return this;
  }

  AddCompetenceCategories(categories: string[]) {
    if (!this.competenceCategories) this.competenceCategories = [];
    this.competenceCategories.push(...categories);
    return this;
  }

  AddInterestCategories(categories: string[]) {
    if (!this.interestCategories) this.interestCategories = [];
    this.interestCategories.push(...categories);
    return this;
  }

  AddRelatedItem(item: IThing<ISearchEntryRelated>) {
    this.queryRelatedItem = item;
    return this;
  }

  static fromRelatedGroupSearch(item: IDetailItem, allCategories: { [_id: string]: { name: string; parentCategory: string } }, sort?: string) {
    const searchRequestObject = new SearchRequestObject()
      .Size(20)
      .Skip(0)
      .Sort(sort || 'sort_desc')
      .CategoryIds(item.categories.map(c => c.categoryId))
      .AddTypes(ThingTypes.Group, ThingTypes.Project);

    searchRequestObject.queryCategories = {};

    for (let category of item.categories) {
      const parent = allCategories[category.categoryId];
      if (parent) {
        if (!searchRequestObject.queryCategories[parent.parentCategory]) searchRequestObject.queryCategories[parent.parentCategory] = {};
        searchRequestObject.queryCategories[parent.parentCategory][category.categoryId] = 1;
      }
    }

    searchRequestObject.lastUpdatedFilter = [];
    searchRequestObject.noUpdateCategories = [];

    return searchRequestObject;
  }

  static fromPageElement(element: IPageElement, related?: IThing<any>, profile?: IProfile) {
    let querySize = element.pageElementMaxResults || 1000;

    const searchRequestObject = new SearchRequestObject().Size(querySize).Skip(0).Sort(element.pageElementSort__);
    searchRequestObject.lastUpdatedFilter = [];
    searchRequestObject.noUpdateCategories = [];

    if (element.pageElementContentSelection__ === PageElementContentSelection.MANUAL) {
      if (!!element.pageElementUniversalElements?.length) {
        searchRequestObject
          .AddContainedIds(...element.pageElementUniversalElements.map(uE => uE.elementId))
          .AddTypes(..._.uniq(element.pageElementUniversalElements.map(uE => uE.typeId)));
      }
    } else if (element.pageElementContentSelection__ === PageElementContentSelection.SEARCH) {
      searchRequestObject.AddTypes(...(element.pageElementThingDescriptions || []));
      if (element.pageElementCategoryType__ === PageElementCategoryType.MANUAL && element.pageElementCategorySelection?.length) {
        searchRequestObject.CategoryIds(element.pageElementCategorySelection.map(c => c.categoryId));
        searchRequestObject.queryCategoriesExact = element.pageElementCategoryExactMatch;
      }
      if (element.pageElementCategoryType__ === PageElementCategoryType.USER && profile) {
        if (!element.pageElementUserExcludeCompetences) {
          searchRequestObject.AddCompetenceCategories(profile.competenceCategories?.map(c => c.categoryId) || []);
        }
        if (!element.pageElementUserExcludeInterests) {
          searchRequestObject.AddInterestCategories(profile.interestCategories?.map(c => c.categoryId) || []);
        }
        searchRequestObject.queryCategoriesExact = element.pageElementCategoryExactMatch;
      }
    } else if (element.pageElementContentSelection__ === PageElementContentSelection.RELATED && related) {
      searchRequestObject
        .AddRelatedItem({
          categories: related.categories,
          _id: related._id,
          _score: related._score,
          type: related.type,
          content: {
            __RelatedDescription: related.content.content,
            __RelatedTitle: related.content.title,
          },
        })
        .AddTypes(...(element.pageElementThingDescriptions || []))
        .AddExcludedIds(related._id);
    }
    searchRequestObject.queryAdditionalId = element.pageElementQueryAdditionalId;
    searchRequestObject.queryMaxRotate = element.pageElementQueryMaxRotate;
    return searchRequestObject;
  }
}
