import { useAtom } from 'jotai';
import { FormWorkgroupInvitation } from '../../../../../../components/form/compositions/FormWorkgroupInvitation';
import { FramedContent } from '../../../../../../components/framed-content';
import { invitationTextAtom } from '../atoms';

export const MeetingInviteStep1 = () => {
  const [invitation, setInvitation] = useAtom(invitationTextAtom);
  return (
    <FramedContent>
      <FormWorkgroupInvitation
        headline={'Ok, noch ein paar Worte zu Ihrer Einladung?'}
        subtitle={'Hier können Sie für Teilnehmende einen Einladungstext verfassen.'}
        formData={{ itemData: { invitation } }}
        onChange={newState => {
          setInvitation(newState?.itemData?.invitation ?? '');
        }}
      />
    </FramedContent>
  );
};
