import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { CommunicationDetail } from './cooperation';
import { ContentDetail } from './content';
import { DetailVisualization } from '../../model/configuration/AliasTypeMapping';
import { IContainerConfig } from '../../model/configuration';
import { fetchParticipationFailed, requestParticipationRequest } from '../../data/reducer/communication';
import { TYPES } from '../../helper';
import { ConnectedActorDetail } from './actor';
import { fetchDetailRequest, IDetailState } from '../../data/reducer/detail';
import { CircularProgress } from '@material-ui/core';
import { UserProfile } from './user-profile';
import { ParticipationRequestStatus, ParticipationRequestType } from '../../model/communication/Participant';
import { useCategoriesState } from '../../data/sagas/categories';
import { getPageElements, ThingTypes } from '../../model/ryve/Thing';
import { triggerPageElementDataRequests } from '../../data/reducer/data';
import { useHistory, Redirect } from 'react-router-dom';
import { NotFoundComponent } from '../../components/detail/not-found';
import { useIsMobile } from '../../helper/hooks/isMobile';
import { Bx } from '@curry-group/mui-curcuma';
import { RequestBlockedComponent } from '../../components/detail/request-blocked';
export interface DetailLoaderParams {
  type: string;
  alias: string;
  parentType: string;
  parentAlias: string;
}
export interface IDetailLoaderComponentProps {
  config: IContainerConfig;
}

export const DetailLoaderComponent: React.FC<IDetailLoaderComponentProps> = ({ config }) => {
  const route = useRouteMatch<DetailLoaderParams>();
  const { type, alias } = route.params;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDetailRequest({ type: config.alias === 'user' ? 'user' : type, alias }));
  }, [dispatch, type, alias, config.alias]);
  const seo = useSelector(state => state.detail.item?.seo);
  const detailId = useSelector(state => state.detail.item?._id);
  const detailType = useSelector(state => state.detail.item?.type);
  const detailWorking = useSelector(state => state.detail.working);
  const detailItem = useSelector(state => state.detail.item);
  const detailError = useSelector(state => state.detail.error);
  const detailErrorCode = useSelector(state => state.detail.errorCode);
  const anon = useSelector(state => state.foundation.anon);

  const categories = useCategoriesState();
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    if (seo?.alias === alias && detailType && detailId && detailItem) {
      const pageElements = getPageElements(detailType);
      if (pageElements && pageElements.length) {
        dispatch(
          triggerPageElementDataRequests({
            prefix: detailId,
            pageElements: pageElements,
            detail: detailItem
          })
        );
      }
    }
  }, [alias, detailItem, detailId, detailType, seo, dispatch]);
  const isMobile = useIsMobile();

  const participation = useSelector(state => state.communication?.participation);
  const participationWorking = useSelector(state => state.communication?.participationWorking);
  const participationDeclined = useSelector(state => state.communication?.participation?.status === 'declined');

  const history = useHistory();
  useEffect(() => {
    if (participationDeclined) {
      history.push('/coop/chat');
      dispatch(fetchParticipationFailed({ message: '' }));
    }
  }, [participationDeclined, history, dispatch]);

  const typeMapping = config.types?.[type]?.aliasTypeMapping;

  if (anon && !detailWorking && !detailId && detailError) {
    return <Redirect to={'/optin'} />
  }

  if (detailType === TYPES.ACTOR) {
    return <ConnectedActorDetail isScrolled={isScrolled} setIsScrolled={setIsScrolled} />;
  }

  if (typeMapping && typeMapping.detailVisualization === DetailVisualization.CONVERSATION) {
    const working = detailWorking || participationWorking;
    if (working) {
      return (
        <Bx display="flex" justifyContent="center" height="100%" alignItems="center">
          <CircularProgress color="primary" />
        </Bx>
      );
    } else if (!detailId) {
      return <NotFoundComponent />;
    } else if (!participation) {
      return (
        <ContentDetail
          chatButton={{
            type: 'apply'
          }}
          isScrolled={isScrolled}
          setIsScrolled={setIsScrolled}
          isMobile={isMobile}
        />
      );
    } else if (participation) {
      if ((participation.type !== ParticipationRequestType.PUBLIC && participation.status !== ParticipationRequestStatus.PENDING) || detailType === ThingTypes.OneToOne) {
        return <CommunicationDetail canEdit={detailItem?.content?.hasEditPermissions} isScrolled={isScrolled} setIsScrolled={setIsScrolled} isMobile={isMobile} />;
      } else {
        return (
          <ContentDetail
            chatButton={{
              type: 'participate',
              onClick: () => {
                detailId && dispatch(requestParticipationRequest({ id: detailId, message: '' }));
              }
            }}
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
            isMobile={isMobile}
          />
        );
      }
    }
  } else if (config.alias === 'user') {
    if (!detailWorking) {
      return !!detailItem
        ? <UserProfile isScrolled={isScrolled} setIsScrolled={setIsScrolled} />
        : detailErrorCode === 429
          ? <RequestBlockedComponent />
          : <NotFoundComponent />;
    }
  }
  return (
    <ContentDetail
      chatButton={{
        type: 'discussion'
      }}
      categories={categories}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
      isMobile={isMobile}
      canEdit={detailItem?.content?.hasEditPermissions}
    />
  );
};
export interface IDetailComponent extends Partial<IDetailState> {
  isScrolled?: boolean;
  setIsScrolled?: (scrolled: boolean) => void;
  isMobile?: boolean;
  canEdit?: boolean;
}
