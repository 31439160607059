import { Bx, DateTimeControl, Input, InputInfoText, OutlinedInputControl, Typo } from '@curry-group/mui-curcuma';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { useEffect } from 'react';
import { FormValidator } from '../input';
import { Grid } from '@material-ui/core';

type TimeSpan = { dateFrom: number; dateTo: number };

interface IFormDateTime extends IBreakpoints {
  value: TimeSpan;
  onChange: (value: TimeSpan) => void;
  type?: string;
  validator?: boolean | ((value: string) => boolean);
  successMessage?: string;
  invalidMessage?: string;
  multiline?: boolean;
  lazy?: boolean;
  autoTrim?: boolean;
  noLengthWarning?: boolean;
  fieldGroup?: string;
  formName?: string;
  infotext?: React.ReactChildren | React.ReactChild;
  startIcon?: IconProp;
  disabled?: boolean;
  args?: any;
  setValid: (valid: boolean) => void;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

export const FormDateTime: React.FC<IFormDateTime> = ({
  value,
  onChange,
  isLgUp,
  isMdUp,
  isSmUp,
  type,
  successMessage,
  invalidMessage,
  multiline,
  fieldGroup,
  formName,
  infotext,
  startIcon,
  disabled,
  setValid,
  args
}) => {
  const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(value ? new Date(value.dateFrom * 1000) : null);
  const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(value ? new Date(value.dateTo * 1000) : null);
  const [isValid, setValidInternal] = React.useState(true);

  const setValidator = (valid: boolean) => {
    var formValid = FormValidator.isValid('', 'meeting');
    FormValidator.updateState(formName ?? 'date-time', valid, fieldGroup);
    if (formValid !== FormValidator.isValid('', 'meeting')) {
      setValid(FormValidator.isValid('', 'meeting'));
    }
    setValidInternal(valid);
  };

  if (!type) {
    type = 'text';
  }
  if (successMessage === null || successMessage === undefined) {
    successMessage = 'Ausgezeichnet!';
  }

  function updateValidator() {
    setValidator(
      selectedDateStart !== null && selectedDateEnd !== null && selectedDateStart < selectedDateEnd && selectedDateStart.toDateString() === selectedDateEnd.toDateString()
    );
  }

  useEffect(() => {
    updateValidator();
  });

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale="de">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Input
            inputLabel={{
              children: 'Beginn *'
            }}
            inputComponent={
              // <Grid container spacing={1}>
              //   <Grid item xs={12} md={6} className="font-size-body1">
              //     <DatePickerControl
              //       showLeadingZeros
              //       value={selectedDateStart}
              //       onChange={setSelectedDateStart}
              //       clearIcon={
              //         value ? (
              //           <div className="text-variant-body1boosted px-0-25 hover:text-color-primary-main">
              //             <FontAwesomeIcon icon={faTimes} />
              //           </div>
              //         ) : null
              //       }
              //       //   openCalendarOnFocus={false}
              //       calendarIcon={
              //         <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //           <FontAwesomeIcon icon={faCalendar} />
              //         </div>
              //       }
              //     />
              //   </Grid>
              //   <Grid item xs={12} md={6} className="font-size-body1">
              //     <TimePickerControl
              //       value={selectedDateStart}
              //       onChange={setSelectedDateStart}
              //       clearIcon={
              //         value ? (
              //           <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //             <FontAwesomeIcon icon={faTimes} />
              //           </div>
              //         ) : null
              //       }
              //       disableClock
              //       clockIcon={
              //         <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //           <FontAwesomeIcon icon={faClock} />
              //         </div>
              //       }
              //     />
              //   </Grid>
              // </Grid>
               <DateTimeControl
                value={selectedDateStart}
                onChange={val => {
                  setSelectedDateStart(val ? val.toDate() : null);
                  onChange({ dateFrom: val?.unix() || 0, dateTo: selectedDateEnd ? Math.round(selectedDateEnd.getTime() / 1000) : 0 });
                  updateValidator();
                }}
                TextFieldComponent={OutlinedInputControl}
                variant="inline"
                format="DD.MM.YYYY HH:mm"
                minDate={dayjs()}
                {...args}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            inputLabel={{
              children: 'Ende *'
            }}
            inputComponent={
              // <Grid container spacing={1}>
              //   <Grid item xs={12} md={6} className="font-size-body1">
              //     <DatePickerControl
              //       showLeadingZeros
              //       value={selectedDateStart}
              //       onChange={setSelectedDateStart}
              //       clearIcon={
              //         value ? (
              //           <div className="text-variant-body1boosted px-0-25 hover:text-color-primary-main">
              //             <FontAwesomeIcon icon={faTimes} />
              //           </div>
              //         ) : null
              //       }
              //       //   openCalendarOnFocus={false}
              //       calendarIcon={
              //         <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //           <FontAwesomeIcon icon={faCalendar} />
              //         </div>
              //       }
              //     />
              //   </Grid>
              //   <Grid item xs={12} md={6} className="font-size-body1">
              //     <TimePickerControl
              //       value={selectedDateStart}
              //       onChange={setSelectedDateStart}
              //       clearIcon={
              //         value ? (
              //           <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //             <FontAwesomeIcon icon={faTimes} />
              //           </div>
              //         ) : null
              //       }
              //       disableClock
              //       clockIcon={
              //         <div className="text-variant-body1boosted px-0-25 pr-1 hover:text-color-primary-main">
              //           <FontAwesomeIcon icon={faClock} />
              //         </div>
              //       }
              //     />
              //   </Grid>
              // </Grid>
              <DateTimeControl
                value={selectedDateEnd}
                onChange={val => {
                  setSelectedDateEnd(val ? val.toDate() : null);
                  onChange({ dateFrom: selectedDateStart ? Math.round(selectedDateStart.getTime() / 1000) : 0, dateTo: val?.unix() || 0 });
                  updateValidator();
                }}
                TextFieldComponent={OutlinedInputControl}
                variant="inline"
                format="DD.MM.YYYY HH:mm"
                minDate={selectedDateStart || dayjs()}
                {...args}
              />
            }
          />
          {!isValid && !!selectedDateStart && (
            <React.Fragment>
              {selectedDateStart?.toDateString() === selectedDateEnd?.toDateString() && (
                <Bx mt={1}>
                  <InputInfoText type={'error'}>
                    <Typo component='span' variant='body3boosted'>
                      Das Meeting braucht einen Endzeitpunkt und dieser muss nach dem Beginn liegen.
                    </Typo>
                  </InputInfoText>
                </Bx>
              )}
              {selectedDateStart?.toDateString() !== selectedDateEnd?.toDateString() && (
                <Bx mt={1}>
                  <InputInfoText type={'error'}>
                    <Typo component='span' variant='body3boosted'>
                      Beginn und Ende des Meetings müssen am selben Tag liegen.
                    </Typo>
                  </InputInfoText>
                </Bx>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
