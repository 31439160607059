import { Typo, Input, Btn, Bx, InputInfoText, IconBtn, Link } from '@curry-group/mui-curcuma';
import { Grid, Hidden } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeadBar } from '../../../components/head-bar';
import { BottomBar } from '../../../components/bottom-bar';
import { AppFrameContent, AppFrameHeader } from '../../../components/frame';
import { AppFrameFooter } from '../../../components/frame/footer';
import { AppFrameSidebar } from '../../../components/frame/sidebar';
import { FramedContent } from '../../../components/framed-content';
import { NavBarLogo } from '../../../components/navbar-logo';
import { SplashSideBar } from '../../../components/splash-sidebar';
import { userCredLoginRequestAction, userMtoLoginRequestAction } from '../../../data/actions/auth';
import { MobileHeadBar } from '../../../components/mobile-head-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { MTOLogo } from '../../../helper';

export const LoginComponent = (data: {
  headline?: string;
  subHeadline?: string | string[];
  page?: React.ReactChildren | React.ReactChild;
  pageCentered?: boolean;
  paddingY?: number;
  paddingX?: number;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [login, setLogin] = useState({ username: '', password: '' });
  const authError = useSelector(state => state.foundation.authError);
  const isMobile = useIsMobile();
  const logoSrc= MTOLogo.logoWithTextSrc;
  const logoAlt= MTOLogo.logoAlt;
  const partnerLogoSrc = MTOLogo.partnerLogoSrc;
  const partnerLogoMobileSrc = MTOLogo.partnerLogoMobileSrc;
  const partnerLogoAlt = MTOLogo.partnerLogoAlt;

  const queryParams = new URLSearchParams(window.location.search);
  const redirectLinkAfterLogin = queryParams.get('ral') || undefined;
  if (redirectLinkAfterLogin) {
    localStorage.setItem('mto_redirect_after_login', redirectLinkAfterLogin);
  }

  return (
    <React.Fragment>
      <Hidden mdDown>
        <AppFrameSidebar>
          <SplashSideBar src="/images/welcome.svg" alt="Anmeldung">
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src="/images/logo.svg" alt="Medtec Online" />
              </Bx>
            </Bx>
          </SplashSideBar>
        </AppFrameSidebar>
      </Hidden>
      <AppFrameHeader>
        {!isMobile && <HeadBar
          transparent={false}
          backButtonLink={() => {history.push('/')}}
        >
          <Bx ml={'auto'}>
            <Bx mt={0} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Bx mr={2} position="relative" top={-5}>
                <Typo variant="body2" component="span" style={{ fontSize: 12, textTransform: 'uppercase' }}>
                  Eine Initiative vom
                </Typo>
              </Bx>
              <Bx>
                <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ maxHeight: '50px' }}/>
              </Bx>
            </Bx>
          </Bx>
        </HeadBar>}
        {isMobile && <MobileHeadBar style={{ minHeight: 60 }} boxShadow={{ xs: 3, md: 3 }}>
          <Bx display="flex" height="100%" minHeight="inherit" alignItems="center">
            <Grid item xs={1}>
              <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                <IconBtn size="medium"
                  onClick={() => {history.push('/')}}
                >
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                </IconBtn>
              </Bx>
            </Grid>
            <Grid item xs={7}>
              <Bx pl={2} display="flex" alignItems="center" justifyContent="flex-start" height="100%">
                <Link to="/" onClick={e => { window.scrollTo({ top: 0, behavior: 'smooth' }); }} >
                  <img src={logoSrc} alt={logoAlt} style={{ height: 28, display: 'block' }} />
                </Link>
              </Bx>
            </Grid>
            <Grid item xs={4}>
              <Bx display="flex" flexDirection="row" justifyContent="flex-end">
                <Typo variant="body2" component="span" style={{ fontSize: 8, lineHeight: 1, textTransform: 'uppercase' }}>
                  Eine Initiative vom
                </Typo>
                <Bx pl={.5}>
                  <img src={partnerLogoMobileSrc} alt={partnerLogoAlt} style={{ height: 42 }}/>
                </Bx>
              </Bx>
            </Grid>
          </Bx>
        </MobileHeadBar>}
      </AppFrameHeader>
      <AppFrameContent>
        <FramedContent display="flex" alignItems="center" height="100%">
          <Bx mb={1} mt={{ md: 10 }}>
            <Typo variant="h1" component="h1">
              Willkommen zurück!
            </Typo>
          </Bx>
          <Typo variant="subtitle1">Melden Sie sich mit Ihren Zugangsdaten auf Medtec Online an.</Typo>
          <Bx my={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Input
                  inputLabel={{ children: 'Benutzername' }}
                  input={{
                    placeholder: 'Benutzernamen eingeben',
                    type: 'text',
                    value: login.username,
                    onChange: e => {
                      setLogin({ ...login, username: e.target.value });
                    },
                    name: 'username'
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  inputLabel={{ children: 'Passwort' }}
                  input={{
                    placeholder: 'Passwort eingeben',
                    type: 'password',
                    value: login.password,
                    onChange: e => {
                      setLogin({ ...login, password: e.target.value });
                    },
                    onKeyPress: e => {
                      if (e.key === 'Enter') {
                        dispatch(userCredLoginRequestAction({ ...login, persist: true }));
                      }
                    },
                    name: 'password'
                  }}
                />
                {authError && (
                  <Bx mt={1}>
                    <InputInfoText type="error">
                      <Typo component='span' variant='body3boosted'>Benutzer existiert nicht oder Benutzername und Passwort stimmen nicht überein.</Typo>
                    </InputInfoText>
                  </Bx>
                )}
              </Grid>
            </Grid>
          </Bx>
        </FramedContent>
      </AppFrameContent>
      <AppFrameFooter>
        <Bx gridArea="footer" zIndex={2} position="sticky" bottom="0">
          <BottomBar scrolled bottom={'unset'}>
            <Bx display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Hidden only="xs">
                <Bx alignSelf="flex-start" mr={1}>
                  <Btn
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={false}
                    onClick={() => {
                      history.push('/optin');
                    }}
                  >
                    Registrieren
                  </Btn>
                </Bx>
                <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                  <Btn
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={false}
                    onClick={() => {
                      history.push('/lostpassword');
                    }}
                  >
                    Passwort vergessen
                  </Btn>
                </Bx>
                {localStorage.devMode && (
                  <Bx alignSelf="flex-end" textAlign="right">
                    <Btn
                      size="large"
                      variant="outlined"
                      disabled={false}
                      onClick={() => {
                        dispatch(userMtoLoginRequestAction({ persist: true }));
                      }}
                    >
                      Mit medteconline.de anmelden
                    </Btn>
                  </Bx>
                )}
                <Bx ml={1} alignSelf="flex-end" textAlign="right">
                  <Btn
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={!login.username || !login.password}
                    onClick={() => {
                      dispatch(userCredLoginRequestAction({ ...login, persist: true }));
                    }}
                  >
                    Login
                  </Btn>
                </Bx>
              </Hidden>
              <Hidden smUp>
                <Bx width="100%" mb={1}>
                  <Btn
                    fullWidth
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={!login.username || !login.password}
                    onClick={() => {
                      dispatch(userCredLoginRequestAction({ ...login, persist: true }));
                    }}
                  >
                    Login
                  </Btn>
                </Bx>
                <Bx width="100%" mb={1}>
                  <Btn
                    fullWidth
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={false}
                    onClick={() => {
                      history.push('/optin');
                    }}
                  >
                    Registrieren
                  </Btn>
                </Bx>
                <Bx width="100%" mb={1}>
                  <Btn
                    fullWidth
                    size="large"
                    color="success"
                    variant="contained"
                    disabled={false}
                    onClick={() => {
                      history.push('/lostpassword');
                    }}
                  >
                    Passwort vergessen
                  </Btn>
                </Bx>
                {localStorage.devMode && (
                  <Bx width="100%" mb={1}>
                    <Btn
                      fullWidth
                      size="large"
                      variant="outlined"
                      disabled={false}
                      onClick={() => {
                        dispatch(userMtoLoginRequestAction({ persist: true }));
                      }}
                    >
                      Mit medteconline.de anmelden
                    </Btn>
                  </Bx>
                )}
              </Hidden>
            </Bx>
          </BottomBar>
        </Bx>
      </AppFrameFooter>
    </React.Fragment>
  );
};
