import { callPost } from '@curry-group/data-addons';
import { takeLatest } from 'redux-saga/effects';
import { GetCookie } from '../../../helper';

import { proposeThemeAction } from '../../actions/mail';
import { api } from '../../api';

export function* watcher() {
  yield takeLatest(proposeThemeAction.type, proposeThemeActionWorker);
}

export function* proposeThemeActionWorker(action: ReturnType<typeof proposeThemeAction>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPost(api.v2.mail, action.payload, addHeader);
  } catch (e) {
    // no error handling in concept
  }
}
