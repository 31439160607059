import clsx from 'clsx';
import * as React from 'react';

import { faHandPaper, faSlash, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Zoom from '@material-ui/core/Zoom';

import { conferenceTheme } from '../../theme';

export interface IConferenceRaisedHandProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  small?: boolean;
}

export interface IConferenceInfoIconProps extends IConferenceRaisedHandProps {
  slashed?: boolean;
  icon: IconDefinition;
}

export interface IConferenceRaisedHandThemeExtension {
  conferenceRaisedHand: {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    fontSize?: React.CSSProperties['fontSize'];
    backgroundColor?: React.CSSProperties['backgroundColor'];
    color?: React.CSSProperties['color'];
    borderRadius?: React.CSSProperties['borderRadius'];
  };
}

const useStyles = makeStyles(theme => ({
  iconSizeSmallMargined: {
    width: conferenceTheme.conferenceRaisedHand?.small.width,
    height: conferenceTheme.conferenceRaisedHand?.small.height,
    fontSize: conferenceTheme.conferenceRaisedHand?.small.fontSize
  },
  iconSizeSmall: {
    width: conferenceTheme.conferenceRaisedHand?.small.width,
    height: conferenceTheme.conferenceRaisedHand?.small.height,
    fontSize: conferenceTheme.conferenceRaisedHand?.small.fontSize
  },
  iconSizeLarge: {
    width: conferenceTheme.conferenceRaisedHand?.large.width,
    height: conferenceTheme.conferenceRaisedHand?.large.height,
    fontSize: conferenceTheme.conferenceRaisedHand?.large.fontSize
  },
  root: {
    backgroundColor: conferenceTheme.conferenceRaisedHand?.backgroundColor,
    color: conferenceTheme.conferenceRaisedHand?.color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: conferenceTheme.conferenceRaisedHand?.borderRadius,
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut })
  },
  rootRaisedHand: {
    backgroundColor: theme?.palette?.warning?.main,
    color: conferenceTheme.conferenceRaisedHand?.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: conferenceTheme.conferenceRaisedHand?.borderRadius,
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut })
  }
}));

export const ConferenceRaisedHand: React.FunctionComponent<IConferenceRaisedHandProps> = ({ active, small, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Zoom in={active}>
      <div className={clsx(classes.rootRaisedHand, classes.iconSizeSmall, className)} {...rest}>
        <FontAwesomeIcon icon={faHandPaper} />
      </div>
    </Zoom>
  );
};

export const ConferenceInfoButton: React.FunctionComponent<IConferenceInfoIconProps> = ({ active, slashed, icon, small, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Zoom in={active}>
      <div className={clsx(classes.root, small ? classes.iconSizeSmallMargined : classes.iconSizeLarge, className)} {...rest}>
        {slashed && 
          <span className="fa-layers">
            <FontAwesomeIcon fixedWidth style={{ marginLeft: -3 }} icon={faSlash} />
            <FontAwesomeIcon icon={icon} />
          </span>
        }
        {!slashed &&
          <FontAwesomeIcon icon={icon} />
        }
      </div>
    </Zoom>
  );
};
