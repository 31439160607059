export interface INavigationConfig {
  name: string;
  alias: string;
  configs: INavigationContainerConfig[];
}

export interface INavigationContainerConfig {
  name: string;
  alias: string;
  visible: boolean;
  order: number;
}

export function getNavigationPath(section: INavigationConfig, config: INavigationContainerConfig) {
  let path = '/';
  if (section.alias) path += section.alias;
  if (config.alias) {
    if (section.alias) path += '/';
    path += config.alias;
  }
  return path;
}
