import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { allowedMimeTypes } from '../../../helper/validator';
import { FormDropZone } from '../../form/controls/dropzone';
import { FormInput } from '../../form/controls/input';
import { FormRTE } from '../../form/controls/rte';

interface IFormIdea {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormIdea: React.FC<IFormIdea> = ({ formData, headline, subtitle, onChange }) => {
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
            {/* Ok, dann erzählen Sie doch etwas mehr... */}
          </Typo>
        </Bx>
      )}
      {subtitle && (
        <Typo variant="subtitle1">
          {subtitle}
          {/* Bitte achten Sie darauf, keine schutzrechtsrelevanten Details offenzulegen. */}
        </Typo>
      )}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <FormInput
              value={formData?.itemData?.title}
              label={'Titel'}
              placeholder={'Titel eingeben'}
              minLength={5}
              maxLength={120}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, title: val } });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormRTE
              value={formData?.itemData?.content}
              label={'Idee'}
              placeholder={'Bitte beschreiben Sie Ihre Idee...'}
              mandatory={true}
              minLength={100}
              maxLength={2500}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, content: val } });
              }}
              lazy
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormDropZone
              value={formData?.attachments ? formData.attachments : []}
              label={'Anhänge'}
              placeholder={'Hochladen oder Datei(en) hier ablegen'}
              mandatory={false}
              accept={allowedMimeTypes}
              onChange={val => {
                onChange({ ...formData, attachments: val });
              }}
              multiple
            />
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
