import React, { useEffect } from 'react';
import { IContainerConfig } from '../../../model/configuration';
import { useConfiguration } from '../../../data/sagas/foundation';
import { WizardContent } from '@curry-group/react-wizard';
import FlowComponents from './flowcomponents';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  setFlowAnonymousAction,
  setFlowDataAction,
  setFlowFormDataAction,
  setFlowStepAction,
  setFlowTypeAction,
  finishFlowRequestAction,
  resetFlowAction,
  setFlowOptionAction,
} from '../../../data/actions/flow';
import { useFlow } from '../../../data/sagas/flow';
import { useCategoriesState } from '../../../data/sagas/categories';
import { useFilterState } from '../../../data/sagas/filter';
import { useSearchState } from '../../../data/sagas/search';
import { fetchFirstPageSearchEntriesRequestAction, fetchNextPageSearchEntriesRequestAction } from '../../../data/actions/search';
import { resetSearchObjectAction } from '../../../data/actions/filter';
import { DetailLoaderParams } from '../../../app/detail-loader';
import { fetchDetailRequest } from '../../../data/reducer/detail';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { FramedContent } from '../../framed-content';

export const FlowContent: React.FC<{
  config?: IContainerConfig;
  step?: number;
  flowSteps?: any[];
  ignoredSteps: Array<number>;
  flow?: any;
  categories: any;
  filterActive: boolean;
  search: any;
  setFlowData: (flowData: any) => void;
  setFlowFormData: (formField: string, formValue: any, formName?: string) => void;
  setFlowStep: (step: number) => void;
  setFlowType: (type: string) => void;
  setFlowOption: (option: string, remove?: boolean) => void;
  setFlowAnon: (anonymous: boolean) => void;
  setNextStepDisabled: (disabled: boolean) => void;
  finishFlow: () => void;
  resetFlow: () => void;
  fetchFirstPageSearchEntries?: () => void;
  fetchNextPageSearchEntries?: () => void;
  resetSearchObject?: () => void;
  close?: () => void;
}> = ({
  config,
  step,
  flowSteps,
  ignoredSteps,
  flow,
  categories,
  filterActive,
  search,
  setFlowData,
  setFlowFormData,
  setFlowStep,
  setFlowType,
  setFlowOption,
  setFlowAnon,
  setNextStepDisabled,
  finishFlow,
  resetFlow,
  fetchFirstPageSearchEntries,
  fetchNextPageSearchEntries,
  resetSearchObject,
  close
}) => {
  const flowFunctions = {
    setFlowData,
    setFlowFormData,
    setFlowType,
    setFlowAnon,
    setFlowOption,
    resetFlow,
    fetchFirstPageSearchEntries,
    fetchNextPageSearchEntries,
    resetSearchObject,
    close,
  };
  return (
    <WizardContent>
      {(callbacks, options) => {
        return (
          <React.Fragment key={`flow_${config?.alias}_${options?.currentStep}`}>
            {FlowComponents(
              options?.currentStep,
              flowSteps?.[options?.currentStep],
              categories,
              flowFunctions,
              flow?.flowData,
              config,
              flow?.currentType,
              flow?.flowAnonymous,
              flow?.finishWorking,
              flow?.finishUrl,
              setNextStepDisabled,
              (e: any) => {
                if (!!e) {
                  e.preventDefault();
                }
                callbacks?.nextClick();
                setFlowStep(options?.currentStep + 1);
              },
              filterActive,
              search
            )}
          </React.Fragment>
        );
      }}
    </WizardContent>
  );
};

export interface IConnectedFlowContent {
  alias: string;
  flowSteps: any[];
  ignoredSteps: Array<number>;
  setNextStepDisabled: (disabled: boolean) => void;
  close?: () => void;
}

export const ConnectedFlowContent: React.FC<IConnectedFlowContent> = ({ alias, flowSteps, ignoredSteps, close, setNextStepDisabled }) => {
  const config = useConfiguration(alias);
  const history = useHistory();
  const flow = useFlow();
  const filter = useFilterState();
  const search = useSearchState();
  const categories = useCategoriesState();
  const dispatch = useDispatch();
  const dispatcher = {
    setFlowDataAction: (flowData: any) => dispatch(setFlowDataAction?.({ alias, flowData })),
    setFlowFormDataAction: (formField: string, formValue: any, formName?: string) => dispatch(setFlowFormDataAction?.({ formField, formValue, formName })),
    setFlowStepAction: (step: number) => dispatch(setFlowStepAction?.({ step })),
    setFlowTypeAction: (type: string) => dispatch(setFlowTypeAction?.({ type })),
    setFlowOptionAction: (option: string, remove?: boolean) => dispatch(setFlowOptionAction?.({ option, remove })),
    setFlowAnonymousAction: (anonymous: boolean) => dispatch(setFlowAnonymousAction?.({ anonymous })),
    finishFlowRequestAction: () => dispatch(finishFlowRequestAction?.({ history })),
    resetFlowAction: () => dispatch(resetFlowAction?.({})),
    fetchFirstPageSearchEntriesRequestAction: () => dispatch(fetchFirstPageSearchEntriesRequestAction?.({})),
    fetchNextPageSearchEntriesRequestAction: () => dispatch(fetchNextPageSearchEntriesRequestAction?.({})),
    resetSearchObjectAction: () => dispatch(resetSearchObjectAction?.({})),
  };
  const detail = useSelector(state => state.detail?.item);
  const route = useRouteMatch<DetailLoaderParams>();
  const location = useLocation();
  useEffect(() => {
    if (alias === 'share' && !flow.flowData?.itemData?.sharedItem) {
      if (!detail) {
        dispatch(fetchDetailRequest({ type: route.params.type, alias: route.params.alias }));
      } else {
        dispatch(
          setFlowDataAction({
            alias: 'share',
            flowData: {
              ...flow.flowData,
              location: { ...location, pathname: location.pathname.substring(0, location.pathname.lastIndexOf('/')) },
              itemData: {
                ...flow.flowData?.itemData,
                sharedItem: detail
              }
            }
          })
        );
      }
    }
  }, [flow, route, dispatch, alias, detail, location]);
  useEffect(() => {
    return () => {
      dispatch(resetFlowAction?.({}));
    };
  }, [dispatch]);
  if (flow?.userRequestInvalid) {
    if (flow?.userRequestInvalidAlias === 'optout') {
      return (
        <FramedContent>
          <Bx mb={4}>
            <Typo variant="h1" component="h1">
              Der Abmeldelink für Medtec Online ist leider abgelaufen oder ungültig.
            </Typo>
          </Bx>
          <Bx mb={1}>
            <Typo variant="subtitle1">
              Kontaktieren Sie uns unter <a href="mailto:info@medteconline.de">info@medteconline.de</a> sollte dieser Fehler auftreten.
            </Typo>
          </Bx>
        </FramedContent>
      );
    }
    return (
      <FramedContent>
        <Bx mb={4}>
          <Typo variant="h1" component="h1">
            Der Registrierungslink für Medtec Online ist leider abgelaufen oder ungültig.
          </Typo>
        </Bx>
        <Bx mb={1}>
          <Typo variant="subtitle1">
            Fordern Sie einen <a href="/optin">neuen Registrierungslink hier</a> an oder kontaktieren Sie uns hier: <a href="mailto:info@medteconline.de">info@medteconline.de</a>.
          </Typo>
        </Bx>
      </FramedContent>
    );
  }
  return (
    <FlowContent
      config={config}
      step={flow?.currentStep}
      flowSteps={flowSteps}
      ignoredSteps={ignoredSteps}
      flow={flow}
      categories={categories}
      filterActive={filter?.frontendFilterActive}
      search={search}
      setFlowData={dispatcher.setFlowDataAction}
      setFlowFormData={dispatcher.setFlowFormDataAction}
      setFlowStep={dispatcher.setFlowStepAction}
      setFlowType={dispatcher.setFlowTypeAction}
      setFlowAnon={dispatcher.setFlowAnonymousAction}
      setFlowOption={dispatcher.setFlowOptionAction}
      finishFlow={dispatcher.finishFlowRequestAction}
      resetFlow={dispatcher.resetFlowAction}
      setNextStepDisabled={setNextStepDisabled}
      fetchFirstPageSearchEntries={dispatcher.fetchFirstPageSearchEntriesRequestAction}
      fetchNextPageSearchEntries={dispatcher.fetchNextPageSearchEntriesRequestAction}
      resetSearchObject={dispatcher.resetSearchObjectAction}
      close={close}
    />
  );
};
