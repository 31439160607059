import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { validateHRAB, validateHRNumber, validateNotEmpty, validateRegistrar, validateUrl } from '../../../helper/validator';
import { FormInput } from '../controls/input';

interface IFormEmployer {
  formData: any;
  typeConfig: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  employerHasParent?: boolean;
  onChange: (formField: string, formValue: any, formName?: string) => void;
}

export const FormEmployer: React.FC<IFormEmployer> = ({ formData, typeConfig, headline, subtitle, subtitleComponent, employerHasParent, onChange }) => {
  let employerIK = formData?.userSelectedEmployerFirstLevel?._source?.content?.ik;
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        {typeConfig && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Bx mb={2}>
                <FormInput
                  value={formData?.actorRequest?.name}
                  label={'Name des Arbeitgebers'}
                  placeholder={'Name eingeben'}
                  minLength={2}
                  maxLength={200}
                  onChange={val => {
                    onChange('name', val, 'actorRequest');
                    onChange('organisation', val, 'itemData');
                  }}
                  validator={validateNotEmpty}
                  fieldGroup={'employer'}
                  lazy
                />
              </Bx>
            </Grid>
            {typeConfig?.content?.commonFieldNames.indexOf('address') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.address}
                    label={'Straße und Hausnummer'}
                    placeholder={'Straße und Hausnummer eingeben'}
                    minLength={typeConfig?.content?.optionalFieldNames.indexOf('address') === -1 ? 2 : 0}
                    maxLength={300}
                    onChange={val => {
                      onChange('address', val, 'actorRequest');
                    }}
                    validator={validateNotEmpty}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.commonFieldNames.indexOf('zip') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.zip}
                    label={'PLZ'}
                    placeholder={'PLZ'}
                    minLength={typeConfig?.content?.optionalFieldNames.indexOf('zip') === -1 ? 2 : 0}
                    maxLength={10}
                    onChange={val => {
                      onChange('zip', val, 'actorRequest');
                    }}
                    validator={validateNotEmpty}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.commonFieldNames.indexOf('city') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.city}
                    label={'Ort'}
                    placeholder={'Ort eingeben'}
                    minLength={typeConfig?.content?.optionalFieldNames.indexOf('city') === -1 ? 2 : 0}
                    maxLength={100}
                    onChange={val => {
                      onChange('city', val, 'actorRequest');
                    }}
                    validator={validateNotEmpty}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.commonFieldNames.indexOf('url') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.url}
                    label={'Internetaddresse'}
                    placeholder={'URL eingeben'}
                    minLength={typeConfig?.content?.optionalFieldNames.indexOf('url') === -1 ? 12 : 0}
                    maxLength={300}
                    onChange={val => {
                      onChange('url', val, 'actorRequest');
                    }}
                    successMessage=""
                    invalidMessage="Die URL muss valide sein und mit http://, oder https:// beginnen!"
                    validator={validateUrl}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.customFieldNames.indexOf('LANR') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.LANR}
                    label={'Lebenslange Arztnummer (LANR)'}
                    placeholder={'LANR eingeben'}
                    minLength={9}
                    maxLength={9}
                    onChange={val => {
                      onChange('LANR', val, 'actorRequest');
                    }}
                    validator={validateNotEmpty}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {(typeConfig?.content?.customFieldNames.indexOf('ik') > -1 || !!employerIK) && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.ik || employerIK}
                    disabled={!!employerIK}
                    label={'Institutskennzeichen'}
                    placeholder={'IK eingeben'}
                    minLength={2}
                    maxLength={50}
                    onChange={val => {
                      onChange('ik', val, 'actorRequest');
                    }}
                    validator={validateNotEmpty}
                    fieldGroup={'employer'}
                    lazy
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.customFieldNames.indexOf('register_number') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.register_number}
                    label={'Handelsregisternummer'}
                    placeholder={'HRNR eingeben'}
                    minLength={2}
                    maxLength={10}
                    onChange={val => {
                      onChange('register_number', val, 'actorRequest');
                    }}
                    validator={validateHRNumber}
                    fieldGroup={'employer'}
                    invalidMessage="Die HRNR besteht nur aus Zahlen, mindestens eine bis maximal zwölf Stellen!"
                    lazy
                    infotext={
                      <span>
                        z.B. Augsburg HRB <mark>10785</mark>
                      </span>
                    }
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.customFieldNames.indexOf('register_art') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.register_art}
                    label={'Handelsregister Art'}
                    placeholder={'Art eingeben'}
                    minLength={3}
                    maxLength={3}
                    onChange={val => {
                      onChange('register_art', val, 'actorRequest');
                    }}
                    validator={validateHRAB}
                    invalidMessage="Die HR Art entweder HRA oder HRB sein!"
                    fieldGroup={'employer'}
                    lazy
                    infotext={
                      <span>
                        z.B. Augsburg <mark>HRB</mark> 10785
                      </span>
                    }
                  />
                </Bx>
              </Grid>
            )}
            {typeConfig?.content?.customFieldNames.indexOf('registrar') > -1 && (
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.actorRequest?.registrar}
                    label={'Amtsgericht'}
                    placeholder={'Amtsgericht eingeben'}
                    minLength={2}
                    maxLength={100}
                    onChange={val => {
                      onChange('registrar', val, 'actorRequest');
                    }}
                    validator={validateRegistrar}
                    invalidMessage="Das Amtsgericht muss in Deutschland gültig und korrekt geschrieben sein."
                    fieldGroup={'employer'}
                    lazy
                    infotext={
                      <span>
                        z.B. <mark>Augsburg</mark> HRB 10785
                      </span>
                    }
                  />
                </Bx>
              </Grid>
            )}
          </Grid>
        )}
      </Bx>
    </React.Fragment>
  );
};
