import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { validateEmail } from '../../../helper/validator';
import { FormInput } from '../controls/input';

interface IFormUser {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitleComponent?: React.ReactChild;
  onChange: (value: any) => void;
}

export const FormLostPassData: React.FC<IFormUser> = ({ formData, headline, subtitle, subtitleComponent, onChange }) => {
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {!!subtitleComponent && subtitleComponent}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Bx mb={2}>
                  <FormInput
                    value={formData?.itemData?.email}
                    label={'Berufliche E-Mail-Adresse (= Benutzername)'}
                    placeholder={'E-Mail-Adresse eingeben'}
                    minLength={0}
                    maxLength={0}
                    onChange={val => {
                      onChange({ ...formData, itemData: { ...formData?.itemData, email: val } });
                    }}
                    type={'email'}
                    validator={value => {
                      return validateEmail(value);
                    }}
                    fieldGroup={'resetPassData'}
                    successMessage={formData?.itemData?.email !== '' ? 'Wenn ein Account mit dieser E-Mail-Adresse existiert, erhalten Sie per Mail einen Link, um das Passwort zurückzusetzen.' : ''}
                    invalidMessage={'Ihre Email muss ein gültiges Format haben!'}
                    lazy
                  />
                </Bx>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
