import { Ava, Bx, ExtendedListItem, IconBtn, Indicator, Typo } from '@curry-group/mui-curcuma';
import { faBookmark as faBookmarkLight, faLockAlt, faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { faBookmark, faMask } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, ChipTypeMap } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { ReactChild, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { theme } from '@curry-group/mui-curcuma';

const useStyles = makeStyles(theme => ({
  memoButton: {
    padding: 0,
    margin: 0,
    cursor: 'pointer'
  },
  emptylistitem: {
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  },
}));

export interface ISidebarListItemProps {
  title: string;
  timestamp: string;
  badgeContent?: number | string | ReactNode;
  badgeColor?: ChipTypeMap['props']['color'];
  subtitle?: string;
  avatar: ReactChild;
  to?: string;
  isOneToOne?: boolean;
  isPublic?: boolean;
  isHidden?: boolean;
  isMobile?: boolean;
  booked?: boolean;
  memoryClick?: () => void;
  active?: boolean;
}

export const SidebarListItem: React.FC<ISidebarListItemProps> = ({
  to,
  title,
  avatar,
  timestamp,
  badgeContent,
  badgeColor,
  subtitle,
  isOneToOne,
  isPublic,
  isHidden,
  isMobile,
  booked,
  memoryClick,
  active
}) => {
  const classes = useStyles();
  return (
    <ExtendedListItem
      to={to}
      avatar={<Bx ml={isMobile ? 2 : 3}>
        {badgeContent && badgeContent !== 'Einladung' && badgeContent !== 'Anfrage'
          ? <Indicator badgeContent={badgeContent} color={'secondary'} variant={'standard'} overlap="circular">
              {avatar}
            </Indicator>
          : avatar
        }
      </Bx>}
      active={active}
      style={{ background: active ? theme.palette.accent?.light : 'inherit' }}
    >
      {/* Template: Has Subtitle */}
      {!!subtitle && (
        <Bx display="flex" flexDirection="column" height="100%" justifyContent="center" mr={isMobile ? 2 : 3}>
          {/* Meta */}
          <Bx display="flex" mb={0.5}>
            <Bx lineHeight={0.5}>
              {/* {!!badgeContent && badgeContent !== 'Einladung' && badgeContent !== 'Anfrage' && (<>
                <Badge sidebar color="secondary">&nbsp;{badgeContent}&nbsp;</Badge>
                &nbsp;
              </>)} */}
              <Typo color="textPrimary" variant="subtitle3" component="span" textTransform="uppercase">
                {subtitle}
              </Typo>
            </Bx>

            <Bx lineHeight={0.5} ml="auto" display="flex">
              {isPublic && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faLockOpen} />
                </Bx>
              )}
              {!isPublic && !isHidden && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faLockAlt} />
                </Bx>
              )}
              {!isPublic && isHidden && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faMask} />
                </Bx>
              )}
              <Typo color="textPrimary" variant="subtitle3" component="span">
                {timestamp}
              </Typo>
            </Bx>
          </Bx>

          {/* Content */}
          <Bx display="flex">
            <Bx color="text.primary" fontWeight={500} fontSize="body2" lineHeight={1.25}>
              <Typo variant="inherit" component="span">
                {title}
              </Typo>
            </Bx>
            <Bx display="flex" flexDirection="row" alignItems="center" height="100%" ml="auto">
              {(badgeContent === 'Einladung' || badgeContent === 'Anfrage') && (
                <Bx>
                  <Chip color={badgeColor || 'primary'} label={badgeContent} size="small" />
                </Bx>
              )}
              {badgeContent !== 'Einladung' && badgeContent !== 'Anfrage' && !!memoryClick && (
                <IconBtn
                  color="primary"
                  size="small"
                  shape="rounded"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    memoryClick?.();
                  }}
                  className={clsx(classes.memoButton)}
                >
                  {<FontAwesomeIcon icon={booked ? faBookmark : faBookmarkLight} />}
                </IconBtn>
              )}
            </Bx>
          </Bx>
        </Bx>
      )}

      {/* Template Has _no_ Subtitle */}
      {!subtitle && (
        <Bx display="flex" height="100%" alignItems="center" mr={isMobile ? 2 : 3}>
          {/* Content */}
          <Bx display="flex">
            <Bx color="text.primary" fontWeight={500} fontSize="body2" lineHeight={1.25}>
              <Typo variant="inherit" component="span">
                {title}
              </Typo>
            </Bx>
          </Bx>

          {/* Meta */}
          <Bx display="flex" flexDirection="column" ml="auto" pl={1}>
            <Bx lineHeight={0.5} ml="auto" display="flex">
              {isPublic && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faLockOpen} />
                </Bx>
              )}
              {!isPublic && !isHidden && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faLockAlt} />
                </Bx>
              )}
              {!isPublic && isHidden && !isOneToOne && (
                <Bx mr={0.5} color="text.primary" mt={-0.25}>
                  <FontAwesomeIcon icon={faMask} />
                </Bx>
              )}
              <Bx mb={-0.25}>
                <Typo color="textPrimary" variant="subtitle3" component="span" noWrap>
                  {timestamp}
                </Typo>
              </Bx>
            </Bx>
            {!!badgeContent && (
              <Bx display="flex" mt={0.5} flexDirection="column" alignItems="flex-end" height="100%" ml="auto">
                <Bx>
                  <Chip color={badgeColor || 'primary'} label={badgeContent} size="small" />
                </Bx>
              </Bx>
            )}
          </Bx>
        </Bx>
      )}
    </ExtendedListItem>
  );
};

export const SidebarListItemSkeleton = () => {
  return (
    <ExtendedListItem
      avatar={
        <Bx ml={3}>
          <Ava loading variant="circle" size="medium" />
        </Bx>
      }
    >
      <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
        <Typo fontWeight={500} variant="body1" component="span">
          <Skeleton animation="wave" style={{ width: '200px' }} />
        </Typo>
        <Typo variant="body2" component="span">
          <Skeleton animation="wave" style={{ width: '120px' }} />
        </Typo>
      </Bx>
    </ExtendedListItem>
  );
};

export const SidebarListItemEmpty = () => {
  const classes = useStyles();
  return (
    <ExtendedListItem avatar={undefined}>
      <Bx className={clsx(classes.emptylistitem)}>
        <Typo variant="body2" component="span" style={{ fontSize: 12 }}>
          Keine Einträge
        </Typo>
      </Bx>
    </ExtendedListItem>
  );
};
