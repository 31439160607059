import { IconBtn, Bx, Hamburger } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarOpenAction } from '../../data/actions/foundation';
import { MTOLogo } from '../../helper';
import { useIsMobile } from '../../helper/hooks/isMobile';
import { HeadBar } from '../head-bar';
import { MobileHeadBar } from '../mobile-head-bar';
import { INavbarOpenProps } from '../navbar';
import { NavBarLogo } from '../navbar-logo';
import { HeaderHeadbarContainer } from './headbar-container';

export const HeaderComponent: React.FC = ({ children }) => {
  return (
    <HeadBar>
      <HeaderHeadbarContainer>{children}</HeaderHeadbarContainer>
    </HeadBar>
  );
};

export const MobileHeaderComponent: React.FC<INavbarOpenProps> = ({ open, setOpen }) => {
  return (
    <MobileHeadBar>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
        <Grid item xs={3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn onClick={() => setOpen(!open)}>
              <Hamburger width={30} height={30} size={20} toggled={open} label={open ? 'Menü schließen' : 'Menü öffnen'} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={6}>
          <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
            <NavBarLogo to="/" src={MTOLogo.logoDarkSrc} />
          </Bx>
        </Grid>
        <Grid item xs={3}>
          <Bx display="flex" pl={1} alignItems="center" justifyContent="flex-end" height="100%">
            {/* <IconBtn>
              <FontAwesomeIcon icon={faSearch} />
            </IconBtn> */}
          </Bx>
        </Grid>
      </Bx>
    </MobileHeadBar>
  );
};

export const ConnectedHeaderComponent: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navbarOpen = useSelector(state => state.foundation?.navbarOpen);

  if (isMobile) return <MobileHeaderComponent open={!!navbarOpen} setOpen={() => dispatch(setNavbarOpenAction(!navbarOpen))} />;
  else return <HeaderComponent />;
};
