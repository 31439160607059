import { Bx, LinearLoader, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { FramedContent } from '../../framed-content';

const FinishFlow = (props: any) => {
  const [progress, setProgress] = React.useState(0);
  const [leaveReset, setLeaveReset] = React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        const rest = (100 - oldProgress) / 100;
        const diff = rest * Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
      props.flowFunctions?.resetSearchObject();
      props.flowFunctions?.resetFlow();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (props.finishWorking && !leaveReset) {
    setLeaveReset(true);
    setTimeout(
      () => {
        if (props.flowFunctions?.close) {
          props.flowFunctions?.close();
        } else {
          history.push(props.finishUrl);
        }
      },
      props.flowStep?.finishTimeout ? props.flowStep?.finishTimeout : 2000
    );
  }
  return (
    <FramedContent display="flex" alignItems="center" height="100%" innerBoxProps={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src="/images/finish.svg" alt="Fertig! Los geht's..." />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="h1" align="center">
                {props.flowStep?.finishHeadline ? props.flowStep?.finishHeadline : `Fertig! Los geht's...`}
              </Typo>
              {props.flowStep?.finishText && (
                <Typo align="center" variant="subtitle1">
                  {props.flowStep?.finishText}
                </Typo>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
              <Bx mt={2}>
                <LinearLoader variant="determinate" value={progress} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FramedContent>
  );
};

export default FinishFlow;
