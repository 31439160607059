import { callGet } from '@curry-group/data-addons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchFilterErrorAction, fetchFilterRequestAction, fetchFilterSuccessAction, resetSearchObjectAction, setSelectedSearchAction } from '../../actions/filter';
import { clearSearchEntriesAction } from '../../actions/search';
import { api } from '../../api';
import { setBotSearchAction, resetBotRequestAction, resetBotFeedbackAction } from '../../actions/bot';
import { resetNewsReferences } from '../../actions/news';

export function useFilterConfig(alias: string, resetSpecial?: boolean, flowSearch?: boolean, fromStartSearchReferrer?: boolean, botSearchInitial?: boolean) {
  const dispatch = useDispatch();
  let previousAlias = useSelector(state => state?.foundation?.activeConfig?.alias);
  const filterAlias = useSelector(state => state?.filter?.filterAlias);
  // const anon = useSelector(state => state.foundation.anon);
  useEffect(() => {
    if (
      // !anon &&
      alias &&
      alias !== filterAlias &&
      (filterAlias !== 'start' || !fromStartSearchReferrer || !filterAlias) &&
      (alias !== previousAlias || flowSearch || !filterAlias)
    ) {
      dispatch(setBotSearchAction({ botSearchActive: filterAlias === 'landingpage' || botSearchInitial || false }));
      dispatch(resetBotRequestAction());
      dispatch(resetNewsReferences());
      dispatch(resetBotFeedbackAction());
      dispatch(resetSearchObjectAction({ keepExcludedIds: resetSpecial }));
      dispatch(clearSearchEntriesAction({}));
      dispatch(fetchFilterRequestAction({ alias }));
      dispatch(setSelectedSearchAction({ searchAlias: alias, flowSearch }));
    }
  }, [dispatch, /*anon,*/ alias, previousAlias, filterAlias, resetSpecial, flowSearch, fromStartSearchReferrer, botSearchInitial]);
}

export function useFilterState() {
  return useSelector(state => state.filter);
}

export function* watcher() {
  yield takeLatest(fetchFilterRequestAction.type, fetchFilterWorker);
}

function* fetchFilterWorker(action: ReturnType<typeof fetchFilterRequestAction>) {
  try {
    const filterItem = yield callGet(api.search.filter, { alias: action.payload.alias });
    if (action.payload.alias === 'landingpage') {
      yield put(setBotSearchAction({ botSearchActive: true }));
    }
    yield put(fetchFilterSuccessAction({ filterItem }));
  } catch (e: any) {
    yield put(fetchFilterErrorAction(e.message));
  }
}
