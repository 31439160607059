import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { Bx, IBxProps, IconBtn } from '@curry-group/mui-curcuma';

export interface IHeadBarProps extends IBxProps {
  transparent?: boolean;
  backButtonLink?: string | Function;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 2,
    top: 0,
    width: '100%',
    transition: theme.transitions.create(['background-color', 'border'], { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut }),
    borderBottom: `1px solid transparent`,
    minHeight: 81
  },
  filled: {
    backgroundColor: '#fff',
    borderBottom: `1px solid #DDDFE0`
  },
  hasBoxShadow: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)'
  }
}));

/**
 * The `HeadBar` component is an extension of the [Material-UI Box](https://material-ui.com/components/box/) and inherits all properties of it.
 */
export const HeadBar: React.FunctionComponent<IHeadBarProps> = ({ backButtonLink, transparent, children, className, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [innerArrow, setInnerArrow] = React.useState(false);
  const btnPropsForInner = innerArrow ? { flexGrow: 0, flexShrink: 0, flexBasis: theme?.custom?.iconBtn?.large?.width } : {};

  React.useEffect(() => {
    function checkForInnerArrow() {
      if (ref?.current?.clientWidth && ref?.current?.clientWidth < 1350) {
        innerArrow !== true && setInnerArrow(true);
      } else {
        innerArrow !== false && setInnerArrow(false);
      }
    }
    checkForInnerArrow();
    window.addEventListener('resize', checkForInnerArrow);
    return () => {
      window.removeEventListener('resize', checkForInnerArrow);
    };
  });

  return (
    <div ref={ref}>
      <Bx px={{ xs: 2, lg: 5 }} py={1.5} display="flex" className={clsx(classes.root, !transparent && classes.filled, className)} {...rest}>
        <Bx display="flex" width="100%">
          {typeof backButtonLink === 'function' && (
            <Bx zIndex={2} {...btnPropsForInner}>
              <IconBtn mrSelf size="large" onClick={e => backButtonLink(e)}>
                <FontAwesomeIcon icon={faLongArrowLeft} />
              </IconBtn>
            </Bx>
          )}
          {typeof backButtonLink === 'string' && (
            <Bx zIndex={2} {...btnPropsForInner}>
              <Bx display="flex" alignItems="center" height="100%">
                <IconBtn to={backButtonLink} mrSelf size="large">
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                </IconBtn>
              </Bx>
            </Bx>
          )}
          {innerArrow ? (
            <Bx width="100%" height="100%">
              {children}
            </Bx>
          ) : (
            <React.Fragment>{children}</React.Fragment>
          )}
        </Bx>
      </Bx>
    </div>
  );
};
