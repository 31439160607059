import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { ConferencingContext } from '..';
import { ConferenceParticipant, ConferenceParticipantLatency } from '../layout/participant';

export interface IConferencingParticipantProps {
  displayName?: string;
  audio?: boolean;
  video?: boolean;
  muted?: boolean;
  self?: boolean;
  hand?: Boolean;
  media?: MediaStream;
  speaking?: boolean;
  small?: boolean;
}

export const ConferencingParticipantLatency = ({ self = false }) => {
  const context = useContext(ConferencingContext);
  const [stats, setStats] = useState(undefined as any);
  useEffect(() => {
    const listener = (stats, type, subtype) => {
      if (self && type === 'out') {
        setStats(stats);
      } else {
      }
    };
    context.addListener?.(listener);
    return () => {
      context.removeListener?.(listener);
    };
  }, [context, self]);
  return <ConferenceParticipantLatency stats={stats} />;
};

export const ConferencingParticipant: React.FC<IConferencingParticipantProps> = ({
  media,
  displayName,
  hand,
  audio,
  video,
  muted,
  self,
  speaking,
  small
}) => {
  const videoRef = useRef<HTMLVideoElement>() as RefObject<HTMLVideoElement>;
  const audioRef = useRef<HTMLAudioElement>() as RefObject<HTMLAudioElement>;
  useEffect(() => {
    if (media && videoRef.current) {
      if (videoRef.current.srcObject !== media) {
        videoRef.current.srcObject = media;
      }
    }
    if (media && audioRef.current) {
      if (audioRef.current.srcObject !== media) {
        audioRef.current.srcObject = media;
      }
      const audElm: any = audioRef.current;
      if ('setSinkId' in audElm && localStorage.__vidConfDevAudOut && 'sinkId' in audElm && audElm.sinkId !== localStorage.__vidConfDevAudOut && !muted && !self) {
        audElm.setSinkId(localStorage.__vidConfDevAudOut);
      }
    }
  });
  return (
    <>
      <ConferenceParticipant
        raisedHand={!!hand}
        name={displayName}
        audioActive={audio}
        videoActive={video}
        speaking={speaking}
        small={small}
        self={self}
        quality={self && <ConferencingParticipantLatency self={self} />}
        stream={
          <>
            <audio autoPlay ref={audioRef} muted={muted} style={{ display: 'none' }} />
            <video autoPlay ref={videoRef} muted playsInline />
          </>
        }
      />
    </>
  );
};
