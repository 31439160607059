import { Ava, Btn, Bx, ExtendedListItem, ListWrapper, Typo } from '@curry-group/mui-curcuma';
import { faPlus, faTimes, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FramedContent } from '../../framed-content';

// DEPRECATED
const EmployerSearch: React.FC<{ props: any; result: any; queryTerm: string; asControl: boolean }> = ({ props, result, queryTerm, asControl = false }) => {
  const [selectedResult, setSelectedResult] = useState<any>(props.flowData?.userSelectedEmployer);
  if (!props.flowData?.userSelectedEmployer) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight) return;
    if (!props.search?.working && props.search?.moreDataAvailable) props.flowFunctions?.fetchNextPageSearchEntries?.();
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  const filteredResults = result?.hits?.hits;
  return (
    <React.Fragment>
      {!queryTerm && !selectedResult && (
        <FramedContent display="flex" alignItems="center" height="100%" innerBoxProps={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                    <img src="images/einfach-tippen.svg" alt="Einfach tippen..." />
                  </Bx>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Typo variant="h1" align="center">
                    Einfach tippen...
                  </Typo>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Typo variant="body1" align="center">
                    Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern. Zwölf Boxkämpfer jagen
                    Viktor quer über den großen Sylter Deich. Vogel Quax zwickt.
                  </Typo>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FramedContent>
      )}
      {selectedResult && (
        <FramedContent>
          <ListWrapper>
            <ExtendedListItem
              key={`selected_${selectedResult._id}`}
              active={true}
              avatar={<Ava size="large" variant="rounded" children={<FontAwesomeIcon icon={faUsers} />} />}
            >
              <Bx display="flex" justifyContent="space-between" alignItems="center" flexDirection={{ xs: 'column', md: 'row' }} height="100%">
                <Bx mb={{ xs: 2, md: 0 }}>
                  <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
                    {selectedResult?._source?.content?.name}
                  </Typo>
                  <Typo variant="body2" color="textPrimary" component="span">
                    {selectedResult?._source?.content?.city}
                  </Typo>
                </Bx>
                <Btn
                  onClick={e => {
                    setSelectedResult(null);
                    props.flowFunctions.setFlowData({ ...props.flowData, userSelectedEmployer: null });
                  }}
                  variant="outlined"
                  size="large"
                  endIcon={<FontAwesomeIcon icon={faTimes} />}
                >
                  Auswahl zurücksetzen
                </Btn>
              </Bx>
            </ExtendedListItem>
          </ListWrapper>
        </FramedContent>
      )}
      {queryTerm && !selectedResult && (
        <FramedContent>
          <ListWrapper>
            {filteredResults &&
              filteredResults.map((actorData: any, idx: number) => {
                const actor = actorData?._source?.content;
                const actorId = actorData?._id;
                return (
                  <ExtendedListItem
                    key={actorId}
                    active={props.flowData?.itemData?.player?.[0]?.player === actorId}
                    onClick={e => {
                      props.setNextStepDisabled(false);
                      setSelectedResult(actorData);
                      props.flowFunctions.setFlowData({
                        ...props.flowData,
                        userSelectedEmployer: actorData,
                        itemData: { ...props.flowData?.itemData, player: [{ player: actorId }] }
                      });
                      props.flowFunctions.setFlowOption('createActor', true);
                      setTimeout(() => {
                        props.nextClick(e);
                      }, 100);
                    }}
                    avatar={<Ava size="large" variant="rounded" children={<FontAwesomeIcon icon={faUsers} />} />}
                  >
                    <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                      <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
                        {actor?.name}
                      </Typo>
                      <Typo variant="body2" color="textPrimary" component="span">
                        {actor?.city}
                      </Typo>
                    </Bx>
                  </ExtendedListItem>
                );
              })}
            <Bx display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" py={3.5}>
              <Bx mb={{ xs: 2, md: 0 }}>
                <Typo variant="body1" component="div">
                  <strong>Ihr Arbeitgeber ist nicht dabei?</strong>
                </Typo>
              </Bx>
              <Btn
                onClick={e => {
                  props.setNextStepDisabled(false);
                  props.flowFunctions.setFlowOption('createActor');
                  setTimeout(() => {
                    props.nextClick(e);
                  }, 100);
                }}
                variant="outlined"
                size="large"
                endIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                Neuen Arbeitgeber beantragen
              </Btn>
            </Bx>
          </ListWrapper>
        </FramedContent>
      )}
    </React.Fragment>
  );
};

export const ConnectedEmployerSearch = (props: any) => {
  const result = useSelector(state => state.search?.result);
  const queryTerm = useSelector(state => state.filter?.searchObject?.queryTerm);
  const flowAlias = useSelector(state => state.flow?.currentAlias);
  let asControl = flowAlias === 'add-participants';
  return <EmployerSearch props={props} queryTerm={queryTerm || ''} result={result} asControl={asControl} />;
};
