import React from 'react';
import { FormMeeting } from '../../form/compositions/FormMeeting';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';

interface ICreateMeetingForm {
  props: any;
  setValid: (valid: boolean) => void;
}

const CreateMeetingForm: React.FC<ICreateMeetingForm> = ({ props, setValid }) => {
  if (
    !props.flowData?.itemData?.interval
      || (props.flowData?.itemData?.external && (
        (!props.flowData?.itemData?.location && !props.flowData?.itemData?.externalLink)
        || (props.flowData?.itemData?.externalType === 'link' && (!props.flowData?.itemData?.externalLink || !props.flowData?.itemData?.externalLinkAcceptedInstructions))
        || (props.flowData?.itemData?.externalType === 'location' && !props.flowData?.itemData?.location)
      ))
      || !FormValidator.isValid('', 'meeting')) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  if (props.flowData?.external) {
    if (!props.flowData?.itemData?.external) {
      props.flowFunctions.setFlowFormData('external', true);
    }
  } else {
    if (props.flowData?.itemData?.external) {
      props.flowFunctions.setFlowFormData('external', false);
    }
  }
  let subtitle =
    'Worum geht es? Was wollen Sie wann besprechen? Bitte geben Sie Ihrem Meeting einen aussagekräftigen Titel und ergänzen Sie eine kurze Beschreibung/Agenda. Beides ist für die Gruppenmitglieder sichtbar.';
  if (props.flowData.external) {
    subtitle =
      'Worum geht es? Was wollen Sie wann und wo besprechen? Bitte geben Sie Ihrem Meeting einen aussagekräftigen Titel und ergänzen Sie den Ort sowie eine kurze Beschreibung/Agenda. Beides ist für die Gruppenmitglieder sichtbar.';
  }
  return (
    <FramedContent>
      <FormMeeting
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={subtitle}
        onChange={(formField, formValue) => {
          props.flowFunctions.setFlowFormData(formField, formValue);
        }}
        setValid={setValid}
        onChangeAttachments={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export const ConnectedCreateMeetingForm = (props: any) => {
  return <CreateMeetingForm props={{ ...props }} setValid={valid => props.setNextStepDisabled(!valid)} />;
};
