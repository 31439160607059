import React from 'react';
import { Anohana, Bx, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export const MessageDeletedDialog: React.FC<{
  hintText: string | undefined;
  open: boolean;
  onCanceled?: () => void;
}> = ({ hintText, open, onCanceled }) => {
  return (
    <Anohana open={!!open} transition="zoom" maxWidth="sm" onClose={onCanceled}>
      <Bx p={2.5}>
        <Bx display="flex" alignItems="center">
          <Typo variant="h4" component="span">
            {hintText}
          </Typo>
          <Bx ml="auto" mr={-1}>
            <IconBtn onClick={onCanceled}>
              <FontAwesomeIcon icon={faTimes} />
            </IconBtn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
