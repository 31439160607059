import * as _ from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Anohana, Ava, Btn, Bx, IconBtn, Input, TabItem, TabList, Typo } from '@curry-group/mui-curcuma';
import { faCheck, faEnvelopeOpenText, faInfo, faPersonToDoor, faScrewdriverWrench, faTimes, faUser, faUserTie, faUserXmark, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { IParticipantResolvedModel, ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { IRoleInfoModel, IRoleModel, RoleType } from '../../../model/communication/Role';
import { IListItem } from '../../../model/list/ListItem';
import { ThingTypes } from '../../../model/ryve/Thing';
import { AddSearch } from '../../list-items/add-search';
import { ListWidget } from '../../widget/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseListItem } from '../../list-items/default';
import { assetUrl, hashCode, ListAvatarColors } from '../../../helper';
import { HeadlineContainer } from '../../headline-container';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { buildAbbreviation } from '../../../helper/user';
import { faTimes as faTimesSolid, faPlus } from '@fortawesome/pro-solid-svg-icons';

export interface IPendingParticipationModal {
  participation?: IParticipantResolvedModel;
  onUpdateInvitation: (message: string) => void;
  onAcceptRequest: () => void;
  onDeclineRequest: () => void;
  close: () => void;
}

export const PendingParticipationModal: React.FC<IPendingParticipationModal> = ({
  participation,
  onUpdateInvitation,
  onAcceptRequest,
  onDeclineRequest,
  close
}) => {
  const isRequest = participation?.type === ParticipationRequestType.REQUEST;

  const headline = isRequest ? 'Anfrage' : 'Einladung erneut versenden';

  const [content, setContent] = useState<string>('');
  useEffect(() => {
    setContent(participation?.message ? participation?.message : isRequest ? 'ANFRAGE' : 'EINLADUNG');
  }, [participation, isRequest]);

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setContent(event.target.value);
  }
  const abbreviation = buildAbbreviation(!!participation ? participation.userResolved?.content?.fullName : '') || 'EB';
  const hash = hashCode(abbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];

  const avatarImage = assetUrl(participation?.userResolved?.content?.avatar, true);
  let avatar =
    <Ava src={avatarImage}
      size="large"
      style={{ color: colorObject.color, fontWeight: 500, backgroundColor: colorObject.bg }}
    >
      {abbreviation}
    </Ava>;

  return (
    <Anohana open={!!participation} fullWidth maxWidth="sm">
      {!!participation && (
        <>
          <HeadlineContainer
            bgcolor="background.paper"
            px={3}
            py={2.5}
            headline={headline}
            minHeight={81}
            headlineVariant="h4"
            headlineComponent="h5"
            endAdornment={
              <IconBtn size="small" onClick={() => close()}>
                <FontAwesomeIcon icon={faTimes} />
              </IconBtn>
            }
          />
          <Bx pb={3} px={3}>
            {participation.status === 'BULK_UPDATE' && <Bx pt={4} pb={2}>
              <Typo component="span" variant="body1">
                Hier können Sie den Text aller nicht angenommenen Einladungen gebündelt bearbeiten. Wenn Sie das Textfeld leer lassen, wird die ursprüngliche Einladung erneut verschickt.
              </Typo>
            </Bx>}
            {participation.status !== 'BULK_UPDATE' && <Bx>
              <BaseListItem
                type=""
                title={participation.userResolved?.content?.fullName || ''}
                avatar={avatar}
              />
            </Bx>}

            <Bx pt={1}>
              {isRequest && (
                <Typo variant="body1" component="p">
                  {content}
                </Typo>
              )}
              {!isRequest && <Input input={{ multiline: true, placeholder: 'Ihre Einladung', value: content, onChange: onInputValueChange }}></Input>}
            </Bx>
            <Bx display="flex" pt={5} justifyContent="space-between">
              <Bx onClick={close} display="flex" justifyContent="center" alignItems="center">
                <Btn color="default" size="medium" variant="outlined">
                  Abbrechen
                </Btn>
              </Bx>
              {isRequest && (
                <Bx display="flex">
                  <Bx display="flex" justifyContent="center" alignItems="center">
                    <Btn onClick={onAcceptRequest} color="secondary" size="medium" variant="contained">
                      Annehmen
                    </Btn>
                  </Bx>
                  <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
                    <Btn onClick={onDeclineRequest} color="quaternary" size="medium" variant="contained">
                      Ablehnen
                    </Btn>
                  </Bx>
                </Bx>
              )}
              {!isRequest && (
                <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
                  <Btn onClick={() => onUpdateInvitation(content)} disabled={!content && participation.status !== 'BULK_UPDATE'} color="secondary" size="medium" variant="contained">
                    Einladung versenden
                  </Btn>
                </Bx>
              )}
            </Bx>
          </Bx>
        </>
      )}
    </Anohana>
  );
};

export interface ParticipantsTab {
  id: string;
  name: string;
  filter: (item: IParticipantResolvedModel) => boolean;
  searchOnly: (permissions?: IRoleModel) => boolean;
  emptyCaption?: string;
  buttonCaption?: string;
  buttonIcon?: IconDefinition;
  count: number;
}

const participantsTab: ParticipantsTab = {
  id: 'participants_tab',
  name: 'Teilnehmende',
  searchOnly: (permissions?: IRoleModel) => !permissions || !permissions.allowInvites,
  filter: p => p.status === ParticipationRequestStatus.ACCEPTED,
  emptyCaption: 'Keine Teilnehmenden',
  count: 0
};
const invitesTab: ParticipantsTab = {
  id: 'invites_tab',
  name: 'Einladungen',
  searchOnly: (permissions?: IRoleModel) => !permissions || !permissions.allowInvites,
  filter: p => p.status === ParticipationRequestStatus.PENDING && p.type === ParticipationRequestType.INVITE,
  emptyCaption: 'Keine Einladungen',
  buttonCaption: 'Offene Einladungen erneut einladen',
  buttonIcon: faPaperPlane,
  count: 0
};
const requestsTab: ParticipantsTab = {
  id: 'requests_tab',
  name: 'Anfragen',
  searchOnly: () => true,
  filter: p => p.status === ParticipationRequestStatus.PENDING && p.type === ParticipationRequestType.REQUEST,
  emptyCaption: 'Keine Anfragen',
  count: 0
};

export interface IParticipantsComponent {
  componentId: string;
  height?: number;

  working?: boolean;
  renderItem: (participant: IParticipantResolvedModel) => IListItem;
  filter: (text?: string) => IParticipantResolvedModel[];

  searchOnly?: boolean;
  addButtonClicked?: () => void;
  emptyCaption?: string;

  menuClicked?: (item: IListItem, close: () => void) => ReactNode;
  itemActions?: (item: IListItem) => ReactNode;

  activeTab?: ParticipantsTab;
}

export const ParticipantsComponent: FC<IParticipantsComponent> = ({
  componentId,
  filter,
  height,
  searchOnly,
  working,
  renderItem,
  addButtonClicked,
  emptyCaption,
  menuClicked,
  itemActions,
  activeTab
}) => {
  const [inputValue, setInputValue] = useState('');
  const [searchActive, setSearchActive] = useState(searchOnly);
  const [items, setItems] = useState<IParticipantResolvedModel[]>([]);
  const searchInputChange = (value: string) => {
    setInputValue(value);
  };
  const [itemsRendered, setItemsRendered] = useState(20);
  const [totalCount, setTotalCount] = useState(0);

  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight) {
      return;
    }
    if (!working && totalCount > itemsRendered) {
      setItemsRendered(itemsRendered + 20);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (!!componentId) {
      setItemsRendered(20);
    }
  }, [componentId]);

  useEffect(() => {
    const totalItems = filter(inputValue);
    setTotalCount((totalItems || []).length);
    const sorted = totalItems.sort((a, b) => {
      if (!a.userResolved?.content?.lastName) return 1;
      if (!b.userResolved?.content?.lastName) return -1;
      if (a.userResolved.content.lastName > b.userResolved.content.lastName ) return 1;
      if (a.userResolved.content.lastName < b.userResolved.content.lastName ) return -1;
      return 0;
    });
    if (sorted.length <= itemsRendered) {
      setItems(sorted);
    } else {
      setItems(sorted.slice(0, itemsRendered));
    }
  }, [itemsRendered, filter, inputValue]);

  return (
    <ListWidget
      menuClicked={menuClicked}
      itemActions={itemActions}
      headline=""
      top={
        <Bx minHeight={96} display="flex" alignItems="center">
          <AddSearch
            size="large"
            searchInput={inputValue}
            searchPlaceholder="Teilnehmende suchen"
            searchInputChange={searchInputChange}
            setSearchActive={active => {
              searchInputChange('');
              if (!searchOnly) {
                setSearchActive(active);
              }
            }}
            searchActive={searchOnly ? true : searchActive}
            maxHeight={height}
            searchEnabled={true}
            addButtonIcon={activeTab?.buttonIcon}
            addButtonClicked={searchOnly ? undefined : addButtonClicked}
            addButtonCaption={
              <Typo variant="body1" component="span" fontWeight={500}>
                {activeTab?.id === 'invites_tab' ? 'Alle Einladungen erneuern' : 'Teilnehmende hinzufügen'}
              </Typo>
            }
            addButtonDisabled={activeTab?.id === 'invites_tab' && !items?.length}
          />
        </Bx>
      }
      emptyCaption={emptyCaption}
      working={working}
      items={items.map(renderItem)}
      listType={'members'}
    />
  );
};

export interface IParticipantsProps {
  working?: boolean;
  participants?: IParticipantResolvedModel[];
  roles?: IRoleInfoModel[];
  showRequests?: boolean;
  showInvites?: boolean;
  kickParticipants?: boolean;
  currentUser?: string;
  permissions?: IRoleModel;

  addButtonClicked?: () => void;

  onRemoveParticipant: (id: string) => void;
  onMakeAdmin: (id: string) => void;
  onRemoveAdmin: (id: string) => void;
  onRevokeInvitation: (id: string) => void;
  onAcceptRequest: (id: string) => void;
  onDeclineRequest: (id: string) => void;
  onUpdateInvitation: (id: string, message: string) => void;
  onLeave: () => void;
  onSetModalParticipation: (participant: IParticipantResolvedModel) => void;
}

export const Participants: FC<IParticipantsProps> = ({
  working,
  participants,
  roles,
  kickParticipants,
  showInvites,
  showRequests,
  currentUser,
  permissions,
  addButtonClicked,
  onRemoveParticipant,
  onMakeAdmin,
  onRemoveAdmin,
  onRevokeInvitation,
  onAcceptRequest,
  onDeclineRequest,
  onUpdateInvitation,
  onSetModalParticipation,
  onLeave
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openAdminMissing, setOpenAdminMissing] = useState<boolean>(false);
  const [tabs, setTabs] = useState([participantsTab, invitesTab, requestsTab]);

  const theme = useTheme();
  let adminMissing = false;
  if (participants) {
    const adminRoleId = roles?.find(role => role.type === 'administrator')?.roleId || '';
    const admins = participants?.filter(participant => participant.roles.includes(adminRoleId));
    const ownParticipation = participants?.find(participant => participant.user === currentUser);
    if (ownParticipation?.roles?.includes(adminRoleId)) {
      adminMissing = !admins || admins.length < 2;
    }
  }

  useEffect(() => {
    participantsTab.count = (participants || []).filter(participantsTab.filter).length;
    const newTabs = [participantsTab];
    if (showInvites) {
      invitesTab.count = (participants || []).filter(invitesTab.filter).length;
      newTabs.push(invitesTab);
    }
    if (showRequests) {
      requestsTab.count = (participants || []).filter(requestsTab.filter).length;
      newTabs.push(requestsTab);
    }
    setTabs(newTabs);
  }, [showInvites, showRequests, participants]);

  const handleTabSelected = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <React.Fragment>
      <Grid item>
        <Bx mt={1.5} border={1} borderColor="divider" borderLeft={0} borderRight={0} borderTop={0}>
          <TabList
            value={selectedTab}
            fontVariant="body1"
            textColor="primary"
            onChange={handleTabSelected as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
          >
            {tabs.map((t, i) => (
              <TabItem
                key={i}
                disabled={working}
                label={
                  <Bx py={1.5}>
                    <Typo variant="h4" component="span" style={{ fontFamily: theme.typography.fontFamily }}>
                      {t.name + (t.count > 0 ? ` (${t.count})` : '')}
                    </Typo>
                  </Bx>
                }
              />
            ))}
          </TabList>
        </Bx>
      </Grid>
      <ParticipantsComponent
        componentId={'selectedTab' + selectedTab}
        itemActions={item => {
          const options: ReactNode[] = [];
          const found = participants?.find(i => i.user === item._id);
          if (found) {
            if (!found?.userResolved?.content?.userId) {
              return <></>;
            }
            if (found.status === ParticipationRequestStatus.PENDING) {
              if (found.type === ParticipationRequestType.INVITE && showInvites) {
                options.push(
                  <Bx key={'buttonBoxInvite'} display="flex" ml="auto">
                    <Bx display="flex" justifyContent="center" alignItems="center">
                      <IconBtn
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSetModalParticipation(found);
                        }}
                        color="default"
                        size="medium"
                        variant="outlined"
                        title="Einladung erneut versenden"
                      >
                        <FontAwesomeIcon icon={faEnvelopeOpenText} />
                      </IconBtn>
                    </Bx>
                    <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
                      <IconBtn
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRevokeInvitation(item._id);
                        }}
                        color="quaternary"
                        size="medium"
                        variant="contained"
                        title="Einladung zurückziehen"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </IconBtn>
                    </Bx>
                  </Bx>
                );
              } else if (found.type === ParticipationRequestType.REQUEST && showRequests) {
                options.push(
                  <Bx key={'buttonBoxRequest'} display="flex" ml="auto">
                    <Bx display="flex" justifyContent="center" alignItems="center">
                      <IconBtn
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSetModalParticipation(found);
                        }}
                        color="default"
                        size="medium"
                        variant="outlined"
                        title="Beitrittsanfrage einsehen"
                      >
                        <FontAwesomeIcon icon={faInfo} />
                      </IconBtn>
                    </Bx>
                    <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
                      <IconBtn
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onAcceptRequest(item._id);
                        }}
                        color="secondary"
                        size="medium"
                        variant="contained"
                        title="Beitritt bestätigen"
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </IconBtn>
                    </Bx>
                    <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
                      <IconBtn
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onDeclineRequest(item._id);
                        }}
                        color="quaternary"
                        size="medium"
                        variant="contained"
                        title="Beitritt ablehnen"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </IconBtn>
                    </Bx>
                  </Bx>
                );
              }
            }
          }
          return options;
        }}
        menuClicked={(item, close) => {
          const options: ReactNode[] = [];
          const found = participants?.find(i => i.user === item._id);
          if (found) {
            if (!found?.userResolved?.content?.userId) {
              return <></>;
            }
            if (found.user === currentUser) {
              options.push(
                <IconBtn
                  key={'leave'}
                  size="medium"
                  variant="contained"
                  color="quaternary"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (adminMissing) {
                      setOpenAdminMissing(true);
                    } else {
                      onLeave();
                    }
                  }}
                  title="Verlassen"
                >
                  <FontAwesomeIcon icon={faPersonToDoor} />
                </IconBtn>
              );
            } else if (found.status === ParticipationRequestStatus.ACCEPTED) {
              const participantRoles = _.intersectionWith(roles, found.roles, (role, roleId) => {
                return role.type !== RoleType.DEFAULT && role.roleId === roleId;
              });
              if (participantRoles.length === 0) {
                options.push(
                  <Bx key={'adminButtonBox'} pr={1}>
                  <IconBtn
                    key={'make-admin'}
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      onMakeAdmin(item._id);
                    }}
                    title="Zum Administrator machen"
                  >
                    <span className="fa-layers" title="Zum Administrator machen">
                      <FontAwesomeIcon icon={faScrewdriverWrench} />
                      <FontAwesomeIcon icon={faPlus} style={{position:'absolute',top:'0',right:'-8px',bottom:'0',left:'auto',fontSize:12}} />
                    </span>
                  </IconBtn>
                  </Bx>
                );
              } else if (participantRoles.find(r => r.type === 'administrator')) {
                options.push(
                  <Bx key={'adminButtonBox'} pr={1}>
                  <IconBtn
                    key={'remove-admin'}
                    size="medium"
                    variant="contained"
                    color="quaternary"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemoveAdmin(item._id);
                    }}
                    title="Administratorstatus entfernen"
                  >
                    <span className="fa-layers" title="Administratorstatus entfernen">
                      <FontAwesomeIcon icon={faScrewdriverWrench} />
                      <FontAwesomeIcon icon={faTimesSolid} style={{position:'absolute',top:'0',right:'-8px',bottom:'0',left:'auto',fontSize:12}} />
                    </span>
                  </IconBtn>
                  </Bx>
                );
              }
              options.push(
                <Bx key={'removeButtonBox'}>
                <IconBtn
                  key={'remove'}
                  size="medium"
                  variant="contained"
                  color="quaternary"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onRemoveParticipant(item._id);
                  }}
                  title="Entfernen"
                >
                  <FontAwesomeIcon icon={faUserXmark} />
                </IconBtn>
                </Bx>
              );
            }
          }
          return (<Bx key={'buttonBoxList'} ml={'auto'} display="flex" alignItems="center">
            {options}
          </Bx>);
        }}
        working={working}
        renderItem={(participant) => renderParticipant(participant, currentUser, roles, kickParticipants)}
        searchOnly={tabs[selectedTab].searchOnly(permissions)}
        emptyCaption={tabs[selectedTab].emptyCaption}
        addButtonClicked={tabs[selectedTab].id === 'invites_tab'
          ? () => { onSetModalParticipation({
            userResolved: {
              categories: [],
              content: {
                isAdmin: false,
                chatAllowed: false,
                externalLinksAllowed: false
              },
              type: 'FAKE',
              _id: 'BULK_UPDATE',
              _score: 0,
            },
            message: 'Neuer Einladungstext',
            user: 'BULK_UPDATE',
            roles: [],
            type: 'FAKE_USER',
            status: 'BULK_UPDATE',
          }) }
          : addButtonClicked
        }
        filter={text =>
          participants?.filter(item => {
            const ok = tabs[selectedTab].filter(item);
            if (!text) return ok;
            return ok && item.userResolved?.content?.fullName?.toLowerCase().includes(text.toLowerCase());
          }) || []
        }
        activeTab={tabs[selectedTab]}
      />
      <OnlyAdminInGroupModal
        openAdminMissing={openAdminMissing}
        onLeave={onLeave}
        close={() => setOpenAdminMissing(false)}
      />
    </React.Fragment>
  );
};

function renderParticipant(
  participant: IParticipantResolvedModel,
  currentUser: string | undefined,
  roles: IRoleInfoModel[] | undefined,
  kickParticipants: boolean | undefined
) {
  const participantRoles = _.intersectionWith(roles, participant.roles, (role, roleId) => {
    return role.type !== RoleType.DEFAULT && role.roleId === roleId;
  });
  const item: IListItem = {
    to: participant.userResolved?.content ? undefined : false,
    __original: participant,
    alias: participant.user,
    _id: participant.user,
    typeId: ThingTypes.Profile,
    title: participant.userResolved?.content?.fullName || 'Ehemaliger Benutzer',
    type: '',
    subtitle: participantRoles?.map(r => r.name).join(' ') || '',
    color: 'primary',
    avatar: {
      type: 'user',
      abbreviation: buildAbbreviation(participant.userResolved?.content?.fullName || ''),
    }
  };
  if (participant.status === ParticipationRequestStatus.ACCEPTED) {
    item.hasMenu = kickParticipants;
  }
  if (participant.user === currentUser) {
    item.hasMenu = true;
  }
  if (participant.userResolved?.content?.avatar) {
    item.avatar = {
      type: 'user',
      image: assetUrl(participant.userResolved?.content?.avatar, true),
      abbreviation: buildAbbreviation(participant.userResolved?.content?.fullName || ''),
    };
  }
  return item;
}

export interface IOnlyAdminInGroupModal {
  openAdminMissing: boolean;
  onLeave: () => void;
  close: () => void;
}

export const OnlyAdminInGroupModal: React.FC<IOnlyAdminInGroupModal> = ({
  openAdminMissing,
  onLeave,
  close
}) => {
  const headline = 'Bitte Nachfolger bestimmen';
  return (
    <Anohana open={openAdminMissing} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={headline}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={
          <IconBtn size="small" onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </IconBtn>
        }
      />
      <Bx pb={3} px={3}>
        <Bx pt={1}>
          <Typo variant="body1" component="p">
            Bitte bennenen Sie mindestens einen Nachfolger als Administrator, bevor Sie die Gruppe verlassen.
          </Typo>
        </Bx>
        <Bx display="flex" pt={5} justifyContent="space-between">
          <Bx onClick={close} display="flex" justifyContent="center" alignItems="center">
            <Btn color="default" size="medium" variant="outlined">
              Abbrechen
            </Btn>
          </Bx>
          <Bx display="flex" ml={1} justifyContent="center" alignItems="center">
            <Btn onClick={close} color="secondary" size="medium" variant="contained">
              OK
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};