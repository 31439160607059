import { IPermissionModel } from './Permissions';

export interface IRolePermissionModel {
  writeMessage: boolean;
  readMessages: boolean;
  manageMessages: boolean;
  manageAttachments: boolean;
  kickParticipants: boolean;
  banParticipants: boolean;
  manageRoles: boolean;
  manageRequests: boolean;
  manageInvites: boolean;
}

export class RoleType {
  public static DEFAULT = 'default';
  public static MODERATOR = 'moderator';
  public static ADMINISTRATOR = 'administrator';
}

export interface IRoleInfoModel {
  roleId: string;
  type: string;
  name: string;
}
export interface IRoleModel extends IPermissionModel, IRolePermissionModel, IRoleInfoModel {}
