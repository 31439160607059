import { Grid } from '@material-ui/core';
import { RefObject, useLayoutEffect, useRef } from 'react';

export const CardWrapper = ({ children }) => {
  return (
    <Grid item xs={12} lg={6} xl={4}>
      {children}
    </Grid>
  );
};

export const InViewport: React.FC<{ onInViewport?: (value: boolean) => void }> = ({ onInViewport }) => {
  const divRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  useLayoutEffect(() => {
    if (divRef.current) {
      const currentNode = divRef.current;
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(value => {
          onInViewport?.(value.intersectionRatio > 0);
        });
      });
      observer.observe(currentNode);
      return () => {
        observer.unobserve(currentNode);
      };
    }
  }, [onInViewport]);
  return <div ref={divRef} style={{ width: '100%', height: 0 }} />;
};
