import { Bx, DropDownMenu, IconBtn, theme } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

const Calendar = (props: any) => (
  <Bx key={props.componentKey} style={{ marginLeft: props.marginLeft }}>
    <IconBtn
      title={'Kalender'}
      size={props.smallVariant ? 'small' : 'medium'}
      color={props.open === props.componentKey ? 'primary' : 'default'}
      variant={props.open === props.componentKey ? 'contained' : 'outlined'}
      onClick={e => {
        props.filterFunctions?.click?.(e);
      }}
      aria-controls={props.componentKey}
      aria-haspopup="true"
      style={{
        borderRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: props.open === props.componentKey ? 0 : theme.shape.borderRadius,
        borderBottomRightRadius: props.open === props.componentKey ? 0 : theme.shape.borderRadius
      }}
    >
      <FontAwesomeIcon icon={faCalendarWeek} />
    </IconBtn>
    <DropDownMenu
      id={`filter_${props.componentKey}`}
      elevation={0}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorEl={props.anchor}
      onClose={e => props.filterFunctions?.open?.('')}
      MenuListProps={{ component: 'div' }}
      open={props.open === props.componentKey}
      keepMounted
    >
      <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={360} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
        {props.open === props.componentKey && <div>CALENDAR_NOT_IMPLEMENTED!</div>}
      </Bx>
    </DropDownMenu>
  </Bx>
);

export default Calendar;
