import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import clsx from 'clsx';
import { handleUnit } from '@curry-group/mui-curcuma';

export interface ICardHeaderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  avatar?: React.ReactChild;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export interface ICardHeaderThemeExtension {
  cardImageHeader: {
    height?: React.CSSProperties['height'];
    fallbackBackgroundColor?: React.CSSProperties['backgroundColor'];
  };
}

interface ICardHeaderStyle {
  avaWidth: React.CSSProperties['width'];
  avaHeight: React.CSSProperties['height'];
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  image: {
    objectFit: 'cover',
    objectPosition: 'center',
    height: theme?.custom?.cardImageHeader?.height,
    width: '100%',
    display: 'block',
  },
  avatar: {
    position: 'absolute',
    left: (props: ICardHeaderStyle) => props.avaWidth && `calc(50% - ${handleUnit(props.avaWidth)} / 2)`,
    top: (props: ICardHeaderStyle) => props.avaHeight && `calc(${handleUnit(theme?.custom?.cardImageHeader?.height ?? 0)} - ${handleUnit(props.avaHeight)} / 2)`,
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut }),
    opacity: 0,
  },
  avatarVisible: {
    opacity: 1,
  },
  blocker: {
    height: (props: ICardHeaderStyle) => props.avaHeight && `calc(${handleUnit(props.avaHeight)} / 2)`,
  },
  fallback: {
    height: theme?.custom?.cardImageHeader?.height,
    backgroundColor: theme?.custom?.cardImageHeader?.fallbackBackgroundColor,
  },
}));

export const CardHeader: React.FunctionComponent<ICardHeaderProps> = ({
  avatar,
  containerProps,
  children,
  className,
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const classes = useStyles({ avaWidth: 80, avaHeight: 80 });

  return (
    <div className={clsx(classes.root, containerProps?.className)} {..._.omit(containerProps, 'className')}>
      {rest?.src && <img className={clsx(classes.image, className)} {...rest} alt={'User Background'} />}
      {!rest?.src && <div className={classes.fallback}></div>}
      {avatar && (
        <React.Fragment>
          <div ref={ref} className={clsx(classes.avatar, classes.avatarVisible)}>
            {avatar}
          </div>
          <div className={classes.blocker}></div>
        </React.Fragment>
      )}
    </div>
  );
};
