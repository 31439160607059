import { useDispatch, useSelector } from "react-redux";
import { PendingParticipationModal } from "../../../../../../components/communication/participants";
import { setParticipantModalAction, requestResponseRequest, updateMeetingInvitationRequest } from "../../../../../../data/reducer/communication";
import { IParticipantResolvedModel } from "../../../../../../model/communication/Participant";

export const ConnectedMeetingParticipantModal: React.FC = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.communication?.id);
    const participantModal = useSelector(state => state.communication?.participantModal);
  
    const setParticipantModal = (participant: IParticipantResolvedModel | undefined) => {
      dispatch(setParticipantModalAction({ participant }));
    };
    const onAcceptRequest = (participantId: string) => {
      id && dispatch(requestResponseRequest({ id, participantId, accept: true }));
    };
    const onDeclineRequest = (participantId: string) => {
      id && dispatch(requestResponseRequest({ id, participantId, accept: false }));
    };
    const onUpdateInvitation = (participantId: string, message: string) => {
      id && dispatch(updateMeetingInvitationRequest({ id, participantId, message }));
    };
    return (
      <PendingParticipationModal
        participation={participantModal}
        onAcceptRequest={() => {
          participantModal && onAcceptRequest(participantModal?.user);
          setParticipantModal(undefined);
        }}
        onDeclineRequest={() => {
          participantModal && onDeclineRequest(participantModal?.user);
          setParticipantModal(undefined);
        }}
        onUpdateInvitation={message => {
          participantModal && onUpdateInvitation(participantModal?.user, message);
          setParticipantModal(undefined);
        }}
        close={() => setParticipantModal(undefined)}
      />
    );
  };