import React from 'react';
import { FormRequisition } from '../../form/compositions/FormRequisition';
import { FramedContent } from '../../framed-content';

const RequisitionForm = (props: any) => {
  if (
    !props.flowData ||
    !props.flowData?.itemData?.title ||
    !props.flowData?.itemData?.contentRequisitionContext ||
    props.flowData?.itemData?.contentRequisitionContext === '<p></p>' ||
    !props.flowData?.itemData?.content ||
    props.flowData?.itemData?.content === '<p></p>' ||
    !props.flowData?.itemData?.contentRequisitionSolutions ||
    props.flowData?.itemData?.contentRequisitionSolutions === '<p></p>'
  ) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormRequisition
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={'Bitte achten Sie darauf, keine schutzrechtsrelevanten Details offenzulegen.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default RequisitionForm;
