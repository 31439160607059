import { RefObject, useEffect, useRef } from 'react';

export const SimpleParticipant: React.FC<{ media?: any; muted?: boolean; objectFitCover?: boolean }> = ({ media, muted, objectFitCover }) => {
  const videoRef = useRef<HTMLVideoElement>() as RefObject<HTMLVideoElement>;
  useEffect(() => {
    if (media && videoRef.current) {
      if (videoRef.current.srcObject !== media) {
        videoRef.current.srcObject = media;
      }
    }
  });
  return <video autoPlay muted={muted} playsInline ref={videoRef} style={{ width: '100%', height: '100%', objectFit: objectFitCover ? 'cover' : undefined }} />;
};
