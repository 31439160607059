import { Bx, CheckboxControl, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FramedContent } from '../../framed-content';

const ProjectConfirmTerms = (props: any) => {
  const location = useLocation();
  if (!props.flowData || !props.flowData?.itemData?.termsAccepted) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={8}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src="/images/bevor-es-losgeht.svg" alt="Nutzungsbedingungen zustimmen" />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={8}>
              <Typo variant="h1" align="center">
                Bevor es losgeht, noch eine Sache...
              </Typo>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={8}>
              <Typo variant="body1" align="center">
                Sie eröffnen einen Projektraum für die Zusammenarbeit mit anderen Nutzenden. Wir weisen Sie darauf hin, dass auch in diesem Raum unsere Nutzungsbedingungen und
                Gemeinschaftsstandards gelten und bitten Sie, uns dabei zu unterstützen, diese auch in diesem Projektraum umzusetzen.
              </Typo>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container justifyContent="center">
            <Bx>
              <CheckboxControl
                control={{
                  value: props.flowData?.itemData?.termsAccepted,
                  color: 'primary',
                  name: 'check1',
                  onChange: e => {
                    props.flowFunctions.setFlowData({ ...props.flowData, itemData: { ...props.flowData?.itemData, termsAccepted: !props.flowData?.itemData?.termsAccepted } });
                  },
                  style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                }}
                style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                checked={props.flowData?.itemData?.termsAccepted}
                label={
                  <Typo>
                    Ich habe die <Link to={'/content/page/nutzungsbedingungen?ref=' + location.pathname}>Nutzungsbedingungen</Link> und{' '}
                    <Link to={'/content/page/gemeinschaftsstandards?ref=' + location.pathname}>Gemeinschaftsstandards</Link> gelesen und akzeptiere diese.
                  </Typo>
                }
              />
            </Bx>
          </Grid>
        </Grid>
      </Grid>
    </FramedContent>
  );
};

export default ProjectConfirmTerms;
