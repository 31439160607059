import { useAtom, useSetAtom } from 'jotai';
import { searchResultHitsAtom, toggleSelectionAtom } from '../atoms';
import { MeetingParticipantCard } from '../card';
import { CardWrapper } from '../misc';

export const MeetingInviteParticipantList = () => {
  const [items] = useAtom(searchResultHitsAtom);
  const toggleSelection = useSetAtom(toggleSelectionAtom);
  return (
    <>
      {items?.map(item => {
        return (
          <CardWrapper key={item._id}>
            <MeetingParticipantCard
              user={item._source?.content}
              userId={item._id}
              userScore={item._score}
              onAddRemove={() => {
                if (item._source?.content?.userId) {
                  toggleSelection({ profileId: item._id, userId: item._source?.content?.userId });
                }
              }}
            />
          </CardWrapper>
        );
      })}
    </>
  );
};
