import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FramedContent } from '../../framed-content';

const EmployerCategorySelect: React.FC<{ props: any; }> = ({
  props
}) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Welche Art von Arbeitgeber wollen sie erstellen?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">
        Hier treffen Sie die Vorauswahl der Arbeitgeber-Kategorie, wie sie auf Medtec Online eingeordnet sind.
      </Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg="auto">
            <ClickableCard
                variant="outlined"
                fluid={{ from: 240, to: 458 }}
                height="100%"
                onClick={e => {
                  props.flowFunctions.setFlowData({ ...props.flowData, employerParentType: '826f68fb-4174-41bf-c9f6-01411aabfbf6' });
                  setTimeout(() => {
                      props.nextClick(e);
                  }, 100);
                }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/quick-meeting.svg" alt="Ad-hoc Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Unternehmen
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie sind MA in einem Unternehmen mit HR Eintrag, eine GmbH oder andere Form.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={6} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 240, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, employerParentType: '5c13c5ea-8728-8504-afb8-b17412fdd153' });
                setTimeout(() => {
                    props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/terminiertes-meeting.svg" alt="Terminiertes Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Leistungserbringer
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie arbeiten in einem Krankenhaus, einer Klinik, ambulant, privat o.ä.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={6} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 240, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, employerParentType: '8b6380cc-9444-aec8-59c4-031fbb03cbfd' });
                setTimeout(() => {
                    props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/externes-meeting.svg" alt="Externes Online- / Präsenz-Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Wissenschaft
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie forschen für eine Universität/Hochschule oder ander Forschungseinrichtung.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={6} lg="auto">
            <ClickableCard
              variant="outlined"
              fluid={{ from: 240, to: 458 }}
              height="100%"
              onClick={e => {
                props.flowFunctions.setFlowData({ ...props.flowData, employerParentType: '0236d3bf-770e-6881-6114-5cd86bc16921' });
                setTimeout(() => {
                    props.nextClick(e);
                }, 100);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/externes-meeting.svg" alt="Externes Online- / Präsenz-Meeting" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Sonstige
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie arbeiten für eine öffentlich Organisation, einen Verein / Stiftung / Netzwerk.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export const ConnectedEmployerCategorySelect = (props: any) => {
  // no more connected props needed
  // const detailId = useSelector(state => state.detail?.item?._id);
  return (
    <EmployerCategorySelect props={props} />
  );
};
