import React from 'react';
import { useSelector } from 'react-redux';
import { FormWorkgroupInvitation } from '../../form/compositions/FormWorkgroupInvitation';
import { FramedContent } from '../../framed-content';

interface IWorkgroupInvitationForm {
  props: any;
  subtitle: string;
}

const WorkgroupInvitationForm: React.FC<IWorkgroupInvitationForm> = ({ props, subtitle }) => {
  if (!props.flowData || !props.flowData?.itemData?.invitation) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormWorkgroupInvitation
        formData={props.flowData}
        headline={'Ok, noch ein paar Worte zu Ihrer Einladung?'}
        subtitle={subtitle ? subtitle : 'Hier können Sie für Teilnehmende einen Einladungstext verfassen.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export const ConnectedWorkgroupInvitationForm = (props: any) => {
  const flowAlias = useSelector(state => state.flow?.currentAlias);

  const subtitle =
    flowAlias === 'createmeeting'
      ? 'Eingeladene Personen erhalten diese Nachricht per Mail zusammen mit den Informationen zum Meeting inkl. Link und ics-Datei.'
      : '';
  return <WorkgroupInvitationForm props={props} subtitle={subtitle} />;
};
