import { createReducer } from '@reduxjs/toolkit';

import { fetchAcademicTitlesRequestAction, fetchAcademicTitlesSuccessAction, fetchAcademicTitlesErrorAction } from '../../actions/lists';

export interface IListsState {
  academicTitles: {
    working: boolean;
    items: Array<{ _id: string, title: string}>;
    error: boolean;
  }

}

const initialState: IListsState = {
  academicTitles: {
    working: false,
    items: [],
    error: false
  }
};

const lists = createReducer(initialState, builder =>
  builder
    .addCase(fetchAcademicTitlesRequestAction, (state, action) => {
      return { ...state, academicTitles: { ...state.academicTitles, working: true } };
    })
    .addCase(fetchAcademicTitlesSuccessAction, (state, action) => {
      return { ...state, academicTitles: { working: false, items: action.payload.items, error: false } };
    })
    .addCase(fetchAcademicTitlesErrorAction, (state, action) => {
      return { ...state, academicTitles: { working: false, items: [], error: true } };
    })
);
export default lists;
