import { IBxProps, ICtnProps, useBreakpoints } from '@curry-group/mui-curcuma';
import React from 'react';
import { MTOLogo } from '../../../../helper';
import { ILandingPageFooterProps, LandingPageFooter } from '../../footer';

export const LandingpageFooterImpl = () => {
  const ctnMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const sectionPx: IBxProps['px'] = { xs: 4, md: 4 };
  const responsive = useBreakpoints();
  const footerLinks: ILandingPageFooterProps['links'] = [
    {
      name: 'Kontakt',
      mailto: 'info@medteconline.de'
    },
    {
      name: 'Impressum',
      to: '/impressum'
    },
    {
      name: 'Datenschutz',
      to: '/datenschutz'
    },
    {
      name: 'Nutzungsbedingungen',
      to: '/nutzungsbedingungen'
    },
    {
      name: 'Gemeinschaftsstandards',
      to: '/gemeinschaftsstandards'
    },
    {
      name: 'Unterstützer',
      to: '/unterstuetzer'
    },
    {
      name: 'Cookie Consents verwalten',
      onClick: e => {
        window['klaro']?.show?.();
      }
    },
    {
      name: 'Cookie Consents zurücksetzen',
      onClick: e => {
        window['klaro']?.getManager?.().resetConsents?.();
        window.location.reload();
      }
    }
  ];
  return (
    <LandingPageFooter
      ctnMaxWidth={ctnMaxWidth}
      sectionPx={sectionPx}
      logoSrc={MTOLogo.logoWithTextSrc}
      logoAlt={MTOLogo.logoAlt}
      partnerLogoSrc={MTOLogo.partnerLogoSrc}
      partnerLogoAlt={MTOLogo.partnerLogoAlt}
      links={footerLinks}
      responsive={responsive}
    />
  );
};
