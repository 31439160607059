import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDictionary } from '../../../helper';
import { IPageElement } from '../../../model/data/PageElement';
import { IThing } from '../../../model/ryve/Thing';
import { ISearchEntry } from '../../../model/search/SearchEntry';
import { useHistory } from 'react-router-dom';
import { IDetailItem } from '../../../model/detail';

export interface IPageElementState {
  working?: boolean;
  backgroundWorking?: boolean;
  items: IThing<ISearchEntry>[];
  totalCount?: number;
  canShowAll?: boolean;
}

export interface IDataState extends IDictionary<IPageElementState> {}

const initialState: IDataState = {};

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
    triggerPageElementDataRequests: (
      state,
      action: PayloadAction<{
        pageElements?: IPageElement[];
        detail?: IThing<any>;
        prefix?: string;
      }>
    ) => {},
    showAllPageElements: (
      state,
      action: PayloadAction<{
        pageElement: IPageElement;
        history: ReturnType<typeof useHistory>;
        route: string;
      }>
    ) => {},
    fetchPageElementDataBackgroundRequest: (
      state,
      action: PayloadAction<{
        pageElement: IPageElement;
        detail?: IThing<any>;
        id: string;
      }>
    ) => {
      if (!state[action.payload.id]) return;
      state[action.payload.id].backgroundWorking = true;
    },
    fetchPageElementDataRequest: (
      state,
      action: PayloadAction<{
        pageElement: IPageElement;
        detail?: IThing<any>;
        id: string;
      }>
    ) => {
      state[action.payload.id] = {
        working: true,
        items: [],
        totalCount: 0,
        canShowAll: false,
        backgroundWorking: false
      };
    },
    fetchPageElementDataSuccess: (
      state,
      action: PayloadAction<{
        items: IThing<ISearchEntry>[];
        canShowAll: boolean;
        totalCount: number;
        id: string;
      }>
    ) => {
      state[action.payload.id] = {
        ...action.payload,
        working: false,
        backgroundWorking: false
      };
    },
    fetchPageElementDataFailed: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state[action.payload.id] = { working: false, items: [], totalCount: 0, canShowAll: false, backgroundWorking: false };
    },
    fetchLandingPageElementsRequest: state => {
      state['landingpage'] = {
        ...state['landingpage'],
        working: true
      };
    },
    fetchLandingPageElementsSuccess: (
      state,
      action: PayloadAction<{
        items: IThing<ISearchEntry>[];
      }>
    ) => {
      state['landingpage'] = {
        ...action.payload,
        working: false
      };
    },
    fetchLandingPageElementsFailed: state => {
      state['landingpage'] = {
        items: [],
        working: false
      };
    },
    detailItemRelatedGroupSearch: (
      state,
      action: PayloadAction<{
        allCategories: { [_id: string]: { name: string; parentCategory: string } };
        item: IDetailItem;
        history: ReturnType<typeof useHistory>;
        target: string;
        ref: string;
      }>
    ) => {}
  }
});

export const {
  triggerPageElementDataRequests,
  showAllPageElements,
  fetchPageElementDataBackgroundRequest,
  fetchPageElementDataRequest,
  fetchPageElementDataSuccess,
  fetchPageElementDataFailed,
  fetchLandingPageElementsRequest,
  fetchLandingPageElementsSuccess,
  fetchLandingPageElementsFailed,
  detailItemRelatedGroupSearch
} = data.actions;
export default data.reducer;
