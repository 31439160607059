import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { IListUser, ISearchResult } from '../../../model/search/SearchResult';

import { callGet, callPost } from '@curry-group/data-addons';
import { api } from '../../api';
import { SearchRequestObject } from '../../../model/search/SearchRequest';
import { copySearchParamsToSearchAction, fetchFilterRequestAction, resetSearchObjectAction, setSelectedSearchAction, showFilterAction } from '../../actions/filter';
import { clearSearchEntriesAction, fetchFirstPageSearchEntriesRequestAction } from '../../actions/search';
import { IPageElement, PageElementCardDesign, PageElementContentSelection, PageElementVisualization } from '../../../model/data/PageElement';
import {
  fetchPageElementDataBackgroundRequest,
  fetchPageElementDataFailed,
  fetchPageElementDataRequest,
  fetchPageElementDataSuccess,
  fetchLandingPageElementsRequest,
  fetchLandingPageElementsSuccess,
  fetchLandingPageElementsFailed,
  showAllPageElements,
  triggerPageElementDataRequests,
  detailItemRelatedGroupSearch,
} from '../../reducer/data';
import { contentTypeCardisBig } from '../../../model/ryve/Thing';
import { setAvatarAssetsAction } from '../../actions/foundation';
import { DESTINATIONS_REVERSED } from '../../../helper';
import { setBotSearchAction } from '../../actions/bot';

function* watcher() {
  yield takeLatest(showAllPageElements.type, showAllPageElementsWorker);
  yield takeLatest(triggerPageElementDataRequests.type, triggerPageElementsDataWorker);
  yield takeEvery(fetchPageElementDataRequest, fetchPageElementDataWorker);
  yield takeEvery(fetchPageElementDataBackgroundRequest.type, fetchPageElementDataWorker);
  yield takeEvery(fetchLandingPageElementsRequest.type, fetchLandingPageElementsWorker);
  yield takeLatest(detailItemRelatedGroupSearch.type, detailItemRelatedGroupSearchWorker);
}

function* detailItemRelatedGroupSearchWorker(action: ReturnType<typeof detailItemRelatedGroupSearch>) {
  yield put(setBotSearchAction({ botSearchActive: false }));
  yield put(clearSearchEntriesAction({}));
  yield put(resetSearchObjectAction({}));
  yield put(fetchFilterRequestAction({ alias: 'groups-projects' }));
  yield put(showFilterAction({}));
  yield put(setSelectedSearchAction({ searchAlias: 'groups-projects', flowSearch: false }));

  const searchObject = SearchRequestObject.fromRelatedGroupSearch(action.payload.item, action.payload.allCategories);
  yield call(waitFor, (state: { filter: { filterItem: any } }) => state.filter.filterItem);
  yield put(copySearchParamsToSearchAction({ searchObject, lastUpdatedFilter: [] }));
  yield put(fetchFirstPageSearchEntriesRequestAction({}));
  action.payload.history.push(action.payload.target + '?ref=' + action.payload.ref);
}

function* showAllPageElementsWorker(action: ReturnType<typeof showAllPageElements>) {
  const searchObject = SearchRequestObject.fromPageElement(action.payload.pageElement);
  yield call(waitFor, (state: { filter: { filterItem: any } }) => state.filter.filterItem);
  const destinationArray: string[] = [];
  if (action.payload.pageElement.onlyFromUserMemoryList) {
    const types = searchObject.searchTypes;
    if (types && types.length) {
      for (let type of types) {
        const dest = DESTINATIONS_REVERSED[type] || '';
        if (dest && !destinationArray.includes(dest)) {
          destinationArray.push(dest);
        }
      }
    }
  }
  yield put(copySearchParamsToSearchAction({ searchObject: { ...searchObject, queryDestinations: destinationArray }, lastUpdatedFilter: [] }));
  yield put(fetchFirstPageSearchEntriesRequestAction({}));
  action.payload.history.push(action.payload.route + '/search');
}

function* triggerPageElementsDataWorker(action: ReturnType<typeof triggerPageElementDataRequests>) {
  if (!!action.payload.prefix && action.payload.prefix === 'memorylist') {
    const memoryList = yield select(state => state.foundation?.profile?.memoryList);
    if (!memoryList || !memoryList.length) {
      return;
    }
  }
  if (action.payload.pageElements && action.payload.pageElements.length) {
    const dataState = yield select(state => state.data);
    for (let i = 0; i < action.payload.pageElements.length; i++) {
      const id = (action.payload.prefix || '') + i;
      const pageElement = action.payload.pageElements[i];
      if (pageElement.pageElementVisualization__ === PageElementVisualization.BOTSEARCH) {
        continue;
      } else if (!dataState[id]) {
        yield put(fetchPageElementDataRequest({ id, pageElement: pageElement, detail: action.payload.detail }));
      } else {
        yield put(fetchPageElementDataBackgroundRequest({ id, pageElement: pageElement, detail: action.payload.detail }));
      }
    }
  }
}

function* fetchPageElementDataWorker(action: ReturnType<typeof fetchPageElementDataRequest>) {
  try {
    const profile = yield select(state => state.foundation?.profile);
    const pE = action.payload.pageElement;
    if (pE.pageElementVisualization__ === PageElementVisualization.CARD && pE.pageElementCardDesign__ !== PageElementCardDesign.FLAT) {
      const pE_Big: IPageElement = {
        ...pE,
        pageElementMaxResults: Math.floor((pE.pageElementMaxResults || 6) / 3),
        pageElementThingDescriptions: pE.pageElementThingDescriptions?.filter(id => contentTypeCardisBig(id)),
      };
      const pE_Small: IPageElement = {
        ...pE,
        pageElementThingDescriptions: pE.pageElementThingDescriptions?.filter(id => !contentTypeCardisBig(id)),
      };
      const searchRequestObjectBig = SearchRequestObject.fromPageElement(pE_Big, action.payload.detail, profile);
      const searchRequestObjectSmall = SearchRequestObject.fromPageElement(pE_Small, action.payload.detail, profile);
      if (pE.excludeChats) {
        let chats = yield select(state => state.foundation?.chats);
        searchRequestObjectBig.AddExcludedIds(...chats);
        searchRequestObjectSmall.AddExcludedIds(...chats);
      }
      if (pE.excludeGroups) {
        let groups = yield select(state => state.foundation?.groups);
        searchRequestObjectBig.AddExcludedIds(...groups);
        searchRequestObjectSmall.AddExcludedIds(...groups);
      }

      let result: any[] = [];
      let canShowAll = false;
      let totalCount = 0;

      if (pE.onlyFromUserMemoryList) {
        const memoryList = yield select(state => state.foundation?.profile?.memoryList);
        if (memoryList && memoryList.length) {
          const containedIds = memoryList?.map(m => m.elementId);
          searchRequestObjectBig.AddContainedIds(...containedIds);
          searchRequestObjectSmall.AddContainedIds(...containedIds);
        }
      }

      const apiUrl = action.payload.pageElement.pageElementContentSelection__ === PageElementContentSelection.RELATED ? api.search.related : api.search.dynamic;
      // no csrf token needed, post does not manipulate data
      const searchResultBig: ISearchResult = yield callPost(apiUrl, searchRequestObjectBig);
      // no csrf token needed, post does not manipulate data
      const searchResultSmall: ISearchResult = yield callPost(apiUrl, searchRequestObjectSmall);
      let listUsers: { [userId: string]: IListUser } = {};
      if (searchResultBig && searchResultBig.hits && searchResultBig.hits.hits) {
        result = searchResultBig.hits.hits.map((e: any) => {
          e._source._id = e._id;
          return e._source;
        });
        totalCount = searchResultBig.hits.total;
        if (searchResultBig.list_users) {
          listUsers = searchResultBig.list_users;
        }
      }

      if (searchResultSmall && searchResultSmall.hits && searchResultSmall.hits.hits) {
        result = [
          ...result,
          ...searchResultSmall.hits.hits.map((e: any) => {
            e._source._id = e._id;
            e._source._score = e._score;
            return e._source;
          }),
        ];
        totalCount += searchResultSmall.hits.total;
        if (searchResultSmall.list_users) {
          listUsers = { ...listUsers, ...searchResultSmall.list_users };
        }
      }
      if (Object.keys(listUsers).length > 0) {
        yield put(setAvatarAssetsAction({ idAssetDict: listUsers }));
      }
      yield put(fetchPageElementDataSuccess({ id: action.payload.id, items: result, canShowAll, totalCount }));
    } else {
      const searchRequestObject = SearchRequestObject.fromPageElement(pE, action.payload.detail, profile);
      if (pE.excludeChats) {
        let chats = yield select(state => state.foundation?.chats);
        searchRequestObject.AddExcludedIds(...chats);
      }
      if (pE.excludeGroups) {
        let groups = yield select(state => state.foundation?.groups);
        searchRequestObject.AddExcludedIds(...groups);
      }
      let result: any[] = [];
      let canShowAll = false;
      let totalCount = 0;

      if (pE.onlyFromUserMemoryList) {
        const memoryList = yield select(state => state.foundation?.profile?.memoryList);
        if (memoryList && memoryList.length) {
          const containedIds = memoryList?.map(m => m.elementId);
          searchRequestObject.AddContainedIds(...containedIds);
        }
      }

      const apiUrl = pE.pageElementContentSelection__ === PageElementContentSelection.RELATED ? api.search.related : api.search.dynamic;
      // no csrf token needed, post does not manipulate data
      const searchResult: ISearchResult = yield callPost(apiUrl, searchRequestObject);
      if (searchResult && searchResult.hits && searchResult.hits.hits) {
        result = searchResult.hits.hits.map((e: any) => {
          e._source._id = e._id;
          e._source._score = e._score;
          return e._source;
        });
        totalCount = searchResult.hits.total;
        if (searchRequestObject.querySize) {
          canShowAll = totalCount > searchRequestObject.querySize;
        }
        if (pE.onlyFromUserMemoryList) {
          canShowAll = true;
        }
        let listUsers: { [userId: string]: IListUser } = {};
        listUsers = { ...listUsers, ...searchResult.list_users };
        if (Object.keys(listUsers).length > 0) {
          yield put(setAvatarAssetsAction({ idAssetDict: listUsers }));
        }
      }
      yield put(fetchPageElementDataSuccess({ id: action.payload.id, items: result, canShowAll, totalCount }));
    }
  } catch (e) {
    yield put(fetchPageElementDataFailed({ id: action.payload.id }));
  }
}

function* fetchLandingPageElementsWorker() {
  try {
    const result = yield callGet(api.v2.landingpage);
    yield put(fetchLandingPageElementsSuccess({ items: result }));
  } catch (e) {
    yield put(fetchLandingPageElementsFailed());
  }
}

export function* data() {
  yield all([watcher()]);
}

function* waitFor(selector) {
  if (yield select(selector)) return; // return if selector true
  while (true) {
    yield take('*'); // wait with empty task
    if (yield select(selector)) return; // return if selector true
  }
}
