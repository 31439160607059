import React from 'react';
import { Grid } from '@material-ui/core';
import { Bx, LinearLoader, Typo } from '@curry-group/mui-curcuma';
import { FramedContent } from '../../../../../../components/framed-content';

export class FinishLoader extends React.Component<{ mode?: 'boxed' | 'framed'; headline?: string; text?: string; interval?: number }, { progress: number }> {
  state = {
    progress: 0
  };

  progressInterval;

  componentDidMount() {
    this.progressInterval = setInterval(() => {
      this.setProgress();
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.progressInterval);
  }

  setProgress = () => {
    const { progress } = this.state;

    const rest = (100 - progress) / 100;
    const diff = rest * Math.random() * 40;
    this.setState({ ...this.state, progress: Math.min(progress + diff, 100) });
  };

  render(): React.ReactNode {
    let { headline, text, mode } = this.props;
    const done = false;
    const { progress } = this.state;

    mode = mode ?? 'boxed';
    headline = headline ?? `Fertig! Los geht's...`;

    const loaderMainContent = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src="/images/finish.svg" alt="Fertig! Los geht's..." />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={6}>
              <Typo variant="h1" align="center">
                {headline}
              </Typo>
              {text && (
                <Typo align="center" variant="subtitle1">
                  {text}
                </Typo>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={8} md={6} lg={4} xl={3}>
              <Bx mt={2}>
                <LinearLoader variant="determinate" value={!done ? progress : 100} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    if (mode === 'framed') {
      return (
        <FramedContent display="flex" alignItems="center" height="100%" innerBoxProps={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
          {loaderMainContent}
        </FramedContent>
      );
    } else {
      return (
        <Bx gridArea="content" display="flex" alignItems="center" justifyContent="center" height="100%">
          {loaderMainContent}
        </Bx>
      );
    }
  }
}
