import { fetchPost } from '@curry-group/data-addons';
import React, { useEffect } from 'react';
import { api } from '../../../data/api';

export const OauthComponent = () => {
  useEffect(() => {
    const integratedLogin = async () => {
      const fragment = decodeURIComponent(window.location.hash);
      const result = /.*id_token=([^&]*)/.exec(fragment);
      if (result) {
        const nonce = JSON.parse(atob(result[1].split('.')[1])).nonce;
        const lsNonce = localStorage.mto_oauth_nonce;
        localStorage.removeItem('mto_oauth_nonce');
        if (nonce !== lsNonce) {
          localStorage.mto_oauth_success = -1;
          window.close();
          return;
        }
        try {
          await fetchPost(api.auth.integratedLogin, { type: 'oauth', provider: 'medtec', token: result[1], persist: !!localStorage.mto_oauth_persist });
          localStorage.removeItem('mto_oauth_persist');
          localStorage.mto_oauth_success = 1;
        } catch (e) {
          console.log(e);
          localStorage.mto_oauth_success = -1;
        }
        window.close();
      }
    };
    integratedLogin();
  }, []);
  return <div>Bitte warten</div>;
};
