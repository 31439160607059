import { Anohana, Bx, ImageGallery, ImageGalleryDetailView, Typo } from '@curry-group/mui-curcuma';
import { IImageGalleryEventProps, IImageGalleryProps } from '@curry-group/mui-curcuma/dist/ui/DataDisplay/ImageGallery';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/pro-light-svg-icons';

export const MessageImageGallery: React.FC<IImageGalleryProps> = args => {
  const [selected, setSelected] = useState<IImageGalleryEventProps | undefined>();
  const [open, setOpen] = useState(false);

  function handleClick(e: IImageGalleryEventProps) {
    setSelected(e);
    setOpen(true);
  }

  return (
    <>
      <div style={{ display: 'flex', margin: 25 }}>
        <div>
          <ImageGallery
            maxImages={4}
            images={args.images || []}
            imageOnClick={e => handleClick(e)}
            moreOverlay={{
              content: (
                <Bx display="flex" alignItems="center" justifyContent="center" p={2} flexDirection="column" height="100%">
                  <Bx mb={0.5}>
                    <Typo variant="h2">
                      <FontAwesomeIcon icon={faImages} />
                    </Typo>
                  </Bx>
                  <Typo variant="body2">%%_NUMBER_%% weitere</Typo>
                </Bx>
              ),
              bgcolor: 'secondary.main',
              bgcolorHover: 'secondary.dark',
              color: 'secondary.contrastText',
              colorHover: 'secondary.contrastText',
              opacity: 0.5,
              opacityHover: 0.75,
              backdropFilter: 'blur(7px)',
              backdropFilterHover: 'blur(7px)',
              onClick: handleClick
            }}
          />
        </div>
      </div>

      <Anohana fullScreen transition="zoom" open={open} onClose={() => setOpen(false)}>
        {selected?.current && <ImageGalleryDetailView showTitle showPaginationCount onClose={() => setOpen(false)} images={selected} />}
      </Anohana>
    </>
  );
};
