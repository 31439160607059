import React, { useState } from 'react';

import { Anohana, Bx, Btn, IconBtn } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FormWorkgroupInvitation } from '../form/compositions/FormWorkgroupInvitation';
import { useDispatch } from 'react-redux';
import { userprofileCommunicationCreateRequest } from '../../data/reducer/userprofile';
import { HeadlineContainer } from '../headline-container';

export const CommunicationInviteModal: React.FC<{ userId?: string; close?: () => void }> = ({ userId, close }) => {
  const [data, setData] = useState<{ itemData?: { invitation?: string } } | null>(null);
  const dispatch = useDispatch();

  return (
    <Anohana open={!!userId} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={'Anfragen'}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={
          <IconBtn
            size="small"
            onClick={() => {
              setData(null);
              close?.();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconBtn>
        }
      />
      <Bx py={3} px={3}>
        <FormWorkgroupInvitation headline="" subtitle="Bitte geben Sie Ihren Einladungstext ein" formData={data} onChange={setData} />
        <Bx display="flex" justifyContent="flex-end" alignItems="center">
          <Btn
            onClick={() => {
              if (userId) {
                close?.();
                dispatch(userprofileCommunicationCreateRequest({ userId, message: data?.itemData?.invitation || '' }));
                setData(null);
              }
            }}
            disabled={(data?.itemData?.invitation || '').length < 5}
            color="secondary"
            size="large"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faArrowToRight} />}
          >
            Absenden
          </Btn>
        </Bx>
      </Bx>
    </Anohana>
  );
};
