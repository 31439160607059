import React, { ReactNode, useEffect, useState } from 'react';
import {
  Anohana,
  Btn,
  BtnList,
  Bx,
  Collapse,
  Divider,
  ExtendedListItem,
  Html2View,
  IconBtn,
  IUnsortedListProps,
  LayoutImage,
  Link,
  Typo,
  UnsortedList,
  GatedContent
} from '@curry-group/mui-curcuma';
import { Chip, Fade, Hidden, useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { IContentAuthorProps, ContentAuthor } from '../../content-author';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCheckCircle, faChevronDown, faChevronUp, faClock, faLockAlt, faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { faMask } from '@fortawesome/pro-solid-svg-icons';
import { ListWidget } from '../../widget/list';
import { IListItem } from '../../../model/list/ListItem';
import { copyValueToClipboard, cropHTML, getDetailRoute, IDictionary, resolveInterval, switchLinkHandler } from '../../../helper';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { IImage } from '../../../model/data/Detail';
import { ICategoryItem } from '../../../model/ryve/Category';
import { IMemoryListItem, IThing, ThingTypes } from '../../../model/ryve/Thing';
import { IContainerConfig } from '../../../model/configuration';
import { IDetailItemContent } from '../../../model/detail';
import { useDispatch, useSelector } from 'react-redux';
import { FramedContent } from '../../framed-content';
import { ContentInteractionBarAnon } from '../../content-interaction-bar';
import { ContentSeriesBar } from '../../content-series-bar';
import dayjs from 'dayjs';
import { setFlowDataAction } from '../../../data/actions/flow';
import { userprofileMemorylistRequestAction } from '../../../data/actions/foundation';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface IDetailContentProps {
  working?: boolean;
  anonymousAccess?: boolean;

  top?: ReactNode;

  keyvisual?: IImage;

  appconfig?: IDictionary<IContainerConfig>;
  parentThing?: IThing<IDetailItemContent>;

  type: string;
  timestamp?: string | number;
  interval?: { dateFrom?: number; dateTo?: number };
  readingTime?: number;
  title: string;
  subtitle?: string;
  anonymousPublish?: boolean;
  authors?: Array<IContentAuthorProps>;

  contentInteractionBar?: ReactNode;
  commentCount?: number;

  series?: {
    episode?: number,
    previous?: {
      title: string,
      link: string
    },
    next?: {
      title: string,
      link: string
    }
  }

  mainPoints?: Array<string>;
  html: string;
  contentCollapsable?: boolean;
  additionalParagraphTitle?: string;
  additionalParagraphs?: Array<{ title: string; content: string }>;
  referenceItems?: Array<
    Array<{
      title: string;
      replaceLinks?: string;
      items: Array<IListItem>;
      initialMaxItems?: number;
      emptyCaption?: string;
      itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE') => void;
      shareClick?: (detailItem) => void;
    }>
  >;
  relatedItems?: Array<{ title: string; items: Array<IListItem> }>;
  moreRelatedAvailable?: boolean;
  relatedWorking?: boolean;

  categories?: ICategoryItem[];

  isPublic?: boolean;
  isHidden?: boolean;

  isScrolled?: boolean;
  setIsScrolled?: (isScrolled: any) => void;

  loadRelatedItems?: () => void;

  afterAuthorContent?: ReactNode;
  cancelled?: boolean;
  meetingEnded?: boolean;
  meetingUpdateMessage?: string;
  meetingCancelledMessage?: string;

  botAnswer?: string;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const DetailContentComponent: React.FC<IDetailContentProps> = ({
  additionalParagraphs,
  additionalParagraphTitle,
  afterAuthorContent,
  anonymousAccess,
  anonymousPublish,
  appconfig,
  authors,
  children,
  cancelled,
  meetingEnded,
  meetingUpdateMessage,
  meetingCancelledMessage,
  commentCount,
  contentCollapsable,
  contentInteractionBar,
  html,
  interval,
  isHidden,
  isPublic,
  isScrolled,
  keyvisual,
  loadRelatedItems,
  mainPoints,
  moreRelatedAvailable,
  parentThing,
  readingTime,
  referenceItems,
  relatedItems,
  relatedWorking,
  series,
  setIsScrolled,
  subtitle,
  timestamp,
  categories,
  top,
  type,
  title,
  working,
  botAnswer
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [expanded, setExpanded] = useState(false);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const auth = useSelector(state => state.foundation.auth);
  const [scrolledFromLink, setScrolledFromLink] = useState(0);
  const [scrolledToContainer, setScrolledToContainer] = useState<null | HTMLElement>(null);

  const [linkCopySuccessModalOpen, setLinkCopySuccessModalOpen] = useState(false);
  const copyPathToClipboard = () => {
    var value = window.location.href;
    copyValueToClipboard(value);
    setLinkCopySuccessModalOpen(true);
  }
  const closeModal = () => {
    setLinkCopySuccessModalOpen(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    if (document.documentElement.scrollTop < 450) {
      if (isScrolled) setIsScrolled?.(false);
    } else if (!isScrolled) setIsScrolled?.(true);
  }

  const handleClickScroll = (anchor: string) => {
    const cleanAnchor = anchor.replaceAll('%3A', ':');
    const element = document.getElementById(cleanAnchor);
    setScrolledFromLink(document.documentElement.scrollTop);
    if (element) {
      const scrollToContainer = element.parentElement?.parentElement?.parentElement;
      if (scrollToContainer) {
        scrollToContainer.style.background = '#80CDEC';
        setScrolledToContainer(scrollToContainer);
      }
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleBgImageLoaded = () => {
    !bgImageLoaded && setBgImageLoaded(true);
  }

  const date = new Date(timestamp || 0);
  const parentLink = getDetailRoute({ typeId: parentThing?.type || '', alias: parentThing?.seo?.alias }, appconfig || {}, location);

  const mainPointsResolved: IUnsortedListProps['items'] = mainPoints?.map(mP => {
    return {
      icon: (
        <Typo color="primary" variant="body1boosted">
          <FontAwesomeIcon icon={faCheckCircle} />
        </Typo>
      ),
      children: <Typo variant="body1boosted">{mP}</Typo>
    };
  });

  const anonymousGroupWithoutRefs = anonymousAccess
    && (type === 'Arbeitsgruppe' || type === 'Projekt')
    && (!referenceItems?.[0]?.[0]?.items || referenceItems?.[0]?.[0]?.items.length === 0);

  
  const dispatcher = {
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  return (
    <React.Fragment>
      {!!keyvisual && (
        <Fade in={bgImageLoaded}>
          <div>
            <LayoutImage
              src={keyvisual?.src}
              alt={keyvisual?.alt}
              position="absolute"
              top={0}
              left={0}
              right={0}
              width="100%"
              height={685}
              zIndex={-1}
              bgcolor={[
                { bgcolor: 'transparent', position: 0 },
                { bgcolor: 'background.default', position: 100 }
              ]}
              gradientDirection="to bottom"
              objectFit="cover"
              onLoadCallback={handleBgImageLoaded}
            />
          </div>
        </Fade>
      )}
      <FramedContent position="relative" zIndex={1} ctnMaxWidth={anonymousAccess ? 'lg' : undefined}>
        <Bx>
          {!working && (
            <Bx>
              {top}
              <Bx bgcolor="background.paper" borderRadius="borderRadius2" mb={3} py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }}>
                <Bx>
                  {/* Head */}
                  <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
                    {type && (
                      <Bx>
                        <Typo variant="subtitle2" component="span" textTransform="uppercase">
                          {type +
                            (parentThing?.content.title
                              ? parentThing?.type === ThingTypes.Group
                                ? ' in der Gruppe '
                                : parentThing?.type === ThingTypes.Project
                                ? ' in dem Projekt '
                                : ' von '
                              : '')}
                        </Typo>
                        {parentThing?.content.title && (
                          <Link to={parentLink || '/'} underline="hover">
                            <Typo variant="subtitle2" component="span" textTransform="uppercase">
                              {parentThing?.content.title}
                            </Typo>
                          </Link>
                        )}
                      </Bx>
                    )}
                    <Bx display="flex">
                      {(type === 'Arbeitsgruppe' || type === 'Projekt') && !anonymousAccess && (
                        <React.Fragment>
                          {isPublic && (
                            <Bx mr={0.5} color="text.primary" mt={-0.25}>
                              <FontAwesomeIcon icon={faLockOpen} title="Offen und gelistet" />
                            </Bx>
                          )}
                          {!isPublic && !isHidden && (
                            <Bx mr={0.5} color="text.primary" mt={-0.25}>
                              <FontAwesomeIcon icon={faLockAlt} title="Geschlossen und gelistet" />
                            </Bx>
                          )}
                          {!isPublic && isHidden && (
                            <Bx mr={0.5} color="text.primary" mt={-0.25}>
                              <FontAwesomeIcon icon={faMask} title="Geschlossen und unsichtbar" />
                            </Bx>
                          )}
                        </React.Fragment>
                      )}
                      {timestamp && (
                        <Bx>
                          <Typo variant="subtitle2" component="span">
                            <Hidden smDown>{'Aktualisiert am ' + dayjs(date).format('DD.MM.YYYY')}</Hidden>
                            <Hidden mdUp>{dayjs(date).format('DD.MM.YYYY')}</Hidden>
                          </Typo>
                        </Bx>
                      )}
                      {readingTime && (
                        <Bx pl={3}>
                          <Typo variant="subtitle2" component="span">
                            <FontAwesomeIcon icon={faClock} />
                            {' ' + Math.floor(readingTime)} Minuten
                          </Typo>
                        </Bx>
                      )}
                    </Bx>
                  </Bx>

                  {series && (
                    <Bx mb={2} mx={{ xs: -3, md: -7.5 }}>
                      <ContentSeriesBar
                        {...series}
                      />
                    </Bx>
                  )}

                  <Bx mb={subtitle ? 2 : 0.5}>
                    <Typo variant={isMdUp ? 'h1boosted' : 'h1'} component="h1">
                      {title}
                    </Typo>
                  </Bx>
                  {subtitle && !cancelled && (
                    <Bx mb={2} fontSize={isMdUp ? 'h5boosted' : 'h5'} lineHeight={1.5}>
                      <Typo variant="body1boosted">{subtitle}</Typo>
                    </Bx>
                  )}
                  {subtitle && cancelled && (
                    <Bx mb={2} lineHeight={1.5}>
                      <Typo color="error.light" variant={isMdUp ? 'h2boosted' : 'h2'}>
                        {subtitle}
                      </Typo>
                    </Bx>
                  )}
                  {interval?.dateFrom && (
                    <Bx mb={2} fontSize={isMdUp ? 'h5boosted' : 'h5'} lineHeight={1.5}>
                      <Typo color={cancelled ? 'error.light' : undefined}>{resolveInterval(interval.dateFrom, interval?.dateTo)}</Typo>
                    </Bx>
                  )}

                  {/* Author */}
                  <Bx mb={4} display="flex" justifyContent="flex-start" flexDirection={{ xs: 'column', md: 'row' }} flexWrap="wrap">
                    {!anonymousPublish &&
                      authors?.map((author, idx) => (
                        <Bx mr={5} key={idx}>
                          <ContentAuthor {...author} />
                        </Bx>
                      ))}
                    {anonymousPublish && (
                      <ExtendedListItem>
                        <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                          <Typo fontWeight={500} variant="body1" component="span">
                            Anonym
                          </Typo>
                        </Bx>
                      </ExtendedListItem>
                    )}
                  </Bx>

                  {categories && !!categories.length && (
                    <Bx mb={6}>
                      <BtnList>
                        {categories.map((c, i) => (
                          <Chip key={c._id} variant="outlined" size="medium" label={c.name} />
                        ))}
                      </BtnList>
                    </Bx>
                  )}

                  {!!afterAuthorContent && afterAuthorContent}

                  {/* Meta Buttons */}
                  {!!contentInteractionBar && <Bx mb={4}>{contentInteractionBar}</Bx>}
                  {anonymousAccess && (
                    <Bx mb={4}>
                      <ContentInteractionBarAnon shareClick={copyPathToClipboard} numComments={commentCount} isGroup={type === 'Arbeitsgruppe' || type === 'Projekt'} />
                    </Bx>
                  )}
                </Bx>

                {mainPointsResolved && mainPointsResolved.length && (
                  <Bx bgcolor="accent.light" py={3.5} mb={5.5} mx={{ xs: -3, md: -7.5 }}>
                    <Hidden smDown>
                      <Bx px={7.5}>
                        <UnsortedList items={mainPointsResolved} />
                      </Bx>
                    </Hidden>
                    <Hidden mdUp>
                      <Bx px={2}>
                        <UnsortedList items={mainPointsResolved} />
                      </Bx>
                    </Hidden>
                  </Bx>
                )}

                <Bx bgcolor="background.paper" borderRadius="borderRadius2">
                  
                  {(!!cancelled && !!meetingCancelledMessage) && (
                    <Bx mb={2} lineHeight={1.5}>
                      <Typo color="error.light" variant={isMdUp ? 'h4boosted' : 'h4'}>
                        {meetingCancelledMessage}
                      </Typo>
                    </Bx>
                  )}

                  {!botAnswer && contentCollapsable && html && !!cropHTML(html, 2)?.[1] && (
                    <Bx>
                      <Collapse expanded={expanded} preview={<Html2View boosted={isMdUp}>{cropHTML(html, 2)[0]}</Html2View>}>
                        <Html2View boosted={isMdUp}>
                          {cropHTML(html, 2)[1]}
                        </Html2View>
                      </Collapse>
                      <Bx display="flex" alignItems="baseline">
                        <Bx flexGrow={1} flexShrink={1}>
                          <Divider />
                        </Bx>
                        <Bx ml={2.5}>
                          <Btn
                            noXPadding
                            size="small"
                            endIcon={<FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />}
                            variant="text"
                            aria-expanded={expanded}
                            onClick={() => setExpanded(!expanded)}
                          >
                            {expanded ? 'weniger lesen' : 'mehr lesen'}
                          </Btn>
                        </Bx>
                      </Bx>
                    </Bx>

                  )}
                  {!botAnswer && html && (!contentCollapsable || !cropHTML(html, 2)?.[1]) && (
                    <Bx>
                      <Html2View boosted={isMdUp}
                        switchLinkHandler={switchLinkHandler}
                        anchorClickHandler={(anchor: string) => handleClickScroll(anchor)}
                        rootDomain={window.location.protocol + '//' + window.location.host}
                      >
                        {html}
                      </Html2View>
                    </Bx>
                  )}
                  {!!botAnswer &&
                  <Bx>
                    <Typo component="label" variant="body2" fontWeight={500}>
                      {'Bot-Antwort'}
                    </Typo>
                    <Bx
                      mt={2}
                      style={{
                        border: '1px solid transparent',
                        borderRadius: 16,
                        background:'rgb(230, 245, 251)',
                        padding:'16px'
                      }}
                    >
                      <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>
                    </Bx>
                  </Bx>}

                  {additionalParagraphTitle && (
                    <Bx mb={2.5} mt={5}>
                      <Typo variant={isMdUp ? 'h1boosted' : 'h1'} component="h1">
                        {additionalParagraphTitle}
                      </Typo>
                    </Bx>
                  )}

                  {additionalParagraphs &&
                    additionalParagraphs.length > 0 &&
                    additionalParagraphs.map((aP, idx) => (
                      <Bx
                        bgcolor="background.paper"
                        borderColor="divider"
                        mt={additionalParagraphs.length > 1 ? 0 : 3.5}
                        pt={additionalParagraphs.length > 1 ? 3 : 3.5}
                        pb={additionalParagraphs.length > 1 ? 2 : 0}
                        border={1}
                        borderLeft={0}
                        borderRight={0}
                        borderBottom={0}
                        key={idx}
                      >
                        <Typo variant={additionalParagraphs.length > 1 ? (isMdUp ? 'h4boosted' : 'h4') : isMdUp ? 'h2boosted' : 'h2'} component="p">
                          {aP.title}
                        </Typo>
                        <Html2View boosted
                          fontStyle="italic"
                          switchLinkHandler={switchLinkHandler}
                          anchorClickHandler={(anchor: string) => handleClickScroll(anchor)}
                          rootDomain={window.location.protocol + '//' + window.location.host}
                        >
                          {aP.content}
                        </Html2View>
                      </Bx>
                    ))}
                </Bx>

                {((anonymousAccess && type !== 'Sammlung' && type !== 'Arbeitsgruppe' && type !== 'Projekt') || anonymousGroupWithoutRefs) && (
                  <Bx mt={2}>
                    <GatedContent
                      gradientColor="divider"
                      classes={{
                        container: anonymousGroupWithoutRefs ? 'position-relative overflow-hidden' : 'position-relative overflow-hidden mt-m10',
                        text: 'pt-10 gated-content-gradient',
                        textContainer: 'position-absolute inset-0',
                        children: anonymousGroupWithoutRefs ? 'position-relative' : 'position-relative pt-10'
                      }}
                      style={anonymousGroupWithoutRefs ? {marginTop:'-10px'} : {}}
                    >
                      <div className="bgc-primary-main text-color-primary-contrastText border-radius-borderRadius lg:p-4 p-2 box-shadow-5">
                        <div className="d-flex">
                          <div className="mr-2 position-relative xs:d-none md:d-block" style={{ flex: '0 0 25%' }}>
                            <img className="position-absolute inset-0" style={{ width: '100%', maxHeight: '100%' }} src="/images/welcome.svg" alt="Register Teaser" />
                          </div>
                          <div style={{ flex: '1 1' }}>
                            <strong className="text-variant-h3 font-weight-normal mb-1 lg:mb-1-5 d-inline-block">
                              {type === 'Arbeitsgruppe' || type === 'Projekt' ? 'Sie möchten mitdiskutieren?' : 'Sie möchten weiterlesen?'}
                            </strong>
                            <p className="mb-0 mt-0 text-variant-body1">
                              Registrieren Sie sich kostenfrei auf Medtec Online und werden Sie Teil der neuen Fach-Community für ein erfolgreiches Zusammenspiel von
                              Medizintechnik, Innovation und Versorgung!
                            </p>
                            <div className="d-flex flex-direction-column sm:flex-direction-row justify-content-flex-end mt-2 lg:mt-4" style={{ gap: 8 }}>
                              <Btn
                                color="secondary"
                                variant="contained"
                                size="large"
                                onClick={() => {
                                  history.push('/optin');
                                }}
                              >
                                Jetzt registrieren
                              </Btn>
                              <Btn
                                color="default"
                                variant="outlined"
                                size="large"
                                onClick={() => {
                                  history.push('/login');
                                }}
                                style={{ backgroundColor: '#fff' }}
                              >
                                Anmelden
                              </Btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </GatedContent>
                  </Bx>
                )}
              </Bx>

              {referenceItems &&
                !!referenceItems.length &&
                referenceItems
                  .filter(rICollection => rICollection.length)
                  .map((rICollection, index) => {
                    return (
                      <Bx key={index} bgcolor="background.paper" borderRadius="borderRadius2" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mb={3}>
                        {rICollection.map((rI, idx) => (
                          <Bx key={idx} mb={idx !== rICollection.length - 1 ? 6 : undefined}>
                            <ListWidget
                              key={idx}
                              headline={rI.title}
                              replaceLinks={rI.replaceLinks}
                              items={rI.items.slice(0, expanded ? undefined : rI.initialMaxItems)}
                              ignoreWorking={true}
                              emptyCaption={rI.emptyCaption}
                              listType={'references'}
                              shareClick={rI.shareClick}
                              itemMemoClicked={rI.itemMemoClicked}
                            />
                            {rI.initialMaxItems && rI.initialMaxItems < rI.items.length && (
                              <Bx display="flex" alignItems="baseline">
                                <Bx flexGrow={1} flexShrink={1} />
                                <Bx ml={2.5}>
                                  <Btn
                                    noXPadding
                                    size="small"
                                    endIcon={<FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />}
                                    variant="text"
                                    aria-expanded={expanded}
                                    onClick={() => setExpanded(!expanded)}
                                  >
                                    {expanded ? 'weniger anzeigen' : `alle ${rI.items.length} anzeigen`}
                                  </Btn>
                                </Bx>
                              </Bx>
                            )}
                          </Bx>
                        ))}
                        {anonymousAccess && referenceItems?.[0] && !!referenceItems?.[0].length && (
                          <GatedContent
                            gradientColor="divider"
                            classes={{
                              container: 'position-relative overflow-hidden mt-m10',
                              text: 'pt-10 gated-content-gradient',
                              textContainer: 'position-absolute inset-0',
                              children: 'position-relative'
                            }}
                            style={{marginTop:'-10px'}}
                          >
                            <div className="bgc-primary-main text-color-primary-contrastText border-radius-borderRadius lg:p-4 p-2 box-shadow-5">
                              <div className="d-flex">
                                <div className="mr-2 position-relative xs:d-none md:d-block" style={{ flex: '0 0 25%' }}>
                                  <img className="position-absolute inset-0" style={{ width: '100%', maxHeight: '100%' }} src="/images/welcome.svg" alt="Register Teaser" />
                                </div>
                                <div style={{ flex: '1 1' }}>
                                  <strong className="text-variant-h3 font-weight-normal mb-1 lg:mb-1-5 d-inline-block">
                                    {type === 'Arbeitsgruppe' || type === 'Projekt' ? 'Sie möchten mitdiskutieren?' : 'Sie möchten weiterlesen?'}
                                  </strong>
                                  <p className="mb-0 mt-0 text-variant-body1">
                                    Registrieren Sie sich kostenfrei auf Medtec Online und werden Sie Teil der neuen Fach-Community für ein erfolgreiches Zusammenspiel von
                                    Medizintechnik, Innovation und Versorgung!
                                  </p>
                                  <div className="d-flex flex-direction-column sm:flex-direction-row justify-content-flex-end mt-2 lg:mt-4" style={{ gap: 8 }}>
                                    <Btn
                                      color="secondary"
                                      variant="contained"
                                      size="large"
                                      onClick={() => {
                                        history.push('/optin');
                                      }}
                                    >
                                      Jetzt registrieren
                                    </Btn>
                                    <Btn
                                      color="default"
                                      variant="outlined"
                                      size="large"
                                      onClick={() => {
                                        history.push('/login');
                                      }}
                                      style={{ backgroundColor: '#fff' }}
                                    >
                                      Anmelden
                                    </Btn>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </GatedContent>
                        )}
                      </Bx>
                    );
                  })}
              {relatedItems &&
                !!relatedItems.length &&
                relatedItems.map((rI, idx) => {
                  if (rI.items.length) {
                    return (
                      <Bx key={idx} bgcolor="background.paper" borderRadius="borderRadius2" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mb={3}>
                        <ListWidget
                          replaceLinks={anonymousAccess ? '/optin' : undefined}
                          items={rI.items}
                          headline={rI.title}
                          ignoreWorking={true}
                          shareClick={dispatcher.shareClick}
                          itemMemoClicked={(item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }))}
                          memoryList={false}
                        />
                      </Bx>
                    );
                  } else return null;
                })}
            </Bx>
          )}

          {working && (
            <Bx bgcolor="background.paper" borderRadius="borderRadius2" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }}>
              {/* Type and Date */}
              <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
                <Bx>
                  <Typo variant="subtitle2" component="span" textTransform="uppercase">
                    <Skeleton animation="wave" width="152px" />
                  </Typo>
                </Bx>
              </Bx>
              {/* Headline H1 */}
              <Bx mb={3.5}>
                <Typo variant="h1" component="h1">
                  <Skeleton animation="wave" width="80%" />
                  <Skeleton animation="wave" width="60%" />
                </Typo>
              </Bx>

              {/* Meta Buttons */}
              <Bx mb={4}>
                <Bx borderColor="divider" border={1} borderLeft={0} borderRight={0} height={74}>
                  <Bx display="flex" alignItems="center" height="100%" py={0.5}>
                    <Bx display="flex" justifyContent="center" alignItems="center">
                      <Hidden smDown>
                        <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                          <Skeleton animation="wave" width="150px" />
                        </Btn>
                      </Hidden>
                      <Hidden mdUp>
                        <IconBtn color="default" size="medium" variant="contained" shape="rounded">
                          <Skeleton animation="wave" width="30px" />
                        </IconBtn>
                      </Hidden>
                    </Bx>
                    <Bx ml={1} display="flex" justifyContent="center" alignItems="center">
                      <Hidden smDown>
                        <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                          <Skeleton animation="wave" width="80px" />
                        </Btn>
                      </Hidden>
                      <Hidden mdUp>
                        <IconBtn color="default" size="medium" variant="contained" shape="rounded">
                          <Skeleton animation="wave" width="30px" />
                        </IconBtn>
                      </Hidden>
                    </Bx>
                  </Bx>
                </Bx>
              </Bx>
              {/* Content */}
              <Html2View boosted={isMdUp} loading />
            </Bx>
          )}
          {children}
        </Bx>
        {!!keyvisual && keyvisual?.copyright && (
          <Bx>
            <Typo variant="subtitle2">{'Hintergrundbild: ' + keyvisual?.copyright}</Typo>
          </Bx>
        )}
        {auth && (
          <Fade in={isScrolled}>
            <Bx position="fixed" bottom={theme.spacing(4)} right={theme.spacing(4)}>
              <Btn
                color="primary"
                size="large"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faArrowUp} />}
                onClick={() => {
                  if (scrolledFromLink > 0) {
                    window.scrollTo({ top: scrolledFromLink, behavior: 'smooth' });
                    setScrolledFromLink(0);
                    if (!!scrolledToContainer) {
                      scrolledToContainer.style.background = 'none';
                      setScrolledToContainer(null);
                    }
                  } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                }}
              >
                {scrolledFromLink > 0 ? 'zurück' : 'zum Seitenanfang'}
              </Btn>
            </Bx>
          </Fade>
        )}

        <Anohana
          open={linkCopySuccessModalOpen}
          transition="zoom"
          disableScrollLock
          fullWidth
          maxWidth="sm"
          onClose={closeModal}
          style={{zIndex:1}}
        >
          <Bx p={2.5}>
            <Bx display="flex" justifyContent="center" mb={3} mt={3}>
              <Typo variant="h4" component="span">
                Der Link wurde in der Zwischenablage gespeichert.
              </Typo>
            </Bx>
          </Bx>
        </Anohana>
      </FramedContent>
    </React.Fragment>
  );
};

export const DetailWorkingComponent: React.FC = () => {
  return (
    <React.Fragment>
      <FramedContent position="relative" zIndex={1}>
        <Bx>
          <Bx bgcolor="background.paper" borderRadius="borderRadius2" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }}>
            {/* Type and Date */}
            <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Bx>
                <Typo variant="subtitle2" component="span" textTransform="uppercase">
                  <Skeleton animation="wave" width="152px" />
                </Typo>
              </Bx>
            </Bx>
            {/* Headline H1 */}
            <Bx mb={3.5}>
              <Typo variant="h1" component="h1">
                <Skeleton animation="wave" width="80%" />
                <Skeleton animation="wave" width="60%" />
              </Typo>
            </Bx>
            {/* Meta Buttons */}
            <Bx mb={4}>
              <Bx borderColor="divider" border={1} borderLeft={0} borderRight={0} height={74}>
                <Bx display="flex" alignItems="center" height="100%" py={0.5}>
                  <Bx display="flex" justifyContent="center" alignItems="center">
                    <Hidden smDown>
                      <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                        <Skeleton animation="wave" width="150px" />
                      </Btn>
                    </Hidden>
                    <Hidden mdUp>
                      <IconBtn color="default" size="medium" variant="contained" shape="rounded">
                        <Skeleton animation="wave" width="30px" />
                      </IconBtn>
                    </Hidden>
                  </Bx>
                  <Bx ml={1} display="flex" justifyContent="center" alignItems="center">
                    <Hidden smDown>
                      <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                        <Skeleton animation="wave" width="80px" />
                      </Btn>
                    </Hidden>
                    <Hidden mdUp>
                      <IconBtn color="default" size="medium" variant="contained" shape="rounded">
                        <Skeleton animation="wave" width="30px" />
                      </IconBtn>
                    </Hidden>
                  </Bx>
                </Bx>
              </Bx>
            </Bx>
            {/* Content */}
            <Html2View boosted loading />
          </Bx>
        </Bx>
      </FramedContent>
    </React.Fragment>
  );
};
