import { Bx, TCurcumaColors } from "@curry-group/mui-curcuma";
import useTheme from '@material-ui/core/styles/useTheme';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface IBadgeProps {
    children: React.ReactNode | React.ReactNode[] | string | number;
    color?: TCurcumaColors;
    sidebar?: boolean;
}


const useStyles = makeStyles(theme => (
        {
            badge: {
                borderRadius: 16,
                padding: '2px 8px',
                textTransform: 'lowercase',
                display: 'inline !important',
            },
            smallBadge: {
                padding: '1px 4px',
                fontSize: '0.65rem',
                borderRadius: 8,
            }
        }
    ),
    {
        name: 'MTO',
        meta: 'Badge'
    }
);

export const Badge: React.FunctionComponent<IBadgeProps> = ({ children, sidebar }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Bx
            className={clsx(classes.badge, sidebar && classes.smallBadge)}
            style={{ color: theme.palette.background.paper, backgroundColor: theme.palette.secondary.main }}
        >
            {children}
        </Bx>
    );
}
