import { Anohana, Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import React, { useState } from 'react';
import { FormInput } from '../../form/controls/input';

export const CancelAcceptInputDialog: React.FC<{
  headerText?: string;
  labelText?: string;
  placeholderText?: string;
  cancelButtonText?: string;
  acceptButtonText?: string;
  open: boolean;
  onCanceled?: () => void;
  onAccepted?: (value?: string) => void;
}> = ({ headerText, labelText, placeholderText, cancelButtonText, acceptButtonText, open, onCanceled, onAccepted }) => {
  const [acceptText, setAcceptText] = useState('');
  return (
    <Anohana open={!!open} transition="zoom" disableScrollLock fullWidth maxWidth="sm" onClose={onCanceled}>
      <Bx p={2.5}>
        <Bx justifyContent="center" mb={3}>
          <Typo variant="h4" component="span">
            {headerText || 'Bitte bestätigen Sie.'}
          </Typo>
        </Bx>
        <Bx mb={3}>
          <FormInput
            noLengthWarning
            multiline
            label={labelText || 'Bestätigungsgrund'}
            placeholder={placeholderText || 'Bitte Grund angeben ...'}
            value={acceptText}
            onChange={setAcceptText}
          />
        </Bx>
        <Bx display="flex" mt={3} justifyContent="flex-end">
          <Bx mr={3}>
            <Btn variant="outlined" size="large" onClick={onCanceled}>
              {cancelButtonText || 'Abbruch'}
            </Btn>
          </Bx>
          <Bx>
            <Btn
              variant="contained"
              color="success"
              size="large"
              onClick={() => {
                onAccepted?.(acceptText);
              }}
            >
              {acceptButtonText || 'Bestätigen'}
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
