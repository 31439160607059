import { Ava, Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import { faCheckCircle, faEllipsisH, faQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { assetUrl, TYPES, TYPES_PROPS, updateArrayObjectSelection, updateArraySelection, hashCode, ListAvatarColors } from '../../../helper';
import { buildAbbreviation } from '../../../helper/user';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { FramedContent } from '../../framed-content';
import { faHourglassClock } from '@fortawesome/pro-solid-svg-icons';
import Badge from '@material-ui/core/Badge';
import { CardHeader } from '../../card/header';

const GroupUserSelect: React.FC<{ props: any; result: any; working?: boolean; first?: boolean; asControl: boolean; selectionOptional: boolean }> = ({
  props,
  result,
  working,
  first,
  asControl = false,
  selectionOptional = false
}) => {
  const isMobile = useIsMobile();
  if (!selectionOptional) {
    props.setNextStepDisabled(!props.flowData?.selectedUserItems?.length);
  } else {
    props.setNextStepDisabled(false);
  }
  function handleScroll() {
    if (
      document.documentElement.scrollTop === 0 ||
      window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight
    )
      return;
    if (!props.search?.working && props.search?.moreDataAvailable && !first) {
      props.flowFunctions?.fetchNextPageSearchEntries?.();
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  const singleSelect = props.flowStep.singleSelect;
  const selectedUserIds = props.flowData?.selectedUsers;

  const filteredResults = singleSelect && props.flowData?.selectedUserItems?.length === 1
    ? props.flowData?.selectedUserItems
    : props.filterActive ? props.flowData?.selectedUserItems : result?.hits?.hits;

  const hashNotFound = hashCode('NF');
  const colorIndexNotFound = hashNotFound % 4;
  const colorObjectNotFound = ListAvatarColors[colorIndexNotFound];
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          {props.title ? props.title : 'Wen wollen Sie zum Austausch / zur Zusammenarbeit einladen?'}
        </Typo>
      </Bx>
      <Typo variant="subtitle1">{props.subtitle ? props.subtitle : 'Sie können Ihre Auswahl jederzeit ändern.'}</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          {(first || (working && (!filteredResults || filteredResults.length === 0))) &&
            _.range(3).map(i => {
              const hash = hashCode(`U${i}`);
              const colorIndex = hash % 4;
              const colorObject = ListAvatarColors[colorIndex];
              return (
                <Grid key={`person_search_working_${i}`} item xs={12} lg={asControl ? 6 : 4} xl={asControl ? 4 : 3}>
                  <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                    <CardHeader style={{ border: 1, borderTopLeftRadius:'8px', borderTopRightRadius:'8px', borderColor:"transparent" }}
                      src={TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
                      alt={`Users loading Keyvisual`}
                      avatar={
                        <Ava
                          style={{color: colorObject.color, background: colorObject.bg}}
                          children={<FontAwesomeIcon icon={faEllipsisH} />}
                          loading={true}
                          src={undefined}
                          size="larger"
                          alt={`Users loading`}
                        />
                      }
                    />
                    <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                      <Bx mb={2}>
                        <Typo align="center" variant="subtitle1">
                          <Skeleton animation="wave" width="80%" />
                        </Typo>
                      </Bx>
                      <Bx>
                        <Typo align="center" variant="body1">
                          <Skeleton animation="wave" width="60%" />
                        </Typo>
                      </Bx>
                    </Bx>
                  </Bx>
                </Grid>
            )})}
          {!working && !first && (!filteredResults || filteredResults.length === 0) && (
            <Grid key={'person_search_noresult'} item xs={12} lg={asControl ? 6 : 4} xl={asControl ? 4 : 3}>
              <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                <CardHeader style={{ border: 1, borderTopLeftRadius:'8px', borderTopRightRadius:'8px', borderColor:"transparent" }}
                  src={TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
                  alt={`No User Found Keyvisual`}
                  avatar={
                    <Ava
                      style={{color: colorObjectNotFound.color, background: colorObjectNotFound.bg}}
                      children={<FontAwesomeIcon icon={faQuestion} />}
                      src={undefined}
                      size="larger"
                      alt={`No User Found Avatar`}
                    />
                  }
                />
                <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                  <Bx mb={2}>
                    <Typo align="center" variant="subtitle1">
                      <strong>Leider konnte für Ihre Suchanfrage kein Teilnehmender gefunden werden.</strong>
                    </Typo>
                  </Bx>
                  <Bx>
                    <Typo align="center" variant="body1">
                      Bitte versuchen Sie andere Suchoptionen.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </Grid>
          )}
          {filteredResults &&
            filteredResults.length > 0 &&
            filteredResults.map((userData: any, idx: number) => {
              const user = userData?._source?.content;
              const userId = userData?._source?.content.userId;
              const userScore = userData?._score;
              const abbreviation = buildAbbreviation(userData?._source?.content?.fullName ?? '');
              const hash = hashCode(abbreviation ?? '');
              const colorIndex = hash % 4;
              const colorObject = ListAvatarColors[colorIndex];
              const baseAvatar = <Ava
                  style={{color: colorObject.color, background: colorObject.bg}}
                  src={user.avatar ? assetUrl(user.avatar, true) : undefined}
                  size="larger"
                  alt={`${user.fullName} Avatar`}
                >
                  {abbreviation}
                </Ava>;
              return (
                <Grid key={idx} item xs={12} lg={asControl ? 6 : 4} xl={asControl ? 4 : 3}>
                  <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
                    <CardHeader style={{ border: 1, borderTopLeftRadius:'8px', borderTopRightRadius:'8px', borderColor:"transparent" }}
                      src={user.keyvisual ? assetUrl(user.keyvisual, true) : TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
                      alt={`${user.fullName} Keyvisual`}
                      avatar={!user?.generalUserStatus || user?.generalUserStatus !== 'to_check_signature_employer'
                        ? baseAvatar
                        : <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            color={'error'}
                            title={'User noch nicht validiert.'}
                            style={{fontSize:48}}
                            badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
                          >
                            {baseAvatar}
                          </Badge>
                        
                      }
                    />
                    <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
                      <Bx mb={1}>
                        <Typo align="center" variant="body1">
                          {localStorage.devModeSearch && (<span>
                            {userId} || {userScore} || {' '}
                          </span>)}
                          <strong>{user.fullName}</strong>
                        </Typo>
                      </Bx>
                      {user.function && (
                        <Bx mb={1}>
                          <Typo align="center" variant="body2">
                            {user.function}
                          </Typo>
                        </Bx>
                      )}
                      {user.organisation && (
                        <Bx mb={2}>
                          <Typo align="center" variant="body2">
                            {user.organisation}
                          </Typo>
                        </Bx>
                      )}
                      <Bx textAlign="center" pt={3} mt="auto">
                        {(selectedUserIds || []).indexOf(userId) > -1 ? (
                          <Btn
                            variant="text"
                            color="secondary"
                            endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                            onClick={e => {
                              let selectedUsers: string[] = [];
                              if (!singleSelect) {
                                selectedUsers = updateArraySelection(selectedUserIds || [], userId);
                                if (selectedUsers.length === 0) {
                                  props.flowFunctions.setFlowOption('usersInvited', true);
                                } else {
                                  props.flowFunctions.setFlowOption('usersInvited', false);
                                }
                              }
                              props.flowFunctions.setFlowData({
                                ...props.flowData,
                                selectedUsers: singleSelect ? [] : selectedUsers,
                                selectedUserItems: singleSelect ? [] : updateArrayObjectSelection('_id', props.flowData?.selectedUserItems || [], userData)
                              });
                            }}
                          >
                            hinzugefügt
                          </Btn>
                        ) : (
                          <Btn
                            variant="outlined"
                            onClick={e => {
                              let selectedUsers: string[] = [];
                              if (!singleSelect) {
                                selectedUsers = updateArraySelection(selectedUserIds || [], userId);
                                if (selectedUsers.length === 0) {
                                  props.flowFunctions.setFlowOption('usersInvited', true);
                                } else {
                                  props.flowFunctions.setFlowOption('usersInvited', false);
                                }
                              }
                              props.flowFunctions.setFlowData({
                                ...props.flowData,
                                selectedUsers: singleSelect ? [userId] : selectedUsers,
                                selectedUserItems: singleSelect ? [userData] : updateArrayObjectSelection('_id', props.flowData?.selectedUserItems || [], userData)
                              });
                            }}
                          >
                            hinzufügen
                          </Btn>
                        )}
                      </Bx>
                    </Bx>
                  </Bx>
                </Grid>
              );
            })}
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export const ConnectedGroupUserSelect = (props: any) => {
  const result = useSelector(state => state.search?.result);
  const working = useSelector(state => state.search?.working);
  const first = useSelector(state => state.search?.first);
  const flowAlias = useSelector(state => state.flow?.currentAlias);
  const asControl = flowAlias === 'add-participants' || flowAlias === 'createmeeting';
  const selectionOptional = false;

  const title = flowAlias === 'createmeeting' ? 'Wen wollen Sie zu Ihrem Meeting einladen?' : undefined;
  const subtitle =
    flowAlias === 'createmeeting'
      ? 'Hier eingeladene Gruppenmitglieder werden sofort per E-Mail benachrichtigt. Ein Import in lokale Kalender ist möglich. In öffentlichen Gruppen können Termine geteilt werden. Nicht-Mitglieder müssen zur Teilnahme der Gruppe beitreten.'
      : undefined;
  return <GroupUserSelect props={{ ...props, subtitle: subtitle, title: title }} result={result} working={working} first={first} asControl={asControl} selectionOptional={selectionOptional} />;
};
