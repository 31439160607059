import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { useAtom, useSetAtom } from 'jotai';
import { FramedContent } from '../../../../../../components/framed-content';
import { dataWorkingAtom, headerFilterWorkingAtom, searchResultHitsAtom, searchResultTotalAtom, asyncSearchAtom } from '../atoms';
import { ParticipantCardNoResult, ParticipantCardWorking } from '../card';
import { MeetingInviteParticipantList } from '../list';
import { CardWrapper, InViewport } from '../misc';

export const MeetingInviteStep0: React.FC = () => {
  const [working] = useAtom(dataWorkingAtom);
  const [filterWorking] = useAtom(headerFilterWorkingAtom);
  const [items] = useAtom(searchResultHitsAtom);
  const [totalCount] = useAtom(searchResultTotalAtom);
  const asyncSearch = useSetAtom(asyncSearchAtom);
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Wen wollen Sie zu diesem Meeting einladen?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Sie können Ihre Auswahl jederzeit ändern.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <MeetingInviteParticipantList />
          {(working || filterWorking) && (
            <>
              <CardWrapper>
                <ParticipantCardWorking keyIndex={1}/>
              </CardWrapper>
              <CardWrapper>
                <ParticipantCardWorking keyIndex={2}/>
              </CardWrapper>
              <CardWrapper>
                <ParticipantCardWorking keyIndex={3}/>
              </CardWrapper>
            </>
          )}
          {!working && !filterWorking && !!!items?.length && (
            <CardWrapper>
              <ParticipantCardNoResult />
            </CardWrapper>
          )}
        </Grid>
        {!working && !filterWorking && (items?.length || 0) < totalCount && (
          <InViewport
            onInViewport={intersecting => {
              if (intersecting) {
                asyncSearch();
              }
            }}
          />
        )}
      </Bx>
    </FramedContent>
  );
};
