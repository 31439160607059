import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchStartupRequestAction, setAppstateMobileAction } from '../data/actions/foundation';
import { userMtoLoginFailedAction } from '../data/actions/auth';
import { AppFrame } from '../components/frame';
import { FrameRouting } from './routing/frame';
import { LogoutComponent } from './auth/logout';
import { OauthComponent } from './auth/oauth';
import { LoginComponent } from './auth/login';
import { useCategories } from '../data/sagas/categories';
import { ConferencingOutletImpl } from '../components/conferencing';
import { VideoStatsComponent } from '../components/__stats/video';
import { ConnectedRegisterFrame } from './auth/register';
import { LandingPageFrame } from './landingpage';
import { ImpressumComponent } from '../components/hardcoded-content/impressum';
import { SignalRWatchComponent } from '../components/communication/signalr-watch';
import { useRedirectAfterLogin } from '../helper/hooks/useRedirectAfterLogin';
import { isGatedContentRoute, TYPES_PROPS } from '../helper';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { ConnectedFlowComponentFrame } from './flow';
import { NotFoundComponent } from '../components/detail/not-found';
import { ConnectedOptoutFrame } from './auth/optout';

const imgTrackUrl = process.env.REACT_APP_MATOMO_IMGTRACKER;

export const AppRouting = () => {
  const redirectPathAfterLogin = useRedirectAfterLogin();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.foundation?.auth);
  const anon = useSelector(state => state.foundation?.anon);
  const conferencingVisible = useSelector(state => state.conferencing.visible);
  const framedRoutes = ['/', '/coop', '/info'];
  const location = useLocation();
  const ogImage = TYPES_PROPS[''].OgImage;
  const appState = localStorage.getItem('mto_app_state_v2');

  useEffect(() => {
    if (!appState){
      dispatch(userMtoLoginFailedAction());
    }
  }, [dispatch, appState])
  
  const isMobile = !!useBreakpoints().mdDown;
  useEffect(() => {
    dispatch(setAppstateMobileAction(isMobile));
  }, [dispatch, isMobile]);

  useEffect(() => {
    dispatch(fetchStartupRequestAction());
  }, [auth, dispatch]);
  useCategories();

  const isSpecialRoute = location.pathname?.toLowerCase().indexOf('impressum') > -1
    || location.pathname?.toLowerCase().indexOf('datenschutz') > -1
    || location.pathname?.toLowerCase().indexOf('nutzungsbedingungen') > -1
    || location.pathname?.toLowerCase().indexOf('gemeinschaftsstandards') > -1
    || location.pathname?.toLowerCase().indexOf('logout') > -1
    || location.pathname?.toLowerCase().indexOf('unterstuetzer') > -1;

  return (
    <AppFrame>
      <Helmet>
        <title>Medtec Online</title>
      </Helmet>
      {imgTrackUrl && <img src={imgTrackUrl + '&__now=' + Date.now()} referrerPolicy="unsafe-url" style={{ border: 0, display: 'none' }} alt="" />}
      {!conferencingVisible && (
        <Switch>
          <Route path="/__stats/video">
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <VideoStatsComponent />
          </Route>
          <Route path="/termsrules">
            {redirectPathAfterLogin === '/termsrules' && (<>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <ConnectedFlowComponentFrame alias={'flowtermsrules'} />
            </>)}
            {redirectPathAfterLogin !== '/termsrules' && (<Redirect to={'/'} />)}
          </Route>
          {!isSpecialRoute && auth && redirectPathAfterLogin === '/termsrules' && (
            <Route>
              <Redirect to={'/termsrules'} />
            </Route>
          )}
          {auth && !anon && (
            <Route path="/login">
              <Redirect to={redirectPathAfterLogin} />
            </Route>
          )}
          {auth && (
            <Route path={['/register', '/optin', '/lostpassword', '/resetpassword']}>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <ConnectedRegisterFrame />
            </Route>
          )}
          {!auth && (
            <Route exact path={['/']}>
              <Helmet>
                <title>Medtec Online - Das neue Wir für die Versorgung</title>
                <meta name="description" content={'Das neue Wir für die Versorgung - Nationales Community-Portal für ein erfolgreiches Zusammenspiel von Medizintechnik, Innovation und Gesundheitsversorgung.'} />
                <meta property="og:url" content={window.location.protocol + '//' + window.location.host} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={'Medtec Online - Das neue Wir für die Versorgung'} />
                <meta property="og:description" content={'Das neue Wir für die Versorgung - Nationales Community-Portal für ein erfolgreiches Zusammenspiel von Medizintechnik, Innovation und Gesundheitsversorgung.'} />
                <meta property="og:image" content={window.location.protocol + '//' + window.location.host + ogImage?.src} />
              </Helmet>
              <LandingPageFrame />
            </Route>
          )}
          <Route path={['/login']}>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <LoginComponent />
          </Route>
          <Route path={['/oauth']}>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <OauthComponent />
          </Route>
          {!auth && (
            <Route path={['/register', '/optin', '/lostpassword', '/resetpassword']}>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <ConnectedRegisterFrame />
            </Route>
          )}
          {!auth && (
            // special optout route if needed for messaging unsubscribe
            <Route exact path={['/optout']}>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
              <ConnectedOptoutFrame />
            </Route>
          )}
          <Route path={['/impressum']}>
            <ImpressumComponent content="impressum" />
          </Route>
          <Route path={['/datenschutz']}>
            <ImpressumComponent content="datenschutz" />
          </Route>
          <Route path={['/nutzungsbedingungen']}>
            <ImpressumComponent content="nutzungsbedingungen" />
          </Route>
          <Route path={['/gemeinschaftsstandards']}>
            <ImpressumComponent content="gemeinschaftsstandards" />
          </Route>
          <Route path={['/unterstuetzer']}>
            <ImpressumComponent content="unterstuetzer" />
          </Route>
          {!auth && !isGatedContentRoute(location.pathname) && (
            <Route>
              <Redirect to="/" />
            </Route>
          )}
          <Route path={['/logout']}>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <LogoutComponent />
          </Route>
          <Route path={framedRoutes} component={FrameRouting} />
          <Route path="*">
            <NotFoundComponent />
          </Route>
        </Switch>
      )}
      <Switch>
        <Route path={'/:seg1/:seg2/:seg3/:seg4'}>
          <SignalRWatchComponent />
        </Route>
      </Switch>
      <ConferencingOutletImpl sidebarType=''/>
    </AppFrame>
  );
};
