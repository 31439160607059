import React from 'react';
import { Anohana, Bx, Btn, IconBtn, Typo, SwitchControl } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Chip, CircularProgress, Fade, Grid, Hidden } from '@material-ui/core';
import { HeadlineContainer } from '../../headline-container';
import { finishFlowRequestAction } from '../../../data/actions/flow';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const NewQnAEntry: React.FC<{
  newQnAEntryOpen: boolean;
  close?: () => void;
  cancel?: () => void;
  save?: () => void
}> = ({
  newQnAEntryOpen,
  close,
  cancel,
  save
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [takeBotAnswer, setTakeBotAnswer] = React.useState(true);
  const dispatcher = {
    createQnAEntry: (takeBotAnswer: boolean, history: ReturnType<typeof useHistory>) => dispatch(finishFlowRequestAction({ history, takeBotAnswer }))
  };
  return (
    <Anohana open={newQnAEntryOpen} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={'Frage ins Forum posten?'}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={<IconBtn size="small" onClick={close}><FontAwesomeIcon icon={faTimes} /></IconBtn>}
      />
      <Bx py={3} px={3}>
        <Typo variant="body1">
          Hier können Sie die Frage samt Antwort des Experten-Bots an das Forum richten.
          Sollten Sie nur die Frage stellen wollen ohne die Antwort des Bots können Sie das hier deaktivieren.
        </Typo>
        <Bx display="flex" height="100%" minHeight="inherit" alignItems="center"
          mt={2}
          style={{
            border: '1px solid transparent',
            borderRadius: 16,
            background:'rgb(230, 245, 251)',
            padding:'8px'
          }}
        >
          <Grid item xs={10}>
            <Typo variant={'body1'}>Bot-Antwort übernehmen</Typo>
          </Grid>
          <Grid item xs={2} style={{paddingLeft:'20px'}}>
            <SwitchControl
              color={'secondary'}
              checked={takeBotAnswer}
              disabled={false}
              onChange={() => setTakeBotAnswer(!takeBotAnswer)}
            />
          </Grid>
        </Bx>
        <Bx mt={4} display="flex" justifyContent="flex-end" alignItems="center">
          <Bx>
            <Btn onClick={() => dispatcher.createQnAEntry(takeBotAnswer, history)} color="success" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
              Frage posten
            </Btn>
          </Bx>
          <Bx ml={1}>
            <Btn onClick={cancel} color="error" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Abbrechen
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
