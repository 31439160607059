import { Bx, Ctn } from '@curry-group/mui-curcuma';
import { Fade } from '@material-ui/core';
import React from 'react';

export const HeaderHeadbarContainer: React.FC = ({ children }) => {
  return (
    <Fade in={true}>
      <Bx height="100%" display="flex" alignItems="center" flexGrow={1} flexShrink={1}>
        <Ctn>
          <Bx display="flex">{children}</Bx>
        </Ctn>
      </Bx>
    </Fade>
  );
};
