import { Btn, Bx, CheckboxControl, DropDownMenu, Typo } from '@curry-group/mui-curcuma';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { DEST_PROPS } from '../../../helper';

const DestinationFilter: React.FC<{ props: any; destinations: string[] }> = ({ props, destinations }) => {
  const aggregations_destinations = props.aggregations?.destinations;
  const active = destinations && destinations.length > 0;
  return (
    <Bx key={props.componentKey} style={{ marginLeft: props.marginLeft }}>
      <Btn
        title={props.filter.name + (props.resultCount > 0 ? ` (${props.resultCount})` : ' (0)')}
        size={props.smallVariant ? 'medium' : 'large'}
        color={props.open === props.componentKey || active ? 'primary' : 'default'}
        variant={props.open === props.componentKey || active ? 'contained' : 'outlined'}
        endIcon={<FontAwesomeIcon icon={props.open === props.componentKey ? faChevronUp : faChevronDown} />}
        onClick={e => {
          props.filterFunctions?.click?.(e);
        }}
        aria-controls={props.componentKey}
        aria-haspopup="true"
        style={{ borderBottomLeftRadius: props.open === props.componentKey ? 0 : undefined, borderBottomRightRadius: props.open === props.componentKey ? 0 : undefined }}
      >
        <Typo variant="inherit" noWrap>
          {props.filter.name + (props.resultCount > 0 ? ` (${props.resultCount})` : ' (0)')}
        </Typo>
      </Btn>
      <DropDownMenu
        id={`filter_${props.componentKey}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={props.anchor}
        onClose={e => props.filterFunctions?.open?.('')}
        MenuListProps={{ component: 'div' }}
        open={props.open === props.componentKey}
        keepMounted
      >
        <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={260} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
          {props.open === props.componentKey && (
            <Grid container spacing={1}>
              {(props.filter?.destinations || []).map((destinationAlias: any, index: number) => {
                return (
                  <Grid key={`filter_${props.componentKey}_${index}`} item xs={12}>
                    <CheckboxControl
                      label={
                        <Typo variant="inherit" noWrap>
                          {DEST_PROPS[destinationAlias] + ' (' + (aggregations_destinations?.[destinationAlias] ? aggregations_destinations?.[destinationAlias] : '0') + ')'}
                        </Typo>
                      }
                      value={destinationAlias}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        props.filterFunctions.onDestinationChange?.(destinationAlias);
                      }}
                      checked={destinations ? destinations?.indexOf(destinationAlias) > -1 : false}
                      disabled={!aggregations_destinations?.[destinationAlias]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

export const ConnectedDestinationFilter = (props: any) => {
  const destinations = useSelector(state => state.filter?.searchObject?.queryDestinations);
  return <DestinationFilter props={props} destinations={destinations || []} />;
};
