import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  setBotSearchAction,
  resetBotRequestAction,
  botResponseChunkAction,
  botResponseEndAction,
  trackBotFeedbackAction,
  resetBotFeedbackAction,
  fetchBotAnswerRequestAction,
  fetchBotAnswerErrorAction,
  fetchBotAnswerSuccessAction,
  botSetFingerprintAction,
} from '../../actions/bot';

interface IBotSearch {
  botAnswer?: string;
  botAnswerDict?: any;
  botRequestId?: string;
  botFeedback?: string;
  botFeedbackRating?: number
  botWorking?: boolean;
  botError?: string;
  botToken?: string;
}

interface IBotState {
  botSearchActive?: boolean;
  botSearch?: IBotSearch;
  fingerprint?: string;
}

const initialState: IBotState = {
  botSearchActive: false,
  botSearch: {
    botAnswer: '',
    botAnswerDict: {},
    botRequestId: '',
    botFeedback: '',
    botFeedbackRating: 0,
    botWorking: false,
    botError: '',
  }
};

const bot = createReducer(initialState, builder =>
  builder
    .addCase(setBotSearchAction, (state, action) => {
      if (action.payload.botSearchActive === false) {
        return { ...state, botSearchActive: false, filterVisible: false };
      } else if (action.payload.botSearchActive === true) {
        return { ...state, botSearchActive: true, filterVisible: false };
      }
      return { ...state, botSearchActive: !state.botSearchActive, filterVisible: false };
    })
    .addCase(resetBotRequestAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botRequestId: '',
          botAnswer: '',
          botAnswerDict: {}
        }
      };
    })
    .addCase(botResponseChunkAction, (state, action) => {
      const chunkLength = Object.keys(state.botSearch?.botAnswerDict || {}).length + 1;
      const unsortedChunks = { ...state.botSearch?.botAnswerDict, [action.payload.timestamp]: action.payload.chunk };
      if ((chunkLength % 10) !== 0) {
        return {
          ...state,
          botSearch: {
            ...state.botSearch,
            botAnswerDict: unsortedChunks
        }};
      }
      const sortedKeys = Object.keys(unsortedChunks).sort();
      const sortedChunks = sortedKeys.reduce((acc, key) => {
        acc[key] = unsortedChunks[key];
        return acc;
      }, {});
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botAnswer: Object.values(sortedChunks).join(''),
          botAnswerDict: sortedChunks,
        }
      };
    })
    .addCase(botResponseEndAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botAnswer: action.payload.response,
          botRequestId: action.payload.request_id,
          botAnswerDict: { },
        }
      };
    })
    .addCase(trackBotFeedbackAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botRequestId: action.payload.botRequestId,
          botFeedback: action.payload.feedback,
          botFeedbackRating: action.payload.rating
        }
      };
    })
    .addCase(resetBotFeedbackAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botRequestId: '',
          botFeedback: '',
          botFeedbackRating: 0
        }
      };
    })
    .addCase(fetchBotAnswerRequestAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botWorking: true,
          botAnswer: '',
          botAnswerDict: {},
          botRequestId: '',
          botFeedback: '',
          botFeedbackRating: 0,
          botError: '',
          botToken: action.payload.anontoken
        }
      };
    })
    .addCase(fetchBotAnswerErrorAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botWorking: false,
          botError: action.payload.error
        }
      };
    })
    .addCase(fetchBotAnswerSuccessAction, (state, action) => {
      return {
        ...state,
        botSearch: {
          ...state.botSearch,
          botWorking: false,
          botAnswer: action.payload.result
        }
      };
    })
    .addCase(botSetFingerprintAction, (state, action) => {
      return {
        ...state,
        fingerprint: action.payload.fingerprint
      };
    })
);
export default bot;
