import { Bx, Hamburger, IconBtn } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarOpenAction } from '../../../data/actions/foundation';
import { MTOLogo } from '../../../helper';
import { MobileHeadBar } from '../../mobile-head-bar';
import { NavBarLogo } from '../../navbar-logo';
interface IMobileHeaderProps {
  type: 'default' | 'search' | 'chat';
}

export const MobileHeader: React.FC<IMobileHeaderProps> = ({ type }) => {
  const dispatch = useDispatch();

  const navbarOpen = useSelector(state => state.foundation.navbarOpen);
  const setNavbarOpen = () => {
    dispatch(setNavbarOpenAction(!navbarOpen));
  };

  return (
    <MobileHeadBar zIndex={5}>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
        <Grid item xs={3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn onClick={() => setNavbarOpen()}>
              <Hamburger width={30} height={30} size={20} toggled={navbarOpen} label={navbarOpen ? 'Menü schließen' : 'Menü öffnen'} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={6}>
          <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
            <NavBarLogo to="/" src={MTOLogo.logoDarkSrc} />
          </Bx>
        </Grid>
      </Bx>
    </MobileHeadBar>
  );
};
