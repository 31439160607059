import React from 'react';
import { Anohana, Bx, Btn, IconBtn, Typo, Input } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../headline-container';

export const InputModal: React.FC<{ 
  open: boolean;
  headline: string;
  content: string;
  inputText: string;
  commitText?: string; 
  cancelText?: string;
  onInputTextChange: (text: string) => void;
  close: () => void;
  cancel: () => void;
  commit: () => void
}> = ({ 
  open,
  headline,
  content,
  inputText,
  commitText,
  cancelText,
  onInputTextChange,
  close,
  cancel,
  commit
}) => {
  return (
    <Anohana open={open} fullWidth maxWidth="sm">
      <HeadlineContainer
        bgcolor="background.paper"
        px={3}
        py={2.5}
        headline={headline}
        minHeight={81}
        headlineVariant="h4"
        headlineComponent="h5"
        endAdornment={<IconBtn size="small" onClick={close}><FontAwesomeIcon icon={faTimes} /></IconBtn>}
      />
      <Bx py={3} px={3}>
        {content && <Typo variant="body1">{content}</Typo>}
        <Bx mt={2} >
          <Input
            input={{
              style: { paddingBottom: 5 },
              value: inputText,
              multiline: true,
              rows: 3,
              onChange: val => onInputTextChange(val.target.value)
            }}
          />
        </Bx>
        <Bx mt={2} display="flex" justifyContent="flex-end" alignItems="center">
          <Bx>
            <Btn onClick={commit} color="success" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faArrowToRight} />}>
              {commitText || 'OK'}
            </Btn>
          </Bx>
          <Bx ml={1}>
            <Btn onClick={cancel} color="error" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              {cancelText || 'Abbrechen'}
            </Btn>
          </Bx>
        </Bx>
      </Bx>
    </Anohana>
  );
};
