import { Ava, Bx, Divider, ExtendedListItem, IconAva, Link, TimeAva, Typo } from '@curry-group/mui-curcuma';
import { faQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { assetUrl } from '../../helper';
import { IListItem } from '../../model/list/ListItem';
import { ChatMessage } from '../chat-message';
import { Skeleton } from '@material-ui/lab';

export interface IActivityStreamItemProps {
  item: IListItem;
  creatorTo: string;
  itemTo?: string;
}

export const ActivityStreamItem: React.FC<IActivityStreamItemProps> = ({ item, creatorTo, itemTo }) => {
  let avatar = <></>;

  switch (item.avatar.type) {
    case 'image':
    case 'user':
      avatar = (
        <React.Fragment>
          <Hidden smDown>
            <Ava size="large" src={item.avatar.image} />
          </Hidden>
          <Hidden mdUp>
            <Ava size="small" src={item.avatar.image} />
          </Hidden>
        </React.Fragment>
      );
      break;
    case 'time':
      avatar = (
        <React.Fragment>
          <Hidden smDown>
            <TimeAva size="large" fromTime={item.avatar.time?.from || ''} toTime={item.avatar.time?.to || ''} />
          </Hidden>
          <Hidden mdUp>
            <TimeAva size="small" fromTime={item.avatar.time?.from || ''} toTime={item.avatar.time?.to || ''} />
          </Hidden>
        </React.Fragment>
      );
      break;
    default:
      avatar = (
        <React.Fragment>
          <Hidden smDown>
            <IconAva color={item.color} children={<FontAwesomeIcon icon={item.avatar.icon || faQuestion} />} size="large" />
          </Hidden>
          <Hidden mdUp>
            <IconAva color={item.color} children={<FontAwesomeIcon icon={item.avatar.icon || faQuestion} />} size="small" />
          </Hidden>
        </React.Fragment>
      );
  }

  return (
    <Bx display="flex" mb={{ xs: 1, md: 3 }}>
      <Hidden smDown>
        <Bx mr={2} pt={0.5}>
          <Ava size="medium" src={item?.__original?.content?.creator?.avatar ? assetUrl(item.__original.content.creator.avatar, true) : undefined} />
        </Bx>
      </Hidden>
      <ChatMessage p={2} bgcolor="background.paper" color="inherit" width="100%">
        <Bx display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Bx display="flex" alignItems="baseline">
            <Bx>
              <Link underline="hover" to={creatorTo}>
                <Typo fontWeight={500} variant="body2">
                  {item.title}
                </Typo>
              </Link>
            </Bx>
            <Bx ml={1}>
              <Typo variant="subtitle2">{new Date(item.timestamp || 0).toLocaleDateString()}</Typo>
            </Bx>
          </Bx>
        </Bx>
        <Bx>
          <Typo component={!item.description ? 'i' : undefined}>{item.description || 'hat etwas geteilt'}</Typo>
        </Bx>
        <Bx mt={2} mb={2}>
          <Divider />
        </Bx>
        <ExtendedListItem py={1} to={itemTo} avatar={avatar}>
          <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
            <Typo variant="subtitle2" color="textPrimary" component="span" textTransform="uppercase">
              {item.type}
            </Typo>
            <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
              {item.subtitle}
            </Typo>
          </Bx>
        </ExtendedListItem>
      </ChatMessage>
    </Bx>
  );
};


export const ActivityStreamItemSkeleton: React.FC = () => {
  let avatar = (
    <React.Fragment>
      <Hidden smDown>
        <Ava loading variant="square" size="large" />
      </Hidden>
      <Hidden mdUp>
        <Ava loading variant="square" size="small" />
      </Hidden>
    </React.Fragment>
  );

  return (
    <Bx display="flex" mb={{ xs: 1, md: 3 }}>
      <Hidden smDown>
        <Bx mr={2} pt={0.5}>
          <Ava loading variant="circle" size="medium" />
        </Bx>
      </Hidden>
      <ChatMessage p={2} bgcolor="background.paper" color="inherit" width="100%">
        <Bx display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Bx display="flex" alignItems="baseline">
            <Bx>
              <Link underline="hover" to={''}>
                <Typo fontWeight={500} variant="body2">
                  <Skeleton animation="wave" style={{ width: '100px' }} />
                </Typo>
              </Link>
            </Bx>
            <Bx ml={1}>
              <Typo variant="subtitle2">
                <Skeleton animation="wave" style={{ width: '70px' }} />
              </Typo>
            </Bx>
          </Bx>
        </Bx>
        <Bx>
          <Skeleton animation="wave" style={{ width: '100%' }} />
          <Skeleton animation="wave" style={{ width: '100%' }} />
          <Skeleton animation="wave" style={{ width: '80%' }} />
        </Bx>
        <Bx mt={2} mb={2}>
          <Divider />
        </Bx>
        <ExtendedListItem py={1} to={''} avatar={avatar}>
          <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
            <Typo variant="subtitle2" color="textPrimary" component="span" textTransform="uppercase">
              <Skeleton animation="wave" style={{ width: '100px' }} />
            </Typo>
            <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
              <Skeleton animation="wave" style={{ width: '50%' }} />
            </Typo>
          </Bx>
        </ExtendedListItem>
      </ChatMessage>
    </Bx>
  );
};