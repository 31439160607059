import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FormInput } from '../controls/input';

interface IFormWorkgroupInvitation {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormWorkgroupInvitation: React.FC<IFormWorkgroupInvitation> = ({ formData, headline, subtitle, onChange }) => {
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <FormInput
              value={formData?.itemData?.invitation}
              label={'Ihr Einladungstext'}
              placeholder={'Erzählen Sie uns mehr...'}
              multiline={true}
              minLength={5}
              maxLength={2000}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, invitation: val } });
              }}
            />
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
