import { IProfile } from '../profile';
import { IAsset } from '../ryve/Asset';
import { IThing } from '../ryve/Thing';
import { ISearchEntry } from '../search/SearchEntry';

export interface IConferenceInformation {
  started?: number;
  ended?: number;
  participants?: string[];
}

export interface IMessageReferenceMeeting {
  _id: string;
  title: string;
  alias: string;
  interval: { dateFrom: number; dateTo: number };
  participation?: { status: string; type: string; message?: string };
}
export interface IMessageReferences {
  meeting?: IMessageReferenceMeeting;
}
export interface IMessageModel {
  id: string;
  parent: string;
  content: string;
  emotes: {};
  deleted: boolean;

  createdBy: string;
  createdAt: number;
  modifiedAt: number;
  modifiedBy: string;

  threadRoot?: string;

  attachments?: string[];
  attachmentsResolved?: IAsset[];

  attachedThingResolved?: IThing<ISearchEntry>;

  quotes?: string;
  quotesResolved?: IMessageModel;
  threadChildren?: IMessageModel[];

  voting?: { [userId: string]: number };
  numVotes?: number;
  numThreadChildren?: number;
  latestThreadChildTimestamp?: number;

  conferenceInfo?: IConferenceInformation;
  referencesResolved?: IMessageReferences;
  createdByResolved?: IThing<IProfile>;
}

export function getMessageCreatedByName(message: IMessageModel, fallback?: string) {
  var fullName = message.createdByResolved?.content?.fullName;
  if (fullName) return fullName;
  if (fallback !== undefined) return fallback;
  return 'Ehemaliger Benutzer';
}
