import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotFoundComponent } from '../detail/not-found';
import { Grid } from '@material-ui/core';
import { Btn, Bx, Input, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailbox } from '@fortawesome/pro-light-svg-icons';
import { HeadlineContainer } from '../headline-container';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useAcademicTitles, useAcademicTitlesState } from '../../data/sagas/lists';
import { registerExpertAdmin } from '../../data/reducer/userprofile';
import { EmployerSelect } from '../form/controls/employer-select';
import { IUserProfilePlayer } from '../../model/profile/player';
import { useGetAllGroups } from '../../data/sagas/userprofile';
import { IGroupSelect } from '../../model/profile/groupselect';

export interface IExpertRegistrationComponentProps { }

export interface IExpertForm {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  employer: string;
  employerRoot: string;
  groups: Array<string>;
}

export const ExpertRegistrationComponent: React.FC<IExpertRegistrationComponentProps> = () => {
  useAcademicTitles();
  useGetAllGroups();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();

  const [expertForm, setExpertForm] = useState<IExpertForm>({
    email: '',
    firstName: '',
    lastName: '',
    title: '',
    employer: '',
    employerRoot: '',
    groups: []
  } as IExpertForm);
  const [titleQuery, setTitleQuery] = useState('');
  const [groupTitleQuery, setGroupTitleQuery] = useState('');
  const [selectedTitle, setSelectedTitle] = useState<{ _id: string, title: string }>({ _id: '', title: ''});
  const [selectedGroups, setSelectedGroups] = useState<Array<IGroupSelect>>([]);
  const [userSelectedEmployerFirstLevel, setUserSelectedEmployerFirstLevel] = useState<any>({});
  const [userSelectedEmployerSecondLevel, setUserSelectedEmployerSecondLevel] = useState<any>({});

  const isAdmin = useSelector(state => state.foundation?.profile?.isAdmin);
  const isMdUp = breakpoints.mdUp || false;
  const academicTitles = useAcademicTitlesState();
  const groupList = useSelector(state => state.userProfile?.groupList);
  const expertRegisteredMessage = useSelector(state => state.userProfile?.expertRegisteredMessage);

  if (!isAdmin) {
    return (<NotFoundComponent />); 
  }

  return (<>
    <Bx mb={6}>
      <HeadlineContainer
        mb={4}
        headline="Registrierung für Teilnehmer auslösen"
        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
        endAdornment={<>
        </>}
      />
      <Bx mb={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Bx mb={2}>
              <Input
                inputLabel={{ children: 'E-Mail', mandatory: true }}
                input={{
                  value: expertForm.email,
                  fullWidth: true,
                  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setExpertForm({ ...expertForm, email: event.target.value.trim()}); 
                  }
                }}
              />
            </Bx>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Bx mb={2}>
              <Input
                inputLabel={{ children: 'Vorname', mandatory: true }}
                input={{
                  value: expertForm.firstName,
                  fullWidth: true,
                  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setExpertForm({ ...expertForm, firstName: event.target.value.trim()}); 
                  }
                }}
              />
            </Bx>
          </Grid>
          <Grid item xs={12} md={6}>
            <Bx mb={2}>
              <Input
                inputLabel={{ children: 'Nachname', mandatory: true }}
                input={{
                  value: expertForm.lastName,
                  fullWidth: true,
                  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setExpertForm({ ...expertForm, lastName: event.target.value.trim()}); 
                  }
                }}
              />
            </Bx>
          </Grid>
          <Grid item xs={12} md={6}>
            <Bx mb={2}>
              <Bx mb={1}><Typo component='span' variant='body2' fontWeight={500}>Akademischer Titel</Typo></Bx>
              <Autocomplete
                value={selectedTitle}
                options={academicTitles}
                noOptionsText={!!academicTitles && academicTitles.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
                autoComplete={false}
                inputValue={titleQuery}
                includeInputInList={false}
                placeholder="keine Angabe"
                renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
                onChange={(e, v: any) => {
                  setSelectedTitle(v);
                  setExpertForm({ ...expertForm, title: v?.title?.trim()});
                }}
                onInputChange={(event, newInputValue) => {
                  setTitleQuery(newInputValue);
                }}
                getOptionLabel={o => o.title}
                getOptionSelected={(option, value) => {
                  return option._id === selectedTitle?._id
                }}
                renderOption={o => {
                  return (
                    <Bx display="flex" alignItems="stretch">
                      <Bx mr={2}>
                        <Typo variant="subtitle1" component="span">
                          {o.title}
                        </Typo>
                      </Bx>
                    </Bx>
                  )
                }}
              />
            </Bx>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EmployerSelect
              customLabelVariant={'body2'}
              label={'Verknüpfter Akteur'}
              sublabel={'Department'}
              preset={userSelectedEmployerFirstLevel}
              subpreset={userSelectedEmployerSecondLevel}
              onSelectFirstLevel={actorData => {
                setUserSelectedEmployerFirstLevel(actorData);
                setUserSelectedEmployerSecondLevel({});
              }}
              onSelectSecondLevel={actorData => {
                setUserSelectedEmployerSecondLevel(actorData);
              }}
              onSelect={(actorData, level) => {
                const swap = [{}];
                const player: IUserProfilePlayer = {
                  player: actorData?._id,
                  playerResolved: {
                    name: actorData?._source.content.name,
                    alias: actorData?._source.seo.alias
                  }
                };
                if (level === 'second') {
                  const playerRoot = userSelectedEmployerFirstLevel as any;
                  player.playerRoot = playerRoot._id;
                  player.playerRootResolved = {
                    name: playerRoot?._source.content.name,
                    alias: playerRoot?._source.seo.alias
                  };
                }
                swap.splice(0, 1, player);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Bx mb={2}>
              <Bx mb={1}><Typo component='span' variant='body2' fontWeight={500}>Initiale Gruppe(n)</Typo></Bx>
              <Autocomplete
                value={selectedGroups}
                options={groupList || []}
                noOptionsText={!!groupList && groupList.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
                autoComplete={false}
                inputValue={groupTitleQuery}
                includeInputInList={false}
                multiple={true}
                placeholder="keine Angabe"
                renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
                onChange={(e, v: Array<IGroupSelect>) => {
                  setSelectedGroups(v);
                }}
                onInputChange={(event, newInputValue) => {
                  setGroupTitleQuery(newInputValue);
                }}
                getOptionLabel={o => o.title}
                getOptionSelected={(option, value) => {
                  return option._id === value?._id
                }}
                renderOption={o => {
                  return (
                    <Bx display="flex" alignItems="stretch">
                      <Bx mr={2}>
                        <Typo variant="subtitle1" component="span">
                          {o.title}
                        </Typo>
                      </Bx>
                    </Bx>
                  )
                }}
              />
            </Bx>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} md={6}>
            <Btn size="large"
              startIcon={<FontAwesomeIcon icon={faMailbox} />}
              variant="outlined"
              disabled={!expertForm.email || !expertForm.firstName || !expertForm.lastName}
              onClick={()=>{
                if (!!(userSelectedEmployerSecondLevel as any)._id) {
                  expertForm.employerRoot = (userSelectedEmployerFirstLevel as any)._id;
                  expertForm.employer = (userSelectedEmployerSecondLevel as any)._id;
                } else if (!!(userSelectedEmployerFirstLevel as any)._id) {
                  expertForm.employer = (userSelectedEmployerFirstLevel as any)._id;
                }
                if (selectedGroups && selectedGroups.length > 0) {
                  expertForm.groups = selectedGroups.map(g => g._id);
                }
                !!isAdmin && dispatch(registerExpertAdmin({request: expertForm}));
                setUserSelectedEmployerFirstLevel({});
                setUserSelectedEmployerSecondLevel({});
                setSelectedTitle({ _id: '', title: ''});
                setSelectedGroups([]);
                setExpertForm({
                  email: '',
                  firstName: '',
                  lastName: '',
                  title: '',
                  employer: '',
                  employerRoot: '',
                  groups: []
                } as IExpertForm);
              }}
            >
              Systemseitige Registrierung auslösen
            </Btn>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} md={6}>
            <Bx mb={2}><Typo component='span' variant='body2' fontWeight={500}>{expertRegisteredMessage ?? ' '}</Typo></Bx>
          </Grid>
        </Grid>
      </Bx>
    </Bx>
  </>);
};
