import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FramedContent } from '../../framed-content';

const PublishSelect = (props: any) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Wohin wollen Sie den Inhalt teilen?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Platzieren Sie interessante Inhalte dort, wo Sie weiter mit ihnen arbeiten wollen.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard variant="outlined" fluid={{ from: 278, to: 458 }} height="100%" to="#" onClick={props.nextClick}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/community.svg" alt="Idee teilen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Community
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie wollen die gesamte Fachcommunity auf Medtec Online über diesen Inhalt informieren?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard variant="outlined" fluid={{ from: 278, to: 458 }} height="100%" to="#" onClick={props.nextClick}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/chat-arbeitsgruppe-projekt.svg" alt="Bedarf melden" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Chat oder Communities
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Sie wollen diesen Inhalt in Formaten mit Ihrer Beteiligung hinweisen?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard variant="outlined" fluid={{ from: 278, to: 458 }} height="100%" onClick={props.nextClick}>
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/social-media.svg" alt="Frage stellen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    Social Media
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Dierser Inhalt ist auch für Personen aus Ihren sozialen Netzwerken interessant?
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export default PublishSelect;
