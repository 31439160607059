import { Ctn, IconBtn, Bx, Typo } from '@curry-group/mui-curcuma';
import { faArrowRight, faArrowToRight, faComment, faLongArrowLeft, faPencil, faShare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setFlowDataAction } from '../../../data/actions/flow';
import { updateVotingRequest } from '../../../data/reducer/detail';
import { removeDetailUrl, TYPES } from '../../../helper';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { interactionButtonSettings } from '../../../model/ryve/Thing';
import { ContentInteractionBar, IContentInteractionBarProps } from '../../content-interaction-bar';
import { HeadBar, IHeadBarProps } from '../../head-bar';
import { MobileHeadBar } from '../../mobile-head-bar';
import { getReferrer } from '../../../helper';

export interface IDetailHeaderProps {
  first?: boolean;
  working?: boolean;
  show?: boolean;
  editClick?: () => void;
  backButtonLink?: IHeadBarProps['backButtonLink'];
  chatButton?: IContentInteractionBarProps['chatButton'];
  adminMissing?: boolean;
}

export const ConnectedDetailHeaderComponent: React.FC<IDetailHeaderProps> = ({ backButtonLink, chatButton, editClick, show }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const listed = useSelector(state => state.detail?.item?.content?.listed);
  const parentListed = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?.content?.listed);
  const isMobile = useIsMobile();
  const detailItem = useSelector(state => state.detail.item);
  const detailType = useSelector(state => state.detail.item?.type);
  const numVotes = useSelector(state => state.detail.item?.content?.voting);
  const numComments = useSelector(state => state.detail.item?.content?.commentCount);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const userVoting = useSelector(state => state.detail.item?.content?.userVoting);
  const working = useSelector(state => state.detail.working);
  const communicationParticipants = useSelector(state => state.communication?.participants);
  const roles = useSelector(state => state.communication?.communication?.roles);
  const userId = useSelector(state => state.foundation?.profile?.userId);

  let adminMissing = false;
  if (communicationParticipants && (detailType === TYPES.GROUP || detailType === TYPES.PROJECT)) {
    const adminRoleId = roles?.find(role => role.type === 'administrator')?.roleId || '';
    const admins = communicationParticipants?.filter(participant => participant.roles.includes(adminRoleId));
    const ownParticipation = communicationParticipants?.find(participant => participant.user === userId);
    if (ownParticipation?.roles?.includes(adminRoleId)) {
      adminMissing = !admins || admins.length < 2;
    }
  }
  const unlistedGroup = (detailType === TYPES.GROUP || detailType === TYPES.PROJECT) && listed === false;
  const unlistedParent = detailType === TYPES.MEETING && parentListed === false;

  const headerProps = {
    backButtonLink: backButtonLink || getReferrer(location.search) || removeDetailUrl(location.pathname),
    numComments: numComments,
    working: working || participationWorking,
    show: show,
    chatButton: interactionButtonSettings(detailType).hideComments ? undefined : chatButton,
    numVotes: numVotes,
    adminMissing: adminMissing,
    voteDownClick:
      (userVoting?.vote !== -1 && !interactionButtonSettings(detailType).hideVoting ? true : undefined) &&
      (() => {
        dispatch(updateVotingRequest({ voting: -1 }));
      }),
    voteUpClick:
      (userVoting?.vote !== 1 && !interactionButtonSettings(detailType).hideVoting ? true : undefined) &&
      (() => {
        dispatch(updateVotingRequest({ voting: 1 }));
      }),
    shareClick:
      !interactionButtonSettings(detailType).hideShare && !(unlistedGroup || unlistedParent)
        ? () => {
            dispatch(
              setFlowDataAction({
                alias: 'share',
                flowData: {
                  location: history.location,
                  itemData: {
                    sharedItem: detailItem
                  }
                }
              })
            );
            history.push(history.location.pathname + '/flow' + history.location.search);
          }
        : undefined
  };

  if (isMobile) return <DetailMobileHeaderComponent {...headerProps} />;
  else return <DetailHeaderComponent {...headerProps} />;
};

export const DetailHeaderComponent: React.FC<IDetailHeaderProps & IContentInteractionBarProps> = ({
  first,
  working,
  show,
  backButtonLink,
  chatButton,
  numComments,
  numVotes,
  adminMissing,
  voteDownClick,
  voteUpClick,
  shareClick,
  memoryClick,
  editClick
}) => {
  return (<>
    <HeadBar py={0.5} zIndex={1} backButtonLink={backButtonLink} id="content-head-container">
      <Fade in={show}>
        <Bx flexShrink={1} flexGrow={1}>
          <Bx height="100%" display="flex" alignItems="center">
            <Ctn>
              <Bx px={7.5}>
                <ContentInteractionBar
                  withoutFrame={true}
                  chatButton={chatButton}
                  numVotes={numVotes}
                  voteDownClick={voteDownClick}
                  voteUpClick={voteUpClick}
                  numComments={numComments}
                  shareClick={shareClick}
                  memoryClick={memoryClick}
                  editClick={editClick}
                />
              </Bx>
            </Ctn>
          </Bx>
        </Bx>
      </Fade>
    </HeadBar>
    {adminMissing && (
      <Bx display="flex" justifyContent="center" px={{ xs: 2, lg: 5 }} py={0} bgcolor={'accent.light'}>
        <Bx display="flex" justifyContent="center" px={1} py={1}>
          <Typo variant="body1" color="textSecondary">
            Bitte bennenen Sie von den Teilnehmern einen weiteren Administrator.
          </Typo>
        </Bx>
      </Bx>
    )}
  </>);
};

export const DetailMobileHeaderComponent: React.FC<IDetailHeaderProps & IContentInteractionBarProps> = ({
  backButtonLink,
  first,
  working,
  show,
  chatButton,
  numComments,
  numVotes,
  adminMissing,
  voteDownClick,
  voteUpClick,
  shareClick,
  editClick
}) => {
  return (<>
    <MobileHeadBar zIndex={1} id="content-head-container">
      <Bx flexShrink={1} flexGrow={1} display="flex" py={1}>
        {typeof backButtonLink === 'function' && (
          <Bx zIndex={2}>
            <Bx pr={1}>
              <IconBtn size="medium" onClick={() => backButtonLink()}>
                <FontAwesomeIcon icon={faLongArrowLeft} />
              </IconBtn>
            </Bx>
          </Bx>
        )}
        {typeof backButtonLink === 'string' && (
          <Bx zIndex={2}>
            <Bx pr={1}>
              <IconBtn size="medium" to={backButtonLink}>
                <FontAwesomeIcon icon={faLongArrowLeft} />
              </IconBtn>
            </Bx>
          </Bx>
        )}
        <Bx display="flex" alignItems="center" flexGrow={1} flexShrink={1} pr={1}>
          <Bx display="flex" width="100%">
            {/* {(voteUpClick || voteDownClick) && (
              <Bx display="flex">
                <Bx display="flex" justifyContent="center" alignItems="center">
                  <VotesAva size="medium" color="secondary" votes={numVotes} colorVariant="contained" />
                </Bx>
                <Bx ml={0} px={1} display="flex" justifyContent="center" alignItems="center" borderColor="divider" borderRight={1}>
                  <IconBtn size="small" onClick={voteUpClick} disabled={!voteUpClick}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </IconBtn>
                </Bx>
                <Bx ml={0} px={1} display="flex" justifyContent="center" alignItems="center" borderColor="divider" borderRight={1}>
                  <IconBtn size="small" onClick={voteDownClick} disabled={!voteDownClick}>
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </IconBtn>
                </Bx>
              </Bx>
            )} */}
            {chatButton && chatButton.type === 'discussion' && (
              <Bx display="flex" justifyContent="center" alignItems="center">
                <IconBtn
                  disabled={!chatButton.onClick}
                  onClick={chatButton.onClick}
                  badgeContent={numComments}
                  badgeVariant="standard"
                  badgeBgColor="accent.light"
                  badgeColor="text.primary"
                  color="secondary"
                  size="small"
                  variant="contained"
                  shape="rounded"
                >
                  <FontAwesomeIcon icon={faComment} />
                </IconBtn>
              </Bx>
            )}
            {chatButton && chatButton.type === 'participate' && (
              <Bx ml="auto" display="flex" justifyContent="center" alignItems="center">
                <IconBtn disabled={!chatButton.onClick} onClick={chatButton.onClick} color="secondary" size="small" variant="contained" shape="rounded">
                  <FontAwesomeIcon icon={faArrowToRight} />
                </IconBtn>
              </Bx>
            )}
            {chatButton && chatButton.type === 'apply' && (
              <Bx ml="auto" display="flex" justifyContent="center" alignItems="center">
                <IconBtn disabled={!chatButton.onClick} onClick={chatButton.onClick} color="secondary" size="small" variant="contained" shape="rounded">
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconBtn>
              </Bx>
            )}
            {shareClick && (
              <Bx ml={!!chatButton?.type ? 1 : 'auto'} display="flex" justifyContent="center" alignItems="center">
                <IconBtn onClick={shareClick} color="primary" size="small" variant="contained" shape="rounded">
                  <FontAwesomeIcon icon={faShare} />
                </IconBtn>
              </Bx>
            )}
            {editClick && (
              <Bx ml={typeof shareClick !== 'undefined' ? 1 : 'auto'} display="flex" justifyContent="center" alignItems="center">
                <IconBtn onClick={editClick} color="secondary" size="small" variant="contained" shape="rounded">
                  <FontAwesomeIcon icon={faPencil} />
                </IconBtn>
              </Bx>
            )}
          </Bx>
        </Bx>
      </Bx>
    </MobileHeadBar>
    {adminMissing && (
      <Bx display="flex" justifyContent="center" px={{ xs: 2, lg: 5 }} py={0} bgcolor={'accent.light'}>
        <Bx display="flex" justifyContent="center" px={1} py={1}>
          <Typo variant="body1" color="textSecondary">
            Bitte bennenen Sie von den Teilnehmern einen weiteren Administrator.
          </Typo>
        </Bx>
      </Bx>
    )}
  </>);
};
