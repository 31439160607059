import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faLock } from '@fortawesome/pro-light-svg-icons';
import useTheme from '@material-ui/core/styles/useTheme';
import { Ava, DataGrid, Typo, Link, Bx } from '@curry-group/mui-curcuma';
import { IDataGridCellParams, IDataGridColumnParams, IDataGridColumnProps, TDataGridSortDirection } from '@curry-group/mui-curcuma/dist/ui/DataDisplay/DataGrid';
import { IAssetResolved, isImage } from '../../../model/ryve/Asset';
import { assetUrl, iconForExtension, resolveTimestamp } from '../../../helper';

import { GridLinkOperator } from '@material-ui/x-grid';

function assetToGridRow(asset: IAssetResolved) {
  return {
    id: asset._id,
    icon: isImage(asset) ? <Ava src={assetUrl(asset._id, true)} variant="rounded" size="small" children={<FontAwesomeIcon icon={iconForExtension(asset.extension)} />} /> : <FontAwesomeIcon icon={iconForExtension(asset.extension)} />,
    name: asset.name + asset.extension,
    changedAt: dayjs(asset.modifiedAt || asset.createdAt).unix() * 1000,
    changedBy: (
      <Link to={'userbyid/' + asset.createdBy} underline="hover">
        {asset.createdByResolved?.content?.fullName || 'Ehemaliger Benutzer'}
      </Link>
    )
  };
}

export type GridRowId = string | number;

export interface FileManagerProps {
  assets?: IAssetResolved[];
  working?: boolean;

  filters?: string[];

  selection?: GridRowId[];
  setSelection?: (selection: GridRowId[]) => void;

  writeProtected?: boolean;
}

export const FileManager: React.FunctionComponent<FileManagerProps> = ({
  assets,
  working,
  filters,
  selection,
  writeProtected,
  setSelection,
}) => {
  const theme: any = useTheme();
  const iconWidth = theme.custom.ava.small.width;

  const cols: IDataGridColumnProps[] = [
    {
      field: 'col1',
      headerName: 'ID',
      type: 'string',
      hide: true,
      sortable: false
    },
    {
      field: 'icon',
      align: 'center',
      renderCell: (params: IDataGridCellParams) => (
        <Bx color="primary.main" mx="auto" width={iconWidth} display="flex" alignItems="center" justifyContent="center">
          <Typo variant="h3" component="span">
            {params.row[params.field]}
          </Typo>
        </Bx>
      ),
      headerName: 'Icon',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderHeader: () => (
        <Bx mx="auto" width={iconWidth} display="flex" alignItems="center" justifyContent="center">
          <Typo variant="body1" component="span">
            <FontAwesomeIcon icon={faFolder} />
          </Typo>
        </Bx>
      ),
      sortable: false
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      flex: 1,
      renderHeader: (params: IDataGridColumnParams) => (
        <Typo variant="subtitle2" component="span">
          {params.colDef?.headerName}
        </Typo>
      ),
      renderCell: (params: IDataGridCellParams) => (
        <Typo variant="body2" component="span" title={params.row[params.field]}>
          {writeProtected && <FontAwesomeIcon icon={faLock} />} {params.row[params.field]}
        </Typo>
      )
    },
    {
      field: 'changedAt',
      headerName: 'Geändert',
      type: 'date',
      sortable: true,
      flex: 1,
      renderHeader: (params: IDataGridColumnParams) => (
        <Typo variant="subtitle2" component="span">
          {params.colDef?.headerName}
        </Typo>
      ),
      renderCell: (params: IDataGridCellParams) => (
        <Typo variant="body2" component="span">
          {resolveTimestamp(params.row[params.field])}
        </Typo>
      )
    },
    {
      field: 'changedBy',
      headerName: 'Geändert von',
      type: 'string',
      sortable: true,
      flex: 1,
      renderHeader: (params: IDataGridColumnParams) => (
        <Typo variant="subtitle2" component="span">
          {params.colDef?.headerName}
        </Typo>
      ),
      renderCell: (params: IDataGridCellParams) => (
        <Typo variant="body2" component="span">
          {params.row[params.field]}
        </Typo>
      )
    }
  ];

  return (
    <DataGrid
      loading={working}
      rows={assets ? assets.map(assetToGridRow) : []}
      columns={cols}
      disableColumnMenu={true}
      hideFooter={true}
      pageSize={0}
      disableColumnResize={true}
      rowHeight={54}
      checkboxSelection
      headerHeight={32}
      autoHeight={true}
      selectionModel={selection}
      onSelectionModelChange={change => {
        setSelection?.(change.selectionModel);
      }}
      filterModel={
        filters && {
          items: [
            ...filters.map(filter => {
              return {
                columnField: 'type',
                operatorValue: 'equals',
                value: filter
              };
            })
          ],
          linkOperator: GridLinkOperator.Or
        }
      }
      sortModel={[
        {
          field: 'changedAt',
          sort: 'desc' as TDataGridSortDirection
        }
      ]}
      className='muic-file-manager'
    />
  );
};
