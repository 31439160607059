import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FormLostPassData } from '../../form/compositions/FormLostPassData';
import { FormValidator } from '../../form/controls/input';
import { FramedContent } from '../../framed-content';

const LostPassFormData = (props: any) => {
  if (!props.flowData?.itemData?.email || !FormValidator.isValid('', 'resetPassData')) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormLostPassData
        formData={props.flowData}
        headline={'Passwort vergessen?'}
        subtitleComponent={
          <Bx>
            <Typo variant="subtitle1">Bitte geben Sie die E-Mail-Adresse an, die Sie zur Anmeldung verwenden.</Typo>
          </Bx>
        }
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default LostPassFormData;
