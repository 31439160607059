import React from 'react';
import { FormQnA } from '../../form/compositions/FormQnA';
import { FramedContent } from '../../framed-content';

const QnAForm: React.FC<{ props: any }> = ({ props }) => {
  if (!props.flowData || !props.flowData?.itemData?.title || props.flowData?.itemData?.title?.length < 5) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormQnA
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={'Bitte achten Sie darauf, keine schutzrechtsrelevanten Details offenzulegen.'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export const ConnectedQnAForm = (props: any) => {
  return <QnAForm props={props} />;
};
