import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomBar } from '../../../components/bottom-bar';
import { CommunicationInput } from '../../../components/communication/input';
import { ContentPendingParticipationComponent } from '../../../components/detail/participation';
import {
  createMessageRequest,
  invitationResponseRequest,
  setMessageDraftAttachments,
  setMessageDraftContent,
  setMessageDraftEdit,
  setMessageDraftQuotes,
  updateMessageRequest
} from '../../../data/reducer/communication';
import { CommunicationType } from '../../../model/communication/Communication';
import { ParticipationRequestStatus } from '../../../model/communication/Participant';

export const ConnectedSingleParticipationPendingBottom: React.FC = () => {
  const dispatch = useDispatch();
  const participation = useSelector(state => state.communication.participation);
  const id = useSelector(state => state.communication.id);

  if (!id) return <></>;

  return (
    <BottomBar scrolled>
      <ContentPendingParticipationComponent
        noHeadline
        noUser
        participation={participation}
        onAccept={() => {
          dispatch(invitationResponseRequest({ id, accepted: true }));
        }}
        onDecline={() => {
          dispatch(invitationResponseRequest({ id, accepted: false }));
        }}
      />
    </BottomBar>
  );
};
export interface IConnectedCommunicationInputProps {
  small?: boolean;
  inputId: string;
  removeThreadRoot?: () => void;
  displayThreadRoot?: boolean;
  disabledWithoutThreadroot?: boolean;
}
export const ConnectedCommunicationInput: React.FC<IConnectedCommunicationInputProps> = ({
  inputId,
  small,
  removeThreadRoot,
  displayThreadRoot,
  disabledWithoutThreadroot
}) => {
  const dispatch = useDispatch();

  const draft = useSelector(state => state.communication?.messageDraft?.[inputId]);

  const allowAttachments = useSelector(state => state.communication?.communication?.allowAttachments && state.communication?.participation?.permissions?.allowAttachments);
  const canWriteMessage = useSelector(state => state.communication?.participation?.permissions?.writeMessage);
  const id = useSelector(state => state.communication?.id);

  const pendingSingleOwn = useSelector(
    state => state.communication?.communication?.type === CommunicationType.ONETOONE && state.communication.participation?.status === ParticipationRequestStatus.PENDING
  );

  const pendingSingleOther = useSelector(state => {
    if (state.communication?.communication?.type !== CommunicationType.ONETOONE) return false;
    // find other participation
    var userId = state.foundation.profile?.userId;
    if (!userId) return false;
    var otherParticipation = state.communication.communication.participants.find(p => p.user !== userId);
    if (!otherParticipation) return false;
    return otherParticipation.status === ParticipationRequestStatus.PENDING;
  });

  if (pendingSingleOwn) {
    return <ConnectedSingleParticipationPendingBottom />;
  }

  if (pendingSingleOther) {
    return <></>;
  }

  return (
    <CommunicationInput
      editMode={!!draft?.editMessageId}
      displayThreadRoot={displayThreadRoot}
      threadRoot={draft?.threadRoot}
      removeThreadRoot={removeThreadRoot}
      quotes={draft?.quotes}
      removeQuotes={() => {
        dispatch(setMessageDraftQuotes({ id: inputId, quotes: undefined }));
      }}
      small={small}
      error={draft?.error}
      allowAttachments={allowAttachments}
      attachments={draft?.attachments}
      setAttachments={attachments => {
        id && dispatch(setMessageDraftAttachments({ id: inputId, attachments, communicationId: id }));
      }}
      canWriteMessage={canWriteMessage}
      disabled={draft?.working || draft?.attachmentsWorking || (disabledWithoutThreadroot && !draft?.threadRoot)}
      value={draft?.content}
      onChange={content => {
        dispatch(setMessageDraftContent({ id: inputId, content }));
      }}
      onSubmit={() => {
        if (id) {
          if (draft.editMessageId && draft?.content) {
            // edit
            dispatch(updateMessageRequest({ communicationId: id, id: inputId, messageId: draft.editMessageId, content: draft.content }));
          } else {
            // create
            if (!draft.content && !draft?.attachments?.length) return;

            dispatch(
              createMessageRequest({
                id: inputId,
                communicationId: id,
                content: draft?.content || '',
                threadRoot: draft.threadRoot,
                quotes: draft.quotes,
                attachments: draft.attachments
              })
            );
          }
        }
      }}
      onCancel={() => {
        dispatch(setMessageDraftEdit({ id: inputId }));
      }}
    />
  );
};
