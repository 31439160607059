import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { IConferencingContext } from '../..';
import { IConferencingRoom } from '../model/room';

import { IConferencingSession } from '../model/session';

declare var window: Window & typeof globalThis & { webkitAudioContext: AudioContext };

const arrayAdj = ['quick', 'slow', 'big', 'small', 'fluid', 'fluffy', 'furry', 'crazy', 'silent'];
const arrayColor = ['green', 'yellow', 'brown', 'black', 'red', 'pink', 'violet', 'blue', 'orange', 'white', 'grey'];
const arrayAnimal = ['cat', 'dog', 'fox', 'frog', 'elephant', 'rhino', 'meercat', 'tiger', 'leopard'];
const getRandomName = () => {
  const r1 = Math.floor(Math.random() * 1000) % arrayAdj.length;
  const r2 = Math.floor(Math.random() * 1000) % arrayColor.length;
  const r3 = Math.floor(Math.random() * 1000) % arrayAnimal.length;
  return arrayAdj[r1] + ' ' + arrayColor[r2] + ' ' + arrayAnimal[r3];
};

const silentAudio = () => {
  const wAudioContext: any =
    window.AudioContext ||
    window.webkitAudioContext || // Safari and old versions of Chrome
    false;
  if (wAudioContext) {
    const ac = new wAudioContext();
    const msd = ac.createMediaStreamDestination();
    return msd.stream.getAudioTracks()[0];
  } else {
    if ((process.env.NODE_ENV === 'production' && localStorage['__vidConfDbg'] !== '1') || localStorage['__vidConfDbg'] === '-1') {
      return null;
    }
    console.log('Your browser does not support Web Audio API. Please upgrade for support!');
  }
};

type events =
  | 'room-update'
  | 'session-update'
  | 'jsep-offer'
  | 'jsep-answer'
  | 'media-update'
  | 'publisher-update'
  | 'toggle-desktop'
  | 'destroyed'
  | 'speakers-update'
  | 'sink-update';

export class ConferencingClient {
  public started = false;
  public emitter?: IConferencingContext;
  private _dummyCanvas: HTMLCanvasElement | undefined;
  private _dummyCanvasTask: any;
  private _dummyVideoTrack: MediaStreamTrack | undefined;
  private _desktop = false;
  private _desktopMediaStream: MediaStream | undefined = undefined;
  private _desktopClient: ConferencingClient | undefined = undefined;
  private _token: string = '';
  private _meetingPoint: string = '';
  private _currentPublisher: any = {};
  private _currentSession: IConferencingSession = {};
  private _currentRoom: IConferencingRoom = {};
  private _displayName: string | undefined = undefined;
  private _userId: string | undefined = undefined;
  private _hubConnection: HubConnection | undefined = undefined;
  private _outgoingPeerConnection: RTCPeerConnection | undefined = undefined;
  private _keepAliveInterval: number | undefined = undefined;
  private _preferredResolution = { width: 800, height: 600 };
  private _maxVideoParticipantCount: number | undefined = undefined;
  private _audioLevelContext: AudioContext | undefined;
  private _audioLevelInterval: any;
  private _speakingInterval: any;
  private _speakerTimestamps: { [key: string]: number } = {};
  private _currentSpeakers: string[] = [];
  private _dominantSpeaker = false;
  private _iceServers: any;

  private _events: {
    [key: string]: Function;
  } = {};
  public on(event: events, cb: Function): void;
  public on(event: string, cb: Function) {
    this._events[event] = cb;
  }

  public off(event: events, cb: Function): void;
  public off(event: string, cb: Function) {
    delete this._events[event];
  }

  private raiseEvent(event: events, args: any): void;
  private raiseEvent(event: string, args: any) {
    if (!['speakers-update'].includes(event)) {
      this.log('ConferencingClient::raiseEvent desktop:', this._desktop, 'event:', event, 'args:', args);
    }
    this._events[event]?.(args);
    if (event === 'publisher-update') {
      const pubCount = Object.keys(this._currentPublisher).filter(k => this._currentPublisher[k].video).length;
      const updateResolution = () => {
        if (this._currentSession.video && this._outgoingPeerConnection) {
          this._outgoingPeerConnection.getSenders().forEach(sender => {
            if (sender.track?.kind === 'video') {
              sender.track.applyConstraints({
                height: { max: this._preferredResolution.height, ideal: this._preferredResolution.height, min: 90 },
                width: { max: this._preferredResolution.width, ideal: this._preferredResolution.width, min: 160 }
              });
            }
          });
        }
      };
      if (pubCount > 1) {
        if (this._preferredResolution.height !== 240) {
          this._preferredResolution = { width: 320, height: 240 };
          updateResolution();
        }
      } else {
        if (this._preferredResolution.height !== 600) {
          this._preferredResolution = { width: 800, height: 600 };
          updateResolution();
        }
      }
    }
  }

  constructor(desktop: boolean = false) {
    this._desktop = desktop;
    this._dummyCanvas = document.createElement('canvas');
    this._dummyCanvas.width = 64;
    this._dummyCanvas.height = 48;
  }

  private log(...args: any) {
    if ((process.env.NODE_ENV === 'production' && localStorage['__vidConfDbg'] !== '1') || localStorage['__vidConfDbg'] === '-1') {
      return;
    }
    console.log(...args);
  }

  public async toggleDesktop() {
    if (!this._desktopClient) {
      try {
        this._desktopClient = new ConferencingClient(true);
        this._desktopClient.on('session-update', (session: IConferencingSession) => {
          if (session.ended) {
            this.log('ConferencingClient:: desktop session ended');
            this._desktopClient?.stop();
            this._desktopClient = undefined;
          }
        });
        this._desktopClient.on('destroyed', () => {
          this._desktopClient = undefined;
          this._desktop = false;
          this.raiseEvent('toggle-desktop', false);
        });
        this._desktopClient.setDisplayName(this._displayName);
        this._desktopClient.setUserId(this._userId);
        await this._desktopClient.start(this._meetingPoint, this._token);
      } catch (e) {
        this.log(e);
        if (this._desktopClient) {
          this._desktopClient.stop();
          this._desktopClient = undefined;
        }
        return;
      }
    } else {
      this._desktopClient.stop();
      this._desktopClient = undefined;
    }
    this.raiseEvent('toggle-desktop', !!this._desktopClient);
  }

  public updatePublisher(room: IConferencingRoom) {
    this._currentRoom = room;
    const newPublisher: any = {};
    let videoParticipantCount = 0;
    for (let member of (room?.member || []).sort((ma, mb) => {
      if (this._dominantSpeaker) {
        if (this._speakerTimestamps[ma.sessionIdInternal ?? ''] && !this._speakerTimestamps[mb.sessionIdInternal ?? '']) {
          return -1;
        }
        if (!this._speakerTimestamps[ma.sessionIdInternal ?? ''] && this._speakerTimestamps[mb.sessionIdInternal ?? '']) {
          return 1;
        }
        if (this._speakerTimestamps[ma.sessionIdInternal ?? ''] && this._speakerTimestamps[mb.sessionIdInternal ?? '']) {
          return this._speakerTimestamps[mb.sessionIdInternal ?? ''] - this._speakerTimestamps[ma.sessionIdInternal ?? ''];
        }
      }
      if (ma.video && !mb.video) {
        return -1;
      }
      if (!ma.video && mb.video) {
        return 1;
      }
      if (ma.video && mb.video) {
        return (ma.videoTimestamp ?? 0) - (mb.videoTimestamp ?? 0);
      }
      return 0;
    })) {
      // the following line hides screensharing to the sharing user
      //if (member.publishing && member.publisherIdJanus && (!member.desktop || (!this._desktopClient && member.desktop))) {
      // the following line makes the screenshare stream visible to all users even the user who is sharing
      if (member.publishing && member.publisherIdJanus) {
        if (!this._currentPublisher[member.publisherIdJanus]) {
          this._currentPublisher[member.publisherIdJanus] = member;
          const newPC = new RTCPeerConnection({ iceServers: this._iceServers });
          newPC.ontrack = e => {
            if (member?.publisherIdJanus && this._currentPublisher[member.publisherIdJanus]) {
              const receivers = this._currentPublisher[member.publisherIdJanus].pc.getReceivers();
              const newMedia = new MediaStream();
              for (let receiver of receivers) {
                if (receiver.track) {
                  newMedia.addTrack(receiver.track);
                }
              }
              this._currentPublisher[member.publisherIdJanus].media = newMedia;
              this.raiseEvent('publisher-update', this._currentPublisher);
            }
          };
          newPC.onicecandidate = ev => {
            this._hubConnection?.invoke('sub-trickle', { candidate: ev.candidate }, member.publisherIdJanus);
          };
          newPublisher[member.publisherIdJanus] = {
            pc: newPC
          };
          videoParticipantCount += member.video && !member.desktop ? 1 : 0;
          if (!member.desktop) {
            let disableVideo = false;
            if (videoParticipantCount > (this._maxVideoParticipantCount ?? 8)) {
              disableVideo = true;
            }
            if (
              newPublisher[member.publisherIdJanus].video !== (disableVideo ? false : member.video) ||
              newPublisher[member.publisherIdJanus].videoDisabled !== disableVideo
            ) {
              newPublisher[member.publisherIdJanus].video = disableVideo ? false : member.video;
              newPublisher[member.publisherIdJanus].videoDisabled = disableVideo;
              this.log('ConferencingClient::subJoin audio:', true, 'video:', disableVideo ? false : member.video);
              this._hubConnection?.invoke('sub-join', member.publisherIdJanus, true, disableVideo ? false : member.video);
            }
          } else {
            this.log('ConferencingClient::subJoin audio:', false, 'video:', true);
            this._hubConnection?.invoke('sub-join', member.publisherIdJanus, true, true);
          }
        } else {
          videoParticipantCount += member.video && !member.desktop ? 1 : 0;
          newPublisher[member.publisherIdJanus] = this._currentPublisher[member.publisherIdJanus];
        }
        if (!member.desktop) {
          let disableVideo = false;
          if (videoParticipantCount > (this._maxVideoParticipantCount ?? 8)) {
            disableVideo = true;
          }
          if (newPublisher[member.publisherIdJanus].video !== (disableVideo ? false : member.video) || newPublisher[member.publisherIdJanus].videoDisabled !== disableVideo) {
            newPublisher[member.publisherIdJanus].video = disableVideo ? false : member.video;
            newPublisher[member.publisherIdJanus].videoDisabled = disableVideo;
            this.log('ConferencingClient::subConfigure audio:', true, 'video:', disableVideo ? false : member.video);
            this._hubConnection?.invoke('sub-configure', member.publisherIdJanus, true, disableVideo ? false : member.video);
          }
        } else {
          newPublisher[member.publisherIdJanus].video = member.video;
        }
        newPublisher[member.publisherIdJanus].audio = member.audio;
        newPublisher[member.publisherIdJanus].desktop = member.desktop;
        newPublisher[member.publisherIdJanus].hand = member.hand;
        newPublisher[member.publisherIdJanus].displayName = member.displayName;
        newPublisher[member.publisherIdJanus].userId = member.userId;
        newPublisher[member.publisherIdJanus].publisherIdJanus = member.publisherIdJanus;
        newPublisher[member.publisherIdJanus].sessionIdInternal = member.sessionIdInternal;
      }
    }
    for (let key in this._currentPublisher) {
      if (!newPublisher[key]) {
        this._currentPublisher[key].pc?.close();
      }
    }
    this.log('ConferencingClient::videoParticipantCount', videoParticipantCount);
    this._currentPublisher = newPublisher;
    this.raiseEvent('publisher-update', this._currentPublisher);
    this.raiseEvent('room-update', this._currentRoom);
  }

  public async start(meetingPoint: string, token: string) {
    this.log('ConferencingClient::start');
    this._meetingPoint = meetingPoint;
    this._token = token;
    if (!this._displayName) {
      throw new Error('displayName not set');
    }
    if (!this._userId) {
      throw new Error('userId not set');
    }
    if (this._desktop) {
      this._desktopMediaStream = await (navigator.mediaDevices as any).getDisplayMedia().catch(() => undefined);
      if (!this._desktopMediaStream) throw new Error('user denied desktop');
      this._desktopMediaStream.getVideoTracks()[0].onended = () => {
        this.stop();
      };
    }
    if (this._dummyCanvas) {
      const ctx = this._dummyCanvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = 'darkgray';
        this._dummyCanvasTask = setInterval(() => {
          ctx.clearRect(0, 0, 64, 36);
          ctx.fillRect(0, 0, 64, 36);
        }, 20);
      }
      const cv: any = this._dummyCanvas;
      const stream = cv.captureStream ? cv.captureStream(30) : cv.mozCaptureStream(30);
      this._dummyVideoTrack = stream.getVideoTracks()[0];
    }
    this.started = true;
    const hubConnBuilder = new HubConnectionBuilder()
      .withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 5000 })
      .withUrl('/kaiaulu-api/video-hub?mp=' + meetingPoint + '&d=' + this._desktop + '&t=' + token);
    if (process.env.NODE_ENV === 'production') {
      hubConnBuilder.configureLogging(LogLevel.Error);
    }
    this._hubConnection = hubConnBuilder.build();
    this._hubConnection.on('speaking', sessionIdInternal => {
      this.log('ConferencingClient::speaking:HUB_ON', sessionIdInternal);
      this._speakerTimestamps[sessionIdInternal] = Date.now();
    });
    this._hubConnection.on('session-update', async (session: any) => {
      if (!this._currentSession.room && session.room && this._outgoingPeerConnection) {
        this._currentSession = session;
        if (this._desktop) {
          const desktopVideoTrack = this._desktopMediaStream?.getVideoTracks()?.[0];
          if (desktopVideoTrack) {
            this._outgoingPeerConnection.addTransceiver(desktopVideoTrack, { direction: 'sendonly' });
          }
        } else {
          const silentAudioTrack = silentAudio();
          if (silentAudioTrack) {
            this._outgoingPeerConnection?.addTrack(silentAudioTrack);
          }
          if (this._dummyVideoTrack) {
            this._outgoingPeerConnection.addTransceiver(this._dummyVideoTrack, { direction: 'sendonly' });
          }
        }
        const offer = await this._outgoingPeerConnection.createOffer();
        if (offer) {
          await this._outgoingPeerConnection.setLocalDescription(offer);
          this._hubConnection?.invoke('publish-in-room', offer);
        }
        const initialStream = new MediaStream();
        if (this._dummyVideoTrack) {
          initialStream.addTrack(this._dummyVideoTrack);
        }
        this.raiseEvent('media-update', initialStream);
      } else {
        this._currentSession = session;
      }
      this.raiseEvent('session-update', { ...this._currentSession });
    });
    if (!this._desktop) {
      this._hubConnection.on('room-update', (room: IConferencingRoom) => {
        this.updatePublisher(room);
      });
      this._hubConnection.on('jsep-offer', async (offer: any) => {
        const publisher = this._currentPublisher[offer.feed];
        if (publisher && publisher.pc && offer.jsep) {
          await publisher.pc.setRemoteDescription(offer.jsep);
          const answer = await publisher.pc.createAnswer();
          await publisher.pc.setLocalDescription(answer);
          await this._hubConnection?.invoke('sub-start', offer.feed, answer);
        }
      });
    }
    this._hubConnection.on('jsep-answer', (answer: any) => {
      if (this._outgoingPeerConnection) {
        this._outgoingPeerConnection.setRemoteDescription(answer);
      }
    });
    this._hubConnection.on('connected', async iceServers => {
      this.cleanupSession();
      this._iceServers = iceServers;
      this._speakingInterval = setInterval(() => {
        const keys = Object.keys(this._speakerTimestamps);
        const now = Date.now();
        let changed = false;
        let triggerUpdate = false;
        for (let key of keys) {
          if (now - this._speakerTimestamps[key] < 1000) {
            if (!this._currentSpeakers.includes(key)) {
              this._currentSpeakers.push(key);
              changed = true;
              triggerUpdate = true;
            }
          } else {
            if (this._currentSpeakers.includes(key)) {
              this._currentSpeakers.splice(this._currentSpeakers.indexOf(key));
              triggerUpdate = true;
            }
          }
        }
        if (triggerUpdate) {
          this.raiseEvent('speakers-update', [...this._currentSpeakers]);
        }
        if (changed && this._dominantSpeaker) {
          this.updatePublisher(this._currentRoom);
        }
      }, 1000);
      this._outgoingPeerConnection = new RTCPeerConnection({ iceServers });
      this._outgoingPeerConnection['statsinterval'] = setInterval(() => {
        let statsObject = {
          audio: {
            jitter: undefined,
            rtt: undefined,
            packetsLost: undefined,
            packetsReceived: undefined
          },
          video: {
            jitter: undefined,
            rtt: undefined,
            packetsLost: undefined,
            packetsReceived: undefined
          }
        };
        for (let out of this._outgoingPeerConnection?.getSenders() || []) {
          out.getStats().then(rep => {
            const iter = rep.entries();
            let entry: IteratorResult<[string, any], any>;
            while ((entry = iter.next()) && !entry.done) {
              const value = entry.value[1];
              if ((value.type || '').includes('inbound') && (value.type || '').includes('rtp')) {
                if (value.kind === 'video') {
                  statsObject.video.jitter = value.jitter;
                  statsObject.video.rtt = value.roundTripTime;
                  statsObject.video.packetsLost = value.packetsLost;
                  statsObject.video.packetsReceived = value.packetsReceived;
                }
                if (value.kind === 'audio') {
                  statsObject.audio.jitter = value.jitter;
                  statsObject.audio.rtt = value.roundTripTime;
                  statsObject.audio.packetsLost = value.packetsLost;
                  statsObject.audio.packetsReceived = value.packetsReceived;
                }
              }
            }
          });
        }
        this.emitter?.emit?.(statsObject, 'out');
      }, 1000);
      this._outgoingPeerConnection.onicecandidate = ev => {
        this._hubConnection?.invoke('trickle', { candidate: ev.candidate });
      };
      this._outgoingPeerConnection.oniceconnectionstatechange = ev => {
        this.log('ConferencingClient::oniceconnectionstatechange state:', this._outgoingPeerConnection?.iceConnectionState);
      };
      this._outgoingPeerConnection.onnegotiationneeded = async () => {
        if (this._outgoingPeerConnection?.iceConnectionState === 'connected') {
          const newOffer = await this._outgoingPeerConnection?.createOffer();
          if (newOffer) {
            await this._outgoingPeerConnection?.setLocalDescription(newOffer);
            await this._hubConnection?.invoke('configure', newOffer);
          }
        }
      };
      this.log('ConferencingClient:: updating session');
      await this._hubConnection?.invoke('update-session', {
        displayName: this._displayName,
        userId: this._userId
      });
      this._keepAliveInterval = setInterval(() => {
        this._hubConnection?.invoke('ping').catch(() => {
          this._hubConnection?.start();
        });
      }, 10000) as any;
    });
    this._hubConnection.on('reconnect', () => {
      this.cleanupSession();
      this._hubConnection
        ?.stop()
        .then(() => {
          this._hubConnection?.start();
        })
        .catch(() => {
          this._hubConnection?.start();
        });
    });
    this._hubConnection.start();
  }

  public async toggleAudio() {
    let newAudioMediaTrack: MediaStreamTrack | undefined = undefined;
    let audioLevel = false;
    if (!this._currentSession.audio) {
      audioLevel = true;
      let media = await navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: localStorage['__vidConfDevAud'] ? { exact: localStorage['__vidConfDevAud'] } : undefined
          }
        })
        .catch(() => {
          console.error('user denied media permission or media not available; trying next free device');
          return undefined;
        });
      if (!media) {
        media = await navigator.mediaDevices
          .getUserMedia({
            audio: true
          })
          .catch(() => {
            console.error('user denied media permission or media not available');
            return undefined;
          });
      }
      const tracks = media?.getAudioTracks() || [];
      if (tracks.length > 0) {
        newAudioMediaTrack = tracks[0];
      }
    } else {
      const silentAudioTrack = silentAudio();
      if (silentAudioTrack) {
        newAudioMediaTrack = silentAudioTrack;
      }
    }
    if (newAudioMediaTrack && this._outgoingPeerConnection) {
      const senders = this._outgoingPeerConnection.getSenders();
      for (let sender of senders) {
        if (sender.track?.kind === 'audio') {
          sender.track?.stop();
          sender.replaceTrack(newAudioMediaTrack);
          await this._hubConnection?.invoke('update-session', { audio: !this._currentSession?.audio });
          break;
        }
      }
      setTimeout(() => {
        const newMedia = new MediaStream();
        let audioTrack: MediaStreamTrack | undefined;
        for (let sender of this._outgoingPeerConnection?.getSenders() || []) {
          if (sender.track) {
            newMedia.addTrack(sender.track);
            if (sender.track.kind === 'audio') {
              audioTrack = sender.track;
            }
          }
        }
        this.ensureAudioLevel(audioLevel ? audioTrack : undefined);
        this.raiseEvent('media-update', newMedia);
      });
    }
  }

  public async toggleVideo() {
    let newVideoMediaTrack: MediaStreamTrack | undefined = undefined;
    let stopTrack = false;
    if (!this._currentSession.video) {
      let media = await navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: localStorage['__vidConfDevVid'] ? { exact: localStorage['__vidConfDevVid'] } : undefined,
            facingMode: 'user',
            height: { max: this._preferredResolution.height, ideal: this._preferredResolution.height, min: 90 },
            width: { max: this._preferredResolution.width, ideal: this._preferredResolution.width, min: 160 },
            frameRate: 30
          }
        })
        .catch(() => {
          console.error('user denied media permission or media not available; trying without facingMode');
          return undefined;
        });
      if (!media) {
        media = await navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: localStorage['__vidConfDevVid'] ? { exact: localStorage['__vidConfDevVid'] } : undefined,
              height: { max: this._preferredResolution.height, ideal: this._preferredResolution.height, min: 90 },
              width: { max: this._preferredResolution.width, ideal: this._preferredResolution.width, min: 160 },
              frameRate: 30
            }
          })
          .catch(() => {
            console.error('user denied media permission or media not available; trying deviceId only');
            return undefined;
          });
      }
      if (!media) {
        media = await navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: localStorage['__vidConfDevVid'] ? { exact: localStorage['__vidConfDevVid'] } : undefined
            }
          })
          .catch(() => {
            console.error('user denied media permission or media not available; trying constraints only');
            return undefined;
          });
      }
      if (!media) {
        media = await navigator.mediaDevices
          .getUserMedia({
            video: {
              height: { max: this._preferredResolution.height, ideal: this._preferredResolution.height, min: 90 },
              width: { max: this._preferredResolution.width, ideal: this._preferredResolution.width, min: 160 },
              frameRate: 30
            }
          })
          .catch(() => {
            console.error('user denied media permission or media not available; trying without constraints');
            return undefined;
          });
      }
      if (!media) {
        media = await navigator.mediaDevices
          .getUserMedia({
            video: true
          })
          .catch(() => {
            console.error('user denied media permission or media not available');
            return undefined;
          });
      }
      const tracks = media?.getVideoTracks() || [];
      if (tracks.length > 0) {
        newVideoMediaTrack = tracks[0];
      }
    } else {
      newVideoMediaTrack = this._dummyVideoTrack;
      stopTrack = true;
    }
    if (newVideoMediaTrack && this._outgoingPeerConnection) {
      const senders = this._outgoingPeerConnection.getSenders();
      for (let sender of senders) {
        if (sender.track?.kind === 'video' || !sender.track) {
          if (stopTrack) {
            sender.track?.stop();
          }
          sender.replaceTrack(newVideoMediaTrack);
          await this._hubConnection?.invoke('update-session', { video: !this._currentSession?.video });
          break;
        }
      }
      setTimeout(() => {
        const newMedia = new MediaStream();
        for (let sender of this._outgoingPeerConnection?.getSenders() || []) {
          if (sender.track) {
            newMedia.addTrack(sender.track);
          }
        }
        this.raiseEvent('media-update', newMedia);
      });
    }
  }

  public async toggleHand() {
    await this._hubConnection?.invoke('update-session', { hand: !this._currentSession?.hand });
  }

  public stop() {
    this.started = false;
    this.cleanupSession();
    if (this._hubConnection) {
      this._hubConnection.stop().catch(() => {});
      this._hubConnection = undefined;
    }
    this._displayName = undefined;
    this._userId = undefined;
    if (this._dummyCanvasTask) {
      clearInterval(this._dummyCanvasTask);
      this._dummyCanvasTask = undefined;
    }
    this.raiseEvent('destroyed', undefined);
  }

  public cleanupSession() {
    for (let key in this._currentPublisher) {
      if (this._currentPublisher[key]) {
        this._currentPublisher[key].pc?.close();
      }
    }
    this.closeAudioLevelContext();
    this._currentPublisher = {};
    this._currentRoom = {};
    this._currentSession = {};
    this._currentSpeakers = [];
    this._iceServers = undefined;
    this.raiseEvent('publisher-update', this._currentPublisher);
    this.raiseEvent('room-update', this._currentRoom);
    this.raiseEvent('session-update', { ...this._currentSession });
    this.raiseEvent('speakers-update', [...this._currentSpeakers]);
    if (this._outgoingPeerConnection) {
      for (let sender of this._outgoingPeerConnection?.getSenders() || []) {
        if (sender.track) {
          sender.track.stop();
        }
      }
      this._outgoingPeerConnection.close();
      clearInterval(this._outgoingPeerConnection['statsinterval']);
      this._outgoingPeerConnection = undefined;
      if (this._desktopClient) {
        this._desktopClient.stop();
        this._desktopClient = undefined;
      }
    }
    if (this._keepAliveInterval) {
      clearInterval(this._keepAliveInterval);
      this._keepAliveInterval = undefined;
    }
    if (this._speakingInterval) {
      clearInterval(this._speakingInterval);
      this._speakingInterval = undefined;
    }
    this._speakerTimestamps = {};
  }

  public setRandomName() {
    this._displayName = getRandomName();
  }

  public setDisplayName(displayName: string | undefined) {
    if (!displayName) {
      this.setRandomName();
    } else {
      this._displayName = displayName;
    }
  }

  public setUserId(id: string | undefined) {
    this._userId = id;
  }

  public setMaxVideoParticipants(maxVideoParticipantCount?: number) {
    this._maxVideoParticipantCount = maxVideoParticipantCount;
  }

  public setDominantSpeaker(enable?: boolean) {
    this._dominantSpeaker = !!enable;
  }

  public closeAudioLevelContext() {
    if (this._audioLevelInterval) {
      clearInterval(this._audioLevelInterval);
      this._audioLevelInterval = undefined;
    }
    if (this._audioLevelContext) {
      this._audioLevelContext.close();
      this._audioLevelContext = undefined;
    }
  }

  public async ensureAudioLevel(streamTrack: MediaStreamTrack | undefined) {
    this.closeAudioLevelContext();
    if (streamTrack && streamTrack.kind === 'audio') {
      const newAudioContext = new AudioContext();
      await newAudioContext.audioWorklet.addModule('/audio/index.js');
      const audioLevelNode = new AudioWorkletNode(newAudioContext, 'audio-level-detector');
      audioLevelNode.port.onmessage = ev => {
        if (ev.data > 0) {
          if (!this._currentSession?.activeSpeaker) {
            this._currentSession.activeSpeaker = 1;
          } else {
            this._currentSession.activeSpeaker++;
          }
          this._currentSession.inactiveSpeaker = 0;
          if (this._currentSession.activeSpeaker > 12) {
            // raises speaking-event only after continuous speaking for three seconds (interval 250 ms times count)
            this._hubConnection?.invoke('speaking');
          }
          if (!this._currentSession.speaking) {
            this._currentSession.speaking = true;
            this.raiseEvent('session-update', { ...this._currentSession });
          }
        } else {
          if (!this._currentSession?.inactiveSpeaker) {
            this._currentSession.inactiveSpeaker = 1;
          } else {
            this._currentSession.inactiveSpeaker++;
          }
          if (this._currentSession.inactiveSpeaker > 12) {
            // continuous speaking is reset if pause of at least three seconds
            this._currentSession.activeSpeaker = 0;
          }
          if (this._currentSession.speaking) {
            this._currentSession.speaking = false;
            this.raiseEvent('session-update', { ...this._currentSession });
          }
        }
      };
      const mss = newAudioContext.createMediaStreamSource(new MediaStream([streamTrack]));
      mss.connect(audioLevelNode);
      this._audioLevelContext = newAudioContext;
      this._audioLevelInterval = setInterval(() => {
        audioLevelNode.port.postMessage('report');
      }, 250);
    }
  }

  public async updateDevices(audio: string, audioOut: string, video: string) {
    let audioChanged = false;
    let audioOutChanged = false;
    let videoChanged = false;
    if (localStorage.__vidConfDevAud !== audio) {
      localStorage.__vidConfDevAud = audio;
      audioChanged = true;
    }
    if (localStorage.__vidConfDevAudOut !== audioOut) {
      localStorage.__vidConfDevAudOut = audio;
      audioOutChanged = true;
    }
    if (localStorage.__vidConfDevVid !== video) {
      localStorage.__vidConfDevVid = video;
      videoChanged = true;
    }
    localStorage['__vidConfDevAudOut'] = audioOut;
    localStorage['__vidConfDevVid'] = video;
    if (this._currentSession.audio && audioChanged) {
      let newAudioMediaTrack: MediaStreamTrack | undefined = undefined;
      const media = await navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: audio ? { exact: audio } : undefined,
          },
        })
        .catch(() => {
          console.error('user denied media permission or media not available');
          return undefined;
        });
      const tracks = media?.getAudioTracks() || [];
      if (tracks.length > 0) {
        newAudioMediaTrack = tracks[0];
      }
      if (newAudioMediaTrack && this._outgoingPeerConnection) {
        const senders = this._outgoingPeerConnection.getSenders();
        for (let sender of senders) {
          if (sender.track?.kind === 'audio') {
            sender.track?.stop();
            await sender.replaceTrack(newAudioMediaTrack);
            break;
          }
        }
        setTimeout(() => {
          const newMedia = new MediaStream();
          let audioTrack: MediaStreamTrack | undefined;
          for (let sender of this._outgoingPeerConnection?.getSenders() || []) {
            if (sender.track) {
              newMedia.addTrack(sender.track);
              if (sender.track.kind === 'audio') {
                audioTrack = sender.track;
              }
            }
          }
          this.ensureAudioLevel(audioTrack);
          this.raiseEvent('media-update', newMedia);
        });
      }
    }
    if (this._currentSession.video && videoChanged) {
      let newVideoMediaTrack: MediaStreamTrack | undefined = undefined;
      const media = await navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: localStorage['__vidConfDevVid'] ? { exact: localStorage['__vidConfDevVid'] } : undefined,
            height: { max: this._preferredResolution.height, ideal: this._preferredResolution.height, min: 90 },
            width: { max: this._preferredResolution.width, ideal: this._preferredResolution.width, min: 160 },
            frameRate: 30,
          },
        })
        .catch(() => {
          console.error('user denied media permission or media not available');
          return undefined;
        });
      const tracks = media?.getVideoTracks() || [];
      if (tracks.length > 0) {
        newVideoMediaTrack = tracks[0];
      }
      if (newVideoMediaTrack && this._outgoingPeerConnection) {
        const senders = this._outgoingPeerConnection.getSenders();
        for (let sender of senders) {
          if (sender.track?.kind === 'video') {
            sender.track?.stop();
            await sender.replaceTrack(newVideoMediaTrack);
            break;
          }
        }
        setTimeout(() => {
          const newMedia = new MediaStream();
          for (let sender of this._outgoingPeerConnection?.getSenders() || []) {
            if (sender.track) {
              newMedia.addTrack(sender.track);
            }
          }
          this.raiseEvent('media-update', newMedia);
        });
      }
    }
    if (audioOutChanged) {
      this.raiseEvent('sink-update', null);
    }
  }
}
