import { Bx, CheckboxControl, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';

interface IFormUserTerms {
  formData: any;
  headline?: string;
  subtitle?: string;
  subtitle2: string;
  subtitle3: string;
  onChange: (value: any) => void;
}

export const FormUserTerms: React.FC<IFormUserTerms> = ({ formData, headline, subtitle, subtitle2, subtitle3, onChange }) => {
  const [inputVal, setInputVal] = React.useState({
    termsAccepted: formData?.itemData?.termsAccepted,
    communityRulesAccepted: formData?.itemData?.communityRulesAccepted
  });

  return (
    <React.Fragment>
      {headline && (
        <Bx mb={2}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      {subtitle2 && <><br/><Typo variant="subtitle1">{subtitle2}</Typo></>}
      {subtitle3 && <><br/><Typo variant="subtitle1">{subtitle3}</Typo></>}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid xs={12} item>
            <Bx mt={{ xs: 1, md: 2, lg: 3 }}>
              <Bx pl={1}>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'termsAccepted',
                    value: inputVal.termsAccepted,
                    onChange: e => {
                      setInputVal({ ...inputVal, [e.target.name]: e.target.checked });
                      onChange({ ...formData, itemData: { ...formData?.itemData, termsAccepted: e.target.checked } });
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={inputVal.termsAccepted}
                  label={
                    <Typo variant={'h5'} fontWeight={500}>
                      Ich habe die{' '}
                      <Link to="/content/page/nutzungsbedingungen">
                        Nutzungsbedingungen
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
              <Bx mt={3} pl={1}>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'communityRulesAccepted',
                    value: inputVal.communityRulesAccepted,
                    onChange: e => {
                      setInputVal({ ...inputVal, [e.target.name]: e.target.checked });
                      onChange({ ...formData, itemData: { ...formData?.itemData, communityRulesAccepted: e.target.checked } });
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={inputVal.communityRulesAccepted}
                  label={
                    <Typo variant={'h5'} fontWeight={500}>
                      Ich habe die{' '}
                      <Link to="/content/page/gemeinschaftsstandards" target="_blank">
                        Gemeinschaftsstandards
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
