import { Ava, Bx, ExtendedListItem, IconAva, Input, TimeAva, Typo } from '@curry-group/mui-curcuma';
import { faExternalLink, faQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { resolveTimestampDateOnly } from '../../../helper';
import { ThingTypes, transformForList } from '../../../model/ryve/Thing';
import { ISearchResultItem } from '../../../model/search/SearchResultItem';

interface IFormShareMessage {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormShareMessage: React.FC<IFormShareMessage> = ({ formData, headline, subtitle, onChange }) => {
  const item = useSelector(state => state.flow.flowData?.itemData?.sharedItem);
  const searchResultMimic: ISearchResultItem = {
    _id: item._id || item.userId,
    _score: 0,
    _source: item.userId
      ? {
          type: ThingTypes.Profile,
          content: item
        }
      : item
  };
  const listItem = transformForList([searchResultMimic])?.[0];

  const getListItemAvatar: () => any = () => {
    switch (listItem.avatar.type){
      case 'user':
      case 'image':
        return <Ava size="large" src={listItem.avatar.image}/>;
      case 'time':
        return <TimeAva size="large" fromTime={listItem.avatar.time?.from || ''} toTime={listItem.avatar.time?.to || ''}/>;
      case 'icon':
      default:
        return <IconAva color={listItem.color} children={<FontAwesomeIcon icon={listItem.avatar.icon || faQuestion} />} size="large" />;
    }
  }

  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <Input
              inputLabel={{ children: 'Ihr Beitrag' }}
              input={{
                style: { paddingBottom: 5 },
                value: formData?.itemData?.message,
                placeholder: 'Erzählen Sie uns mehr...',
                multiline: true,
                rows: 3,
                onChange: val => {
                  onChange({ ...formData, itemData: { ...formData?.itemData, message: val.target.value } });
                }
              }}
              attachments={
                listItem && (
                  <>
                    <Bx mt={2} mb={2}>
                      <Divider />
                    </Bx>
                    {listItem.typeId !== ThingTypes.News && (
                      <ExtendedListItem
                        py={1}
                        avatar={getListItemAvatar()}
                      >
                        <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                          <Typo variant="subtitle2" color="textPrimary" component="span" textTransform="uppercase">
                            {listItem.type}
                          </Typo>
                          <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
                            {listItem.title}
                          </Typo>
                        </Bx>
                      </ExtendedListItem>
                    )}
                    {listItem.typeId === ThingTypes.News && (
                      <ExtendedListItem
                        py={1}
                      >
                        <Bx pb={.5}>
                          <Typo variant="subtitle2" color='textPrimary' component="span">
                            {resolveTimestampDateOnly(item.content.pubDate)}
                          </Typo>
                        </Bx>
                        <Bx pb={2}>
                          <Typo variant="subtitle2" color='textSecondary' component="span">
                            {item.content.source}
                          </Typo>
                        </Bx>
                        <Bx pb={1}>
                          <Typo fontWeight={500} color='textPrimary' variant="h3" component="h2">
                            {item.content.title}
                          </Typo>
                        </Bx>
                        <Bx pb={2}>
                          <Typo color='textPrimary' variant="body1" component="span">
                            <FontAwesomeIcon icon={faExternalLink} />
                          </Typo>
                          &nbsp;&nbsp;
                          <Typo color='primary' style={{ textDecoration: 'underline' }} variant="subtitle2" component="span">
                            {item.content.url}
                          </Typo>
                        </Bx>
                      </ExtendedListItem>
                    )}
                  </>
                )
              }
            />
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
