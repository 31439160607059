import { IContainerConfig, INavigationContainerConfig } from '../../../model/configuration';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { ContainerRouting } from '../container';
import { NotFoundComponent } from '../../../components/detail/not-found';

export interface ISectionRoutingProps {
  sections: INavigationContainerConfig[];
  configs: { [alias: string]: IContainerConfig };
}

export const SectionRouting: React.FC<ISectionRoutingProps> = ({ sections, configs }) => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={[match.url]}>
        <Redirect to={match.url + '/' + sections[0].alias} push={true}/>
      </Route>
      {sections.map((section, index) => {
        const config = configs[section.alias];

        if (!config) return <React.Fragment key={index}></React.Fragment>;
        const path = (match.url === '/' ? match.url : match.url + '/') + section.alias;

        return (
          <Route path={[path]} key={index}>
            <ContainerRouting alias={section.alias} />
          </Route>
        );
      })}
      <Route path="*">
        <NotFoundComponent />
      </Route>
    </Switch>
  );
};
