import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  setFlowDataAction,
  setFlowFormDataAction,
  setFlowStepAction,
  setFlowTypeAction,
  setFlowAnonymousAction,
  resetFlowAction,
  finishFlowRequestAction,
  finishFlowErrorAction,
  finishFlowSuccessAction,
  setFlowAliasAction,
  setFlowOptionAction,
  fetchEmployerConfigsRequestAction,
  fetchEmployerConfigsSuccessAction,
  fetchEmployerConfigsErrorAction,
  userProfileRequestValidAction,
  userProfileRequestNotValidAction,
} from '../../actions/flow';

export interface IFlowState {
  currentAlias: string;
  flowAnonymous?: boolean;
  currentStep: number;
  currentType: string;
  flowSteps: any[];
  flowData: any;
  finishWorking: boolean;
  error: string;
  finishUrl: string;
  flowOptions: string[];
  employerConfigsWorking: boolean;
  employerConfigs: any;
  employerConfigsError: any;
  userRequestInvalid: boolean;
  userRequestInvalidAlias?: string;
}

const initialState: IFlowState = {
  currentAlias: '',
  flowAnonymous: undefined,
  currentStep: 0,
  currentType: '',
  flowSteps: [],
  flowData: {},
  finishWorking: false,
  error: '',
  finishUrl: '',
  flowOptions: [],
  employerConfigsWorking: false,
  employerConfigs: null,
  employerConfigsError: '',
  userRequestInvalid: false,
  userRequestInvalidAlias: '',
};

const flow = createReducer(initialState, builder =>
  builder
    .addCase(setFlowDataAction, (state, action) => {
      const currentAlias = state.currentAlias;
      let flowData = {};
      if (currentAlias === action.payload.alias) {
        flowData = _.cloneDeep(state.flowData);
      }
      return { ...state, currentAlias: action.payload.alias, flowData: { ...flowData, ...action.payload.flowData } };
    })
    .addCase(setFlowFormDataAction, (state, action) => {
      const formField = action.payload.formField;
      const formName = action.payload.formName;
      if (formName) {
        return { ...state, flowData: { ...state.flowData, [formName]: { ...(state.flowData?.[formName] || {}), [formField]: action.payload.formValue } } };
      } else {
        return { ...state, flowData: { ...state.flowData, itemData: { ...(state.flowData?.itemData || {}), [formField]: action.payload.formValue } } };
      }
    })
    .addCase(setFlowAliasAction, (state, action) => {
      const currentAlias = state.currentAlias;
      if (action.payload.keepState) {
        return {
          ...state,
          currentAlias: action.payload.alias,
          flowSteps: action.payload.flowSteps,
        };
      } else if (action.payload.alias === 'share') {
        return {
          ...initialState,
          flowData: state.flowData,
          currentAlias: action.payload.alias,
          flowSteps: action.payload.flowSteps,
        };
      } else if (currentAlias === action.payload.alias) {
        return state;
      } else {
        return { ...initialState, currentAlias: action.payload.alias, flowSteps: action.payload.flowSteps };
      }
    })
    .addCase(setFlowAnonymousAction, (state, action) => {
      return { ...state, flowAnonymous: action.payload.anonymous };
    })
    .addCase(setFlowOptionAction, (state, action) => {
      const flowOptions = [...state.flowOptions];
      const optIndex = flowOptions.indexOf(action.payload.option);
      if (optIndex > -1) {
        if (action.payload.remove) {
          flowOptions.splice(optIndex, 1);
        }
      } else {
        if (!action.payload.remove) {
          flowOptions.push(action.payload.option);
        }
      }
      return { ...state, flowOptions };
    })
    .addCase(setFlowStepAction, (state, action) => {
      const stepAdd = state.currentStep - action.payload.step;
      let nextStep = action.payload.step;
      const ignoredSteps = Array<number>();
      const flowSteps = [...state.flowSteps];
      if (state.currentType || state.currentAlias === 'register' || state.currentAlias === 'createmeeting' || state.currentAlias === 'add-participants') {
        for (let i = 0; i < flowSteps.length; i++) {
          const step = flowSteps[i];
          if (step.type && step.type !== state.currentType) {
            ignoredSteps.push(i);
          }
          if (!!step.excludeIfOptionNotSet && step.excludeIfOptionNotSet.length) {
            if (state.flowOptions && state.flowOptions.length > 0) {
              if (_.intersection(step.excludeIfOptionNotSet, state.flowOptions).length !== step.excludeIfOptionNotSet.length) {
                ignoredSteps.push(i);
              }
            } else {
              if (ignoredSteps.indexOf(i) === -1) {
                ignoredSteps.push(i);
              }
            }
          }
        }
      }
      if ((ignoredSteps || []).indexOf(nextStep) > -1) {
        while ((ignoredSteps || []).indexOf(nextStep) > -1) {
          nextStep -= stepAdd;
        }
      }
      return { ...state, currentStep: nextStep };
    })
    .addCase(setFlowTypeAction, (state, action) => {
      return { ...state, currentType: action.payload.type };
    })
    .addCase(resetFlowAction, (state, action) => {
      return { ...initialState };
    })
    .addCase(finishFlowRequestAction, (state, action) => {
      return { ...state, finishWorking: false };
    })
    .addCase(finishFlowErrorAction, (state, action) => {
      return { ...state, finishWorking: true, error: action.payload.message };
    })
    .addCase(finishFlowSuccessAction, (state, action) => {
      return { ...state, finishWorking: true, finishUrl: action.payload.finishUrl };
    })
    .addCase(fetchEmployerConfigsRequestAction, (state, action) => {
      return { ...state, employerConfigsWorking: true };
    })
    .addCase(fetchEmployerConfigsSuccessAction, (state, action) => {
      return { ...state, employerConfigs: action.payload.result, employerConfigsWorking: false };
    })
    .addCase(fetchEmployerConfigsErrorAction, (state, action) => {
      return { ...state, employerConfigsError: action.payload.message, employerConfigsWorking: false };
    })
    .addCase(userProfileRequestValidAction, (state, action) => {
      const tokenInfo = action.payload.tokenInfo;
      const data = !action.payload.messagingPermissions
        ? tokenInfo && tokenInfo.refId
          ? { email: tokenInfo.refId }
          : undefined
        : { messagingPermissions: action.payload.messagingPermissions };
      const token = tokenInfo && tokenInfo._id ? tokenInfo._id : '';
      return { ...state, flowData: { ...state.flowData, itemData: data, token }, userRequestInvalid: false, userRequestInvalidAlias: '' };
    })
    .addCase(userProfileRequestNotValidAction, (state, action) => {
      return { ...state, flowData: { ...state.flowData, itemData: undefined, token: undefined }, userRequestInvalid: true, userRequestInvalidAlias: action.payload.alias };
    })
);
export default flow;
