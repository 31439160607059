import React from 'react';
import { Bx, useBreakpoints } from '@curry-group/mui-curcuma';

import { useTheme } from '@material-ui/core/styles';
import { IConferencingParticipant } from '../../../interfaces';
import { ConferencingParticipant } from '../../../participant';
import useScreenOrientation from '../../../../../helper/hooks/useScreenOrientation';
import { useIsMobile } from '../../../../../helper/hooks/isMobile';
import { isMobile as isMobileDevice } from "react-device-detect";

const GridCalculator: React.FC<{ children?: { key: any; cmp: React.ReactNode }[] }> = ({ children }) => {
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  children = children ?? [];
  let templateColumns: string | undefined = undefined;
  let templateRows: string | undefined = undefined;
  let templateAreas: string | undefined = undefined;
  if (breakpoints.smDown) {
    templateColumns = '1fr';
    templateRows = '1fr';
  } else {
    switch (children.length) {
      case 1: {
        templateColumns = '1fr';
        break;
      }
      case 2: {
        templateColumns = '1fr 1fr';
        break;
      }
      case 3: {
        templateColumns = '1fr 1fr';
        templateRows = '1fr 1fr';
        templateAreas = `
            "ta-0 ta-1"
            "ta-0 ta-2"
            `;
        break;
      }
      case 4: {
        templateColumns = '1fr 1fr';
        templateRows = '1fr 1fr';
        break;
      }
      case 5: {
        templateColumns = '1fr 1fr 1fr 1fr 1fr 1fr';
        templateRows = '4fr 3fr';
        templateAreas = `
            "ta-0 ta-0 ta-0 ta-1 ta-1 ta-1"
            "ta-2 ta-2 ta-3 ta-3 ta-4 ta-4"
            `;
        break;
      }
      case 6: {
        templateColumns = '1fr 1fr 1fr';
        templateRows = '1fr 1fr';
        break;
      }
      case 7: {
        templateColumns = 'repeat(12,1fr)';
        templateRows = '4fr 3fr';
        templateAreas = `
            "ta-0 ta-0 ta-0 ta-0 ta-1 ta-1 ta-1 ta-1 ta-2 ta-2 ta-2 ta-2"
            "ta-3 ta-3 ta-3 ta-4 ta-4 ta-4 ta-5 ta-5 ta-5 ta-6 ta-6 ta-6"
            `;
        break;
      }
      default: {
        templateColumns = '1fr 1fr 1fr 1fr';
        if (children.length > 10) {
          templateColumns += ' 1fr';
        }
        if (children.length > 20) {
          templateColumns += ' 1fr';
        }
        if (children.length > 30) {
          templateColumns += ' 1fr';
        }
        if (children.length > 42) {
          templateColumns += ' 1fr';
        }
        if (children.length > 56) {
          templateColumns += ' 1fr';
        }
        if (children.length > 72) {
          templateColumns += ' 1fr';
        }
        if (children.length > 80) {
          templateColumns += ' 1fr';
        }
        if (children.length > 98) {
          templateColumns += ' 1fr';
        }
        break;
      }
    }
  }
  return (
    <Bx
      display="grid"
      height="100%"
      width="100%"
      style={{
        gridTemplateColumns: templateColumns,
        gridTemplateRows: templateRows,
        gridTemplateAreas: templateAreas,
        gap: theme.spacing(0.5)
      }}
    >
      {children.map((child, idx) => (
        <Bx key={child.key} style={{ gridArea: templateAreas ? 'ta-' + idx : undefined, display: breakpoints.smDown && idx > 0 ? 'none' : 'block' }}>
          {child.cmp}
        </Bx>
      ))}
    </Bx>
  );
};

export const ParticipantGrid: React.FC<{
  participants?: IConferencingParticipant[];
  self?: IConferencingParticipant;
  speakers?: string[];
  userName?: string;
}> = ({ participants, self, speakers, userName }) => {
  const ps = participants || [];
  const theme = useTheme();
  const isMobile = useIsMobile();
  const orientation = useScreenOrientation();
  let unit = 'vh';
  if (isMobileDevice && isMobile && orientation.indexOf('landscape') > -1) {
    unit = 'vw';
  }
  return (
    <Bx height="100%" position="relative">
      <GridCalculator>
        {ps.map((p, idx) => {
          return {
            key: p.id,
            cmp: (
              <Bx width="100%" height="100%" key={p.id} display="flex" justifyContent="center" alignItems="center">
                <ConferencingParticipant
                  audio={p.audio}
                  displayName={p.displayName}
                  hand={p.hand}
                  media={p.media}
                  speaking={speakers?.includes(p.sessionIdInternal ?? '')}
                  video={p.video}
                />
              </Bx>
            )
          };
        })}
      </GridCalculator>
      {self && (
        <Bx position="absolute" top={theme.spacing(2)} right={theme.spacing(2)} width={'calc(13' + unit + ' / 9 * 16)'} height={'13' + unit} style={{ border: 0 }}>
          <ConferencingParticipant
            self
            muted
            audio={self.audio}
            displayName={userName}
            hand={self.hand}
            media={self.media}
            speaking={self.speaking}
            video={self.video}
            small
          />
        </Bx>
      )}
    </Bx>
  );
};
