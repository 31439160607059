import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Messages } from '../../../components/communication/messages';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { IMessageModel, IMessageReferenceMeeting } from '../../../model/communication/Message';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GetMessagesRequest } from '../../../components/communication/header';
import {
  deleteMessageRequest,
  fetchMessagesRequest,
  fetchNewerMessagesRequest,
  fetchOlderMessagesRequest,
  joinConferenceRequest,
  setActionCanvas,
  setLastReadTimestampRequest,
  setMessageDraftThreadRoot,
  setMessagesScrolledBottom
} from '../../../data/reducer/communication';
import { IBxProps } from '@curry-group/mui-curcuma';
import { ParticipationRequestStatus } from '../../../model/communication/Participant';
import { OpenExternalLinkDialog } from '../../../components/dialogs/open-external-link';
import { MessageDeletedDialog } from '../../../components/dialogs/message-deleted';

export interface IConnectedMessagesProps {
  threadingClicked?: (message: IMessageModel) => void;
  voteUpClick?: (message: IMessageModel) => void;
  voteDownClick?: (message: IMessageModel) => void;
  quoteClicked?: (message: IMessageModel) => void;
  emoteClicked?: (message: IMessageModel, emoji: string) => void;
  threadRoot?: IMessageModel;
  forceInlineThreading?: boolean;
  disableThreading?: boolean;
  autoWidth?: boolean;
  request?: GetMessagesRequest;
  alias: string;
  editMessage?: (message: IMessageModel) => void;
  renderEmptyContent?: () => ReactNode;
  scrollContainer?: boolean;
  p?: IBxProps['p'];
}

export const ConnectedMessages: React.FC<IConnectedMessagesProps> = state => {
  const [atBottom, setAtBottom] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const initial = useRef(true);

  let allowThreading = useSelector(state => state.communication?.communication?.allowThreads && state.communication?.participation?.permissions?.allowThreads);
  if (state.disableThreading) allowThreading = false;
  const allowVoting = useSelector(state => state.communication?.communication?.allowVoting && state.communication?.participation?.permissions?.allowVoting);
  const allowQuotes = useSelector(state => state.communication?.communication?.allowQuotes && state.communication?.participation?.permissions?.allowQuotes);

  let inlineThreading = useSelector(state => state.communication?.communication?.inlineThreading);
  if (state.forceInlineThreading) inlineThreading = true;

  const manageMessagesPermission = useSelector(state => state.communication?.participation?.permissions?.manageMessages);
  const mainMessages = useSelector(state => state.communication?.messages?.['main']?.messages);

  const initialMessage = useSelector(state => state.communication?.calledMessage);
  const scrollToMessage = useSelector(state => state.communication?.scrollToMessage);
  const messageInThread = useSelector(state => state.communication?.calledMessageInThread);
  const communicationType = useSelector(state => state.communication?.communication?.type);

  let messageWidth = 100;
  const theme: any = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSM = useMediaQuery(theme.breakpoints.only('sm'));
  const isMD = useMediaQuery(theme.breakpoints.only('md'));
  const isLG = useMediaQuery(theme.breakpoints.only('lg'));
  const isXL = useMediaQuery(theme.breakpoints.only('xl'));
  if (state.autoWidth) {
    messageWidth = isXS ? 85 : messageWidth;
    messageWidth = isSM ? 80 : messageWidth;
    messageWidth = isMD ? 75 : messageWidth;
    messageWidth = isLG ? 70 : messageWidth;
    messageWidth = isXL ? 60 : messageWidth;
  }

  const id = useSelector(state => state.communication?.id);
  const currentUserId = useSelector(state => state.foundation.profile?.userId);
  const messages = useSelector(s => s.communication?.messages?.[state.threadRoot?.id || state.alias]);
  const participants = useSelector(state => state.communication.participants);
  const activeParticipants = participants?.filter(p => p.status === ParticipationRequestStatus.ACCEPTED);
  const usernamesById = useSelector(state => state.communication.usernamesById);
  let dictActiveParticipants = activeParticipants?.reduce((result, participant) => {
    result[participant.user] = participant.userResolved?.content?.fullName;
    return result;
  },{});
  if (!activeParticipants || activeParticipants.length === 0) {
    dictActiveParticipants = usernamesById;
  }

  const externalLinksAllowed = useSelector(state => state.foundation?.profile?.externalLinksAllowed);

  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openMessageDeletedDialog, setOpenMessageDeletedDialog] = useState(false);
  const [openExternalLink, setOpenExternalLink] = useState('');

  useEffect(() => {
    if (id && initial.current) {
      initial.current = false;
      const threadId = state.threadRoot?.id;
      const msgToLoad = !!initialMessage && !!threadId ? undefined : initialMessage;
      dispatch(
        fetchMessagesRequest({
          reset: true,
          alias: state.alias,
          communicationId: id,
          threadRoot: threadId,
          numThreadChildren: state.request?.numThreadChildren,
          message: msgToLoad || messageInThread,
        })
      );
    }
    if (
      communicationType !== 'onetoone' &&
      !!messageInThread &&
      !!scrollToMessage &&
      !!mainMessages &&
      mainMessages.length > 0
    ) {
        const mainMessageThread = mainMessages?.find(m => m.id === scrollToMessage);
        dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: mainMessageThread }));
        dispatch(setActionCanvas({ open: true, threadRoot: mainMessageThread }));
    }
  }, [id, dispatch, state.threadRoot, state.request, state.alias, location, scrollToMessage, initialMessage, mainMessages, communicationType, messageInThread]);

  useEffect(() => {
    if (messages?.hint) {
      setOpenMessageDeletedDialog(true);
      setTimeout(() => {
        setOpenMessageDeletedDialog(false);
      }, 3000);
    }
  }, [messages?.hint]);

  const onMessageRead = useCallback(
    message => {
      id && dispatch(setLastReadTimestampRequest({ id, timestamp: message.createdAt }));
    },
    [id, dispatch]
  );

  function handleMessageReferenceSelected(type: string, data: any) {
    switch (type) {
      case 'meeting':
        var { alias } = data as IMessageReferenceMeeting;
        history.push(location.pathname + '/meetings/' + alias);
        break;
      case 'link':
        if (!externalLinksAllowed) {
          setOpenExternalLink(data);
          setOpenExternalLinkDialog(true);
        }
    }
  }
  return (
    <>
      <Messages
        alias={state.alias}
        currentUserId={currentUserId || ''}
        p={state.p}
        scrolledDown={messages?.atBottom}
        setScrolledDown={down => {
          dispatch(setMessagesScrolledBottom({ alias: state.alias, atBottom: down }));
        }}
        scrollContainer={state.scrollContainer}
        renderEmptyContent={state.renderEmptyContent}
        dateDivider={true}
        allowQuotes={allowQuotes}
        quoteClicked={state.quoteClicked}
        emoteClicked={state.emoteClicked}
        allowVoting={allowVoting}
        voteDownClick={state.voteDownClick}
        voteUpClick={state.voteUpClick}
        inlineThreading={inlineThreading}
        messageWidth={messageWidth}
        allowThreading={allowThreading}
        working={messages?.working}
        olderWorking={messages?.olderWorking}
        focusMessage={scrollToMessage}
        messageThread={messageInThread}
        newerWorking={messages?.newerWorking}
        joinConference={message => {
          dispatch(joinConferenceRequest({ message }));
        }}
        fetchOlderMessages={before => {
          if (id) {
            dispatch(
              fetchOlderMessagesRequest({
                before,
                alias: state.alias,
                communicationId: id,
                threadRoot: state.threadRoot?.id,
                numThreadChildren: state.request?.numThreadChildren
              })
            );
          }
        }}
        fetchNewerMessages={since => {
          if (id) {
            dispatch(
              fetchNewerMessagesRequest({
                since,
                alias: state.alias,
                communicationId: id,
                threadRoot: state.threadRoot?.id,
                numThreadChildren: state.request?.numThreadChildren
              })
            );
          }
        }}
        atStart={messages?.atStart}
        atEnd={messages?.atEnd}
        messages={messages?.messages}
        hint={messages?.hint}
        atBottom={atBottom}
        onScrollBottomChange={bottom => {
          setAtBottom(bottom);
        }}
        threadingClicked={state.threadingClicked}
        loadThreadMessages={message => {
          id && dispatch(fetchMessagesRequest({ alias: message.id, communicationId: id, threadRoot: message.id }));
        }}
        manageMessages={manageMessagesPermission}
        editMessage={state.editMessage}
        deleteMessage={message => {
          id && dispatch(deleteMessageRequest({ communicationId: id, id: message.id, deleted: message.deleted }));
        }}
        onMessageRead={onMessageRead}
        onReferenceSelected={handleMessageReferenceSelected}
        activeParticipants={dictActiveParticipants}
      />
      <OpenExternalLinkDialog
        open={openExternalLinkDialog}
        headerText={'Sie verlassen Medtec Online'}
        noteToUserText={'Bitte beachten Sie, dass dieser Link eine unabhängige Website öffnet, für deren Inhalt und Betrieb wir nicht verantwortlich sind; die Abfrage erfolgt direkt vom Server des Dritten. Für die Bereitstellung, Datenverarbeitung und den Inhalt ist der Dritte verantwortlich. Diese Meldung wird einmalig angezeigt, ihr Inhalt gilt auch für zukünftige Abrufe von Drittwebseiten.'}
        labelText={'Weiter zur externen Seite'}
        link={openExternalLink}
        onCanceled={() => {setOpenExternalLinkDialog(false);}}
      />
      <MessageDeletedDialog
        open={openMessageDeletedDialog}
        hintText={messages?.hint}
        onCanceled={() => {setOpenMessageDeletedDialog(false);}}
      />
    </>
  );
};
