import { Btn, Bx, Ctn, IconBtn, TabItem, TabList, Typo } from '@curry-group/mui-curcuma';
import { faCheck, faEdit, faLongArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IDetailComponent } from '..';
import { FormValidator } from '../../../components/form/controls/input';
import { AppFrameContent, AppFrameHeader } from '../../../components/frame';
import { HeadBar } from '../../../components/head-bar';
import { MobileHeadBar } from '../../../components/mobile-head-bar';
import { UserProfileComponent } from '../../../components/user-profile';
import { AllowExternalLinksModal } from '../../../components/user-profile/allowExternalLinks';
import { CommunicationAllowChatModal } from '../../../components/user-profile/communicationAllowChat';
import { OneToOneCommunicationStateButton } from '../../../components/user-profile/communicationButton';
import { CommunicationInviteModal } from '../../../components/user-profile/communicationInvite';
import { userprofileAllowExternalLinksRequestAction, userprofileChangeDataVisibilityRequestAction, userprofileChangeMessagingPermissionsRequestAction } from '../../../data/actions/foundation';
import { unsubscribeFromSignalR } from '../../../data/reducer/communication';
import { fetchDetailRequest, userprofileUpdate, userprofileUpdateAvatar, userprofileUpdatePasswordReset } from '../../../data/reducer/detail';
import { getReferrer } from '../../../helper';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { IProfile } from '../../../model/profile';
import { IPreviewableFile } from '../../../model/profile/file';

export interface IUserProfileHeadProperties {
  startInvitation?: (userId: string) => void;
  isSettings: boolean;
  setSettings: (setting: boolean) => void;
  isAdmin: boolean;
  setAdmin: (setting: boolean) => void;
  isInvite: boolean;
  setInvite: (setting: boolean) => void;
  isExpertRegistration: boolean;
  setExpertRegistration: (setting: boolean) => void;
  isScrolled?: boolean;

  isEditing?: boolean;
  disableSave?: boolean;

  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}

export const UserProfileHeadComponent: React.FC<IUserProfileHeadProperties> = settings => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const detailWorking = useSelector(state => state.detail?.working);
  const detailItem = useSelector(state => state.detail?.item);
  const ownProfile = useSelector(state => state.foundation?.profile);
  const profile = detailItem as any as IProfile;
  const dispatch = useDispatch();

  const oneToOne = useSelector(state => state.userProfile.communication);
  const oneToOneRef = useRef(oneToOne);
  
  const noCompetences = !profile?.competenceCategories || profile?.competenceCategories.length === 0;
  const noInterests = !profile?.interestCategories || profile?.interestCategories.length === 0;

  useEffect(() => {
    if (oneToOneRef.current !== oneToOne) {
      oneToOneRef.current = oneToOne;
    }
    const currRef = oneToOneRef.current;
    return () => {
      currRef && dispatch(unsubscribeFromSignalR({ id: currRef.id }));
    };
  }, [oneToOne, dispatch]);

  const handleChange = (e: React.ChangeEvent<{}>, v: any) => {
    if (v === 1) {
      settings.setSettings(true);
      settings.setAdmin(false);
      settings.setInvite(false);
      settings.setExpertRegistration(false);
    } else if (v === 2) {
      settings.setSettings(false);
      settings.setAdmin(true);
      settings.setInvite(false);
      settings.setExpertRegistration(false);
    } else if (v === 3) {
      settings.setSettings(false);
      settings.setAdmin(false);
      settings.setInvite(true);
      settings.setExpertRegistration(false);
    } else if (v === 4) {
      settings.setSettings(false);
      settings.setAdmin(false);
      settings.setInvite(false);
      settings.setExpertRegistration(true);
    } else {
      settings.setSettings(false);
      settings.setAdmin(false);
      settings.setInvite(false);
      settings.setExpertRegistration(false);
    }
  };
  const isMyProfile = profile.userId === ownProfile?.userId;
  let tabs = ['Mein Profil', 'Einstellungen'];
  if (isMyProfile && ownProfile?.isAdmin)
  {
    tabs.push('Admin');
    tabs.push('Einladung');
    tabs.push('Systemseitige Registrierung');
  }

  return (
    <>
      {!isMobile ? (
        <HeadBar py={0} zIndex={1} backButtonLink={getReferrer(location.search) || '/info/experts-actors'} id="content-head-container">
          <Fade in={true}>
            <Bx flexShrink={1} flexGrow={1}>
              <Bx height="100%" display="flex" alignItems="center">
                <Ctn style={{ height: '100%' }}>
                  {isMyProfile && !!tabs?.length && (
                    <Bx display="flex" justifyContent="space-between" alignItems="center" height="100%">
                      <Bx display="flex" alignItems="flex-end" height="100%">
                        <TabList
                          style={{ height: '100%' }}
                          value={settings.isSettings ? 1 : settings.isAdmin ? 2 : settings.isInvite ? 3 : settings.isExpertRegistration ? 4 : 0}
                          fontVariant="body1"
                          textColor="primary"
                          onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                        >
                          {tabs.map((t, i) => (
                            <TabItem
                              key={i}
                              disabled={detailWorking}
                              label={
                                <Typo variant="h4" component="span" style={{ height: 80, marginTop: -6, marginBottom: -6, display: 'flex', alignItems: 'center' }}>
                                  {t}
                                </Typo>
                              }
                            />
                          ))}
                        </TabList>
                      </Bx>
                      <Bx display="flex" alignItems="center" height="100%">
                        <Grid container spacing={1}>
                          {!!isMyProfile && !settings.isSettings && !settings.isAdmin && !settings.isInvite && settings.isEditing ? (
                            <>
                              <Grid item>
                                <Btn color="default" size="large" variant="outlined" onClick={settings.onCancel} startIcon={<FontAwesomeIcon icon={faTimes} />}>
                                  Abbrechen
                                </Btn>
                              </Grid>
                              <Grid item>
                                <Btn
                                  disabled={!FormValidator.isValid('', '') || settings.disableSave}
                                  color="success"
                                  size="large"
                                  variant="contained"
                                  onClick={settings.onSave}
                                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                                >
                                  Profil speichern
                                </Btn>
                              </Grid>
                            </>
                          ) : (
                            <Grid item>
                              {!settings.isSettings && !settings.isAdmin && !settings.isInvite && (
                                <Btn size="large" variant="outlined" onClick={settings.onEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                                  Profil bearbeiten
                                </Btn>
                              )}
                            </Grid>
                          )}
                          {!isMyProfile && profile?.userId && settings.isScrolled && (
                            <Grid item>
                              <OneToOneCommunicationStateButton onRequest={() => profile?.userId && settings.startInvitation?.(profile?.userId)} userId={profile?.userId} />
                            </Grid>
                          )}
                        </Grid>
                      </Bx>
                    </Bx>
                  )}
                </Ctn>
              </Bx>
            </Bx>
          </Fade>
        </HeadBar>
      ) : (
        <MobileHeadBar id="content-head-container">
          <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
            <Grid item xs={2}>
              <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
                <IconBtn size="medium" to={getReferrer(location.search) || '/info/experts-actors'}>
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                </IconBtn>
              </Bx>
            </Grid>
            <Grid item xs={7}>
              <Bx display="flex" alignItems="flex-end" height="100%">
                {isMyProfile && !!tabs?.length && (
                  <TabList
                    style={{ height: '100%' }}
                    value={settings.isSettings ? 1 : settings.isAdmin ? 2 : 0}
                    fontVariant="body1"
                    textColor="primary"
                    onChange={handleChange as ((event: React.ChangeEvent<{}>, value: any) => void) & ((event: React.FormEvent<HTMLButtonElement>) => void)}
                  >
                    {tabs.map((t, i) => (
                      <TabItem
                        key={i}
                        disabled={detailWorking}
                        label={
                          <Typo variant="h6" component="span" style={{ height: 40, marginTop: -6, marginBottom: -6, display: 'flex', alignItems: 'center' }}>
                            {t}
                          </Typo>
                        }
                      />
                    ))}
                  </TabList>
                )}
              </Bx>
            </Grid>
            <Grid item xs={3}>
              {!!isMyProfile && settings.isEditing ? (
                <Bx display="flex" alignItems="center" justifyContent="flex-end" height="100%">
                  <Bx pl={1}>
                    <IconBtn onClick={settings.onCancel} size="small" color="default" variant="outlined" shape="rounded">
                      <FontAwesomeIcon icon={faTimes} />
                    </IconBtn>
                  </Bx>
                  <Bx px={1}>
                    <IconBtn
                      disabled={!FormValidator.isValid('', '') || settings.disableSave}
                      size="small"
                      onClick={settings.onSave}
                      color="secondary"
                      variant="contained"
                      shape="rounded"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </IconBtn>
                  </Bx>
                </Bx>
              ) : (
                <>
                  {isMyProfile && (
                    <Bx display="flex" alignItems="center" justifyContent="flex-end" height="100%" px={1}>
                      <IconBtn onClick={settings.onEdit} size="small" color="default" variant="outlined" shape="rounded">
                        <FontAwesomeIcon icon={faEdit} />
                      </IconBtn>
                    </Bx>
                  )}
                </>
              )}
            </Grid>
          </Bx>
        </MobileHeadBar>
      )}
      {(noCompetences || noInterests) && isMyProfile && (
        <Bx display="flex" justifyContent="center" px={{ xs: 2, lg: 5 }} py={0} bgcolor={'accent.light'}>
          <Bx display="flex" justifyContent="center" px={1} py={1}>
            <Typo variant="body1" color="textSecondary">
              Sie haben noch keine&nbsp;
              {noCompetences ? 'Kompetenzen' : ''}
              {noCompetences && noInterests ? '/' : ''}
              {noInterests ? 'Interessen' : ''}
              &nbsp;in Ihrem Profil gepflegt. Um die volle Funktionalität des Portals nutzen können, passen Sie ihr Profil an.
            </Typo>
          </Bx>
        </Bx>
      )}
    </>
  );
};

export const UserProfile: React.FC<IDetailComponent> = ({ isScrolled, setIsScrolled }) => {
  const [isSettings, setSettings] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isInvite, setInvite] = useState(false);
  const [isExpertRegistration, setExpertRegistration] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const dispatch = useDispatch();
  const detailItem = useSelector(state => state.detail?.item);
  const ownProfile = useSelector(state => state.foundation?.profile);
  const [profile, setProfile] = useState<IProfile>(detailItem as any);
  const [files, setFiles] = React.useState<Array<IPreviewableFile>>([]);
  const isMyProfile = profile.userId === ownProfile?.userId;
  const [inviteModelUserId, setInviteModelUserId] = useState<string | undefined>(undefined);
  const [editChatAllowed, setEditChatAllowed] = useState<boolean>(false);
  const [externalLinksAllowed, setExternalLinksAllowed] = useState<boolean>(false);


  return (
    <>
      <AppFrameHeader>
        <UserProfileHeadComponent
          startInvitation={setInviteModelUserId}
          isEditing={isEditing}
          disableSave={disableSave}
          onEdit={() => {
            setEditing(true);
            dispatch(userprofileUpdatePasswordReset());
          }}
          onSave={() => {
            files[0] && dispatch(userprofileUpdateAvatar(files[0]));
            dispatch(userprofileUpdate(profile));
            setEditing(false);
          }}
          onCancel={() => {
            setEditing(false);
            dispatch(fetchDetailRequest({ type: 'user', alias: isMyProfile ? 'profile' : profile.userId ?? '' }));
          }}
          isSettings={isSettings}
          setSettings={setSettings}
          isAdmin={isAdmin}
          setAdmin={setAdmin}
          isInvite={isInvite}
          setInvite={setInvite}
          isExpertRegistration={isExpertRegistration}
          setExpertRegistration={setExpertRegistration}
          isScrolled={isScrolled}
        />
      </AppFrameHeader>
      <AppFrameContent>
        <UserProfileComponent
          startInvitation={setInviteModelUserId}
          openChatModal={() => setEditChatAllowed(true)}
          openExternalLinkModal={() => setExternalLinksAllowed(true)}
          isEditing={isEditing}
          profile={profile}
          setProfile={setProfile}
          files={files}
          setFiles={setFiles}
          onScroll={setIsScrolled}
          isScrolled={isScrolled}
          setEditMode={setEditing}
          isSettings={isSettings}
          isInvite={isInvite}
          isExpertRegistration={isExpertRegistration}
          isAdmin={isAdmin}
          setDisableSave={setDisableSave}
          onCancel={() => {
            setEditing(false);
            dispatch(fetchDetailRequest({ type: 'user', alias: isMyProfile ? 'profile' : profile.userId ?? '' }));
          }}
          changeDataVisibility={(toggleProp) => {
            dispatch(userprofileChangeDataVisibilityRequestAction({toggleProperty: toggleProp}));
            var newProfile = { ...profile };
            if (!profile.dataVisibility) {
              newProfile = { ...profile, dataVisibility: {
                all: true,
                chats: false,
                closedGroups: false,
                openGroups: false,
              }};
            }
            switch (toggleProp) {
              case 'all':
                const newAll = !profile.dataVisibility?.all;
                if (newAll) {
                  newProfile = { ...profile, dataVisibility: {
                    all: true,
                    chats: false,
                    closedGroups: false,
                    openGroups: false,
                  }};
                } else {
                  newProfile = { ...profile, dataVisibility: {
                    all: false,
                    chats: profile.dataVisibility?.chats || false,
                    closedGroups: profile.dataVisibility?.closedGroups || false,
                    openGroups: profile.dataVisibility?.openGroups || false,
                  }};
                }
                break;
              case 'chats':
                newProfile = {
                  ...profile, dataVisibility: {
                    ...profile.dataVisibility,
                    chats: !profile.dataVisibility?.chats,
                    all: !profile.dataVisibility?.chats ? false : profile.dataVisibility?.all,
                    closedGroups: profile.dataVisibility?.closedGroups || false,
                    openGroups: profile.dataVisibility?.openGroups || false
                }};
                break;
              case 'closedGroups':
                newProfile = {
                  ...profile, dataVisibility: {
                    ...profile.dataVisibility,
                    closedGroups: !profile.dataVisibility?.closedGroups,
                    all: !profile.dataVisibility?.closedGroups ? false : profile.dataVisibility?.all,
                    chats: profile.dataVisibility?.chats || false,
                    openGroups: profile.dataVisibility?.openGroups || false
                }};
                break;
              case 'openGroups':
                newProfile = {
                  ...profile, dataVisibility: {
                    ...profile.dataVisibility,
                    openGroups: !profile.dataVisibility?.openGroups,
                    all: !profile.dataVisibility?.openGroups ? false : profile.dataVisibility?.all,
                    chats: profile.dataVisibility?.chats || false,
                    closedGroups: profile.dataVisibility?.closedGroups || false
                }};
                break;
              default:
                break;
            }
            setProfile(newProfile);
          }}
          changeMessagingPermissions={(toggleProp) => {
            dispatch(userprofileChangeMessagingPermissionsRequestAction({toggleProperty: toggleProp}));
            var newProfile = { ...profile };
            if (!profile.messagingPermissions) {
              newProfile = { ...profile, messagingPermissions: {
                all: true,
                chat: false,
                groupChat: false,
                groupMeeting: false,
                forum: false,
                magazine: false,
              }};
            }
            switch (toggleProp) {
              case 'all':
                const newAll = !profile.messagingPermissions?.all;
                if (newAll) {
                  newProfile = { ...profile, messagingPermissions: {
                    all: true,
                    chat: false,
                    groupChat: false,
                    groupMeeting: false,
                    forum: false,
                    magazine: false,
                  }};
                } else {
                  newProfile = { ...profile, messagingPermissions: {
                    all: false,
                    chat: profile.messagingPermissions?.chat || false,
                    groupChat: profile.messagingPermissions?.groupChat || false,
                    groupMeeting: profile.messagingPermissions?.groupMeeting || false,
                    forum: profile.messagingPermissions?.forum || false,
                    magazine: profile.messagingPermissions?.magazine || false
                  }};
                }
                break;
              case 'chat':
                newProfile = {
                  ...profile, messagingPermissions: {
                    ...profile.messagingPermissions,
                    chat: !profile.messagingPermissions?.chat,
                    all: !profile.messagingPermissions?.chat ? false : profile.messagingPermissions?.all,
                    groupChat: profile.messagingPermissions?.groupChat || false,
                    groupMeeting: profile.messagingPermissions?.groupMeeting || false,
                    forum: profile.messagingPermissions?.forum || false,
                    magazine: profile.messagingPermissions?.magazine || false
                }};
                break;
              case 'groupChat':
                newProfile = {
                  ...profile, messagingPermissions: {
                    ...profile.messagingPermissions,
                    groupChat: !profile.messagingPermissions?.groupChat,
                    all: !profile.messagingPermissions?.groupChat ? false : profile.messagingPermissions?.all,
                    chat: profile.messagingPermissions?.chat || false,
                    groupMeeting: profile.messagingPermissions?.groupMeeting || false,
                    forum: profile.messagingPermissions?.forum || false,
                    magazine: profile.messagingPermissions?.magazine || false
                }};
                break;
              case 'groupMeeting':
                newProfile = {
                  ...profile, messagingPermissions: {
                    ...profile.messagingPermissions,
                    groupMeeting: !profile.messagingPermissions?.groupMeeting,
                    all: !profile.messagingPermissions?.groupMeeting ? false : profile.messagingPermissions?.all,
                    chat: profile.messagingPermissions?.chat || false,
                    groupChat: profile.messagingPermissions?.groupChat || false,
                    forum: profile.messagingPermissions?.forum || false,
                    magazine: profile.messagingPermissions?.magazine || false
                }};
                break;
              case 'forum':
                newProfile = {
                  ...profile, messagingPermissions: {
                    ...profile.messagingPermissions,
                    forum: !profile.messagingPermissions?.forum,
                    all: !profile.messagingPermissions?.groupMeeting ? false : profile.messagingPermissions?.all,
                    chat: profile.messagingPermissions?.chat || false,
                    groupChat: profile.messagingPermissions?.groupChat || false,
                    groupMeeting: profile.messagingPermissions?.groupMeeting || false,
                    magazine: profile.messagingPermissions?.magazine || false
                }};
                break;
              case 'magazine':
                newProfile = {
                  ...profile, messagingPermissions: {
                    ...profile.messagingPermissions,
                    magazine: !profile.messagingPermissions?.magazine,
                    all: !profile.messagingPermissions?.groupMeeting ? false : profile.messagingPermissions?.all,
                    chat: profile.messagingPermissions?.chat || false,
                    groupChat: profile.messagingPermissions?.groupChat || false,
                    groupMeeting: profile.messagingPermissions?.groupMeeting || false,
                    forum: profile.messagingPermissions?.forum || false
                }};
                break;
              default:
                break;
            }
            setProfile(newProfile);
          }}
        />
      </AppFrameContent>
      <CommunicationInviteModal
        userId={inviteModelUserId}
        close={() => {
          setInviteModelUserId(undefined);
        }}
      />
      {isMyProfile && <CommunicationAllowChatModal
        chatAllowed={profile.chatAllowed}
        editChatAllowed={editChatAllowed}
        close={() => {
          setEditChatAllowed(false);
        }}
        cancel={() => {
          setEditChatAllowed(false);
        }}
        save={() => {
          var newProfile = { ...profile, chatAllowed: !profile.chatAllowed };
          setProfile(newProfile)
          dispatch(userprofileUpdate(newProfile));
          setEditChatAllowed(false);
        }}
      />}
      {isMyProfile && <AllowExternalLinksModal
        externalLinksAllowed={profile.externalLinksAllowed}
        editExternalLinksAllowed={externalLinksAllowed}
        close={() => {
          setExternalLinksAllowed(false);
        }}
        cancel={() => {
          setExternalLinksAllowed(false);
        }}
        save={() => {
          var newProfile = { ...profile, externalLinksAllowed: !profile.externalLinksAllowed };
          setProfile(newProfile);
          dispatch(userprofileAllowExternalLinksRequestAction());
          setExternalLinksAllowed(false);
        }}
      />}
    </>
  );
};
