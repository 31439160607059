import React from 'react';
import { Ava, Bx, DropZone, formatBytes, IconBtn, Link, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { iconForExtension } from '../../../../helper';

interface IFormDropZone extends IBreakpoints {
  value: any[];
  label: string;
  placeholder: string;
  mandatory: boolean;
  accept?: string | string[];
  multiple?: boolean;
  onChange: (value: any[]) => void;
}

export interface IBreakpoints {
  isLgUp?: boolean;
  isMdUp?: boolean;
  isSmUp?: boolean;
}

export const FormDropZone: React.FC<IFormDropZone> = ({ value, label, placeholder, mandatory, accept, multiple, onChange, isLgUp, isMdUp, isSmUp }) => {
  const files = value || [];
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const newFiles = multiple
          ? [
              ...files,
              ...acceptedFiles.map((file: any) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file)
                })
              )
            ]
          : [
              ...acceptedFiles.map((file: any) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file)
                })
              )
            ];
        onChange(newFiles);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <React.Fragment>
      <Bx mb={1} display="flex" justifyContent="space-between">
        <Typo component="label" variant="body2" fontWeight={500}>
          {label + (mandatory ? ' *' : '')}
        </Typo>
      </Bx>
      <DropZone onDrop={onDrop} accept={accept} multiple={multiple} label={placeholder} />
      {files.length > 0 && (
        <Grid container spacing={1}>
          {files.map((file, idx) => {
            return (
              <Grid key={idx} item xs={12} md={6}>
                <Bx mt={2} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius="borderRadius2" borderColor="#D6D7D9">
                  <Bx mr={2}>
                    <Typo variant="h1" color="primary" component="div">
                      <Ava size="small" variant="rounded" src={file.preview} children={<FontAwesomeIcon icon={iconForExtension(file.extension)} />} />
                    </Typo>
                  </Bx>
                  <Bx mr={2} overflow={'hidden'}>
                    <Link underline="hover" to="/" title={file.name}>
                      <Typo variant="body2" fontWeight={500} noWrap>
                        {file.existing_id ? file.realname : file.name}
                      </Typo>
                    </Link>
                    {file.size > 0 && <Typo variant="subtitle3">{formatBytes(file.size)}</Typo>}
                    {file.filesize > 0 && <Typo variant="subtitle3">{formatBytes(file.filesize)}</Typo>}
                  </Bx>
                  <Bx ml="auto">
                    <IconBtn
                      size="small"
                      onClick={() => {
                        const removeArray = [...files];
                        removeArray?.splice(idx, 1);
                        onChange(removeArray);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </IconBtn>
                  </Bx>
                </Bx>
              </Grid>
            );
          })}
        </Grid>
      )}
    </React.Fragment>
  );
};
