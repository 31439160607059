import { Bx, CheckboxControl, IconBtn, Input, InputEndAdornment, InputInfoText, InputValidationIcon, Link, Typo } from '@curry-group/mui-curcuma';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import { passwordValid } from '../../../helper';

interface IFormIdea {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormUserPassword: React.FC<IFormIdea> = ({ formData, headline, subtitle, onChange }) => {
  const inputRef1 = React.useRef<any>(null);
  const inputRef2 = React.useRef<any>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [inputVal, setInputVal] = React.useState({
    password1: formData?.password || '',
    password2: formData?.passwordRepetition || '',
    termsAccepted: formData?.itemData?.termsAccepted,
    communityRulesAccepted: formData?.itemData?.communityRulesAccepted
  });

  const handleTogglePassword = () => {
    if (inputRef1.current.type === 'password' && inputRef2.current.type === 'password') {
      inputRef1.current.type = 'text';
      inputRef2.current.type = 'text';
      setShowPassword(true);
    } else {
      inputRef1.current.type = 'password';
      inputRef2.current.type = 'password';
      setShowPassword(false);
    }
  };
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort' }}
              input={{
                value: inputVal.password1,
                placeholder: 'Passwort eingeben',
                type: 'password',
                inputRef: inputRef1,
                onChange: e => {
                  setInputVal({ ...inputVal, [e.target.name]: e.target.value });
                  onChange({ ...formData, password: e.target.value, passwordRepetition: inputVal.password2 });
                },
                name: 'password1',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={handleTogglePassword}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && passwordValid(inputVal.password1) && inputVal.password1 === inputVal.password2 && (
                      <InputValidationIcon type="success" />
                    )}
                  </InputEndAdornment>
                )
              }}
            />
            {inputVal.password1?.length > 0 && !passwordValid(inputVal.password1) && (
              <Bx mt={1}>
                <InputInfoText type="error">
                  <Typo component='span' variant='body3boosted'>Das Passwort muss Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten und mindestens 8 Zeichen lang sein.</Typo>
                </InputInfoText>
              </Bx>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Input
              inputLabel={{ children: 'Passwort wiederholen' }}
              input={{
                value: inputVal.password2,
                placeholder: 'Passwort eingeben',
                type: 'password',
                inputRef: inputRef2,
                onChange: e => {
                  setInputVal({ ...inputVal, [e.target.name]: e.target.value });
                  onChange({ ...formData, password: inputVal.password1, passwordRepetition: e.target.value });
                },
                name: 'password2',
                endAdornment: (
                  <InputEndAdornment>
                    <IconBtn size="small" onClick={handleTogglePassword}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </IconBtn>
                    {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && passwordValid(inputVal.password1) && inputVal.password1 === inputVal.password2 && (
                      <InputValidationIcon type="success" />
                    )}
                  </InputEndAdornment>
                )
              }}
            />
            {inputVal.password1?.length > 0 && inputVal.password2?.length > 0 && inputVal.password1 !== inputVal.password2 && (
              <Bx mt={1}>
                <InputInfoText type="error">
                  <Typo component='span' variant='body3boosted'>Die Passwörter simmen nicht überein!</Typo>
                </InputInfoText>
              </Bx>
            )}
          </Grid>
          <Grid xs={12} item>
            <Bx mt={{ xs: 4, md: 6, lg: 8 }}>
              <Bx pl={1}>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'termsAccepted',
                    value: inputVal.termsAccepted,
                    onChange: e => {
                      setInputVal({ ...inputVal, [e.target.name]: e.target.checked });
                      onChange({ ...formData, itemData: { ...formData?.itemData, termsAccepted: e.target.checked } });
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={inputVal.termsAccepted}
                  label={
                    <Typo>
                      Ich habe die{' '}
                      <Link to="/nutzungsbedingungen" target="_blank">
                        Nutzungsbedingungen
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
              <Bx mt={3} pl={1}>
                <CheckboxControl
                  control={{
                    color: 'primary',
                    name: 'communityRulesAccepted',
                    value: inputVal.communityRulesAccepted,
                    onChange: e => {
                      setInputVal({ ...inputVal, [e.target.name]: e.target.checked });
                      onChange({ ...formData, itemData: { ...formData?.itemData, communityRulesAccepted: e.target.checked } });
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={inputVal.communityRulesAccepted}
                  label={
                    <Typo>
                      Ich habe die{' '}
                      <Link to="/gemeinschaftsstandards" target="_blank">
                        Gemeinschaftsstandards
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </Typo>
                  }
                />
              </Bx>
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
