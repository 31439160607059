import { takeLatest, put, select } from 'redux-saga/effects';
import { callGet } from '@curry-group/data-addons';
import { api } from '../../api';
import {
  fetchAcademicTitlesRequestAction,
  fetchAcademicTitlesSuccessAction,
  fetchAcademicTitlesErrorAction
} from '../../actions/lists';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useAcademicTitles() {
  const dispatch = useDispatch();  
  useEffect(() => {
    dispatch(fetchAcademicTitlesRequestAction({}));
  }, [dispatch]);
}

export function useAcademicTitlesState(): Array<{ _id: string, title: string}> {
  return useSelector(state => state.lists.academicTitles.items);
}

export function* lists() {
  yield takeLatest(fetchAcademicTitlesRequestAction.type, fetchAcademicTitlesWorker);
}

function* fetchAcademicTitlesWorker(action: ReturnType<typeof fetchAcademicTitlesRequestAction>) {
  try {
    const academicTitles = yield select(state => state.lists.academicTitles.items);
    if (!academicTitles || academicTitles.length === 0) {
      const items = yield callGet(api.lists.academicTitles);
      yield put(fetchAcademicTitlesSuccessAction({ items }));
    } else {
      yield put(fetchAcademicTitlesSuccessAction({ items: academicTitles }));
    }
  } catch (e: any) {
    yield put(fetchAcademicTitlesErrorAction({ message: e.message }));
  }
}
