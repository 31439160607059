import { Bx } from '@curry-group/mui-curcuma';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppFrameSidebar } from '../../../components/frame/sidebar';
import { NavBarLogo } from '../../../components/navbar-logo';
import { SplashSideBar } from '../../../components/splash-sidebar';
import { useFlowLostPass, useFlowRegister } from '../../../data/sagas/flow';
import { ConnectedFlowComponentFrame } from '../../flow';

export const RegisterComponent = (data: {
  headline?: string;
  subHeadline?: string | string[];
  page?: React.ReactChildren | React.ReactChild;
  pageCentered?: boolean;
  paddingY?: number;
  paddingX?: number;
  flowStep: any;
  alias: string;
}) => {
  const image = data.flowStep?.sidebarImageSrc;
  return (
    <React.Fragment>
      <Hidden mdDown>
        <AppFrameSidebar>
          <SplashSideBar src={image ? `/images/${image}.svg` : `/images/register.svg`} alt={data.flowStep?.sidebarImageAlt}>
            <Bx display="flex" width="100%" alignItems="center" justifyContent="space-between">
              <Bx display="flex" justifyContent={'flex-start'} alignItems="center" height="100%">
                <NavBarLogo to="/" src="/images/logo.svg" alt="Medtec Online" />
              </Bx>
            </Bx>
          </SplashSideBar>
        </AppFrameSidebar>
      </Hidden>
      <ConnectedFlowComponentFrame alias={data.alias} />
    </React.Fragment>
  );
};

export const ConnectedRegisterFrame = (data: {
  headline?: string;
  subHeadline?: string | string[];
  page?: React.ReactChildren | React.ReactChild;
  pageCentered?: boolean;
  paddingY?: number;
  paddingX?: number;
}) => {
  const location = useLocation();
  useFlowRegister(location.pathname, location.search);
  useFlowLostPass(location.pathname, location.search);
  const alias = location.pathname?.replaceAll('/', '');
  const currentStep = useSelector(state => state.flow.currentStep);
  const flowSteps = useSelector(state => state.flow.flowSteps);
  const currentStepItem = flowSteps[currentStep];
  return <RegisterComponent alias={alias} flowStep={currentStepItem} />;
};
