import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FramedContent } from '../framed-content';
import { AppFrameHeader } from '../frame/header';
import { AppFrameContent } from '../frame';
import { ConnectedHeaderComponent } from '../header';

const BotSelect = (props: any) => {
  const history = useHistory();
  return (
    <>
      <AppFrameHeader>
        <ConnectedHeaderComponent />
      </AppFrameHeader>

      <AppFrameContent>
        <FramedContent>
          <Bx mb={1}>
            <Typo variant="h1" component="h1">
              Bot auf Medtec Online nutzen
            </Typo>
          </Bx>
          <Bx my={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg="auto">
                <ClickableCard
                  variant="outlined"
                  fluid={{ from: 278, to: 458 }}
                  height="100%"
                  onClick={() => history.push('/news')}
                >
                  <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                    <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                      <img src="/images/bot-news.svg" alt="Bot im Newsticker" width="70%" />
                    </Bx>
                    <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                      <Typo variant="h2" align="center">
                        Bot im Newsticker
                      </Typo>
                      <Bx mt={2} mb={2}>
                        <Typo variant="body1" align="center">
                          Befragen Sie den Bot zu aktuellen Fachnachrichten.
                        </Typo>
                      </Bx>
                    </Bx>
                  </Bx>
                </ClickableCard>
              </Grid>
              <Grid item xs={12} md={4} lg="auto">
                <ClickableCard
                  variant="outlined"
                  fluid={{ from: 278, to: 458 }}
                  height="100%"
                  onClick={() => history.push('/info/magazine')}
                >
                  <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                    <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                      <img src="/images/bot-magazine.svg" alt="Bot im Magazin" width="70%" />
                    </Bx>
                    <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                      <Typo variant="h2" align="center">
                        Bot im Magazin
                      </Typo>
                      <Bx mt={2} mb={2}>
                        <Typo variant="body1" align="center">
                          Befragen Sie den Bot zum Fachwissen auf Medtec Online
                        </Typo>
                      </Bx>
                    </Bx>
                  </Bx>
                </ClickableCard>
              </Grid>
              <Grid item xs={12} md={4} lg="auto">
                <ClickableCard
                  active={props.currentType === 'project'}
                  variant="outlined"
                  fluid={{ from: 278, to: 458 }}
                  height="100%"
                  onClick={() => history.push('/info/forum')}
                >
                  <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                    <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                      <img src="/images/bot-forum.svg" alt="Bot im Forum" width="70%" />
                    </Bx>
                    <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                      <Typo variant="h2" align="center">
                        Bot im Forum
                      </Typo>
                      <Bx mt={2} mb={2}>
                        <Typo variant="body1" align="center">
                          Befragen Sie den Bot und stellen Frage & Antwort zur Diskussion.
                        </Typo>
                      </Bx>
                    </Bx>
                  </Bx>
                </ClickableCard>
              </Grid>
            </Grid>
          </Bx>
        </FramedContent>
      </AppFrameContent>
    
    </>
  );
};

export default BotSelect;
