import * as React from 'react';
import { rgba } from 'polished';
import clsx from 'clsx';
import { Ava, Bx, IBxProps, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { buildAbbreviation } from '../../../../helper/user';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faMicrophone, faSignal, faSignal1, faSignal2, faSignal3 } from '@fortawesome/pro-light-svg-icons';

import { ConferenceInfoButton, ConferenceRaisedHand } from '../raised-hand';

import { conferenceTheme } from '../../theme';

export interface IConferenceParticipantProps extends IBxProps {
  name?: string;
  stream?: React.ReactChild;
  quality?: React.ReactNode;
  audioActive?: boolean;
  videoActive?: boolean;
  speaking?: boolean;
  small?: boolean;
  self?: boolean;
  raisedHand?: boolean;
  menu?: React.ReactChild;
  menuOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  menuOpen?: boolean;
  menuButtonProps?: {
    size?: number;
  };
}

export interface IConferenceParticipantThemeExtension {
  conferenceParticipant: {
    paddingTop?: React.CSSProperties['paddingTop'];
    paddingTopSelf?: React.CSSProperties['paddingTop'];
    borderRadius?: React.CSSProperties['borderTop'];
    boxShadow?: React.CSSProperties['boxShadow'];
    boxShadowSelf?: React.CSSProperties['boxShadow'];
    noStreamBackgroundColor?: React.CSSProperties['backgroundColor'];
    avaBackgroundColor?: React.CSSProperties['backgroundColor'];
    avaColor?: React.CSSProperties['color'];
    menuBtnBackgroundColor?: React.CSSProperties['backgroundColor'];
    menuBtnColor?: React.CSSProperties['color'];
    nameColor?: React.CSSProperties['color'];
  };
}
interface IConferenceParticipantStyle {
  menuButtonSize: number;
  small: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    boxShadow: conferenceTheme.conferenceParticipant?.boxShadow
  },
  noVideoSignal: {
    opacity: 0,
    visibility: 'hidden'
  },
  center: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  bottomleft: {
    position: 'absolute',
    bottom: theme.spacing(.5),
    left: (props: IConferenceParticipantStyle) =>
      props.small
        ? theme.spacing(5)
        : theme.spacing(.5)
  },
  bottomcenter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  rootNoStream: {
    backgroundColor: conferenceTheme.conferenceParticipant?.noStreamBackgroundColor
  },
  speaking: { boxShadow: `inset 0 0 0 3px ${theme?.palette?.accent?.main}, 2px 2px 4px 0 rgba(0,0,0,0.15)` },
  handRaised: { boxShadow: `inset 0 0 0 3px ${theme?.palette?.warning?.main}, 2px 2px 4px 0 rgba(0,0,0,0.15)` },
  stream: {
    overflow: 'hidden',
    backgroundColor: '#000',

    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  content: {
    transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter, easing: theme.transitions.easing.easeInOut }),
    opacity: 0,
    backgroundColor: conferenceTheme.conferenceParticipant?.noStreamBackgroundColor ? rgba(conferenceTheme.conferenceParticipant?.noStreamBackgroundColor, 0.9) : undefined
    // '&:hover': {
    //   opacity: 1,
    // },
  },
  contentMenuOpen: {
    opacity: 1
  },
  name: {
    color: conferenceTheme.conferenceParticipant?.nameColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: conferenceTheme.conferenceParticipant?.nameBackgroundColor,
    padding: conferenceTheme.conferenceParticipant?.namePadding,
    zIndex: 1,
    opacity: 0.75
  },
  avaContainer: {},
  ava: {
    backgroundColor: conferenceTheme.conferenceParticipant?.avaBackgroundColor,
    color: conferenceTheme.conferenceParticipant?.avaColor,
    border: 0
  },
  avaSmall: {
    fontSize: '14px'
  },
  raisedHand: {
    position: 'absolute',
    zIndex: 3,
    top: theme.spacing(.5),
    right: theme.spacing(.5),
  },
  raisedHandName: {
    backgroundColor: theme?.palette?.warning?.main,
    color: conferenceTheme.conferenceRaisedHand?.backgroundColor,
  },
  raisedMicrophone: {
    position: 'absolute',
    zIndex: 3,
    top: theme.spacing(.5),
    left: theme.spacing(.5)
    // bottom: (props: IConferenceParticipantStyle) => (props.small ? theme.spacing(.5) : conferenceTheme.conferenceRaisedHand?.large.width + theme.spacing(2)),
    // left: (props: IConferenceParticipantStyle) =>
    //   props.small
    //     ? theme.spacing(.5)
    //     : `calc(50% - ${stripUnit(conferenceTheme.conferenceRaisedHand?.large.width / 2 ?? 0)}px)`
  },
  menu: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: theme.spacing(1)
  },
  iconBtn: {
    width: (props: IConferenceParticipantStyle) => props.menuButtonSize,
    height: (props: IConferenceParticipantStyle) => props.menuButtonSize,
    fontSize: (props: IConferenceParticipantStyle) => props.menuButtonSize * 0.75,
    backgroundColor: conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor ? rgba(conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor, 0.5) : undefined,
    color: conferenceTheme.conferenceParticipant?.menuBtnColor,
    '&:hover': {
      backgroundColor: conferenceTheme.conferenceParticipant?.menuBtnBackgroundColor
    }
  }
}));

export const ConferenceParticipantLatency: React.FC<{ stats?: any }> = ({ stats }) => {
  stats = stats || {};
  stats.audio = stats.audio || {};
  stats.video = stats.video || {};
  const theme = useTheme();
  let quality: 0 | 1 | 2 | 3 = 3;
  let qualityScore = 100.0;
  let coloring;
  if (stats.video.jitter > 0.1) {
    qualityScore -= 15;
  }
  if (stats.video.jitter > 0.5) {
    qualityScore -= 15;
  }
  if (stats.video.jitter > 0.99) {
    qualityScore -= 15;
  }
  if (stats.audio.jitter > 0.1) {
    qualityScore -= 15;
  }
  if (stats.audio.jitter > 0.5) {
    qualityScore -= 15;
  }
  if (stats.audio.jitter > 0.99) {
    qualityScore -= 15;
  }
  if (qualityScore < 90) {
    quality -= 1;
  }
  if (qualityScore < 70) {
    quality -= 1;
  }
  if (qualityScore < 30) {
    quality -= 1;
  }
  switch (quality) {
    case 0: {
      coloring = {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
      };
      break;
    }
    case 1: {
      coloring = {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
      };
      break;
    }
    case 2: {
      coloring = {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText
      };
      break;
    }
    case 3: {
      coloring = {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText
      };
      break;
    }
  }
  return (
    <div
      style={{
        ...coloring,
        padding: theme.spacing(1),
        borderRadius: '50%',
        width: 32,
        height: 32,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {quality === 0 && <FontAwesomeIcon icon={faSignal1} />}
      {quality === 1 && <FontAwesomeIcon icon={faSignal2} />}
      {quality === 2 && <FontAwesomeIcon icon={faSignal3} />}
      {quality === 3 && <FontAwesomeIcon icon={faSignal} />}
    </div>
  );
};

export const ConferenceParticipant: React.FunctionComponent<IConferenceParticipantProps> = ({
  name,
  quality,
  stream,
  videoActive,
  audioActive,
  speaking,
  small,
  self,
  raisedHand,
  menu,
  menuOnClick,
  menuOpen,
  menuButtonProps,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles({ menuButtonSize: menuButtonProps?.size || 32, small: small || false });
  const theme = useTheme();
  return (
    <Bx
      className={clsx(
        classes.root,
        (!stream || !videoActive) && classes.rootNoStream,
        !videoActive && speaking && classes.speaking,
        !videoActive && raisedHand && classes.handRaised,
        className
      )}
      {...rest}
    >
      {!self && (
        <Typo
          variant={small ? 'body3' : 'body1'}
          className={clsx(classes.name, classes.bottomleft, raisedHand ? classes.raisedHandName : undefined)}
        >
          {name}
        </Typo>
      )}

      <ConferenceRaisedHand active={raisedHand} small={small} className={classes.raisedHand} />
      {!small && !audioActive && <ConferenceInfoButton active={!audioActive} small={true} icon={faMicrophone} slashed className={classes.raisedMicrophone} />}
      {stream && (
        <Bx className={clsx(classes.stream, classes.center, !videoActive && classes.noVideoSignal)}>
          <>
            {stream}
            {speaking && <Bx width="100%" height="100%" className={clsx(classes.speaking, classes.center)} />}
            {raisedHand && <Bx width="100%" height="100%" className={clsx(classes.handRaised, classes.center)} />}
          </>
        </Bx>
      )}
      {quality && <Bx style={{ position: 'absolute', top: 0, left: 0, padding: small ? theme.spacing(.5) : theme.spacing(1) }}>{quality}</Bx>}
      {(!stream || !videoActive) && (
        <Bx display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={clsx(classes.avaContainer, classes.center)}>
          <Ava size={small ? 'small' : 'large'} className={clsx(classes.ava, small && classes.avaSmall)}>
            {self ? 'Ich' : buildAbbreviation(name ?? '')}
          </Ava>
        </Bx>
      )}
      {small && (
        <Bx style={{ position: 'absolute', bottom: 0, left: 0, padding: small ? theme.spacing(.5) : theme.spacing(1) }}>
          {!audioActive && <ConferenceInfoButton active={!audioActive} small icon={faMicrophone} slashed />}
        </Bx>
      )}
      <Bx className={clsx(classes.content, classes.center, menuOpen && classes.contentMenuOpen)}>
        {!self && menuOnClick && menu && (
          <div className={classes.menu}>
            <IconBtn onClick={menuOnClick} className={classes.iconBtn}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconBtn>
            {menu}
          </div>
        )}
      </Bx>
    </Bx>
  );
};

ConferenceParticipant.defaultProps = {
  videoActive: true
};
