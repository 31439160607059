import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FormInterestCategories } from '../../form/compositions/FormInterestCategories';
import { FramedContent } from '../../framed-content';

const InterestsForm = (props: any) => {
  props.setNextStepDisabled(false);
  return (
    <FramedContent>
      <FormInterestCategories
        headline={'Ihre Interessen'}
        subtitleComponent={
          <Bx>
            <Typo variant="subtitle1">Die Angabe Ihrer Interessen dient dazu, Ihnen Informationen personalisiert zuzustellen, die sich mit Ihren Interessen decken.</Typo>
            <Typo variant="subtitle1">Ihre Einträge sind für andere Nutzende sichtbar.</Typo>
          </Bx>
        }
        formData={props.flowData}
        categories={props.categories}
        config={props.config}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
        splashImage={'interests'}
      />
    </FramedContent>
  );
};

export default InterestsForm;
