import { Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEmployerConfigs } from '../../../data/sagas/flow';
import { IUserProfilePlayer } from '../../../model/profile/player';
import { EmployerSelect } from '../../form/controls/employer-select';
import { FramedContent } from '../../framed-content';

const UserEmployerIntro: React.FC<{ props: any }> = ({ props }) => {
  const hasSelection = props.flowData?.itemData?.player && props.flowData?.itemData?.player.length > 0;
  if (hasSelection) {
    props.setNextStepDisabled(false);
  } else {
    props.setNextStepDisabled(true);
  }
  return (
    <FramedContent>
      <Bx mb={2}>
        <Typo variant="h1" component="h1">
          Professionalität in unserer Fach-Community: <br />
          Ihr Arbeitgeber
        </Typo>
      </Bx>
      <Bx mb={1}>
        <Typo variant="subtitle1">
          Medtec Online ist eine Fach-Community für ein erfolgreiches Zusammenspiel von Medizintechnik, Innovation und Versorgung.
          Sie steht Mitarbeitenden von Akteuren der Wirtschaft, Gesundheitsversorgung, Wissenschaft und öffentlichen Hand auf diesem Sektor offen.
        </Typo>
      </Bx>
      <Bx mb={1}>
        <Typo variant="subtitle1">
          Wir sind auftragsgemäß gehalten sicherzustellen, dass Nutzende dieser Fach-Community angehören.
        </Typo>
      </Bx>
      <Bx mb={1}>
        <Typo variant="subtitle1">
          Wir bitten Sie daher, sich nachstehend mit Ihrem Arbeitgeber bzw. Ihrer Institution zu verknüpfen.
        </Typo>
      </Bx>
      <Bx mb={3}>
        <Typo variant="subtitle1">
          Sollten Sie Einzelunternehmer sein oder einem Unternehmen angehören, das nicht im deutschen Handelsregister gelistet ist,
            dann bitten wir Sie, vor Ihrer Registrierung mit uns <a href="mailto:info@medteconline.de">Kontakt</a> aufzunehmen.
        </Typo>
        <Typo variant="subtitle1">
          Sollten Sie einer Institution aus Wissenschaft, Gesundheitsversorgung oder öffentlicher Hand angehören, die Sie in unserer Datenbank nicht finden können, dann übermitteln Sie uns bitte im Zuge Ihrer Registrierung Ihre E-Mail-Signatur.
        </Typo>
        <Typo variant="subtitle1">
          Wir prüfen dann die Zugehörigkeit zur Fach-Community anhand Ihrer Angaben.
        </Typo>
      </Bx>
      <EmployerSelect
        label={'Institution'}
        sublabel={'Department'}
        preset={props.flowData?.userSelectedEmployerFirstLevel}
        subpreset={props.flowData?.userSelectedEmployerSecondLevel}
        onSelectFirstLevel={actorData => {
          props.flowFunctions.setFlowData({
            ...props.flowData,
            userSelectedEmployerFirstLevel: actorData,
            userSelectedEmployerSecondLevel: null,
            actorRequest: null,
            employerType: null,
            itemData: {
              ...props.flowData?.itemData,
              department: '',
              organisation: '',
              player: null
            }
          });
        }}
        onSelectSecondLevel={actorData => {
          props.flowFunctions.setFlowData({
            ...props.flowData,
            userSelectedEmployerSecondLevel: actorData
          });
        }}
        onSelect={(actorData, level) => {
          props.flowFunctions.setFlowOption('createActor', true);
          const player: IUserProfilePlayer = {
            player: actorData?._id,
            playerResolved: {
              name: actorData?._source.content.name,
              alias: actorData?._source.seo.alias
            }
          };
          if (level === 'second') {
            const playerRoot = props.flowData?.userSelectedEmployerFirstLevel as any;
            player.playerRoot = playerRoot._id;
            player.playerRootResolved = {
              name: playerRoot?._source.content.name,
              alias: playerRoot?._source.seo.alias
            };
          }
          props.flowFunctions.setFlowData({
            actorRequest: null,
            employerType: null,
            itemData: {
              ...props.flowData?.itemData,
              department: level === 'second' ? actorData?._source.content.name || '' : '',
              organisation: level === 'second' ? (props.flowData?.userSelectedEmployerFirstLevel as any)?._source.content.name : actorData?._source.content.name,
              player: actorData ? [player] : null
            }
          });
        }}
      />
      {!hasSelection && (
        <Bx display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" py={3.5}>
          <Bx mb={{ xs: 2, md: 0 }}>
            <Typo variant="body1" component="div">
              <strong>
                Sie finden Ihren Arbeitgeber bzw. Ihre Institution nicht, dann übermitteln Sie uns bitte Ihre E-Mail-Signatur:
              </strong>
            </Typo>
          </Bx>
          <Btn
            onClick={e => {
              if (!!props.flowData?.userSelectedEmployerFirstLevel) {
                let parentCategory = '';
                if (!!props.flowData?.userSelectedEmployerFirstLevel?._source?.categories
                  && props.flowData?.userSelectedEmployerFirstLevel?._source?.categories.length > 0) {
                    parentCategory = props.flowData?.userSelectedEmployerFirstLevel?._source?.categories?.[0]?.categoryId;
                }
                if (!!parentCategory) {
                  props.flowFunctions.setFlowData({ employerFirstLevelCategory: parentCategory });
                }
                props.flowFunctions.setFlowOption('categorySelectEmployer', true);
                if (props.flowData.userSelectedEmployerFirstLevel._source?.content?.type === 'health institution') {
                  props.flowFunctions.setFlowFormData('hospital_id', props.flowData.userSelectedEmployerFirstLevel._id, 'actorRequest');
                } else if (props.flowData.userSelectedEmployerFirstLevel._source?.content?.type === 'scientific institution') {
                  props.flowFunctions.setFlowFormData('university_id', props.flowData.userSelectedEmployerFirstLevel._id, 'actorRequest');
                }
              } else {
                props.flowFunctions.setFlowOption('categorySelectEmployer');
                props.flowFunctions.setFlowData({ employerFirstLevelCategory: null });
              }
              props.setNextStepDisabled(false);
              props.flowFunctions.setFlowOption('createActor');
              setTimeout(() => {
                props.nextClick(e);
              }, 100);
            }}
            variant="outlined"
            size="large"
            endIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            Berufliche Signatur eingeben
          </Btn>
        </Bx>
      )}
    </FramedContent>
  );
};

export const ConnectedUserEmployerIntro = (props: any) => {
  useEmployerConfigs();
  return <UserEmployerIntro props={props} />;
};
