import React, { ReactNode } from 'react';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid, Hidden } from '@material-ui/core';
import { FramedContent } from '../../framed-content';

export const EmptyContent: React.FunctionComponent<{
  caption?: string;
  bottom?: ReactNode;
  src?: string
}> = ({
  caption,
  src,
  bottom
}) => {
  const title = caption || 'Noch nichts los. Schreiben Sie doch was!';
  const imgSrc = src || '/images/empty-chat.svg';

  return (
    <FramedContent
      display="flex"
      alignItems="center"
      height="100%"
      innerBoxProps={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', alignContent: 'center' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={7}>
              <Bx textAlign="center" mb={{ xs: 1, md: 2, lg: 3 }}>
                <img src={imgSrc} alt={title} style={{ width: '100%', maxWidth: 400 }} />
              </Bx>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} xl={7}>
              <Hidden smDown>
                <Typo variant="h1" align="center">
                  {title}
                </Typo>
              </Hidden>
              <Hidden mdUp xsDown>
                <Typo variant="h3" align="center">
                  {title}
                </Typo>
              </Hidden>
              <Hidden smUp>
                <Typo variant="h4" align="center">
                  {title}
                </Typo>
              </Hidden>
            </Grid>
          </Grid>
          {bottom && (
            <Grid container justifyContent="center">
              <Grid item>{bottom}</Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FramedContent>
  );
};
