import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invitationResponseRequest } from '../../data/reducer/communication';
import { BorderedContentPendingParticipationComponent } from '../detail/participation';

export interface IOneToOneCommunicationPendingParticipationBannerProps {
  userId: string;
}
export const OneToOneCommunicationPendingParticipationBanner: React.FC<IOneToOneCommunicationPendingParticipationBannerProps> = ({ userId }) => {
  const dispatch = useDispatch();
  const communication = useSelector(state => state.userProfile.communication);

  if (communication && communication.status === 'own-pending' && communication.participation) {
    return (
      <BorderedContentPendingParticipationComponent
        mt={2}
        headline="Anfrage"
        participation={communication.participation}
        onAccept={() => {
          dispatch(invitationResponseRequest({ id: communication.id, accepted: true, onetoone: userId }));
        }}
        onDecline={() => {
          dispatch(invitationResponseRequest({ id: communication.id, accepted: false, onetoone: userId }));
        }}
      />
    );
  }
  return <></>;
};
