import { useDispatch, useSelector } from 'react-redux';
import { Participants } from '../../../../../../components/communication/participants';
import * as _ from 'lodash';
import { IParticipantResolvedModel } from '../../../../../../model/communication/Participant';
import { revokeMeetingInvitationRequest, setParticipantModalAction, updateMeetingInvitationRequest } from '../../../../../../data/reducer/communication';

export const ConnectedSettingsMeetingParticipants: React.FC = () => {
  const dispatch = useDispatch();
  const participation = useSelector(state => state.communication?.participation);
  const communication = useSelector(state => state.communication?.communication);
  const participantsWorking = useSelector(state => state.detail.meetingParticipants.working);
  const participantsResolved = useSelector(state => state.detail.meetingParticipants.items);
  const id = useSelector(state => state.communication?.id);
  const setParticipantModal = (participant: IParticipantResolvedModel | undefined) => {
    dispatch(setParticipantModalAction({ participant }));
  };
  const onUpdateInvitation = (participantId: string, message: string) => {
    id && dispatch(updateMeetingInvitationRequest({ id, participantId, message }));
  };
  return (
    <>
      <Participants
        participants={_.sortBy(participantsResolved, 'userResolved.content.fullName')}
        roles={communication?.roles}
        permissions={participation?.permissions}
        showInvites={communication?.allowInvites && participation?.permissions?.manageInvites}
        // showRequests={communication?.allowRequests && participation?.permissions?.manageRequests}
        working={participantsWorking}
        onMakeAdmin={() => undefined} // not active in meeting
        onRemoveAdmin={() => undefined} // not active in meeting
        onAcceptRequest={() => undefined} // not active in meeting
        onRemoveParticipant={() => undefined} // not active in meeting
        onRevokeInvitation={participantId => id && dispatch(revokeMeetingInvitationRequest({ id, participantId }))}
        onDeclineRequest={() => undefined} // not active in meeting
        onUpdateInvitation={onUpdateInvitation}
        onLeave={() => undefined} // not active in meeting
        onSetModalParticipation={setParticipantModal}
      />
    </>
  );
};
