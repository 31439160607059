import React from 'react';
import { FormCategories } from '../../form/compositions/FormCategories';
import { FramedContent } from '../../framed-content';

const CategoryForm = (props: any) => {
  props.setNextStepDisabled(false);
  return (
    <FramedContent>
      <FormCategories
        headline={'Welche Themenbereiche berührt Ihr Beitrag?'}
        subtitle={'Bitte weisen Sie Ihrem Beitrag Schlagwörter zu, um Ihren gewünschten Leserkreis zu erreichen.'}
        formData={props.flowData}
        categories={props.categories}
        config={props.config}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default CategoryForm;
