import React from 'react';
import { useLocation } from 'react-router';
import { Bx } from '@curry-group/mui-curcuma';
import { INavbarFooterProps } from '..';
import { NavBarUser } from '../../navbar-user';
import { NavBarMetaLink } from '../../navbar-meta-link';

export const NavbarFooter: React.FC<INavbarFooterProps> = ({ userFullName, userAvatarSrc, userAbbreviation, userStatus }) => {
  const location = useLocation();
  return (
    <>
      <NavBarUser
        mt={4}
        mb={4}
        fullName={userFullName ?? ''}
        userAbbreviation={userAbbreviation ?? ''}
        userStatus={userStatus ?? ''}
        avatar={{ src: userAvatarSrc, alt: 'Avatar von ' + userFullName, children: userAbbreviation }}
        links={[
          { name: 'Zum Profil', to: '/user/profile?ref=' + location.pathname },
          { name: 'Ausloggen', to: '/logout' }
        ]}
      />
      <Bx display="flex" flexWrap="wrap">
        <NavBarMetaLink mailto={'info@medteconline.de'}>Kontakt</NavBarMetaLink>
        <NavBarMetaLink to={'/content/page/impressum?ref=' + location.pathname}>Impressum</NavBarMetaLink>
        <NavBarMetaLink to={'/content/page/datenschutz?ref=' + location.pathname}>Datenschutz</NavBarMetaLink>
        <NavBarMetaLink to={'/uwao-api/core/asset/System/About/MTO_FAQ.pdf'} target="_blank">FAQ</NavBarMetaLink>
        <NavBarMetaLink to={'/content/page/gemeinschaftsstandards?ref=' + location.pathname}>Gemeinschaftsstandards</NavBarMetaLink>
        <NavBarMetaLink to={'/content/page/nutzungsbedingungen?ref=' + location.pathname}>Nutzungsbedingungen</NavBarMetaLink>
        <NavBarMetaLink to={'/content/page/unterstuetzer?ref=' + location.pathname}>Unterstützer</NavBarMetaLink>
      </Bx>
    </>
  );
};
