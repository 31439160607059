import { Bx, Typo } from '@curry-group/mui-curcuma';
import { FramedContent } from '../../framed-content';
import { FormEmailUnsubscribe } from '../../form/compositions/FormEmailUnsubscribe';

const EmailUnsubscribeForm = (props: any) => {
  if (
    !props.flowData?.token
  ) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormEmailUnsubscribe
        formData={props.flowData}
        headline={'Automatische Nachrichten von Medtec Online wurden deaktiviert'}
        subtitleComponent={<>
          <Bx mb={4}>
            <Typo variant="subtitle1">
              Sie erhalten keine weiteren E-Mails zu aktuellen Inhalten von Medtec Online.
            </Typo>
          </Bx>
          <Bx>
            <Typo variant="subtitle1">
              Falls Sie Anpassungen machen wollen, können Sie das hier ändern.
            </Typo>
            <Typo variant="subtitle1">
              Legen Sie fest, aus welchen Bereichen Sie <b>keine</b> Benachrichtigungen erhalten wollen.
            </Typo>
          </Bx>
          </>
        }
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default EmailUnsubscribeForm;
