import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { intersectionWith } from 'lodash';
import { ConferencingClient } from './data/client';
import { IConferencingSession } from './data/model/session';
import { fetchParticipantsRequest } from '../../data/reducer/communication';
import { IParticipantResolvedModel } from '../../model/communication/Participant';
import { toggleConferencing } from '../../data/actions/conferencing';
import { ConferencingOutlet } from './outlet';
import { TSidebarType } from './theme';

type TListenerType = (stats: any, type: 'out' | 'in', subtype?: string) => void;
export interface IConferencingContext {
  rtcpc_out?: RTCPeerConnection;
  rtcpc_in?: { [key: string]: RTCPeerConnection | undefined };
  addListener?: (listener: TListenerType) => void;
  removeListener?: (listener: TListenerType) => void;
  emit?: TListenerType;
}

export const ConferencingContext = React.createContext<IConferencingContext>({});

const createContextValue = () => {
  const listeners: TListenerType[] = [];
  return {
    addListener: listener => {
      listeners.push(listener);
    },
    removeListener: listener => {
      const idx = listeners.indexOf(listener);
      if (idx > -1) {
        listeners.splice(idx, 1);
      }
    },
    emit: (stats, type, subtype) => {
      for (let listener of listeners) {
        setTimeout(() => {
          listener(stats, type, subtype);
        });
      }
    }
  } as IConferencingContext;
};

export const ConferencingOutletImpl: React.FC<{
  sidebarType: TSidebarType;
}> = ({ sidebarType = 'chat' }) => {
  const conferenceContextRef = useRef<IConferencingContext>();
  if (!conferenceContextRef.current) {
    conferenceContextRef.current = createContextValue();
  }
  const [currentSideBarType, setCurrentSideBarType] = useState(sidebarType);
  const conferencingStateMessage = useSelector(state => state.conferencing?.message);
  const conferencingStateSpeakersDynamic = useSelector(state => state.conferencing?.speakersDynamic);
  const conferencingStateToken = useSelector(state => state.conferencing?.token);
  const conferencingStateVisible = useSelector(state => state.conferencing?.visible);
  const displayName = useSelector(state => state.foundation.profile?.fullName);
  const userId = useSelector(state => state.foundation.profile?.userId);
  const participants = useSelector(state => state.communication.participants);

  const confSession = useRef<ConferencingClient>();
  const [session, setSession] = useState<IConferencingSession>();
  const [desktopActive, setDesktopActive] = useState(false);
  const [myMedia, setMyMedia] = useState<MediaStream>();
  const [publisher, setPublisher] = useState<any[]>();
  const sinkUpdateState = useState(Date.now());
  const [speakers, setSpeakers] = useState<string[]>();
  const [currentParticipants, setCurrentParticipants] = useState<IParticipantResolvedModel[]>([]);
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isMobile = breakpoints.mdDown;
  useEffect(() => {
    if (conferencingStateMessage?.parent) {
      dispatch(fetchParticipantsRequest({ id: conferencingStateMessage?.parent }));
    }
  }, [dispatch, conferencingStateMessage?.parent]);
  useEffect(() => {
    const resolved = intersectionWith(participants, conferencingStateMessage?.conferenceInfo?.participants || [], (a, b) => a.user === b);
    setCurrentParticipants(resolved);
  }, [participants, conferencingStateMessage?.conferenceInfo?.participants]);
  if (!confSession.current) {
    confSession.current = new ConferencingClient();
    confSession.current.emitter = conferenceContextRef.current;
    confSession.current.on('toggle-desktop', (active: boolean) => {
      setDesktopActive(active);
    });
    confSession.current.on('session-update', (s: IConferencingSession) => {
      setSession(s);
    });
    confSession.current.on('media-update', (ms: MediaStream) => {
      setMyMedia(ms);
    });
    confSession.current.on('sink-update', () => {
      sinkUpdateState?.[1]?.(Date.now());
    });
    confSession.current.on('publisher-update', (pubDict: any) => {
      const publisher: any[] = [];
      for (let key in pubDict) {
        publisher.push({ id: pubDict[key].publisherIdJanus, ...pubDict[key] });
      }
      setPublisher(publisher);
    });
    confSession.current.on('speakers-update', newSpeakers => {
      setSpeakers(newSpeakers);
    });
  }

  if (conferencingStateVisible && conferencingStateMessage && conferencingStateToken && !confSession.current.started) {
    confSession.current.setDisplayName(displayName);
    confSession.current.setUserId(userId);
    confSession.current.start(conferencingStateMessage.id, conferencingStateToken);
  } else if (!conferencingStateVisible && confSession.current.started) {
    confSession.current.stop();
  }
  useEffect(() => {
    if (confSession.current) {
      confSession.current.setMaxVideoParticipants(isMobile ? 1 : 8);
    }
  }, [publisher, isMobile]);
  var speakersDynamic = conferencingStateSpeakersDynamic || false;
  useEffect(() => {
    if (confSession.current) {
      confSession.current.setDominantSpeaker(speakersDynamic);
    }
  }, [speakersDynamic]);

  const toggleSidebar = (sidebarType: TSidebarType) => {
    if (sidebarType === currentSideBarType) {
      setCurrentSideBarType('');
    } else {
      setCurrentSideBarType(sidebarType);
    }
  };

  return (
    <ConferencingContext.Provider value={conferenceContextRef.current}>
      <ConferencingOutlet
        userName={displayName}
        desktopActive={desktopActive}
        myMedia={myMedia}
        open={conferencingStateVisible}
        currentParticipants={currentParticipants}
        publisher={publisher || []}
        speakers={speakers}
        speakersDynamic={speakersDynamic}
        session={session}
        onDevicesChange={(audio, audioOut, video) => {
          confSession.current?.updateDevices(audio, audioOut, video);
        }}
        sidebarVisible={sidebarType !== ''}
        sidebarType={currentSideBarType}
        toggleSidebar={toggleSidebar}
        closeClick={() => {
          dispatch(toggleConferencing());
          toggleSidebar('');
        }}
        toggleAudioClick={() => {
          confSession?.current?.toggleAudio();
        }}
        toggleDesktopClick={() => {
          confSession?.current?.toggleDesktop();
        }}
        toggleHandClick={() => {
          confSession?.current?.toggleHand();
        }}
        toggleVideoClick={() => {
          confSession?.current?.toggleVideo();
        }}
      />
    </ConferencingContext.Provider>
  );
};
