import { Ava, Btn, Bx, Typo } from '@curry-group/mui-curcuma';

import { Skeleton } from '@material-ui/lab';
import { faCheckCircle, faEllipsisH, faQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { assetUrl, TYPES, TYPES_PROPS, hashCode, ListAvatarColors } from '../../../../../../helper';
import { buildAbbreviation } from '../../../../../../helper/user';
import { useIsMobile } from '../../../../../../helper/hooks/isMobile';
import { selectionAtom } from '../atoms';
import { CardHeader } from '../../../../../../components/card/header';

export interface IParticipantCardProps {
  user?: any;
  userId?: string;
  userScore?: number;
  selected?: boolean;
  onAddRemove?: () => void;
}

export const ParticipantCard: React.FC<IParticipantCardProps> = ({ user, userId, userScore, selected, onAddRemove }) => {
  const isMobile = useIsMobile();
  const abbreviation = buildAbbreviation(user?.fullName ?? '');
  const hash = hashCode(abbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  return (
    <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
      <CardHeader
        style={{ border: 1, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderColor: 'transparent' }}
        src={user.keyvisual ? assetUrl(user.keyvisual, true) : TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
        alt={`${user.fullName} Keyvisual`}
        avatar={
          <Ava
            style={{color: colorObject.color, background: colorObject.bg}}
            src={user.avatar ? assetUrl(user.avatar, true) : undefined}
            size="larger"
            alt={`${user.fullName} Avatar`}
          >
            {abbreviation}
          </Ava>
        }
      />
      <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
        <Bx mb={1}>
          <Typo align="center" variant="body1">
            {localStorage.devModeSearch && (
              <span>
                {userId} || {userScore} ||{' '}
              </span>
            )}
            <strong>{user.fullName}</strong>
          </Typo>
        </Bx>
        {user.function && (
          <Bx mb={1}>
            <Typo align="center" variant="body2">
              {user.function}
            </Typo>
          </Bx>
        )}
        {user.organisation && (
          <Bx mb={2}>
            <Typo align="center" variant="body2">
              {user.organisation}
            </Typo>
          </Bx>
        )}
        <Bx textAlign="center" pt={3} mt="auto">
          {selected ? (
            <Btn variant="text" color="secondary" endIcon={<FontAwesomeIcon icon={faCheckCircle} />} onClick={onAddRemove}>
              hinzugefügt
            </Btn>
          ) : (
            <Btn variant="outlined" onClick={onAddRemove}>
              hinzufügen
            </Btn>
          )}
        </Bx>
      </Bx>
    </Bx>
  );
};

export const MeetingParticipantCard: React.FC<Omit<IParticipantCardProps, 'selected'>> = props => {
  const selectionByIdAtom = useMemo(() => {
    return atom(get => {
      return !!get(selectionAtom)[props.userId || ''];
    });
  }, [props.userId]);
  const [selected] = useAtom(selectionByIdAtom);
  return <ParticipantCard selected={selected} {...props} />;
};

export const ParticipantCardNoResult: React.FC = () => {
  const isMobile = useIsMobile();
  const hashNotFound = hashCode('NF');
  const colorIndexNotFound = hashNotFound % 4;
  const colorObjectNotFound = ListAvatarColors[colorIndexNotFound];
  return (
    <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
      <CardHeader
        style={{ border: 1, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderColor: 'transparent' }}
        src={TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
        alt={`No User Found Keyvisual`}
        avatar={
          <Ava src={undefined}
            size="larger"
            alt={`No User Found Avatar`}
            style={{color: colorObjectNotFound.color, background: colorObjectNotFound.bg}}
            children={<FontAwesomeIcon icon={faQuestion} />}
          />
        }
      />
      <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
        <Bx mb={2}>
          <Typo align="center" variant="subtitle1">
            <strong>Leider konnte für Ihre Suchanfrage kein Teilnehmender gefunden werden.</strong>
          </Typo>
        </Bx>
        <Bx>
          <Typo align="center" variant="body1">
            Bitte versuchen Sie andere Suchoptionen.
          </Typo>
        </Bx>
      </Bx>
    </Bx>
  );
};

export const ParticipantCardWorking: React.FC<{ keyIndex: number }> = ({ keyIndex }) => {
  const isMobile = useIsMobile();
  const hash = hashCode(`U${keyIndex}`);
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];
  return (
    <Bx border={1} borderRadius={'borderRadius2'} borderColor="#D6D7D9">
      <CardHeader
        style={{ border: 1, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderColor: 'transparent' }}
        src={TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
        alt={`No User Found Keyvisual`}
        avatar={
          <Ava loading={true}
            src={undefined}
            size="larger"
            alt={`No User Found Avatar`}
            style={{color: colorObject.color, background: colorObject.bg}}
            children={<FontAwesomeIcon icon={faEllipsisH} />}
          />
        }
      />
      <Bx py={2} px={3} minHeight={isMobile ? 150 : 240} display="flex" flexDirection="column">
        <Bx mb={2}>
          <Typo align="center" variant="subtitle1">
            <Skeleton animation="wave" width="80%" />
          </Typo>
        </Bx>
        <Bx>
          <Typo align="center" variant="body1">
            <Skeleton animation="wave" width="60%" />
          </Typo>
        </Bx>
      </Bx>
    </Bx>
  );
};
