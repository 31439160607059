import { IBxProps, ICtnProps, useBreakpoints } from '@curry-group/mui-curcuma';
import React from 'react';
import { MTOLogo } from '../../../../helper';

import { LandingPageHeader } from '../../header';

export const LandingpageHeaderImpl: React.FC<{ scrolled?: boolean; view?: string; backButtonLink?: any; auth?: boolean }> = ({ scrolled, view, backButtonLink, auth }) => {
  const ctnMaxWidthHeader: ICtnProps['maxWidth'] = 'xl';
  const sectionPx: IBxProps['px'] = backButtonLink ? { xs: 1, md: 1, lg:4 } : { xs: 2, md: 2, lg:4 };
  const responsive = useBreakpoints();
  return (
    <LandingPageHeader
      logoSrc={MTOLogo.logoWithTextSrc}
      logoAlt={MTOLogo.logoAlt}
      partnerLogoSrc={MTOLogo.partnerLogoSrc}
      partnerLogoMobileSrc={MTOLogo.partnerLogoMobileSrc}
      partnerLogoAlt={MTOLogo.partnerLogoAlt}
      ctnMaxWidth={ctnMaxWidthHeader}
      responsive={responsive}
      scrolled={view !== 'default' || !!scrolled}
      sectionPx={sectionPx}
      registerLabel="Jetzt registrieren"
      registerLink="/optin"
      loginLabel="Anmelden"
      loginLink="/login"
      backButtonLink={backButtonLink}
      auth={auth || false}
    />
  );
};
