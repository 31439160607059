import React, { ReactNode } from 'react';
import { IUploadableFile } from '../../../data/reducer/communication';
import { Grid } from '@material-ui/core';
import { faTimes, faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ava, Bx, IBxProps, IconAva, IconBtn, Typo } from '@curry-group/mui-curcuma';
import { assetUrl, formatBytes, iconForExtension, imageFileExtensions } from '../../../helper';
import { IAsset } from '../../../model/ryve/Asset';

export interface AttachmentViewProps {
  name: string;
  extension: string;
  size: number;
  src?: string;
  color?: string;
  mt?: IBxProps['mt'];
}

export const AttachmentView: React.FC<AttachmentViewProps & { onRemove?: () => void; onDownload?: () => void }> = ({
  mt,
  name,
  extension,
  size,
  src,
  onRemove,
  onDownload,
  color
}) => {
  let avatar: ReactNode = null;
  if (imageFileExtensions.includes((extension || '').toLowerCase())) {
    // is image
    avatar = <Ava size="small" variant="rounded" src={src} />;
  } else {
    // icon
    avatar = (
      <IconAva size="small">
        <FontAwesomeIcon icon={iconForExtension((extension || '').toLowerCase())} />
      </IconAva>
    );
  }

  return (
    <Bx mt={mt} color={color} display="flex" alignItems="center" p={1} bgcolor="#F8F8F8" border={1} borderRadius="borderRadius2" borderColor="#D6D7D9">
      <Bx mr={2}>
        <Typo variant="h1" color="primary" component="div">
          {avatar}
        </Typo>
      </Bx>
      <Bx mr={2}>
        <Typo variant="body2" fontWeight={500} style={{ lineHeight: 1.25 }} gutterBottom>
          {name}
        </Typo>
        <Typo variant="subtitle3">{formatBytes(size)}</Typo>
      </Bx>
      <Bx ml="auto">
        {!!onRemove && (
          <IconBtn size="small" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </IconBtn>
        )}
        {!!onDownload && (
          <IconBtn size="small" onClick={onDownload}>
            <FontAwesomeIcon icon={faDownload} />
          </IconBtn>
        )}
      </Bx>
    </Bx>
  );
};

export interface FileAttachmentProps {
  file: IUploadableFile;
  onRemove?: () => void;
}

export const FileAttachment: React.FC<FileAttachmentProps> = ({ file, onRemove }) => {
  const extension = '.' + file.name.split('.').pop();
  const src = imageFileExtensions.includes((extension || '').toLowerCase()) ? URL.createObjectURL(file) : '';
  return <AttachmentView name={file.name} size={file.size} src={src} extension={extension} onRemove={onRemove} />;
};

export interface AssetAttachmentProps {
  asset: IAsset;
  onRemove?: () => void;
  onDownload?: (asset: IAsset) => void;
  mt?: IBxProps['mt'];
  color?: string;
}

export const AssetAttachment: React.FC<AssetAttachmentProps> = ({ asset, color, onRemove, onDownload, mt }) => {
  const src = imageFileExtensions.includes((asset?.extension || '').toLowerCase()) ? assetUrl(asset._id) : '';
  return (
    <AttachmentView
      color={color}
      mt={mt}
      name={asset.name + asset.extension}
      size={asset.fileSize}
      src={src}
      extension={asset.extension}
      onRemove={onRemove}
      onDownload={!!onDownload ? () => onDownload(asset) : undefined}
    />
  );
};

export interface AttachmentsViewProps {
  assets?: IAsset[];
  files?: IUploadableFile[];
  setFiles?: (files: IUploadableFile[]) => void;
}

export const AttachmentsView: React.FC<AttachmentsViewProps> = ({ assets, files, setFiles }) => {
  return (
    <Bx mt={2}>
      <Grid container spacing={1}>
        {files?.map((attachment, index) => (
          <Grid key={'file-' + index} item xs={12} md={6}>
            <FileAttachment
              file={attachment}
              onRemove={() => {
                setFiles?.(files.filter(file => file.name !== attachment.name));
              }}
            />
          </Grid>
        ))}
        {assets?.map((attachment, index) => (
          <Grid key={'asset-' + index} item xs={12} md={6}>
            <AssetAttachment asset={attachment} onRemove={() => {}} />
          </Grid>
        ))}
      </Grid>
    </Bx>
  );
};
