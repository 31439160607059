import { put, select, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { callDelete, callGet, callPost, callPostPlain, callPut } from '@curry-group/data-addons';
import { signalrEventHandling, signalRWatcher } from './signalr';
import * as _ from 'lodash';
import {
  setCommunication,
  fetchParticipationRequest,
  fetchParticipationFailed,
  fetchMessagesRequest,
  createConferenceMessageRequest,
  createConferenceMessageSuccess,
  createConferenceMessageFailed,
  createMessageRequest,
  createMessageSuccess,
  createMessageFailed,
  fetchMessagesSuccess,
  fetchMessagesFailed,
  fetchParticipationSuccess,
  fetchMessageByIdRequest,
  fetchMessageByIdSuccess,
  fetchMessageByIdFailed,
  fetchParticipantsRequest,
  fetchParticipantsSuccess,
  fetchParticipantsFailed,
  deleteMessageRequest,
  deleteMessageSuccess,
  deleteMessageFailed,
  getNumUnreadMessagesRequest,
  getNumUnreadMessagesSuccess,
  getNumUnreadMessagesFailed,
  setLastReadTimestampRequest,
  setLastReadTimestampSuccess,
  setLastReadTimestampFailed,
  updateMessageRequest,
  updateMessageSuccess,
  updateMessageFailed,
  fetchCommunicationsRequest,
  fetchCommunicationsSuccess,
  fetchCommunicationsFailed,
  getNumUnreadMessagesByIdRequest,
  getNumUnreadMessagesByIdFailed,
  getNumUnreadMessagesByIdSuccess,
  invitationResponseRequest,
  invitationResponseSuccess,
  invitationResponseFailed,
  joinConferenceRequest,
  joinConferenceSuccess,
  joinConferenceFailed,
  removeParticipantRequest,
  removeParticipantSuccess,
  removeParticipantFailed,
  revokeInvitationRequest,
  revokeInvitationSuccess,
  revokeInvitationFailed,
  requestResponseRequest,
  requestResponseSuccess,
  requestResponseFailed,
  revokeRequestRequest,
  revokeRequestSuccess,
  revokeRequestFailed,
  requestParticipationRequest,
  requestParticipationSuccess,
  requestParticipationFailed,
  setAdministratorStatusRequest,
  setAdministratorStatusSuccess,
  setAdministratorStatusFailed,
  fetchCommunicationByIdRequest,
  fetchCommunicationByIdSuccess,
  fetchCommunicationByIdFailed,
  addParticipantsRequest,
  addParticipantsSuccess,
  addParticipantsFailed,
  updateInvitationRequest,
  updateInvitationFailed,
  updateInvitationSuccess,
  setMessageDraftAttachmentPreparing,
  setMessageDraftAttachments,
  setMessageDraftAttachmentPrepared,
  setMessageDraftAttachmentFailed,
  fetchFilesRequest,
  fetchFilesSuccess,
  fetchFilesFailed,
  fetchOlderMessagesRequest,
  fetchOlderMessagesFailed,
  fetchOlderMessagesSuccess,
  fetchNewerMessagesRequest,
  fetchNewerMessagesSuccess,
  fetchNewerMessagesFailed,
  voteMessageRequest,
  setUploadFiles,
  setUploadFilePreparing,
  setUploadFilePrepared,
  setUploadFileFailed,
  uploadFilesRequest,
  uploadFilesSuccess,
  uploadFilesFailed,
  setUploadFilesActive,
  unreadMessagesChanged,
  leaveCommunicationRequest,
  deleteFilesRequest,
  deleteFilesFailed,
  deleteFilesSuccess,
  downloadFilesRequest,
  downloadFilesResponse,
  setDefaultRoleRequest,
  setDefaultRoleSuccess,
  setDefaultRoleFailed,
  subscribeToSignalR,
  revokeMeetingInvitationRequest,
  revokeMeetingInvitationSuccess,
  revokeMeetingInvitationFailed,
  updateMeetingInvitationRequest,
  updateMeetingInvitationFailed,
  updateMeetingInvitationSuccess,
  setUserMessageEmoteRequest,
  setUserMessageEmoteFailed,
  setUserMessageEmoteSuccess,
  fetchProfileFullNameByIdsRequest,
  fetchProfileFullNameByIdsSuccess,
  fetchProfileFullNameByIdsFailed,
  toggleWriteProtectRequest,
  toggleWriteProtectSuccess,
  toggleWriteProtectFailed,
  setActiveView,
  setActiveTab,
  fetchAdminParticipationsRequest,
  fetchAdminParticipationsSuccess,
  fetchAdminParticipationsFailed,
} from '../../reducer/communication';

import { api } from '../../api';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setSpeakersDynamic, toggleConferencing, updateConferencingMessage } from '../../actions/conferencing';
import { IMessageModel } from '../../../model/communication/Message';
import { finishFlowErrorAction, finishFlowSuccessAction } from '../../actions/flow';
import { userprofileCommunicationRequest } from '../../reducer/userprofile';
import { setHasBadgeAction } from '../../actions/notifications';
import { GetCookie, IDictionary, isSubscriptionType } from '../../../helper';
import { fetchStartupSuccessAction } from '../../actions/foundation';
import { IContainerConfig } from '../../../model/configuration';
import { ICommunicationListItem } from '../../../model/communication/Communication';
import { fetchMeetingParticipantsRequest, updateCommitmentRequest, updateConferencing } from '../../reducer/detail';
import { ThingTypes } from '../../../model/ryve/Thing';

function* watcher() {
  yield takeLatest(setCommunication.type, setCommunicationWatcher);

  yield takeLatest(fetchParticipationRequest.type, fetchParticipationWorker);

  yield takeLatest(fetchMessagesRequest.type, fetchMessagesWorker);
  yield takeLatest(fetchOlderMessagesRequest.type, fetchOlderMessagesWorker);
  yield takeLatest(fetchNewerMessagesRequest.type, fetchNewerMessagesWorker);

  yield takeLatest(joinConferenceRequest.type, joinConferenceWorker);
  yield takeLatest(createConferenceMessageRequest.type, createConferenceMessageWorker);
  yield takeLatest(createMessageRequest.type, createMessageWorker);

  yield takeLatest(toggleConferencing.type, setSpeakersDynamicWorker);

  yield takeLatest(deleteMessageRequest.type, deleteMessageWorker);

  yield takeLatest(updateMessageRequest.type, updateMessageWorker);

  yield takeLatest(fetchParticipantsRequest.type, fetchParticipantsWorker);

  yield takeEvery(getNumUnreadMessagesRequest.type, getNumUnreadMessagesWorker);

  yield takeLatest(setLastReadTimestampRequest.type, setLastReadTimestampWorker);

  yield takeLatest(fetchCommunicationsRequest.type, fetchCommunicationsWorker);
  yield takeLatest(fetchCommunicationByIdRequest.type, fetchCommunicationByIdWorker);

  yield takeEvery(fetchMessageByIdRequest.type, fetchMessageByIdWorker);
  yield takeEvery(fetchMessageByIdSuccess.type, fetchMessageByIdSuccessWorker);

  yield takeEvery(getNumUnreadMessagesByIdRequest.type, getNumUnreadMessagesByIdWorker);

  yield takeLatest(invitationResponseRequest.type, invitationResponseWorker);

  yield takeLatest(removeParticipantRequest.type, removeParticipantWorker);

  yield takeLatest(revokeInvitationRequest.type, revokeInvitationWorker);
  yield takeLatest(revokeMeetingInvitationRequest.type, revokeMeetingInvitationWorker);

  yield takeLatest(updateInvitationRequest.type, updateInvitationWorker);
  yield takeLatest(updateMeetingInvitationRequest.type, updateMeetingInvitationWorker);

  yield takeLatest(requestResponseRequest.type, requestResponseWorker);

  yield takeLatest(revokeRequestRequest.type, revokeRequestWorker);

  yield takeLatest(requestParticipationRequest.type, requestParticipationWorker);

  yield takeLatest(setDefaultRoleRequest.type, setDefaultRoleWorker);

  yield takeLatest(setAdministratorStatusRequest.type, setAdministratorStatusWorker);

  yield takeLatest(addParticipantsRequest.type, addParticipantsWorker);

  yield takeLatest(setMessageDraftAttachments.type, uploadAttachmentsWorker);

  yield takeLatest(setUploadFiles.type, prepareUploadFilesWorker);

  yield takeLatest(uploadFilesRequest.type, finalizeUploadFilesWorker);

  yield takeLatest(fetchFilesRequest.type, fetchFilesWorker);

  yield takeLatest(voteMessageRequest.type, voteMessageWorker);

  yield takeEvery(unreadMessagesChanged.type, unreadMessagesChangedWorker);

  yield takeLatest(fetchStartupSuccessAction.type, fetchInitialUnreadMessagesWorker);

  yield takeLatest(leaveCommunicationRequest.type, leaveCommunicationWorker);

  yield takeLatest(deleteFilesRequest.type, deleteFilesWorker);

  yield takeLatest(downloadFilesRequest.type, downloadFilesWorker);

  yield takeLatest(setUserMessageEmoteRequest.type, setUserMessageEmoteWorker);
  yield takeLatest(fetchProfileFullNameByIdsRequest.type, fetchProfileFullNameByIdWorker);

  yield takeLatest(toggleWriteProtectRequest.type, toggleWriteProtectWorker);

  yield takeLatest(fetchAdminParticipationsRequest.type, fetchAdminParticipationsWorker);
}

function* downloadFilesWorker(action: ReturnType<typeof downloadFilesRequest>) {
  const fileIds = action.payload.files.map(f => f._id);

  if (fileIds.length === 0 || fileIds.length >= 40) {
    yield put(downloadFilesResponse({ success: false }));
  }

  const url = api.query(api.params(api.v2.communication.downloadFiles, { id: action.payload.communicationId }), { ids: fileIds });

  try {
    // no csrf token needed, does not manipulate data
    const result = yield fetch(url)
      .then(async res => {
        return res.status === 200
          ? {
              data: await res.blob(),
              filename: decodeURIComponent((res.headers.get('file-name') || '').replace(/\+/g, '%20')),
            }
          : null;
      })
      .then(obj => {
        if (obj && obj.data) {
          var url = window.URL.createObjectURL(obj.data);
          var a = document.createElement('a');
          a.href = url;
          a.download = obj.filename || 'Download';
          document.body.appendChild(a);
          a.click();
          a.remove();
          return true;
        }
        return false;
      });
    if (result) {
      yield put(downloadFilesResponse({ success: true }));
    } else {
      yield put(downloadFilesResponse({ success: false }));
    }
  } catch (e: any) {
    yield put(downloadFilesResponse({ success: false }));
  }
}

function* deleteFilesWorker(action: ReturnType<typeof deleteFilesRequest>) {
  const url = api.params(api.v2.communication.files, { id: action.payload.communicationId });
  const fileIds = action.payload.files.map(f => f._id);
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(url, fileIds, addHeader);
    if (result.success) {
      yield put(deleteFilesSuccess(result));
    } else {
      yield put(deleteFilesFailed(result));
    }
    yield put(fetchFilesRequest({ id: action.payload.communicationId }));
  } catch (e: any) {
    yield put(deleteFilesFailed({ success: false, data: fileIds, message: e.message }));
  }
}

function* leaveCommunicationWorker(action: ReturnType<typeof leaveCommunicationRequest>) {
  try {
    const url = api.params(api.v2.communication.leave, { id: action.payload.id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(url, undefined, addHeader);
    if (result.success) {
      if (action.payload.resubscribe || !action.payload.history || !action.payload.navigateTo) {
        yield put(subscribeToSignalR({ id: action.payload.id }));
        yield put(fetchParticipantsRequest({ id: action.payload.id }));
        yield put(fetchParticipationRequest({ id: action.payload.id }));
      } else {
        yield put(setCommunication({ id: undefined, communication: undefined }));
        yield put(fetchCommunicationByIdRequest({ id: action.payload.id }));
        action.payload.history.push(action.payload.navigateTo);
      }
    }
  } catch (e: any) {}
}

function* fetchInitialUnreadMessagesWorker() {
  const appconfigs: IDictionary<IContainerConfig> = yield select(state => state.foundation?.appconfig);
  const anon: boolean = yield select(state => state.foundation?.anon);
  if (appconfigs && !anon) {
    for (let config of Object.values(appconfigs)) {
      if (config.sidebarTypes && config.sidebarTypes.length) {
        yield put(getNumUnreadMessagesRequest({ types: config.sidebarTypes }));
      }
    }
  }
}

function* unreadMessagesChangedWorker() {
  const numUnreadDict: IDictionary<IDictionary<number>> = yield select(state => state.communication?.numUnreadMessages);

  if (numUnreadDict) {
    for (let type of Object.keys(numUnreadDict)) {
      if (isSubscriptionType(type)) continue;
      if (numUnreadDict[type]) {
        const values = _.compact(Object.values(numUnreadDict[type]));
        let reduced = 0;
        if (values && values.length) {
          reduced = _.compact(values).reduce((a, b) => a + b);
        }
        const currentBadge = yield select(state => state.notifications?.badges?.[type]);

        if (currentBadge !== reduced) {
          yield put(setHasBadgeAction({ id: type, count: reduced }));
        }
      }
    }
  }
}

function* uploadAttachmentsWorker(action: ReturnType<typeof setMessageDraftAttachments>) {
  if (!action.payload.attachments) return;
  const xsrfToken = GetCookie('XSRF-TOKEN');
  const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
  for (let attachment of action.payload.attachments) {
    if (!attachment.tempname) {
      try {
        yield put(setMessageDraftAttachmentPreparing({ id: action.payload.id, attachment }));
        const newFormData = new FormData();
        newFormData.append('file', attachment);
        const url = api.params(api.v2.communication.prepareAttachments, { id: action.payload.communicationId });
        const result = yield callPostPlain(url, newFormData, addHeader);
        if (result.success) {
          yield put(setMessageDraftAttachmentPrepared({ id: action.payload.id, attachment, tempFilename: result.data }));
        } else {
          yield put(setMessageDraftAttachmentFailed({ id: action.payload.id, attachment }));
        }
      } catch (e: any) {
        console.error(e);
        yield put(setMessageDraftAttachmentFailed({ id: action.payload.id, attachment }));
      }
    }
  }
}

function* prepareUploadFilesWorker(action: ReturnType<typeof setUploadFiles>) {
  if (!action.payload.files) return;
  const xsrfToken = GetCookie('XSRF-TOKEN');
  const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
  for (let file of action.payload.files) {
    if (!file.tempname) {
      try {
        yield put(setUploadFilePreparing({ file }));
        const newFormData = new FormData();
        newFormData.append('file', file);
        const url = api.params(api.v2.communication.prepareAttachments, { id: action.payload.communicationId });
        const result = yield callPostPlain(url, newFormData, addHeader);
        if (result.success) {
          yield put(setUploadFilePrepared({ file, tempFilename: result.data }));
        } else {
          yield put(setUploadFileFailed({ file }));
        }
      } catch (e: any) {
        console.error(e);
        yield put(setUploadFileFailed({ file }));
      }
    }
  }
}

function* finalizeUploadFilesWorker(action: ReturnType<typeof uploadFilesRequest>) {
  try {
    if (!action.payload.communicationId) return;
    if (!action.payload.files || !action.payload.files.length) return;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const url = api.params(api.v2.communication.finalizeUpload, { id: action.payload.communicationId });
    const result = yield callPost(
      url,
      action.payload.files.map(f => f.tempname),
      addHeader
    );
    if (result.success) {
      yield put(uploadFilesSuccess({}));
      yield put(setUploadFilesActive(false));
      yield put(fetchFilesRequest({ id: action.payload.communicationId }));
    } else {
      yield put(uploadFilesFailed({ message: result.message }));
    }
  } catch (e: any) {
    yield put(uploadFilesFailed({ message: e.message }));
  }
}

function* revokeRequestWorker(action: ReturnType<typeof revokeRequestRequest>) {
  try {
    const url = api.params(api.v2.communication.request, { id: action.payload.id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(url, undefined, addHeader);
    if (result.success) {
      yield put(revokeRequestSuccess({}));
      yield put(fetchParticipationRequest({ id: action.payload.id }));
    } else {
      yield put(revokeRequestFailed({}));
    }
  } catch (e: any) {
    yield put(revokeRequestFailed({ message: e.message }));
  }
}

function* requestParticipationWorker(action: ReturnType<typeof requestParticipationRequest>) {
  try {
    const url = api.params(api.v2.communication.request, { id: action.payload.id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(url, action.payload.message, addHeader);
    if (result.success) {
      yield put(requestParticipationSuccess({}));
      yield put(fetchParticipationRequest({ id: action.payload.id }));
      if (action.payload.reloadParticipants) yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(requestParticipationFailed({}));
    }
  } catch (e: any) {
    yield put(requestParticipationFailed({ message: e.message }));
  }
}

function* requestResponseWorker(action: ReturnType<typeof requestResponseRequest>) {
  try {
    const url = api.params(api.v2.communication.requestResponse, { id: action.payload.id, participantId: action.payload.participantId });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = action.payload.accept ? yield callPost(url, undefined, addHeader) : yield callDelete(url, undefined, addHeader);
    if (result.success) {
      yield put(requestResponseSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(requestResponseFailed({}));
    }
  } catch (e: any) {
    yield put(requestResponseFailed({ message: e.message }));
  }
}

function* updateInvitationWorker(action: ReturnType<typeof updateInvitationRequest>) {
  try {
    const url = api.v2.communication.participantInvitation;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPut(api.params(url, { id: action.payload.id, participantId: action.payload.participantId }), action.payload.message, addHeader);
    if (result.success) {
      yield put(updateInvitationSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(updateInvitationFailed({}));
    }
  } catch (e: any) {
    yield put(updateInvitationFailed({ message: e.message }));
  }
}

function* updateMeetingInvitationWorker(action: ReturnType<typeof updateMeetingInvitationRequest>) {
  try {
    const url = api.v2.meetings.meetingInvitation;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPut(api.params(url, { id: action.payload.id, participantId: action.payload.participantId }), action.payload.message, addHeader);
    if (result.success) {
      yield put(updateMeetingInvitationSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(updateMeetingInvitationFailed({}));
    }
  } catch (e: any) {
    yield put(updateMeetingInvitationFailed({ message: e.message }));
  }
}

function* revokeInvitationWorker(action: ReturnType<typeof revokeInvitationRequest>) {
  try {
    const url = api.v2.communication.participantInvitation;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(api.params(url, { id: action.payload.id, participantId: action.payload.participantId }), undefined, addHeader);
    if (result.success) {
      yield put(revokeInvitationSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
      const sidebarTypes = yield select(state => state.foundation?.activeConfig?.sidebarTypes);
      if (!!sidebarTypes && sidebarTypes.length > 0) {
        yield put(fetchCommunicationsRequest({ types: sidebarTypes }));
      }
    } else {
      yield put(revokeInvitationFailed({}));
    }
  } catch (e: any) {
    yield put(revokeInvitationFailed({ message: e.message }));
  }
}

function* revokeMeetingInvitationWorker(action: ReturnType<typeof revokeMeetingInvitationRequest>) {
  try {
    const url = api.v2.meetings.meetingInvitation;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(api.params(url, { id: action.payload.id, participantId: action.payload.participantId }), undefined, addHeader);
    if (result.success) {
      yield put(revokeMeetingInvitationSuccess({}));
      yield put(fetchMeetingParticipantsRequest());
    } else {
      yield put(revokeMeetingInvitationFailed({}));
    }
  } catch (e: any) {
    yield put(revokeMeetingInvitationFailed({ message: e.message }));
  }
}

function* removeParticipantWorker(action: ReturnType<typeof removeParticipantRequest>) {
  try {
    const url = api.v2.communication.removeParticipant;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(api.params(url, { id: action.payload.id, participantId: action.payload.participantId }), undefined, addHeader);
    if (result.success) {
      yield put(removeParticipantSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(removeParticipantFailed({}));
    }
  } catch (e: any) {
    yield put(removeParticipantFailed({ message: e.message }));
  }
}

function* setDefaultRoleWorker(action: ReturnType<typeof setDefaultRoleRequest>) {
  try {
    const url = api.params(api.v2.communication.role, { participantId: action.payload.participantId, id: action.payload.id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = action.payload.remove ? yield callDelete(url, undefined, addHeader) : yield callPost(url, undefined, addHeader);
    if (result.success) {
      yield put(setDefaultRoleSuccess({}));
      yield put(subscribeToSignalR({ id: action.payload.id }));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
      yield put(fetchParticipationRequest({ id: action.payload.id }));
    } else {
      yield put(setDefaultRoleFailed({}));
    }
  } catch (e: any) {
    yield put(setDefaultRoleFailed({ message: e.message }));
  }
}

function* setAdministratorStatusWorker(action: ReturnType<typeof setAdministratorStatusRequest>) {
  try {
    const url = api.params(api.v2.communication.administrator, { participantId: action.payload.participantId, id: action.payload.id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = action.payload.admin ? yield callPost(url, undefined, addHeader) : yield callDelete(url, undefined, addHeader);
    if (result.success) {
      yield put(setAdministratorStatusSuccess({}));
      yield put(fetchParticipantsRequest({ id: action.payload.id }));
    } else {
      yield put(setAdministratorStatusFailed({}));
    }
  } catch (e: any) {
    yield put(setAdministratorStatusFailed({ message: e.message }));
  }
}

function* invitationResponseWorker(action: ReturnType<typeof invitationResponseRequest>) {
  try {
    const url = action.payload.accepted ? api.v2.communication.acceptInvitation : api.v2.communication.declineInvitation;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.params(url, { id: action.payload.id }), undefined, addHeader);
    if (result.success) {
      yield put(invitationResponseSuccess({}));
      if (!action.payload.onetoone) {
        yield put(fetchParticipationRequest({ id: action.payload.id }));
        if (action.payload.accepted) {
          yield put(fetchCommunicationByIdRequest({ id: action.payload.id }));
        }
      } else {
        yield put(userprofileCommunicationRequest({ id: action.payload.onetoone }));
      }
    } else {
      yield put(invitationResponseFailed({}));
    }
  } catch (e: any) {
    yield put(invitationResponseFailed({ message: e.message }));
  }
}

function* fetchCommunicationByIdWorker(action: ReturnType<typeof fetchCommunicationByIdRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.byId, { id: action.payload.id }));
    if (result.success) {
      const communicationListItem: ICommunicationListItem = result.data;
      const activeConfig: IContainerConfig = yield select(state => state.foundation.activeConfig);
      if (activeConfig && activeConfig.sidebarTypes && activeConfig.sidebarTypes.includes(communicationListItem.type)) {
        yield put(fetchCommunicationByIdSuccess({ id: action.payload.id, communication: result.data }));
      }
    } else {
      yield put(fetchCommunicationByIdFailed({ id: action.payload.id }));
    }
  } catch (e: any) {
    yield put(fetchCommunicationByIdFailed({ message: e.message, id: action.payload.id }));
  }
}

function* fetchCommunicationsWorker(action: ReturnType<typeof fetchCommunicationsRequest>) {
  try {
    const result = yield callGet(api.query(api.v2.communication.base, { types: action.payload.types?.join(',') }));
    if (result.success) {
      yield put(fetchCommunicationsSuccess({ communications: result.data }));
    } else {
      yield put(fetchCommunicationsFailed({}));
    }
  } catch (e: any) {
    yield put(fetchCommunicationsFailed({ message: e.message }));
  }
}

function* setLastReadTimestampWorker(action: ReturnType<typeof setLastReadTimestampRequest>) {
  try {
    const participation = yield select(state => state.communication?.participation);
    if (!participation) return;
    if (participation.lastReadTimestamp >= action.payload.timestamp) return;
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.params(api.v2.communication.readMessage, { timestamp: action.payload.timestamp, id: action.payload.id }), undefined, addHeader);
    if (result.success) {
      yield put(setLastReadTimestampSuccess({ id: action.payload.id }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(setLastReadTimestampFailed({}));
    }
  } catch (e: any) {
    yield put(setLastReadTimestampFailed({ message: e.message }));
  }
}

function* getNumUnreadMessagesByIdWorker(action: ReturnType<typeof getNumUnreadMessagesByIdRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.unreadMessagesForId, { id: action.payload.id }));
    if (result.success) {
      yield put(getNumUnreadMessagesByIdSuccess({ id: action.payload.id, dict: result.data }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(getNumUnreadMessagesByIdFailed({}));
    }
  } catch (e: any) {
    yield put(getNumUnreadMessagesByIdFailed({ message: e.message }));
  }
}

function* getNumUnreadMessagesWorker(action: ReturnType<typeof getNumUnreadMessagesRequest>) {
  try {
    const result = yield callGet(api.query(api.v2.communication.unreadMessagesForTypes, { types: action.payload.types?.join(',') }));
    if (result.success) {
      yield put(getNumUnreadMessagesSuccess({ dict: result.data }));
      yield put(unreadMessagesChanged());
    } else {
      yield put(getNumUnreadMessagesFailed({}));
    }
  } catch (e: any) {
    yield put(getNumUnreadMessagesFailed({ message: e.message }));
  }
}

function* joinConferenceWorker(action: ReturnType<typeof joinConferenceRequest>) {
  try {
    // if (!action.payload.message.conferenceInfo?.ended) {
    const detailType = yield select(state => state.detail?.item?.type);

    const result = yield callGet(api.params(api.v2.communication.joinconference, { messageId: action.payload.message.id }));
    yield put(joinConferenceSuccess({}));
    yield put(toggleConferencing({ message: action.payload.message, token: result }));

    if (detailType === ThingTypes.Meeting) yield put(updateCommitmentRequest({ commit: true }));
  } catch (e: any) {
    yield put(joinConferenceFailed({}));
  }
}

function* createConferenceMessageWorker(action: ReturnType<typeof createConferenceMessageRequest>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(
      api.params(api.v2.communication.conferenceMessage, { id: action.payload.communicationId, title: action.payload.title || 'Neue Besprechung' }),
      undefined,
      addHeader
    );
    const detailType = yield select(state => state.detail?.item?.type);
    if (result.success) {
      yield put(createConferenceMessageSuccess({ id: action.payload.id, message: result.data.message }));
      yield put(toggleConferencing({ message: result.data.message, token: result.data.token }));

      if (detailType === ThingTypes.Meeting) yield put(updateCommitmentRequest({ commit: true }));
    } else {
      yield put(createConferenceMessageFailed({ id: action.payload.id, message: result.message }));
    }
  } catch (e: any) {
    yield put(createConferenceMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* setSpeakersDynamicWorker(action: ReturnType<typeof toggleConferencing>) {
  const isMobile = yield select(state => state.foundation?.isMobile);
  yield put(setSpeakersDynamic({ setDynamic: isMobile }));
}

function* createMessageWorker(action: ReturnType<typeof createMessageRequest>) {
  try {
    const url = api.params(api.v2.communication.messages, { id: action.payload.communicationId });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(
      url,
      {
        content: action.payload.content,
        threadRoot: action.payload.threadRoot?.id,
        quotes: action.payload.quotes?.id,
        attachments: action.payload.attachments?.map(file => file.tempname),
        attachedThing: action.payload.attachedThing,
      },
      addHeader
    );

    if (result.success) {
      yield put(createMessageSuccess({ id: action.payload.id, message: result.data }));
    } else {
      yield put(createMessageFailed({ id: action.payload.id, message: result.message }));
    }
  } catch (e: any) {
    yield put(createMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* voteMessageWorker(action: ReturnType<typeof voteMessageRequest>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPost(
      api.params(api.v2.communication.voteMessage, { id: action.payload.communicationId, messageId: action.payload.messageId, vote: action.payload.up ? 1 : -1 }),
      undefined,
      addHeader
    );
  } catch (e: any) {}
}

function* updateMessageWorker(action: ReturnType<typeof updateMessageRequest>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPut(
      api.params(api.v2.communication.messages, { id: action.payload.communicationId }),
      {
        id: action.payload.messageId,
        content: action.payload.content,
      },
      addHeader
    );
    if (result.success) {
      yield put(updateMessageSuccess({ id: action.payload.id, message: result.data }));
    } else {
      yield updateMessageFailed({ id: action.payload.id, message: result.message });
    }
  } catch (e: any) {
    yield put(updateMessageFailed({ id: action.payload.id, message: e.message }));
  }
}

function* deleteMessageWorker(action: ReturnType<typeof deleteMessageRequest>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callDelete(
      api.params(api.v2.communication.messages, { id: action.payload.communicationId }),
      {
        id: action.payload.id,
      },
      addHeader
    );
    if (result.success) {
      yield put(deleteMessageSuccess({ message: result.data, localRemove: action.payload.deleted }));
    } else {
      yield put(deleteMessageFailed({ message: result.message }));
    }
  } catch (e: any) {
    yield put(deleteMessageFailed({ message: e.message }));
  }
}

function* addParticipantsWorker(action: ReturnType<typeof addParticipantsRequest>) {
  try {
    const itemId = yield select(state => state.detail?.item?._id);
    const participants = yield select(state => state.flow?.flowData?.selectedUsers);
    const message = yield select(state => state.flow?.flowData?.itemData?.invitation);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(api.params(api.v2.communication.participants, { id: itemId }), { message: message, participantUserIds: participants }, addHeader);
    if (result.success) {
      yield put(addParticipantsSuccess({ invitations: result.data }));
      const id = yield select(state => state.communication?.id);
      const participants = yield select(state => state.communication?.participants);
      if (id === itemId && participants) {
        yield put(fetchParticipantsRequest({ id }));
      }
    } else {
      yield put(addParticipantsFailed({}));
    }
    yield put(finishFlowSuccessAction({ finishUrl: '' }));
  } catch (e: any) {
    yield put(addParticipantsFailed({ message: e.message }));
    yield put(finishFlowErrorAction({ message: e.message }));
  }
}

function* fetchMessageByIdWorker(action: ReturnType<typeof fetchMessageByIdRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.message, { id: action.payload.communicationId, messageId: action.payload.messageId }));
    if (result.success) {
      yield put(fetchMessageByIdSuccess({ new: action.payload.new, message: result.data }));
      if (result.data?.emotes) {
        const commType = yield select(state => state.communication.communication?.type);
        const groupAlias = yield select(state => state.foundation.activeConfig?.groupAlias);
        if (groupAlias === 'coop' && commType === 'group') {
          return;
        }
        const emoteUsers = Object.keys(result.data?.emotes);
        if (!emoteUsers || emoteUsers.length === 0) {
          return;
        }
        const usernameDict = yield select(state => state.communication.usernamesById);
        const existingUserIds = Object.keys(usernameDict);
        const userIds = !!existingUserIds && existingUserIds.length > 0 ? emoteUsers.filter(userId => !existingUserIds.includes(userId)) : emoteUsers;
        if (!!userIds && userIds.length > 0) {
          yield put(fetchProfileFullNameByIdsRequest({ userIds }));
        }
      }
    } else {
      yield put(fetchMessageByIdFailed({}));
    }
  } catch (e: any) {
    yield put(fetchMessageByIdFailed({ message: e.message }));
  }
}

function* fetchMessageByIdSuccessWorker(action: ReturnType<typeof fetchMessageByIdSuccess>) {
  const conferenceMessage: IMessageModel | undefined = yield select<(s: DefaultRootState) => IMessageModel | undefined>(s => s.conferencing.message);
  const newMessage = action.payload.message;
  const detailId = yield select<(s: DefaultRootState) => string | undefined>(s => s.detail.item?._id);
  if (newMessage && newMessage.parent === detailId) {
    if (newMessage.conferenceInfo?.started && !newMessage.conferenceInfo?.ended) {
      yield put(updateConferencing({ message: newMessage }));
    } else if (newMessage.conferenceInfo?.ended) {
      yield put(updateConferencing({ message: null }));
    }
  }
  if (conferenceMessage?.id === newMessage.id) {
    yield put(updateConferencingMessage({ message: newMessage }));
  }
}

function* fetchMessagesWorker(action: ReturnType<typeof fetchMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) {
      query.trt = action.payload.threadRoot;
    }
    if (action.payload.numThreadChildren) {
      query.ntc = action.payload.numThreadChildren;
    }
    if (action.payload.message) {
      query.msg = action.payload.message;
    } else {
      if (action.payload.before) {
        query.bfr = action.payload.before;
      }
      if (action.payload.since) {
        query.snc = action.payload.since;
      }
    }

    const result = yield callGet(api.query(api.params(api.v2.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      const msgResult = action.payload.message && !result.hint ? result.data ?? [] : (result.data ?? []).reverse();
      yield put(fetchMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: msgResult, hint: result.hint }));
      if (msgResult) {
        const commType = yield select(state => state.communication.communication?.type);
        const groupAlias = yield select(state => state.foundation.activeConfig?.groupAlias);
        if (groupAlias === 'coop' && commType === 'group') {
          return;
        }
        const emoteUsers = msgResult.reduce((collector: string[], current: any) => {
          if (!current?.emotes) {
            return collector;
          }
          const keys = Object.keys(current?.emotes);
          return _.union(collector, keys);
        }, []);
        if (!emoteUsers || emoteUsers.length === 0) {
          return;
        }
        const usernameDict = yield select(state => state.communication.usernamesById);
        const existingUserIds = Object.keys(usernameDict);
        const userIds = !!existingUserIds && existingUserIds.length > 0 ? emoteUsers.filter(userId => !existingUserIds.includes(userId)) : emoteUsers;
        if (!!userIds && userIds.length > 0) {
          yield put(fetchProfileFullNameByIdsRequest({ userIds }));
        }
      }
    } else {
      yield put(fetchMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* fetchOlderMessagesWorker(action: ReturnType<typeof fetchOlderMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) query.trt = action.payload.threadRoot;
    if (action.payload.numThreadChildren) query.ntc = action.payload.numThreadChildren;
    if (action.payload.before) query.bfr = action.payload.before;

    const result = yield callGet(api.query(api.params(api.v2.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      yield put(fetchOlderMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: (result.data ?? []).reverse() }));
    } else {
      yield put(fetchOlderMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchOlderMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* fetchNewerMessagesWorker(action: ReturnType<typeof fetchNewerMessagesRequest>) {
  try {
    const query: any = {};
    if (action.payload.threadRoot) query.trt = action.payload.threadRoot;
    if (action.payload.numThreadChildren) query.ntc = action.payload.numThreadChildren;
    if (action.payload.since) query.snc = action.payload.since;

    const result = yield callGet(api.query(api.params(api.v2.communication.messages, { id: action.payload.communicationId }), { q: JSON.stringify(query) }));
    if (result.success) {
      yield put(fetchNewerMessagesSuccess({ alias: action.payload.alias, atStart: result.atStart, atEnd: result.atEnd, messages: result.data ?? [] }));
    } else {
      yield put(fetchNewerMessagesFailed({ alias: action.payload.alias, message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchNewerMessagesFailed({ alias: action.payload.alias, message: e.message }));
  }
}

function* setCommunicationWatcher(action: ReturnType<typeof setCommunication>) {
  if (!!action.payload.view) {
    yield put(setActiveView({ view: action.payload.view }));
  } else {
    yield put(setActiveView({ view: 'chat' }));
  }
  if (!!action.payload.tab) {
    yield put(setActiveTab({ tab: action.payload.tab }));
  } else {
    yield put(setActiveTab({ tab: undefined }));
  }
}

function* fetchParticipationWorker(action: ReturnType<typeof fetchParticipationRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.participation, { id: action.payload.id }));
    if (result.success) {
      yield put(fetchParticipationSuccess({ participation: result.data }));
    } else {
      yield put(fetchParticipationFailed({ message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchParticipationFailed({ message: e.message }));
  }
}

function* fetchFilesWorker(action: ReturnType<typeof fetchFilesRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.files, { id: action.payload.id }));
    if (result.success) {
      yield put(fetchFilesSuccess({ files: result.data }));
    } else {
      yield put(fetchFilesFailed({ message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchFilesFailed({ message: e.message }));
  }
}

export function useParticipants() {
  const dispatch = useDispatch();
  const id = useSelector(state => state.communication.id);
  useEffect(() => {
    id && dispatch(fetchParticipantsRequest({ id }));
  }, [dispatch, id]);
}

export function useFiles() {
  const dispatch = useDispatch();
  const id = useSelector(state => state.communication.id);
  useEffect(() => {
    id && dispatch(fetchFilesRequest({ id }));
  }, [dispatch, id]);
}

function* fetchParticipantsWorker(action: ReturnType<typeof fetchParticipantsRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.participants, { id: action.payload.id }));
    if (result.success) {
      yield put(fetchParticipantsSuccess({ participants: result.data }));
    } else {
      yield put(fetchParticipantsFailed({ message: result.message }));
    }
  } catch (e: any) {
    yield put(fetchParticipantsFailed({ message: e.message }));
  }
}

function* setUserMessageEmoteWorker(action: ReturnType<typeof setUserMessageEmoteRequest>) {
  try {
    const url = api.params(api.v2.communication.updateEmotes, {
      id: action.payload.target.parent,
      messageId: action.payload.target.id,
      emoji: action.payload.emoji,
    });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(url, {}, addHeader);

    if (result.success) {
      yield put(setUserMessageEmoteSuccess());
    } else {
      yield put(setUserMessageEmoteFailed());
    }
  } catch (e: any) {
    yield put(setUserMessageEmoteFailed());
  }
}

function* fetchProfileFullNameByIdWorker(action: ReturnType<typeof fetchProfileFullNameByIdsRequest>) {
  try {
    const result = yield callPost(api.v1.profile.fullNameByIds, { userIds: action.payload.userIds });
    if (result) {
      yield put(fetchProfileFullNameByIdsSuccess({ idNameDict: result }));
    } else {
      yield put(fetchProfileFullNameByIdsFailed());
    }
  } catch (e: any) {
    yield put(fetchProfileFullNameByIdsFailed());
  }
}

function* toggleWriteProtectWorker(action: ReturnType<typeof toggleWriteProtectRequest>) {
  try {
    const id = yield select(state => state.communication.id);
    const url = api.params(api.v2.communication.toggleFileWriteProtected, { id });
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result = yield callPost(
      url,
      {
        assetIds: action.payload.assetIds,
        active: action.payload.active,
      },
      addHeader
    );
    if (result.success) {
      yield put(toggleWriteProtectSuccess());
      yield put(fetchFilesRequest({ id }));
    } else {
      yield put(toggleWriteProtectFailed());
    }
  } catch (e: any) {
    yield put(toggleWriteProtectFailed());
  }
}

function* fetchAdminParticipationsWorker(action: ReturnType<typeof fetchAdminParticipationsRequest>) {
  try {
    const result = yield callGet(api.v2.communication.adminCommunications);
    if (result) {
      yield put(fetchAdminParticipationsSuccess({ participations: result }));
    } else {
      yield put(fetchAdminParticipationsFailed({}));
    }
  } catch (e: any) {
    yield put(fetchAdminParticipationsFailed({}));
  }
}

export function* communication() {
  yield all([watcher(), signalRWatcher(), signalrEventHandling()]);
}
