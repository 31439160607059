import { Bx, Typo } from '@curry-group/mui-curcuma';
import React from 'react';
import { FormCompetenceCategories } from '../../form/compositions/FormCompetenceCategories';
import { FramedContent } from '../../framed-content';

const CompetencesForm = (props: any) => {
  props.setNextStepDisabled(false);
  return (
    <FramedContent>
      <FormCompetenceCategories
        headline={'Ihre Kompetenzen'}
        subtitleComponent={
          <Bx>
            <Typo variant="subtitle1">Die Angabe Ihrer Kompetenzen dient dazu, Ihnen Inhalte und Anfragen personalisiert zuzustellen, bei denen Ihre Expertise gefragt ist.</Typo>
            <Typo variant="subtitle1">Ihre Einträge sind für andere Nutzende sichtbar.</Typo>
          </Bx>
        }
        formData={props.flowData}
        categories={props.categories}
        config={props.config}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
        splashImage={'competences'}
      />
    </FramedContent>
  );
};

export default CompetencesForm;
