import { useEffect, useState } from 'react';
import { Anohana, useBreakpoints } from '@curry-group/mui-curcuma';
import { IConferencingOutlet, IConferencingParticipant, IExtendedResolvedParticipant } from '../interfaces';
import { AppFrameSidebar } from '../../frame/sidebar';
import { AppFrame, AppFrameContent, AppFrameHeader } from '../../frame';
import { ConferenceFrame } from '../layout/frame';
import { ParticipantOutlet } from './participants';
import { useDispatch } from 'react-redux';
import { toggleSpeakersDynamic, setSpeakersDynamic } from '../../../data/actions/conferencing';
import { ConnectedConferenceHeaderComponent } from './header';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { ConferencingControls } from '../controls';
import { ConferencingSidebar } from './sidebar';

export const ConferencingOutlet: React.FC<IConferencingOutlet> = ({
  userName,
  desktopActive,
  myMedia,
  open,
  publisher,
  currentParticipants,
  session,
  sidebarType,
  speakers,
  speakersDynamic,
  toggleSidebar,
  onDevicesChange,
  closeClick,
  toggleHandClick,
  toggleAudioClick,
  toggleVideoClick,
  toggleDesktopClick,
  onInfoClick,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [fullscreen, setFullscreen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const breakpoints = useBreakpoints();
  const dp: any = publisher?.filter(m => m.desktop)[0];
  const normalPublisher: any[] = publisher?.filter(m => !m.desktop) || [];
  const publisherUnion: IConferencingParticipant[] = [
    {
      id: session?.publisherIdJanus || -100,
      self: true,
      audio: session?.audio,
      video: session?.video,
      hand: session?.hand,
      media: myMedia,
      speaking: session?.speaking,
    },
    ...normalPublisher,
  ];
  let visibleParticipants: IConferencingParticipant[] = publisherUnion;

  const extendedParticipants: IExtendedResolvedParticipant[] =
    currentParticipants?.map(p => {
      return {
        conferencingStatus: visibleParticipants.find(vP => vP.userId === p.user) || visibleParticipants.find(vP => vP.self),
        ...p,
      };
    }) || [];

  useEffect(() => {
    if (isMobile) {
      dispatch(setSpeakersDynamic({ setDynamic: true }));
    }
  }, [dispatch, isMobile]);

  const fade = false;
  const sidebarVisible = sidebarType !== '';
  return (
    <Anohana open={open} fullScreen transition="zoom">
      <AppFrame>
        <AppFrameHeader fadeOut={fade}>
          <ConnectedConferenceHeaderComponent
            sidebarVisible={sidebarVisible || false}
            fadeOut={fade}
            onMouseEnter={undefined}
            onMouseLeave={undefined}
          />
        </AppFrameHeader>
        {sidebarVisible && <AppFrameSidebar sidebarPosition="right">
          <ConferencingSidebar
            sidebarType={sidebarType}
            currentParticipants={extendedParticipants}
            session={session}
            onCloseClick={() => toggleSidebar('')}
            onDevicesChange={(audio, audioOut, video) => {
              onDevicesChange?.(audio, audioOut, video);
            }}
            fullscreen={breakpoints.smDown}
          />
        </AppFrameSidebar>}
        <AppFrameContent>
          <ConferenceFrame
            fadeOutControls={fade}
            onControlsMouseEnter={undefined}
            onControlsMouseLeave={undefined}
            stream={<ParticipantOutlet participants={visibleParticipants} speakers={speakers} desktop={dp} userName={userName} />}
            frameControls={undefined}
            rightControls={
              <ConferencingControls
                audio={session?.audio}
                video={session?.video}
                hand={session?.hand}
                desktop={desktopActive}
                toggleDesktop={toggleDesktopClick}
                toggleHand={toggleHandClick}
                toggleAudio={toggleAudioClick}
                toggleVideo={toggleVideoClick}
                close={closeClick}
                gridview={!fullscreen}
                onGridviewChange={gridview => {
                  setFullscreen(!gridview);
                }}
                settingsOpen={settingsOpen}
                currentParticipants={extendedParticipants}
                participantsOrderDynamic={speakersDynamic}
                toggleSettings={() => {
                  setSettingsOpen(!settingsOpen);
                }}
                toggleParticipantsOrderDynamic={() => {
                  dispatch(toggleSpeakersDynamic());
                }}
                sidebarType={sidebarType}
                toggleSidebar={toggleSidebar}
                onDevicesChange={onDevicesChange}
              />
            }
          />
        </AppFrameContent>
      </AppFrame>

      <div style={{ position: 'absolute', inset: 0, backgroundColor: 'transparent' }} onMouseMove={undefined}></div>
    </Anohana>
  );
};
