import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import clsx from 'clsx';
import { Bx } from '@curry-group/mui-curcuma';

export interface ISplashSideBarProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  alt?: string;
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignContent'];
}

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.primary.main,
      width: 342,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'hidden'
    },
    limiter: {
      overflow: 'hidden',
      display: 'flex',
      padding: theme.spacing(6),
      justifyContent: (props: ISplashSideBarProps) => props.justify,
      alignItems: (props: ISplashSideBarProps) => props.align
    },
    inner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      width: 342
    }
  }),
  {
    name: 'Curcuma',
    meta: ' SplashSideBar'
  }
);

/**
 * The `SplashSideBar` component inherits all HTML attributes of an `DIV`-Element.
 */
export const SplashSideBar: React.FunctionComponent<ISplashSideBarProps> = ({ src, alt, justify, align, className, children, ...rest }) => {
  const classes = useStyles({ justify, align });
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {children && (
        <Bx px={3.5} py={3} className={classes.inner}>
          {children}
        </Bx>
      )}
      <div className={classes.limiter}>{src && <img src={src} alt={alt} />}</div>
    </div>
  );
};

SplashSideBar.defaultProps = {
  justify: 'center',
  align: 'center'
};
